import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-dayparting-modal',
  templateUrl: './campaign-dayparting-modal.component.html',
  styleUrls: ['./campaign-dayparting-modal.component.scss']
})
export class CampaignDaypartingModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }
  selectedOptions: string;
  columnDefsRules = [
    {headerName: 'From', field: 'from', width: 120 },
    {headerName: 'To', field: 'to', width: 120 },
    {headerName: 'Mon', field: 'mon', width: 60 },
    {headerName: 'Tue', field: 'tue', width: 60 },
    {headerName: 'Wed', field: 'wed', width: 60 },
    {headerName: 'Thu', field: 'thu', width: 60 },
    {headerName: 'Fri', field: 'fri', width: 60 },
    {headerName: 'Sat', field: 'sat', width: 60 },
    {headerName: 'Sun', field: 'sun', width: 60 },
    {headerName: '', field: 'action', width: 100 },
  ];
  rowDataRules = [];

  constructor() { }

  ngOnInit() {
    this.selectedOptions = 'custom';
  }

}
