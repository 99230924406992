import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-contextual-modal',
  templateUrl: './campaign-contextual-modal.component.html',
  styleUrls: ['./campaign-contextual-modal.component.scss']
})
export class CampaignContextualModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }

  columnDefsSets = [
    {headerName: 'Name', field: 'name', width: 200 },
    {headerName: 'Category1', field: 'cat1', width: 80 },
    {headerName: 'Category2', field: 'cat2', width: 80 },
    {headerName: 'Category3', field: 'cat3', width: 80 },
    {headerName: '', field: 'action', width: 80 },
  ];
  rowDataSets = [];
  columnDefsSetsTarg = [
    {headerName: 'Name', field: 'name', width: 200 },
    {headerName: 'Category1', field: 'cat1', width: 80 },
    {headerName: 'Category2', field: 'cat2', width: 80 },
    {headerName: 'Category3', field: 'cat3', width: 80 },
    {headerName: '', field: 'action', width: 80 },
  ];
  rowDataSetsTarg = [];

  constructor() { }

  ngOnInit() {
  }

}
