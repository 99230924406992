import { Component } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import {CommonAuthService} from './auth/common-auth.service';
import {UserRestService} from './admin/users/user-rest.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  loading = false;
  constructor(private router: Router, private authenticationService: CommonAuthService, private route: ActivatedRoute, private userService: UserRestService) {
    console.log('routes: ', router, this.authenticationService.loggedIn);

    this.route.queryParams.subscribe(params => {
        console.log(params);
        if (typeof params['single_sign_on_code'] !== 'undefined' && params['single_sign_on_code'] !== null && params['single_sign_on_code'] !== '') {
          const oldToken = localStorage.getItem('token');
          localStorage.setItem('token', params['single_sign_on_code']);
          this.userService.getByToken(params['single_sign_on_code']).subscribe(
            response => {
              console.log(response)
              localStorage.setItem('user', JSON.stringify(response.user));
              localStorage.setItem('user2', JSON.stringify(response['user2']));
              this.authenticationService.setCustomUser(response.user);
              this.router.navigate(['/mybrands']);
            }, error => {
              console.log(error)
            }
          )
        }
    });

    // @ts-ignore
    // this.router.events.subscribe((event: Event) => {
    //   switch (true) {
    //     case event instanceof NavigationStart: {
    //       this.loading = true;
    //       break;
    //     }
    //
    //     case event instanceof NavigationEnd:
    //     case event instanceof NavigationCancel:
    //     case event instanceof NavigationError: {
    //       this.loading = false;
    //       break;
    //     }
    //     default: {
    //       break;
    //     }
    //   }
    // });
  }
}
