import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {ThirdPartyDataService} from '../../_services/third-party-data/third-party-data.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-contextual-bulk',
  templateUrl: './contextual-bulk.component.html',
  styleUrls: ['./contextual-bulk.component.scss']
})
export class ContextualBulkComponent implements OnInit {

  contextualSource = 'thirdParty';
  configContextual = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  itemsContextual: TreeviewItem[] = [];
  contextualRules: any[] = [];
  @Input() parentType = 'modal';
  @Output() setDataRules = new EventEmitter<any>();
  contextualTargeting: any;
  ngLoading = false;

  constructor(private thirdSourceService: ThirdPartyDataService, private fb: FormBuilder) { }

  ngOnInit() {
    this.contextualTargeting = this.fb.group({
        providerIds: this.fb.array([], Validators.required),
        compositeContextualRule: this.fb.group({
          type: ['composite'],
          logicalOperator: ['or'],
          rules: this.fb.array([])
        })
    })
  }

  changeContextualSource(event) {
    console.log(event);
  }

  contextualFilterChanged(event) {
    console.log(event);
  }

  removeRule(pos, type) {
    this.contextualRules.splice(pos, 1);
  }

  getContextualData(item = null) {
    if (this.itemsContextual.length === 0) {
      this.thirdSourceService.getContextualSegments().subscribe(
        (response) => {
          let position = 0;
          for (const context in response[0]) {
            if (response[0][context].type === 'contextual') {
              this.itemsContextual.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              this.itemsContextual[position]['level'] = 0;
              this.itemsContextual[position]['targetable'] = response[0][context].targetable;
              this.itemsContextual[position].setCollapsedRecursive(true);
              position++;
            }
          }
          console.log(response, this.itemsContextual)
        },
        (error) => {
          console.log(error, 'getContextualData');
        }
      )
    } else if (item !== null) {
      this.thirdSourceService.getContextualSegmentChildren(item.value).subscribe(
        (response) => {
          let position = 1;
          for (const context in response[0]) {
            if (response[0][context].type === 'contextual') {
              item.children.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              position++;
            }
          }
          item.children.shift();
          console.log(response, this.itemsContextual)
        },
        (error) => {
          console.log(error, 'getContextualData');
        }
      )
    }
  }

  addTargetSegment(item, type: string) {
    item['type'] = type;
    item['blocked'] = false;
    this.contextualRules.push(item);
  }

  saveRules() {
    this.ngLoading = true;
    if (this.contextualRules.length > 0) {
      for (const rule of this.contextualRules) {
        this.fullfillContextualTargeting(rule, rule.type);
      }
    }
    this.setDataRules.emit(this.contextualTargeting.value);
  }

  cancelModal() {
    $('.contextual-bulk-modal').modal('hide');
  }

  fullfillContextualTargeting(item, type: string, formControl: FormGroup = null) {
    console.log(item, type);
    if (formControl === null) {
      // @ts-ignore
      formControl = this.contextualTargeting.controls.compositeContextualRule;
    }
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    if (typeof item.providerId !== 'undefined' && (this.contextualTargeting.controls.providerIds as FormArray).value.findIndex((context) => context === item.providerId) === -1) {
      // @ts-ignore
      (this.contextualTargeting.controls.providerIds as FormArray).push(this.fb.control(item.providerId));
    }
    // @ts-ignore
    const position = (formControl.controls.rules as FormArray).value.length;
    if (position > 1) {
      const currentValues = (formControl.controls.rules as FormArray).at(position - 1).value;
      if (currentValues.type === 'atomic') {
        (formControl.controls.rules as FormArray).removeAt(position - 1);
        (formControl.controls.rules as FormArray).push(this.fb.group({
          type: ['composite'],
          logicalOperator: [type],
          rules: this.fb.array([
            this.fb.group({
              type: ['atomic'],
              blocked: [currentValues.blocked],
              minWeight: [100],
              contextualEntryId: [currentValues.contextualEntryId]
            })
          ])
        }));
      }
      this.fullfillContextualTargeting(item, type, ((formControl.controls.rules as FormArray).at(position - 1) as FormGroup))
    } else {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      (formControl.controls.logicalOperator as FormControl).setValue(type);
      // @ts-ignore
      (formControl.controls.rules as FormArray).push(this.fb.group({
        type: ['atomic'],
        blocked: [false],
        minWeight: [100],
        contextualEntryId: [item.value]
      }));
      console.log(this.contextualTargeting.value);
    }
  }
}
