import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {User} from '../../_models/user';
import {UserRestService} from '../../admin/users/user-rest.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions-cell-renderer.component.html',
  styleUrls: ['./actions-cell-renderer.component.scss']
})
export class ActionsCellRendererComponent {
  // @ts-ignore
  @ViewChild('i') buttons;
  params;
  gridType = 'User';
  type = 'all';
  brandId: any = null;
  copy = false;
  showDelete = false;
  user;
  canGhost = false;

  constructor(private userRest: UserRestService, private router: Router) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.canGhost = this.user.role === 'ADMIN' || (typeof this.user['from'] !== 'undefined' && this.user['from'] === 'anAdmin');
  }

  agInit(params: any): void {
    this.params = params;
    this.gridType = this.params.renderType;
    this.type = (typeof params.type !== 'undefined') ? params.type : 'all';
    this.copy = (typeof params.copy !== 'undefined') ? params.copy : false;
    this.showDelete = (typeof params.delete !== 'undefined') ? params.delete : false;
  }

  getValue() {
    return this.buttons.nativeElement.value;
  }

  deleteRow() {
    if (this.gridType === 'ScheduleReport') {
      this.params.context.componentParent.deleteScheduleRpt({remove: [this.params.node.data]});
    }
    if (this.gridType === 'Client' || this.gridType === 'User') {
      this.params.context.componentParent.remove(this.params.node.data.id, this.gridType);
      this.params.api.updateRowData({remove: [this.params.node.data]});
    }
    if (this.gridType === 'Ad') {
      this.params.context.componentParent.removeAd(this.params.node.data);
      this.params.api.updateRowData({remove: [this.params.node.data]});
    }
    //this.params.api.updateRowData({remove: [this.params.node.data]});
  }

  ghostUser(id) {
    if (this.gridType === 'User') {
      this.params.context.componentParent.ghostUser(id);
    }
  }
}
