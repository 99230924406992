import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-link-ad-campaign',
  templateUrl: './link-ad-campaign.component.html',
  styleUrls: ['./link-ad-campaign.component.scss']
})
export class LinkAdCampaignComponent {

  // @ts-ignore
  @ViewChild('i') customHtml;
  params;
  text = '';
  linked = false;

  constructor() { }

  agInit(params: any): void {
    if (typeof params.linked !== 'undefined' && params.linked !== null) {
      this.linked = params.linked;
    }
  }

  getValue() {
    return this.customHtml.nativeElement.value;
  }

  refresh(): boolean {
    return false;
  }

  linkCampaign(event) {

  }
}
