import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-locations-modal',
  templateUrl: './campaign-locations-modal.component.html',
  styleUrls: ['./campaign-locations-modal.component.scss']
})
export class CampaignLocationsModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }
  selectedOptions: string;
  columnDefsLocation = [
    {headerName: 'Location', field: 'location', width: 500 },
    {headerName: 'Status', field: 'id', width: 120 },
    {headerName: '', field: 'action', width: 100 },
  ];
  rowDataLocation = [];

  constructor() { }

  ngOnInit() {
    this.selectedOptions = 'usa';
  }

  openBrowseAddLocationModal() {
    $('.browse-add-locations-modal').modal('show');
  }

  openLookupDMACodesModal() {
    $('.lookup-codes-modal').modal('show');
  }

}
