import {Component, OnInit, ViewChild} from '@angular/core';
/*import {CampaignsComponent} from '../../../pages/campaigns/campaigns.component';*/

@Component({
  selector: 'app-number-format-cell-renderer',
  templateUrl: './number-format-cell-renderer.component.html',
  styleUrls: ['./number-format-cell-renderer.component.scss']
})
export class NumberFormatCellRendererComponent {

  // @ts-ignore
  @ViewChild('i') customHtml;
  params;
  number = '0';

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    const column = this.params.column.colId;
    if (this.params.totalType === 'percent') {
      this.number = (((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? parseFloat(this.params.value) : 0) * 100).toFixed(2) + '%';
    } else if (this.params.totalType === 'money') {
      if ((column.indexOf('Budget') === -1 && column.indexOf('budget') === -1) || (typeof params.context !== 'undefined' && typeof params.context.componentParent !== 'undefined' /*&& params.context.componentParent instanceof CampaignsComponent*/)) {
        const margin = (typeof params.data.extraData !== 'undefined' && params.data.extraData.margin > 1) ?
          ((100 - params.data.extraData.margin) / 100) : 1;
        this.number = '$' + ((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? parseFloat(this.params.value) / 1 : 0).toFixed(2);
      } else {
        this.number = '$' + ((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? parseFloat(this.params.value) : 0).toFixed(2);
      }
    } else {
      this.number = String((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? parseFloat(this.params.value) : 0);
    }
  }

  getValue() {
    return this.customHtml.nativeElement.value;
  }

  refresh(): boolean {
    return false;
  }

}
