import { Injectable } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject();
  private keepAfterRouteChange = false;

  constructor(private router: Router, private toastr: ToastrService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    console.log('success in');
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({type: 'success', text: message});
  }

  error(message: string, keepAfterRouteChange = false) {
    console.log('error in');
    this.keepAfterRouteChange = keepAfterRouteChange;
    console.log('this.keepAfterRouteChange', this.keepAfterRouteChange);
    this.subject.next({type: 'error', text: message});
  }

  clear() {
    console.log('clear in');
    this.subject.next();
  }

  alert(type: any, text: string, timeout: any = 4000) {
    if (type === 'success') {
      this.toastr.success(text, '', {
        timeOut: 5000,
        progressBar: true,
        positionClass: 'toast-top-right'
      })
    }
    if (type === 'warning') {
      this.toastr.warning(text, '', {
        timeOut: 7000,
        progressBar: true,
        positionClass: 'toast-top-right'
      })
    }
    if (type === 'error') {
      this.toastr.error(text, '', {
        timeOut: 5000,
        progressBar: true,
        positionClass: 'toast-top-right'
      })
    }
  }

  confirm(callback, text = 'Are you sure want to delete this item?', icon = 'warning', title = '') {
    Swal.fire({
      title: text,
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#51cbce',
      cancelButtonColor: '#ef8157',
      confirmButtonText: 'Yes'
    }).then(callback);
  }
}
