import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BrandResponse, UniversalResponse, UniversalStatsResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Conversion} from '../../_models/conversions';
import {Audience} from '../../_models/audience';
import {UniversalPixel} from '../../_models/universal_pixel';

@Injectable({
  providedIn: 'root'
})
export class UniversalPixelsService {
  private univUrl = 'universal';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getUniversalStats(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0, searchVal = ''): Observable<UniversalStatsResponse> {
    console.log(params);
    return this.http.get<UniversalStatsResponse>(
      `${environment.apiUrl}` + this.univUrl
      + '?brandId=' + brandId + '&max=' + max + '&offset=' + offset + '&filter=' + params.filter
      + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&includeDeleted=' + params.status
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<UniversalStatsResponse>('getUniversalStats'))
    );
  }

  getAudienceById(id: any) {
    return this.http.get<BrandResponse>(`${environment.apiUrl}` + this.univUrl + '/' + id).pipe(
      tap(_ => console.log('fetched audience')),
      catchError(Errors.handleError<BrandResponse>('getAudienceById'))
    );
  }

  createUniversal(univ: UniversalPixel): Observable<UniversalResponse> {
    return this.http.post<UniversalResponse>(`${environment.apiUrl}` + this.univUrl , univ, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createUniversal', univ))
      ) as Observable<UniversalResponse>;
  }

  updateUniversal(univ: UniversalPixel, id: bigint): Observable<UniversalResponse>  {
    return this.http.put<UniversalResponse>(`${environment.apiUrl}` + this.univUrl + '/' + id, univ, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateUniversal', univ))
      ) as Observable<UniversalResponse>;
  }

  getTagCode(type: string, univId: number, brandId: number, userMatching: boolean, deploy: boolean) {
    return this.http.get<{response: string, status: string}>(`${environment.apiUrl}` + this.univUrl + '/getTag/' + univId
      + '?brandId=' + brandId + '&type=' + type + '&userMatching=' + userMatching + '&deploy=' + deploy)
      .pipe(
        tap(_ => console.log('fetched universal tag')),
        catchError(Errors.handleError<{response: string, status: string}>('getTagCode'))
      );
  }

  getPixelRules(univId: bigint, brandId: number) {
    return this.http.get<{response: any, status: string}>(`${environment.apiUrl}` + this.univUrl + '/' + brandId + '/' + univId)
      .pipe(
        tap(_ => console.log('fetched universal tag')),
        catchError(Errors.handleError<{response: string, status: string}>('getPixelRules'))
      );
  }
}
