export enum Role {
  Manager = 'MANAGER',
  Basic = 'BASIC',
  Admin = 'ADMIN'
}

export enum RoleBasic {
  Manager = 'MANAGER',
  Basic = 'BASIC',
}

export enum Status {
  online = 'online',
  offline = 'offline',
  archived = 'archived'
}

export enum ReviewStatus {
  pending = 'pending',
  eligible = 'eligible',
  hold = 'hold',
  denied = 'denied',
  review = 'review',
  blocked = 'blocked'
}

export enum Type {
  banner = 'banner',
  tag = 'tag',
  expandable = 'expandable',
  ad_builder = 'ad_builder',
  video = 'video',
  native = 'native',
  html5 = 'html5',
  audio = 'audio'
}

export enum ExpandingDirection {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
  up_left = 'up_left',
  up_right = 'up_right',
  down_left = 'down_left',
  down_right = 'down_right',
  up_or_down = 'up_or_down',
  left_or_right = 'left_or_right',
  any_diagonal = 'any_diagonal',
  none = 'none'
}

export enum NativeCreativeDescriptor {
  facebook_sidebar = 'facebook_sidebar',
  facebook_news_feed = 'facebook_news_feed'
}

export enum PacingSetting {
  CAMPAIGN_GROUP = 'CAMPAIGN_GROUP',
  CAMPAIGN = 'CAMPAIGN'
}

export enum KpiType {
  CTR = 'CTR',
  CPA = 'CPA',
  CPClick = 'CPClick',
  VIDEO_COMP = 'VIDEO_COMP'
}

export enum AdvertiserSpendType {
  FLAT_CPC = 'FLAT_CPC',
  FLAT_CPM = 'FLAT_CPM',
  MARGIN = 'MARGIN',
  MARKUP = 'MARKUP',
  NONE = 'NONE'
}

export enum ImpressionCapType {
  NONE = 'NONE',
  ALL_TIME = 'ALL_TIME',
  DAILY = 'DAILY'
}

export enum DomainListType {
  whitelist = 'whitelist',
  blacklist = 'blacklist'
}
