import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {RangesDatepickerComponent} from '../ranges-datepicker/ranges-datepicker.component';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {Campaign} from '../../_models/campaign';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {CsvExportParams} from 'ag-grid-community';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import * as moment from 'moment';
import {CampaignsComponent} from '../../pages/campaigns/campaigns.component';
import {GridColumnsCustomizationComponent} from '../grid-columns-customization/grid-columns-customization.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-domains-stats-crud',
  templateUrl: './domains-stats-crud.component.html',
  styleUrls: ['./domains-stats-crud.component.scss']
})
export class DomainsStatsCrudComponent implements OnInit {
  @Input() campaignData: Campaign;
  @Output() refreshDates = new EventEmitter<any>();
  rangesFooter = RangesDatepickerComponent;
  defaultBrandsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' }
  };
  columnDefsDomains = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    // {
    //   headerName: 'Actions',
    //   field: 'campaignId',
    //   colId: 'actions',
    //   sortable: true,
    //   filter: true,
    //   width: 90,
    //   cellRenderer: 'actionsCellRendererComponent',
    //   cellRendererParams: { renderType: 'Campaign' },
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    {
      headerName: 'Status',
      field: 'impliedStatus',
      width: 90, filter: true,
      cellRenderer: 'statusCellRendererComponent',
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', sort: 'asc', width: 250, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'CPM Bid Rate', field: 'impliedBid', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total Spend', field: 'totalSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend', field: 'advertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Imps. Won', field: 'impressionsWon', width: 100 },
    {headerName: 'Clicks', field: 'clicks', width: 100 },
    {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 100 },
    {headerName: 'Primary Conv.', field: 'conversions', width: 100, cellRenderer: (params) => params.node.data.clickThroughConversions +  params.node.data.viewThroughConversions },
    {headerName: 'Total Eligible Imps.', field: 'eligibleImpressions', width: 100 },
    {headerName: 'Total Viewable Imps.', field: 'viewableImpressions', width: 100 },
    {headerName: 'Measured Rate', field: '', width: 100 },
    {headerName: 'Viewable Rate', field: '', width: 100 },
    {headerName: 'Media Spend', field: 'auctionsSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Data Spend', field: 'dataSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Win Rate', field: 'winRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Adv. Spend CPM', field: 'effectiveCPMOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPC', field: 'effectiveCPCOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPC', field: 'costPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Media CPM', field: 'mediaEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Data CPM', field: 'dataEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTR', field: 'clickthruRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'VTC', field: 'viewthruConversions', width: 100 },
    {headerName: 'CTC', field: 'clickThroughConversions', width: 100 },
    {headerName: 'Primary CTC', field: '', width: 100 },
    {headerName: 'Primary VTC', field: '', width: 100 },
    {headerName: 'Total Revenue', field: 'revenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTC Revenue', field: 'ctcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'VTC Revenue', field: 'vtcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPA', field: 'effectiveCPAOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. Adv. Spend CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPA', field: 'costPerAcquisition', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. COnv. CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Primary CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click CVR', field: 'clickCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'View CVR', field: 'viewCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total CVRM', field: 'totalCVRM', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'EPC', field: 'profitPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total RPM', field: 'revenuePerMille', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click RPM', field: 'clickRPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'ROAS', field: 'returnOnAdSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Audio/Video Starts', field: 'videoStarted', width: 100 },
    {headerName: '25% Complete', field: 'videoFirstQuartileReached', width: 100 },
    {headerName: '50% Complete', field: 'videoMidpointReached', width: 100 },
    {headerName: '75% Complete', field: 'videoThirdQuartileReached', width: 100 },
    {headerName: '100% Complete', field: 'videoCompleted', width: 100 },
    {headerName: 'Adv. Spend CPCV', field: 'effectiveCPCVOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPCV', field: 'totalEffectiveCPCV', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Eligible Spend', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Eligible CPM', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Companion Imps.', field: 'companionImpressions', width: 100 },
    {headerName: 'Companion Clicks', field: 'companionClicks', width: 100 },
    {headerName: 'Companion CTC', field: 'companionConversions', width: 100 },
    {headerName: 'Companion CTCR', field: 'companionOfferRevenue', width: 100 }
  ];
  rowDataDomains: Observable<Array<any>>;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent
  };
  domainsGrid: any = null;
  domainsDateRange: any;
  statusSelected = '';
  domainsFilterString = '';
  ngLoading = false;
  bulkStatusEdit = '';
  cpmBidBulkEdit = '';
  rangeDatesFlag = false;
  filtersActive = false;
  private currentDateScope = 'one';
  private gridColumns: Array<{text: string, id: string, child: Array<{text: string, id: string}>}> =
    [
      {
        text: 'Auctions',
        id: 'auctions',
        child: [
          {text: 'Imp. Won', id: 'impressionsWon'},
          {text: 'Win Rate', id: 'winRate'},
          {text: 'Total eCPM', id: 'totalEffectiveCPM'},
          {text: 'Adv. Spend eCPM', id: 'effectiveCPMOnAdvertiserSpend'},
          {text: 'Media eCPM', id: 'mediaEffectiveCPM'},
          {text: 'Data eCPM', id: 'dataEffectiveCPM'}
        ]
      },
      {
        text: 'Audio/Video',
        id: 'audio_video',
        child: [
          {text: 'Audio/Video Starts', id: 'videoStarted'},
          {text: '25% Complete', id: 'videoFirstQuartileReached'},
          {text: '50% Complete', id: 'videoMidpointReached'},
          {text: '75% Complete', id: 'videoThirdQuartileReached'},
          {text: '100% Complete', id: 'videoCompleted'},
          {text: 'Completion Rate', id: 'videoCompletionRate'},
          {text: 'Adv. Spend eCPCV', id: 'effectiveCPCVOnAdvertiserSpend'},
          {text: 'Total eCPCV', id: 'totalEffectiveCPCV'},
        ]
      },
      {
        text: 'Budget',
        id: 'budget',
        child: [
          {text: 'Total Spend', id: 'totalSpend'},
          {text: 'CPM Bid', id: 'impliedBid'},
          {text: 'Daily Budget', id: 'dailyTotalSpendTarget'},
          {text: 'All Time Budget', id: 'budget'},
          {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
          {text: 'Adv. Spend', id: 'advertiserSpend'},
          {text: 'Media Spend', id: 'auctionsSpend'},
          {text: 'Data Spend', id: 'dataSpend'},
          {text: 'Actual Margin %', id: 'marginOnAdvertiserSpend'},
          {text: 'Max Billable', id: 'advertiserSpendRate'}
        ]
      },
      {
        text: 'Clicks',
        id: 'clicks',
        child: [
          {text: 'Clicks', id: 'clicks'},
          {text: 'Adv. Spend eCPC', id: 'effectiveCPCOnAdvertiserSpend'},
          {text: 'CTR', id: 'costPerClick'},
          {text: 'EPC', id: 'profitPerClick'},
        ]
      },
      {
        text: 'Companion Ads',
        id: 'companion_ads',
        child: [
          {text: 'Companion Imps. Won', id: 'companionImpressions'},
          {text: 'Companion Clicks', id: 'companionClicks'},
          {text: 'Companion CTC', id: 'companionConversions'},
          {text: 'Companion CTC Revenue', id: 'companionOfferRevenue'}
        ]
      },
      {
        text: 'Conversions',
        id: 'conversions',
        child: [
          {text: 'Total Conversions', id: 'totalConversions'},
          {text: 'CTC', id: 'clickThroughConversions'},
          {text: 'VTC', id: 'viewthruConversions'},
          {text: 'Adv. Spend eCPA', id: 'effectiveCPAOnAdvertiserSpend'},
          {text: 'Total eCPA', id: 'effectiveCPA'},
          {text: 'CTC eCPA', id: 'ctcEffectiveCPA'},
          {text: 'Click CVR', id: 'clickCVR'},
          {text: 'View CVR', id: 'viewCVR'},
          {text: 'Total CVRM', id: 'totalCVRM'},
        ]
      },
      {
        text: 'Extras',
        id: 'extras',
        child: [
          {text: 'Group ID', id: 'campaignGroupId'}
        ]
      },
      {
        text: 'Primary Conversions',
        id: 'primary_conversions',
        child: [
          {text: 'Primary Conv.', id: 'conversions'},
          {text: 'Primary CTC', id: 'primaryCTC'},
          {text: 'Primary VTC', id: 'primaryVTC'},
          {text: 'Primary Adv. Spend eCPA', id: 'primaryEffectiveCPASpend'},
          {text: 'Primary Conv. eCPA', id: 'primaryEffectiveCPAConversion'},
          {text: 'Primary CTC eCPA', id: 'primaryEffectiveCPACTC'},
        ]
      },
      {
        text: 'Revenue',
        id: 'revenues',
        child: [
          {text: 'Total Revenue', id: 'revenue'},
          {text: 'CTC Revenue', id: 'ctcRevenue'},
          {text: 'VTC Revenue', id: 'vtcRevenue'},
          {text: 'Total RPM', id: 'revenuePerMille'},
          {text: 'Click RPM', id: 'clickRPM'},
          {text: 'ROAS', id: 'returnOnAdSpend'},
        ]
      },
      {
        text: 'Viewability',
        id: 'viewability',
        child: [
          {text: 'Total Eligible Imps.', id: 'eligibleImpressions'},
          {text: 'Total Measured Imps.', id: 'measuredImpressions'},
          {text: 'Total Viewable Imps.', id: 'viewableImpressions'},
          {text: 'Measured Rate', id: 'measuredRate'},
          {text: 'Viewable Rate', id: 'viewableRate'},
          {text: 'Eligible Spend', id: 'eligibleSpend'},
          {text: 'Eligible vCPM', id: 'eligibleVCPM'},
        ]
      }
    ];

  columnSetup = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks','effectiveCPCOnAdvertiserSpend','costPerClick','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','effectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];
  columnSetupDefault = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks','effectiveCPCOnAdvertiserSpend','costPerClick','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','effectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];


  constructor(private campaignService: CampaignService, private modalService: NgbModal, private gridRender: GridRenderService, public dialog: MatDialog) {
    this.initializeRangeDates();
  }

  ngOnInit() {
    const domainsCustomColumns = localStorage.getItem('domainsCustomColumns');
    if (domainsCustomColumns !== null) {
      this.columnSetup = JSON.parse(domainsCustomColumns);
    }
    this.rowDataDomains = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
      ob.next([]);
      ob.complete();
    });
  }

  getCampaignDomainsStats(campaignId) {
    this.ngLoading = true;
    this.campaignService.getCampaignDomainsStats(campaignId,
      // @ts-ignore
      {
            filter: this.domainsFilterString,
            // tslint:disable-next-line:max-line-length
            dateTo: (this.domainsDateRange !== null && typeof this.domainsDateRange !== 'undefined') ? this.domainsDateRange.end.format('YYYYMMDD') : '',
            // tslint:disable-next-line:max-line-length
            dateFrom: (this.domainsDateRange !== null && typeof this.domainsDateRange !== 'undefined') ? this.domainsDateRange.begin.format('YYYYMMDD') : '',
        status: this.statusSelected
          }
      ).subscribe(
      (response) => {
        console.log(response);
        // @ts-ignore
        if (typeof response !== 'undefined' && response !== null && response.length > 0) {
          for (const i in response) {
            // const margin = (typeof response[i]['extraData'] !== 'undefined' && response[i]['extraData'].margin > 1) ?
            //   ((100 - response[i]['extraData'].margin) / 100) : 1;
            const margin = 1;
            response[i].totalSpend = isNaN(response[i].totalSpend) ? 0 : response[i].totalSpend / margin;
            response[i].impliedBid = isNaN(response[i].impliedBid) ? 0 : response[i].impliedBid / margin;
            response[i].grossTotalSpend = isNaN(response[i].grossTotalSpend) ? 0 : response[i].grossTotalSpend / margin;
            response[i].auctionsSpend = isNaN(response[i].auctionsSpend) ? 0 : response[i].auctionsSpend / margin;
            response[i].dataCost = isNaN(response[i].dataCost) ? 0 : response[i].dataCost / margin;
            response[i].dataSpend = isNaN(response[i].dataSpend) ? 0 : response[i].dataSpend / margin;
            response[i].dataEffectiveCPM = isNaN(response[i].dataEffectiveCPM) ? 0 : response[i].dataEffectiveCPM / margin;
            response[i].effectiveCPM = isNaN(response[i].effectiveCPM) ? 0 : response[i].effectiveCPM / margin;
            response[i].mediaEffectiveCPM = isNaN(response[i].mediaEffectiveCPM) ? 0 : response[i].mediaEffectiveCPM / margin;
            response[i].totalEffectiveCPCV = isNaN(response[i].totalEffectiveCPCV) ? 0 : response[i].totalEffectiveCPCV / margin;
            response[i].effectiveCPMOnAdvertiserSpend = isNaN(response[i].effectiveCPMOnAdvertiserSpend) ? 0 : response[i].effectiveCPMOnAdvertiserSpend / margin;
            response[i].effectiveCPCOnAdvertiserSpend = isNaN(response[i].effectiveCPCOnAdvertiserSpend) ? 0 : response[i].effectiveCPCOnAdvertiserSpend / margin;
            response[i].costPerClick = isNaN(response[i].costPerClick) ? 0 : response[i].costPerClick / margin;
            response[i].effectiveCPCVOnAdvertiserSpend = isNaN(response[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response[i].effectiveCPCVOnAdvertiserSpend / margin;
            response[i].returnOnAdSpend = isNaN(response[i].returnOnAdSpend) ? 0 : response[i].returnOnAdSpend / margin;
            response[i].clickRPM = isNaN(response[i].clickRPM) ? 0 : response[i].clickRPM / margin;
            response[i].revenue = isNaN(response[i].revenue) ? 0 : response[i].revenue / margin;
            response[i].ctcRevenue = isNaN(response[i].ctcRevenue) ? 0 : response[i].ctcRevenue / margin;
            response[i].vtcRevenue = isNaN(response[i].vtcRevenue) ? 0 : response[i].vtcRevenue / margin;
            response[i].effectiveCPAOnAdvertiserSpend = isNaN(response[i].effectiveCPAOnAdvertiserSpend) ? 0 : response[i].effectiveCPAOnAdvertiserSpend / margin;
            response[i].costPerAcquisition = isNaN(response[i].costPerAcquisition) ? 0 : response[i].costPerAcquisition / margin;
            response[i].profitPerClick = isNaN(response[i].profitPerClick) ? 0 : response[i].profitPerClick / margin;
            response[i].revenuePerMille = isNaN(response[i].revenuePerMille) ? 0 : response[i].revenuePerMille / margin;
          }
        }
        this.rowDataDomains = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
          // @ts-ignore
          ob.next(response);
          ob.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'getCampaignDomainsStats'); this.ngLoading = false; }
    )
  }

  cellClickedHanlder(node) {
    console.log('cellClickedHanlder: ', node);
    if (node.column.colId === 'impliedStatus' && typeof node.data.impliedStatus !== 'undefined' && node.data.impliedStatus !== null) {
      if (node.data.impliedStatus === 'online' || node.data.impliedStatus === 1) {
        const nodeData = {identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'online', type: node.data.type};
        console.log('node.data.impliedStatus: ', node.data.impliedStatus);
        if (typeof node.data.store !== 'undefined' && node.data.store !== null && node.data.store !== '') {
          nodeData['store'] = node.data.store;
        }
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [nodeData],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignDomainsStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      } else {
        const nodeData = {identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'offline', type: node.data.type};
        console.log('node.data.impliedStatus: ', node.data.impliedStatus);
        if (typeof node.data.store !== 'undefined' && node.data.store !== null && node.data.store !== '') {
          nodeData['store'] = node.data.store;
        }
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [nodeData],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignDomainsStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      }
    }
  }

  onDomainsGridReady(params: any) {
    this.domainsGrid = params.api;
    this.updateColumns();
  }

  refreshGrid() {
    this.currentDateScope = 'one';
    this.getCampaignDomainsStats(this.campaignData.campaignId);
  }

  domainsBulkEdit(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      if (result === 'save' && this.bulkStatusEdit !== '' && this.cpmBidBulkEdit !== '') {
        const selected = this.domainsGrid.getSelectedRows();
        this.campaignService.setInventoryUnitTargets(
          selected.map((item) => ({
            identifier: item.identifier,
            name: item.name,
            locked: item.locked,
            ruleStatus: (this.bulkStatusEdit !== '') ? this.bulkStatusEdit : item.ruleStatus,
            ruleBid: (this.cpmBidBulkEdit !== '') ? this.cpmBidBulkEdit : item.impliedBid,
            type: item.type
          })),
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignDomainsStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  exportDomains() {
    this.domainsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params, 'domains');
      }
    } as CsvExportParams);
  }

  rangeChanged(event: SatDatepickerInputEvent<any>, input: HTMLInputElement = null) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      this.domainsDateRange.begin = this.domainsDateRange.begin.subtract(1, 'days');
      this.domainsDateRange.end = this.domainsDateRange.end.subtract(1, 'days');
      input.value = this.domainsDateRange.begin.format('MM/DD/YYYY') + ' - ' + this.domainsDateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      this.currentDateScope = 'one';
      if (event['value']['extraData'] === 'fromButtonAll') {
        this.currentDateScope = 'all';
        this.refreshDates.emit({'event':  event, 'input': input});
      } else if (savedGridDates !== null && typeof savedGridDates === 'object') {
        savedGridDates = {
          ...savedGridDates,
          domainsDateRange: this.domainsDateRange
        };
      } else {
        savedGridDates = {
          domainsDateRange: this.domainsDateRange
        };
      }
      // savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
      //   ? {
      //     ...savedGridDates,
      //     domainsDateRange: this.domainsDateRange
      //   }
      //   : {
      //     domainsDateRange: this.domainsDateRange
      //   };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
  }

  closedDatesRange() {
    console.log(this.currentDateScope);
    if (this.rangeDatesFlag && this.currentDateScope === 'one') {
      this.refreshGrid();
    } else {
      this.initializeRangeDates();
    }
    this.rangeDatesFlag = false;
  }

  initializeRangeDates() {
    const savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
    if (savedGridDates !== null && typeof savedGridDates === 'object') {
      if (typeof savedGridDates.domainsDateRange !== 'undefined') {
        this.domainsDateRange = {begin: moment(savedGridDates.domainsDateRange.begin), end: moment(savedGridDates.domainsDateRange.end)};
      }
    }
  }

  filterChanged(event: any) {
    this.filtersActive = Object.keys(event.api.getFilterModel()).length > 0;
  }

  updateColumns(): void {
    let visibleColumns = [];
    if (this.columnSetup.length > 0) {
      visibleColumns = this.columnSetup;
    }
    console.log('visibleColumns: ', visibleColumns);
    this.domainsGrid.columnController.columnApi.setColumnsVisible(this.columnSetupDefault, false);
    this.domainsGrid.columnController.columnApi.setColumnsVisible(visibleColumns, true);
    this.domainsGrid.columnController.columnApi.autoSizeAllColumns();
  }

  customizeColumns() {
    const dialogRef = this.dialog.open(GridColumnsCustomizationComponent, {
      maxWidth: '1050px',
      width: '600px',
      disableClose: true,
      data: {currentColumns: this.columnSetup, gridColumns: this.gridColumns},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (typeof result !== 'undefined' && result !== null) {
        // this._reportsService.saveReportColumnsCustomization({name: result.name, columns: result.columns, ad_account_id: this.filterForm.get('adAccountId').value}).subscribe(
        //   response => {
        //     console.log(response);
        //   }, error => console.log(error)
        // );
        localStorage.setItem('domainsCustomColumns', JSON.stringify(result.columns));
        this.columnSetup = result.columns;
        this.updateColumns();
      }
    });
  }

}
