import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {ReportsService} from '../../_services/reports/reports.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {BrandService} from '../../_services/brand/brand.service';
import {GroupService} from '../../_services/group/group.service';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {Observable} from 'rxjs';
import {OneTimeReportsComponent} from '../../pages/one-time-reports/one-time-reports.component';
import {report_type_dimension, report_type_dimension_codes, report_types, report_types_by_entity} from '../../_models/aux-data';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {Domains} from '../../_models/domains';
import {Report} from '../../_models/report';
import * as moment from 'moment';
import {response} from 'express';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-new-one-time-report-modal',
  templateUrl: './new-one-time-report-modal.component.html',
  styleUrls: ['./new-one-time-report-modal.component.scss']
})
export class NewOneTimeReportModalComponent implements OnInit {
  gridApi;
  gridApiConv;
  gridColumnApi;
  _parent: OneTimeReportsComponent;
  @Input() parent;
  @Output() refreshGrid = new EventEmitter<string>();
  editOrUpdate = 'New';
  newScheduleRptForm: FormGroup;
  invoiceForm: FormGroup;
  selectedOptions = [];
  searchGroup = 'campaign';
  previousSelected: any;
  useConvPixels = false;
  archived = false;
  entityGroup = '';
  reportTypes = report_types;
  reportTypeDimension = report_type_dimension;
  reportTypeDimensionCodes = report_type_dimension_codes;
  reportTypesByEntity = report_types_by_entity;
  firstTabActive = true;
  archivedConv = false;
  showConvPixels = false;

  public rowSelection;
  public modules: Module[] = AllModules;
  groupSelectedRows = [];
  public defaultColDef;
  public autoGroupColumnDef;

  columnDefs = [
    {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
    {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
    // {headerName: 'Campaign', field: 'campaign', width: 400 },
    {headerName: 'ID', field: 'campaignId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
  ];
  pixelSelected = [];

  columnDefsPixels = [
    {
      headerName: '',
      field: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
    },
    {headerName: 'Name', field: 'name', width: 400 },
    {headerName: 'ID', field: 'offerId', width: 120},
  ];
  rowData: any;
  rowDataPixels = [];
  auxRowData = [];
  campaignSelected = false;
  invoiceCampaignAds = [];
  richTextShowed = false;
  adsLoading =  false;
  apiUrl = `${environment.apiUrl}`;
  backendUrl = `${environment.backendUrl}`;
  currentToken = localStorage.getItem('token');
  minDate = new Date(2022, 9, 1);
  maxDate = moment().subtract(1, 'days').toDate();

  constructor(private formB: FormBuilder,
              private rptService: ReportsService,
              private alertService: AlertService,
              private auth: CommonAuthService,
              private brandService: BrandService,
              private groupService: GroupService,
              private campaignService: CampaignService,
              private reportsService: ReportsService,
              private conversionService: ConversionService
  ) {
    this.rowSelection = 'multiple';
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
    };
    this.autoGroupColumnDef = {
      headerName: 'Campaign',
      field: 'campaign',
      minWidth: 400,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      }
    };
    this.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
  }

  ngOnInit() {
    this._parent = this.parent;
    this.newScheduleRptForm = this.formB.group({
      name: ['', Validators.required],
      report_type: ['Delivery/Performance Report'],
      dimensions: ['', Validators.required],
      group_by: ['campaign'],
      interval: [''],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      entities: ['', []],
      conversion_pixels: [''],
      pixels: ['', []],
    });
    this.newScheduleRptForm.controls.conversion_pixels.setValue(false);
    this.newScheduleRptForm.controls.report_type.setValue('Delivery/Performance Report');
    // this.loadBrands();
    this.invoiceForm = this.formB.group({
      advertiser: [''],
      order_brand: [''],
      product: [''],
      estimate_number: [''],
      property: [''],
      account_executive: [''],
      sales_office: [''],
      sales_region: [''],
      billing_calendar: [''],
      billing_type: [''],
      special_handling: [''],
      invoice_number: [''],
      order_number: [''],
      alt_order_number: [''],
      deal_number: [''],
      order_flight: [''],
      agency_code: [''],
      advertiser_code: [''],
      product_1_2: [''],
      agency_ref: [''],
      advertiser_ref: [''],
      custom_description: [''],
      custom_start_date: [''],
      custom_end_date: [''],
      gross_total: [''],
      agency_commission: [''],
      net_amount_due: [''],
      remit_address: this.formB.group({
        address: [''],
        address_2: [''],
        city: [''],
        state: [''],
        zip: [''],
        phone: ['']
      }),
      billing_address: this.formB.group({
        address: [''],
        address_2: [''],
        city: [''],
        state: [''],
        zip: [''],
        phone: ['']
      })
    });
  }

  loadConversions() {
    this.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    const allBrandIds = this.gridApi.getSelectedNodes().map(node => node.data.brandId);
    let brandIds = [];
    for (let b of allBrandIds) {
      if (brandIds.indexOf(b) === -1)
        brandIds.push(b);
    }
    if (brandIds.length > 0) {
      this.conversionService.getConversionsFromMultpBrands({
        brandIds: brandIds,
        filter: $('#search_grid_pixels').val().toString(),
        dateTo: '',
        dateFrom: '',
        status: ''
      }).subscribe(
        (response) => {
          console.log(response);
          this.rowDataPixels = Observable.create((obs) => {
            let result = [];
            if (!this.archivedConv) {
              for (let conv of response.response.conversions) {
                if (!conv.deleted) {
                  result.push(conv);
                }
              }
              obs.next(result);
            } else {
              obs.next(response.response.conversions);
            }
            obs.complete();
          });
        },
        (error) => {
          console.log(error)
        }
      );
    }
  }

  selectAllConversions() {
    if ($('#sel_conv_all').html() === 'Select All'){
      this.gridApiConv.selectAll();
      $('#sel_conv_all').html('Unselect All');
    } else {
      this.gridApiConv.deselectAll();
      $('#sel_conv_all').html('Select All');
    }
  }

  onGridReadyConv(event: any) {
    this.gridApiConv = event.api;
  }

  convRowSelected(event: any) {
    console.log('event:', event);
    this.pixelSelected =  this.gridApiConv.getSelectedNodes().map(node => node.data.offerId);
  }

  changeArchivedPixels(event: any) {
    this.archivedConv = !this.archivedConv;
    if (this.archivedConv) {
      this.loadConversions();
    }
  }

  changeReportType() {

  }

  onRowDataUpdated(event: any) {
    if (typeof this.previousSelected !== 'undefined' && this.previousSelected.length > 0) {
      let allNodes = [];
      this.gridApi.forEachNode(function(node) {
        if (typeof node.data !== 'undefined') {
          allNodes.push(node);
        }
      });
      let groupIds = []; let brandIds = [];
      for (let row of this.auxRowData) {
        if (this.previousSelected.indexOf(row.campaignId) !== -1) {
          if (groupIds.indexOf(row.gorupId) !== -1) {
            groupIds.push(row.groupId);
          }
          if (brandIds.indexOf(row.brandId) !== -1) {
            brandIds.push(row.brandId);
          }
        }
      }
      for (let node of allNodes) {
        if (this.searchGroup === 'campaign' && this.previousSelected.indexOf(node.data.campaignId) !== -1) {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        } else if (this.searchGroup === 'group' && groupIds.indexOf(node.data.groupId) !== -1) {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        } else if (this.searchGroup === 'brand' && brandIds.indexOf(node.data.brandId) !== -1)  {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        }
      }
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  groupRowSelected(event: any) {
    console.log(event);
    this.campaignSelected = true;
    const group = $('#entityGroup').val();
    if (group === 'brand') {
      this.groupSelectedRows = [];
      const selNodes = event.api.getSelectedNodes();
      for (let node of selNodes) {
        for (let obj of this.auxRowData) {
          if (node.data.brandId === obj.brandId)
            this.groupSelectedRows.push(obj);
        }
      }
    } else if (group === 'group') {
      this.groupSelectedRows = [];
      const selNodes = event.api.getSelectedNodes();
      for (let node of selNodes) {
        for (let obj of this.auxRowData) {
          if (node.data.groupId === obj.groupId)
            this.groupSelectedRows.push(obj);
        }
      }
    } else {
      this.groupSelectedRows = event.api.getSelectedNodes().map((value, index, array) => value.data);
    }
    console.log(this.groupSelectedRows);
    this.loadConversions();
  }

  onRowClicked(event: any) { console.log('row', event); }
  onCellClicked(event: any) { console.log('cell', event); }

  loadBrands() {
    const search = $('#search_grid').val();
    const params = {
      archived: this.archived,
      search: typeof search !== 'undefined' ? search.toString() : '',
    };
    // let group = $('#entityGroup').val();
    let group = 'campaign';

    if ((typeof group === 'undefined' || group === '') && this.searchGroup !== null && this.searchGroup !== '') {
      group = this.searchGroup;
    }
    if (typeof group !== 'undefined' && group !== '' && group !== 'campaign') {
      if (group === 'brand') {
        console.log('BRAND');
        this.columnDefs = [
          {
            headerName: '',
            field: 'checkboxSelect',
            width: 40,
            checkboxSelection: true,
            sortable: false,
            resizable: false,
          },
          {headerName: 'Brand', field: 'brand', width: 400, checkboxSelection: false, sortable: true, resizable: true },
          {headerName: 'ID', field: 'brandId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
        ];
        this.autoGroupColumnDef = {
          headerName: 'Brand',
          field: 'brand',
          minWidth: 400,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true,
          }
        };
      } else {
        this.columnDefs = [
          {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
          // {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true },
          {headerName: 'ID', field: 'groupId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
        ];
        this.autoGroupColumnDef = {
          headerName: 'Group',
          field: 'group',
          minWidth: 400,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true,
          }
        };
      }
    } else {
      console.log('Campaign');
      this.columnDefs = [
        {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
        {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
        // {headerName: 'Campaign', field: 'campaign', width: 400 },
        {headerName: 'ID', field: 'campaignId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
      ];
    }
    this.reportsService.getBrandsGroupsCampaigns(params).subscribe(
      (response) => {
        console.log(response);
        this.rowData =  Observable.create((obs) => {
          if (group === 'brand') {
            let aux = []; let aux2 = [];
            for (let obj of response.response.brands) {
              if (aux2.indexOf(obj.brandId) === -1) {
                aux.push(obj);
                aux2.push(obj.brandId);
              }
            }
            obs.next(aux);
          } else if (group === 'group') {
            let aux = []; let aux2 = [];
            for (let obj of response.response.brands) {
              if (aux2.indexOf(obj.groupId) === -1) {
                aux.push(obj);
                aux2.push(obj.groupId);
              }
            }
            obs.next(aux);
          } else {
            console.log('Campaign2');
            obs.next(response.response.brands);
          }
          this.auxRowData = response.response.brands;
          obs.complete();
        });
      });
  }

  isRowSelectable(params: any) {
    let group = 'campaign'; //$('#entityGroup').val();
    console.log('group:', group);
    return typeof group !== 'undefined' && group !== '';
  }

  changePixels(event: any) {
    this.showConvPixels = !this.showConvPixels;
    this.loadConversions();
  }

  changeTabEvent(event: any) {
    console.log(this.invoiceCampaignAds);
    if (event.index === 1 && this.groupSelectedRows.length === 0) {
      this.loadBrands();
    }
    if (event.index === 3) {
      this.richTextShowed = true;
      if (this.campaignSelected) {
        console.log('this.groupSelectedRows: ', this.groupSelectedRows);
        const oldSelected = this.invoiceCampaignAds;
        this.invoiceCampaignAds = [];
        for (const selected of this.groupSelectedRows) {
          const pos = oldSelected.findIndex(i => i.campId === selected.campaignId);
          if (pos === -1) {
            this.getCampaignAds(selected.campaignId, selected.campaign);
          } else {
            this.invoiceCampaignAds.push(oldSelected[pos]);
          }
        }
        this.campaignSelected = false;
      }
    } else {
      this.richTextShowed = false;
    }
  }

  changeGroupBy() {
    if (this.newScheduleRptForm.controls.group_by.value !== '') {
      // if ((this.newScheduleRptForm.controls.group_by.value === 'brand' && (this.searchGroup === 'group' || this.searchGroup === 'campaign')) || (this.newScheduleRptForm.controls.group_by.value === 'group' && this.searchGroup === 'campaign')) {
      //   this.rowData = Observable.create((obs) => {
      //     obs.next([]);
      //     obs.complete();
      //   });
      //   this.groupSelectedRows = [];
      // }
      if (this.newScheduleRptForm.controls.group_by.value === 'DATE') {
        this.alertService.alert('warning', 'Only Campaigns and Ads report types have Statistics by Date.', 7000);
      }
    }
  }

  selectAll() {
    if ($('#sel_grid_all').html() === 'Select All') {
      this.gridApi.selectAll();
      $('#sel_grid_all').html('Unselect All');
    } else {
      this.gridApi.deselectAll();
      $('#sel_grid_all').html('Select All');
    }
    if (this.archivedConv) {
      this.loadConversions();
    }
  }

  changeGroup(event: any) {
    console.log('event:', event);
    $('#entityGroup').val(event.value);
    // this.loadBrands();
  }

  changeArchived(event: any) {
    this.archived = !this.archived;
    this.loadBrands();
  }

  onSubmit() {
    if (!this.newScheduleRptForm.valid) {
      console.log(this.newScheduleRptForm.controls);
      this.alertService.alert('error', 'Please complete all required inputs.');
    } else {
      this.newScheduleRptForm.controls.entities.setValue(JSON.stringify(this.groupSelectedRows));
      this.newScheduleRptForm.controls.pixels.setValue(JSON.stringify(this.pixelSelected));
      console.log(this.newScheduleRptForm.controls);

      if (
        (
          this.formControls.dimensions.value === 'DOMAIN_EXCHANGE_PLACEMENT_WITH_HIERARCHY'
          || this.formControls.dimensions.value === 'DOMAIN_EXCHANGE_WITH_HIERARCHY'
          || this.formControls.dimensions.value === 'DOMAIN_WITH_HIERARCHY'
        )
        && moment(this.formControls.start_date.value).add(45, 'days').isBefore(moment(this.formControls.end_date.value))
      ) {
        this.alertService.alert('error', 'Maximum allowed length for report type "Domains > Exchanges > Placements" is 45 days.');
        return;
      }

      const report = {
        description: this.formControls.name.value,
        advertiserId: '',
        entityIds: JSON.parse(this.formControls.entities.value),
        entityType: 'CAMPAIGN', // this.formControls.group_by.value.toUpperCase(),
        conversionPixels: JSON.parse(this.formControls.pixels.value),
        fromDate: this.formControls.start_date.value,
        toDate: this.formControls.end_date.value,
        reportType: this.formControls.dimensions.value,
        aggregation: this.formControls.interval.value,
      };
      if (report.reportType === 'INVOICE') {
        report['invoiceData'] = this.invoiceForm.value;
        report['campAdData'] = this.invoiceCampaignAds;
      }
      console.log(report);
      this.reportsService.saveOneTimeReport(report)
        .subscribe(response => {
          // @ts-ignore
          this.alertService.alert('success', response.response, 7000);
          this.refreshGrid.emit('done');
          this.invoiceCampaignAds = [];
          $('.new-one-time-report-modal').modal('hide');
          console.log(response);
        });

    }
  }

  closeModal() {
    this.invoiceCampaignAds = [];
    $('.new-one-time-report-modal').modal('hide');
  }

  get formControls() {
    return this.newScheduleRptForm.controls;
  }

  getCampaignAds(campId: number, campName: string) {
    this.adsLoading = true;
    this.campaignService.getCampaignAdsStats(
      campId, {
      filter: '',
      dateTo: (this.formControls.end_date.value !== null && this.formControls.end_date.value !== '')
        ? moment(this.formControls.end_date.value).format('YYYYMMDD') : '',
      dateFrom: (this.formControls.start_date.value !== null && this.formControls.start_date.value !== '')
        ? moment(this.formControls.start_date.value).format('YYYYMMDD') : '',
      status: ''
      }
    ).subscribe(
      response => {
        if (typeof response !== 'undefined' && response !== null) {
          const newCampAd = {
            campId: campId,
            campName: campName,
            ads: []
          };
          const pos = this.invoiceCampaignAds.length - 1;
          for (const i in response) {
            const margin = (typeof response[i]['extraData'] !== 'undefined' && response[i]['extraData'].margin > 1) ?
              ((100 - response[i]['extraData'].margin) / 100) : 1;
            newCampAd.ads.push({
              adName: response[i].label,
              adId: response[i].creativeId,
              impressions: response[i].impressionsWon,
              spend: parseFloat(String(response[i].totalSpend / margin)).toFixed(2),
              editor: null,
              selected: false
            });
          }
          this.invoiceCampaignAds.push(newCampAd);
          this.adsLoading = false;
        }
      }, error => console.log(error)
    );
  }

}
