import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BrandCategoriesResponse, BrandCategory,
  BrandResponse,
  BrandsResponse,
  BrandStatsResponse,
  UserResponse,
  UsersResponse
} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, publishReplay, refCount, retry, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Brand} from '../../_models/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  brandCategories: Observable<BrandCategoriesResponse>;
  private brandsUrl = 'brands';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  // tslint:disable-next-line:max-line-length
  getBrands(params = {filter: '', dateTo: '', dateFrom: '', showArchived: false}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<BrandStatsResponse> {
    return this.http.get<BrandStatsResponse>(
      // tslint:disable-next-line:max-line-length
      `${environment.apiUrl}` + this.brandsUrl + '?max=' + max + '&pageSize=1000' + '&offset=' + offset + '&showArchived=' + params.showArchived + '&filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom
    ).pipe(
      retry(3),
      tap(_ => console.log('fetched brands')),
      catchError(Errors.handleError<BrandStatsResponse>('getBrands'))
    );
  }

  getBrandById(id: any) {
    return this.http.get<BrandResponse>(`${environment.apiUrl}` + this.brandsUrl + '/' + id).pipe(
      tap(_ => console.log('fetched brand')),
      catchError(Errors.handleError<BrandResponse>('getBrandById'))
    );
  }

  getBrandClient(id: any) {
    return this.http.get<any>(`${environment.apiUrl}` + this.brandsUrl + '/' + id + '/client').pipe(
      tap(_ => console.log('fetched brand client')),
      catchError(Errors.handleError<any>('getBrandClient'))
    );
  }

  createBrand(brand: Brand): Observable<BrandResponse> {
    return this.http.post<BrandResponse>(`${environment.apiUrl}` + this.brandsUrl, brand, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addBrand', brand))
      ) as Observable<BrandResponse>;
  }

  updateBrand(brand: Brand, id: number): Observable<BrandResponse>  {
    return this.http.put<BrandResponse>(`${environment.apiUrl}` + this.brandsUrl + '/' + id, brand, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateUser', brand))
      ) as Observable<BrandResponse>;
  }

  deleteBrand(id: string) {
    return this.http.delete<BrandResponse>(`${environment.apiUrl}` + this.brandsUrl + '/' + id).pipe(
      tap(_ => console.log('deleted brand')),
      catchError(Errors.handleError<BrandResponse>('deleteBrand'))
    );
  }

  getBrandCategories() {
    if (!this.brandCategories) {
      this.brandCategories = this.http.get<BrandCategoriesResponse>(`${environment.apiUrl}` + this.brandsUrl + '-categories').pipe(
        tap(_ => console.log('fetched brand')),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        catchError(Errors.handleError<BrandCategoriesResponse>('getBrandCategories'))
      );
    }
    return this.brandCategories;
  }

  // Clear cachessss
  clearCache() {
    this.brandCategories = null;
  }
}
