import {Component, OnInit, ViewChild} from '@angular/core';
import {NewOneTimeReportModalComponent} from '../../modals/new-one-time-report-modal/new-one-time-report-modal.component';
import {Observable} from 'rxjs';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import {ReportsService} from '../../_services/reports/reports.service';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-one-time-reports',
  templateUrl: './one-time-reports.component.html',
  styleUrls: ['./one-time-reports.component.scss']
})
export class OneTimeReportsComponent implements OnInit {

  @ViewChild(NewOneTimeReportModalComponent, null) newReportModal: NewOneTimeReportModalComponent;
  columnDefsOnce = [
    {
      headerName: '',
      field: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
    },
    {headerName: 'Report', field: 'name', width: 500 },
    // {headerName: 'Actions', field: 'actions', width: 100 },
    {headerName: 'Created On', field: 'created_at', width: 150 },
    // {headerName: 'Expires On', field: 'expires_at', width: 150},
    {headerName: 'Status', field: 'status', width: 100 },
    {headerName: 'PDF', field: 'url', width: 70, cellRenderer: (param) => (param.node.data.reportType === 'INVOICE' && param.value !== null && param.value !== '') ? '<a target="_blank" href="' + param.value + '"><i class="ti-download"></i></a>' : ''},
    {headerName: 'Excel', field: 'url', width: 70, cellRenderer: (param) => (param.node.data.reportType !== 'INVOICE' && param.value !== null && param.value !== '') ? '<a target="_blank" href="' + param.value + '"><i class="ti-download"></i></a>' : ''},
    {headerName: 'CSV', field: 'csv_url', width: 70, cellRenderer: (param) => (param.node.data.reportType !== 'INVOICE' && param.value !== null && param.value !== '') ? '<a target="_blank" href="' + param.value + '"><i class="ti-download"></i></a>' : ''},
  ];
  rowDataOnce: any;
  title = 'once';
  selectedOption2: string;
  filter = '';
  public grid: any;

  constructor(private breadcrumbsService: BreadcrumbsService, private reportService: ReportsService, private alertService: AlertService) { }

  ngOnInit() {
    this.selectedOption2 = 'all';
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'One Time Reports', url: '/one-time-reports', params: []},
    ];
    this.refreshGrid(null);
  }

  refreshGrid(event: any) {
    this.reportService.getOneTimeReports({status: this.selectedOption2, filter: this.filter}).subscribe(
      response => {
        console.log(response);
        this.rowDataOnce =  Observable.create((obs) => {
          obs.next(response.response.reports);
          obs.complete();
        });
      }, error => console.log(error, 'getOneTimeReports')
    );
  }

  openNewRptModal() {
    $('#search_grid').val('');
    $('#search_grid_pixels').val('');
    this.newReportModal.newScheduleRptForm.reset();
    this.newReportModal.searchGroup = '';
    this.newReportModal.rowData = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.newReportModal.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.newReportModal.groupSelectedRows = [];
    this.newReportModal.pixelSelected = [];
    this.newReportModal.showConvPixels = false;
    $('.new-one-time-report-modal').modal('show');
  }

  deleteReports() {
    console.log(this.grid.getSelectedRows())
    const rows = this.grid.getSelectedRows();
    if (rows.length > 0) {
      this.reportService.deleteReport(rows.map((item) => item.id)).subscribe(
        response => {
          this.alertService.alert('success', response.response);
          this.refreshGrid(null);
        }, error => console.log(error, 'deleteReport')
      )
    }
  }

  gridSetup(params: any) {
    this.grid = params.api;
  }
}
