import {Component, OnInit, ViewChild} from '@angular/core';
import {Moment} from 'moment';
import * as  moment from 'moment';
import * as Highcharts from 'highcharts';
import {Observable} from 'rxjs';
import {CampaignGroup} from '../../_models/campaignGroup';
import {NewGroupModalComponent} from '../../modals/new-group-modal/new-group-modal.component';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {AdReviewComponent} from '../../shared/ad-review/ad-review.component';
import {BrandService} from '../../_services/brand/brand.service';
import {GroupService} from '../../_services/group/group.service';
import {AlertService} from '../../_services/alert.service';
import {AdService} from '../../_services/ad/ad.service';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {UniversalPixelsService} from '../../_services/universal-pixels/universal-pixels.service';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import {RangesDatepickerComponent} from '../../shared/ranges-datepicker/ranges-datepicker.component';
import {Campaign} from '../../_models/campaign';
import {NewBrandModalComponent} from '../../modals/new-brand-modal/new-brand-modal.component';
import {AudiencesRulesComponent} from '../../shared/audiences-rules/audiences-rules.component';
import {ContextualBulkComponent} from '../../shared/contextual-bulk/contextual-bulk.component';
import {ConversionsBulkComponent} from '../../shared/conversions-bulk/conversions-bulk.component';
import {DayPartingBulkComponent} from '../../shared/day-parting-bulk/day-parting-bulk.component';
import {DomainListBulkComponent} from '../../shared/domain-list-bulk/domain-list-bulk.component';
import {LocationsBulkComponent} from '../../shared/locations-bulk/locations-bulk.component';
import {BrandProtectionBulkComponent} from '../../shared/brand-protection-bulk/brand-protection-bulk.component';
import {CampaignNameBulkComponent} from '../../shared/campaign-name-bulk/campaign-name-bulk.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CsvExportParams} from 'ag-grid-community';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
  brandFilterString = '';
  brandDateRange: any = {begin: moment(), end: moment()};
  columnDefsCamp = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Actions',
      field: 'campaignId',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Campaign' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 90, filter: true,
      cellRenderer: 'statusCellRendererComponent',
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 250, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Type', field: 'campaignType', width: 100, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'CPM Bid', field: 'defaultBid', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Daily Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'dailyBudget'}, pinnedRowCellRenderer: null },
    {headerName: 'All Time Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'totalBudget'}, pinnedRowCellRenderer: null },
    {headerName: 'Start Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'startDate'}, pinnedRowCellRenderer: null },
    {headerName: 'End Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'endDate'}, pinnedRowCellRenderer: null },
    {headerName: 'Total Spend', field: 'totalSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'KPI Metric', field: '', width: 100 },
    {headerName: 'KPI Value', field: '', width: 100 },
    {headerName: 'Adv. Spend', field: 'advertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Imps.', field: 'impressionsWon', width: 100 },
    {headerName: 'Clicks', field: 'clicks', width: 100 },
    {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 100 },
    {headerName: 'Primary Conv.', field: 'conversions', width: 100, cellRenderer: (params) => params.node.data.clickThroughConversions +  params.node.data.viewThroughConversions },
    {headerName: 'Total Eligible Imps.', field: 'eligibleImpressions', width: 100 },
    {headerName: 'Total Viewable Imps.', field: 'viewableImpressions', width: 100 },
    {headerName: 'Measured Rate', field: '', width: 100 },
    {headerName: 'Viewable Rate', field: '', width: 100 },
    {headerName: 'Media Spend', field: 'auctionsSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Data Spend', field: 'dataSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Win Rate', field: 'winRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Adv. Spend CPM', field: 'effectiveCPMOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPC', field: 'effectiveCPCOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPC', field: 'costPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Media CPM', field: 'mediaEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Data CPM', field: 'dataEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTR', field: 'clickthruRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'VTC', field: 'viewthruConversions', width: 100 },
    {headerName: 'CTC', field: 'clickThroughConversions', width: 100 },
    {headerName: 'Primary CTC', field: '', width: 100 },
    {headerName: 'Primary VTC', field: '', width: 100 },
    {headerName: 'Total Revenue', field: 'revenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTC Revenue', field: 'ctcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'VTC Revenue', field: 'vtcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPA', field: 'effectiveCPAOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. Adv. Spend CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPA', field: 'costPerAcquisition', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. COnv. CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Primary CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click CVR', field: 'clickCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'View CVR', field: 'viewCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total CVRM', field: 'totalCVRM', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'EPC', field: 'profitPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total RPM', field: 'revenuePerMille', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click RPM', field: 'clickRPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'ROAS', field: 'returnOnAdSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Audio/Video Starts', field: 'videoStarted', width: 100 },
    {headerName: '25% Complete', field: 'videoFirstQuartileReached', width: 100 },
    {headerName: '50% Complete', field: 'videoMidpointReached', width: 100 },
    {headerName: '75% Complete', field: 'videoThirdQuartileReached', width: 100 },
    {headerName: '100% Complete', field: 'videoCompleted', width: 100 },
    {headerName: 'Adv. Spend CPCV', field: 'effectiveCPCVOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPCV', field: 'totalEffectiveCPCV', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Eligible Spend', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Eligible CPM', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Companion Imps.', field: 'companionImpressions', width: 100 },
    {headerName: 'Companion Clicks', field: 'companionClicks', width: 100 },
    {headerName: 'Companion CTC', field: 'companionConversions', width: 100 },
    {headerName: 'Companion CTCR', field: 'companionOfferRevenue', width: 100 }
  ];
  rowDataCamp: Observable<Array<Campaign>>;
  defaultCampsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' }
  };
  pinnedCampsRowData: any;
  @ViewChild(NewBrandModalComponent, null) newBrandModal: NewBrandModalComponent;
  @ViewChild(AudiencesRulesComponent, null) audRulesModal: AudiencesRulesComponent;
  @ViewChild(ContextualBulkComponent, null) contextualBulkRulesModal: ContextualBulkComponent;
  @ViewChild(ConversionsBulkComponent, null) conversionsBulkModal: ConversionsBulkComponent;
  @ViewChild(DayPartingBulkComponent, null) dayPartingBulkModal: DayPartingBulkComponent;
  @ViewChild(DomainListBulkComponent, null) domainListBulkModal: DomainListBulkComponent;
  @ViewChild(LocationsBulkComponent, null) locationsBulkModal: LocationsBulkComponent;
  @ViewChild(BrandProtectionBulkComponent, null) brandProtectionBulkModal: BrandProtectionBulkComponent;
  @ViewChild(CampaignNameBulkComponent, null) campaignNameBulkModal: CampaignNameBulkComponent;
  campDateRange: any;
  campFilterString = '';
  campaignIdSelected = false;
  groupId: number;
  brandId: number;
  campIdSelected: number;
  campSelectedRows = [];
  campaignGrid: any;
  locationsBulkType = 'add';

  ngLoading = false;
  columnDefsGroups = [
    {
      headerName: 'Actions',
      field: 'campaignGroupId',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Campaign Group', brandId: '' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 90, filter: true,
      cellRenderer: 'statusCellRendererComponent',
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 300, pinnedRowCellRenderer: null, pinnedRowCellRendererParams: null, cellRenderer: null },
    {headerName: 'Brand Name', field: 'brandName', width: 300, pinnedRowCellRenderer: null, pinnedRowCellRendererParams: null, cellRenderer: null },
    {headerName: 'Days Remaining', field: 'daysRemaining', width: 80 },
    {headerName: 'Start Date', field: 'budget', width: 80, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'startDate'} },
    {headerName: 'End Date', field: 'budget', width: 80, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'endDate'} },
    {headerName: 'OSI %', field: 'totalSpendOnScheduleIndicator', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'KPI %', field: 'keyPerformanceIndicatorOnTotalSpend', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Req. Daily Total Spend', field: 'dailyTotalSpendTarget', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Yesterday Total Spend', field: 'yesterdaySpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Yesterday Total Add-on Spend', field: 'nonBillableSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total Budget', field: 'budget', width: 80, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'totalBudget'}, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Imps. Won', field: 'impressionsWon', width: 80 },
    {headerName: 'Clicks', field: 'clicks', width: 80 },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 80 },
    {headerName: 'Actual Margin %', field: 'marginOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } }
  ];
  rowDataGroups: Observable<Array<CampaignGroup>>;
  pinnedGroupsRowData: any = [];
  @ViewChild(NewGroupModalComponent, null) newGroupModal: NewGroupModalComponent;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent,
    adReviewComponent: AdReviewComponent
  };
  compParent = {componentParent: this};
  groupFilterString = '';
  groupDateRange: any;

  title = 'campaigns';

  selectedOption = '';
  selectedOption2 = '';
  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    title: {
      text: 'Daily Total Spend'
    },
    series: [{
      data: [],
      type: 'line',
      name: 'Daily Total Spend',
    }, {
      data: [],
      type: 'line',
      name: 'Required Daily Total Spend',
    }],
    xAxis: {
      categories: [],
    }
  };
  groupSelectedRows = [];
  groupSelectedRowNode = [];
  updateFlag = false;

  defaultBrandsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' }
  };
  rangesFooter = RangesDatepickerComponent;
  groupsGrid: any;
  archivedShow = true;

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  constructor(
    private brandService: BrandService,
    private groupService: GroupService,
    private alertService: AlertService,
    private campaignService: CampaignService,
    private router: Router,
    private route: ActivatedRoute,
    private gridRender: GridRenderService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.alwaysShowCalendars = true;
  }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'Performance', url: '/performance', params: []},
    ];
    this.selectedOption = 'online&activeOnly=true';
    this.selectedOption2 = 'total_spend';
    this.rowDataGroups = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataCamp =  Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.getGroups({
      filter: this.groupFilterString,
      // tslint:disable-next-line:max-line-length
      dateTo: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.end.format('YYYYMMDD') : '',
      // tslint:disable-next-line:max-line-length
      dateFrom: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.begin.format('YYYYMMDD') : '',
      status: this.selectedOption
    })
  }

  campaignRowSelected(event: any) {
    console.log(event);
  }

  refreshGrid(type = 'group', status = 'online,offline') {
    console.log('REFRESHING GRID: ', type);
    if (type === 'group') {
      this.groupSelectedRows = [];
      this.groupSelectedRowNode = [];
      // @ts-ignore
      this.getGroups({
        filter: this.groupFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.begin.format('YYYYMMDD') : '',
        status: this.selectedOption
      })
    } else if (type === 'campaign') {
      if (this.groupSelectedRowNode.length > 0) {
        this.getCampaigns(this.groupSelectedRows[0], {
          filter: this.campFilterString,
          dateTo: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.end.format('YYYYMMDD') : '',
          dateFrom: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.begin.format('YYYYMMDD') : '',
          status: status
        });
      } else {
        this.rowDataCamp =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
      }
    }
  }

  groupRowSelected(event: any) {
    this.groupSelectedRows = event.api.getSelectedRows().map((value, index, array) => value.campaignGroupId);
    this.groupSelectedRowNode = event.api.getSelectedRows().map((value, index, array) => value);
    console.log(this.groupSelectedRows);
    if (this.groupSelectedRows.length > 0) {
      const daily = (this.groupSelectedRowNode[0].dailyTotalSpendTarget > 0 ? ((this.groupSelectedRowNode[0].yesterdaySpend / this.groupSelectedRowNode[0].dailyTotalSpendTarget) * 100).toFixed(2) : 0);
      const total = this.groupSelectedRowNode[0].budget.amount > 0 ? ((this.groupSelectedRowNode[0].totalSpend / this.groupSelectedRowNode[0].budget.amount) * 100).toFixed(2) : 0;
      $('.daily-spend-bar').css('width', daily > 100 ? '100%' : daily + '%');
      $('.total-spend-bar').css('width', total > 100 ? '100%' : total + '%');
      const kpiToDate = (typeof this.groupSelectedRowNode[0].keyPerformanceIndicatorOnTotalSpend !== 'undefined') ? this.groupSelectedRowNode[0].keyPerformanceIndicatorOnTotalSpend * 100 : 0;
      $('.kpi-to-date').css('width', kpiToDate > 100 ? '100%' : kpiToDate + '%');
      $('.total-spend-bar').css('width', total > 100 ? '100%' : total + '%');
      this.getCampaigns(this.groupSelectedRows[0], {
        filter: this.campFilterString,
        dateTo: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.end.format('YYYYMMDD') : '',
        dateFrom: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.begin.format('YYYYMMDD') : '',
        status: 'online,offline'
      });
      let series = []; let required = [];
      let categories = [];
      console.log(this.groupSelectedRowNode[0]);
      for (let stat of this.groupSelectedRowNode[0].statsList) {
        series.push(stat.stats.totalSpend);
        categories.push(stat.date.substr(5, 2) + '/' + stat.date.substr(8, 2) + '/' + stat.date.substr(2, 2));
        if (this.groupSelectedRowNode[0].status === 'online') {
          required.push(this.groupSelectedRowNode[0].dailyTotalSpendTarget);
        }
      }
      this.chartOptions.series[0] = {
        data: series,
        type: 'line',
        name: 'Daily Total Spend'
      };
      this.chartOptions.series[1] = {
        data: required,
        type: 'line',
        name: 'Required Daily Total Spend',
      };
      this.chartOptions.xAxis = {
        categories: categories,
      };
      this.updateFlag = true;
    }
  }

  editNewGroupModal(node) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.newGroupModal.loadGroup(node.data as CampaignGroup);
      $('.new-group-modal').modal({backdrop: 'static', keyboard: false});
      $('.new-group-modal').modal('show');
    }
  }

  updateStatusGroupModal(data) {
    this.newGroupModal.loadGroup(data as CampaignGroup);
    this.newGroupModal.onSubmit();
  }

  stringSearch(event: KeyboardEvent, type: string) {
    // if (type === 'brand') {
    //   // @ts-ignore
    //   this.getBrands({filter: event.target.value, dateTo: '', dateFrom: ''})
    // }
  }

  rangeChanged(event: SatDatepickerInputEvent<unknown>, type: string) {
    // if (type === 'brand') {
    //   // @ts-ignore
    //   this.getBrands({filter: '', dateTo: event.value.end.format('YYYYMMDD'), dateFrom: event.value.begin.format('YYYYMMDD')})
    // }
  }

  stringSearch2(event: KeyboardEvent, group: string) {

  }

  rangeChanged2(event: SatDatepickerInputEvent<unknown>, group: string) {

  }

  editNewCampaignModal(node) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.router.navigate(['/campaigns/' + this.groupSelectedRowNode[0].brandId + '/' + this.groupSelectedRowNode[0].campaignGroupId + '/edit-campaign/' + node.data.campaignId + '/advance']);
    }
  }

  getCampaigns(groupId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    this.ngLoading = true;
    this.campaignService.getCampaigns(groupId, params).subscribe(
      (response) => {
        this.rowDataCamp =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataCamp =  Observable.create((obs) => {
          obs.next(response.response.campaigns);
          obs.complete();
        });
        const pinnedData = {
          totalSpend: 0,
          advertiserSpendRate: 0,
          advertiserSpend: 0,
          impressionsWon: 0,
          totalEffectiveCPM: 0,
          videoCompletionRate: 0,
          winRate: 0,
          clicks: 0,
          costPerClick: 0,
          clickThroughRate: 0,
          totalConversions: 0,
          conversions: 0,
          eligibleImpressions: 0,
          measuredImpressions: 0,
          viewableImpressions: 0,
          marginOnAdvertiserSpend: 0,
          dataSpend: 0,
          effectiveCPMOnAdvertiserSpend: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          mediaEffectiveCPM: 0,
          dataEffectiveCPM: 0,
          clickThroughConversions: 0,
          clickRPM: 0,
          viewthruConversions: 0,
          viewCVR: 0,
          revenue: 0,
          ctcRevenue: 0,
          vtcRevenue: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          totalCVRM: 0,
          profitPerClick: 0,
          revenuePerMille: 0,
          returnOnAdSpend: 0,
          videoStarted: 0,
          videoFirstQuartileReached: 0,
          videoMidpointReached: 0,
          videoThirdQuartileReached: 0,
          videoCompleted: 0,
          effectiveCPCVOnAdvertiserSpend: 0,
          totalEffectiveCPCV: 0,
          companionImpressions: 0,
          companionClicks: 0,
          companionConversions: 0,
          companionOfferRevenue: 0
        };
        this.pinnedCampsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        if (typeof response.response.campaigns !== 'undefined') {
          for (const i in response.response.campaigns) {
            // const margin = (typeof response.response.campaigns[i]['extraData'] !== 'undefined' && response.response.campaigns[i]['extraData'].margin > 1) ?
            //   ((100 - response.response.campaigns[i]['extraData'].margin) / 100) : 1;
            const margin = 1;
            pinnedData.totalSpend += isNaN(response.response.campaigns[i].totalSpend) ? 0 : response.response.campaigns[i].totalSpend / margin;
            response.response.campaigns[i].totalSpend = isNaN(response.response.campaigns[i].totalSpend) ? 0 : response.response.campaigns[i].totalSpend / margin;
            response.response.campaigns[i].defaultBid = isNaN(response.response.campaigns[i].defaultBid) ? 0 : response.response.campaigns[i].defaultBid / margin;
            if (typeof response.response.campaigns[i].budget !== 'undefined' && typeof response.response.campaigns[i].budget.amount !== 'undefined') {
              // response.response.campaigns[i].budget.amount = isNaN(response.response.campaigns[i].budget.amount) ? 0 : response.response.campaigns[i].budget.amount / margin;
              response.response.campaigns[i].budget.amount = isNaN(response.response.campaigns[i].budget.amount) ? 0 : response.response.campaigns[i].budget.amount /
                ((typeof response.response.campaigns[i]['extraData'] !== 'undefined' && response.response.campaigns[i]['extraData'].margin > 1) ?
                  ((100 - response.response.campaigns[i]['extraData'].margin) / 100) : 1);
            }
            // pinnedData.defaultBid += isNaN(response.response.campaigns[i].defaultBid) ? 0 : response.response.campaigns[i].defaultBid;
            pinnedData.advertiserSpendRate += isNaN(response.response.campaigns[i].advertiserSpendRate) ? 0 : response.response.campaigns[i].advertiserSpendRate;
            pinnedData.advertiserSpend += isNaN(response.response.campaigns[i].advertiserSpend) ? 0 : response.response.campaigns[i].advertiserSpend / margin
            response.response.campaigns[i].advertiserSpend = isNaN(response.response.campaigns[i].advertiserSpend) ? 0 : response.response.campaigns[i].advertiserSpend / margin
            pinnedData.impressionsWon += isNaN(response.response.campaigns[i].impressionsWon) ? 0 : response.response.campaigns[i].impressionsWon;
            pinnedData.totalEffectiveCPM += isNaN(response.response.campaigns[i].totalEffectiveCPM) ? 0 : response.response.campaigns[i].totalEffectiveCPM;
            pinnedData.videoCompletionRate += isNaN(response.response.campaigns[i].videoCompletionRate) ? 0 : response.response.campaigns[i].videoCompletionRate;
            pinnedData.winRate += isNaN(response.response.campaigns[i].winRate) ? 0 : response.response.campaigns[i].winRate;
            pinnedData.clicks += isNaN(response.response.campaigns[i].clicks) ? 0 : response.response.campaigns[i].clicks;
            pinnedData.costPerClick += isNaN(response.response.campaigns[i].costPerClick) ? 0 : response.response.campaigns[i].costPerClick;
            pinnedData.clickThroughRate += isNaN(response.response.campaigns[i].clickThroughRate) ? 0 : response.response.campaigns[i].clickThroughRate;
            pinnedData.totalConversions += isNaN(response.response.campaigns[i].totalConversions) ? 0 : response.response.campaigns[i].totalConversions;
            pinnedData.conversions += isNaN(response.response.campaigns[i].conversions) ? 0 : response.response.campaigns[i].conversions;
            pinnedData.eligibleImpressions += isNaN(response.response.campaigns[i].eligibleImpressions) ? 0 : response.response.campaigns[i].eligibleImpressions;
            pinnedData.measuredImpressions += isNaN(response.response.campaigns[i].measuredImpressions) ? 0 : response.response.campaigns[i].measuredImpressions;
            pinnedData.viewableImpressions += isNaN(response.response.campaigns[i].viewableImpressions) ? 0 : response.response.campaigns[i].viewableImpressions;
            pinnedData.marginOnAdvertiserSpend += isNaN(response.response.campaigns[i].marginOnAdvertiserSpend) ? 0 : response.response.campaigns[i].marginOnAdvertiserSpend;
            pinnedData.dataSpend += isNaN(response.response.campaigns[i].dataSpend) ? 0 : response.response.campaigns[i].dataSpend;
            pinnedData.effectiveCPMOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPMOnAdvertiserSpend;
            pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCOnAdvertiserSpend;
            pinnedData.mediaEffectiveCPM += isNaN(response.response.campaigns[i].mediaEffectiveCPM) ? 0 : response.response.campaigns[i].mediaEffectiveCPM;
            pinnedData.dataEffectiveCPM += isNaN(response.response.campaigns[i].dataEffectiveCPM) ? 0 : response.response.campaigns[i].dataEffectiveCPM;
            pinnedData.clickThroughConversions += isNaN(response.response.campaigns[i].clickThroughConversions) ? 0 : response.response.campaigns[i].clickThroughConversions;
            pinnedData.clickRPM += isNaN(response.response.campaigns[i].clickRPM) ? 0 : response.response.campaigns[i].clickRPM;
            pinnedData.viewthruConversions += isNaN(response.response.campaigns[i].viewthruConversions) ? 0 : response.response.campaigns[i].viewthruConversions;
            pinnedData.viewCVR += isNaN(response.response.campaigns[i].viewCVR) ? 0 : response.response.campaigns[i].viewCVR;
            pinnedData.revenue += isNaN(response.response.campaigns[i].revenue) ? 0 : response.response.campaigns[i].revenue;
            pinnedData.ctcRevenue += isNaN(response.response.campaigns[i].ctcRevenue) ? 0 : response.response.campaigns[i].ctcRevenue;
            pinnedData.vtcRevenue += isNaN(response.response.campaigns[i].vtcRevenue) ? 0 : response.response.campaigns[i].vtcRevenue;
            pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPAOnAdvertiserSpend;
            pinnedData.viewCVR += isNaN(response.response.campaigns[i].viewCVR) ? 0 : response.response.campaigns[i].viewCVR;
            pinnedData.totalCVRM += isNaN(response.response.campaigns[i].totalCVRM) ? 0 : response.response.campaigns[i].totalCVRM;
            pinnedData.profitPerClick += isNaN(response.response.campaigns[i].profitPerClick) ? 0 : response.response.campaigns[i].profitPerClick;
            pinnedData.revenuePerMille += isNaN(response.response.campaigns[i].revenuePerMille) ? 0 : response.response.campaigns[i].revenuePerMille;
            pinnedData.clickRPM += isNaN(response.response.campaigns[i].clickRPM) ? 0 : response.response.campaigns[i].clickRPM;
            pinnedData.returnOnAdSpend += isNaN(response.response.campaigns[i].returnOnAdSpend) ? 0 : response.response.campaigns[i].returnOnAdSpend;
            pinnedData.videoStarted += isNaN(response.response.campaigns[i].videoStarted) ? 0 : response.response.campaigns[i].videoStarted;
            pinnedData.videoFirstQuartileReached += isNaN(response.response.campaigns[i].videoFirstQuartileReached) ? 0 : response.response.campaigns[i].videoFirstQuartileReached;
            pinnedData.videoMidpointReached += isNaN(response.response.campaigns[i].videoMidpointReached) ? 0 : response.response.campaigns[i].videoMidpointReached;
            pinnedData.videoThirdQuartileReached += isNaN(response.response.campaigns[i].videoThirdQuartileReached) ? 0 : response.response.campaigns[i].videoThirdQuartileReached;
            pinnedData.videoCompleted += isNaN(response.response.campaigns[i].videoCompleted) ? 0 : response.response.campaigns[i].videoCompleted;
            // tslint:disable-next-line:max-line-length
            pinnedData.effectiveCPCVOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend;
            pinnedData.totalEffectiveCPCV += isNaN(response.response.campaigns[i].totalEffectiveCPCV) ? 0 : response.response.campaigns[i].totalEffectiveCPCV;
            pinnedData.companionImpressions += isNaN(response.response.campaigns[i].companionImpressions) ? 0 : response.response.campaigns[i].companionImpressions;
            pinnedData.companionClicks += isNaN(response.response.campaigns[i].companionClicks) ? 0 : response.response.campaigns[i].companionClicks;
            pinnedData.companionConversions += isNaN(response.response.campaigns[i].companionConversions) ? 0 : response.response.campaigns[i].companionConversions;
            pinnedData.companionOfferRevenue += isNaN(response.response.campaigns[i].companionOfferRevenue) ? 0 : response.response.campaigns[i].companionOfferRevenue;
          }
        }
        this.pinnedCampsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    )
  }

  getGroups(params = {filter: '', dateTo: '', dateFrom: '', status: 'online '}) {
    this.ngLoading = true;
    this.groupService.getActivePacingGroups(params).subscribe(
      (response) => {
        console.log(response);
        this.rowDataGroups =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataCamp =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataGroups =  Observable.create((obs) => {
          obs.next(response.response.campaignGroups);
          obs.complete();
        });
        const pinnedData = {
          totalSpend: 0,
          advertiserSpendRate: 0,
          advertiserSpend: 0,
          impressionsWon: 0,
          totalEffectiveCPM: 0,
          videoCompletionRate: 0,
          winRate: 0,
          clicks: 0,
          costPerClick: 0,
          clickThroughRate: 0,
          totalConversions: 0,
          conversions: 0,
          eligibleImpressions: 0,
          measuredImpressions: 0,
          viewableImpressions: 0,
          marginOnAdvertiserSpend: 0,
          dataSpend: 0,
          effectiveCPMOnAdvertiserSpend: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          mediaEffectiveCPM: 0,
          dataEffectiveCPM: 0,
          clickThroughConversions: 0,
          clickRPM: 0,
          viewthruConversions: 0,
          viewCVR: 0,
          revenue: 0,
          ctcRevenue: 0,
          vtcRevenue: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          totalCVRM: 0,
          profitPerClick: 0,
          revenuePerMille: 0,
          returnOnAdSpend: 0,
          videoStarted: 0,
          videoFirstQuartileReached: 0,
          videoMidpointReached: 0,
          videoThirdQuartileReached: 0,
          videoCompleted: 0,
          effectiveCPCVOnAdvertiserSpend: 0,
          totalEffectiveCPCV: 0,
          companionImpressions: 0,
          companionClicks: 0,
          companionConversions: 0,
          companionOfferRevenue: 0
        };
        this.pinnedGroupsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        for (const i in response.response.campaignGroups) {
          // const margin = (typeof response.response.campaignGroups[i]['extraData'] !== 'undefined' && response.response.campaignGroups[i]['extraData'].margin > 1) ?
          //   ((100 - response.response.campaignGroups[i]['extraData'].margin) / 100) : 1;

          const margin = 1;

          pinnedData.totalSpend += isNaN(response.response.campaignGroups[i].totalSpend) ? 0 : response.response.campaignGroups[i].totalSpend / margin;
          response.response.campaignGroups[i].totalSpend = isNaN(response.response.campaignGroups[i].totalSpend) ? 0 : response.response.campaignGroups[i].totalSpend / margin;
          response.response.campaignGroups[i].dailyTotalSpendTarget = isNaN(response.response.campaignGroups[i].dailyTotalSpendTarget) ? 0 : response.response.campaignGroups[i].dailyTotalSpendTarget / margin;
          response.response.campaignGroups[i].yesterdaySpend = isNaN(response.response.campaignGroups[i].yesterdaySpend) ? 0 : response.response.campaignGroups[i].yesterdaySpend / margin;
          response.response.campaignGroups[i].nonBillableSpend = isNaN(response.response.campaignGroups[i].nonBillableSpend) ? 0 : response.response.campaignGroups[i].nonBillableSpend / margin;
          // response.response.campaignGroups[i].budget.amount = isNaN(response.response.campaignGroups[i].budget.amount) ? 0 : response.response.campaignGroups[i].budget.amount / margin;
          response.response.campaignGroups[i].budget.amount = isNaN(response.response.campaignGroups[i].budget.amount) ? 0 : response.response.campaignGroups[i].budget.amount /
            ((typeof response.response.campaignGroups[i]['extraData'] !== 'undefined' && response.response.campaignGroups[i]['extraData'].margin > 1) ?
            ((100 - response.response.campaignGroups[i]['extraData'].margin) / 100) : 1);
          pinnedData.advertiserSpendRate += isNaN(response.response.campaignGroups[i].advertiserSpendRate) ? 0 : response.response.campaignGroups[i].advertiserSpendRate;
          pinnedData.advertiserSpend += isNaN(response.response.campaignGroups[i].advertiserSpend) ? 0 : response.response.campaignGroups[i].advertiserSpend / margin;
          response.response.campaignGroups[i].advertiserSpend = isNaN(response.response.campaignGroups[i].advertiserSpend) ? 0 : response.response.campaignGroups[i].advertiserSpend / margin;
          pinnedData.impressionsWon += isNaN(response.response.campaignGroups[i].impressionsWon) ? 0 : response.response.campaignGroups[i].impressionsWon;
          pinnedData.totalEffectiveCPM += isNaN(response.response.campaignGroups[i].totalEffectiveCPM) ? 0 : response.response.campaignGroups[i].totalEffectiveCPM / margin;
          response.response.campaignGroups[i].totalEffectiveCPM = isNaN(response.response.campaignGroups[i].totalEffectiveCPM) ? 0 : response.response.campaignGroups[i].totalEffectiveCPM / margin;
          pinnedData.videoCompletionRate += isNaN(response.response.campaignGroups[i].videoCompletionRate) ? 0 : response.response.campaignGroups[i].videoCompletionRate;
          pinnedData.winRate += isNaN(response.response.campaignGroups[i].winRate) ? 0 : response.response.campaignGroups[i].winRate;
          pinnedData.clicks += isNaN(response.response.campaignGroups[i].clicks) ? 0 : response.response.campaignGroups[i].clicks;
          pinnedData.costPerClick += isNaN(response.response.campaignGroups[i].costPerClick) ? 0 : response.response.campaignGroups[i].costPerClick;
          pinnedData.clickThroughRate += isNaN(response.response.campaignGroups[i].clickThroughRate) ? 0 : response.response.campaignGroups[i].clickThroughRate;
          pinnedData.totalConversions += isNaN(response.response.campaignGroups[i].totalConversions) ? 0 : response.response.campaignGroups[i].totalConversions;
          pinnedData.conversions += isNaN(response.response.campaignGroups[i].conversions) ? 0 : response.response.campaignGroups[i].conversions;
          pinnedData.eligibleImpressions += isNaN(response.response.campaignGroups[i].eligibleImpressions) ? 0 : response.response.campaignGroups[i].eligibleImpressions;
          pinnedData.measuredImpressions += isNaN(response.response.campaignGroups[i].measuredImpressions) ? 0 : response.response.campaignGroups[i].measuredImpressions;
          pinnedData.viewableImpressions += isNaN(response.response.campaignGroups[i].viewableImpressions) ? 0 : response.response.campaignGroups[i].viewableImpressions;
          pinnedData.marginOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].marginOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].marginOnAdvertiserSpend;
          pinnedData.dataSpend += isNaN(response.response.campaignGroups[i].dataSpend) ? 0 : response.response.campaignGroups[i].dataSpend;
          pinnedData.effectiveCPMOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend / margin;
          response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend / margin;
          pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend / margin;
          response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend / margin;
          pinnedData.mediaEffectiveCPM += isNaN(response.response.campaignGroups[i].mediaEffectiveCPM) ? 0 : response.response.campaignGroups[i].mediaEffectiveCPM / margin;
          response.response.campaignGroups[i].mediaEffectiveCPM = isNaN(response.response.campaignGroups[i].mediaEffectiveCPM) ? 0 : response.response.campaignGroups[i].mediaEffectiveCPM / margin;
          pinnedData.dataEffectiveCPM += isNaN(response.response.campaignGroups[i].dataEffectiveCPM) ? 0 : response.response.campaignGroups[i].dataEffectiveCPM;
          pinnedData.clickThroughConversions += isNaN(response.response.campaignGroups[i].clickThroughConversions) ? 0 : response.response.campaignGroups[i].clickThroughConversions;
          pinnedData.clickRPM += isNaN(response.response.campaignGroups[i].clickRPM) ? 0 : response.response.campaignGroups[i].clickRPM;
          pinnedData.viewthruConversions += isNaN(response.response.campaignGroups[i].viewthruConversions) ? 0 : response.response.campaignGroups[i].viewthruConversions;
          pinnedData.viewCVR += isNaN(response.response.campaignGroups[i].viewCVR) ? 0 : response.response.campaignGroups[i].viewCVR;
          pinnedData.revenue += isNaN(response.response.campaignGroups[i].revenue) ? 0 : response.response.campaignGroups[i].revenue;
          pinnedData.ctcRevenue += isNaN(response.response.campaignGroups[i].ctcRevenue) ? 0 : response.response.campaignGroups[i].ctcRevenue;
          pinnedData.vtcRevenue += isNaN(response.response.campaignGroups[i].vtcRevenue) ? 0 : response.response.campaignGroups[i].vtcRevenue;
          pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend / margin;
          response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend / margin;
          pinnedData.viewCVR += isNaN(response.response.campaignGroups[i].viewCVR) ? 0 : response.response.campaignGroups[i].viewCVR;
          pinnedData.totalCVRM += isNaN(response.response.campaignGroups[i].totalCVRM) ? 0 : response.response.campaignGroups[i].totalCVRM;
          pinnedData.profitPerClick += isNaN(response.response.campaignGroups[i].profitPerClick) ? 0 : response.response.campaignGroups[i].profitPerClick;
          pinnedData.revenuePerMille += isNaN(response.response.campaignGroups[i].revenuePerMille) ? 0 : response.response.campaignGroups[i].revenuePerMille;
          pinnedData.clickRPM += isNaN(response.response.campaignGroups[i].clickRPM) ? 0 : response.response.campaignGroups[i].clickRPM;
          pinnedData.returnOnAdSpend += isNaN(response.response.campaignGroups[i].returnOnAdSpend) ? 0 : response.response.campaignGroups[i].returnOnAdSpend;
          pinnedData.videoStarted += isNaN(response.response.campaignGroups[i].videoStarted) ? 0 : response.response.campaignGroups[i].videoStarted;
          pinnedData.videoFirstQuartileReached += isNaN(response.response.campaignGroups[i].videoFirstQuartileReached) ? 0 : response.response.campaignGroups[i].videoFirstQuartileReached;
          pinnedData.videoMidpointReached += isNaN(response.response.campaignGroups[i].videoMidpointReached) ? 0 : response.response.campaignGroups[i].videoMidpointReached;
          pinnedData.videoThirdQuartileReached += isNaN(response.response.campaignGroups[i].videoThirdQuartileReached) ? 0 : response.response.campaignGroups[i].videoThirdQuartileReached;
          pinnedData.videoCompleted += isNaN(response.response.campaignGroups[i].videoCompleted) ? 0 : response.response.campaignGroups[i].videoCompleted;
          // tslint:disable-next-line:max-line-length
          pinnedData.effectiveCPCVOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend / margin;
          response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend / margin;
          pinnedData.totalEffectiveCPCV += isNaN(response.response.campaignGroups[i].totalEffectiveCPCV) ? 0 : response.response.campaignGroups[i].totalEffectiveCPCV / margin;
          response.response.campaignGroups[i].totalEffectiveCPCV = isNaN(response.response.campaignGroups[i].totalEffectiveCPCV) ? 0 : response.response.campaignGroups[i].totalEffectiveCPCV / margin;
          pinnedData.companionImpressions += isNaN(response.response.campaignGroups[i].companionImpressions) ? 0 : response.response.campaignGroups[i].companionImpressions;
          pinnedData.companionClicks += isNaN(response.response.campaignGroups[i].companionClicks) ? 0 : response.response.campaignGroups[i].companionClicks;
          pinnedData.companionConversions += isNaN(response.response.campaignGroups[i].companionConversions) ? 0 : response.response.campaignGroups[i].companionConversions;
          pinnedData.companionOfferRevenue += isNaN(response.response.campaignGroups[i].companionOfferRevenue) ? 0 : response.response.campaignGroups[i].companionOfferRevenue;
        }
        this.pinnedGroupsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  openMultEditBrandProtect() {
    this.brandProtectionBulkModal.getBrandProtectionData();
    $('.brand-protection-modal').modal('show');
  }

  openMultEditCampNames() {
    this.campaignNameBulkModal.rowDataNames = Observable.create((obs) => {
      obs.next(this.campaignGrid.getSelectedRows());
      obs.complete();
    });
    $('.campaigns-name-bulk-modal').modal('show');
  }

  openMultEditCampContextual() {
    this.contextualBulkRulesModal.getContextualData();
    $('.contextual-bulk-modal').modal('show');
  }

  openBulkConversionModal() {
    this.conversionsBulkModal.getConversions();
    $('.conversions-bulk-modal').modal('show');
  }

  openBulkDaypartingModal() {
    $('.day-parting-bulk-modal').modal('show');
  }

  openBulkDomainListModal() {
    this.domainListBulkModal.getDomainList();
    $('.domain-list-bulk-modal').modal('show');
  }

  openBulkGeneralSettingsModal() {
    $('.bulk-campaign-general-modal').modal('show');
  }

  openBulkLocationsModal() {
    $('.locations-bulk-modal').modal('show');
  }

  closeAudiencesRulesModal() {
    $('.audiences-rules-modal').modal('hide');
  }

  updateAudiencesRules(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({audienceForm: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.audRulesModal.ngLoading = false;
        $('.audiences-rules-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.audRulesModal.ngLoading = false; }
    );
  }

  onCampaignGridReady(event: any) {
    this.campaignGrid = event.api;
  }

  openMultEditAudiences() {
    this.audRulesModal.brandId = this.groupSelectedRowNode[0].brandId;
    this.audRulesModal.getAudiences();
    $('.audiences-rules-modal').modal('show');
  }

  saveBrandProtectionRules(event: any) {
    console.log(event);
  }

  saveContextualRules(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({contextualTargeting: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.contextualBulkRulesModal.ngLoading = false;
        $('.contextual-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.contextualBulkRulesModal.ngLoading = false; }
    );
  }

  saveConversionsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({conversionConfig: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.conversionsBulkModal.ngLoading = false;
        $('.conversions-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.conversionsBulkModal.ngLoading = false; }
    );
  }

  saveDayPartingBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({dayParting: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.dayPartingBulkModal.ngLoading = false;
        $('.day-parting-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.dayPartingBulkModal.ngLoading = false; }
    );
  }

  saveDomainListBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({domainList: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.domainListBulkModal.ngLoading = false;
        $('.domain-list-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.domainListBulkModal.ngLoading = false; }
    );
  }

  saveGeneralSettingsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({generalSettings: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        $('.bulk-campaign-general-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); }
    );
  }

  saveLocationsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campaignService.bulkCampaigns({locations: event, campaignIds: campIds, type: this.locationsBulkType}).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns updated successfully.');
        this.locationsBulkModal.ngLoading = false;
        $('.locations-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.locationsBulkModal.ngLoading = false; }
    );
  }

  groupsGridReady(event: any) {
    this.groupsGrid = event.api;
  }

  exportGroups() {
    this.groupsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  exportCampaigns() {
    this.campaignGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }
}
