import { Component, OnInit, Renderer, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { CommonAuthService } from 'app/auth/common-auth.service';
import {User} from '../../_models/user';
import {UserRestService} from '../../admin/users/user-rest.service';
import {UserService} from '../../_services/user/user.service';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
  listTitles: any[];
  location: Location;
  nativeElement: Node;
  toggleButton;
  sidebarVisible: boolean;
  showUnGhost = false;

  public isCollapsed = true;
  @ViewChild('navbar-cmp', {static: false}) button;

  constructor(location: Location, private renderer: Renderer, private element: ElementRef, private router: Router, private auth: CommonAuthService, private userService: UserService) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.showUnGhost = this.auth.currentUserValue !== null && typeof this.auth.currentUserValue['from'] !== 'undefined' && this.auth.currentUserValue['from'] === 'anAdmin';
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
  }

  logout() {
    let token = localStorage.getItem('token');
    this.auth.logout(token).subscribe(
      (resp) => {
        console.log("Logged Out Success");
        localStorage.clear();
        this.router.navigate(['/home/login']);
        console.log(resp);
      },
      (error) => {
        //this.router.navigate(['/home/login'])
        console.log("Logged Out with error");
      }
    );
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  supportResquestModal() {
    $('.support-request-modal').modal('show');
  }

  contactInformationModal() {
    $('.contact-information-modal').modal('show');
  }

  changePasswordModal() {
    $('.change-password-modal').modal('show');
  }

  unGhost() {
    // this.ngLoading = true;
    this.userService.unGhostUser(this.auth.currentUserValue['ghostAdmin']).subscribe(
      (response) => {
        if (response && response['response'].token && response['response'].user) {
          localStorage.removeItem('token');
          localStorage.setItem('token', response['response'].token);
          localStorage.setItem('user', JSON.stringify(response['response'].user));
          localStorage.setItem('user2', JSON.stringify(response['response'].user));
          this.auth.setCustomUser(response.user as User);
          location.href = '/mybrands';
        }
        // this.ngLoading = false;
      }
    )
  }
}
