import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Role, RoleBasic} from '../../_models/enums';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserRestService} from '../../admin/users/user-rest.service';
import {User} from '../../_models/user';
import {AlertService} from '../../_services/alert.service';
import {ActivatedRoute} from '@angular/router';
import {CommonAuthService} from '../../auth/common-auth.service';
import { Client } from 'app/_models/client';
import {ClientService} from '../../_services/client/client.service';
import {Observable, Observer} from 'rxjs';

@Component({
  selector: 'app-new-user-basic',
  templateUrl: './new-user-basic.component.html',
  styleUrls: ['./new-user-basic.component.scss']
})
export class NewUserBasicComponent implements OnInit {

  ngLoading = false;
  createOrEdit = 'Create';
  // @ts-ignore
  roles: Array<RoleBasic> = Object.values(RoleBasic);
  newUserForm: FormGroup;
  clients: Array<Client>;
  clientIds: string[] = [];
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userService: UserRestService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private auth: CommonAuthService,
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.newUserForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      role: ['', Validators.required],
      password: [''],
      // confirmPassword: ['', this.confirmPasswordValidator]
    });
    this.newUserForm.addControl('confirmPassword', new FormControl('', [this.confirmPasswordValidator(this.newUserForm)]))
    // this.clients = this.auth.currentUserValue.clients;
  }

  get formControls() {
    return this.newUserForm.controls;
  }

  getClients() {
    this.ngLoading = true;
    this.clientService.getClients().subscribe(
      (response) => {
        this.clients = response.response.clients;
        this.ngLoading = false;
      });
  }

  onSubmit() {
    this.ngLoading = true;
    // this.newUserForm.controls.clients.setValue(this.clientIds);
    if (this.createOrEdit === 'Create') {
      this.userService.createUser(this.newUserForm.value as User)
        .subscribe(response => {
          this.ngLoading = false;
          this.newUserForm.reset();
          this.createOrEdit = 'Create';
          $('.new-user-basic-modal').modal('hide');
          this.ngOnInit();
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'User created successfully.');
          }
          this.refreshGrid.emit();
        });
    } else {
      this.userService.updateUser(this.newUserForm.value as User, this.newUserForm.get('id').value)
        .subscribe(response => {
          this.ngLoading = false;
          this.createOrEdit = 'Create';
          $('.new-user-basic-modal').modal('hide');
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'User updated successfully.');
          }
          this.refreshGrid.emit();
        });
    }
  }

  confirmPasswordValidator(userForm: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && control.value !== userForm.get('password').value) {
        return { 'confirmPassword': true };
      }
      return null;
    };
  }

  loadUser (user: User) {
    this.createOrEdit = 'Edit';
    this.newUserForm.addControl('id', new FormControl());
    this.newUserForm.controls.id.setValue(user.id);
    this.newUserForm.controls.firstname.setValue(user.firstname);
    this.newUserForm.controls.lastname.setValue(user.lastname);
    this.newUserForm.controls.email.setValue(user.email);
    // this.clientIds = user.clients.map((item) => String(item.id));
    this.newUserForm.controls.role.setValue(user.role);

    this.newUserForm.controls.password.clearValidators()
    this.newUserForm.controls.confirmPassword.clearValidators()
    this.newUserForm.controls.confirmPassword.setValidators([this.confirmPasswordValidator(this.newUserForm)]);
    this.newUserForm.controls.password.updateValueAndValidity();
    this.newUserForm.controls.confirmPassword.updateValueAndValidity();
  }

  closeNewUserModal() {
    this.newUserForm.reset();
    this.createOrEdit = 'Create';
    $('.new-user-basic-modal').modal('hide');
  }

  resetForm() {
    this.newUserForm.reset();
    this.ngOnInit();
  }
}
