import {Component, OnInit, ViewChild} from '@angular/core';
import {INoRowsOverlayAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-grid-cell-select',
  templateUrl: './grid-cell-select.component.html',
  styleUrls: ['./grid-cell-select.component.scss']
})
export class GridCellSelectComponent implements INoRowsOverlayAngularComp {
  // @ts-ignore
  @ViewChild('i') buttons;
  params;
  value: any;
  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.value = (typeof params.value !== 'undefined' && params.value !== null && params.value !== '')
      ? params.value : 'Select Event Type';
  }

  getValue() {
    return this.buttons.nativeElement.value;
  }

  saveChanges() {

  }

  deleteRow() {
    this.params.api.updateRowData({remove: [this.params.node.data]});
  }

}
