import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {CampaignGroup} from '../../../_models/campaignGroup';
import {GroupService} from '../../../_services/group/group.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CampaignService} from '../../../_services/campaign/campaign.service';
import {AlertService} from '../../../_services/alert.service';

@Component({
  selector: 'app-copy-campaign',
  templateUrl: './copy-campaign.component.html',
  styleUrls: ['./copy-campaign.component.scss']
})
export class CopyCampaignComponent implements OnInit {
  copyCampaignForm: FormGroup;
  groupsGroup: any;
  selectedGroup: CampaignGroup = null;
  public groups: Observable<any>;
  allGroups: Array<CampaignGroup> = [];
  @Input() campId = null;
  @Input() groupId = null;
  @Input() brandId = null;
  ngLoading = false;

  constructor(
    private fb: FormBuilder,
    private groupService: GroupService,
    private campService: CampaignService,
    public dialogRef: MatDialogRef<CopyCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private router: Router,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.ngLoading = true;
    this.copyCampaignForm = this.fb.group({
      'name': this.fb.control(this._data.campName + ' (Copy)'),
      'group': this.fb.control('')
    });
    this.brandId = this._data.brandId;
    this.groupId = this._data.groupId;
    this.campId = this._data.campId;
    this.getGroups(this.brandId);
  }

  public compareGroups = (item, selected) => {
    if (selected.entityId && item.entityId) {
      return item.entityId === selected.entityId;
    }
    return false;
  };

  getGroups(brandId, params = {filter: ''}) {
    this.groupService.getGroups(brandId).subscribe(
      (response) => {
        console.log(response);
        this.allGroups = response.response.campaignGroups;
        // const filteredCountries = this.allGroups.filter((item, index, array) => {
        //   if (typeof item.brandId !== 'undefined') {
        //     const indexParent = array.findIndex(elem => elem.entityId === item.parentId);
        //     if (indexParent !== -1) {
        //       item['parentName'] = array[indexParent].name;
        //       return true;
        //     } else {
        //       return false;
        //     }
        //   } else {
        //     return false;
        //   }
        // });
        this.groupsGroup = (text: string, children: any[]) => {
          return this.allGroups.find(elem => String(elem.campaignGroupId) === String(children[0].campaignGroupId));
        }
        this.groups = Observable.create((obs) => {
          obs.next(this.allGroups);
          obs.complete();
        });
        console.log(this.groupId, this.allGroups.find(elem => String(elem.campaignGroupId) === String(this.groupId)));
        this.selectedGroup = this.allGroups.find(elem => String(elem.campaignGroupId) === String(this.groupId));
        this.ngLoading = false;
      });
  }

  saveCopyCampaign() {
    this.ngLoading = true;
    this.campService.copyCampaign({newName: this.copyCampaignForm.get('name').value, campaignGroupId: this.selectedGroup['campaignGroupId']}, this.campId).subscribe(
      response => {
        this.alert.alert('success', 'Campaign copied successfully.');
        this.dialogRef.close('done');
        this.ngLoading = false;
      }, error => {
        this.alert.alert('error', error.error);
        this.dialogRef.close();
        this.ngLoading = false;
      }
    );
  }

  redirectToEditView() {
    console.log(this.selectedGroup);
    this.ngLoading = true;
    this.router.navigate(['/campaigns/' + this.brandId + '/' + this.selectedGroup['campaignGroupId'] + '/new-campaign/advance'],
      { queryParams: { copyId: this.campId, newName: this.copyCampaignForm.get('name').value} }).then(r => {
      this.ngLoading = false;
      this.dialogRef.close('done');
    });
  }
}
