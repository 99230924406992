import { ChangeDetectorRef, Component } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SatCalendar, SatCalendarFooter, SatDatepicker } from 'saturn-datepicker';
import { DateAdapter } from 'saturn-datepicker';

@Component({
  selector: 'app-ranges-datepicker',
  templateUrl: './ranges-datepicker.component.html',
  styleUrls: ['./ranges-datepicker.component.scss']
})
export class RangesDatepickerComponent<Date> implements SatCalendarFooter<Date> {
  public ranges: Array<{key: string, label: string}> = [
    {key: 'today', label: 'Today'},
    {key: 'thisWeek', label: 'This Week'},
    {key: 'thisMonth', label: 'This Month'},
    {key: 'thisQuarter', label: 'This Quarter'},
    {key: 'thisYear', label: 'This Year'},
    {key: 'allTime', label: 'All Time'},
  ];
  destroyed = new Subject<void>();
  beginDate = null;
  endDate = null;

  constructor(
    private calendar: SatCalendar<Date>,
    private datePicker: SatDatepicker<Date>,
    private dateAdapter: DateAdapter<Date>,
    cdr: ChangeDetectorRef
  ) {
    this.beginDate = null;
    this.endDate = null;
    this.calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck())
  }

  setRange(range: string) {
    switch (range) {
      case 'today':
        this.beginDate = this.dateAdapter.deserialize(new Date());
        this.endDate = this.dateAdapter.deserialize(new Date());
        // this.calendar.activeDate = this.calendar.beginDate;
        break;
      case 'thisWeek':
        this.beginDate = this.dateAdapter.deserialize(moment().weekday(0).toDate());
        this.endDate = this.dateAdapter.deserialize(moment().weekday(6).toDate());
        break;
      case 'thisMonth':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('month').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('month').toDate());
        break;
      case 'thisQuarter':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('quarter').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('quarter').toDate());
        break;
      case 'thisYear':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('year').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('year').toDate());
        break;
      case 'allTime':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('year').subtract(20, 'years').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('year').toDate());
        break;
    }
    // this.calendar.activeDate = this.calendar.beginDate;
    // this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    // this.calendar.dateRangesChange.emit({begin: this.calendar.beginDate, end: this.calendar.endDate});
    // this.datePicker.close();
  }

  setRangeSelect(event: any) {
    console.log(event);
    switch (event) {
      case 'today':
        this.beginDate = this.dateAdapter.deserialize(new Date());
        this.endDate = this.dateAdapter.deserialize(new Date());
        // this.calendar.activeDate = this.calendar.beginDate;
        break;
      case 'thisWeek':
        this.beginDate = this.dateAdapter.deserialize(moment().weekday(0).toDate());
        this.endDate = this.dateAdapter.deserialize(moment().weekday(6).toDate());
        break;
      case 'thisMonth':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('month').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('month').toDate());
        break;
      case 'thisQuarter':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('quarter').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('quarter').toDate());
        break;
      case 'thisYear':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('year').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('year').toDate());
        break;
      case 'allTime':
        this.beginDate = this.dateAdapter.deserialize(moment().startOf('year').subtract(20, 'years').toDate());
        this.endDate = this.dateAdapter.deserialize(moment().endOf('year').toDate());
        break;
      case 'custom':
        break;
    }
    // this.calendar.activeDate = this.calendar.beginDate;
    // this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    // this.calendar.dateRangesChange.emit({begin: this.calendar.beginDate, end: this.calendar.endDate});
    // this.datePicker.close();
  }

  setDates() {
    if (this.beginDate !== null) {
      this.calendar.beginDate = this.beginDate;
      this.calendar.endDate = this.endDate;
    }
    // @ts-ignore
    this.calendar.beginDate = moment(this.calendar.beginDate).add(1, 'days').toDate();
    // @ts-ignore
    this.calendar.endDate = moment(this.calendar.endDate).add(1, 'days').toDate();
    this.calendar.activeDate = this.calendar.beginDate;
    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    // @ts-ignore
    this.calendar.dateRangesChange.emit({begin: this.calendar.beginDate, end: this.calendar.endDate, extraData: 'fromButton'});
    this.datePicker.close();
  }

  setDatesAll() {
    if (this.beginDate !== null) {
      this.calendar.beginDate = this.beginDate;
      this.calendar.endDate = this.endDate;
    }
    // @ts-ignore
    this.calendar.beginDate = moment(this.calendar.beginDate).add(1, 'days').toDate();
    // @ts-ignore
    this.calendar.endDate = moment(this.calendar.endDate).add(1, 'days').toDate();
    this.calendar.activeDate = this.calendar.beginDate;
    this.calendar.beginDateSelectedChange.emit(this.calendar.beginDate);
    // @ts-ignore
    this.calendar.dateRangesChange.emit({begin: this.calendar.beginDate, end: this.calendar.endDate, extraData: 'fromButtonAll'});
    this.datePicker.close();
  }
}
