import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CampaignGroup} from '../../_models/campaignGroup';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {GroupService} from '../../_services/group/group.service';
import * as moment from 'moment';
import {AdvertiserSpendType} from '../../_models/enums';
import {MatRadioChange} from '@angular/material/radio';
import {Observable} from 'rxjs';
import {CampaignService} from '../../_services/campaign/campaign.service';

@Component({
  selector: 'app-new-group-modal',
  templateUrl: './new-group-modal.component.html',
  styleUrls: ['./new-group-modal.component.scss']
})
export class NewGroupModalComponent implements OnInit {

  // private _parent: BrandsComponent ;
  // @Input() set parent(value: BrandsComponent ) {
  //   this._parent = value;
  // }
  selectedOption: string;
  selectedOption2: string;
  selectedOption3: string;
  selectedOption4: string;

  newGroupForm: FormGroup;

  selectedOptions = [];
  createOrEdit = 'Create';
  ngLoading = false;
  freqCap = false;
  brandId: number;
  @Output() refreshGrid = new EventEmitter<string>();
  advertiserSpendTypeMoney = true;
  kpiMetricMoney = true;
  valuesGroupChanged = false;
  fullyLoaded = true;
  margin = 1;
  currentUser: any;
  isCopy = false;
  campaignsToCopy = [];

  constructor(
    private formB: FormBuilder,
    private groupService: GroupService,
    private alertService: AlertService,
    private campService: CampaignService,
    private auth: CommonAuthService
  ) { }

  ngOnInit() {
    this.selectedOption = 'on';
    this.selectedOption2 = 'none';
    this.selectedOption3 = 'NONE';
    this.selectedOption4 = 'no_kpi';
    this.freqCap = false;
    this.currentUser = JSON.parse(localStorage.getItem('user'));

    this.newGroupForm = this.formB.group({
      name: ['', Validators.required],
      status: ['offline'],
      budget: [{value: '5.00', disabled: false}, Validators.required],
      kpiValue: [{value: '', disabled: true}],
      kpiType: ['NONE'],
      budgetType: ['all_time'],
      budgetStartDate: [{value: '', disabled: false}, Validators.required],
      budgetEndDate: [{value: '', disabled: false}, Validators.required],
      pacingSetting: ['CAMPAIGN'],
      freqCap: ['off'],
      impressions: [{value: '10', disabled: true}],
      periodInHours: [{value: '12', disabled: true}],
      advertiserSpendType: ['NONE'],
      advertiserSpendRate: [{value: null, disabled: true}],
      advertiserMaxBillable: [{value: '0.00', disabled: true}]
    });

    this.valuesGroupChanged = false;
    this.fullyLoaded = true;

    this.newGroupForm.valueChanges.subscribe(
      response => {
        if (this.fullyLoaded) {
          console.log('newGroupForm');
          this.valuesGroupChanged = true;
        }
      }
    )
  }

  get formControls() {
    return this.newGroupForm.controls;
  }

  onSubmit() {
    if (this.brandId === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
      return;
    }
    const group = {
      brandId: this.brandId,
      name: this.formControls.name.value,
      status: this.formControls.status.value,
      kpiValue: (this.formControls.kpiType.value === 'NONE' || this.formControls.kpiType.value === null || this.formControls.kpiType.value === '')
        ? undefined : this.formControls.kpiValue.value,
      kpiType: (this.formControls.kpiType.value === 'NONE' || this.formControls.kpiType.value === null || this.formControls.kpiType.value === '')
        ? undefined : this.formControls.kpiType.value,
      budget: {
        amount: (parseFloat(String(this.formControls.budget.value).replace('$', '').replace(',', '')) * this.margin),
        type: this.formControls.budgetType.value,
        evenDeliveryEnabled: true,
        schedule: {
          flightDates: {
            from: moment(this.formControls.budgetStartDate.value).format('YYYYMMDD'),
            to: moment(this.formControls.budgetEndDate.value).format('YYYYMMDD')
          }
        }
      },
      pacingSetting: this.formControls.pacingSetting.value,
      frequencyCapping: (this.freqCap) ? {impressions: this.formControls.impressions.value,  periodInHours: this.formControls.periodInHours.value} : null,
      advertiserSpendType: this.formControls.advertiserSpendType.value,
      advertiserSpendRate: parseFloat(String(this.formControls.advertiserSpendRate.value).replace('$', '').replace(',', '')).toFixed(2),
      advertiserMaxBillable: parseFloat(String(this.formControls.advertiserMaxBillable.value).replace('$', '').replace(',', '')).toFixed(2),
      campaignsToCopy: (this.isCopy) ? this.campaignsToCopy : null
    };
    console.log(group);
    if (this.createOrEdit === 'Create') {
      this.ngLoading = true;
      this.groupService.createGroup(group as unknown as CampaignGroup)
        .subscribe(response => {
          this.ngLoading = false;
          this.newGroupForm.reset();
          this.ngOnInit();
          this.createOrEdit = 'Create';
          this.refreshGrid.emit('group');
          this.valuesGroupChanged = false;
          this.fullyLoaded = true;
          this.isCopy = false;
          this.campaignsToCopy = [];
          $('.new-group-modal').modal('hide');
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Campaign Group created successfully.');
          }
        },
          (error) => { console.log(error); this.ngLoading = false; });
    } else {
      this.groupService.updateGroup(group as unknown as CampaignGroup, this.newGroupForm.get('id').value)
        .subscribe(response => {
          this.ngLoading = false;
          this.newGroupForm.reset();
          this.ngOnInit();
          this.createOrEdit = 'Create';
          this.refreshGrid.emit('group');
          this.valuesGroupChanged = false;
          this.fullyLoaded = true;
          this.isCopy = false;
          this.campaignsToCopy = [];
          $('.new-group-modal').modal('hide');
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Campaign Group updated successfully.');
          }
        },
          (error) => { console.log(error); this.ngLoading = false; });
    }
  }

  loadGroup(group: CampaignGroup, copy = false) {
    this.fullyLoaded = false;
    this.isCopy = false;
    if (!copy) {
      window.addEventListener('beforeunload', event => {
        console.log('beforeunload', event);
        if (this.valuesGroupChanged) {
          event.preventDefault();
          // Chrome requires returnValue to be set.
          event.returnValue = 'You have unsaved changes...!';
        }
      }, false);
      console.log(group);
      this.valuesGroupChanged = false;
      this.createOrEdit = 'Edit';
      this.newGroupForm.addControl('id', new FormControl());
      this.newGroupForm.controls.id.setValue(group.campaignGroupId);
    } else {
      group.name = 'Copy ' + group.name;
      this.isCopy = true;
      this.ngLoading = true;
      this.campService.getCampaigns(group.campaignGroupId, {filter: '', dateTo: '', dateFrom: '', status: ''}, 1000).subscribe(
        (response) => {
          this.campaignsToCopy = response.response.campaigns;
          this.ngLoading = false;
        },
        (error) => {
          console.log(error);
          this.ngLoading = false;
        }
      )
    }
    this.newGroupForm.controls.name.setValue(group.name);
    this.newGroupForm.controls.status.setValue(copy ? 'offline' : group.status);
    this.newGroupForm.controls.kpiValue.setValue((typeof group.kpiValue !== 'undefined') ? group.kpiValue : 0);
    this.newGroupForm.controls.kpiType.setValue((typeof group.kpiType !== 'undefined') ? group.kpiType : 'NONE');
    this.newGroupForm.controls.budgetType.setValue((group.budget.type !== 'none' ? group.budget.type : 'daily'));
    // if (group.budget.type !== 'none') {
      this.newGroupForm.controls.budget.setValue((typeof group.budget.amount !== 'undefined') ? (group.budget.amount).toFixed(2) : 0);
      if (typeof group.budget.schedule !== 'undefined' && group.budget.schedule !== null
        && typeof group.budget.schedule.flightDates !== 'undefined' && group.budget.schedule.flightDates !== null) {
        this.newGroupForm.controls.budgetStartDate.setValue((group.budget.schedule.flightDates.from) ? moment(group.budget.schedule.flightDates.from).toDate() : '');
        this.newGroupForm.controls.budgetEndDate.setValue((group.budget.schedule.flightDates.to) ? moment(group.budget.schedule.flightDates.to).toDate() : '');
      }
      if (this.currentUser.role !== 'ADMIN') {
        this.newGroupForm.get('budget').disable();
      } else {
        this.newGroupForm.get('budget').enable();
      }
      // this.newGroupForm.get('budgetStartDate').enable();
      // this.newGroupForm.get('budgetEndDate').enable();
    // }
    this.newGroupForm.controls.pacingSetting.setValue((typeof group.pacingSetting !== 'undefined') ? group.pacingSetting : '');
    if (typeof group.frequencyCapping !== 'undefined' && group.frequencyCapping !== null) {
      this.newGroupForm.controls.freqCap.setValue('on');
      this.newGroupForm.controls.impressions.setValue(group.frequencyCapping.impressions);
      this.newGroupForm.controls.periodInHours.setValue(group.frequencyCapping.periodInHours);
      this.freqCap = true;
    }
    this.newGroupForm.controls.advertiserSpendType.setValue((typeof group.advertiserSpendType !== 'undefined') ? group.advertiserSpendType : '');
    // @ts-ignore
    if (group.advertiserSpendType !== 'none' && group.advertiserSpendType !== AdvertiserSpendType.NONE) {
      this.newGroupForm.controls.advertiserSpendRate.setValue((typeof group.advertiserSpendRate !== 'undefined') ? group.advertiserSpendRate.toFixed(2) : 0);
      this.newGroupForm.controls.advertiserMaxBillable.setValue((typeof group.advertiserMaxBillable !== 'undefined') ? group.advertiserMaxBillable.toFixed(2) : 0);
      this.newGroupForm.get('advertiserSpendRate').enable();
      this.newGroupForm.get('advertiserMaxBillable').enable();
    }
    this.fullyLoaded = true;
  }

  budgetTypeChanged(event: any) {
    if (event === 'none') {
      this.newGroupForm.get('budget').disable();
      // this.newGroupForm.get('budgetEndDate').disable();
      // this.newGroupForm.get('budgetStartDate').disable();
    } else {
      this.newGroupForm.get('budget').enable();
      // this.newGroupForm.get('budgetEndDate').enable();
      // this.newGroupForm.get('budgetStartDate').enable();
    }
  }

  freqTypeChanged(event: MatRadioChange) {
    if (event.value === 'on') {
      this.freqCap = true;
      this.newGroupForm.get('impressions').enable();
      this.newGroupForm.get('periodInHours').enable();
      this.newGroupForm.get('impressions').setValidators(Validators.required);
      this.newGroupForm.get('periodInHours').setValidators(Validators.required);
    } else {
      this.newGroupForm.get('impressions').disable();
      this.newGroupForm.get('periodInHours').disable();
      this.newGroupForm.get('impressions').clearValidators();
      this.newGroupForm.get('periodInHours').clearValidators();
      this.newGroupForm.get('impressions').setValue('10');
      this.newGroupForm.get('periodInHours').setValue('12');
      this.freqCap = false;
    }
  }

  advertiserSpendTypeChanged(event: any) {
    if (event === 'none') {
      this.newGroupForm.get('advertiserMaxBillable').disable();
      this.newGroupForm.get('advertiserSpendRate').disable();
      this.newGroupForm.get('advertiserSpendRate').clearValidators();
    } else {
      this.newGroupForm.get('advertiserMaxBillable').enable();
      this.newGroupForm.get('advertiserSpendRate').enable();
      this.newGroupForm.get('advertiserSpendRate').setValidators(Validators.required);
    }
    if (event === 'margin' || event === 'markup' || event === 'none') {
      this.advertiserSpendTypeMoney = false;
    } else {
      this.advertiserSpendTypeMoney = true;
    }
    this.newGroupForm.get('advertiserSpendRate').reset();
    this.newGroupForm.updateValueAndValidity();
  }

  kpiTypeChanged(event: any) {
    if (event === 'NONE') {
      this.newGroupForm.get('kpiValue').disable();
      this.newGroupForm.get('kpiValue').clearValidators();
    } else {
      this.newGroupForm.get('kpiValue').enable();
      this.newGroupForm.get('kpiValue').setValidators(Validators.required);
    }
    if (event === 'CTR' || event === 'VIDEO_COMP' || event === 'NONE') {
      this.kpiMetricMoney = false;
    } else {
      this.kpiMetricMoney = true;
    }
    this.newGroupForm.get('kpiValue').reset();
    this.newGroupForm.updateValueAndValidity();
  }

  datesDifference(start: string, end: string) {
    return moment(end).diff(moment(start), 'days') + 1;
  }

  dailySpend() {
    return (this.newGroupForm.value.budgetType === 'daily')
      ? '$' + this.newGroupForm.value.budget
      : (
        (this.newGroupForm.value.budgetStartDate === '' || this.newGroupForm.value.budgetEndDate === '')
          ? 'Select date range'
          : ((this.newGroupForm.value.budget !== null && !this.newGroupForm.controls.budget.disabled)
          ? '$' + (this.newGroupForm.value.budget / (this.datesDifference(this.newGroupForm.value.budgetStartDate, this.newGroupForm.value.budgetEndDate))).toFixed(2)
          : 'N/A')
      )
  }

  totalSpend() {
    return (this.newGroupForm.value.budgetType === 'all_time')
      ? '$' + this.newGroupForm.value.budget
      : (
        (this.newGroupForm.value.budgetStartDate === '' || this.newGroupForm.value.budgetEndDate === '')
          ? 'Select date range'
          : ((this.newGroupForm.value.budget !== null && !this.newGroupForm.controls.budget.disabled)
          ? '$' + (this.newGroupForm.value.budget * (this.datesDifference(this.newGroupForm.value.budgetStartDate, this.newGroupForm.value.budgetEndDate))).toFixed(2)
          : 'N/A')
      )
  }

  advDailySpend() {
    const spend = parseFloat(this.dailySpend().replace('$', ''));
    return (this.newGroupForm.value.advertiserSpendType === 'margin' || this.newGroupForm.value.advertiserSpendType === 'markup')
      ? ((!isNaN(spend) && !isNaN(this.newGroupForm.value.advertiserSpendRate)) ? '$' + (spend + (spend * (this.newGroupForm.value.advertiserSpendRate / 100))).toFixed(2) : 'N/A')
      : 'N/A'
  }

  advTotalSpend() {
    const spend = parseFloat(this.totalSpend().replace('$', ''));
    return (this.newGroupForm.value.advertiserSpendType === 'margin' || this.newGroupForm.value.advertiserSpendType === 'markup')
      ? ((!isNaN(spend) && !isNaN(this.newGroupForm.value.advertiserSpendRate)) ? '$' + (spend + (spend * (this.newGroupForm.value.advertiserSpendRate / 100))).toFixed(2) : 'N/A')
      : 'N/A'
  }

  closeNewGroupModal() {
    if (this.valuesGroupChanged) {
      if (!confirm('Your changes haven’t been saved! Do you want to continue?')) {
        return;
      }
    }
    this.valuesGroupChanged = false;
    this.newGroupForm.reset();
    this.ngOnInit();
    this.createOrEdit = 'Create';
    this.isCopy = false;
    this.campaignsToCopy = [];
    $('.new-group-modal').modal('hide');
  }

  removeCamp(pos: number) {
    this.campaignsToCopy.splice(pos, 1);
  }
}
