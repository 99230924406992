import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, publishReplay, refCount, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Observable} from 'rxjs';
import {BrandCategoriesResponse} from '../../_models/responses';
import {TreeviewItem} from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyDataService {

  makes: Observable<any>;
  private url = 'thirdparty';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  // Clear cachessss
  clearCache() {
    this.makes = null;
  }

  getContextualSegments(filter: string = '') {
    return this.http.get(
      `${environment.apiUrl}` + this.url + '/contextual?filter=' + filter
    ).pipe(
      tap(_ => console.log('fetched Contextual Segments')),
      catchError(Errors.handleError<any>('getContextualSegments'))
    );
  }

  getMakes(query: string) {
    // if (!this.makes) {
      return this.makes = this.http.get(
        `${environment.apiUrl}` + this.url + '/makes?q=' + query
      ).pipe(
        tap(_ => console.log('fetched Makes')),
        publishReplay(1),
        refCount(),
        catchError(Errors.handleError<any>('getMakes'))
      );
    // }
    // return this.makes;
  }

  getModels(make) {
    return this.http.get(
      `${environment.apiUrl}` + this.url + '/models/' + make
    ).pipe(
      tap(_ => console.log('fetched Models')),
      catchError(Errors.handleError<any>('getModels'))
    );
  }

  getContextualSegmentChildren(value: any) {
    return this.http.get(
      `${environment.apiUrl}` + this.url + '/contextual/' + value
    ).pipe(
      tap(_ => console.log('fetched Contextual Segment Children')),
      catchError(Errors.handleError<any>('getContextualSegmentChildern'))
    );
  }

  getContextualSegmentData(value: any) {
    return this.http.get(
      `${environment.apiUrl}` + this.url + '/contextual/data/' + value
    ).pipe(
      tap(_ => console.log('fetched getContextualSegmentData')),
      catchError(Errors.handleError<any>('getContextualSegmentData'))
    );
  }

  structureFilterAudiences(items: any, item) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].value === item.parentId) {
        const newItem = new TreeviewItem({
          text: 'blank', value: 0, collapsed: true, children: null
        }, false);
        newItem['targetable'] = false;
        newItem['level'] = items[i]['level'] + 2;
        if (typeof items[i].children === 'undefined' || items[i].children === null) {
          items[i]['children'] = [new TreeviewItem({
            text: item.name,
            value: item.demographicId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false)];
          items[i].collapsed = false;
        } else if (items[i].children.length === 1 && items[i].children[0].text === 'blank' && items[i].children[0].value === 0) {
          items[i].children[0] = new TreeviewItem({
            text: item.name,
            value: item.demographicId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false);
          items[i].collapsed = false;
        } else {
          items[i].children.push(new TreeviewItem({
            text: item.name,
            value: item.demographicId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false));
          items[i].collapsed = false;
        }
        items[i].children[items[i].children.length - 1]['level'] = items[i]['level'] + 1;
        items[i].children[items[i].children.length - 1]['targetable'] = item.targetable;
        items[i].children[items[i].children.length - 1]['price'] = item.price;
        items[i].children[items[i].children.length - 1].setCheckedRecursive(false);
        items[i].setCheckedRecursive(false);
        return [items, true];
      } else if (typeof items[i].children !== 'undefined' && items[i].children.length > 0) {
        const newItems = this.structureFilterAudiences(items[i].children, item);
        if (newItems[1]) {
          items[i].children = newItems[0];
          items[i].setCheckedRecursive(false);
          return [items, true];
        }
      }
    }
    return [items, false];
  }

  structureFilterContextual(items: any, item) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].value === item.parentId) {
        const newItem = new TreeviewItem({
          text: 'blank', value: 0, collapsed: true, children: null
        }, false);
        newItem['targetable'] = false;
        newItem['level'] = items[i]['level'] + 2;
        if (typeof items[i].children === 'undefined' || items[i].children === null) {
          items[i]['children'] = [new TreeviewItem({
            text: item.name,
            value: item.contextualEntryId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false)];
          items[i].collapsed = false;
        } else if (items[i].children.length === 1 && items[i].children[0].text === 'blank' && items[i].children[0].value === 0) {
          items[i].children[0] = new TreeviewItem({
            text: item.name,
            value: item.contextualEntryId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false);
          items[i].collapsed = false;
        } else {
          items[i].children.push(new TreeviewItem({
            text: item.name,
            value: item.contextualEntryId,
            collapsed: true,
            children: ((item.numberOfChildren > 0) ? [newItem] : null)
          }, false));
          items[i].collapsed = false;
        }
        items[i].children[items[i].children.length - 1]['level'] = items[i]['level'] + 1;
        items[i].children[items[i].children.length - 1]['targetable'] = item.targetable;
        if (item.targetable) {
          items[i].children[items[i].children.length - 1]['parentId'] = item.parentId;
          items[i].children[items[i].children.length - 1]['parentName'] = item.parentName;
          items[i].children[items[i].children.length - 1]['providerId'] = item.providerId;
          items[i].children[items[i].children.length - 1]['providerName'] = item.providerName;
        }
        items[i].children[items[i].children.length - 1].setCheckedRecursive(false);
        items[i].setCheckedRecursive(false);
        return [items, true];
      } else if (typeof items[i].children !== 'undefined' && items[i].children.length > 0) {
        const newItems = this.structureFilterContextual(items[i].children, item);
        if (newItems[1]) {
          items[i].children = newItems[0];
          items[i].setCheckedRecursive(false);
          return [items, true];
        }
      }
    }
    return [items, false];
  }

  getAudienceSegments(value = null) {
    const param = (value !== null) ? value.value : '';
    return (typeof value === 'string') ? this.http.get(
      `${environment.apiUrl}` + this.url + '/audience/?text=' + value
    ).pipe(
      tap(_ => console.log('fetched Audience Segments')),
      catchError(Errors.handleError<any>('getAudienceSegments'))
    ) : this.http.get(
      `${environment.apiUrl}` + this.url + '/audience/' + param
    ).pipe(
      tap(_ => console.log('fetched Audience Segments')),
      catchError(Errors.handleError<any>('getAudienceSegments'))
    );
  }

  getAudienceSegmentData(value = null) {
    const param = (value !== null) ? ((typeof value.value !== 'undefined') ? value.value : value) : '';

    return this.http.get(
      `${environment.apiUrl}` + this.url + '/audience/data/' + param
    ).pipe(
      tap(_ => console.log('fetched Audience Segment  Data')),
      catchError(Errors.handleError<any>('getAudienceSegmentData'))
    );
  }
}
