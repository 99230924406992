import {Component, Input, OnInit} from '@angular/core';
// import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormBuilder, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';
import {environment} from '../../../../environments/environment';
import {Brand} from '../../../_models/brand';

@Component({
  selector: 'app-new-multiple-image-modal',
  templateUrl: './new-multiple-image-modal.component.html',
  styleUrls: ['./new-multiple-image-modal.component.scss']
})
export class NewMultipleImageModalComponent extends NewAdComponent implements OnInit {

  // private _parent: any ;
  ngLoading = false;
  pondOptionsZip: any;
  successUpload = false;

  // @Input() set parent(value: any ) {
  //   this._parent = value;
  // }

  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pondOptionsZip = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop file or click here for browsing...',
      acceptedFileTypes: 'zip,application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
      imagePreviewMarkupShow: false,
      maxFileSize: '25MB',
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadZipFile(this.brandId, fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
                console.log(response);
                load(`${environment.publicUrl}` + fieldName);
                // this.newForm.controls.assetUrl.setValue(response);
                for (let i in response.assetUrls) {
                  let image = new Image();
                  let div = document.createElement('DIV');
                  image.src = response.assetUrls[i].assetUrl;
                  this.newForm.controls.assetUrl.setValue(this.newForm.controls.assetUrl.value + '|' + response.assetUrls[i].assetUrl);
                  if (typeof response.originalFiles !== 'undefined') {
                    this.newForm.controls.label.setValue(this.newForm.controls.label.value + '|' + response.originalFiles[i]);
                  } else {
                    this.newForm.controls.label.setValue(this.newForm.controls.label.value + '|' + response.assetUrls[i].originalFileName);
                  }
                  document.getElementById('adPanel2').appendChild(image);
                  document.getElementById('adPanel2').appendChild(div);
                }
                this.newForm.controls.landingPageDomain.setValue(this.brandDomain);
                this.successUpload = true;
                return response;
              },
              (error1) => {
                console.log(error1);
                error(error1.message);
                this.successUpload = false;
                load();
              });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getFile(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          this.successUpload = false;
          load();
        }
      }
    };
    this.newForm = this.fb.group({
      adType: ['image'],
      label: ['', Validators.required],
      status: ['online'],
      zip: [true],
      width: [''],
      height: [''],
      type: ['banner'],
      assetUrl: ['', [Validators.required]],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      landingPageDomain: ['', [Validators.pattern('^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$'), Validators.required]],
      reviewAttributes: ['', [Validators.required]],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')])
    });
  }

}
