import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-names-modal',
  templateUrl: './campaign-names-modal.component.html',
  styleUrls: ['./campaign-names-modal.component.scss']
})
export class CampaignNamesModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }

  columnDefsCamp = [
    {headerName: 'ID', field: 'id', width: 100 },
    {headerName: 'Name', field: 'name', width: 300 },
  ];
  rowDataCamp = [];

  constructor() { }

  ngOnInit() {
  }

}
