import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../_models/client';
import {AlertService} from '../../_services/alert.service';
import {ClientService} from '../../_services/client/client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {
  ngLoading = false;
  createOrEdit = 'Create';
  newClientForm: FormGroup;
  @Output() refreshGrid = new EventEmitter<string>();

  // tslint:disable-next-line:max-line-length
  constructor(
    private fb: FormBuilder,
    private clientService: ClientService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.newClientForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.email]],
      phone: [''],
      address: [''],
      city: [''],
      country: [''],
      zip: [''],
      margin: [0],
      oldMargin: [0],
      users: [1],
      trial_end_date: [''],
      status: ['1']
    });
  }

  get formControls() {
    return this.newClientForm.controls;
  }

  onSubmit() {
    this.ngLoading = true;
    if (this.createOrEdit === 'Create') {
      this.clientService.createClient(this.newClientForm.value as Client)
        .subscribe(response => {
          this.ngLoading = false;
          this.newClientForm.reset();
          this.createOrEdit = 'Create';
          $('.new-client-modal').modal('hide');
          this.ngOnInit();
          if ( String(response['status']) === 'SUCCESS' ) {
            const currentUser = JSON.parse(localStorage.getItem('user'));
            currentUser.clients.push(response['response']['client']);
            localStorage.setItem('user', JSON.stringify(currentUser));
            this.alertService.alert('success', 'Client created successfully.');
          }
          this.refreshGrid.emit();
        });
    } else {
      if (this.newClientForm.get('margin').value !== this.newClientForm.get('oldMargin').value) {
        Swal.fire({
          title: '',
          text: 'When you change the margin %, all the client campaign\'s budget will be updated. Are you sure you want to update the margin %?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#51cbce',
          cancelButtonColor: '#ef8157',
          confirmButtonText: 'Yes'
        }).then((confirm) => {
          if (typeof confirm.value !== 'undefined' && confirm.value) {
            this.updateClient();
          } else {
            this.ngLoading = false;
          }
        });
      } else {
        this.updateClient();
      }
    }
  }

  updateClient() {
    console.log('Executed updateClient');

    this.clientService.updateClient(this.newClientForm.value as Client, this.newClientForm.get('id').value)
      .subscribe(response => {
        this.ngLoading = false;
        this.createOrEdit = 'Create';
        $('.new-client-modal').modal('hide');
        if ( String(response['status']) === 'SUCCESS' ) {
          const currentUser = JSON.parse(localStorage.getItem('user'));
          const index = currentUser.clients.findIndex(i => i.id === this.newClientForm.get('id').value);
          currentUser.clients[index] = response['response']['client'];
          localStorage.setItem('user', JSON.stringify(currentUser));
          this.alertService.alert('success', 'Client updated successfully.');
        }
        this.refreshGrid.emit();
      }, error => { console.log(error); this.ngLoading = false; });
  }

  loadClient (client: Client) {
    this.createOrEdit = 'Edit';
    this.newClientForm.addControl('id', new FormControl());
    this.newClientForm.controls.id.setValue(client.id);
    this.newClientForm.controls.name.setValue(client.name);
    this.newClientForm.controls.phone.setValue(client.phone);
    this.newClientForm.controls.email.setValue(client.email);
    this.newClientForm.controls.address.setValue(client.address);
    this.newClientForm.controls.city.setValue(client.city);
    this.newClientForm.controls.country.setValue(client.country);
    this.newClientForm.controls.zip.setValue(client.zip);
    this.newClientForm.controls.margin.setValue(client.margin);
    this.newClientForm.controls.oldMargin.setValue(client.margin);
    this.newClientForm.controls.users.setValue(client.users);
    if (client.trial_end_date !== '' && client.trial_end_date !== '0000-00-00') {
      this.newClientForm.controls.trial_end_date.setValue(client.trial_end_date);
    }
    this.newClientForm.controls.status.setValue(String(client.status));
  }

  closeNewClientModal() {
    this.newClientForm.reset();
    this.createOrEdit = 'Create';
    $('.new-client-modal').modal('hide');
  }

  resetForm() {
    this.newClientForm.reset();
    this.ngOnInit();
  }

}
