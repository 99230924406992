import {Component, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-status-report-cell-render',
  templateUrl: './status-report-cell-render.component.html',
  styleUrls: ['./status-report-cell-render.component.scss']
})
export class StatusReportCellRenderComponent {

  // @ts-ignore
  @ViewChild('i') customHtml;
  params;
  text = 'On';
  checkedSlide = false;
  slide = true;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.slide = false;
    this.text = this.params.value;
  }

  getValue() {
    return this.customHtml.nativeElement.value;
  }

  refresh(): boolean {
    return false;
  }

  statusChanged(event) {
    this.text = (this.text === 'ACTIVE') ? 'ACTIVE' : 'PAUSED';
  }

  selectChanged(event: Event) {
    // @ts-ignore
    this.params.data.status = event.target.value;
    if (typeof this.params.data.rpt_id !== 'undefined') {
      console.log(this.params.context);
      this.params.context.componentParent.updateStatusScheduleRpt(this.params.data);
    }
  }

}
