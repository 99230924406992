import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {CampaignGroupResponse, CampaignGroupsResponse} from '../../_models/responses';
import {CampaignGroup} from '../../_models/campaignGroup';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private groupsUrl = 'campaign-groups';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getGroups(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0): Observable<CampaignGroupsResponse> {
    return this.http.get<CampaignGroupsResponse>(
      `${environment.apiUrl}` + this.groupsUrl
      + '?brandId=' + brandId + '&max=' + max + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched groups')),
      catchError(Errors.handleError<CampaignGroupsResponse>('getGroups'))
    );
  }

  getActiveGroups(params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0): Observable<CampaignGroupsResponse> {
    return this.http.get<CampaignGroupsResponse>(
      `${environment.apiUrl}` + 'campaigngroups/all'
      + '?max=' + max + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched active groups')),
      catchError(Errors.handleError<CampaignGroupsResponse>('getActiveGroups'))
    );
  }

  getActivePacingGroups(params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 1000, offset: number = 0): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}` + 'campaigngroups/pacing/all'
      + '?max=' + max + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched active pacing groups')),
      catchError(Errors.handleError<any>('getActivePacingGroups'))
    );
  }

  getGroupById(id: any) {
    return this.http.get<CampaignGroupResponse>(`${environment.apiUrl}` + this.groupsUrl + '/' + id).pipe(
      tap(_ => console.log('fetched brand')),
      catchError(Errors.handleError<CampaignGroupResponse>('getGroupById'))
    );
  }

  createGroup(group: CampaignGroup): Observable<CampaignGroupResponse> {
    return this.http.post<CampaignGroupResponse>(`${environment.apiUrl}` + this.groupsUrl, group, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createGroup', group))
      ) as Observable<CampaignGroupResponse>;
  }

  updateGroup(group: CampaignGroup, id: number): Observable<CampaignGroupResponse>  {
    return this.http.put<CampaignGroupResponse>(`${environment.apiUrl}` + this.groupsUrl + '/' + id, group, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateGroup', group))
      ) as Observable<CampaignGroupResponse>;
  }

  updateMultipleGroup(groups: {}): Observable<{}>  {
    return this.http.put(`${environment.apiUrl}` + this.groupsUrl + '/multiple', groups, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateGroup', groups))
      ) as Observable<{}>;
  }

  deleteGroup(id: string) {
    return this.http.delete<CampaignGroupResponse>(`${environment.apiUrl}` + this.groupsUrl + '/' + id).pipe(
      tap(_ => console.log('deleted group')),
      catchError(Errors.handleError<CampaignGroupResponse>('deleteGroup'))
    );
  }

  getBrandAndCampaignGroupNames(groupId: number) {
    return this.http.get<{brandName: string, groupName: string}>(`${environment.apiUrl}` + 'names/' + groupId).pipe(
      tap(_ => console.log('fetched brand')),
      catchError(Errors.handleError<{brandName: string, groupName: string}>('getBrandAndCampaignGroupNames'))
    );
  }

  getCampaignsByGroup(brandId: number) {
    return this.http.get<[]>(`${environment.apiUrl}` + 'campaigns-by-groups/' + brandId).pipe(
      tap(_ => console.log('fetched data')),
      catchError(Errors.handleError<[]>('getCampaignsByGroup'))
    );
  }

}
