import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
import {Brand} from '../../_models/brand';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {Audience} from '../../_models/audience';

@Component({
  selector: 'app-new-audience-modal',
  templateUrl: './new-audience-modal.component.html',
  styleUrls: ['./new-audience-modal.component.scss']
})
export class NewAudienceModalComponent implements OnInit {

  // private _parent: BrandsComponent ;
  // @Input() set parent(value: BrandsComponent ) {
  //   this._parent = value;
  // }
  brandId: number;

  createOrEdit = 'Create';
  ngLoading = false;
  newAudForm: FormGroup;
  audienceId: bigint;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private formB: FormBuilder,
    private audService: AudiencesService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.newAudForm = this.formB.group({
      name: ['', Validators.required],
      visible: [true],
      notes: ['']
    });
  }

  loadAudience(aud: Audience) {
    this.resetModal();
    this.createOrEdit = 'Edit';
    this.audienceId = aud.audienceId;
    // this.newAudForm.addControl('id', new FormControl());
    // this.newAudForm.controls.id.setValue(aud.id);
    this.newAudForm.addControl('brandId', new FormControl());
    this.newAudForm.controls.brandId.setValue(this.brandId);
    this.newAudForm.controls.name.setValue(aud.name);
    this.newAudForm.controls.notes.setValue(aud.notes);
    this.newAudForm.controls.visible.setValue(aud.visible);
  }

  onSubmit() {
    this.ngLoading = true;
    console.log(this.newAudForm.value);
    this.newAudForm.addControl('brandId', new FormControl());
    this.newAudForm.controls.brandId.setValue(this.brandId);
    if (this.createOrEdit === 'Create') {
      this.audService.createAudience(this.newAudForm.value as Audience).subscribe(
        (response) => {
          this.newAudForm.reset();
          this.ngOnInit();
          this.refreshGrid.emit('audience');
          $('.new-audience-modal').modal('hide');
          if (response.status === 'success') {
            this.alertService.alert('success', 'Audience created successfully.')
          }
          this.ngLoading = false;
        },
        (error) => { console.log(error, 'updateAudience'); this.ngLoading = false; }
      );
    } else {
      this.audService.updateAudience(this.newAudForm.value as Audience, this.audienceId).subscribe(
        (response) => {
          if (response.status.toLowerCase() === 'success') {
            this.newAudForm.reset();
            this.ngOnInit();
            this.refreshGrid.emit('audience');
            $('.new-audience-modal').modal('hide');
            this.alertService.alert('success', 'Audience updated successfully.')
          }
          this.ngLoading = false;
        },
        (error) => { console.log(error, 'updateAudience'); this.ngLoading = false; }
      )
    }

  }

  get formControls() {
    return this.newAudForm.controls;
  }

  public resetModal() {
    this.audienceId = null;
    this.createOrEdit = 'Create';
    this.newAudForm.reset();
    this.ngOnInit();
  }

}
