import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Campaign} from '../../../_models/campaign';
import {ActionsCellRendererComponent} from '../../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {
  NumberFormatCellRendererComponent
} from '../../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {CampaignService} from '../../../_services/campaign/campaign.service';
import {response} from 'express';
import {AlertService} from '../../../_services/alert.service';
import {CampaignGroup} from '../../../_models/campaignGroup';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  ngLoading = false;
  rowDataCamp: Observable<Array<Campaign>>;
  rowDataGroups: Observable<Array<CampaignGroup>>;
  rowDataAds: Observable<Array<Campaign>>;
  defaultCampsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' },
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA - valueB)
  };
  compParent = {componentParent: this};
  columnDefsCamp = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 90, filter: true,
      pinnedRowCellRenderer: '',
      sortable: false,
      cellRenderer: params => params.node.data.status,
    },
    {headerName: 'Name', field: 'name', width: 300, pinnedRowCellRenderer: '', cellRenderer: '', sort: 'asc',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA === valueB) { return 0; }
        return (valueA > valueB) ? 1 : -1;
      } },
    {headerName: 'Campaign ID', field: 'campaignId', width: 200, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Type', field: 'campaignType', width: 100, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Campaign Group Name', field: 'campaignGroupName', width: 300, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Campaign Group ID', field: 'campaignGroupId', width: 200, pinnedRowCellRenderer: '', cellRenderer: '' },
  ];

  columnDefsGroups = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 90, filter: true,
      pinnedRowCellRenderer: '',
      sortable: false,
      cellRenderer: params => params.node.data.status,
    },
    {headerName: 'Name', field: 'name', width: 300, pinnedRowCellRenderer: '', cellRenderer: '', sort: 'asc',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA === valueB) { return 0; }
        return (valueA > valueB) ? 1 : -1;
      } },
    {headerName: 'Campaign Group ID', field: 'campaignGroupId', width: 200, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Brand Name', field: 'brandName', width: 300, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Brand ID', field: 'brandId', width: 200, pinnedRowCellRenderer: '', cellRenderer: '' },
  ];
  columnDefsAds = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 90, filter: true,
      pinnedRowCellRenderer: '',
      sortable: false,
      cellRenderer: params => params.node.data.status,
    },
    {headerName: 'Name', field: 'label', width: 300, pinnedRowCellRenderer: '', cellRenderer: '', sort: 'asc',
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (valueA === valueB) { return 0; }
        return (valueA > valueB) ? 1 : -1;
      } },
    {headerName: 'Type', field: 'type', width: 100, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Brand ID', field: 'brandId', width: 200, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Brand Name', field: 'brandName', width: 300, pinnedRowCellRenderer: '', cellRenderer: '' },
  ];
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent
  };
  campaignGrid: any;
  groupGrid: any;
  adsGrid: any;
  campFilterString = '';
  groupFilterString = '';
  archivedShow = true;
  archivedGroupShow = true;
  basisIds = '';
  groupIds = '';
  adsIds = '';
  adsFilterString = '';
  all_inventories = true;

  constructor(
    private campService: CampaignService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.refreshGrid('all');
  }

  onCampaignGridReady(event: any) {
    this.campaignGrid = event.api;
  }

  onGroupGridReady(event: any) {
    this.groupGrid = event.api;
  }

  onAdGridReady(event: any) {
    this.adsGrid = event.api;
  }

  getCampaigns(groupId: any, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    this.ngLoading = true;
    this.campService.getLocalCampaigns(groupId, params).subscribe(
      (response) => {
        this.rowDataCamp = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataCamp = Observable.create((obs) => {
          obs.next(response.response.campaigns);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => {
        console.log(error);
        this.ngLoading = false;
      }
    )
  }

  getGroups(groupId: any, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    this.ngLoading = true;
    this.campService.getLocalGroups(groupId, params).subscribe(
      (response) => {
        this.rowDataGroups = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataGroups = Observable.create((obs) => {
          // @ts-ignore
          obs.next(response.response.campaign_groups);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => {
        console.log(error);
        this.ngLoading = false;
      }
    )
  }

  getAds() {
    this.ngLoading = true;
    this.campService.getLocalAds(this.adsFilterString).subscribe(
      (response) => {
        this.rowDataAds = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataAds = Observable.create((obs) => {
          obs.next(response.ads);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => {
        console.log(error);
        this.ngLoading = false;
      }
    )
  }

  refreshGrid(type = 'campaign', campaignId: any = null, status = 'online,offline') {
    switch (type) {
      case 'group':
        this.getGroups('', {
          filter: this.groupFilterString,
          dateTo: '',
          dateFrom: '',
          status: status
        });
        break;
      case 'campaign':
        this.getCampaigns('', {
          filter: this.campFilterString,
          dateTo: '',
          dateFrom: '',
          status: status
        });
        break;
      case 'ad':
        this.getAds();
        break;
      case 'all':
        this.getGroups('', {
          filter: this.groupFilterString,
          dateTo: '',
          dateFrom: '',
          status: status
        });
        this.getCampaigns('', {
          filter: this.campFilterString,
          dateTo: '',
          dateFrom: '',
          status: status
        });
        this.getAds();
        break;
    }
  }

  stringSearch(event: KeyboardEvent, group: string) {

  }

  importCampaignFromBasis(): void {
    this.ngLoading  = true;
    this.campService.importBasisCampaigns(this.basisIds, this.all_inventories).subscribe(
      response => {
        console.log(response);
        this.alertService.alert('success', response.response, 8000);
        this.ngLoading  = false;
        this.basisIds = '';
        this.refreshGrid();
      },
      error => {
        console.log(error);
        this.ngLoading  = false;
      }
    )
  }

  importGroupFromBasis(): void {
    this.ngLoading  = true;
    this.campService.importBasisGroups(this.groupIds).subscribe(
      response => {
        console.log(response);
        this.alertService.alert('success', response.response, 8000);
        this.ngLoading  = false;
        this.groupIds = '';
        this.refreshGrid();
      },
      error => {
        console.log(error);
        this.ngLoading  = false;
      }
    )
  }

  importAdsFromBasis(): void {
    this.ngLoading  = true;
    this.campService.importBasisAds(this.adsIds).subscribe(
      response => {
        console.log(response);
        this.alertService.alert('success', 'Ads imported successfully.');
        this.ngLoading  = false;
        this.adsIds = '';
        this.refreshGrid('ad');
      },
      error => {
        console.log(error);
        this.ngLoading  = false;
      }
    )
  }
}
