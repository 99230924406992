import {Component, OnInit, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-total-cell-renderer',
  templateUrl: './total-cell-renderer.component.html',
  styleUrls: ['./total-cell-renderer.component.scss']
})
export class TotalCellRendererComponent implements ICellRendererAngularComp {
// @ts-ignore
  @ViewChild('i') customHtml;
  params;
  total = '0';

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    const column = this.params.column.coldId;
    if (typeof this.params.value === 'undefined' || this.params.value === null) {
      this.total = '';
    } else if (this.params.totalType === 'percent') {
      this.total = (((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? this.params.value : 0) * 100).toFixed(2) + '%';
    } else if (this.params.totalType === 'money') {
      this.total = '$' + ((typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? this.params.value : 0).toFixed(2);
    } else {
      this.total = (typeof this.params.value !== 'undefined' && !isNaN(this.params.value)) ? this.params.value : 0;
    }
  }

  getValue() {
    return this.customHtml.nativeElement.value;
  }

  refresh(): boolean {
    return false;
  }

}
