import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Campaign} from '../../_models/campaign';
import {RangesDatepickerComponent} from '../ranges-datepicker/ranges-datepicker.component';
import {Observable, Observer} from 'rxjs';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {CsvExportParams} from 'ag-grid-community';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import * as moment from 'moment';
import {CampaignsComponent} from '../../pages/campaigns/campaigns.component';
import {GridColumnsCustomizationComponent} from '../grid-columns-customization/grid-columns-customization.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-exchanges-stats',
  templateUrl: './campaign-exchanges-stats.component.html',
  styleUrls: ['./campaign-exchanges-stats.component.scss']
})
export class CampaignExchangesStatsComponent implements OnInit {
  @Input() campaignData: Campaign;
  @Output() refreshDates = new EventEmitter<any>();
  rangesFooter = RangesDatepickerComponent;
  defaultBrandsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' }
  };
  columnDefsEx = [
    // {
    //   headerName: '',
    //   colId: 'checkboxSelect',
    //   width: 40,
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   headerCheckboxSelectionFilteredOnly: true,
    //   sortable: false,
    //   resizable: false,
    //   filter: false,
    //   cellRenderer: '',
    //   cellRendererParams: null,
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    // {
    //   headerName: 'Actions',
    //   field: 'creativeId',
    //   colId: 'actions',
    //   sortable: true,
    //   filter: true,
    //   width: 90,
    //   cellRenderer: 'actionsCellRendererComponent',
    //   cellRendererParams: { renderType: 'Campaign' },
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    {headerName: 'Exchange', sort: 'asc', field: 'name', width: 250, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'Imps. Won', field: 'impressionsWon', width: 100 },
    {headerName: 'Clicks', field: 'clicks', width: 100 },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 100 },
    {headerName: 'Primary Conv.', field: 'conversions', width: 100, cellRenderer: (params) => params.node.data.clickThroughConversions +  params.node.data.viewThroughConversions },
    {headerName: 'Total Eligible Imps.', field: 'eligibleImpressions', width: 100 },
    {headerName: 'Total Viewable Imps.', field: 'viewableImpressions', width: 100 },
    {headerName: 'Adv. Spend', field: 'advertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total Spend', field: 'totalSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPM', field: 'effectiveCPMOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPM', field: 'totalEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend CPC', field: 'effectiveCPCOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPC', field: 'costPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTR', field: 'clickthruRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Adv. Spend CPA', field: 'effectiveCPAOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. Adv. Spend CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPA', field: 'costPerAcquisition', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Prim. Conv. CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click CVR', field: 'clickCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total CVRM', field: 'totalCVRM', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Audio/Video Starts', field: 'videoStarted', width: 100 },
    {headerName: '100% Complete', field: 'videoCompleted', width: 100 },
    {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 100 }
  ];
  rowDataEx: Observable<Array<any>>;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent
  };
  exGrid: any = null;
  exCampaingDateRange: any;
  statusSelected = '';
  exFilterString = '';
  ngLoading = false;
  bulkStatusEdit = '';
  cpmBidBulkEdit = '';
  rangeDatesFlag = false;
  filtersActive = false;
  pinnedRowData: any;
  private currentDateScope = 'one';

  private gridColumns: Array<{text: string, id: string, child: Array<{text: string, id: string}>}> =
    [
      {
        text: 'Auctions',
        id: 'auctions',
        child: [
          {text: 'Imp. Won', id: 'impressionsWon'},
          {text: 'Win Rate', id: 'winRate'},
          {text: 'Total eCPM', id: 'totalEffectiveCPM'},
          {text: 'Adv. Spend eCPM', id: 'effectiveCPMOnAdvertiserSpend'},
          {text: 'Media eCPM', id: 'mediaEffectiveCPM'},
          {text: 'Data eCPM', id: 'dataEffectiveCPM'}
        ]
      },
      {
        text: 'Audio/Video',
        id: 'audio_video',
        child: [
          {text: 'Audio/Video Starts', id: 'videoStarted'},
          {text: '25% Complete', id: 'videoFirstQuartileReached'},
          {text: '50% Complete', id: 'videoMidpointReached'},
          {text: '75% Complete', id: 'videoThirdQuartileReached'},
          {text: '100% Complete', id: 'videoCompleted'},
          {text: 'Completion Rate', id: 'videoCompletionRate'},
          {text: 'Adv. Spend eCPCV', id: 'effectiveCPCVOnAdvertiserSpend'},
          {text: 'Total eCPCV', id: 'totalEffectiveCPCV'},
        ]
      },
      {
        text: 'Budget',
        id: 'budget',
        child: [
          {text: 'Total Spend', id: 'totalSpend'},
          {text: 'CPM Bid', id: 'impliedBid'},
          {text: 'Daily Budget', id: 'dailyTotalSpendTarget'},
          {text: 'All Time Budget', id: 'budget'},
          {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
          {text: 'Adv. Spend', id: 'advertiserSpend'},
          {text: 'Media Spend', id: 'auctionsSpend'},
          {text: 'Data Spend', id: 'dataSpend'},
          {text: 'Actual Margin %', id: 'marginOnAdvertiserSpend'},
          {text: 'Max Billable', id: 'advertiserSpendRate'}
        ]
      },
      {
        text: 'Clicks',
        id: 'clicks',
        child: [
          {text: 'Clicks', id: 'clicks'},
          {text: 'Adv. Spend eCPC', id: 'effectiveCPCOnAdvertiserSpend'},
          {text: 'CTR', id: 'costPerClick'},
          {text: 'EPC', id: 'profitPerClick'},
        ]
      },
      {
        text: 'Companion Ads',
        id: 'companion_ads',
        child: [
          {text: 'Companion Imps. Won', id: 'companionImpressions'},
          {text: 'Companion Clicks', id: 'companionClicks'},
          {text: 'Companion CTC', id: 'companionConversions'},
          {text: 'Companion CTC Revenue', id: 'companionOfferRevenue'}
        ]
      },
      {
        text: 'Conversions',
        id: 'conversions',
        child: [
          {text: 'Total Conversions', id: 'totalConversions'},
          {text: 'CTC', id: 'clickThroughConversions'},
          {text: 'VTC', id: 'viewthruConversions'},
          {text: 'Adv. Spend eCPA', id: 'effectiveCPAOnAdvertiserSpend'},
          {text: 'Total eCPA', id: 'effectiveCPA'},
          {text: 'CTC eCPA', id: 'ctcEffectiveCPA'},
          {text: 'Click CVR', id: 'clickCVR'},
          {text: 'View CVR', id: 'viewCVR'},
          {text: 'Total CVRM', id: 'totalCVRM'},
        ]
      },
      {
        text: 'Extras',
        id: 'extras',
        child: [
          {text: 'Group ID', id: 'campaignGroupId'}
        ]
      },
      {
        text: 'Primary Conversions',
        id: 'primary_conversions',
        child: [
          {text: 'Primary Conv.', id: 'conversions'},
          {text: 'Primary CTC', id: 'primaryCTC'},
          {text: 'Primary VTC', id: 'primaryVTC'},
          {text: 'Primary Adv. Spend eCPA', id: 'primaryEffectiveCPASpend'},
          {text: 'Primary Conv. eCPA', id: 'primaryEffectiveCPAConversion'},
          {text: 'Primary CTC eCPA', id: 'primaryEffectiveCPACTC'},
        ]
      },
      {
        text: 'Revenue',
        id: 'revenues',
        child: [
          {text: 'Total Revenue', id: 'revenue'},
          {text: 'CTC Revenue', id: 'ctcRevenue'},
          {text: 'VTC Revenue', id: 'vtcRevenue'},
          {text: 'Total RPM', id: 'revenuePerMille'},
          {text: 'Click RPM', id: 'clickRPM'},
          {text: 'ROAS', id: 'returnOnAdSpend'},
        ]
      },
      {
        text: 'Viewability',
        id: 'viewability',
        child: [
          {text: 'Total Eligible Imps.', id: 'eligibleImpressions'},
          {text: 'Total Measured Imps.', id: 'measuredImpressions'},
          {text: 'Total Viewable Imps.', id: 'viewableImpressions'},
          {text: 'Measured Rate', id: 'measuredRate'},
          {text: 'Viewable Rate', id: 'viewableRate'},
          {text: 'Eligible Spend', id: 'eligibleSpend'},
          {text: 'Eligible vCPM', id: 'eligibleVCPM'},
        ]
      }
    ];

  columnSetup = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks','effectiveCPCOnAdvertiserSpend','costPerClick','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','effectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];
  columnSetupDefault = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks','effectiveCPCOnAdvertiserSpend','costPerClick','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','effectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];


  constructor(private campaignService: CampaignService, private modalService: NgbModal, private gridRender: GridRenderService, public dialog: MatDialog) {
    this.initializeRangeDates();
  }

  ngOnInit() {
    const exCustomColumns = localStorage.getItem('exCustomColumns');
    if (exCustomColumns !== null) {
      this.columnSetup = JSON.parse(exCustomColumns);
    }
    this.rowDataEx = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
      ob.next([]);
      ob.complete();
    });
  }

  getCampaignExStats(campaignId) {
    this.ngLoading = true;
    this.campaignService.getCampaignExchangesStats(campaignId,
      // @ts-ignore
      {
        filter: this.exFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.exCampaingDateRange !== null && typeof this.exCampaingDateRange !== 'undefined') ? this.exCampaingDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.exCampaingDateRange !== null && typeof this.exCampaingDateRange !== 'undefined') ? this.exCampaingDateRange.begin.format('YYYYMMDD') : '',
        status: this.statusSelected
      }
    ).subscribe(
      (response) => {
        console.log(response);
        const pinnedData = {
          impressionsWon: 0,
          clicks: 0,
          totalConversions: 0,
          conversions: 0,
          eligibleImpressions: 0,
          viewableImpressions: 0,
          advertiserSpend: 0,
          totalSpend: 0,
          effectiveCPMOnAdvertiserSpend: 0,
          totalEffectiveCPM: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          costPerClick: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          costPerAcquisition: 0,
          videoCompleted: 0,
          videoCompletionRate: 0
        };
        if (typeof response !== 'undefined' && response !== null) {
          for (const i in response) {
            // const margin = (typeof response[i]['extraData'] !== 'undefined' && response[i]['extraData'].margin > 1) ?
            //   ((100 - response[i]['extraData'].margin) / 100) : 1;
            const margin = 1;
            response[i].advertiserSpend = isNaN(response[i].advertiserSpend) ? 0 : response[i].advertiserSpend / margin;
            response[i].totalSpend = isNaN(response[i].totalSpend) ? 0 : response[i].totalSpend / margin;
            response[i].effectiveCPMOnAdvertiserSpend = isNaN(response[i].effectiveCPMOnAdvertiserSpend) ? 0 : response[i].effectiveCPMOnAdvertiserSpend / margin;
            response[i].totalEffectiveCPM = isNaN(response[i].totalEffectiveCPM) ? 0 : response[i].totalEffectiveCPM / margin;
            response[i].effectiveCPCOnAdvertiserSpend = isNaN(response[i].effectiveCPCOnAdvertiserSpend) ? 0 : response[i].effectiveCPCOnAdvertiserSpend / margin;
            response[i].costPerClick = isNaN(response[i].costPerClick) ? 0 : response[i].costPerClick / margin;
            response[i].effectiveCPAOnAdvertiserSpend = isNaN(response[i].effectiveCPAOnAdvertiserSpend) ? 0 : response[i].effectiveCPAOnAdvertiserSpend / margin;
            response[i].costPerAcquisition = isNaN(response[i].costPerAcquisition) ? 0 : response[i].costPerAcquisition / margin;

            pinnedData.impressionsWon += isNaN(response[i].impressionsWon) ? 0 : response[i].impressionsWon;
            pinnedData.videoCompleted += isNaN(response[i].videoCompleted) ? 0 : response[i].videoCompleted;
            pinnedData.videoCompletionRate += isNaN(response[i].videoCompletionRate) ? 0 : response[i].videoCompletionRate;
            pinnedData.totalEffectiveCPM += isNaN(response[i].totalEffectiveCPM) ? 0 : response[i].totalEffectiveCPM / margin;
            pinnedData.totalSpend += isNaN(response[i].totalSpend) ? 0 : response[i].totalSpend / margin;
            pinnedData.clicks += isNaN(response[i].clicks) ? 0 : response[i].clicks;
            pinnedData.totalConversions += isNaN(response[i].totalConversions) ? 0 : response[i].totalConversions;
            pinnedData.conversions += isNaN(response[i].conversions) ? 0 : response[i].conversions;
            pinnedData.eligibleImpressions += isNaN(response[i].eligibleImpressions) ? 0 : response[i].eligibleImpressions;
            pinnedData.viewableImpressions += isNaN(response[i].viewableImpressions) ? 0 : response[i].viewableImpressions;
            pinnedData.costPerAcquisition += isNaN(response[i].costPerAcquisition) ? 0 : response[i].costPerAcquisition / margin;
            pinnedData.advertiserSpend += isNaN(response[i].advertiserSpend) ? 0 : response[i].advertiserSpend / margin;
            pinnedData.totalSpend += isNaN(response[i].totalSpend) ? 0 : response[i].totalSpend / margin;
            pinnedData.effectiveCPMOnAdvertiserSpend += isNaN(response[i].effectiveCPMOnAdvertiserSpend) ? 0 : response[i].effectiveCPMOnAdvertiserSpend / margin;
            pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response[i].effectiveCPCOnAdvertiserSpend) ? 0 : response[i].effectiveCPCOnAdvertiserSpend / margin;
            pinnedData.costPerClick += isNaN(response[i].costPerClick) ? 0 : response[i].costPerClick / margin;
            pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response[i].effectiveCPAOnAdvertiserSpend) ? 0 : response[i].effectiveCPAOnAdvertiserSpend / margin;
          }
        }
        pinnedData.totalEffectiveCPM = (pinnedData.totalSpend / pinnedData.impressionsWon) * 1000;
        pinnedData.costPerClick = (pinnedData.clicks > 0 ? pinnedData.totalSpend / pinnedData.clicks : 0);
        this.pinnedRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
        this.rowDataEx = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
          // @ts-ignore
          ob.next(response);
          ob.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'getCampaignExStats'); this.ngLoading = false; }
    )
  }

  cellClickedHanlder(node) {
    console.log(node);
    if (node.column.colId === 'impliedStatus') {
      if (node.event.target.firstElementChild !== null
        && typeof node.event.target.firstElementChild.value !== 'undefined'
        && node.event.target.firstElementChild.value === 'on') {
        console.log(node.event.target.firstElementChild.value);
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [{identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'online', type: node.data.type}],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignExStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      } else {
        console.log(node.event.target);
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [{identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'offline', type: node.data.type}],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignExStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      }
    }
  }

  onAdsGridReady(params: any) {
    this.exGrid = params.api;
    this.updateColumns();
  }

  refreshGrid() {
    this.currentDateScope = 'one';
    this.getCampaignExStats(this.campaignData.campaignId);
  }

  exportDomains() {
    this.exGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params, 'ads');
      }
    } as CsvExportParams);
  }

  rangeChanged(event: SatDatepickerInputEvent<any>, input: HTMLInputElement = null) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      this.exCampaingDateRange.begin = this.exCampaingDateRange.begin.subtract(1, 'days');
      this.exCampaingDateRange.end = this.exCampaingDateRange.end.subtract(1, 'days');
      input.value = this.exCampaingDateRange.begin.format('MM/DD/YYYY') + ' - ' + this.exCampaingDateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      this.currentDateScope = 'one';
      if (event['value']['extraData'] === 'fromButtonAll') {
        this.currentDateScope = 'all';
        this.refreshDates.emit({'event':  event, 'input': input});
      } else if (savedGridDates !== null && typeof savedGridDates === 'object') {
        savedGridDates = {
          ...savedGridDates,
          exCampaingDateRange: this.exCampaingDateRange
        };
      } else {
        savedGridDates = {
          exCampaingDateRange: this.exCampaingDateRange
        };
      }
      // savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
      //   ? {
      //     ...savedGridDates,
      //     exCampaingDateRange: this.exCampaingDateRange
      //   }
      //   : {
      //     exCampaingDateRange: this.exCampaingDateRange
      //   };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
  }

  closedDatesRange() {
    if (this.rangeDatesFlag && this.currentDateScope === 'one') {
      this.refreshGrid();
    } else {
      this.initializeRangeDates();
    }
    this.rangeDatesFlag = false;
  }

  initializeRangeDates() {
    const savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
    if (savedGridDates !== null && typeof savedGridDates === 'object') {
      if (typeof savedGridDates.exCampaingDateRange !== 'undefined') {
        this.exCampaingDateRange = {begin: moment(savedGridDates.exCampaingDateRange.begin), end: moment(savedGridDates.exCampaingDateRange.end)};
      }
    }
  }

  filterChanged(event: any) {
    this.filtersActive = Object.keys(event.api.getFilterModel()).length > 0;
  }
  updateColumns(): void {
    let visibleColumns = [];
    if (this.columnSetup.length > 0) {
      visibleColumns = this.columnSetup;
    }
    console.log('visibleColumns: ', visibleColumns);
    this.exGrid.columnController.columnApi.setColumnsVisible(this.columnSetupDefault, false);
    this.exGrid.columnController.columnApi.setColumnsVisible(visibleColumns, true);
    this.exGrid.columnController.columnApi.autoSizeAllColumns();
  }

  customizeColumns() {
    const dialogRef = this.dialog.open(GridColumnsCustomizationComponent, {
      maxWidth: '1050px',
      width: '600px',
      disableClose: true,
      data: {currentColumns: this.columnSetup, gridColumns: this.gridColumns},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (typeof result !== 'undefined' && result !== null) {
        // this._reportsService.saveReportColumnsCustomization({name: result.name, columns: result.columns, ad_account_id: this.filterForm.get('adAccountId').value}).subscribe(
        //   response => {
        //     console.log(response);
        //   }, error => console.log(error)
        // );
        localStorage.setItem('exCustomColumns', JSON.stringify(result.columns));
        this.columnSetup = result.columns;
        this.updateColumns();
      }
    });
  }
}
