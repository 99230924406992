import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {ThirdPartyDataService} from '../../_services/third-party-data/third-party-data.service';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {MatSelectionList} from '@angular/material/list';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-audiences-rules',
  templateUrl: './audiences-rules.component.html',
  styleUrls: ['./audiences-rules.component.scss']
})
export class AudiencesRulesComponent implements OnInit {
  newAudiencesForm: FormGroup;
  audiencesThirdPartyData: TreeviewItem[] = [];
  audiencesTab = 'audiences';
  audiences = [];
  configThirdPartyTree = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
  });
  newAudiencesFormextraData = {};
  selectedAudienceGroup = 0;
  campaignList = '/campaigns/';
  // @ts-ignore
  @ViewChild('myAudiencesList') myAudiencesList: MatSelectionList;
  @Input() brandId: number;
  @Input() parentType = 'modal';
  @Output() setDataRules = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<string>();
  ngLoading = false;

  constructor(private fb: FormBuilder, private thirdSourceService: ThirdPartyDataService, private audienceService: AudiencesService, private alert: AlertService) { }

  ngOnInit() {
    this.newAudiencesForm = this.fb.group({
      compositeAudienceTargetingRule: this.fb.group({
        type: ['composite'],
        logicalOperator: ['OR'],
        rules:  this.fb.array([])
      }),
    });
  }

  audiencesTabChanged(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.audiencesThirdPartyTreeData();
    }
  }

  audiencesThirdPartyTreeData(item = null) {
    if ((this.audiencesThirdPartyData.length === 0 && item === null) || (item !== null && item.children.length <= 1)) {
      this.thirdSourceService.getAudienceSegments(item).subscribe(
        (response) => {
          if (item !== null) { item.children.shift(); }
          let position = 0;
          for (const context in response[0]) {
            if (item === null) {
              this.audiencesThirdPartyData.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].demographicId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].demographicId}
                ] : null)
              }, true));
              this.audiencesThirdPartyData[position]['level'] = 0;
              this.audiencesThirdPartyData[position]['targetable'] = response[0][context].targetable;
              this.audiencesThirdPartyData[position]['price'] = response[0][context].price;
              this.audiencesThirdPartyData[position].setCollapsedRecursive(true);
              this.audiencesThirdPartyData[position].setCheckedRecursive(false);
            } else {
              item.children.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].demographicId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].demographicId}
                ] : null)
              }, true));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              item.children[position]['price'] = response[0][context].price;
              item.children[position]['reach'] = response[0][context].reach;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              item.children[position].setCheckedRecursive(item.checked);
            }
            position++;
          }
          console.log(response, this.audiencesThirdPartyData)
        },
        (error) => {
          console.log(error, 'getAudienceSegments');
        }
      )
    }
  }

  getAudiences() {
    if (this.audiences.length === 0 ) {
      console.log('this.brandId: ', this.brandId);
      this.audienceService.getAudiencesStats(this.brandId).subscribe(
        (response) => {
          this.audiences = response.response.audiences;
        },
        (error) => {
          console.log(error, 'audienceService');
        }
      )
    }
  }

  audiencesThirdPartySelectedChanged(event: any[]) {
  }

  addAudienceRules(selectedGroup = -1) {
    const newRules: FormArray = this.fb.array([]);
    if (this.myAudiencesList.selectedOptions.selected.length > 0) {
      for (const myAud of this.myAudiencesList.selectedOptions.selected) {
        if (selectedGroup > -1) {
          const currentGroups = this.newAudiencesForm.controls.compositeAudienceTargetingRule['controls'].rules.controls[selectedGroup].controls.rules.value;
          if (currentGroups.findIndex(i => i.audienceId === myAud.value.audienceId) !== -1) {
            this.alert.alert('error', 'You can not have an Audience twice in the same group.', 10000);
          } else {
            this.newAudiencesFormextraData[myAud.value.audienceId] = {
              checked: true,
              text: myAud.value.name,
              value: myAud.value.audienceId,
              level: 1,
              targetable: true,
              providerId: 0,
              providerName: 'My Audiences',
            };
            // @ts-ignore
            // tslint:disable-next-line:max-line-length
            (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules as FormArray).push(
              this.fb.group({
                type: ['atomic'],
                audienceId: [myAud.value.audienceId],
                audienceType: ['FIRST_PARTY'],
                fromMillisSinceLastAccess: [0],
                toMillisSinceLastAccess: [0],
                blocked: [false]
              })
            );
          }
        } else {
          this.newAudiencesFormextraData[myAud.value.audienceId] = {
            checked: true,
            text: myAud.value.name,
            value: myAud.value.audienceId,
            level: 1,
            targetable: true,
            providerId: 0,
            providerName: 'My Audiences',
          };
          newRules.push(this.fb.group({
            type: ['atomic'],
            audienceId: [myAud.value.audienceId],
            audienceType: ['FIRST_PARTY'],
            fromMillisSinceLastAccess: [0],
            toMillisSinceLastAccess: [0],
            blocked: [false]
          }))
        }
      }
      this.myAudiencesList.deselectAll();
    }
    if (this.audiencesThirdPartyData.length > 0) {
      for (const item of this.audiencesThirdPartyData) {
        const selectedItems = item.getSelection();
        if (selectedItems.checkedItems && selectedItems.checkedItems.length > 0) {
          for (const thirdAud of selectedItems.checkedItems) {
            if (selectedGroup > -1) {
              const currentGroups = this.newAudiencesForm.controls.compositeAudienceTargetingRule['controls'].rules.controls[selectedGroup].controls.rules.value;
              if (currentGroups.findIndex(i => i.audienceId === thirdAud.value) !== -1) {
                this.alert.alert('error', 'You can not have an Audience twice in the same group.', 10000);
              } else {
                this.newAudiencesFormextraData[thirdAud.value] = thirdAud;
                // tslint:disable-next-line:max-line-length
                (this.newAudiencesForm.controls.compositeAudienceTargetingRule['controls'].rules.controls[selectedGroup].controls.rules as FormArray).push(
                  this.fb.group({
                    type: ['atomic'],
                    audienceId: [thirdAud.value],
                    audienceType: ['THIRD_PARTY'],
                    fromMillisSinceLastAccess: [0],
                    toMillisSinceLastAccess: [0],
                    blocked: [false]
                  })
                );
              }
            } else {
              this.newAudiencesFormextraData[thirdAud.value] = thirdAud;
              newRules.push(this.fb.group({
                type: ['atomic'],
                audienceId: [thirdAud.value],
                audienceType: ['THIRD_PARTY'],
                fromMillisSinceLastAccess: [0],
                toMillisSinceLastAccess: [0],
                blocked: [false]
              }))
            }
          }
        }
        item.setCheckedRecursive(false);
      }
    }
    if (newRules.length === 1) {
      // @ts-ignore
      (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).push(newRules.controls[0]);
    } else if (newRules.length > 1) {
      // @ts-ignore
      (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).push(
        this.fb.group({
          type: ['composite'],
          logicalOperator: ['OR'],
          rules: newRules
        }));
    }
  }

  selectAudienceGroup(pos) {
    this.selectedAudienceGroup = pos;
  }

  removeAudienceRule(position) {
    (this.newAudiencesForm.controls.compositeAudienceTargetingRule['controls'].rules as FormArray).removeAt(position);
  }

  cancelModal() {
    $('.audiences-rules-modal').modal('hide');
  }

  saveRules() {
    this.ngLoading = true;
    this.setDataRules.emit(this.newAudiencesForm.value);
  }
}
