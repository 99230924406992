import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserRestService} from '../../admin/users/user-rest.service';
import {AlertService} from '../../_services/alert.service';
import {User} from '../../_models/user';
import {CommonAuthService} from '../common-auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changeForm: FormGroup;
  ngLoading = false;
  constructor(private fb: FormBuilder, private userService: UserRestService, private alert: AlertService, private currentUser: CommonAuthService) { }

  ngOnInit() {
    this.changeForm = this.fb.group({
      oldPassword: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    });
    this.changeForm.controls.confirmPassword.setValidators(this.confirmPasswordValidator(this.changeForm));
  }

  onSubmit() {
    this.ngLoading = true;
    this.userService.updatePassword(this.changeForm.value, this.currentUser.currentUserValue.id).subscribe(
      (response) => {
        this.alert.alert('success', 'Password changed successfully.');
        this.ngLoading = false;
        this.closeModal();
      }, (error) => {
        console.log(error, 'updatePassword'); this.ngLoading = false;
      }
    )
  }

  confirmPasswordValidator(userForm: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && control.value !== userForm.get('password').value) {
        return { 'confirmPassword': true };
      }
      return null;
    };
  }

  closeModal() {
    this.changeForm.reset();
    $('.change-password-modal').modal('hide');
  }
}
