import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormBuilder, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';

@Component({
  selector: 'app-new-vast-video-modal',
  templateUrl: './new-vast-video-modal.component.html',
  styleUrls: ['./new-vast-video-modal.component.scss']
})
export class NewVastVideoModalComponent extends NewAdComponent implements OnInit {

  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }
  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getVendors();
    this.newForm = this.fb.group({
      adType: ['vast_video'],
      label: ['', Validators.required],
      status: ['online'],
      width: [''],
      height: [''],
      type: ['vast_video'],
      vastUrl: ['', Validators.required],
      sslEnabled: [true],
      // tslint:disable-next-line:max-line-length
      landingPageDomain: ['', [Validators.pattern(this.domainRegex), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      customerVastEvents: [''],
      apis: [''],
      vendors: ['']
    });
    // this._parent.newForm = this.newForm;
  }

  vendorsGridInit(event: any) {
    this.vendorsApiGrid = event.api;
  }

}
