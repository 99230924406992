import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-domainlist-modal',
  templateUrl: './campaign-domainlist-modal.component.html',
  styleUrls: ['./campaign-domainlist-modal.component.scss']
})
export class CampaignDomainlistModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }
  columnDefsDomain = [
    {headerName: 'Name', field: 'name', width: 300 },
    {headerName: 'ID', field: 'id', width: 100 },
    {headerName: 'Type', field: 'type', width: 100 },
    {headerName: 'URL Count', field: 'count', width: 100 },
  ];
  rowDataDomain = [];

  constructor() { }

  ngOnInit() {
  }

}
