import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RoleBasic} from '../../_models/enums';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Client} from '../../_models/client';
import {UserRestService} from '../../admin/users/user-rest.service';
import {AlertService} from '../../_services/alert.service';
import {ActivatedRoute} from '@angular/router';
import {CommonAuthService} from '../../auth/common-auth.service';
import {ClientService} from '../../_services/client/client.service';
import {SubscriptionService} from '../../_services/subscription/subscription.service';
import {User} from '../../_models/user';
declare var CollectJS;

@Component({
  selector: 'app-new-subscription-modal',
  templateUrl: './new-subscription-modal.component.html',
  styleUrls: ['./new-subscription-modal.component.scss']
})
export class NewSubscriptionModalComponent implements OnInit {

  ngLoading = false;
  acceptTerms = false;
  newSubsForm: FormGroup;
  amount = 100;
  height = 500;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userService: UserRestService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private auth: CommonAuthService,
    private clientService: ClientService,
    private subsService: SubscriptionService
  ) { }

  ngOnInit() {
    this.newSubsForm = this.fb.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      address1: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      amount: ['', Validators.required],
      variant: ['', Validators.required],
      payment_token: ['', Validators.required],
    });
  }

  onSubmit() {
    event.preventDefault();
    if (document.getElementById('payment_token').getAttribute('value') !== '') {
      this.formControls.payment_token.setValue(document.getElementById('payment_token').getAttribute('value'));
      this.formControls.amount.setValue(this.amount);
      console.log(this.formControls);
      this.subsService.startSubscription(this.newSubsForm.value)
        .subscribe(response => {
            console.log('response:', response);
            localStorage.setItem('user', response.user);
            localStorage.setItem('user2', response.user);
            this.newSubsForm.reset();
            $('.new-subscription-modal').modal('hide');
            this.ngOnInit();
            if (response.status === 200) {
              this.alertService.alert('success', 'Subscription created successfully.');
            }
            this.refreshGrid.emit();
            location.reload();
            this.ngLoading = false;
          },
          error => {
            console.log('error: ', error);
            if (error.status === 200) {
              localStorage.setItem('user', error.user);
              localStorage.setItem('user2', error.user);
              this.newSubsForm.reset();
              $('.new-subscription-modal').modal('hide');
              this.ngOnInit();
              if (error.status === 200) {
                this.alertService.alert('success', 'Subscription created successfully.');
              }
              this.refreshGrid.emit();
              location.reload();
            } else {
              this.alertService.alert('error', error.error);
            }
            this.ngLoading = false;
          });
    } else {
      this.ngLoading = true;
      CollectJS.startPaymentRequest();
    }
  }

  get formControls() {
    return this.newSubsForm.controls;
  }

  changeAmount(amount) {
    this.amount = amount;
  }

  setAll(event) {
    this.acceptTerms = event;
    if (event) {
      this.height = 300;
      CollectJS.configure({
        'validationCallback' : function(field, status, message) {
          let message1 = '';
          if (status) {
            message1 = field + ' is now OK: ' + message;
          } else {
            message1 = field + ' is now Invalid: ' + message;
          }
          console.log(message1);
        },
        'timeoutDuration' : 5000,
        'timeoutCallback' : function () {
          console.log('The tokenization didn\'t respond in the expected timeframe.  ' +
            'This could be due to an invalid or incomplete field or poor connectivity');
        },
        'fieldsAvailableCallback' : function () {
          console.log('Collect.js loaded the fields onto the form');
        },
        'callback' : function(response) {
          document.getElementById('payment_token').setAttribute('value', response.token);
          const amount = document.getElementById('amount').getAttribute('value');
          if (amount === '100') {
            document.getElementById('payButton').click();
          } else {
            document.getElementById('payButton1').click();
          }
        },
        variant: 'inline',
        googleFont: 'Abel',
        invalidCss: {
          color: '#B40E3E'
        },
        validCss: {
          color: '#14855F'
        },
        customCss: {
          'border-color': '#FFFFFF',
          'border-style': 'solid'
        },
        focusCss: {
          'border-color': '#1CC48B',
          'border-style': 'solid',
          'border-width': '3px'
        },
        fields: {
          cvv: {
            placeholder: 'CVV'
          },
          ccnumber: {
            placeholder: 'Credit Card'
          },
          ccexp: {
            placeholder: 'MM / YY'
          }
        }
      });
    } else {
      this.height = 500;
    }
  }

  closeSubsModal() {
    this.newSubsForm.reset();
    $('.new-subscription-modal').modal('hide');
    this.acceptTerms = false;
    this.height = 500;
  }

  resetForm() {
    this.newSubsForm.reset();
    this.ngOnInit();
  }

}
