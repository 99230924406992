import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {InventoryComponent} from '../../pages/inventory/inventory.component';
import {Observable} from 'rxjs';
import {DomainListService} from '../../_services/domain-list/domain-list.service';
import {Domains} from '../../_models/domains';
import {BrandsComponent} from '../../pages/brands/brands.component';

@Component({
  selector: 'app-add-domainapp-current-domain-modal',
  templateUrl: './add-domainapp-current-domain-modal.component.html',
  styleUrls: ['./add-domainapp-current-domain-modal.component.scss']
})
export class AddDomainappCurrentDomainModalComponent implements OnInit {
  _parent: InventoryComponent ;
  @Input() parent;
  @Output() refreshGrid = new EventEmitter<string>();
  public addDom = 0;
  rowSelection = 'single';
  disabledSubmit = true;
  selectedRows = [];
  ngLoading = false;

  rowData: any;
  columnDefs = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 200 },
    {headerName: 'Type', field: 'type', width: 100 },
    {headerName: 'URL Count', field: 'urlCount', width: 50, cellRenderer: this.medalCellRenderer },
  ];

  constructor(
    private domainListService: DomainListService,
    private alertService: AlertService,
    private auth: CommonAuthService
  ) { }

  ngOnInit() {
      this._parent = this.parent;
  }

  onSubmit() {
    this.ngLoading = true;
    let list = [];
    for (let aux of this._parent.groupSelectedRows) {
      if (aux.indexOf('|') !== -1) {
        list.push(aux.split('|')[1].trim());
      } else {
        list.push(aux);
      }
    }
    this.domainListService.addDomainsToList({domains: list, listId: this.selectedRows[0]})
      .subscribe(response => {
        console.log(response);
        this.ngOnInit();
        $('.add-domain-list-modal').modal('hide');
        if ( String(response.status) === 'SUCCESS' ) {
          this.alertService.alert('success', 'Domain List updated successfully.');
        }
        this.ngLoading = false;
      });
  }

  medalCellRenderer(params) {
    console.log(params);
    return params.data.domains.length;
  }

  changeSearchName() {
    this.rowData = null;
    this.getDomainList();
  }

  rowSelected(event: any) {
    this.selectedRows = event.api.getSelectedNodes().map((value, index, array) => value.data.domainListId);
    console.log(this.selectedRows);
    if(this.selectedRows.length > 0)
      this.disabledSubmit = false;
    else
      this.disabledSubmit = true;
  }

  getDomainList() {
    this.ngLoading = true;
    this.domainListService.getDomainList({ name: $('#search_name').val().toString() }).subscribe(
      (response) => {
        console.log(response);
        this.disabledSubmit = true;
        this.rowData =  Observable.create((obs) => {
          obs.next(response.response.domain_lists);
          obs.complete();
        });
        this.ngLoading = false;
      });
  }

}
