import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CampaignGroupsResponse, CampaignResponse, CampaignsResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Observable} from 'rxjs';
import {Campaign} from '../../_models/campaign';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private campsUrl = 'campaigns';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getCampaigns(campaignGroupId: number | bigint, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0): Observable<CampaignsResponse> {
    return this.http.get<CampaignsResponse>(
      `${environment.apiUrl}` + this.campsUrl
      + '?campaignGroupId=' + campaignGroupId + '&max=' + max + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched campaigns')),
      catchError(Errors.handleError<CampaignsResponse>('getCampaigns'))
    );
  }

  importBasisCampaigns(ids: string, allInventories = true) {
    return this.http.post<any>(
      `${environment.apiUrl}` + 'support/import/campaigns', {ids: ids.split(','), allInventories: allInventories}
    ).pipe(
      tap(_ => console.log('fetched campaigns')),
      catchError(Errors.handleError<CampaignsResponse>('getCampaigns'))
    );
  }

  importBasisGroups(ids: string) {
    return this.http.post<any>(
      `${environment.apiUrl}` + 'support/import/groups', {ids: ids.split(',')}
    ).pipe(
      tap(_ => console.log('fetched importBasisGroups')),
      catchError(Errors.handleError<CampaignGroupsResponse>('importBasisGroups'))
    );
  }

  importBasisAds(ids: string) {
    return this.http.post<any>(
      `${environment.apiUrl}` + 'support/import/ads', {ids: ids.split(',')}
    ).pipe(
      tap(_ => console.log('fetched ads')),
      catchError(Errors.handleError<CampaignsResponse>('getads'))
    );
  }

  getLocalCampaigns(campaignGroupId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0): Observable<CampaignsResponse> {
    return this.http.get<CampaignsResponse>(
      `${environment.apiUrl}` + this.campsUrl + '/index-dash'
      + '?campaignGroupId=' + campaignGroupId + '&max=' + 1000 + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched campaigns')),
      catchError(Errors.handleError<CampaignsResponse>('getCampaigns'))
    );
  }

  getLocalGroups(campaignGroupId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0): Observable<CampaignGroupsResponse> {
    return this.http.get<CampaignGroupsResponse>(
      `${environment.apiUrl}` + 'campaign-groups/index-dash'
      + '?campaignGroupId=' + campaignGroupId + '&max=' + 1000 + '&offset='
      + offset + '&filter=' + params.filter + '&dateTo='
      + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched campaigns')),
      catchError(Errors.handleError<CampaignGroupsResponse>('getCampaigns'))
    );
  }

  getLocalAds(filter: string = ''): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}` + 'ads/local' + (filter !== '' ? '?filter=' + filter : '')
    ).pipe(
      tap(_ => console.log('fetched ads')),
      catchError(Errors.handleError<any>('getAds'))
    );
  }

  getCampaignById(id: any, brandId = null) {
    return this.http.get<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl + '/' + id + (brandId !== null ? ('?brandId=' + brandId) : '')).pipe(
      tap(_ => console.log('fetched campaign')),
      catchError(Errors.handleError<CampaignResponse>('getCampaignById'))
    );
  }

  createCampaign(campaign: Campaign): Observable<CampaignResponse> {
    return this.http.post<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl, campaign, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createCampaign', campaign))
      ) as Observable<CampaignResponse>;
  }

  createCampaignTest(campaignData): Observable<CampaignResponse> {
    return this.http.post<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl, campaignData, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createCampaign', campaignData))
      ) as Observable<CampaignResponse>;
  }

  updateCampaign(campaign: Campaign, id: number): Observable<CampaignResponse>  {
    return this.http.put<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl + '/' + id, campaign, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateCampaign', campaign))
      ) as Observable<CampaignResponse>;
  }

  copyCampaign(data: any, id: number): Observable<any>  {
    return this.http.post<any>(`${environment.apiUrl}` + this.campsUrl + '/copy/' + id, data, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateCampaign', data))
      ) as Observable<any>;
  }

  updatePartiallyCampaign(campaign: Campaign, id: number): Observable<CampaignResponse>  {
    return this.http.patch<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl + '/partially/' + id, campaign, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updatePartiallyCampaign', campaign))
      ) as Observable<CampaignResponse>;
  }

  deleteCampaign(id: string) {
    return this.http.delete<CampaignResponse>(`${environment.apiUrl}` + this.campsUrl + '/' + id).pipe(
      tap(_ => console.log('deleted group')),
      catchError(Errors.handleError<CampaignResponse>('deleteCampaign'))
    );
  }

  getAlgorithmicOptimization(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/optimization/algorithmic').pipe(
      tap(_ => console.log('fetched getAlgorithmicOptimization')),
      catchError(Errors.handleError('getAlgorithmicOptimization'))
    );
  }

  getMachineLearningOptimization(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/optimization/machineLearning').pipe(
      tap(_ => console.log('fetched getMachineLearningOptimization')),
      catchError(Errors.handleError('getMachineLearningOptimization'))
    );
  }

  getPagePositions(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/pagePositions').pipe(
      tap(_ => console.log('fetched getPagePositions')),
      catchError(Errors.handleError('getPagePositions'))
    );
  }

  getTrafficType(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/trafficType').pipe(
      tap(_ => console.log('fetched getTrafficType')),
      catchError(Errors.handleError('getTrafficType'))
    );
  }

  getGeoTargeting(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/geo').pipe(
      tap(_ => console.log('fetched getGeoTargeting')),
      catchError(Errors.handleError('getGeoTargeting'))
    );
  }

  getContextualTargeting(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/contextual').pipe(
      tap(_ => console.log('fetched getContextualTargeting')),
      catchError(Errors.handleError('getContextualTargeting'))
    );
  }

  getHyperlocalTargeting(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/hyperlocal').pipe(
      tap(_ => console.log('fetched getHyperlocalTargeting')),
      catchError(Errors.handleError('getHyperlocalTargeting'))
    );
  }

  getDevicesTargeting(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/devices').pipe(
      tap(_ => console.log('fetched getDevicesTargeting')),
      catchError(Errors.handleError('getDevicesTargeting'))
    );
  }

  getDeals(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/deals').pipe(
      tap(_ => console.log('fetched getDeals')),
      catchError(Errors.handleError('getDeals'))
    );
  }

  getAudienceTargeting(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/audiences').pipe(
      tap(_ => console.log('fetched getAudienceTargeting')),
      catchError(Errors.handleError('getAudienceTargeting'))
    );
  }

  getBidMultipliers(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/bid-multipliers').pipe(
      tap(_ => console.log('fetched getBidMultipliers')),
      catchError(Errors.handleError('getBidMultipliers'))
    );
  }

  getOnlyValidTracks(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/track-only-valids').pipe(
      tap(_ => console.log('fetched getBidMultipliers')),
      catchError(Errors.handleError('getBidMultipliers'))
    );
  }

  getInventoryUnitsData(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/inventoryUnits').pipe(
      tap(_ => console.log('fetched getInventoryUnitsData')),
      catchError(Errors.handleError('getInventoryUnitsData'))
    );
  }

  getExchangesData(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/exchanges').pipe(
      tap(_ => console.log('fetched getExchangesData')),
      catchError(Errors.handleError('getExchangesData'))
    );
  }

  getDomainListData(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/targeting/domainList').pipe(
      tap(_ => console.log('fetched getDomainListData')),
      catchError(Errors.handleError('getDomainListData'))
    );
  }

  getSchedulingData(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/scheduling').pipe(
      tap(_ => console.log('fetched getSchedulingData')),
      catchError(Errors.handleError('getSchedulingData'))
    );
  }

  getCreativesData(campaignId: number) {
    return this.http.get(`${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/creatives').pipe(
      tap(_ => console.log('fetched getCreativesData')),
      catchError(Errors.handleError('getCreativesData'))
    );
  }

  bulkCampaigns(campaignsData) {
    return this.http.put(`${environment.apiUrl}` + this.campsUrl + '/bulk-edit', campaignsData, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateCampaign', campaignsData))
      );
  }

  getCampaignDomainsStats(campaignId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    return this.http.get(
    `${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/domains/stats'
        + '?filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched getCampaignDomainsStats')),
      catchError(Errors.handleError('getCampaignDomainsStats'))
    );
  }

  getCampaignAdsStats(campaignId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    return this.http.get(
      `${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/ads/stats'
      + '?filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched getCampaignAdsStats')),
      catchError(Errors.handleError('getCampaignAdsStats'))
    );
  }

  getCampaignConversionsStats(campaignId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    return this.http.get(
      `${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/conversions/stats'
      + '?filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched getCampaignAdsStats')),
      catchError(Errors.handleError('getCampaignAdsStats'))
    );
  }

  getCampaignExchangesStats(campaignId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    return this.http.get(
      `${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/exchanges/stats'
      + '?filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched getCampaignAdsStats')),
      catchError(Errors.handleError('getCampaignAdsStats'))
    );
  }

  getCampaignStats(campaignId: number, by = 'DAY', params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    return this.http.get(
      `${environment.apiUrl}` + this.campsUrl + '/' + campaignId + '/stats'
      + '?by=' + by + '&filter=' + params.filter + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched getCampaignStats')),
      catchError(Errors.handleError('getCampaignStats'))
    );
  }

  setInventoryUnitTargets(targets, campIds) {
    return this.http.put(`${environment.apiUrl}` + this.campsUrl + '/inventoryUnit/targets',
      {inventoryUnitTargets: targets, campaignIds: campIds}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('setInventoryUnitTargets', {inventoryUnitTargets: targets, campaignIds: campIds}))
      );
  }

  setLocationsSet(name: string, locations: Array<any>) {
    return this.http.post(`${environment.apiUrl}` + 'user/locations',
      {name: name, locations: locations}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('setInventoryUnitTargets', {name: name, locations: locations}))
      );
  }

  setContextualsSet(name: string, segments: Array<any>, type = 'contextual') {
    return this.http.post(`${environment.apiUrl}` + 'user/contextuals',
      {name: name, segments: segments, type: type}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('setInventoryUnitTargets', {name: name, segments: segments, type: type}))
      );
  }

  setAudienceSet(name, audienceSet) {
    return this.http.post(`${environment.apiUrl}` + 'campaigns/audiences/set/',
      {name: name, set: audienceSet}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('setAudienceSet', {name: name, set: audienceSet}))
      );
  }

  getLocationsSet() {
    return this.http.get(`${environment.apiUrl}` + 'user/locations')
      .pipe(
        catchError(Errors.handleError('getLocationsSet', {}))
      );
  }

  getContextualsSet() {
    return this.http.get(`${environment.apiUrl}` + 'user/contextuals')
      .pipe(
        catchError(Errors.handleError('getContextualsSet', {}))
      );
  }

  deleteLocationsSet(id: any) {
    return this.http.delete(`${environment.apiUrl}` + 'user/locations/' + id)
      .pipe(
        catchError(Errors.handleError('deleteLocationsSet', {}))
      );
  }

  deleteAudienceSet(id: any) {
    return this.http.delete(`${environment.apiUrl}` + 'campaigns/audiences/set/' + id)
      .pipe(
        catchError(Errors.handleError('deleteLocationsSet', {}))
      );
  }

  deleteContextualSet(id: any) {
    return this.http.delete(`${environment.apiUrl}` + 'user/contextuals/' + id)
      .pipe(
        catchError(Errors.handleError('deleteContextualsSet', {}))
      );
  }
}
