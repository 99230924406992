import {Component, OnInit, ViewChild} from '@angular/core';
import * as  moment from 'moment';
import {FormControl, NgModel} from '@angular/forms';
import {Observable} from 'rxjs';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {BrandService} from '../../_services/brand/brand.service';
import {BrandCategory} from '../../_models/responses';
import {City} from '../../_models/city';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {NewBrandModalComponent} from '../../modals/new-brand-modal/new-brand-modal.component';
import {NewDomainListModalComponent} from '../../modals/new-domain-list-modal/new-domain-list-modal.component';
import {AlertService} from '../../_services/alert.service';
import {AddDomainappCurrentDomainModalComponent} from '../../modals/add-domainapp-current-domain-modal/add-domainapp-current-domain-modal.component';
import {CsvExportParams} from 'ag-grid-community';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  @ViewChild(NewDomainListModalComponent, null) newInventoryModal: NewDomainListModalComponent;
  @ViewChild(AddDomainappCurrentDomainModalComponent, null) addDomainModal: AddDomainappCurrentDomainModalComponent;
  rowData: [];
  columnDefsInventory = [
    {headerName: 'Name', field: 'name', width: 400, rowGroup: true, hide: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1)
    },
    {headerName: 'Yesterday\'s Auctions', field: 'dailyImpressions', width: 150, sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (typeof valueA === 'undefined') {
          valueA = (isNaN(nodeA.allLeafChildren[0].data.parentDailyImpressions) || nodeA.allLeafChildren[0].data.parentDailyImpressions === '') ? 0 : parseFloat(nodeA.allLeafChildren[0].data.parentDailyImpressions);
        }
        if (typeof valueB === 'undefined') {
          valueB = (isNaN(nodeB.allLeafChildren[0].data.parentDailyImpressions) || nodeB.allLeafChildren[0].data.parentDailyImpressions === '') ? 0 : parseFloat(nodeB.allLeafChildren[0].data.parentDailyImpressions);
        }
        return (valueA - valueB);
      },
      cellRenderer: (params) => {
        if (typeof params.value !== 'undefined') {
          return (params.value === '' || (isNaN(params.value)) ? 0 : params.value);
        } else if (typeof params.node.allLeafChildren !== 'undefined') {
          return (isNaN(params.node.allLeafChildren[0].data.parentDailyImpressions) || params.node.allLeafChildren[0].data.parentDailyImpressions === '')
            ? 0
            : params.node.allLeafChildren[0].data.parentDailyImpressions;
        } else {
          return 0;
        }
      }
    },
    // {headerName: 'Observed CPM', field: 'observedCPM', width: 150,  aggFunc: this.myAvg, cellRenderer: this.medalCellRenderer },
    {headerName: 'Observed CPM', field: 'observedCPM', width: 150, sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (typeof valueA === 'undefined') {
          valueA = (isNaN(nodeA.allLeafChildren[0].data.parentObservedCPM) || nodeA.allLeafChildren[0].data.parentObservedCPM === '') ? 0 : parseFloat(nodeA.allLeafChildren[0].data.parentObservedCPM);
        }
        if (typeof valueB === 'undefined') {
          valueB = (isNaN(nodeB.allLeafChildren[0].data.parentObservedCPM) || nodeB.allLeafChildren[0].data.parentObservedCPM === '') ? 0 : parseFloat(nodeB.allLeafChildren[0].data.parentObservedCPM);
        }
        return (valueA - valueB);
      },
      cellRenderer: (params) => {
        if (typeof params.value !== 'undefined') {
          return (params.value === '' || (isNaN(params.value)) ? parseFloat('0').toFixed(2) : parseFloat(params.value).toFixed(2));
        } else if (typeof params.node.allLeafChildren !== 'undefined') {
          return (isNaN(params.node.allLeafChildren[0].data.parentObservedCPM) || params.node.allLeafChildren[0].data.parentObservedCPM === '')
            ? parseFloat('0').toFixed(2)
            : parseFloat(params.node.allLeafChildren[0].data.parentObservedCPM).toFixed(2);
        } else {
          return parseFloat('0').toFixed(2);
        }
      }
    },
  ];
  public rowDataInventory: any
  title = 'inventory';
  exchanges = [];
  initDone = false;

  public selectedOptions = [];
  public categories: Observable<BrandCategory[]>;
  public allCategories: BrandCategory[];
  public categoriesGroup: any;
  public createOrEdit = 'Create';
  isRowSelectable;
  disabled = true;

  public allowedSizes = ['1x1', '3x3', '3x300', '6x6', '6x7', '6x8', '12x12', '120x20', '120x90', '120x600', '160x600', '168x28', '180x150', '200x200', '216x36', '220x90', '234x60', '240x400', '250x250', '250x360', '300x50', '300x60', '300x100', '300x170', '300x250', '300x340', '300x600', '300x1050', '320x50', '320x100', '320x180', '320x200', '320x240', '320x250', '320x320', '320x480', '333x333', '336x280', '386x300', '400x300', '468x60', '480x75', '480x320', '480x360', '480x480', '480x800', '600x400', '600x600', '631x385', '640x300', '640x480', '640x640', '687x228', '700x450', '720x300', '720x480', '728x90', '750x200', '768x768', '768x1024', '800x1100', '900x550', '960x30', '960x470', '970x66', '970x90', '970x250', '970x550', '980x120', '980x150', '990x200', '1024x768', '1030x590', '1200x627', '1200x1200', '1272x328', '1280x720', '1600x500', '1800x1000', '1920x480', '1920x1080', '1920x1200', '1920x1280'];

  public selectedOptions2 = [];
  public regions: Observable<City[]>;
  public allCountries: City[];
  public countriesGroup: any;
  public createOrEdit2 = 'Create';
  ngLoading = false;
  defaultColDef;
  autoGroupColumnDef;
  rowSelection;
  public modules: Module[] = AllModules;
  groupSelectedRows = [];

  selectedOption = null;
  selectedInvoiceType = 'app';
  selectedOption2 = [];
  selectedOption3 = [];
  selectedOption4 = [];
  categoriesChanged = false;
  regionsChanged = false;

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  inventoryGrid: any;
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  mySum(values) {
    let result = 0; let cont = 0;
    values.forEach( function(value) {
      if (typeof value === 'number' && cont > 0) {
        result += value;
      }
      cont++;
    });
    return result;
  }

  myAvg(values) {
    return typeof values[0] !== 'undefined' ? values[0] : 0;
  }

  medalCellRenderer(params) {
    return params.value !== '' ? '$' + params.value : '';
  }

  constructor(private invetoryService: InventoryService, private brandService: BrandService, private alertService: AlertService, private gridRender: GridRenderService, private breadcrumbsService: BreadcrumbsService) {
    this.isRowSelectable = function(node) {
      return node.group;
    };
    this.alwaysShowCalendars = true;
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true
    };
    this.autoGroupColumnDef = {
      headerName: 'Inventory Unit',
      field: 'exchangeName',
      minWidth: 400,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
      sortable: true,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1)
    };
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'Inventory', url: '/nventory', params: []},
    ];
    this.getInventory();
    this.getExchanges();
    this.getBrandCategories();
    this.getCities();
    this.initDone = true;
  }

  refreshGrid(event: any) {

  }

  addDomainListModal() {
    if (this.groupSelectedRows.length <= 2000) {
      this.addDomainModal.addDom = this.groupSelectedRows.length;
      $('.add-domain-list-modal').modal('show');
      $('#search_name').val('');
      this.addDomainModal.getDomainList();
    } else {
      this.alertService.alert('error', 'Max inventory size is 2000 units.');
    }
  }

  showNewDomainListModal() {
    if (this.groupSelectedRows.length <= 2000) {
      this.newInventoryModal.disabledFile = true;
      let str = '';
      let c = 0;
      for (let aux of this.groupSelectedRows) {
        c++;
        if (aux.indexOf('|') !== -1) {
          str += aux.split('|')[1].trim();
        } else {
          str += aux;
        }
        if (c < this.groupSelectedRows.length) {
          str += '\n';
        }
      }
      this.newInventoryModal.formControls.domains.setValue(str);
      this.newInventoryModal.domappCount = 2000 - this.groupSelectedRows.length;
      $('.new-domain-list-modal').modal('show');
    } else {
      this.alertService.alert('error', 'Max inventory size is 2000 units.');
    }
  }

  getInventory() {
    this.ngLoading = true;
    /*format*/
    console.log(this.selectedOption);
    /*device*/
    console.log(this.selectedOption2);
    /*Exchange*/
    console.log(this.selectedOption3);
    /*Ad Sizes*/
    console.log(this.selectedOption4);
    /*Categories*/
    console.log(this.selectedOptions);
    /*Countries/Cities*/
    console.log(this.selectedOptions2);

    let geo = [];
    if (this.selectedOptions2.length > 0) {
      for (let aux of this.selectedOptions2) {
          if (typeof aux.regionCode !== 'undefined') {
            geo.push(aux.countryCode + '-' + aux.regionCode);
          } else {
            geo.push(aux.countryCode);
          }
      }
    }

    const params = {
      filter: (typeof $('#domain_search_val').val() !== 'undefined') ? $('#domain_search_val').val().toString().trim() : '',
      inventoryType: ((this.selectedInvoiceType !== null) ? this.selectedInvoiceType : ''),
      mediaType: ((this.selectedOption !== null) ? this.selectedOption : ''),
      deviceTypes: ((typeof this.selectedOption2 !== 'string') ? this.selectedOption2.join(',') : ''),
      exchangeIds: ((typeof this.selectedOption3 !== 'string') ? this.selectedOption3.join(',') : ''),
      categories: '',
      geoCodes: geo.join(','),
      adSize: ((typeof this.selectedOption4 !== 'string') ? this.selectedOption4.join(',') : ''),
    };
    this.invetoryService.getInventory(params).subscribe(
      (response) => {
            console.log(response);
            this.rowDataInventory =  Observable.create((obs) => {
              obs.next(response.response.inventory_units);
              obs.complete();
            });
        this.ngLoading = false;
        });
  }

  groupRowSelected(event: any) {
    this.groupSelectedRows = event.api.getSelectedNodes().map((value, index, array) => value.key);
    console.log(this.groupSelectedRows);
    if(this.groupSelectedRows.length > 0)
      this.disabled = false;
    else
      this.disabled = true;
  }

  getExchanges() {
    this.invetoryService.getExchanges().subscribe(
      (response) => {
        console.log('exchanges:', response);
        this.exchanges = response.response.exchanges;
      });
  }

  // getCategories() {
  //   this.invetoryService.getCategories().subscribe(
  //     (response) => {
  //       console.log('categories:', response);
  //       this.categories = response.response.categories;
  //     });
  // }

  clearFilters() {
    this.selectedOption = null;
    this.selectedOption2 = [];
    this.selectedOptions2 = [];
    this.selectedOptions = [];
    this.selectedOption4 = [];
    this.selectedOption3 = [];
    $('#domain_search_val').val('');
  }

  getCities(params = {filter: ''}) {
    this.invetoryService.getCities(params).subscribe(
      (response) => {
        console.log(response);
        this.allCountries = response.response.cities;
        const filteredCountries = this.allCountries.filter((item, index, array) => {
          if (typeof item.parentId !== 'undefined') {
            const indexParent = array.findIndex(elem => elem.entityId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.countriesGroup = (text: string, children: any[]) => {
          const group = this.allCountries.find(elem => elem.entityId === children[0].parentId);
          return group;
        }
        this.regions = Observable.create((obs) => {
          obs.next(filteredCountries);
          obs.complete();
        });
      });
  }

  public getBrandCategories() {
    this.brandService.getBrandCategories().subscribe(
      (response) => {
        console.log(response);
        this.allCategories = response.response.brandCategories;
        const filteredCategories = this.allCategories.filter((item, index, array) => {
          if (typeof item.parentId) {
            const indexParent = array.findIndex(elem => elem.attributeId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.categoriesGroup = (text: string, children: any[]) => {
          const group = this.allCategories.find(elem => elem.attributeId === children[0].parentId);
          return group;
        }
        this.categories = Observable.create((obs) => {
          obs.next(filteredCategories);
          obs.complete();
        });
      },
      (error) => { console.log(error); }
    )
  }

  public compareCategories = (item, selected) => {
    if (selected.attributeId && item.attributeId) {
      return item.attributeId === selected.attributeId;
    }
    return false;
  };

  public compareCountries = (item, selected) => {
    if (selected.entityId && item.entityId) {
      return item.entityId === selected.entityId;
    }
    return false;
  };

  inventoryGridReady(event: any) {
    this.inventoryGrid = event.api;
  }

  exportInventories() {
    this.inventoryGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  rowOpened(event: any) {
    console.log('rowOpened: ', event);
    if (event.node.allChildrenCount === 1) {
      this.ngLoading = true;
      let geo = [];
      if (this.selectedOptions2.length > 0) {
        for (let aux of this.selectedOptions2) {
          if (typeof aux.regionCode !== 'undefined') {
            geo.push(aux.countryCode + '-' + aux.regionCode);
          } else {
            geo.push(aux.countryCode);
          }
        }
      }

      const params = {
        filter: (typeof $('#domain_search_val').val() !== 'undefined') ? $('#domain_search_val').val().toString().trim() : '',
        inventoryType: '',
        mediaType: ((this.selectedOption !== null) ? this.selectedOption : ''),
        deviceTypes: ((typeof this.selectedOption2 !== 'string') ? this.selectedOption2.join(',') : ''),
        exchangeIds: ((typeof this.selectedOption3 !== 'string') ? this.selectedOption3.join(',') : ''),
        categories: '',
        geoCodes: geo.join(','),
        adSize: ((typeof this.selectedOption4 !== 'string') ? this.selectedOption4.join(',') : ''),
      };
      this.invetoryService.getInventoryExanges(event.node.allLeafChildren[0].data.identifier, params).subscribe(
        response => {
          response.response.inventory_units = response.response.inventory_units.map(elem => {
            elem['parentObservedCPM'] = event.node.allLeafChildren[0].data.parentObservedCPM;
            elem['parentDailyImpressions'] = event.node.allLeafChildren[0].data.parentDailyImpressions;
            return elem;
          })
          this.inventoryGrid.updateRowData({add: response.response.inventory_units});
          this.inventoryGrid.updateRowData({ remove: [event.node.allLeafChildren[0].data] });
          // this.rowDataInventory =  Observable.create((obs) => {
          //   obs.next(response.response.inventory_units);
          //   obs.complete();
          // });
          this.ngLoading = false;
        }
      )
    }
  }

  sortChanged(event: any) {
    // this.getInventory();
  }
}
