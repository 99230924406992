import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-audience-target-sub-rule',
  templateUrl: './audience-target-sub-rule.component.html',
  styleUrls: ['./audience-target-sub-rule.component.scss']
})
export class AudienceTargetSubRuleComponent implements OnInit {

  @Input() rule: FormGroup;
  @Input() position: number;
  @Input() extraData: any;
  @Output() removeRule = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
  }

  removeTheRule() {
    this.removeRule.emit(this.position);
  }
}
