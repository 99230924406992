import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
// import {CampaignsComponent} from '../../../pages/campaigns/campaigns.component';

@Component({
  selector: 'app-budget-cell-renderer',
  templateUrl: './budget-cell-renderer.component.html',
  styleUrls: ['./budget-cell-renderer.component.scss']
})
export class BudgetCellRendererComponent {

  // @ts-ignore
  @ViewChild('i') customHtml;
  params;
  text = '';

  constructor() { }

  agInit(params: any): void {
    if (typeof params.value !== 'undefined' && params.value !== null) {
      this.params = params;
      const column = this.params.column.colId;
      /*const margin = (typeof params.data.extraData !== 'undefined' && params.data.extraData.margin > 1
        && ((column.indexOf('Budget') === -1 && column.indexOf('budget') === -1) || (typeof params.context !== 'undefined' && typeof params.context.componentParent !== 'undefined' && params.context.componentParent instanceof CampaignsComponent))) ?
        ((100 - params.data.extraData.margin) / 100) : 1;*/
      if (this.params.type === 'totalBudget') {
        if (this.params.value.type === 'all_time') {
          this.text = '$' + (this.params.value.amount / 1).toFixed(2);
        } else {
          this.text = '-';
        }
      } else if (this.params.type === 'startDate') {
        if (typeof this.params.value.schedule !== 'undefined') {
          this.text = moment(this.params.value.schedule.flightDates.from).format('MM/DD/YYYY');
        }
      } else if (this.params.type === 'endDate') {
        if (typeof this.params.value.schedule !== 'undefined') {
          this.text = moment(this.params.value.schedule.flightDates.to).format('MM/DD/YYYY');
        }
      } else if (this.params.type === 'dailyBudget') {
        if (this.params.value.type === 'daily') {
          this.text = '$' + (this.params.value.amount / 1).toFixed(2);
        } else {
          this.text = '-';
        }
      }
    }
  }

  getValue() {
    return this.customHtml.nativeElement.value;
  }

  refresh(): boolean {
    return false;
  }

  statusChanged(event) {
    this.text = (this.text === 'On') ? 'Archived' : 'On';
  }

}
