import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserIndexComponent } from './user-index/user-index.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserRestService } from './user-rest.service';
import {AgGridModule} from 'ag-grid-angular';
import {NewUserComponent} from '../../modals/new-user/new-user.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxLoadingModule} from 'ngx-loading';
// import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatFormFieldModule, MatOptionModule, MatSelectModule} from '@angular/material';
import {MatInputModule} from '@angular/material/input';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';


@NgModule({
  declarations: [UserIndexComponent, UserCreateComponent, NewUserComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    AgGridModule,
    ReactiveFormsModule,
    FormsModule,
    NgxLoadingModule,
    // NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    SweetAlert2Module
  ],
  exports: [
    NewUserComponent
  ],
  providers: [
    UserRestService
  ]
})
export class UserModule { }
