import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {MatCheckboxChange} from '@angular/material';

@Component({
  selector: 'app-audience-get-tags',
  templateUrl: './audience-get-tags.component.html',
  styleUrls: ['./audience-get-tags.component.scss']
})
export class AudienceGetTagsComponent implements OnInit {
  loading = false;
  tagType = 'script';
  mapUrlChecked = false;
  mapUrlRedirectUrl = '';
  linkUrl = '';
  linkText = '';
  audienceId: number;
  textCode: string;
  brandName = '';
  constructor(private audService: AudiencesService, private fb: FormBuilder) { }

  ngOnInit() {
    this.tagType = 'script';
  }

  changeTagType(event) {
    this.tagType = event;
    this.getTagCode();
  }

  getTagCode() {
    this.loading = true;
    this.audService.getTagCode(this.tagType, this.audienceId).subscribe(
      (response) => {
        this.loading = false;
        switch (this.tagType) {
          case 'mapUrl':
            this.textCode = response.response + '?dpid=[INSERT_DEVICE_ID_HERE]' + ((this.mapUrlChecked) ? '&r=' + this.mapUrlRedirectUrl : '');
            break;
          case 'link':
            this.textCode = response.response.replace('My Link', this.linkText).replace('?r=', '?r=' + this.linkUrl);
            break;
          default:
            this.textCode = response.response
            break;
        }
        },
      (error) => { console.log(error, 'getTagCode'); this.loading = false; }
    )
  }

  addRedirect(event: any) {
    if (typeof event.checked !== 'undefined') {
      this.textCode = (event.checked) ?
        this.textCode + '&r=' + this.mapUrlRedirectUrl :
        this.textCode.split('&r=')[0];
    } else if (typeof event.key !== 'undefined' && event.key !== null) {
      this.textCode = this.textCode + event.key;
    }
  }

  addRedirectLink(event: any) {
    this.textCode = this.textCode.split('?r=')[0] + '?r=' + this.linkUrl + '">' + this.linkText + '</a>';
  }
}
