import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {Conversion} from '../../_models/conversions';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {Audience} from '../../_models/audience';
import {Observable, Observer} from 'rxjs';

@Component({
  selector: 'app-converion-audience-capture',
  templateUrl: './converion-audience-capture.component.html',
  styleUrls: ['./converion-audience-capture.component.scss']
})
export class ConverionAudienceCaptureComponent implements OnInit {
  brandId: number;
  ngLoading = false;
  checkedAud = 'false';
  public offerId: number;
  selectedAud: bigint;
  audiences: Observable<Array<Audience>>;
  newAudience = false;
  audName: string;

  constructor(
    private convService: ConversionService,
    private alertService: AlertService,
    private audService: AudiencesService
  ) { }

  ngOnInit() {
    this.audiences = new Observable((observer: Observer<Array<Audience>>) => {
      observer.next([]);
      observer.complete();
    });
  }

  loadConversionAudience(convId: number, brandID: number) {
    this.ngLoading = true;
    this.brandId = brandID;
    this.offerId = convId;
    // @ts-ignore
    this.selectedAud = 0;
    this.getAllAudiences(brandID);
    this.getConversionAud(convId, brandID);
  }

  onSubmit() {
    this.ngLoading = true;
    this.convService.setConversionAudience(this.brandId, this.offerId, this.selectedAud).subscribe(
      (response) => {
        this.audName = '';
        this.newAudience = false;
        this.selectedAud = null;
        $('.conversion-audience-capture-modal').modal('hide');
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'getConversionAudience'); this.checkedAud = 'false'; this.ngLoading = false; }
    )
  }

  private getAllAudiences(brandID: number) {
    this.audService.getAudiencesStats(brandID, {filter: '', dateTo: '', dateFrom: '', visible: '', userIdTypes: ''}, 1000).subscribe(
      (response) => {
        this.audiences = new Observable((obs: Observer<Array<Audience>>) => {
          obs.next(response.response.audiences);
          obs.complete();
        })
      },
      (error) => { console.log(error, 'getAudiencesStats'); }
    );
  }

  private getConversionAud(convId: number, brandID: number) {
    this.convService.getConversionAudience(brandID, convId).subscribe(
      (response) => {
        this.selectedAud = response.audienceId;
        this.checkedAud = 'true';
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'getConversionAudience'); this.checkedAud = 'false'; this.ngLoading = false; }
    )
  }

  createAudience() {
    this.ngLoading = true;
    this.audService.createAudience({brandId: this.brandId, name: this.audName, visible: true, notes: ''} as unknown as Audience).subscribe(
      (response) => {
        this.getAllAudiences(this.brandId);
        this.audName = '';
        this.newAudience = false;
        this.selectedAud = response.audience.audienceId;
        this.ngLoading = false;
        this.alertService.alert('success', 'Audience created successfully.');
      },
      (error) => { console.log(error, 'createAudience'); this.ngLoading = false; }
    )
  }
}
