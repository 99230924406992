import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonAuthRoutingModule } from './common-auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CommonAuthService } from './common-auth.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor.interceptor';
import {NgxLoadingModule} from 'ngx-loading';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {MatInputModule, MatTooltipModule} from '@angular/material';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';

@NgModule({
  declarations: [LoginComponent, RegisterComponent, ForgotPasswordComponent, ChangePasswordComponent],
  imports: [
    CommonModule,
    CommonAuthRoutingModule,
    NgxLoadingModule.forRoot({}),
    MatInputModule,
    SweetAlert2Module,
    MatTooltipModule
  ],
  exports: [
    ChangePasswordComponent
  ],
  providers: [
    CommonAuthService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
  ]
})
export class CommonAuthModule { }
