import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdService} from '../../_services/ad/ad.service';
import {AlertService} from '../../_services/alert.service';
import {Observable} from 'rxjs';
import {Campaign} from '../../_models/campaign';
import {CampaignService} from '../../_services/campaign/campaign.service';

@Component({
  selector: 'app-campaign-name-bulk',
  templateUrl: './campaign-name-bulk.component.html',
  styleUrls: ['./campaign-name-bulk.component.scss']
})
export class CampaignNameBulkComponent implements OnInit {
  columnDefsNames = [
    {headerName: 'ID', field: 'campaignId', width: 100, editable: false },
    {headerName: 'Name', field: 'name', width: 400, editable: true },
  ];
  rowDataNames: any;
  selectedAds = [];
  defaultColDef = {
    editable: true,
    resizable: true
  };
  preffix = '';
  suffix = '';
  find = '';
  replace = '';
  gridApi;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(private campService: CampaignService, private alert: AlertService) { }

  ngOnInit() {
    this.rowDataNames = Observable.create((obs) => {
      obs.next(this.selectedAds);
      obs.complete();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  refreshGridData() {
    this.rowDataNames = Observable.create((obs) => {
      obs.next(this.selectedAds);
      obs.complete();
    });
  }

  addPreffix() {
    const toUpdate = [];
    const preffix = this.preffix;
    this.gridApi.forEachNode(function(node) {
      node.data.name = preffix + node.data.name;
      console.log(node.data.name);
      toUpdate.push(node.data);
    });
    console.log(toUpdate);
    this.gridApi.updateRowData({ update: toUpdate });
  }

  addSuffix() {
    const toUpdate = [];
    const suffix = this.suffix;
    this.gridApi.forEachNode(function(node) {
      node.data.name = node.data.name + suffix;
      toUpdate.push(node.data);
    });
    console.log(toUpdate)
    this.gridApi.updateRowData({ update: toUpdate });
  }

  finReplace() {
    const toUpdate = [];
    const find = this.find;
    const replace = this.replace;
    const reg = new RegExp(find, 'g');
    this.gridApi.forEachNode(function(node) {
      node.data.name = node.data.name.replace(reg, replace);
      toUpdate.push(node.data);
    });
    console.log(toUpdate)
    this.gridApi.updateRowData({ update: toUpdate });
  }

  saveChanges() {
    const params = [];
    this.gridApi.forEachNode(function(node) {
      params.push({id: node.data.campaignId, field: 'name', newValue: node.data.name});
    });
    this.campService.bulkCampaigns({campaignNames: params}).subscribe(
      (response) => {
        this.ngOnInit();
        this.refreshGrid.emit('campaign');
        this.alert.alert('success', 'Changes saved successfully.')
        $('.campaigns-name-bulk-modal').modal('hide');
      },
      (error) => {console.log(error, 'saveChanges')}
    )
  }
}
