import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserResponse, UsersResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {User} from '../../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usersUrl = 'users';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getUsers(max: number = 20, offset: number = 0, searchVal = ''): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(
      `${environment.apiUrl}` + this.usersUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
    ).pipe(
      tap(_ => console.log('fetched users')),
      catchError(Errors.handleError<UsersResponse>('getUsers'))
    );
  }

  getUsersByClient(clientId: number, max: number = 20, offset: number = 0, searchVal = ''): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(
      `${environment.apiUrl}` + this.usersUrl + '?clientId=' + clientId + '&max=' + max + '&offset=' + offset + '&query=' + searchVal
    ).pipe(
      tap(_ => console.log('fetched users')),
      catchError(Errors.handleError<UsersResponse>('getUsers'))
    );
  }

  getAgencyUsers(max: number = 20, offset: number = 0, searchVal = ''): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(
      `${environment.apiUrl}` + this.usersUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
    ).pipe(
      tap(_ => console.log('fetched users')),
      catchError(Errors.handleError<UsersResponse>('getUsers'))
    );
  }

  getUserById(id: any) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getUserById'))
    );
  }

  getUserWithTokenById(id: any) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id + '/with-token').pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getUserWithTokenById'))
    );
  }

  unGhostUser(id: any) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id + '/unghost').pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getUserWithTokenById'))
    );
  }

  createUser(user: User) {
    return this.http.post<User>(`${environment.apiUrl}` + this.usersUrl, user, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addUser', user))
      );
  }

  updateUser(user: User, id: bigint) {
    return this.http.put<User>(`${environment.apiUrl}` + this.usersUrl + '/' + id, user, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateUser', user))
      );
  }

  deleteUser(id: string) {
    return this.http.delete<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
      tap(_ => console.log('deleted business')),
      catchError(Errors.handleError<UserResponse>('deleteUser'))
    );
  }
}
