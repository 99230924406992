import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormBuilder, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';

@Component({
  selector: 'app-new-expandable-tag-modal',
  templateUrl: './new-expandable-tag-modal.component.html',
  styleUrls: ['./new-expandable-tag-modal.component.scss']
})
export class NewExpandableTagModalComponent extends NewAdComponent implements OnInit {

  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }
  opened = false;
  expandDirections = [
    {id: 'up', name: 'Up'},
    {id: 'down', name: 'Down'},
    {id: 'left', name: 'Left'},
    {id: 'right', name: 'Right'},
    {id: 'up_left', name: 'Up-left'},
    {id: 'up_right', name: 'Up-right'},
    {id: 'down_left', name: 'Down-left'},
    {id: 'down_right', name: 'Down-right'},
    {id: 'up_or_down', name: 'Up or Down'},
    {id: 'left_or_right', name: 'Left or Right'},
    {id: 'any_diagonal', name: 'Any Diagonal'}
  ];
  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getVendors();
    this.newForm = this.fb.group({
      adType: ['expandable'],
      label: ['', Validators.required],
      status: ['online'],
      width: ['', Validators.required],
      height: ['', Validators.required],
      type: ['expandable'],
      sslEnabled: [true],
      // tslint:disable-next-line:max-line-length
      landingPageDomain: ['', [Validators.pattern(this.domainRegex), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      vendors: [''],
      code: ['', Validators.required],
      expandingDirection: ['up', Validators.required]
    });
    // this._parent.newForm = this.newForm;
  }

  vendorsGridInit(event: any) {
    this.vendorsApiGrid = event.api;
  }

  changedDimension(event: any) {
    if (typeof event !== 'undefined') {
      const dim = event.split('x');
      this.newForm.controls.width.setValue(dim[0]);
      this.newForm.controls.height.setValue(dim[1]);
    } else {
      this.newForm.controls.width.setValue('');
      this.newForm.controls.height.setValue('');
    }
  }

}
