import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-edit-cell',
  templateUrl: './text-edit-cell.component.html',
  styleUrls: ['./text-edit-cell.component.scss']
})
export class TextEditCellComponent {
  params;
  value: any;
  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.value = (typeof params.value !== 'undefined' && params.value !== null && params.value !== '')
      ? params.value : 'Enter a tracking URL';
  }

  getValue() {
    return this.value;
  }

}
