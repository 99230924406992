import { Component, OnInit } from '@angular/core';
import {GroupService} from '../../_services/group/group.service';
import {Observable} from 'rxjs';
import {LinkAdCampaignComponent} from '../../shared/cell-renderers/link-ad-campaign/link-ad-campaign.component';
import {AdService} from '../../_services/ad/ad.service';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-link-ads-modal',
  templateUrl: './link-ads-modal.component.html',
  styleUrls: ['./link-ads-modal.component.scss']
})
export class LinkAdsModalComponent implements OnInit {
  gridApiCamps;
  gridColumnApiCamps;
  gridApiLinked;
  gridColumnApiLinked;
  brandId: number;
  selectedAds = [];
  columnDefsLinkedCampaigns = [
    {headerName: 'Campaign Name', field: 'campaignName', width: 280 },
    {headerName: 'ID', field: 'campaignId', width: 120 },
    {headerName: '', field: 'action', width: 80, cellRenderer: 'linkAdCampaignComponent', cellRendererParams: { linked: true }, filter: false }
  ];
  rowDataLinkedCampaigns: Observable<Array<{}>>;
  columnDefsCampaigns = [
    {field: 'campaignGroupId', rowGroup: true, hide: true},
    // {headerName: 'Campaign Name', field: 'campaignName', width: 200 },
    {headerName: 'ID', field: 'campaignId', width: 120 },
    {headerName: '', field: 'action', width: 80, cellRenderer: 'linkAdCampaignComponent', cellRendererParams: { linked: false }, filter: false }
  ];
  rowDataCampaigns: Observable<Array<{}>>;
  autoGroupColumnDef = {headerName: 'Name', field: 'campaignName', width: 280};
  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
  };
  frameworks = {
    linkAdCampaignComponent: LinkAdCampaignComponent
  };
  ngLoading = false;

  constructor(private groupService: GroupService, private adService: AdService, private alertService: AlertService) { }

  ngOnInit() {
    this.getCampaigns();
  }

  getCampaigns() {
    if (typeof this.brandId !== 'undefined' && this.brandId !== null) {
      this.groupService.getCampaignsByGroup(this.brandId).subscribe(
        (response) => {
          this.rowDataCampaigns = Observable.create((obs) => {
            obs.next(response);
            obs.complete();
          });
          this.rowDataLinkedCampaigns = Observable.create((obs) => {
            obs.next([]);
            obs.complete();
          });
        },
        (error) => {console.log(error, 'getCampaignsByGroup')}
      );
    }
  }

  cellClicked(node: any) {
    if (node.column.colId === 'action' && node.event.target.tagName === 'BUTTON' && node.event.target.innerText === 'Link') {
      this.gridApiLinked.updateRowData({ add: [node.data] });
      this.gridApiCamps.updateRowData({ remove: [node.data] });
    } else if (node.column.colId === 'action' && node.event.target.tagName === 'BUTTON' && node.event.target.innerText === 'Unlink') {
      this.gridApiLinked.updateRowData({ remove: [node.data] });
      this.gridApiCamps.updateRowData({ add: [node.data] });
    }
  }

  onGridReadyCamps(params) {
    this.gridApiCamps = params.api;
    this.gridColumnApiCamps = params.columnApi;
  }

  onGridReadyLinked(params) {
    this.gridApiLinked = params.api;
    this.gridColumnApiLinked = params.columnApi;
  }

  submitLinked() {
    this.ngLoading = true;
    const linkedIds: number[] = [];
    this.gridApiLinked.forEachNode(function(node) {
      linkedIds.push(node.data.campaignId);
    });
    this.adService.linkCampaignsToAds(linkedIds, this.selectedAds).subscribe(
      (response) => {
        this.alertService.alert('success', 'Campaigns Linked successfully.');
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'linkCampaignsToAds'); this.ngLoading = false; }
    )
  }

  resetGrids() {
    this.rowDataLinkedCampaigns = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
  }

  getAdCampaigns() {
    if (this.selectedAds.length === 1) {
      this.adService.getAdCampaigns(this.selectedAds[0]).subscribe(
        (response) => {
          this.rowDataLinkedCampaigns = Observable.create((obs) => {
            obs.next(response.adCampaigns);
            obs.complete();
          });
          this.rowDataCampaigns = Observable.create((obs) => {
            obs.next(response.campaigns);
            obs.complete();
          });
        },
        (error) => {console.log(error, 'getCampaignsByGroup')}
      );
    }
  }

  unlinkAll() {
    const toUnlink = [];
    this.gridApiLinked.forEachNode(function(node) {
      toUnlink.push(node.data);
    });
    this.gridApiCamps.updateRowData({ add: toUnlink });
    this.gridApiLinked.updateRowData({ remove: toUnlink });
  }
}
