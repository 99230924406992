import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, publishReplay, refCount, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {AdResponse, AdsResponse, BrandCategoriesResponse, CampaignsResponse} from '../../_models/responses';
import {Ad} from '../../_models/ad';
import {CommonAuthService} from '../../auth/common-auth.service';
import {Campaign} from '../../_models/campaign';

@Injectable({
  providedIn: 'root'
})
export class AdService {

  vendors: Observable<{response: Array<{vendorId: number, name: string}>}>;
  private adsUrl = 'ads';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient, private auth: CommonAuthService) { }

  getAds(brandId: number, params = {filter: '', status: ''}, max: number = 1000, offset: number = 0): Observable<AdsResponse> {
    return this.http.get<AdsResponse>(
      `${environment.apiUrl}` + this.adsUrl
      + '?brandId=' + brandId + '&max=' + max + '&pageSize=' + max + '&offset='
      + offset + '&filter=' + params.filter
      + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched ads')),
      catchError(Errors.handleError<AdsResponse>('getAds'))
    );
  }

  getAdById(id: any) {
    return this.http.get<AdResponse>(`${environment.apiUrl}` + this.adsUrl + '/' + id).pipe(
      tap(_ => console.log('fetched ad')),
      catchError(Errors.handleError<AdResponse>('getAdById'))
    );
  }

  createAd(ad: Ad): Observable<AdResponse> {
    return this.http.post<AdResponse>(`${environment.apiUrl}` + this.adsUrl, ad, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createGroup', ad))
      ) as Observable<AdResponse>;
  }

  updateAd(ad: Ad, id: number): Observable<AdResponse>  {
    return this.http.put<AdResponse>(`${environment.apiUrl}` + this.adsUrl + '/' + id, ad, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateGroup', ad))
      ) as Observable<AdResponse>;
  }

  updateAdPartially(data: any, id: number): Observable<AdResponse>  {
    return this.http.put<AdResponse>(`${environment.apiUrl}` + this.adsUrl + '/update/partially/' + id, data, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateAdPartially', data))
      ) as Observable<AdResponse>;
  }

  updateMultipleAds(ads: Array<number>, fields: Array<{}>): Observable<{}>  {
    return this.http.put(`${environment.apiUrl}` + 'multiple/' + this.adsUrl, {ads : ads, fields: fields}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateGroup', ads))
      ) as Observable<{}>;
  }

  severalAdsEdit(ads: Array<{id: number, field: string, newValue: any}>): Observable<{}>  {
    return this.http.put(`${environment.apiUrl}` + 'several/' + this.adsUrl + '/edit', {ads : ads}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateGroup', ads))
      ) as Observable<{}>;
  }

  deleteAd(id: string) {
    return this.http.delete<AdResponse>(`${environment.apiUrl}` + this.adsUrl + '/' + id).pipe(
      tap(_ => console.log('deleted ad')),
      catchError(Errors.handleError<AdResponse>('deleteAd'))
    );
  }

  uploadImage(fieldName, file, metadata, load, error, progress, abort) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}` + 'upload/image', data, this.httpOptions)
      .pipe(
        tap(_ => console.log('fetched Ad Image')),
        catchError(Errors.handleError('uploadImage', file))
      );
  }

  uploadZipFile(brandId, fieldName, file, metadata, load, error, progress, abort) {
    const data = new FormData();
    data.append('file', file);
    data.append('brandId', brandId);
    return this.http.post(`${environment.apiUrl}` + 'upload/zip', data, this.httpOptions)
      .pipe(
        tap(_ => console.log('fetched Zip File')),
        catchError(Errors.handleError('uploadZipFile', file))
      );
  }

  getImage(source) {
    const httpOptionsCustom = {
      responseType: 'blob'
    };
    const data = new FormData();
    data.append('url', source);
    // @ts-ignore
    return this.http.post(`${environment.apiUrl}` + 'get/image', data, httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched LocationsExport')),
        catchError(Errors.handleError('addLocation', location))
      );
  }

  deleteFile(file) {
    const data = new FormData();
    data.append('path',  '/ads/' + this.auth.currentUserValue.id + '/' + file.filename);
    return this.http.post(`${environment.apiUrl}` + 'delete/file', data)
      .pipe(
        tap(_ => console.log('fetched deleteAdFile')),
        catchError(Errors.handleError('deleteAdFile', file))
      );
  }

  linkCampaignsToAds(campaignIds: number[], adIds: number[]) {
    return this.http.post<{}>(`${environment.apiUrl}` + 'link-campaigns-ads', {campaignIds: campaignIds, adIds: adIds}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('linkCampaignsToAds', {campaignIds: campaignIds, adIds: adIds}))
      ) as Observable<{}>;
  }

  getAdCampaigns(adId: number) {
    return this.http.get<{adCampaigns: [], campaigns: []}>(`${environment.apiUrl}` + this.adsUrl + '/' + adId + '/campaigns/' ).pipe(
      tap(_ => console.log('fetched ad')),
      catchError(Errors.handleError<{adCampaigns: [], campaigns: []}>('getAdById'))
    );
  }

  uploadAudio(fieldName, file, metadata, load, error, progress, abort) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}` + 'upload/file', data, this.httpOptions)
      .pipe(
        tap(_ => console.log('fetched Ad Audio')),
        catchError(Errors.handleError('uploadAudio', file))
      );
  }

  getFile(source) {
    const httpOptionsCustom = {
      responseType: 'blob'
    };
    const data = new FormData();
    data.append('url', source);
    // @ts-ignore
    return this.http.post(`${environment.apiUrl}` + 'get/file', data, httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched getFile')),
        catchError(Errors.handleError('getFile', source))
      );
  }

  getVendors() {
    if (!this.vendors) {
      this.vendors = this.http.get<{response: Array<{vendorId: number, name: string}>}>(`${environment.apiUrl}` + 'vendors' ).pipe(
        tap(_ => console.log('fetched vendors')),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        catchError(Errors.handleError<{response: Array<{vendorId: number, name: string}>}>('getVendors'))
      );
    }
    return this.vendors;
  }

  getCompanionCreative(adID) {
    return this.http.get<{response: any}>(`${environment.apiUrl}` + 'ads/' + adID + '/companion-creative' ).pipe(
      tap(_ => console.log('fetched companion-creative')),
      catchError(Errors.handleError<{response: any}>('getCompanionCreative'))
    );
  }

  getCustomerVastEvents(adID) {
    return this.http.get<{response: any}>(`${environment.apiUrl}` + 'ads/' + adID + '/customer-vast-events' ).pipe(
      tap(_ => console.log('fetched getCustomerVastEvents')),
      catchError(Errors.handleError<{response: any}>('getCustomerVastEvents'))
    );
  }

  getBrandCategories(brandId, adID) {
    return this.http.get<{response: any}>(`${environment.apiUrl}` + 'brands/' + brandId + '/ads/' + adID + '/vertical-attributes' ).pipe(
      tap(_ => console.log('fetched getBrandCategories')),
      catchError(Errors.handleError<{response: any}>('getBrandCategories'))
    );
  }

  // Clear cachessss
  clearCache() {
    this.vendors = null;
  }

}
