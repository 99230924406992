import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GroupService} from '../../_services/group/group.service';
import {AlertService} from '../../_services/alert.service';
import * as moment from 'moment';
import {CampaignGroup} from '../../_models/campaignGroup';

@Component({
  selector: 'app-multiple-edit-group-modal',
  templateUrl: './multiple-edit-group-modal.component.html',
  styleUrls: ['./multiple-edit-group-modal.component.scss']
})
export class MultipleEditGroupModalComponent implements OnInit {
  ngLoading = false;
  freqCap = false;
  newGroupForm: FormGroup;
  selectedGroups: Array<{}>;
  @Output() refreshGrid = new EventEmitter<string>();
  constructor(
    private formB: FormBuilder,
    private groupService: GroupService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.newGroupForm = this.formB.group({
      status: [''],
      budget: [{value: '', disabled: true}, Validators.required],
      kpiValue: [''],
      kpiType: [''],
      budgetType: [''],
      budgetStartDate: [{value: '', disabled: true}, Validators.required],
      budgetEndDate: [{value: '', disabled: true}, Validators.required],
      advertiserSpendType: [''],
      advertiserSpendRate: [''],
      advertiserMaxBillable: ['']
    });
  }

  get formControls() {
    return this.newGroupForm.controls;
  }

  onSubmit() {
    if (this.selectedGroups === null) {
      this.alertService.alert('error', 'You need to select at least one Campaign Group.');
      return;
    }
    const groups = {
      status: this.formControls.status.value,
      budget: {
        amount: parseFloat(String(this.formControls.budget.value).replace('$', '')).toFixed(2),
        type: this.formControls.budgetType.value,
        evenDeliveryEnabled: true,
        schedule: {
          flightDates: {
            from: moment(this.formControls.budgetStartDate.value).format('YYYYMMDD'),
            to: moment(this.formControls.budgetEndDate.value).format('YYYYMMDD')
          }
        }
      },
      advertiserSpendType: this.formControls.advertiserSpendType.value,
      advertiserSpendRate: parseFloat(String(this.formControls.advertiserSpendRate.value).replace('$', '')).toFixed(2),
      advertiserMaxBillable: parseFloat(String(this.formControls.advertiserMaxBillable.value).replace('$', '')).toFixed(2),
      groupIds: this.selectedGroups
    };
    this.groupService.updateMultipleGroup(groups)
      .subscribe(response => {
        this.ngLoading = false;
        $('.new-multiple-edit-group-modal').modal('hide');
        this.refreshGrid.emit('group');
        // @ts-ignore
        if ( String(response.status) === 'SUCCESS' ) {
          this.alertService.alert('success', 'Campaign Groups updated successfully.');
        }
      });
  }

  closeNewGroupModal() {
    this.newGroupForm.reset();
    this.ngOnInit();
    $('.new-multiple-edit-group-modal').modal('hide');
  }

  budgetTypeChanged(event: any) {
    if (event === 'none' || event === 'NaN') {
      this.newGroupForm.get('budget').disable();
      this.newGroupForm.get('budgetEndDate').disable();
      this.newGroupForm.get('budgetStartDate').disable();
    } else {
      this.newGroupForm.get('budget').enable();
      this.newGroupForm.get('budgetEndDate').enable();
      this.newGroupForm.get('budgetStartDate').enable();
    }
  }
}
