import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertService} from '../../../_services/alert.service';

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss']
})
export class StatusCellRendererComponent {
  // @ts-ignore
  @ViewChild('i') customHtml;
  params;
  text = 'On';
  checkedSlide = false;
  slide = true;

  constructor(private alert: AlertService) { }

  agInit(params: any): void {
    this.params = params;
    this.slide = (typeof this.params.data.campaignGroupId === 'undefined' && typeof this.params.data.creativeId === 'undefined');
    if (this.slide) {
      if (typeof this.params.value !== 'string') {
        this.checkedSlide = (this.params.column.colId === 'deleted' || this.params.column.colId === 'archived') ? !this.params.value : this.params.value;
        this.text = (!this.params.value) ? 'on' : 'archived';
      } else {
        this.text = this.params.value;
        this.checkedSlide = (this.text === 'online') ? true : false;
      }
    } else {
      this.text = this.params.value;
    }
  }

  getValue() {
    // return this.customHtml.nativeElement.value;
    return Number(this.checkedSlide);
  }

  refresh(): boolean {
    return false;
  }

  statusChanged(event) {
    this.text = (this.text === 'On') ? 'Archived' : 'On';
  }

  selectChanged(event: Event) {
    this.alert.confirm((action) => {
      if (action.value) {
        // @ts-ignore
        this.params.data.status = event.target.value;
        if (typeof this.params.data.campaignId !== 'undefined') {
          this.params.context.componentParent.updateCampaignStatus(this.params.data);
        } else if (typeof this.params.data.campaignGroupId !== 'undefined') {
          this.params.context.componentParent.updateStatusGroupModal(this.params.data);
        } else if (typeof this.params.data.creativeId !== 'undefined') {
          this.params.context.componentParent.updateStatusAdModal(this.params.data);
        }
      } else {
        this.text = this.params.data.status;
      }
    }, 'Are you sure you want to change the status?');
  }

  refuseSlider() {
    console.log(this.params);
    return false;
  }

  confirmSlider(type = 'slider') {
    this.checkedSlide = !this.checkedSlide;
    this.text = (this.text === 'On') ? 'Archived' : 'On';
    this.params.value = Number(this.checkedSlide);
    this.params.setValue(Number(this.checkedSlide));
  }
}
