import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  AudienceResponse,
  AudiencesStatsResponse,
  BrandResponse,
  ConversionResponse,
  ConversionsStatsResponse
} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Audience} from '../../_models/audience';
import {Conversion} from '../../_models/conversions';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {
  private convUrl = 'conversions';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getConversionsStats(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0, searchVal = ''): Observable<ConversionsStatsResponse> {
    return this.http.get<ConversionsStatsResponse>(
      `${environment.apiUrl}` + this.convUrl
      + '?brandId=' + brandId + '&max=' + max + '&offset=' + offset + '&filter=' + params.filter
      + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&deleted=' + ((params.status === 'false') ? '0' : '')
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<ConversionsStatsResponse>('getAudiencesStats'))
    );
  }

  getConversionsNoStats(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 20, offset: number = 0, searchVal = ''): Observable<ConversionsStatsResponse> {
    return this.http.get<ConversionsStatsResponse>(
      `${environment.apiUrl}` + this.convUrl + '/nostats/' + brandId
      + '?brandId=' + brandId + '&max=' + max + '&offset=' + offset + '&filter=' + params.filter
      + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + '&deleted=' + ((params.status === 'false') ? '0' : '')
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<ConversionsStatsResponse>('getAudiencesStats'))
    );
  }

  getConversionsFromMultpBrands(params = {brandIds: [], filter: '', dateTo: '', dateFrom: '', status: ''}, max: number = 50, offset: number = 0, searchVal = ''): Observable<ConversionsStatsResponse> {
    return this.http.post<ConversionsStatsResponse>(
      `${environment.apiUrl}` + this.convUrl + '/multiple', params
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<ConversionsStatsResponse>('getConversionsFromMultpBrands'))
    );
  }

  getAudienceById(id: any) {
    return this.http.get<BrandResponse>(`${environment.apiUrl}` + this.convUrl + '/' + id).pipe(
      tap(_ => console.log('fetched audience')),
      catchError(Errors.handleError<BrandResponse>('getAudienceById'))
    );
  }

  createConversion(conv: Conversion): Observable<ConversionResponse> {
    return this.http.post<ConversionResponse>(`${environment.apiUrl}` + this.convUrl , conv, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createConversion', conv))
      ) as Observable<ConversionResponse>;
  }

  updateConversion(conv: Conversion, id: bigint): Observable<ConversionResponse>  {
    return this.http.put<ConversionResponse>(`${environment.apiUrl}` + this.convUrl + '/' + id, conv, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateConversion', conv))
      ) as Observable<ConversionResponse>;
  }

  getAllTagsCode(convIds: number[], type: string, convNames: string[], linkUrl = '', linkText = '') {
    const httpOptionsCustom = {
      responseType: 'text',
      Accept: 'text/plain'
    };
    return this.http.get(`${environment.apiUrl}` + this.convUrl + '/getAllTags'
      + '?offersId[]=' + convIds.join('&offersId[]=') + '&type=' + type
      + '&conversionsName[]=' + convNames.join('&conversionsName[]=')
      + '&linkUrl=' + linkUrl
      + '&linkText=' + linkText ,
      // @ts-ignore
      httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched getAllTagsCode')),
        catchError(Errors.handleError('getAllTagsCode'))
      );
  }

  getConversionAudience(brandId: number, offerId: number) {
    return this.http.get<Audience>(`${environment.apiUrl}` + this.convUrl + '/' + offerId + '/audience?brandId=' + brandId)
      .pipe(
        tap(_ => console.log('fetched getConversionAudience'))
      );
  }

  setConversionAudience(brandId: number, offerId: number, audId: bigint) {
    return this.http.put<Audience>(`${environment.apiUrl}` + this.convUrl + '/' + offerId + '/audience', {audienceId: audId, brandId: brandId})
      .pipe(
        tap(_ => console.log('fetched getConversionAudience')),
        catchError(Errors.handleError<Audience>('getConversionAudience'))
      );
  }

  getTagCode(type: string, offerId: number) {
    return this.http.get<{response: string, status: string}>(`${environment.apiUrl}` + this.convUrl + '/getTag/' + offerId + '?type=' + type)
      .pipe(
        tap(_ => console.log('fetched audience')),
        catchError(Errors.handleError<{response: string, status: string}>('getTagCode'))
      );
  }
}
