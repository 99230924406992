import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {Audience} from '../../_models/audience';
import {Conversion} from '../../_models/conversions';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-new-conversion-modal',
  templateUrl: './new-conversion-modal.component.html',
  styleUrls: ['./new-conversion-modal.component.scss']
})
export class NewConversionModalComponent implements OnInit {

  // private _parent: BrandsComponent ;
  // @Input() set parent(value: BrandsComponent ) {
  //   this._parent = value;
  // }
  brandId: number;

  createOrEdit = 'Create';
  ngLoading = false;
  newConvForm: FormGroup;
  offerId: bigint;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private formB: FormBuilder,
    private convService: ConversionService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.newConvForm = this.formB.group({
      name: ['', Validators.required],
      defaultRevenue: ['0.00', Validators.required],
      deleted: [false],
      notes: ['']
    });
  }

  loadConversion(conv: Conversion) {
    this.createOrEdit = 'Edit';
    this.offerId = conv.offerId;
    this.newConvForm.addControl('brandId', new FormControl());
    this.newConvForm.controls.brandId.setValue(this.brandId);
    this.newConvForm.controls.name.setValue(conv.name);
    this.newConvForm.controls.defaultRevenue.setValue(conv.defaultRevenue.toFixed(2));
    this.newConvForm.controls.deleted.setValue(conv.deleted);
  }

  onSubmit() {
    this.ngLoading = true;
    this.newConvForm.addControl('brandId', new FormControl());
    this.newConvForm.controls.brandId.setValue(this.brandId);
    const form = {...this.newConvForm.value};
    form.defaultRevenue = parseFloat(form.defaultRevenue.replace(',', '')).toFixed(2);
    if (this.createOrEdit === 'Create') {
      this.convService.createConversion(form as Conversion).subscribe(
        (response) => {
          this.newConvForm.reset();
          this.ngOnInit();
          this.refreshGrid.emit('conversion');
          $('.new-conversion-modal').modal('hide')
          if (response.status === 'success') {
            this.alertService.alert('success', 'Conversion created successfully.')
          }
          this.ngLoading = false;
        },
        (error) => { console.log(error, 'updateAudience'); this.ngLoading = false; }
      );
    } else {
      this.convService.updateConversion(form as Conversion, this.offerId).subscribe(
        (response) => {
          this.newConvForm.reset();
          this.ngOnInit();
          this.refreshGrid.emit('conversion');
          $('.new-conversion-modal').modal('hide')
          if (response.status === 'success') {
            this.alertService.alert('success', 'Conversion updated successfully.')
          }
          this.ngLoading = false;
        },
        (error) => { console.log(error, 'updateAudience'); this.ngLoading = false; }
      )
    }

  }

  get formControls() {
    return this.newConvForm.controls;
  }

  public resetModal() {
    this.createOrEdit = 'Create';
    this.newConvForm.reset();
    // this.ngOnInit();
  }
}
