import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { AlertService } from '../_services/alert.service';
import {CommonAuthService} from '../auth/common-auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: CommonAuthService, private alertService: AlertService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
          tap(_ => { localStorage.removeItem('tooManyAttemps'); }),
          catchError(err => {
            console.log('error: ', err);
            if ([401, 403].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authenticationService.logout(localStorage.getItem('token'));
                // location.reload(true);
              location.href = '/home/login';
            }

            if ([429].indexOf(err.status) !== -1) {
              const attemps = localStorage.getItem('tooManyAttemps');
              if (typeof attemps !== 'undefined' && attemps !== null && attemps !== '') {
                return;
              }
              localStorage.setItem('tooManyAttemps', 'exists');
            }

            if (typeof err.error !== 'undefined' && typeof err.error.message !== 'undefined' && typeof err.error.message.message !== 'undefined' && err.error.message.message.indexOf('ConversionPixel does not have an audience') !== -1) {
              return throwError(err);
            }

            const error = err.error.error || err.error.message || err.statusText || err.message;
            if ( typeof error === 'object' ) {
              for (const er in error) {
                this.alertService.alert('error', error[er], 5000);
              }
            } else {
              this.alertService.alert('error', error, 5000);
            }
            return throwError(err);
        })
      );
    }
}
