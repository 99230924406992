import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {AdService} from '../../_services/ad/ad.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AudiencesService} from '../../_services/audiences/audiences.service';

@Component({
  selector: 'app-audience-upload-deviceids',
  templateUrl: './audience-upload-deviceids.component.html',
  styleUrls: ['./audience-upload-deviceids.component.scss']
})
export class AudienceUploadDeviceidsComponent implements OnInit {

  pondOptionsTxt: any;
  newForm: FormGroup;
  audienceId: number;
  loading = false;
  brandName = '';
  pondFiles: any[];
  constructor(private adService: AdService, private fb: FormBuilder, private audService: AudiencesService) { }

  ngOnInit() {
    this.newForm = this.fb.group({
      fileUrl: ['', Validators.required]
    });
    this.pondOptionsTxt = {
      multiple: false,
      labelIdle: 'Drop file or click here for browsing...',
      acceptedFileTypes: 'text/*',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadAudio(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              this.newForm.controls.fileUrl.setValue(response);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getFile(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
  }

  public pondRemovedFile(event) {
    this.adService.deleteFile(event.file).subscribe(response => {}, error => {console.log(error, 'pondRemovedFile')});
  }

  onSubmit() {
    this.loading = true;
    this.audService.uploadDeviceIds(this.newForm.controls.fileUrl.value, this.audienceId).subscribe(
      (response) => {  this.newForm.reset(); this.pondFiles = []; this.loading = false; $('.audience-upload-deviceids').modal('hide'); },
      (error) => { console.log(error, 'uploadDeviceIds');  this.loading = false; }
    )
  }
}
