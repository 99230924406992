import {Component, OnInit, ViewChild} from '@angular/core';
import {UserRestService} from '../../admin/users/user-rest.service';

@Component({
  selector: 'app-ad-review',
  templateUrl: './ad-review.component.html',
  styleUrls: ['./ad-review.component.scss']
})
export class AdReviewComponent {
  // @ts-ignore
  @ViewChild('i') buttons;
  params;
  internal = '#2ecc71';
  google = 'none';
  nexus = 'none';
  googleFeed: any;
  nexusFeed: any;

  constructor(private userRest: UserRestService) {}

  agInit(params: any): void {
    this.params = params;
    this.internal = (this.params.data.reviewStatus === 'eligible') ? '#2ecc71' :
      ((this.params.data.reviewStatus === 'denied') ? '#d6d56e' : '#959595' );
    if (this.params.data.hasOwnProperty('externalReviews') && this.params.data.externalReviews !== null) {
      for (let review of this.params.data.externalReviews) {
        if (review.partner === 'APP_NEXUS') {
          this.nexus = (review.status === 'approved') ? '#2ecc71' : ((review.status === 'expired') ? '#d6d56e' : '#959595' );
          this.nexusFeed = review;
        }
        if (review.partner === 'GOOGLE') {
          this.google = (review.status === 'approved') ? '#2ecc71' : ((review.status === 'expired') ? '#d6d56e' : '#959595' );
          this.googleFeed = review;
        }
      }
    }
  }

  getValue() {
    return this.buttons.nativeElement.value;
  }

  saveChanges() {

  }

  deleteRow() {
    this.params.api.updateRowData({remove: [this.params.node.data]});
  }
}
