import {Component, OnInit, ViewChild} from '@angular/core';
import {DomainListService} from '../../_services/domain-list/domain-list.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {Observable} from 'rxjs';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {AdReviewComponent} from '../../shared/ad-review/ad-review.component';
import {NewDomainListModalComponent} from '../../modals/new-domain-list-modal/new-domain-list-modal.component';
import {UniversalPixel} from '../../_models/universal_pixel';
import {Domains} from '../../_models/domains';
import {CsvExportParams} from 'ag-grid-community';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {
  @ViewChild(NewDomainListModalComponent, null) newInventoryModal: NewDomainListModalComponent;
  rowData: any;
  ngLoading = false;
  columnDefsDomains = [
    {headerName: 'ID', field: 'id', width: 100 },
    {
      headerName: 'Actions',
      field: 'id',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'DomainList' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 500 },
    {headerName: 'Type', field: 'type', width: 150, cellRenderer: this.typeCellRenderer },
    {headerName: 'URL Count', field: 'urlCount', width: 150, cellRenderer: this.medalCellRenderer},
  ];
  rowDataDomains = [];
  title = 'domains';
  rowSelection = 'single';
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
  };
  domainsGrid: any;

  refreshGrid(event: any) {

  }

  constructor(
    private domainListService: DomainListService,
    private alertService: AlertService,
    private auth: CommonAuthService,
    private gridRender: GridRenderService,
    private breadcrumbsService: BreadcrumbsService
  ) { }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'Domains', url: '/domains', params: []},
    ];
    this.getDomainList();
  }

  rowSelected(event: any) {

  }

  editUniversalModal(node: any) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      console.log('edit-' + node.data.id);
      this.newInventoryModal.loadDomainList(node.data as Domains);
      $('.new-domain-list-modal').modal('show');
    }
  }

  showNewDomListModal() {
    this.newInventoryModal.editOrUpdate = 'New';
    this.newInventoryModal.newDomainForm.reset();
    this.newInventoryModal.domappCount = 2000;
    this.newInventoryModal.disabledFile = false;
    this.newInventoryModal.selInpMeth = true;
    this.newInventoryModal.inputmethod = true;
    this.newInventoryModal.newDomainForm.controls.inputMethod.setValue('input');
    this.newInventoryModal.newDomainForm.controls.listType.setValue('whitelist');
    $('.new-domain-list-modal').modal('show');
  }

  medalCellRenderer(params) {
    return params.data.domains.length;
  }

  typeCellRenderer(params) {
    return params.data.type.toUpperCase();
  }

  getDomainList() {
    this.ngLoading = true;
    this.domainListService.getDomainList({ name: (typeof $('#search_domain_name').val() !== 'undefined') ? $('#search_domain_name').val().toString() : '' }).subscribe(
      (response) => {
        console.log(response);
        this.rowData =  Observable.create((obs) => {
          obs.next(response.response.domain_lists);
          obs.complete();
        });
        this.ngLoading = false;
      });
  }

  domainsGridReady(event: any) {
    this.domainsGrid = event.api;
  }

  exportDomains() {
    this.domainsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }
}
