import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';

@Component({
  selector: 'app-new-multiple-tag-modal',
  templateUrl: './new-multiple-tag-modal.component.html',
  styleUrls: ['./new-multiple-tag-modal.component.scss']
})
export class NewMultipleTagModalComponent implements OnInit {

  private _parent: BrandsComponent ;
  @Input() set parent(value: BrandsComponent ) {
    this._parent = value;
  }
  columnDefsVendors = [
    {headerName: 'Vendor', field: 'url', width: 400 },
  ];
  rowDataVendors = [];


  constructor() { }

  ngOnInit() {
  }

}
