import { Injectable } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormBuilderService {

  buildedForm: any;
  constructor(private fb: FormBuilder) { }

  buildForm(obj: any) {
    if (Array.isArray(obj)) {
      const arrayForm = this.fb.array([]);
      for (const elem of obj) {
        arrayForm.push(this.buildForm(elem))
      }
      return arrayForm;
    } else if (typeof obj === 'object') {
      const groupForm = this.fb.group({});
      const keys  = Object.keys(obj);
      for (const key of keys) {
        groupForm.addControl(key, this.buildForm(obj[key]));
      }
      return groupForm;
    } else {
      return this.fb.control(obj);
    }
  }
}
