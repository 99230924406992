import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {BrandCategory} from '../../_models/responses';
import {AdService} from '../../_services/ad/ad.service';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {environment} from '../../../environments/environment';
import {Ad} from '../../_models/ad';
import {CampaignGroup} from '../../_models/campaignGroup';
import {GridCellSelectComponent} from '../grid-cell-select/grid-cell-select.component';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {MultipleEdit2AdModalComponent} from '../../modals/multiple-edit2-ad-modal/multiple-edit2-ad-modal.component';
import {TextEditCellComponent} from '../text-edit-cell/text-edit-cell.component';
import {NewImageModalComponent} from '../../modals/Ads/new-image-modal/new-image-modal.component';

@Component({
  selector: 'app-new-ad',
  templateUrl: './new-ad.component.html',
  styleUrls: ['./new-ad.component.scss']
})
export class NewAdComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild('myPondImage') myPondImage: any;
  // @ts-ignore
  @ViewChild('myPondAudio') myPondAudio: any;
  // @ts-ignore
  @ViewChild('myPondZip') myPondZip: any;
  // @ts-ignore
  @ViewChild('myPond') myPond: any;
  public pondOptionsImage: any;
  public pondFilesImage = [];
  public pondFilesZip = [];
  public pondFilesAudio = [];
  public pondFilesVideo = [];
  public allowedSizes = ['1x1', '3x3', '3x300', '6x6', '6x7', '6x8', '12x12', '120x20', '120x90', '120x600', '160x600', '168x28', '180x150', '200x200', '216x36', '220x90', '234x60', '240x400', '250x250', '250x360', '300x50', '300x60', '300x100', '300x170', '300x250', '300x340', '300x600', '300x1050', '320x50', '320x100', '320x180', '320x200', '320x240', '320x250', '320x320', '320x480', '333x333', '336x280', '386x300', '400x300', '468x60', '480x75', '480x320', '480x360', '480x480', '480x800', '600x400', '600x600', '631x385', '640x300', '640x480', '640x640', '687x228', '700x450', '720x300', '720x480', '728x90', '750x200', '768x768', '768x1024', '800x1100', '900x550', '960x30', '960x470', '970x66', '970x90', '970x250', '970x550', '980x120', '980x150', '990x200', '1024x768', '1030x590', '1200x627', '1200x1200', '1272x328', '1280x720', '1600x500', '1800x1000', '1920x480', '1920x1080', '1920x1200', '1920x1280'];
  public img = new Image();
  public imagesUploaded = [];
  public zipFile: any;
  public htmlToAdd = '';
  public newForm: FormGroup;
  public urlReg = '(https?://)([\\\\da-z.-]+)\\\\.([a-z.]{2,6})[/\\\\w .-]*/?';
  public domainRegex = '^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$';
  public selectedOptions = [];
  public categories: Observable<BrandCategory[]>;
  public allCategories: BrandCategory[];
  public categoriesGroup: any;
  public createOrEdit = 'Create';
  public ngLoading = false;
  public impTrackingField = [1];
  public scriptTrackingField = [1];
  public impTrackingFieldCompanion = [1];
  public scriptTrackingFieldCompanion = [1];
  public brandId: number;
  public brandDomain: string;
  public trackingUrlsValues = {
    'Click Tracking': 'click',
    'Audio/Video Start': 'start',
    'First Quartile': 'firstquartile',
    'Second Quartile': 'midpoint',
    'Third Quartile': 'thirdquartile',
    'Completion': 'complete',
  };
  public columnDefsVasts = [
    {headerName: 'Tracking URL', field: 'url', width: 250, editable: true, cellRenderer: (params) => {
      return '<span style="position: absolute;left: 4px;top: 0px;">' + ((params.value !== '') ? params.value : '<i>Enter a tracking URL</i>') + '</span><i class="ti-pencil" style="position: absolute;right: 4px;top: 8px;"></i>'
      } },
    {
      headerName: 'Event Type',
      field: 'type', width: 150,
      editable: true,
      cellRenderer: (params) => {
        return '<span style="position: absolute;left: 4px;top: 0px;">' + ((params.value !== '') ? params.value : '<i>Enter a Event Type</i>') + '</span><i class="ti-pencil" style="position: absolute;right: 4px;top: 8px;"></i>'
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        // cellRenderer: 'gridCellSelectComponent',
        values: [
          'click',//'Click Tracking',
          'start',//'Audio/Video Start',
          'firstquartile',//'First Quartile',
          'midpoint',//'Second Quartile',
          'thirdquartile',//'Third Quartile',
          'complete'//'Completion'
        ]
      }
    },
    {headerName: '', field: 'remove', width: 100, cellRenderer: 'actionsCellRendererComponent', cellRendererParams: {type: 'onlyDelete', renderType: 'Tracking URL'} },
  ];
  public rowDataVasts: Observable<Array<{}>>;
  public frameworkComponents = {
    gridCellSelectComponent: GridCellSelectComponent,
    actionsCellRendererComponent: ActionsCellRendererComponent,
    textEditCellComponent: TextEditCellComponent
  };
  public gridTrackingUrls: any;

  public columnDefsVendors = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      field: 'vendorId',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null,
      filter: false
    },
    {headerName: 'Vendor', field: 'name', width: 400 },
  ];
  public rowDataVendors: Observable<Array<{}>>;
  public vendorsApiGrid: any;
  @Output() refreshGrid = new EventEmitter<string>();

  // @ViewChild(NewImageModalComponent, null) newImageAdModal: NewImageModalComponent;
  valuesAdChanged = false;
  fullyLoaded = true;
  valuesChangedSubs: Subscription;

  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService,
  ) { }

  ngOnInit() {
    this.rowDataVasts = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.selectedOptions = [];
    this.pondOptionsImage = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/jpeg, image/png, image/jpg, image/gif',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              this.img.src = `${environment.publicUrl}` + response;
              if (typeof this.newForm.controls.assetUrlCompanion !== 'undefined' && this.newForm.controls.assetUrlCompanion !== null) {
                this.newForm.controls.assetUrlCompanion.setValue(response);
              } else {
                this.newForm.controls.assetUrl.setValue(response);
              }
              // console.log('IMG ASSET: ', this.newForm.controls.assetUrlCompanion.value, this.newForm.value);
              document.getElementById('adPanel').appendChild(this.img);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getImage(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    //this.newForm = this.newImageAdModal.newForm
    // this.newForm = this.fb.group({
    //   adType: ['image'],
    //   label: ['', Validators.required],
    // status: ['online'],
    //   width: [''],
    //   height: [''],
    //   type: ['banner'],
    //   assetUrl: ['', [Validators.required]],
    //   clickUrl: ['', [Validators.required]],
    //   winNotificationUrl: this.fb.array([this.fb.control('')]),
    //   scriptTrackingUrl: this.fb.array([this.fb.control('')]),
    //   sslEnabled: [],
    //   // tslint:disable-next-line:max-line-length
    //   landingPageDomain: ['', [Validators.pattern(this.domainRegex), Validators.required]],
    //   reviewAttributes: [''],
    //   brandId: [''],
    //   impressionTracking: ['']
    // });

    this.valuesAdChanged = false;
    this.fullyLoaded = true;
    this.getBrandCategories();
  }

  ngOnDestroy(): void {
    if (typeof this.valuesChangedSubs !== 'undefined') {
      this.valuesChangedSubs.unsubscribe();
    }
  }

  setValuesCHangedEvent() {
    this.valuesChangedSubs = this.newForm.valueChanges.subscribe(
      response => {
        console.log('newForm');
        if (this.fullyLoaded) {
          this.valuesAdChanged = true;
        }
      }
    );
  }

  public impressionTrackingUrls() {
    const values = [];
    if (typeof this.newForm.get('impressionTracking') !== 'undefined' && this.newForm.get('impressionTracking') !== null) {
      const controlsPixels = (this.newForm.get('impressionTracking') as FormArray).value;
      for (const control of controlsPixels) {
        if (control !== '') {
          values.push({'url': control, 'type': 'PIXEL'})
        }
      }
    }
    if (typeof this.newForm.get('scriptTrackingUrl') !== 'undefined' && this.newForm.get('scriptTrackingUrl') !== null) {
      const controlsScripts = (this.newForm.get('scriptTrackingUrl') as FormArray).value;
      for (const control of controlsScripts) {
        if (control !== '') {
          values.push({'url': control, 'type': 'SCRIPT'});
          (this.newForm.controls.impressionTracking as FormArray).push(this.fb.control(''));
        }
      }
    }
    console.log('impressionTracking', this.newForm.get('impressionTracking'), values);
    return values;
  }

  public impressionTrackingUrlsCompanion() {
    const values = [];
    if (typeof this.newForm.get('winNotificationUrCompaniolCompanion') !== 'undefined' && this.newForm.get('winNotificationUrlCompanion') !== null) {
      const controlsPixels = (this.newForm.get('winNotificationUrlCompanion') as FormArray).value;
      for (const control of controlsPixels) {
        values.push({'url': control, 'type': 'PIXEL'})
      }
    }
    if (typeof this.newForm.get('scriptTrackingUrlCompanion') !== 'undefined' && this.newForm.get('scriptTrackingUrlCompanion') !== null) {
      const controlsScripts = (this.newForm.get('scriptTrackingUrlCompanion') as FormArray).value;
      for (const control of controlsScripts) {
        values.push({'url': control, 'type': 'SCRIPT'})
      }
    }
    return values;
  }

  public compareCategories = (item, selected) => {
    if (selected.attributeId && item.attributeId) {
      return item.attributeId === selected.attributeId;
    }
    return false;
  };

  public get formControls() {
    return this.newForm.controls;
  }

  public get winNotificationUrlsControls() {
    return this.newForm.controls.impressionTracking['controls'] as FormArray;
  }

  public get scriptTrackingUrlsControls() {
    return this.newForm.controls.scriptTrackingUrl['controls'] as FormArray;
  }

  public get winNotificationUrlsCompanionControls() {
    return this.newForm.controls.winNotificationUrlCompanion['controls'] as FormArray;
  }

  public get scriptTrackingUrlsCompanionControls() {
    return this.newForm.controls.scriptTrackingUrlCompanion['controls'] as FormArray;
  }

  public getBrandCategories() {
    this.brandService.getBrandCategories().subscribe(
      (response) => {
        this.allCategories = response.response.brandCategories;
        const filteredCategories = this.allCategories.filter((item, index, array) => {
          if (typeof item.parentId) {
            const indexParent = array.findIndex(elem => elem.attributeId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.categoriesGroup = (text: string, children: any[]) => {
          const group = this.allCategories.find(elem => elem.attributeId === children[0].parentId);
          return group;
        }
        this.categories = Observable.create((obs) => {
          obs.next(filteredCategories);
          obs.complete();
        });
      },
      (error) => { console.log(error); }
    )
  }

  public addImpTrackingField(values: any = {}) {
    (this.newForm.get('impressionTracking') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.impTrackingField.push(this.impTrackingField.length);
  }

  public deleteImpTrackingField(pos: number) {
    console.log(pos, this.impTrackingField);
    (this.newForm.get('impressionTracking') as FormArray).removeAt(pos);
    this.impTrackingField.splice(pos, 1);
  }

  public addScriptTrackingField(values: any = {}) {
    (this.newForm.get('scriptTrackingUrl') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.scriptTrackingField.push(this.scriptTrackingField.length);
  }

  public deleteScriptTrackingField(pos: number) {
    console.log(pos, this.scriptTrackingField);
    (this.newForm.get('scriptTrackingUrl') as FormArray).removeAt(pos);
    this.scriptTrackingField.splice(pos, 1);
  }

  public addImpTrackingFieldCompanion(values: any = {}) {
    (this.newForm.get('winNotificationUrlCompanion') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.impTrackingFieldCompanion.push(this.impTrackingFieldCompanion.length);
  }

  public deleteImpTrackingFieldCompanion(pos: number) {
    console.log(pos, this.impTrackingFieldCompanion);
    (this.newForm.get('winNotificationUrlCompanion') as FormArray).removeAt(pos);
    this.impTrackingFieldCompanion.splice(pos, 1);
  }

  public addScriptTrackingFieldCompanion(values: any = {}) {
    (this.newForm.get('scriptTrackingUrlCompanion') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.scriptTrackingFieldCompanion.push(this.scriptTrackingFieldCompanion.length);
  }

  public deleteScriptTrackingFieldCompanion(pos: number) {
    console.log(pos, this.scriptTrackingFieldCompanion);
    (this.newForm.get('scriptTrackingUrlCompanion') as FormArray).removeAt(pos);
    this.scriptTrackingFieldCompanion.splice(pos, 1);
  }

  public pondHandleInit() {
    // console.log('FilePond has initialised', this.myPond);
  }

  public pondHandleAddFile(event: any) {
    // console.log('A file was added', event);
  }

  public pondRemovedFile(event, from = 'main') {
    const element = document.getElementById('adPanel');
    try {
      element.innerHTML = '';
      if (from === 'companion') {
        this.newForm.controls.assetUrlCompanion.setValue('');
      } else {
        this.newForm.controls.assetUrl.setValue('');
      }
    } catch (error) {
      console.error(error, 'pondRemovedFile');
    }
    this.adService.deleteFile(event.file).subscribe(response => {}, error => {console.log(error, 'pondRemovedFile')});
  }

  public pondRemovedZipFile(event) {
    const element = document.getElementById('adPanel2');
    try {
      element.innerHTML = '';
    } catch (error) {
      console.error(error, 'pondRemovedZipFile');
    }
    this.adService.deleteFile(event.file).subscribe(response => {}, error => {console.log(error, 'pondRemovedZipFile')});
  }

  public getGridTrackingUrls() {
    const urls: Array<{type: string, urls: string[]}> = [];
    // const types = this.trackingUrlsValues;
    // console.log(types);
    this.gridTrackingUrls.forEachNode( function(rowNode, index) {
      console.log(rowNode.data);
      // const realType = types[rowNode.data.type];
      const indexUrl = urls.findIndex((element) => element.type === rowNode.data.type);
      if (indexUrl !== -1) {
        urls[indexUrl].urls.push(rowNode.data.url)
      } else {
        urls.push({type: rowNode.data.type, urls: [rowNode.data.url]});
      }
    });
    return urls;
  }

  public loadAd(ad: Ad) {
    console.log('LOAD AD: ', this.newForm, ad);
    window.addEventListener('beforeunload', event => {
      console.log('beforeunload', event);
      if (this.valuesAdChanged) {
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = 'You have unsaved changes...!';
      }
    }, false);
    this.createOrEdit = 'Edit';
    this.fullyLoaded = false;
    this.valuesAdChanged = false;
    this.newForm.addControl('creativeId', this.fb.control(ad.creativeId, [Validators.required]));
    this.newForm.controls.label.setValue(ad.label);
    this.newForm.controls.status.setValue(ad.status);
    this.newForm.controls.type.setValue(ad.type);
    this.newForm.controls.brandId.setValue(this.brandId);
    this.adService.getBrandCategories(this.brandId, ad.creativeId).subscribe(
      response => {
        this.newForm.controls.reviewAttributes.setValue(response.response);
        this.selectedOptions = response.response as unknown as Array<any>;
        console.log(response, this.selectedOptions)
      }, error => console.log(error)
    )
    if (typeof this.newForm.controls.assetUrl !== 'undefined' && this.newForm.controls.assetUrl !== null) {
      this.newForm.controls.assetUrl.setValue(ad.assetUrl);
      this.img.src = ad.assetUrl;
      document.getElementById('adPanel').appendChild(this.img);
    }
    // this.pondFiles = [imgAd.assetUrl];
    if (typeof this.newForm.controls.clickUrl !== 'undefined' && this.newForm.controls.clickUrl !== null) {
      this.newForm.controls.clickUrl.setValue(ad.clickUrl);
    }
    if (typeof this.newForm.controls.vastUrl !== 'undefined' && this.newForm.controls.vastUrl !== null) {
      this.newForm.controls.vastUrl.setValue(ad.vastUrl);
    }
    if (typeof this.newForm.controls.apis !== 'undefined' && this.newForm.controls.apis !== null) {
      this.newForm.controls.apis.setValue(ad.apis);
    }
    if (typeof this.vendorsApiGrid !== 'undefined' && this.vendorsApiGrid !== null &&
      typeof this.newForm.controls.vendors !== 'undefined' && this.newForm.controls.vendors !== null) {
      this.newForm.controls.vendors.setValue(this.vendorsApiGrid.getSelectedRows().map((value) => value.vendorId));
    }
    if (ad.winNotificationUrl !== null) {
      if (typeof ad.winNotificationUrl === 'string') {
        (<FormArray>this.newForm.controls.impressionTracking).controls['0'].setValue(ad.winNotificationUrl);
      }
    }
    if (typeof ad.impressionTracking !== 'undefined' && ad.impressionTracking !== null) {
      for (const index in ad.impressionTracking) {
        if (ad.impressionTracking[index].type === 'PIXEL') {
          if ((<FormArray>this.newForm.controls.impressionTracking).at(0).value !== null
            && (<FormArray>this.newForm.controls.impressionTracking).at(0).value !== '') {
            (<FormArray>this.newForm.controls.impressionTracking).push(this.fb.control(ad.impressionTracking[index].url));
            this.impTrackingField.push((<FormArray>this.newForm.controls.impressionTracking).controls.length);
          } else {
            // (<FormArray>this.newForm.controls.impressionTracking).setControl(0, this.fb.control(ad.impressionTracking[index].url));
            (<FormArray>this.newForm.controls.impressionTracking).at(0).setValue(ad.impressionTracking[index].url);
          }
        } else {
          if ((<FormArray>this.newForm.controls.scriptTrackingUrl).controls['0'].value !== '') {
            (<FormArray>this.newForm.controls.scriptTrackingUrl).push(new FormControl(ad.impressionTracking[index].url));
            this.scriptTrackingField.push((<FormArray>this.newForm.controls.scriptTrackingUrl).controls.length);
          } else {
            (<FormArray>this.newForm.controls.scriptTrackingUrl).controls['0'].setValue(ad.impressionTracking[index].url);
          }
        }
      }
    }
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    this.newForm.controls.sslEnabled.setValue(ad.sslEnabled);
    this.newForm.controls.landingPageDomain.setValue(ad.landingPageDomain);
    // this.newForm.controls.landingPageDomain.disable();
    // this.newForm.controls.reviewAttributes.setValue((ad.reviewAttributes !== null) ? ad.reviewAttributes : '');
    // if (typeof ad.reviewAttributes !== 'undefined' && ad.reviewAttributes !== null && ad.reviewAttributes.length > 0) {
    //   this.selectedOptions = [...this.selectedOptions, ...ad.reviewAttributes];
    // }
    if (typeof ad.nativeFields !== 'undefined' && ad.nativeFields !== null) {
      for (const field of ad.nativeFields) {
        switch (field.name) {
          case 'SPONSORED_BY':
            // tslint:disable-next-line:max-line-length
            (((this.newForm.controls.nativeFields as FormArray).controls['3'] as FormGroup).controls['value'] as FormControl).setValue(field.value);
            break;
          case 'DESCRIPTION':
            // tslint:disable-next-line:max-line-length
            (((this.newForm.controls.nativeFields as FormArray).controls['0'] as FormGroup).controls['value'] as FormControl).setValue(field.value);
            break;
          case 'TITLE':
            // tslint:disable-next-line:max-line-length
            (((this.newForm.controls.nativeFields as FormArray).controls['1'] as FormGroup).controls['value'] as FormControl).setValue(field.value);
            break;
          case 'MAIN':
            // tslint:disable-next-line:max-line-length
            (((this.newForm.controls.nativeFields as FormArray).controls['2'] as FormGroup).controls['imageUrl'] as FormControl).setValue(field.imageUrl);
            this.img.src = field.imageUrl;
            document.getElementById('main_image').appendChild(this.img);
            break;
          case 'ICON':
            // tslint:disable-next-line:max-line-length
            (((this.newForm.controls.nativeFields as FormArray).controls['4'] as FormGroup).controls['imageUrl'] as FormControl).setValue(field.imageUrl);
            const logo = new Image();
            logo.src = field.imageUrl;
            document.getElementById('logoNative').appendChild(logo);
            break;
        }
      }
    }
    this.adService.getCustomerVastEvents(ad.creativeId).subscribe(
      response => {
        if (response.response !== null && typeof response.response !== 'undefined' && response.response.length > 0) {
          const toSave = [];
          for (const event of response.response) {
            for (const url of event.urls) {
              toSave.push({url: url, type: event.type});
            }
          }
          this.gridTrackingUrls.updateRowData({add: toSave});
        }
      }, error => {}
    );
    if (typeof this.vendorsApiGrid !== 'undefined' && typeof ad.vendors !== 'undefined' && ad.vendors.length > 0) {
      this.vendorsApiGrid.forEachNode((rowNode, index) => {
        if (ad.vendors.findIndex((item) => item.vendorId === rowNode.data.vendorId) !== -1) {
          rowNode.selectThisNode(true);
        }
      });
    }
    if (typeof ad.companionAdAttached !== 'undefined' && ad.companionAdAttached) {
      this.adService.getCompanionCreative(ad.creativeId).subscribe(
        response => {
          const adCompanion = (Array.isArray(response.response[0])) ? response.response[0][0] : response.response[0];
          if (typeof this.newForm.controls.companionCreativeId !== 'undefined' && typeof adCompanion.companionCreativeId !== 'undefined') {
            this.newForm.controls.companionCreativeId.setValue(adCompanion.companionCreativeId);
          }
          if (adCompanion.winNotificationUrl !== null) {
            if (typeof ad.winNotificationUrl === 'string') {
              (<FormArray>this.newForm.controls.winNotificationUrlCompanion).controls['0'].setValue(adCompanion.winNotificationUrl);
            } else {
            }
          }
          if (typeof adCompanion.clickUrl !== 'undefined' && adCompanion.clickUrl !== null && typeof this.newForm.controls.clickUrlCompanion !== 'undefined') {
            this.newForm.controls.clickUrlCompanion.setValue(adCompanion.clickUrl);
          }
          if (typeof adCompanion.assetUrl !== 'undefined' && adCompanion.assetUrl !== null && typeof this.newForm.controls.assetUrlCompanion !== 'undefined') {
            this.newForm.controls.assetUrlCompanion.setValue(adCompanion.assetUrl);
          }
          if (typeof adCompanion.impressionTracking !== 'undefined' && adCompanion.impressionTracking !== null) {
            this.scriptTrackingFieldCompanion = [];
            this.impTrackingFieldCompanion = [];
            (this.newForm.controls.scriptTrackingUrlCompanion as FormArray).clear();
            (this.newForm.controls.winNotificationUrlCompanion as FormArray).clear();
            for (const index in adCompanion.impressionTracking) {
              if (adCompanion.impressionTracking[index].type === 'PIXEL') {
                (this.newForm.controls.winNotificationUrlCompanion as FormArray).push(this.fb.control(adCompanion.impressionTracking[index].url));
                this.impTrackingFieldCompanion.push((<FormArray>this.newForm.controls.winNotificationUrlCompanion).controls.length);
              } else {
                (this.newForm.controls.scriptTrackingUrlCompanion as FormArray).push(this.fb.control(adCompanion.impressionTracking[index].url));
                this.scriptTrackingFieldCompanion.push((<FormArray>this.newForm.controls.scriptTrackingUrlCompanion).controls.length);
              }
            }
            if ((this.newForm.controls.winNotificationUrlCompanion as FormArray).length === 0) {
              (this.newForm.controls.winNotificationUrlCompanion as FormArray).push(this.fb.control(''));
            }
            if ((this.newForm.controls.scriptTrackingUrlCompanion as FormArray).length === 0) {
              (this.newForm.controls.scriptTrackingUrlCompanion as FormArray).push(this.fb.control(''));
            }
            console.log(this.newForm.controls.winNotificationUrlCompanion, this.impTrackingFieldCompanion);
          }
          this.fullyLoaded = true;
          this.setValuesCHangedEvent();
        }, error => {
          this.fullyLoaded = true;
          this.setValuesCHangedEvent();
        }
      );
    } else if (typeof this.newForm.controls.clickUrlCompanion !== 'undefined' && typeof this.newForm.controls.clickUrl !== 'undefined') {
      this.newForm.controls.clickUrlCompanion.setValue(ad.clickUrl);
    }
    if (typeof ad.companionAdAttached !== 'undefined' && !ad.companionAdAttached) {
      this.newForm.controls.assetUrlCompanion.clearValidators();
      this.newForm.controls.assetUrlCompanion.updateValueAndValidity();
      this.newForm.updateValueAndValidity();
      console.log('LOAD AD INSIDE: ', this.newForm, ad);
    }
  }

  public onSubmit(modalName = 'new-image-modal') {
    // console.log(this.newForm.value);
    if (this.brandId === null || typeof this.brandId === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
      return;
    }
    this.ngLoading = true;
    if (typeof this.newForm.controls.reviewAttributes !== 'undefined') {
      this.newForm.controls.reviewAttributes.setValue(this.selectedOptions);
    }
    this.newForm.controls.brandId.setValue(this.brandId);
    const newData = this.newForm.value;
    if (typeof this.newForm.controls.impressionTracking !== 'undefined' && this.newForm.controls.impressionTracking !== null) {
      // this.newForm.controls.impressionTracking.setValue(this.impressionTrackingUrls());
      newData.impressionTracking = this.impressionTrackingUrls();
    }
    if (typeof this.newForm.controls.impressionTrackingCompanion !== 'undefined' && this.newForm.controls.impressionTrackingCompanion !== null) {
      // this.newForm.controls.impressionTrackingCompanion.setValue(this.impressionTrackingUrlsCompanion());
      newData.impressionTrackingCompanion = this.impressionTrackingUrlsCompanion();
    }
    if (typeof this.newForm.controls.customerVastEvents !== 'undefined' && this.newForm.controls.customerVastEvents !== null) {
      // this.newForm.controls.customerVastEvents.setValue(this.getGridTrackingUrls());
      newData.customerVastEvents = this.getGridTrackingUrls();
    }
    if (typeof this.vendorsApiGrid !== 'undefined' && this.vendorsApiGrid !== null &&
      typeof this.newForm.controls.vendors !== 'undefined' && this.newForm.controls.vendors !== null) {
      // this.newForm.controls.vendors.setValue(this.vendorsApiGrid.getSelectedRows().map((value) => value.vendorId));
      newData.vendors = this.vendorsApiGrid.getSelectedRows().map((value) => value.vendorId);
    }
    if (this.createOrEdit === 'Create') {
      console.log('DATA TO SAVE: ', this.newForm.controls);
      this.adService.createAd(newData as Ad)
        .subscribe(response => {
          console.log('newAdSuccess', response);
          this.ngLoading = false;
          this.resetModal(modalName);
          this.refreshGrid.emit('ad');
            this.valuesAdChanged = false;
            this.fullyLoaded = true;
          // if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Ad created successfully.');
          // }
        },
          (error) => {
            console.log(error, 'saveAd');
            this.alertService.alert('error', error.message);
            this.ngLoading = false;
          });
    } else {
      this.adService.updateAd(newData as Ad, this.newForm.get('creativeId').value)
        .subscribe(response => {
          this.ngLoading = false;
          this.resetModal(modalName);
          this.refreshGrid.emit('ad');
            this.valuesAdChanged = false;
            this.fullyLoaded = true;
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Ad updated successfully.');
          }
        },
          (error) => {
            console.log(error, 'updateAd');
            this.alertService.alert('error', error.message);
            this.ngLoading = false;
          });
    }
  }

  public resetModal(modelName = '') {
    if (this.valuesAdChanged) {
      if (!confirm('Your changes haven’t been saved! Do you want to continue?')) {
        return;
      }
    }
    this.impTrackingField = [1];
    this.impTrackingFieldCompanion = [1];
    this.scriptTrackingField = [1];
    if (typeof this.newForm.controls.winNotificationUrlCompanion !== 'undefined') {
      (this.newForm.controls.winNotificationUrlCompanion as FormArray).clear();
      (this.newForm.controls.winNotificationUrlCompanion as FormArray).push(this.fb.control(''));
    }
    if (typeof this.newForm.controls.scriptTrackingUrlCompanion !== 'undefined') {
      (this.newForm.controls.scriptTrackingUrlCompanion as FormArray).clear();
      (this.newForm.controls.scriptTrackingUrlCompanion as FormArray).push(this.fb.control(''));
    }
    this.pondFilesImage = [];
    this.pondFilesAudio = [];
    this.pondFilesVideo = [];
    this.pondFilesZip = [];
    this.newForm.reset();
    this.createOrEdit = 'Create';
    this.selectedOptions = [];
    if (document.getElementById('adPanel').hasChildNodes()) {
      document.getElementById('adPanel').removeChild(this.img);
    }
    if (typeof this.myPond !== 'undefined') {
      this.myPond.removeFiles();
    }
    if (typeof this.myPondAudio !== 'undefined') {
      this.myPondAudio.removeFiles();
    }
    if (typeof this.myPondImage !== 'undefined') {
      this.myPondImage.removeFiles();
    }
    if (typeof this.myPondZip !== 'undefined') {
      this.myPondZip.removeFiles();
    }
    this.ngOnInit();
    this.valuesAdChanged = false;
    this.fullyLoaded = true;
    $('.' + modelName).modal('hide');
  }

  public trackingUrlsGridInit(params) {
    this.gridTrackingUrls = params.api;
  }

  public addGridTrackingUrl() {
    this.gridTrackingUrls.updateRowData({ add: [{url: '', type: ''}] });
  }

  public getVendors() {
    this.adService.getVendors().subscribe(
      (response) => {
        this.rowDataVendors = Observable.create((obs) => {
          obs.next(response.response);
          obs.complete();
        });
      },
      (error) => {console.log(error, 'getVendors')}
    );
  }

}
