import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClientsResponse, UserResponse, UsersResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {User} from '../../_models/user';
import { Client } from 'app/_models/client';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientsUrl = 'clients';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getClients(max: number = 20, offset: number = 0, searchVal = '', from = '', to = '', dateFilter = ''): Observable<ClientsResponse> {
    return this.http.get<ClientsResponse>(
      // tslint:disable-next-line:max-line-length
      `${environment.apiUrl}` + this.clientsUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal + '&from=' + from + '&to=' + to + '&dateFilter=' + dateFilter
    ).pipe(
      tap(_ => console.log('fetched users')),
      catchError(Errors.handleError<ClientsResponse>('getUsers'))
    );
  }

  // tslint:disable-next-line:max-line-length
  getClientGroupsStats(clientId, max: number = 20, offset: number = 0, searchVal = '', from = '20201006', to = moment().format('YYYYMMDD'), dateFilter = ''): Observable<ClientsResponse> {
    return this.http.get<ClientsResponse>(
      // tslint:disable-next-line:max-line-length
      `${environment.apiUrl}` + this.clientsUrl + '/' + clientId + '/groups/stats/?max=' + max + '&offset=' + offset + '&query=' + searchVal + '&from=' + from + '&to=' + to + '&dateFilter=' + dateFilter
    ).pipe(
      tap(_ => console.log('fetched users')),
      catchError(Errors.handleError<ClientsResponse>('getUsers'))
    );
  }

  getTrans(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}` + this.clientsUrl + '/trans'
    ).pipe(
      tap(_ => console.log('fetched trans')),
      catchError(Errors.handleError<any>('getTrans'))
    );
  }

  getClientById(id: any) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + this.clientsUrl + '/' + id).pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getUserById'))
    );
  }

  createClient(client: Client) {
    return this.http.post<Client>(`${environment.apiUrl}` + this.clientsUrl, client, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addClient', client))
      );
  }

  updateClient(client: Client, id: number) {
    return this.http.put<Client>(`${environment.apiUrl}` + this.clientsUrl + '/' + id, client, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateClient', client))
      );
  }

  deleteClient(id: string) {
    return this.http.delete(`${environment.apiUrl}` + this.clientsUrl + '/' + id).pipe(
      tap(_ => console.log('deleted business')),
      catchError(Errors.handleError('deleteClient'))
    );
  }
}
