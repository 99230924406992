import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Campaign} from '../../_models/campaign';
import {RangesDatepickerComponent} from '../ranges-datepicker/ranges-datepicker.component';
import {Observable, Observer} from 'rxjs';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {CsvExportParams} from 'ag-grid-community';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import * as moment from 'moment';
import {CampaignsComponent} from '../../pages/campaigns/campaigns.component';

@Component({
  selector: 'app-campaign-conversions-stats',
  templateUrl: './campaign-conversions-stats.component.html',
  styleUrls: ['./campaign-conversions-stats.component.scss']
})
export class CampaignConversionsStatsComponent implements OnInit {
  @Input() campaignData: Campaign;
  @Output() refreshDates = new EventEmitter<any>();
  rangesFooter = RangesDatepickerComponent;
  defaultBrandsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' }
  };
  columnDefsConv = [
    // {
    //   headerName: '',
    //   colId: 'checkboxSelect',
    //   width: 40,
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   headerCheckboxSelectionFilteredOnly: true,
    //   sortable: false,
    //   resizable: false,
    //   filter: false,
    //   cellRenderer: '',
    //   cellRendererParams: null,
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    // {
    //   headerName: 'Actions',
    //   field: 'creativeId',
    //   colId: 'actions',
    //   sortable: true,
    //   filter: true,
    //   width: 90,
    //   cellRenderer: 'actionsCellRendererComponent',
    //   cellRendererParams: { renderType: 'Campaign' },
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    {headerName: 'Name', sort: 'asc', field: 'name', width: 250, pinnedRowCellRenderer: '', cellRenderer: '' },
    {headerName: 'CTC', field: 'clickThroughConversions', width: 100 },
    {headerName: 'VTC', field: 'viewThroughConversions', width: 100 },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 100 },
    {headerName: 'CTC CPA', field: 'clickEffectiveCPA', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPA', field: 'costPerAcquisition', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total Revenue', field: 'revenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'ROAS', field: 'returnOnAdSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Media CPM', field: 'mediaEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Data CPM', field: 'dataEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total CPM', field: 'totalEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
  ];
  rowDataConv: Observable<Array<any>>;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent
  };
  convGrid: any = null;
  convCampaingDateRange: any;
  statusSelected = '';
  convFilterString = '';
  ngLoading = false;
  bulkStatusEdit = '';
  cpmBidBulkEdit = '';
  rangeDatesFlag = false;
  filtersActive = false;
  pinnedRowData: any;
  private currentDateScope = 'one';

  constructor(private campaignService: CampaignService, private modalService: NgbModal, private gridRender: GridRenderService) {
    this.initializeRangeDates();
  }

  ngOnInit() {
    this.rowDataConv = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
      ob.next([]);
      ob.complete();
    });
  }

  getCampaignConversionsStats(campaignId) {
    this.ngLoading = true;
    this.campaignService.getCampaignConversionsStats(campaignId,
      // @ts-ignore
      {
        filter: this.convFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.convCampaingDateRange !== null && typeof this.convCampaingDateRange !== 'undefined') ? this.convCampaingDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.convCampaingDateRange !== null && typeof this.convCampaingDateRange !== 'undefined') ? this.convCampaingDateRange.begin.format('YYYYMMDD') : '',
        status: this.statusSelected
      }
    ).subscribe(
      (response) => {
        console.log(response);
        const pinnedData = {
          clickThroughConversions: 0,
          viewThroughConversions: 0,
          totalConversions: 0,
          clickEffectiveCPA: 0,
          costPerAcquisition: 0,
          revenue: 0,
          returnOnAdSpend: 0,
          mediaEffectiveCPM: 0,
          dataEffectiveCPM: 0,
          totalEffectiveCPM: 0,
        };
        if (typeof response !== 'undefined' && response !== null) {
          for (const i in response) {
            // const margin = (typeof response[i]['extraData'] !== 'undefined' && response[i]['extraData'].margin > 1) ?
            //   ((100 - response[i]['extraData'].margin) / 100) : 1;
            const margin = 1;
            response[i].clickEffectiveCPA = isNaN(response[i].clickEffectiveCPA) ? 0 : response[i].clickEffectiveCPA / margin;
            response[i].costPerAcquisition = isNaN(response[i].costPerAcquisition) ? 0 : response[i].costPerAcquisition / margin;
            response[i].revenue = isNaN(response[i].revenue) ? 0 : response[i].revenue / margin;
            response[i].returnOnAdSpend = isNaN(response[i].returnOnAdSpend) ? 0 : response[i].returnOnAdSpend / margin;
            response[i].mediaEffectiveCPM = isNaN(response[i].mediaEffectiveCPM) ? 0 : response[i].mediaEffectiveCPM / margin;
            response[i].dataEffectiveCPM = isNaN(response[i].dataEffectiveCPM) ? 0 : response[i].dataEffectiveCPM / margin;
            response[i].totalEffectiveCPM = isNaN(response[i].totalEffectiveCPM) ? 0 : response[i].totalEffectiveCPM / margin;

            pinnedData.clickThroughConversions += isNaN(response[i].clickThroughConversions) ? 0 : response[i].clickThroughConversions;
            pinnedData.viewThroughConversions += isNaN(response[i].viewThroughConversions) ? 0 : response[i].viewThroughConversions;
            pinnedData.totalConversions += isNaN(response[i].totalConversions) ? 0 : response[i].totalConversions;
            pinnedData.clickEffectiveCPA += isNaN(response[i].clickEffectiveCPA) ? 0 : response[i].clickEffectiveCPA / margin;
            pinnedData.costPerAcquisition += isNaN(response[i].costPerAcquisition) ? 0 : response[i].costPerAcquisition / margin;
            pinnedData.revenue += isNaN(response[i].revenue) ? 0 : response[i].revenue / margin;
            pinnedData.returnOnAdSpend += isNaN(response[i].returnOnAdSpend) ? 0 : response[i].returnOnAdSpend / margin;
            pinnedData.mediaEffectiveCPM += isNaN(response[i].mediaEffectiveCPM) ? 0 : response[i].mediaEffectiveCPM / margin;
            pinnedData.dataEffectiveCPM += isNaN(response[i].dataEffectiveCPM) ? 0 : response[i].dataEffectiveCPM / margin;
            pinnedData.totalEffectiveCPM += isNaN(response[i].totalEffectiveCPM) ? 0 : response[i].totalEffectiveCPM / margin;
          }
        }
        this.pinnedRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
        this.rowDataConv = new Observable<Array<any>>((ob: Observer<Array<any>>) => {
          // @ts-ignore
          ob.next(response);
          ob.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error, 'getCampaignConversionsStats'); this.ngLoading = false; }
    )
  }

  cellClickedHanlder(node) {
    console.log(node);
    if (node.column.colId === 'impliedStatus') {
      if (node.event.target.firstElementChild !== null
        && typeof node.event.target.firstElementChild.value !== 'undefined'
        && node.event.target.firstElementChild.value === 'on') {
        console.log(node.event.target.firstElementChild.value);
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [{identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'online', type: node.data.type}],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignConversionsStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      } else {
        console.log(node.event.target);
        this.campaignService.setInventoryUnitTargets(
          // tslint:disable-next-line:max-line-length
          [{identifier: node.data.identifier, name: node.data.name, locked: node.data.locked, ruleStatus: 'offline', type: node.data.type}],
          [this.campaignData.campaignId]).subscribe(
          (response) => {
            this.getCampaignConversionsStats(this.campaignData.campaignId);
          },
          (error) => { console.log(error, 'setInventoryUnitTargets'); }
        );
      }
    }
  }

  onAdsGridReady(params: any) {
    this.convGrid = params.api;
  }

  refreshGrid() {
    this.currentDateScope = 'one';
    this.getCampaignConversionsStats(this.campaignData.campaignId);
  }

  exportDomains() {
    this.convGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params, 'ads');
      }
    } as CsvExportParams);
  }

  rangeChanged(event: SatDatepickerInputEvent<any>, input: HTMLInputElement = null) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      this.convCampaingDateRange.begin = this.convCampaingDateRange.begin.subtract(1, 'days');
      this.convCampaingDateRange.end = this.convCampaingDateRange.end.subtract(1, 'days');
      input.value = this.convCampaingDateRange.begin.format('MM/DD/YYYY') + ' - ' + this.convCampaingDateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      this.currentDateScope = 'one';
      if (event['value']['extraData'] === 'fromButtonAll') {
        this.currentDateScope = 'all';
        this.refreshDates.emit({'event':  event, 'input': input});
      } else if (savedGridDates !== null && typeof savedGridDates === 'object') {
        savedGridDates = {
          ...savedGridDates,
          convCampaingDateRange: this.convCampaingDateRange
        };
      } else {
        savedGridDates = {
          convCampaingDateRange: this.convCampaingDateRange
        };
      }
      // savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
      //   ? {
      //     ...savedGridDates,
      //     convCampaingDateRange: this.convCampaingDateRange
      //   }
      //   : {
      //     convCampaingDateRange: this.convCampaingDateRange
      //   };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
  }

  closedDatesRange() {
    if (this.rangeDatesFlag && this.currentDateScope === 'one') {
      this.refreshGrid();
    } else {
      this.initializeRangeDates();
    }
    this.rangeDatesFlag = false;
  }

  initializeRangeDates() {
    const savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
    if (savedGridDates !== null && typeof savedGridDates === 'object') {
      if (typeof savedGridDates.convCampaingDateRange !== 'undefined') {
        this.convCampaingDateRange = {begin: moment(savedGridDates.convCampaingDateRange.begin), end: moment(savedGridDates.convCampaingDateRange.end)};
      }
    }
  }

  filterChanged(event: any) {
    this.filtersActive = Object.keys(event.api.getFilterModel()).length > 0;
  }
}
