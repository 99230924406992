import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
import {Observable} from 'rxjs';
import {AdService} from '../../_services/ad/ad.service';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-multiple-edit-ad-modal',
  templateUrl: './multiple-edit-ad-modal.component.html',
  styleUrls: ['./multiple-edit-ad-modal.component.scss']
})
export class MultipleEditAdModalComponent implements OnInit {
  columnDefsNames = [
    {headerName: 'ID', field: 'c', width: 100, editable: false },
    {headerName: 'Name', field: 'label', width: 400, editable: true },
  ];
  rowDataNames: any;
  selectedAds = [];
  defaultColDef = {
    editable: true,
    resizable: true
  };
  preffix = '';
  suffix = '';
  find = '';
  replace = '';
  gridApi;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(private adService: AdService, private alert: AlertService) { }

  ngOnInit() {
    this.rowDataNames = Observable.create((obs) => {
      obs.next(this.selectedAds);
      obs.complete();
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();
  }

  refreshGridData() {
    this.rowDataNames = Observable.create((obs) => {
      obs.next(this.selectedAds);
      obs.complete();
    });
  }

  addPreffix() {
    const toUpdate = [];
    const preffix = this.preffix;
    this.gridApi.forEachNode(function(node) {
      node.data.label = preffix + node.data.label;
      console.log(node.data.label);
      toUpdate.push(node.data);
    });
    console.log(toUpdate);
    this.gridApi.updateRowData({ update: toUpdate });
  }

  addSuffix() {
    const toUpdate = [];
    const suffix = this.suffix;
    this.gridApi.forEachNode(function(node) {
      node.data.label = node.data.label + suffix;
      toUpdate.push(node.data);
    });
    console.log(toUpdate)
    this.gridApi.updateRowData({ update: toUpdate });
  }

  finReplace() {
    const toUpdate = [];
    const find = this.find;
    const replace = this.replace;
    const reg = new RegExp(find, 'g');
    this.gridApi.forEachNode(function(node) {
      node.data.label = node.data.label.replace(reg, replace);
      toUpdate.push(node.data);
    });
    console.log(toUpdate)
    this.gridApi.updateRowData({ update: toUpdate });
  }

  saveChanges() {
    const params = [];
    this.gridApi.forEachNode(function(node) {
      params.push({id: node.data.creativeId, field: 'label', newValue: node.data.label});
    });
    this.adService.severalAdsEdit(params).subscribe(
      (response) => {
        this.ngOnInit();
        this.refreshGrid.emit('ad');
        this.alert.alert('success', 'Changes saved successfully.')
        $('.new-multiple-edit-ad-modal').modal('hide');
      },
      (error) => {console.log(error, 'saveChanges')}
    )
  }
}
