import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientService} from '../../_services/client/client.service';
import {Observable, Observer} from 'rxjs';
import { Client } from 'app/_models/client';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {UserService} from '../../_services/user/user.service';
import {User} from '../../_models/user';
import {CampaignNameBulkComponent} from '../../shared/campaign-name-bulk/campaign-name-bulk.component';
import {NewClientComponent} from '../../modals/new-client/new-client.component';
import {NewUserComponent} from '../../modals/new-user/new-user.component';
import {FormArray, FormControl} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import {NewUserBasicComponent} from '../../modals/new-user-basic/new-user-basic.component';
import {NewSubscriptionModalComponent} from '../../modals/new-subscription-modal/new-subscription-modal.component';
import {SubscriptionService} from '../../_services/subscription/subscription.service';
import {AddBalanceModalComponent} from '../../modals/add-balance-modal/add-balance-modal.component';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {GroupService} from '../../_services/group/group.service';
declare var CollectJS;
declare var validCVV;
declare var validCard;
declare var validExp;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  columnDefsClients = [
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: {renderType: 'Client', delete: true},
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', sortable: true, filter: true},
    {headerName: 'Email', field: 'email', sortable: true, filter: true},
    {headerName: 'Phone', field: 'phone', sortable: true, filter: true},
    {headerName: 'Address', field: 'address', sortable: true, filter: true}
  ];
  ngLoading = false;
  rowDataClients: any;
  rowDataUsers: any;
  rowDataTransacts: any;
  rowDataMethods: any;
  columnDefsUsers = [
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: {renderType: 'User', delete: true},
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'First name', field: 'firstname', sortable: true, filter: true},
    {headerName: 'Last Name', field: 'lastname', sortable: true, filter: true},
    {headerName: 'Email', field: 'email', sortable: true, filter: true},
    {headerName: 'Role', field: 'role', sortable: true, filter: true}
  ];
  currentUser = JSON.parse(localStorage.getItem('user'));


  columnDefsTransacts = [
    // {
    //   headerName: 'Actions',
    //   field: 'actions',
    //   sortable: false,
    //   filter: false,
    //   width: 90,
    //   cellRenderer: 'actionsCellRendererComponent',
    //   cellRendererParams: {renderType: 'User', delete: true},
    //   pinnedRowCellRenderer: '',
    //   pinnedRowCellRendererParams: null
    // },
    {headerName: 'ID', field: 'id', sortable: true, filter: true, width: 50},
    {headerName: 'Trans. ID', field: 'trans_id', sortable: true, filter: true, width: 120},
    {headerName: 'Amount', field: 'amount', sortable: true, filter: true, width: 50},
    {headerName: 'Description', field: 'description', sortable: true, filter: true, width: 120},
    {headerName: 'Status', field: 'status', sortable: true, filter: true, width: 80},
    {headerName: 'User', field: 'user', sortable: true, filter: true, width: 80},
    {headerName: 'Date', field: 'created_at', sortable: true, filter: true, width: 100},
  ];

  columnDefsMethods = [
    {headerName: 'ID', field: 'id', sortable: true, filter: true, width: 50},
    {headerName: 'Alias', field: 'name', sortable: true, filter: true, width: 120},
    {headerName: 'Name', field: 'first_name', sortable: true, filter: true, width: 120},
    {headerName: 'Account', field: 'account', sortable: true, filter: true, width: 120},
    {headerName: 'Default', field: 'default', sortable: true, filter: true, cellRenderer: (params) => params.value ? 'Default' : '', width: 80},
    {headerName: 'Status', field: 'status', sortable: true, filter: true, width: 80},
    {headerName: 'Created At', field: 'created_at', sortable: true, filter: true, width: 100},
  ];

  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
  };
  currentClient: any = null;
  @ViewChild(NewClientComponent, null) newClientModal: NewClientComponent;
  @ViewChild(NewUserBasicComponent, null) newUserModal: NewUserBasicComponent;
  @ViewChild(NewSubscriptionModalComponent, null) newSubsModal: NewSubscriptionModalComponent;
  @ViewChild(AddBalanceModalComponent, null) balanceModal: AddBalanceModalComponent;
  compParent = {componentParent: this};
  clientsGrid: any;
  usersGrid: any;
  transactsGrid: any;
  methodsGrid: any;
  methodSelected = -1;
  currentSpends = '$0.00';

  constructor(private clientService: ClientService,
              private userService: UserService,
              private alert: AlertService,
              private breadcrumbsService: BreadcrumbsService,
              private groupsService: GroupService,
              private subsService: SubscriptionService) {
  }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'My Partners', url: '/myagencies', params: []}
    ];
    this.getUsers();
    this.getTransacts();
    this.getMethods();
    this.rowDataUsers = new Observable((ob: Observer<Array<User>>) => {
      ob.next([]);
      ob.complete();
    });
    this.clientService.getClientGroupsStats(this.currentUser['clients'][0]['id']).subscribe(
      response => {
        if (response['groups'].length > 0) {
          const margin = (typeof response['groups'][0]['extraData'] !== 'undefined' && response['groups'][0]['extraData'].margin > 1) ?
            ((100 - response['groups'][0]['extraData'].margin) / 100) : 1;
          let spend = 0;
          for (const group of response['groups']) {
            spend += typeof group.totalSpend !== 'undefined' ? Number(group.totalSpend) : 0;
          }
          this.currentSpends = '$' + (spend / margin).toFixed(2);
        }
      }
    )
  }

  selectPaymentMethod(event) {
    this.methodSelected = event.data.id;
  }

  getMethods() {
    this.ngLoading = true;
    this.subsService.getPaymentMethods().subscribe(
      (response) => {
        this.rowDataMethods = new Observable((ob: Observer<Array<any>>) => {
          ob.next(response.methods);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  deleteMethod() {
    console.log(this.methodSelected);
    if (this.methodSelected > -1) {
      this.ngLoading = true;
      this.subsService.removeMethod(this.methodSelected).subscribe(
        (response) => {
          this.alert.alert('success', response.message);
          this.ngLoading = false;
          this.getMethods();
          this.methodSelected = -1;
        },
        (error) => {
          console.log(error);
          if (typeof error.error !== 'undefined') {
            this.alert.alert('error', error.error);
          }
          this.ngLoading = false;
        }
      )
    } else {
      this.alert.alert('error', 'Please select the payment method.');
    }
  }

  defaultMethod() {
    if (this.methodSelected > -1) {
      this.ngLoading = true;
      this.subsService.makeDefaultMethod(this.methodSelected).subscribe(
        (response) => {
          this.alert.alert('success', response.message);
          this.ngLoading = false;
          this.getMethods();
        },
        (error) => {
          console.log(error);
          if (typeof error.error !== 'undefined') {
            this.alert.alert('error', error.error);
          }
          this.ngLoading = false;
        }
      )
    } else {
      this.alert.alert('error', 'Please select the payment method.');
    }
  }

  getClients() {
    this.ngLoading = true;
    this.clientService.getClients().subscribe(
      (response) => {
        this.rowDataClients = new Observable((ob: Observer<Array<Client>>) => {
          ob.next(response.response.clients);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  getUsers() {
    this.ngLoading = true;
    this.userService.getAgencyUsers().subscribe(
      (response) => {
        this.rowDataUsers = new Observable((ob: Observer<Array<User>>) => {
          ob.next(response.response.users);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  getTransacts() {
    this.ngLoading = true;
    this.clientService.getTrans().subscribe(
      (response) => {
        this.rowDataTransacts = new Observable((ob: Observer<Array<any>>) => {
          ob.next(response.transactions);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  addUserModal() {
    // this.newUserModal.clientIds = [String(this.currentClient.id)];
    // this.newUserModal.getClients();
    $('.new-user-basic-modal').modal('show');
  }

  cancelSubscription() {
    this.ngLoading = true;
    this.subsService.cancelSubscription().subscribe(
      (response) => {
        console.log(response);
        localStorage.setItem('user', response.user);
        localStorage.setItem('user2', response.user);
        location.reload();
        this.ngLoading = false;
      },
      error => {
        console.log(error);
        this.alert.alert('error', error.error);
        this.ngLoading = false;
      }
    )
  }

  addBalance() {
    this.balanceModal.formControls.payment_token.setValue('');
    $('.new-add-balance-modal').modal('show');
    CollectJS.configure({
      'validationCallback' : function(field, status, message) {
        let message1 = '';
        if (status) {
          if (field === 'cvv')
            validCVV = true;
          if (field === 'ccexp')
            validExp = true;
          if (field === 'ccnumber')
            validCard = true;
          message1 = field + ' is now OK: ' + message;
        } else {
          if (field === 'cvv')
            validCVV = false;
          if (field === 'ccexp')
            validExp = false;
          if (field === 'ccnumber')
            validCard = false;
          message1 = field + ' is now Invalid: ' + message;
        }
        console.log(message1);
      },
      'timeoutDuration' : 5000,
      'timeoutCallback' : function () {
        alert('The tokenization didn\'t respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity');
      },
      'fieldsAvailableCallback' : function () {
        console.log('Collect.js loaded the fields onto the form');
      },
      'callback' : function(response) {
        document.getElementById('payment_token_2').setAttribute('value', response.token);
        document.getElementById('payButton4').click();
      },
      variant: 'inline',
      googleFont: 'Abel',
      invalidCss: {
        color: '#B40E3E'
      },
      validCss: {
        color: '#14855F'
      },
      customCss: {
        'border-color': '#FFFFFF',
        'border-style': 'solid'
      },
      focusCss: {
        'border-color': '#1CC48B',
        'border-style': 'solid',
        'border-width': '3px'
      },
      fields: {
        cvv: {
          placeholder: 'CVV'
        },
        ccnumber: {
          placeholder: 'Credit Card'
        },
        ccexp: {
          placeholder: 'MM / YY'
        }
      }
    });
  }

  showSubsModal() {
    this.newSubsModal.formControls.payment_token.setValue('');
    $('.new-subscription-modal').modal('show');
    CollectJS.configure({
      'validationCallback' : function(field, status, message) {
        let message1 = '';
        if (status) {
          if (field === 'cvv')
            validCVV = true;
          if (field === 'ccexp')
            validExp = true;
          if (field === 'ccnumber')
            validCard = true;
          message1 = field + ' is now OK: ' + message;
        } else {
          if (field === 'cvv')
            validCVV = false;
          if (field === 'ccexp')
            validExp = false;
          if (field === 'ccnumber')
            validCard = false;
          message1 = field + ' is now Invalid: ' + message;
        }
        console.log(message1);
      },
      'timeoutDuration' : 5000,
      'timeoutCallback' : function () {
        alert('The tokenization didn\'t respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity');
      },
      'fieldsAvailableCallback' : function () {
        console.log('Collect.js loaded the fields onto the form');
      },
      'callback' : function(response) {
        document.getElementById('payment_token').setAttribute('value', response.token);
        const amount = document.getElementById('amount').getAttribute('value');
        if (amount === '100') {
          document.getElementById('payButton').click();
        } else {
          document.getElementById('payButton1').click();
        }
      },
      variant: 'inline',
      googleFont: 'Abel',
      invalidCss: {
        color: '#B40E3E'
      },
      validCss: {
        color: '#14855F'
      },
      customCss: {
        'border-color': '#FFFFFF',
        'border-style': 'solid'
      },
      focusCss: {
        'border-color': '#1CC48B',
        'border-style': 'solid',
        'border-width': '3px'
      },
      fields: {
        cvv: {
          placeholder: 'CVV'
        },
        ccnumber: {
          placeholder: 'Credit Card'
        },
        ccexp: {
          placeholder: 'MM / YY'
        }
      }
    });
  }

  rowClientSelected(event: any) {
    this.currentClient = event.data;
    this.getUsers();
  }

  editClientModal(node: any) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.newClientModal.loadClient(node.data);
      $('.new-client-modal').modal('show');
    }
  }

  rowUserSelected(event: any) {

  }

  editUserModal(node: any) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      // this.newUserModal.getClients();
      this.newUserModal.loadUser(node.data);
      $('.new-user-basic-modal').modal('show');
    }
  }

  addClientModal() {
    $('.new-client-modal').modal('show');
  }

  remove(id, type = 'Client') {
    if (type === 'User') {
      this.ngLoading = true;
      this.userService.deleteUser(id).subscribe(
        (response) => {
          this.alert.alert('success', 'User deleted successfully.');
          this.ngLoading = false;
        },
        (error) => {
          console.log(error, 'deleteUser');
          this.ngLoading = false;
        }
      )
    } else {
      this.ngLoading = true;
      this.clientService.deleteClient(id).subscribe(
        (response) => {
          this.alert.alert('success', 'Client deleted successfully.');
          this.ngLoading = false;
        },
        (error) => {
          console.log(error, 'deleteUser');
          this.ngLoading = false;
        }
      )
    }
  }

  exportClients() {
    this.clientsGrid.exportDataAsCsv();
  }

  clientsGridReady(event: any) {
    this.clientsGrid = event.api;
  }

  usersGridReady(event: any) {
    this.usersGrid = event.api;
  }

  transactsGridReady(event: any) {
    this.transactsGrid = event.api;
  }

  methodsGridReady(event: any) {
    this.methodsGrid = event.api;
  }

  exportUsers() {
    this.usersGrid.exportDataAsCsv();
  }

  exportTransacts() {
    this.transactsGrid.exportDataAsCsv();
  }
}
