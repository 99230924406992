import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CampaignGroupResponse, DomainListResponse, DomainResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {CampaignGroup} from '../../_models/campaignGroup';
import {Domains} from '../../_models/domains';

@Injectable({
  providedIn: 'root'
})
export class DomainListService {

  private domainsUrl = 'domains';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getDomainList(params = {name: ''}): Observable<DomainListResponse> {
    return this.http.get<DomainListResponse>(
      `${environment.apiUrl}` + this.domainsUrl + '?name=' + params.name
    ).pipe(
      tap(_ => console.log('fetched domains list')),
      catchError(Errors.handleError<DomainListResponse>('getDomainLIst'))
    );
  }

  createDomainList(domainList: Domains): Observable<DomainResponse> {
    return this.http.post<DomainResponse>(`${environment.apiUrl}` + this.domainsUrl + '/store', domainList, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createDomainList', domainList))
      ) as Observable<DomainResponse>;
  }

  updateDomainList(params = {domainList: null, domainId: ''}): Observable<DomainResponse> {
    return this.http.post<DomainResponse>(`${environment.apiUrl}` + this.domainsUrl + '/update', params, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createDomainList', params))
      ) as Observable<DomainResponse>;
  }

  addDomainsToList(params = {domains: [], listId: ''}): Observable<DomainResponse> {
    return this.http.post<DomainResponse>(`${environment.apiUrl}` + this.domainsUrl + '/adddomains', params, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createDomainList', params))
      ) as Observable<DomainResponse>;
  }

  uploadFile(fieldName, file, metadata, load, error, progress, abort) {
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}` + 'upload/domains-file', data, this.httpOptions)
      .pipe(
        tap(_ => console.log('fetched Domains File')),
        catchError(Errors.handleError('uploadFile', file))
      );
  }

  getFile(source) {
    const httpOptionsCustom = {
      responseType: 'blob'
    };
    const data = new FormData();
    data.append('url', source);
    // @ts-ignore
    return this.http.post(`${environment.apiUrl}` + 'get/domains-file', data, httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched Domains File')),
        catchError(Errors.handleError('domainsFile', source))
      );
  }

}
