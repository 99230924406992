import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {LinkAdCampaignComponent} from '../../shared/cell-renderers/link-ad-campaign/link-ad-campaign.component';
import {GroupService} from '../../_services/group/group.service';
import {AdService} from '../../_services/ad/ad.service';
import {AlertService} from '../../_services/alert.service';
import {Ad} from '../../_models/ad';

@Component({
  selector: 'app-link-ads-campaign',
  templateUrl: './link-ads-campaign.component.html',
  styleUrls: ['./link-ads-campaign.component.scss']
})
export class LinkAdsCampaignComponent implements OnInit {
  gridAds;
  gridAdsLinked;
  brandId: number;
  selectedAds = [];
  columnDefsLinkedAds = [
    {headerName: 'Ad Name', field: 'label', width: 300 },
    {headerName: 'ID', field: 'creativeId', width: 120 },
    {headerName: '', field: 'action', width: 80, cellRenderer: 'linkAdCampaignComponent', cellRendererParams: { linked: true }, filter: false }
  ];
  @Input() rowDataLinkedAds: Observable<Array<{}>>;
  columnDefsAds = [
    // {field: 'campaignGroupId', rowGroup: true, hide: true},
    {headerName: 'Ad Name', field: 'label', width: 300 },
    {headerName: 'ID', field: 'creativeId', width: 120 },
    {headerName: '', field: 'action', width: 80, cellRenderer: 'linkAdCampaignComponent', cellRendererParams: { linked: false }, filter: false }
  ];
  rowDataAds: Observable<Array<{}>>;
  // autoGroupColumnDef = {headerName: 'Name', field: 'campaignName', width: 280};
  defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
  };
  frameworks = {
    linkAdCampaignComponent: LinkAdCampaignComponent
  };
  ngLoading = false;
  @Output() adsToLink = new EventEmitter<Ad[]>();

  constructor(private groupService: GroupService, private adService: AdService, private alertService: AlertService) { }

  ngOnInit() {
  }

  setLinkedAds(linkedAds: Array<any>) {
    this.rowDataLinkedAds = Observable.create((obs) => {
      obs.next(linkedAds);
      obs.complete();
    });
  }

  getAds() {
    if (typeof this.brandId !== 'undefined' && this.brandId !== null) {
      this.adService.getAds(this.brandId).subscribe(
        (response) => {
          if (typeof this.rowDataLinkedAds === 'undefined' || this.rowDataLinkedAds === null) {
            this.rowDataLinkedAds = Observable.create((obs) => {
              obs.next([]);
              obs.complete();
            });
            this.rowDataAds = Observable.create((obs) => {
              obs.next(response.response.ads);
              obs.complete();
            });
          } else {
            this.rowDataLinkedAds.subscribe((ads) => {
              const notSelectedAds = [];
              const selectedAds = ads.map(ad => ad['creativeId']);
              if (typeof response.response.ads !== 'undefined') {
                for (const ad of response.response.ads) {
                  if (selectedAds.findIndex(selAd => selAd === ad['creativeId']) === -1) {
                    notSelectedAds.push(ad);
                  }
                }
              }
              this.rowDataAds = Observable.create((obs) => {
                obs.next(notSelectedAds);
                obs.complete();
              });
            });
          }
        },
        (error) => {console.log(error, 'getAds')}
      );
    }
  }

  cellClicked(node: any) {
    if (node.column.colId === 'action' && node.event.target.tagName === 'BUTTON' && node.event.target.innerText === 'Link') {
      this.gridAdsLinked.updateRowData({ add: [node.data] });
      this.gridAds.updateRowData({ remove: [node.data] });
    } else if (node.column.colId === 'action' && node.event.target.tagName === 'BUTTON' && node.event.target.innerText === 'Unlink') {
      this.gridAdsLinked.updateRowData({ remove: [node.data] });
      this.gridAds.updateRowData({ add: [node.data] });
    }
  }

  onGridReadyAds(params) {
    this.gridAds = params.api;
  }

  onGridReadyLinked(params) {
    this.gridAdsLinked = params.api;
  }

  unlinkAll() {
    const toUnlink = [];
    this.gridAdsLinked.forEachNode(function(node) {
      toUnlink.push(node.data);
    });
    this.gridAds.updateRowData({ add: toUnlink });
    this.gridAdsLinked.updateRowData({ remove: toUnlink });
  }

  closeModal() {
    this.rowDataLinkedAds = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.unlinkAll();
    $('.link-ads-campaign').modal('hide');
  }

  linkAds() {
    const toUnlink = [];
    this.gridAdsLinked.forEachNode(function(node) {
      toUnlink.push(node.data);
    });
    this.adsToLink.emit(toUnlink);
    this.rowDataLinkedAds = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.unlinkAll();
    $('.link-ads-campaign').modal('hide');
  }
}
