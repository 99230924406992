import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef, Injectable,
  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {PerformanceComponent} from '../performance/performance.component';
import {Location, Appearance} from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;
import * as jquery from 'jquery';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {LinkAdCampaignComponent} from '../../shared/cell-renderers/link-ad-campaign/link-ad-campaign.component';
import {MatSelectChange} from '@angular/material/select';
import {GeographicService} from '../../_services/geographic/geographic.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {concat, forkJoin, Observable, Observer, of, Subject, Subscription} from 'rxjs';
import {AlertService} from '../../_services/alert.service';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {Conversion} from '../../_models/conversions';
import {ActivatedRoute, CanDeactivate, Router} from '@angular/router';
import {MatRadioChange} from '@angular/material/radio';
import {browserLanguages, carriersData, dmaCountryCodes} from '../../_models/aux-data';
import {ThirdPartyDataService} from '../../_services/third-party-data/third-party-data.service';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {NewAudienceModalComponent} from '../../modals/new-audience-modal/new-audience-modal.component';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import * as L from 'leaflet';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {GeoHereLibService} from '../../_services/geo-here-lib/geo-here-lib.service';
import {environment} from '../../../environments/environment';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {CdkStep, StepperSelectionEvent} from '@angular/cdk/stepper';
import {MatSelectionList, MatSelectionListChange} from '@angular/material/list';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {Brand} from '../../_models/brand';
import {LinkAdsCampaignComponent} from '../../modals/link-ads-campaign/link-ads-campaign.component';
import {Ad} from '../../_models/ad';
import {AdReviewComponent} from '../../shared/ad-review/ad-review.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import {CheckBoxCellComponent} from '../../shared/check-box-cell/check-box-cell.component';
// @ts-ignore
import moment = require('moment');
import {CampaignService} from '../../_services/campaign/campaign.service';
import {Campaign} from '../../_models/campaign';
import {NgSelectComponent} from '@ng-select/ng-select';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import {DynamicFormBuilderService} from '../../_services/dynamic-form-builder.service';
import {MatHorizontalStepper} from '@angular/material';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {now} from 'moment';
import {BrandService} from '../../_services/brand/brand.service';
import { CommonAuthService } from 'app/auth/common-auth.service';
import {NewConversionModalComponent} from '../../modals/new-conversion-modal/new-conversion-modal.component';
import Swal from 'sweetalert2';
import {GroupService} from '../../_services/group/group.service';
import {MarketplaceComponent} from '../marketplace/marketplace.component';
import {SelectInventoryModalComponent} from '../../modals/select-inventory-modal/select-inventory-modal.component';
import {AdService} from '../../_services/ad/ad.service';
import {response} from 'express';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.scss']
})
export class NewCampaignComponent implements OnInit, OnDestroy {
  hoursRulesGrid: any;
  public audienceDone = false;
  public audienceCrmDone = false;
  public audienceSetsDone = false;
  convertionsGrid: any;
  carriersGrid: any;
  carriersTargetGrid: any;
  toTrackConvertionsGrid: any;
  brandId: any;
  groupId: any;
  hyperlocalGrid: any;
  selectedAudienceGroup = 0;
  newInventoryForm: FormGroup;
  inventoryGrid: any;
  domainListsGrid: any;
  dealsGrid: any;
  public linkedAdsGrid: any;
  public currentAdRanges: any[] = [{begin: null, end: null}];
  public currentRowAd: any;
  public newCampaignForm: FormGroup;
  public newGeneralCampaignForm: FormGroup;
  public useBidMultipliers = false;
  public frequencyCap = 'off';
  compParent = {componentParent: this};
  public impressionsMultipliersColumns = [
    { headerName: 'Impression', field: 'impression' },
    {
      headerName: 'Bid Multiplier',
      field: 'multiplier',
      cellRenderer: (params) => parseFloat(params.value).toFixed(2) + 'x bid factor <i class="ti-pencil" style="position: absolute;right: 4px;top: 8px;"></i>',
      editable: true
    }
  ];
  public impressionsMultipliersData = [
    { impression: '1st', multiplier: 1 },
    { impression: '2nd', multiplier: 1 },
    { impression: '3rd', multiplier: 1 },
  ];
  public times = [
    '12:00am',
    '12:30am',
    '1:00am',
    '1:30am',
    '2:00am',
    '2:30am',
    '3:00am',
    '3:30am',
    '4:00am',
    '4:30am',
    '5:00am',
    '5:30am',
    '6:00am',
    '6:30am',
    '7:00am',
    '7:30am',
    '8:00am',
    '8:30am',
    '9:00am',
    '9:30am',
    '10:00am',
    '10:30am',
    '11:00am',
    '11:30am',
    '12:00pm',
    '12:30pm',
    '1:00pm',
    '1:30pm',
    '2:00pm',
    '2:30pm',
    '3:00pm',
    '3:30pm',
    '4:00pm',
    '4:30pm',
    '5:00pm',
    '5:30pm',
    '6:00pm',
    '6:30pm',
    '7:00pm',
    '7:30pm',
    '8:00pm',
    '8:30pm',
    '9:00pm',
    '9:30pm',
    '10:00pm',
    '10:30pm',
    '11:00pm',
    '11:30pm',
    '11:59pm',
  ];
  public hoursColumns = [
    {
      headerName: 'From', field: 'from', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.times
      },
      width: 75
    },
    {
      headerName: 'To', field: 'to', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.times
      },
      width: 75
    },
    { headerName: 'Mon', field: 'monday', cellRenderer: 'checkBoxCellComponent', width: 50 },
    { headerName: 'Tue', field: 'tuesday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Wed', field: 'wednesday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Thu', field: 'thursday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Fri', field: 'friday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Sat', field: 'saturday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Sun', field: 'sunday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: '', field: 'actions', width: 50, cellRenderer: (params) => '<i class="ti-trash" style="font-size: 14px; cursor: pointer"></i>'},
  ];
  public hoursData = [];
  frameworks = {
    linkAdCampaignComponent: LinkAdCampaignComponent,
    actionsCellRendererComponent: ActionsCellRendererComponent,
    adReviewComponent: AdReviewComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    checkBoxCellComponent: CheckBoxCellComponent
  };
  public gridImpressionsMultiplier: any;
  budgetDateRange = {
    begin: null,
    end: null
  };
  flightDateRangeType = 'range';
  pacingModel = 1;
  dayPartingType = 'all';
  optimizationType = 'none';
  advanceOptSettings = false;
  ngLoading = false;
  isCollapsed = true;
  countries: any = [];
  zipCountrySelect = 'US';
  dmaCodesInput = '';
  zipCodesInput = '';

  public locationsColumns = [
    {
      headerName: '', field: 'type', hide: true
    },
    {
      headerName: '', field: 'codes', hide: true
    },
    {
      headerName: 'Location', field: 'location', width: 500
    },
    {
      headerName: 'Status', field: 'status', width: 100
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-trash"></i>'},
  ];
  public locationsGridData = [];
  public converstionGridData: any;
  public carriersGridData = [];
  public trackConverstionGridData = [];
  public targetCarriersGridData = [];
  locationsGrid: any;
  viewability = 'off';
  trackConverstions = 'off';
  public convertionsColumns = [
    {
      headerName: 'Name', field: 'name', width: 300
    },
    {
      headerName: 'ID', field: 'offerId', width: 100
    },
    {
      headerName: 'Default Value', field: 'defaultRevenue', width: 100, cellRenderer: (params) => '$' + parseFloat(params.value).toFixed(2)
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-shift-right" title="Track"></i>'},
  ];

  public carriersColumns = [
    {
      headerName: 'Carrier', field: 'carrier', width: 400, filter: true
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-shift-right" title="Target"></i>'},
  ];

  public targetCarriersColumns = [
    {
      headerName: 'Carrier', field: 'carrier', width: 400, filter: true
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-trash"></i>'},
  ];

  public trackConvertionsColumns = [
    {
      headerName: 'Primary', field: 'primary', width: 80, hide: true, cellRenderer: 'checkBoxCellComponent'
    },
    {
      headerName: 'Name', field: 'name', width: 300
    },
    {
      headerName: 'ID', field: 'offerId', width: 100
    },
    {
      headerName: 'Default Value', field: 'defaultRevenue', width: 100, cellRenderer: (params) => '$' + parseFloat(params.value).toFixed(2)
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-trash"></i>'},
  ];
  primaryConversion = false;
  clickthroughConversions = true;
  viewthroughConversions = true;
  videoConfig = false;
  platformInvType = false;
  mobileCarriers = false;
  mobileCarriersType = 'all';
  carrierType = 'home';
  mobileConnectionType = false;
  wifiEnabled = false;
  mobileDataEnabled = false;
  unknownConnectionEnabled = false;
  contextual = false;
  contextualSource = 'thirdParty';
  brandProtectionSource = 'thirdParty';
  pagePosition = false;
  audienceCapture = false;
  audiences = [];
  audiencesCrm = [];
  audienceSets = [];
  clickAudienceCheck = false;
  @ViewChild(NewAudienceModalComponent, null) newAudienceModal: NewAudienceModalComponent;
  adOptimization = false;
  deals = false;
  newDevicesForm: any;
  deviceType =  false;
  deviceMake = false;
  deviceModel = false;
  deviceOSVersion = false;
  selectedDeviceMakes = [];
  selectedDeviceModels = [];
  makes: any;
  // modelsLocal: Observable<Array<{make: string, model: string}>>;
  modelsLocal: Array<{make: string, model: string}> = [];
  deviceBrowser = false;
  browserLanguages = browserLanguages;
  deviceBrowserLang =  false;
  newAudiencesForm: any;

  itemsLocations: TreeviewItem[] = [];
  audiencesThirdPartyData: TreeviewItem[] = [];
  valuesLocations: any[] = [];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
    maxHeight: 400
  });
  dmaCodes = dmaCountryCodes;
  selectedDmaCodes = [];
  itemsContextual: TreeviewItem[] = [];
  itemsBrandProtection: TreeviewItem[] = [];
  configContextual = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  contextualRules: any[] = [];
  brandProtectionRules: any[] = [];
  brandProtection = false;
  hyperlocal = false;
  hyperlocalPutDataType = 'normal';
  manualHyperlocalType = 'enter';
  autocompleteAddresses: any[] = [];
  markers: Array<any> = [];
  mapHyper: {};
  popup = L.popup();
  mapIcon = L.icon({
    iconUrl: '/assets/img/map-marker.png',
    iconSize:     [10, 25],
  });
  // @ts-ignore
  @ViewChild('primaryConvertionCheckbox') primaryConvertionCheckbox: any;
  // @ts-ignore
  @ViewChild('auto') auto: ElementRef;
  // @ts-ignore
  @ViewChild('dmaCodesSelector') dmaCodesSelector: NgSelectComponent;
  // @ts-ignore
  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAuto: MatAutocompleteTrigger;
  hyperlocalRadiusUnits = 'km';
  hyperlocalSelectedData: any[] = [];
  hyperlocalSelectedColumns = [
    {
      headerName: 'Active', field: 'status', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Yes', 'No']
      },
      width: 60
    },
    {
      headerName: 'Zone', field: 'zone', width: 60
    },
    {
      headerName: 'Address', field: 'address', width: 300
    },
    {
      headerName: 'Latitude', field: 'lat', width: 80
    },
    {
      headerName: 'Longitude', field: 'lng', width: 80
    },
    {
      headerName: 'Radius', field: 'radius', width: 120, editable: true, cellRenderer: (params) => parseFloat(params.value).toFixed(2) + ' ' + this.hyperlocalRadiusUnits
    },
    {
      headerName: 'Target', field: 'target', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Targeted', 'Exclude']
      },
      width: 75
    },
    { headerName: '', field: 'actions', width: 65, cellRenderer: (params) => '<i class="ti-location-pin" style="margin-right: 8px;font-size: 16px;cursor: pointer"></i><i class="ti-trash" style="font-size: 16px;cursor: pointer" title="Remove"></i>'},
  ];
  pondOptions = {
    multiple: false,
    labelIdle: 'Drop files or click here for browsing...',
    acceptedFileTypes: 'text/plain',
    allowImagePreview: false,
    maxFileSize: '200KB',
  };
  public pondFiles = [];
  // @ts-ignore
  @ViewChild('myPond') myPond: any;
  audiencesTab = 'audiences';
  configThirdPartyTree = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: true,
  });
  // @ts-ignore
  @ViewChild('myAudiencesList') myAudiencesList: MatSelectionList;
  // @ts-ignore
  @ViewChild('myAudiencesCrmList') myAudiencesCrmList: MatSelectionList;
  newAudiencesFormextraData = {};
  columnDefsInventory = [
    {headerName: 'Name', field: 'name', width: 300 },
    {headerName: 'Exchanges', field: 'exchanges', width: 400,
      cellRenderer: (params) => (typeof params.value !== 'undefined' && params.value !== null && params.value.length > 0)
        ? params.value.join(', ') : 'All Exchanges' },
    {
      headerName: '',
      field: 'domainId',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 40,
      cellRenderer: (params) => {
        let amount = 0;
        if (typeof this.inventoryGrid !== 'undefined' && this.inventoryGrid !== null) {
          amount = this.inventoryGrid.getDisplayedRowCount();
        }
        return (
          (this.inventoryUnitsSelected === null || this.inventoryUnitsSelected.findIndex(i => i.identifier === params.node.data.domainId) === -1)
          && (amount > 1)
        )
        ? '<i class="ti-trash" style="cursor: pointer;font-size: 13px"></i>' : '';
      }
    },
  ];
  columnDefsDomainLists = [
    {headerName: 'Domain List Name', field: 'name', width: 300 },
    {headerName: 'Domain List Type', field: 'type', width: 400 },
    {
      headerName: '',
      field: 'domainListId',
      colId: 'actions',
      sortable: false,
      filter: true,
      width: 40,
      cellRenderer: (params) => '<i class="ti-trash" style="cursor: pointer;font-size: 13px"></i>'
    },
  ];
  columnDefsDeals = [
    {
      headerName: '',
      field: 'dealId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 40,
      cellRenderer: (params) => '<i class="ti-trash" style="cursor: pointer;font-size: 13px"></i>'
    },
    {headerName: 'Name', field: 'name', width: 300, sortable: true },
    {headerName: 'Publisher', field: 'publisherName', width: 150, sortable: true },
    {headerName: 'Exchange', field: 'exchangeName', width: 150, sortable: true},
    {headerName: 'Price', field: 'price', width: 100, sortable: true, cellRenderer: (params) => '$' + parseFloat(params.node.data.price)},
    {headerName: 'Start Date', field: 'start', width: 100, sortable: true},
    {headerName: 'End Date', field: 'end', width: 100, sortable: true},
    {headerName: 'Y\'days Auctions', field: 'previousDayAuctions', width: 150, sortable: true}
  ];
  rowDataInventory: any;
  rowDataDomainLists: any;
  rowDataDeals: any;
  selectedDomainExchanges = { domains: [], exchanges: [] };
  newLinkAdsForm: FormGroup;
  @ViewChild(LinkAdsCampaignComponent, null) linkAdsCampaignModal: LinkAdsCampaignComponent;
  rowDataLinkedAds: any = new Observable((ob: Observer<Array<Ad>>) => {
    ob.next([]);
    ob.complete();
  });
  columnDefsLinkedAds = [
    {
      headerName: '',
      field: 'creativeId',
      checkboxSelection: true,
      cellRenderer: (params) => '',
      width: 40
    },
    {
      headerName: '',
      field: 'label',
      width: 200
    },
    {
      headerName: 'Preview',
      field: 'previewUrl',
      width: 200,
      autoHeight: true,
      cellStyle: {'text-align': 'center'},
      cellRenderer: (params) => {
        if (params.data.format === 'image') {
          if (params.data.width > params.data.height && params.data.width > 200) {
            return '<a target="_blank" href="' + params.value + '"  title="Go to Preview">' +
              '<img src="' + params.data.assetUrl + '" style="width: 200px; height: auto;padding: 6px 0;" />' +
              '</a>';
          } else {
            return '<a target="_blank" href="' + params.value + '" title="Go to Preview">' +
              '<img src="' + params.data.assetUrl + '" style="width: auto; height: 200px;padding: 6px 0;" />' +
              '</a>';
          }
        } else {
          return '<a target="_blank" href="' + params.value + '">Go to Preview</a>';
        }
      }
    },
    {headerName: 'Review', field: 'reviewStatus', width: 120, cellRenderer: 'adReviewComponent' },
    {headerName: 'Status', field: 'status', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'ID', field: 'creativeId', width: 80 },
    {headerName: 'Dimensions', field: 'width', width: 80, cellRenderer: (params) => params.node.data.width + 'x' +  params.node.data.height },
    {headerName: 'Ad Type', field: 'format', width: 150 },
    {headerName: 'Tracking Summary', field: 'clickUrl', width: 250, cellRenderer: (params) => (params.value !== '') ? 'Destination URL: ' + params.value : '' },
    {headerName: 'SSL Status', field: 'sslEnabled', width: 120, cellRenderer: (params) => (params.node.data.sslEnabled) ? 'Secured' : 'Not Secured' },
    {headerName: 'Flight Dates', field: 'flightDates', width: 200,
      cellRenderer: (params) => {
        if (typeof params.value === 'undefined') {
          return '<button class="action-button text-primary" style="position: absolute;right: 3px;top: 0px"><i class="ti-pencil"></i></button>';
        } else {
            let html = '';
            for (const date of params.value) {
              html += '<div>' +
                '<label>' + ((typeof date.end !== 'undefined' && date.end !== null && date.end.isValid()) ? (date.begin.format('MM/DD/YYYY') + ' - ' + date.end.format('MM/DD/YYYY')) : ('Starts on ' + date.begin.format('MM/DD/YYYY'))) + '</label>' +
              '</div>';
            }
            html += '<button class="action-button text-primary" style="position: absolute;right: 3px;top: 0px"><i class="ti-pencil"></i></button>';
            return html;
        }
      }
    },
    {
      headerName: 'Actions',
      field: 'creativeId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Ad', type: 'onlyDelete' }
    },
  ];
  campaignList = '/campaigns/';
  toTrackConvertionsColumnApi: any;
  bulkEditFlightDates = false;
  campaignId = null;
  deviceTypeDesktop = false;
  deviceTypePhone = false;
  deviceTypeTablet = false;
  deviceTypeTV = false;
  deviceBrowserSelected = [];
  deviceBrowserLangSelected = [];

  campaignType = 'advance';
  showPacing = true;
  showAdOptimization = true;
  showAudienceCapture = true;
  showPagePosition = true;
  showHyperlocal = true;
  showBrandProtection = true;
  showContextual = true;
  showMobileConnectionTypes = true;
  showMobileCarriers = true;
  showInventory = true;
  showVideo = true;
  showLinkAdsForm = true;
  showInventoryForm = true;
  showAudiencesForm = true;
  showDeviceLanguage = true;
  showDeviceBrowser = true;
  showDeviceOS = true;
  showDeviceModel = true;
  showDeviceMake = true;
  showDeviceType = true;
  ngLoadingLinkedAds = false;
  amountLocationsByType = {dmaCode: 0, city: 0, regionName: 0, countryName: 0, postalCode: 0};
  conversionsText = [];
  contextualRulesNotBlocked = 0;
  contextualRulesBlocked = 0;
  hyperlocalNotBlocked = 0;
  hyperlocalBlocked = 0;
  brandProtectionRulesBlocked = 0;
  brandProtectionRulesNotBlocked = 0;
  audienceCaptureClick = false;
  audienceCaptureConv = false;
  makesLoading = false;
  selectLocationInputControl: FormControl =  new FormControl('');
  makeInputControl: FormControl =  new FormControl('');
  modelsLoading = false;
  modelInputControl: FormControl =  new FormControl('');
  modelsLocalFiltered = [];
  treeItemCheckedOut = [];
  locationsSets = [];
  contextualSets = [];
  brandProtectionSets = [];
  showTabErrors = false;
  margin = 1;
  audienceFilterControl = new FormControl('');
  filtering = false;
  getAudiencesAll: Subscription;
  selectLocationSubs: Subscription;
  currentUser;
  hideStatus = false;
  onlyTrackableDevices = false;
  campaignGroup = null;
  @ViewChild(NewConversionModalComponent, null) newConversionModal: NewConversionModalComponent;
  @ViewChild(MarketplaceComponent, null) marketplaceModal: MarketplaceComponent;
  @ViewChild(SelectInventoryModalComponent, null) selectInventoryModal: SelectInventoryModalComponent;
  webBid = '1';
  mobileAppBid = '1';
  emailBid = '1';
  mobileWebBid = '1';
  mobileOptimizedWebBid = '1';
  loadingVars = {fork: false, creatives: false, audiences: false};
  valueChanged = false;
  devicesValueChanged = false;
  audiencesValueChanged = false;
  inventoryValueChanged = false;
  linkAdsValueChanged = false;
  showDeals = true;
  inventoryUnitsSelected = null;
  contextualFilterControl = new FormControl('');
  getFilteredContextual: Subscription;
  getFilteredBrandProtection: Subscription;
  brandProtectionFilterControl = new FormControl('');

  getRowNodeId = (data) => data.id;
  playerSizeChecked =  false;
  filteringContextual = false;
  filteringBrandProtection = false;

  constructor(
    private fb: FormBuilder,
    private geoService: GeographicService,
    private modalService: NgbModal,
    private alert: AlertService,
    private convService: ConversionService,
    private route: ActivatedRoute,
    private thirdSourceService: ThirdPartyDataService,
    private audienceService: AudiencesService,
    private geoHereService: GeoHereLibService,
    private campaignService: CampaignService,
    private breadcrumbsService: BreadcrumbsService,
    private buildForm: DynamicFormBuilderService,
    private brandService: BrandService,
    private router: Router,
    private auth: CommonAuthService,
    private groupService: GroupService,
    private adsService: AdService
  ) { }

  ngOnDestroy(): void {

  }

  ngOnInit() {
    this.selectInventoryModal.fromEdit = false;
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.hideInitialStatus();
    // this.thirdSourceService.getMakes().subscribe(
    //   (response) => {
    //     this.makes = new Observable((ob: Observer<Array<string>>) => {
    //       ob.next(response[0]);
    //       ob.complete();
    //     });
    //   },
    //   (error) => { console.log(error, 'getMakes'); }
    // )
    this.getLocationSets();
    this.rowDataInventory = new Observable((ob: Observer<Array<any>>) => {
      ob.next([]);
      ob.complete();
    });

    this.rowDataDomainLists = new Observable((ob: Observer<Array<any>>) => {
      ob.next([]);
      ob.complete();
    });

    this.newCampaignForm = this.fb.group({
      name: ['', Validators.required],
      campaignGroupId: [this.groupId, Validators.required],
      brandId: [this.brandId, Validators.required],
      status: ['offline', Validators.required],
      defaultBid: ['4.00', [Validators.required, stringNumberMinValidator(0.10)]],
      bidShading: ['off'],
      maxBid: [{value: '0.00', disabled: !this.useBidMultipliers}],
      enableCrossDevice: [false],
      frequencyCapping: this.fb.group({
        impressions: [3, [Validators.min(1), Validators.max(99)]],
        periodInHours: [12, [Validators.min(0.10), Validators.max(72)]],
        type: ['campaign'],
      }),
      flightDates: this.fb.group({
        from: [''],
        to: ['']
      }),
      budget: this.fb.group({
        amount: ['100.00', [Validators.required, stringNumberMinValidator(5)]],
        type: ['all_time'],
        evenDeliveryEnabled: [true],
        pacingPriority: ['0'],
        schedule: this.fb.group({
          flightDates: this.fb.group({
            from: [''],
            to: ['']
          }),
          daypartingRules: this.fb.array([]),
          isRelativeDayParting: [false]
        })
      }),
      algorithmicOptimization: this.fb.group({
        optimizationMetric: ['CPC'],
        goalValue: ['5.00'],
        maxBid: ['8.00', [this.stringNumberMinCustomValidator('defaultBid')]],
        minBid: ['1.00', [this.stringNumberMaxCustomValidator('defaultBid')]],
        learnBudget: ['1.00', [this.stringNumberMaxCustomValidator('budget.amount')]],
        stepOfBidAdjustment: ['0.10', this.stringNumberMaxCustomValidator('algorithmicOptimization.maxBid')],
        thresholdOfImpressions: ['1000'],
        optimizeDomainLevel: [true],
        turnOffPlacementsOptAtMinBid: [true]
      }),
      machineLearningOptimization: this.fb.group({
        optimizationMetric: ['CPC'],
        goalValue: ['5.00', [stringNumberMinValidator(0.01), stringNumberMaxValidator(100)]],
        maxBid: ['10.00', stringNumberMaxValidator(100)],
        optimizationByPrimaryConversions: [null],
        learningScope: ['PIXEL']
      }),
      conversionConfig: this.fb.group({
        conversionWindowInDays: [30, [Validators.min(1), Validators.max(30)]],
        offers: this.fb.array([]),
        ordered: [false],
        uniquenessPolicy: ['PER_USER_CUSTOM_PERIOD'],
        uniquenessPeriodInMillis: [2, [Validators.min(1), Validators.max(99)]]
      }),
      viewthruConfig: this.fb.group({
        conversionWindowInDays: [30, [Validators.min(1), Validators.max(30)]],
        offers: this.fb.array([]),
        payoutRate: [100, [Validators.min(1), Validators.max(100)]],
        deduplicationEnabled: [true]
      }),
      enablePixalateViewability: [false],
      videoConfig: this.fb.group({
        videoSkippability: this.fb.group({
          REQUIRED: [true],
          BLOCKED: [true],
          UNKNOWN: [true],
        }),
        placementTypes: this.fb.group({
          IN_STREAM: [true],
          OUT_STREAM: [true],
          UNKNOWN: [true]
        }),
        videoPlaybackModes: this.fb.group({
          AUTOMATIC_SOUND_ON: [true],
          AUTOMATIC_SOUND_OFF: [true],
          ON_CLICK: [true],
          ON_HOVER: [true],
          UNKNOWN: [true],
        }),
        // inStreamOptions: this.fb.group({
        //   startDelays: this.fb.group({
        //     PRE_ROLL: [true],
        //     MID_ROLL: [true],
        //     POST_ROLL: [true],
        //     UNKNOWN: [true]
        //   })
        // }),
        startDelays: this.fb.group({
          PRE_ROLL: [true],
          MID_ROLL: [true],
          POST_ROLL: [true],
          UNKNOWN: [true]
        }),
        inBannerOptions: this.fb.group({
          audioPlaybackModes: this.fb.array([])
        }),
        blockUnknownPlayerDimensions: [false],
        minPlayerWidth: [''],
        minPlayerHeight: ['']
      }),
      pagePositions: this.fb.group({
        above_the_fold: [true],
        below_the_fold: [true],
        unknown: [true]
      }),
      audienceCapture:  this.fb.group({
        clickAudience: [{ value: '', disabled: true }],
        landingPageClickAudience: [''],
        conversionAudience: [{ value: '', disabled: true }]
      }),
      trafficTypes: this.fb.group({
        mobile_app: [false],
        mobile_optimized_web: [false],
        mobile_web: [false],
        web: [false],
        email: [false]
      }),
      geoTargeting: this.fb.array([]),
      contextualTargeting: this.fb.group({
        providerIds: this.fb.array([]),
        compositeContextualRule: this.fb.group({
          type: ['composite'],
          logicalOperator: ['and'],
          rules: this.fb.array([])
        })
      }),
      hyperlocalTargeting: this.fb.array([]),
      isRelativeDayParting: [false],
      notes: ['', Validators.maxLength(1000)],
      deals: this.fb.array([])
    });

    this.newDevicesForm = this.fb.group({
      deviceTargeting: this.fb.group({
        deviceTypes: this.fb.array([]),
        hardwares: this.fb.array([]),
        softwares: this.fb.array([
          this.fb.group({
            name: [''],
            osVersionTargets: this.fb.array([
              this.fb.group({
                  from: this.fb.group({
                    major: ['']
                  }),
                  to: this.fb.group({
                    major: [''],
                  })
                })
            ])
          })
        ]),
        browsers: this.fb.array([]),
        connection: this.fb.group({
          carrierCodes: this.fb.array( []),
          wifiBlocked: [false],
          connectionTypes: this.fb.array( [])
        }),
        languages: this.fb.array([]),
      }),
    });

    this.newAudiencesForm = this.fb.group({
      compositeAudienceTargetingRule: this.fb.group({
        type: ['composite'],
        logicalOperator: ['or'],
        rules:  this.fb.array([])
      }),
    });

    this.newInventoryForm = this.fb.group({
      inventoryUnitTargets: this.fb.array([]),
      domainList: this.fb.array([]),
      exchanges: this.fb.group({
        targets: this.fb.array([])
      })
    });

    this.newLinkAdsForm = this.fb.group({
      ads: this.fb.array([])
    });

    this.newGeneralCampaignForm = this.fb.group({
      bidMultipliers: this.fb.group({
        bidMultiplierRules: this.fb.array([
          this.fb.group({
            target: ['FREQUENCY_CAPPING'],
            multiplier: [''],
            fromImpression: [''],
            toImpression: ['']
          }),
          this.fb.group({
            target: ['TRAFFIC_TYPE'],
            multiplier: [''],
            trafficType: ['MOBILE_WEB']
          }),
          this.fb.group({
            target: ['EXCHANGE'],
            multiplier: [''],
            exchangeId: ['']
          }),
        ]),
        minAdjustedBidInCpm: [0],
        maxAdjustedBidInCpm: ['', Validators.required]
      }),
      flightDates: this.fb.group({
        from: ['', Validators.required],
        to: ['', Validators.required],
        timezone: ['America/Toronto']
      }),
      enabledROP: [''],
      isRelativeDayParting: [false],
      excludeAnonymousDomains: [false],
      optimizationType: ['none'],
      deals: this.fb.array([]),
      campLinkAdSchedules: this.fb.group({
        campaignSchedules: this.fb.array([
          this.fb.group({
            flightDates: this.fb.group({
              from: ['20191101 03:00:00'],
              to: ['20190801 02:59:59']
            })
          })
        ]),
        creativeSchedules: this.fb.array([
          this.fb.group({
            creativeId: [2595104],
            schedules: this.fb.array([
              this.fb.group({
                flightDates: this.fb.group({
                  from: ['20190601 00:00:00'],
                  to: ['20190630 23:59:59']
                })
              }),
              this.fb.group({
                flightDates: this.fb.group({
                  from: ['20190501 00:00:00'],
                  to: ['20190515 23:59:59']
                })
              })
            ])
          })
        ])
      })
    });

    this.selectLocationInputControl.valueChanges.pipe(debounceTime(500)).subscribe(
      value => {
        if (value !== null && value !== '' && typeof value === 'string' && value.length >= 3) {
          if (typeof this.selectLocationSubs !== 'undefined') {
            this.selectLocationSubs.unsubscribe();
          }
          this.geoFilter(value);
        }
      }
    );

    this.makeInputControl.valueChanges.pipe(debounceTime(500)).subscribe(
      value => {
        if (value !== null && value !== '' && typeof value === 'string') {
          this.makesLoading = true;
          this.setMakes(value);
        }
      }
    );

    this.modelInputControl.valueChanges.pipe(debounceTime(500)).subscribe(
      value => {
        if (value !== null && value !== '' && typeof value === 'string') {
          // this.modelsLoading = true;
          this.modelsLocalFiltered = this.modelsLocal.filter(i => (i.model.toLowerCase().indexOf(value.toLowerCase()) !== -1));
        }
      }
    );

    this.audienceFilterControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(
      value => {
        this.getAudiencesAll.unsubscribe();
        if (typeof value !== 'undefined' && value !== null && value !== '') {
          this.audiencesThirdPartyTreeDataFilter(value);
        } else {
          this.audiencesThirdPartyTreeData();
        }
      }
    );

    this.contextualFilterControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(
      value => {
        this.getFilteredContextual.unsubscribe();
        this.itemsContextual = [];
        if (typeof value !== 'undefined' && value !== null && value !== '') {
          this.contextualThirdPartyTreeDataFilter(value);
        } else {
          this.getContextualData();
        }
      }
    );

    this.brandProtectionFilterControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(
      value => {
        this.getFilteredBrandProtection.unsubscribe();
        this.itemsBrandProtection = [];
        if (typeof value !== 'undefined' && value !== null && value !== '') {
          this.brandProtectionThirdPartyTreeDataFilter(value);
        } else {
          this.getBrandProtectionData();
        }
      }
    );

    this.getContextualSets();

    this.newCampaignForm.valueChanges.subscribe(
      response => {
        if (this.loadingVars.fork && this.loadingVars.creatives) {
          this.valueChanged = true;
        }
      }
    )

    this.newDevicesForm.valueChanges.subscribe(
      response => {
        if (this.loadingVars.fork && this.loadingVars.creatives) {
          this.devicesValueChanged = true;
        }
      }
    )

    this.newAudiencesForm.valueChanges.subscribe(
      response => {
        if (this.loadingVars.fork && this.loadingVars.creatives) {
          this.audiencesValueChanged = true;
        }
      }
    )

    this.newInventoryForm.valueChanges.subscribe(
      response => {
        if (this.loadingVars.fork && this.loadingVars.creatives) {
          this.inventoryValueChanged = true;
        }
      }
    )

    this.newLinkAdsForm.valueChanges.subscribe(
      response => {
        if (this.loadingVars.fork && this.loadingVars.creatives) {
          this.linkAdsValueChanged = true;
        }
      }
    )

    this.route.params.subscribe(params => {
      this.groupId = params['groupId'];
      this.brandId = params['brandId'];
      this.groupService.getGroupById(this.groupId).subscribe(
        response => {
          // @ts-ignore
          this.campaignGroup = response.response[0];
          this.campaignGroup.budget.amount = this.campaignGroup.budget.amount / this.margin;
        }
      );
      this.newCampaignForm.controls.brandId.setValue(this.brandId);
      this.newCampaignForm.controls.campaignGroupId.setValue(this.groupId);
      if (typeof params['campaignType'] !== 'undefined') {
        this.campaignType = params['campaignType'];
      }
      if (this.campaignType === 'site') {
        this.showPacing = false;
        this.showVideo = false;
        this.showInventory = false;
        this.showMobileCarriers = false;
        this.showMobileConnectionTypes = false;
        this.showHyperlocal = false;
        this.showAudiencesForm = false;
        this.showDeviceMake = false;
        this.showDeviceModel = false;
      } else if (this.campaignType === 'retargeting' || this.campaignType === 'audience' || this.campaignType === 'contextual') {
        this.showPacing = false;
        this.showVideo = false;
        this.showInventory = false;
        this.showMobileCarriers = false;
        this.showMobileConnectionTypes = false;
        this.showHyperlocal = false;
        if (this.campaignType !== 'contextual') {
          this.showContextual = false;
        }
        this.showDeviceMake = false;
        this.showDeviceModel = false;
      } else if (this.campaignType === 'video') {
        this.videoConfig = true;
        this.showPacing = false;
        this.showInventory = false;
        this.showMobileCarriers = false;
        this.showMobileConnectionTypes = false;
        this.showHyperlocal = false;
      } else if (this.campaignType === 'mobile_app') {
        this.showContextual = false;
        this.showBrandProtection = false;
        this.showInventory = false;
        this.showPagePosition = false;
      } else if (this.campaignType === 'mobile_web') {
        this.showInventory = false;
        this.showPagePosition = false;
        this.showHyperlocal = false;
      } else if (this.campaignType === 'mobile_retargeting') {
        this.showInventory = false;
        this.showPagePosition = false;
        this.showContextual = false;
      } else if (this.campaignType === 'mobile_video') {
        this.videoConfig = true;
        this.showInventory = false;
        this.showPagePosition = false;
      } else if (this.campaignType === 'hyperlocal') {
        this.showInventory = false;
        this.showPagePosition = false;
        this.showContextual = false;
        this.showBrandProtection = false;
      }
      if (typeof params['campaignId'] !== 'undefined') {
        window.addEventListener('beforeunload', event => {
          if (this.valueChanged || this.devicesValueChanged || this.audiencesValueChanged || this.inventoryValueChanged || this.linkAdsValueChanged) {
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = 'You have unsaved changes...!';
          }
        }, false);
        this.loadingVars = {fork: false, creatives: false, audiences: false};
        this.ngLoading = true;
        this.campaignId = params['campaignId'];
        const currentUnit = localStorage.getItem('hyperlocalRadiusUnits_' + this.campaignId);
        this.hyperlocalRadiusUnits = (typeof currentUnit !== 'undefined' && currentUnit !== null && currentUnit !== '') ? currentUnit : 'km';
        this.breadcrumbsService.breadcrumbs = [
          {label: 'My Brands', url: '/mybrands', params: []},
          {label: 'Campaigns', url: '/campaigns/' + params['brandId'] + '/' + params['groupId'], params: []},
          {label: 'Edit Campaign', url: '/campaigns/' + params['brandId'] + '/' + params['groupId'] + '/edit-campaign/' + this.campaignId + '/' + this.campaignType, params: []},
        ];
        this.getAudiences();
        this.campaignService.getCampaignById(this.campaignId, this.brandId).subscribe(
          (response) => {
            // @ts-ignore
            if (response.status === 200) {
              this.margin = (response.margin > 1) ? ((100 - response.margin) / 100) : 1;
              if (typeof this.campaignGroup !== 'undefined' && this.campaignGroup !== null) {
                this.campaignGroup.budget.amount = this.campaignGroup.budget.amount / this.margin;
              }
              if (this.campaignGroup !== null && typeof this.campaignGroup.budget !== 'undefined' && typeof this.campaignGroup.budget.amount !== 'undefined') {
                this.campaignGroup.budget.amount = this.campaignGroup.budget.amount / this.margin;
              }
              this.newCampaignForm.controls.name.setValue(response.campaign.name);
              this.newCampaignForm.controls.status.setValue(response.campaign.status);
              this.newCampaignForm.controls.defaultBid.setValue((response.campaign.defaultBid / this.margin).toFixed(2));
              this.newCampaignForm.controls.bidShading.setValue(response.campaign.bidShading);
              if (typeof response.campaign.maxBid !== 'undefined') {
                this.useBidMultipliers = true;
                this.newCampaignForm.controls.maxBid.setValue(response.campaign.maxBid.toFixed(2));
              }
              this.newCampaignForm.controls.enableCrossDevice.setValue(response.campaign.enableCrossDevice);
              if (typeof response.campaign.frequencyCapping !== 'undefined') {
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.frequencyCapping['controls'].impressions.setValue(response.campaign.frequencyCapping.impressions);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.frequencyCapping['controls'].periodInHours.setValue(response.campaign.frequencyCapping.periodInHours);
                this.newCampaignForm.controls.frequencyCapping['controls'].type.setValue(response.campaign.frequencyCapping.type);
                this.frequencyCap = 'on';
              }
              if (typeof response.campaign.flightDates !== 'undefined') {
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.flightDates['controls'].from.setValue(response.campaign.flightDates.from);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.flightDates['controls'].to.setValue(response.campaign.flightDates.to);
                this.flightDateRangeType = 'range';
                this.budgetDateRange = {
                  begin: moment(response.campaign.flightDates.from, 'YYYYMMDD'),
                  end: moment(response.campaign.flightDates.to, 'YYYYMMDD')
                };
              }
              this.newCampaignForm.controls.budget['controls'].amount.setValue((response.campaign.budget.amount / this.margin).toFixed(2));
              if (this.currentUser.role !== 'ADMIN') {
                this.newCampaignForm.get('budget.amount').disable();
              }
              this.newCampaignForm.controls.budget['controls'].evenDeliveryEnabled.setValue(response.campaign.budget.evenDeliveryEnabled);
              if (typeof response.campaign.budget.evenDeliveryEnabled !== 'undefined'
                && response.campaign.budget.evenDeliveryEnabled !== null
                && response.campaign.budget.evenDeliveryEnabled) {
                this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue('1');
              } else {
                this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue('0');
                // this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue(String(response.campaign.budget.pacingPriority));
              }
              this.newCampaignForm.controls.budget['controls'].type.setValue(response.campaign.budget.type);
              if (typeof response.campaign.budget.schedule !== 'undefined') {
                if (
                  typeof response.campaign.budget.schedule.daypartingRules !== 'undefined'
                  && response.campaign.budget.schedule.daypartingRules.length > 0
                ) {
                  this.dayPartingType = 'range';
                  const dayRulesGrid = [];
                  for (const dayRule of response.campaign.budget.schedule.daypartingRules) {
                    // (this.newCampaignForm.controls.budget['controls'].schedule['controls'].daypartingRules as FormArray)
                    //   .push(this.buildForm.buildForm(dayRule));
                    dayRulesGrid.push({
                      from: moment(dayRule.fromTime, 'HH:mm').format('hh:mma'),
                      to: moment(dayRule.toTime, 'HH:mm').format('hh:mma'),
                      monday: dayRule.daysOfWeek.indexOf('monday') !== -1,
                      tuesday: dayRule.daysOfWeek.indexOf('tuesday') !== -1,
                      wednesday: dayRule.daysOfWeek.indexOf('wednesday') !== -1,
                      thursday: dayRule.daysOfWeek.indexOf('thursday') !== -1,
                      friday: dayRule.daysOfWeek.indexOf('friday') !== -1,
                      saturday: dayRule.daysOfWeek.indexOf('saturday') !== -1,
                      sunday: dayRule.daysOfWeek.indexOf('sunday') !== -1
                    });
                  }
                  setTimeout(() => {const result = this.hoursRulesGrid.updateRowData({add: dayRulesGrid}); this.hoursData = dayRulesGrid; }, 1000);
                  }
                if (typeof response.campaign.budget.schedule.isRelativeDayParting !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.budget['controls'].schedule['controls'].isRelativeDayParting.setValue(response.campaign.budget.schedule.isRelativeDayParting);
                }
              }
              let convertionChecked = false;
              if (typeof response.campaign.conversionConfig !== 'undefined') {
                this.trackConverstions = 'on';
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.conversionConfig['controls'].conversionWindowInDays.setValue(response.campaign.conversionConfig.conversionWindowInDays);
                this.newCampaignForm.controls.conversionConfig['controls'].ordered.setValue(response.campaign.conversionConfig.ordered);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.conversionConfig['controls'].uniquenessPolicy.setValue(response.campaign.conversionConfig.uniquenessPolicy.toUpperCase());
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.conversionConfig['controls'].uniquenessPeriodInMillis.setValue(response.campaign.conversionConfig.uniquenessPeriodInMillis / 60000);
                // tslint:disable-next-line:max-line-length
                if (typeof response.campaign.conversionConfig.offers !== 'undefined' && response.campaign.conversionConfig.offers.length > 0) {
                  this.getConversions(response.campaign.conversionConfig.offers);
                  convertionChecked = true;
                }
              }
              if (typeof response.campaign.viewthruConfig !== 'undefined') {
                this.trackConverstions = 'on';
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.viewthruConfig['controls'].conversionWindowInDays.setValue(response.campaign.viewthruConfig.conversionWindowInDays);
                this.newCampaignForm.controls.viewthruConfig['controls'].payoutRate.setValue(response.campaign.viewthruConfig.payoutRate * 100);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.viewthruConfig['controls'].deduplicationEnabled.setValue(response.campaign.viewthruConfig.deduplicationEnabled);
                if (typeof response.campaign.viewthruConfig.offers !== 'undefined' && response.campaign.viewthruConfig.offers.length > 0 && !convertionChecked) {
                  this.getConversions(response.campaign.viewthruConfig.offers);
                }
              }
              this.newCampaignForm.controls.enablePixalateViewability.setValue(response.campaign.enablePixalateViewability);
              this.newCampaignForm.controls.isRelativeDayParting.setValue(response.campaign.isRelativeDayParting);
              this.newCampaignForm.controls.notes.setValue(response.campaign.notes);
              if (typeof response.campaign.videoConfig !== 'undefined') {
                // this.videoConfig = true;
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].blockUnknownPlayerDimensions.setValue(response.campaign.videoConfig.blockUnknownPlayerDimensions);
                if (response.campaign.videoConfig.blockUnknownPlayerDimensions) {
                  this.videoConfig = true;
                }
                if (typeof response.campaign.videoConfig.minPlayerWidth !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].minPlayerWidth.setValue(response.campaign.videoConfig.minPlayerWidth);
                  if (!isNaN(response.campaign.videoConfig.minPlayerWidth)) {
                    this.videoConfig = true;
                    this.playerSizeChecked = true;
                  }
                }
                if (typeof response.campaign.videoConfig.minPlayerHeight !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].minPlayerHeight.setValue(response.campaign.videoConfig.minPlayerHeight);
                  if (!isNaN(response.campaign.videoConfig.minPlayerHeight)) {
                    this.videoConfig = true;
                    this.playerSizeChecked = true;
                  }
                }
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.REQUIRED.setValue(response.campaign.videoConfig.videoSkippability.indexOf('REQUIRED') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.BLOCKED.setValue(response.campaign.videoConfig.videoSkippability.indexOf('BLOCKED') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.UNKNOWN.setValue(response.campaign.videoConfig.videoSkippability.indexOf('UNKNOWN') !== -1);
                if (response.campaign.videoConfig.videoSkippability.indexOf('REQUIRED') === -1
                  || response.campaign.videoConfig.videoSkippability.indexOf('BLOCKED') === -1
                  || response.campaign.videoConfig.videoSkippability.indexOf('UNKNOWN') === -1) {
                  this.videoConfig = true;
                }

                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM.setValue(response.campaign.videoConfig.placementTypes.indexOf('IN_STREAM') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.OUT_STREAM.setValue(response.campaign.videoConfig.placementTypes.indexOf('OUT_STREAM') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.UNKNOWN.setValue(response.campaign.videoConfig.placementTypes.indexOf('UNKNOWN') !== -1);
                // tslint:disable-next-line:max-line-length
                // this.newCampaignForm.controls.videoConfig['controls'].deliveryTypes.controls.BOTH.setValue(response.campaign.videoConfig.deliveryTypes.indexOf('BOTH') !== -1);
                // tslint:disable-next-line:max-line-length
                if (response.campaign.videoConfig.placementTypes.indexOf('IN_STREAM') === -1
                  || response.campaign.videoConfig.placementTypes.indexOf('OUT_STREAM') === -1
                  || response.campaign.videoConfig.placementTypes.indexOf('UNKNOWN') === -1) {
                  this.videoConfig = true;
                }

                this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_ON.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_ON') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_OFF.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_OFF') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_CLICK.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_CLICK') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_HOVER.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_HOVER') !== -1);
                // tslint:disable-next-line:max-line-length
                this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.UNKNOWN.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('UNKNOWN') !== -1);
                if (response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_ON') === -1
                  || response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_OFF') === -1
                  || response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_HOVER') === -1
                  || response.campaign.videoConfig.videoPlaybackModes.indexOf('UNKNOWN') === -1
                  || response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_CLICK') === -1) {
                  this.videoConfig = true;
                }

                if (typeof response.campaign.videoConfig.startDelays !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('PRE_ROLL') !== -1);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('MID_ROLL') !== -1);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('POST_ROLL') !== -1);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.setValue(response.campaign.videoConfig.startDelays.indexOf('UNKNOWN') !== -1);
                  if (response.campaign.videoConfig.startDelays.indexOf('PRE_ROLL') === -1
                    || response.campaign.videoConfig.startDelays.indexOf('MID_ROLL') === -1
                    || response.campaign.videoConfig.startDelays.indexOf('POST_ROLL') === -1
                    || response.campaign.videoConfig.startDelays.indexOf('UNKNOWN') === -1) {
                    this.videoConfig = true;
                  }
                } else {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.setValue(false);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.setValue(false);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.setValue(false);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.setValue(false);
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.disable();
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.disable();
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.disable();
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.disable();
                }
              }
              if (typeof response.campaign.audienceCapture !== 'undefined') {
                this.audienceCapture = true;
                this.newCampaignForm.controls.audienceCapture['controls'].clickAudience.enable();
                this.newCampaignForm.controls.audienceCapture['controls'].conversionAudience.enable();
                if (typeof response.campaign.audienceCapture.clickAudience !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.audienceCapture['controls'].clickAudience.setValue(response.campaign.audienceCapture.clickAudience);
                  this.audienceCaptureClick = true;
                }
                if (typeof response.campaign.audienceCapture.conversionAudience !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.audienceCapture['controls'].conversionAudience.setValue(response.campaign.audienceCapture.conversionAudience);
                  this.audienceCaptureConv = true;
                }
                if (typeof response.campaign.audienceCapture.landingPageClickAudience !== 'undefined') {
                  // tslint:disable-next-line:max-line-length
                  this.newCampaignForm.controls.audienceCapture['controls'].landingPageClickAudience.setValue(response.campaign.audienceCapture.landingPageClickAudience);
                }
              }
              this.campaignService.getAlgorithmicOptimization(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    if (responseExtra['enabled']) {
                      this.optimizationType = 'algorithmic';
                      this.advanceOptSettings = true;
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].optimizationMetric.setValue(responseExtra['optimizationMetric']);
                      switch (responseExtra['optimizationMetric']) {
                        case 'CTR':
                          responseExtra['goalValue'] = responseExtra['goalValue'] * 100;
                          break;
                        case 'VCR':
                          responseExtra['goalValue'] = responseExtra['goalValue'] * 100;
                          break;
                      }
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].goalValue.setValue(responseExtra['goalValue']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].maxBid.setValue(responseExtra['maxBid']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].minBid.setValue(responseExtra['minBid']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].learnBudget.setValue(responseExtra['learnBudget']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].stepOfBidAdjustment.setValue(responseExtra['stepOfBidAdjustment']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].thresholdOfImpressions.setValue(responseExtra['thresholdOfImpressions']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].optimizeDomainLevel.setValue(responseExtra['optimizeDomainLevel']);
                      this.newCampaignForm.controls.algorithmicOptimization['controls'].turnOffPlacementsOptAtMinBid.setValue(responseExtra['turnOffPlacementsOptAtMinBid']);
                    }
                  }
                },
                (error) => { console.log(error, 'getAlgorithmicOptimization'); }
              );
              this.campaignService.getMachineLearningOptimization(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] !== null) {
                    const last = (responseExtra as Array<any>).length - 1;
                    if (last >= 0 && responseExtra[last].active) {
                      this.optimizationType = 'machineLearning';
                      this.newCampaignForm.controls.machineLearningOptimization['controls'].optimizationMetric.setValue(responseExtra[last]['optimizationMetric']);
                      this.newCampaignForm.controls.machineLearningOptimization['controls'].goalValue.setValue(responseExtra[last]['goalValue'].toFixed(2));
                      this.newCampaignForm.controls.machineLearningOptimization['controls'].maxBid.setValue(responseExtra[last]['maxBid'].toFixed(2));
                    }
                  }
                },
                (error) => { console.log(error, 'getMachineLearningOptimization'); }
              );
              this.campaignService.getPagePositions(this.campaignId).subscribe(
                (responseExtra: any) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    this.pagePosition = true;
                    this.newCampaignForm.controls.pagePositions['controls'].above_the_fold.setValue(responseExtra.indexOf('above_the_fold') !== -1);
                    this.newCampaignForm.controls.pagePositions['controls'].below_the_fold.setValue(responseExtra.indexOf('below_the_fold') !== -1);
                    this.newCampaignForm.controls.pagePositions['controls'].unknown.setValue(responseExtra.indexOf('unknown') !== -1);
                    if (responseExtra.indexOf('above_the_fold') !== -1 && responseExtra.indexOf('below_the_fold') !== -1 && responseExtra.indexOf('unknown') !== -1) {
                      this.pagePosition = false;
                    }
                  }
                },
                (error) => { console.log(error, 'getPagePositions'); }
              );
              this.campaignService.getTrafficType(this.campaignId).subscribe(
                (responseExtra: any) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    this.newCampaignForm.controls.trafficTypes['controls'].mobile_app.setValue(responseExtra.indexOf('mobile_app') !== -1 || responseExtra.indexOf('mobile_app'.toUpperCase()) !== -1);
                    this.newCampaignForm.controls.trafficTypes['controls'].mobile_optimized_web.setValue(responseExtra.indexOf('mobile_optimized_web') !== -1 || responseExtra.indexOf('mobile_optimized_web'.toUpperCase()) !== -1);
                    this.newCampaignForm.controls.trafficTypes['controls'].mobile_web.setValue(responseExtra.indexOf('mobile_web') !== -1 || responseExtra.indexOf('mobile_web'.toUpperCase()) !== -1);
                    this.newCampaignForm.controls.trafficTypes['controls'].web.setValue(responseExtra.indexOf('web') !== -1 || responseExtra.indexOf('WEB') !== -1);
                    this.newCampaignForm.controls.trafficTypes['controls'].email.setValue(responseExtra.indexOf('email') !== -1 || responseExtra.indexOf('EMAIL') !== -1);
                    // this.newCampaignForm.controls.trafficTypes['controls'].other.setValue(responseExtra.indexOf('other') !== -1 || responseExtra.indexOf('OTHER') !== -1);
                    if (
                      responseExtra.indexOf('mobile_app') !== -1 || responseExtra.indexOf('MOBILE_APP') !== -1
                      || responseExtra.indexOf('mobile_optimized_web') !== -1 || responseExtra.indexOf('MOBILE_OPTMIZED_WEB') !== -1
                      || responseExtra.indexOf('mobile_web') !== -1 || responseExtra.indexOf('MOBILE_WEB') !== -1
                      || responseExtra.indexOf('web') !== -1 || responseExtra.indexOf('WEB') !== -1
                      || responseExtra.indexOf('email') !== -1 || responseExtra.indexOf('EMAIL') !== -1
                    ) {
                      this.platformInvType = true;
                    }
                  }
                },
                (error) => { console.log(error, 'getTrafficType'); }
              );
              this.campaignService.getGeoTargeting(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    const toAdd = [];
                    for (const target of responseExtra['results']) {
                      if (typeof target.dmaCode !== 'undefined') {
                        const dmaData = this.dmaCodes.find((elem) => elem.dmaCode === target.dmaCode);
                        toAdd.push({id: dmaData.dmaCode + '-' + dmaData.dmaName, location: 'Country: United States, DMA Code:  ' + dmaData.dmaCode + ' - ' + dmaData.dmaName, type: 'zip', codes: JSON.stringify({countryCode: 'US', blocked: target.blocked, dmaCode: dmaData.dmaCode}), status: (target.blocked) ? 'Excludes' : 'Targeted' });
                      } else if (typeof target.postalCode !== 'undefined') {
                        toAdd.push({ id: target.countryName + '-' + target.postalCode, location: 'Country: ' + target.countryName + ',  Zip/Postal Code: ' + target.postalCode, type: 'zip', codes: JSON.stringify({countryCode: target.countryCode, blocked: target.blocked, postalCode: target.postalCode}), status: (target.blocked) ? 'Excludes' : 'Targeted' })
                      } else {
                        const locationString = (typeof target.city !== 'undefined')
                          ? (target.city + ' (City), ' + target.primaryRegionName) : ((typeof target.primaryRegionCode !== 'undefined')
                            ? (target.primaryRegionName + ' (Region), ' + target.countryName) : (target.countryName + ' (Country)'));
                        toAdd.push({type: 'locations', codes: JSON.stringify(target), location: locationString, status: (target.blocked) ? 'Excludes' : 'Targeted'});
                      }
                    }
                    const res = this.locationsGrid.updateRowData({ add: toAdd });
                  }
                },
                (error) => { console.log(error, 'getGeoTargeting'); }
              );
              this.campaignService.getContextualTargeting(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['compositeContextualRule'] !== 'undefined' && responseExtra['compositeContextualRule'] !== null) {
                    this.ngLoading = true;
                    this.reverseContextualFullfillment(responseExtra['compositeContextualRule']);
                  }
                },
                (error) => { console.log(error, 'getContextualTargeting'); }
              );
              this.campaignService.getHyperlocalTargeting(this.campaignId).subscribe(
                (responseExtra: any) => {
                  // tslint:disable-next-line:max-line-length
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] !== null) {
                    // this.hyperlocalRadiusUnits = responseExtra[0]['unit'] === 'meters'  ? 'm' : 'ft';
                    for (const loc of responseExtra) {
                      const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
                      if (this.hyperlocalRadiusUnits === 'km' && loc.unit === 'meters') {
                        loc.distance = parseFloat(loc.distance) / 1000;
                      } else if (this.hyperlocalRadiusUnits === 'ml' && loc.unit === 'meters') {
                        loc.distance = parseFloat(loc.distance) / 1609.34;
                      } else if (this.hyperlocalRadiusUnits === 'km' && loc.unit === 'feet') {
                        loc.distance = parseFloat(loc.distance) / 3280.84;
                      } else if (this.hyperlocalRadiusUnits === 'ft') {
                        loc.distance = parseFloat(loc.distance);
                      }
                      const location = {
                        status: 'Yes',
                        zone: zone,
                        address: (typeof loc.label !== 'undefined') ? loc.label : '',
                        lat: loc.latitude,
                        lng: loc.longitude,
                        radius: loc.distance,
                        target: (!loc.blocked) ? 'Targeted' : 'Exclude',
                      };
                      // const res = this.hyperlocalGrid.updateRowData({add: [location]});
                      this.hyperlocalSelectedData.push(location);
                      this.mapClick(loc.latitude, loc.longitude, zone);
                    }
                    if (this.hyperlocalSelectedData.length > 0) {
                      this.hyperlocal = this.showHyperlocal = true;
                      // this.hyperlocalRadiusUnits = responseExtra[0]['unit'] === 'feet' ? 'ft' : 'm';
                    }
                  }
                },
                (error) => { console.log(error, 'getHyperlocalTargeting'); }
              );
              this.campaignService.getDevicesTargeting(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    if (typeof responseExtra['deviceTypes'] !== 'undefined' && (responseExtra['deviceTypes'] as Array<string>).length > 0) {
                      this.deviceType = true;
                      for (const type of responseExtra['deviceTypes']) {
                        this.deviceType = true;
                        (this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes as FormArray).push(this.fb.control(type));
                        if (type === 'DESKTOP') {
                          this.deviceTypeDesktop = true;
                        } else if (type === 'PHONE') {
                          this.deviceTypePhone = true;
                        } else if (type === 'TABLET') {
                          this.deviceTypeTablet = true;
                        } else if (type === 'CONNECTED_TV') {
                          this.deviceTypeTV = true;
                        }
                      }
                    }
                    if (typeof responseExtra['hardwares'] !== 'undefined' && (responseExtra['hardwares'] as Array<any>).length > 0) {
                      this.deviceMake = true;
                      this.deviceModel = true;
                      const allModels = [];
                      for (const type of responseExtra['hardwares']) {
                        const models = [];
                        if (typeof type.models !== 'undefined') {
                          for (const model of type.models) {
                            models.push({make: type.make, model: model});
                            allModels.push({make: type.make, model: model});
                          }
                        }
                        (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).push(this.fb.group({
                          make: [type.make],
                          models: this.fb.array(models.map(i => i.model))
                        }));
                        this.selectedDeviceMakes.push({make: type.make, models: models});
                        this.selectedDeviceModels = allModels;
                        this.addModelsByMake(type.make);
                      }
                      // @ts-ignore
                      // this.deviceMakesChanged(this.selectedDeviceMakes);
                      // if (models.length > 0) {
                      //   // @ts-ignore
                      //   this.deviceModelsChanged(models);
                      // }
                    }
                    if (typeof responseExtra['softwares'] !== 'undefined' && (responseExtra['softwares'] as Array<any>).length > 0) {
                      (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).removeAt(0);
                      this.deviceOSVersion = true;
                      for (const soft of responseExtra['softwares']) {
                        let osVersionTargets: FormArray = null;
                        if (typeof soft.osVersionTargets !== 'undefined' && soft.osVersionTargets.length > 0) {
                          osVersionTargets = this.fb.array([
                            this.fb.group({
                              from: this.fb.group({
                                major: [(typeof soft.osVersionTargets[0] !== 'undefined'
                                  && typeof soft.osVersionTargets[0].from !== 'undefined'
                                  && typeof soft.osVersionTargets[0].from.major !== 'undefined')
                                  ? soft.osVersionTargets[0].from.major :
                                  ''],
                                minor: [(typeof soft.osVersionTargets[0] !== 'undefined'
                                  && typeof soft.osVersionTargets[0].from !== 'undefined'
                                  && typeof soft.osVersionTargets[0].from.minor !== 'undefined')
                                  ? soft.osVersionTargets[0].from.minor :
                                  '']
                              }),
                              to: this.fb.group({
                                major: [(typeof soft.osVersionTargets[0] !== 'undefined'
                                  && typeof soft.osVersionTargets[0].to !== 'undefined'
                                  && typeof soft.osVersionTargets[0].to.major !== 'undefined')
                                  ? soft.osVersionTargets[0].to.major :
                                  ''],
                                minor: [(typeof soft.osVersionTargets[0] !== 'undefined'
                                  && typeof soft.osVersionTargets[0].to !== 'undefined'
                                  && typeof soft.osVersionTargets[0].to.minor !== 'undefined')
                                  ? soft.osVersionTargets[0].to.minor :
                                  '']
                              }),
                            })
                          ]);
                        }
                        (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).push(this.fb.group({
                          name: [soft.name],
                          osVersionTargets: osVersionTargets
                        }));
                      }
                    }
                    if (typeof responseExtra['browsers'] !== 'undefined' && (responseExtra['browsers'] as Array<any>).length > 0) {
                      this.deviceBrowser = true;
                      this.deviceBrowserSelected = responseExtra['browsers'];
                      for (const brow of responseExtra['browsers']) {
                        (this.newDevicesForm.controls.deviceTargeting.controls.browsers as FormArray).push(this.fb.control(brow));
                      }
                    }
                    if (typeof responseExtra['languages'] !== 'undefined' && (responseExtra['languages'] as Array<any>).length > 0) {
                      this.deviceBrowserLang = true;
                      for (const lang of responseExtra['languages']) {
                        (this.newDevicesForm.controls.deviceTargeting.controls.languages as FormArray).push(this.fb.control(lang));
                        this.deviceBrowserLangSelected.push(this.browserLanguages.find((item) => item.code === lang).code);
                      }
                    }
                    // tslint:disable-next-line:max-line-length
                    if (typeof responseExtra['connection'] !== 'undefined' && typeof responseExtra['connection']['carrierCodes'] !== 'undefined' && (responseExtra['connection']['carrierCodes'] as Array<any>).length > 0) {
                      this.mobileCarriers = true;
                      this.mobileCarriersType = 'custom';
                      this.setCarriersData(responseExtra['connection']['carrierCodes']);
                    } else {
                      this.setCarriersData();
                    }
                    if (typeof responseExtra['connection'] !== 'undefined' && typeof responseExtra['connection']['connectionTypes'] !== 'undefined' && (responseExtra['connection']['connectionTypes'] as Array<any>).length > 0) {
                      this.mobileConnectionType = true;
                      this.wifiEnabled = responseExtra['connection']['connectionTypes'].indexOf('WIFI') >= 0;
                      this.mobileDataEnabled = responseExtra['connection']['connectionTypes'].indexOf('CELLULAR_DATA') >= 0;
                      this.unknownConnectionEnabled = responseExtra['connection']['connectionTypes'].indexOf('UNKNOWN') >= 0;
                    }
                  } else {
                    this.setCarriersData();
                  }
                },
                (error) => { console.log(error, 'getDevicesTargeting'); this.setCarriersData(); }
              );
              this.campaignService.getAudienceTargeting(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['compositeAudienceTargetingRule'] !== 'undefined' && responseExtra['compositeAudienceTargetingRule'] !== null) {
                    this.selectedAudienceGroup = 0;
                    this.reverseAudiencesFullfillment(responseExtra['compositeAudienceTargetingRule']);
                    this.loadingVars.audiences = true;
                    setTimeout(() => {
                      this.newAudiencesForm = this.fb.group({
                        compositeAudienceTargetingRule: this.buildForm.buildForm(responseExtra['compositeAudienceTargetingRule'])
                      });
                      this.loadingVars.audiences = false;
                    }, 8000)
                   }
                },
                (error) => { console.log(error, 'getAudienceTargeting'); }
              );
              this.campaignService.getBidMultipliers(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined') {
                    if (typeof responseExtra['maxAdjustedBidInCpm'] !== 'undefined' && responseExtra['maxAdjustedBidInCpm'] !== '') {
                      this.useBidMultipliers = true;
                      this.newCampaignForm.controls.maxBid.enable();
                      this.newCampaignForm.controls.maxBid.setValue(parseFloat(responseExtra['maxAdjustedBidInCpm']));
                      this.newCampaignForm.controls.maxBid.setValidators(Validators.required);
                      this.newCampaignForm.controls.maxBid.setValidators(stringNumberMinValidator(0.10));
                      this.newCampaignForm.controls.maxBid.updateValueAndValidity();
                      if (typeof responseExtra['bidMultiplierRules'] !== 'undefined' && responseExtra['bidMultiplierRules'].length > 0) {
                        this.impressionsMultipliersData = [];
                        for (const imp of responseExtra['bidMultiplierRules']) {
                          if (imp.target === 'FREQUENCY_CAPPING') {
                            const impression = imp.fromImpression + 1;
                            this.impressionsMultipliersData.push({
                              impression: (impression === 1) ? '1st' : (impression === 2 ? ('2nd') :
                                (impression === 3 ? '3rd' : impression + 'th')),
                              multiplier: imp.multiplier
                            })
                          } else if (imp.target === 'TRAFFIC_TYPE') {
                            switch (imp.trafficType) {
                              case 'MOBILE_OPTIMIZED_WEB':
                                this.mobileOptimizedWebBid = String(imp.multiplier);
                                break;
                              case 'MOBILE_WEB':
                                this.mobileWebBid = String(imp.multiplier);
                                break;
                              case 'MOBILE_APP':
                                this.mobileAppBid = String(imp.multiplier);
                                break;
                              case 'WEB':
                                this.webBid = String(imp.multiplier);
                                break;
                            }
                          }
                        }
                      }
                    }
                  }
                },
                (error) => { console.log(error, 'getAudienceTargeting'); }
              );

              this.campaignService.getOnlyValidTracks(this.campaignId).subscribe(
                (responseExtra) => {
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] === 'IFA') {
                    this.onlyTrackableDevices = true;
                  }
                },
                (error) => { console.log(error, 'getAudienceTargeting'); }
              );

              this.campaignService.getDeals(this.campaignId).subscribe(
                (responseExtra) => {
                  if (typeof responseExtra['deals'] !== 'undefined' && responseExtra['deals'].length > 0) {
                    for (const deal of responseExtra['deals']) {
                      (this.newCampaignForm.get('deals') as FormArray).push(this.fb.group({assetId: [deal.assetId]}));
                    }
                    this.marketplaceModal.setGridSelected(responseExtra['deals'].map(i => i.assetId));
                    this.rowDataDeals = new Observable((ob: Observer<Array<any>>) => {
                      ob.next(responseExtra['deals']);
                      ob.complete();
                    });
                    this.deals = this.showDeals = true;
                  }
                },
                (error) => { console.log(error, 'getAudienceTargeting'); }
              );

              const domainsSelected = this.campaignService.getInventoryUnitsData(this.campaignId);
              const exchangesSelected = this.campaignService.getExchangesData(this.campaignId);
              const domainListSelected = this.campaignService.getDomainListData(this.campaignId);
              this.selectInventoryModal.fromEdit = true;
              forkJoin([domainsSelected, exchangesSelected, domainListSelected]).subscribe(
                (results) => {
                  if (typeof results[0] !== 'undefined' && typeof results[0]['inventoryUnitTargets'] !== 'undefined') {
                    this.inventoryUnitsSelected = typeof response.campaign.enabledRON !== 'undefined' && response.campaign.enabledRON === false ? results[0]['inventoryUnitTargets'] : [];
                    this.selectInventoryModal.setInvType(this.inventoryUnitsSelected.length > 0 ? 'custom' : 'all');
                    this.selectInventoryModal.domainsSelected = [];
                    // @ts-ignore
                    this.selectInventoryModal.domainListSelected = (typeof results[2] !== 'undefined' && results[2] !== null) ? results[2] : [];
                    this.selectInventoryModal.exchangesSelected = (typeof results[1] !== 'undefined' && results[1] !== null && !results[1]['enableROP'] && typeof results[1]['targets'] !== 'undefined' && results[1]['targets'] !== null)
                      ? results[1]['targets'].map(i => ({name: i.exchangeName, exchangeId: i.exchangeId})) : [];
                    this.selectInventoryModal.setDomainListType(this.selectInventoryModal.domainListSelected.length > 0 ? 'custom' : 'none');
                    this.selectInventoryModal.setExchangeType(this.selectInventoryModal.exchangesSelected.length > 0 ? 'custom' : 'all');
                  }
                  this.addInventorySelected({
                    domainsSelected: (typeof results[0] !== 'undefined' && typeof results[0]['inventoryUnitTargets'] !== 'undefined' && false) ? results[0]['inventoryUnitTargets'] : [],
                    exchangesSelected: (typeof results[1] !== 'undefined' && results[1] !== null && !results[1]['enableROP'] && typeof results[1]['targets'] !== 'undefined' && results[1]['targets'] !== null && !results[1]['enableROP'])
                      ? results[1]['targets'].map(i => ({name: i.exchangeName, exchangeId: i.exchangeId})) : [],
                    domainListSelected: (typeof results[2] !== 'undefined' && results[2] !== null) ? results[2] : [],
                  });
                  this.loadingVars.fork = true;
                }
              )
              this.campaignService.getCreativesData(this.campaignId).subscribe(
                (responseExtra) => {
                  this.ngLoadingLinkedAds = true;
                  // tslint:disable-next-line:max-line-length
                  if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['results'] !== 'undefined') {
                    this.campaignService.getSchedulingData(this.campaignId).subscribe(
                      (responseExtra2) => {
                        // tslint:disable-next-line:max-line-length
                        if (typeof responseExtra2['creativeSchedules'] !== 'undefined') {
                          for (const indexCreative in responseExtra['results']) {
                            if (responseExtra['results'][indexCreative]['linked']) {
                              responseExtra['results'][indexCreative]['flightDates'] = [];
                              // tslint:disable-next-line:max-line-length
                              const scheduleDates = responseExtra2['creativeSchedules'].find((item) => item.creativeId === responseExtra['results'][indexCreative]['creativeId']);
                              if (typeof scheduleDates !== 'undefined' && scheduleDates !== null && typeof scheduleDates['schedules'] !== 'undefined' && scheduleDates['schedules'].length > 0) {
                                for (const date of scheduleDates['schedules']) {
                                  responseExtra['results'][indexCreative]['flightDates'].push(
                                    typeof date.flightDates.to !== 'undefined' && date.flightDates.to !== null && date.flightDates.to !== ''
                                    ? {begin: moment(date.flightDates.from, 'YYYYMMDD HH:mm:ss'), end: moment(date.flightDates.to, 'YYYYMMDD HH:mm:ss')}
                                    : {begin: moment(date.flightDates.from, 'YYYYMMDD HH:mm:ss')}
                                  );
                                }
                              }
                            }
                          }
                          this.adsToLink(responseExtra['results'].filter(elem => elem.linked));
                        } else {
                          this.adsToLink(responseExtra['results'].filter(elem => elem.linked));
                        }
                        this.loadingVars.creatives = true;
                        setTimeout(() => {
                          this.linkedAdsGrid.resetRowHeights();
                          this.ngLoadingLinkedAds = false;
                        }, 2000);
                      },
                      (error) => { console.log(error, 'getSchedulingData'); this.adsToLink(responseExtra['results']); this.ngLoadingLinkedAds = true; this.loadingVars.creatives = true; }
                    );
                  } else {
                    this.loadingVars.creatives = true;
                  }
                },
                (error) => { console.log(error, 'getCreativesData'); }
              );
            } else {
              this.setCarriersData();
            }
            this.ngLoading = false;
          },
          (error) => {
            console.log( error, 'getCampaignById' );
            this.setCarriersData();
          }
        )
      } else {
        this.route.queryParams
          .subscribe(paramsQ => {
              if (typeof paramsQ.copyId !== 'undefined' && !isNaN(paramsQ.copyId)) {
                this.loadingVars = {fork: false, creatives: false, audiences: false};
                this.ngLoading = true;
                const currentUnit = localStorage.getItem('hyperlocalRadiusUnits_' + paramsQ.copyId);
                this.hyperlocalRadiusUnits = (typeof currentUnit !== 'undefined' && currentUnit !== null && currentUnit !== '')
                  ? currentUnit : 'km';
                this.getAudiences();
                this.campaignService.getCampaignById(paramsQ.copyId, this.brandId).subscribe(
                  (response) => {
                    // @ts-ignore
                    if (response.status === 200) {
                      this.setCampaignDataToForm(
                        response,
                        (typeof paramsQ.newName !== 'undefined' ? paramsQ.newName : '')
                      );
                    } else {
                      this.setCarriersData();
                    }
                    this.ngLoading = false;
                  },
                  (error) => {
                    console.log( error, 'getCampaignById' );
                    this.setCarriersData();
                  }
                )
              } else {
                this.setCarriersData();
              }
            }
        );
        this.brandService.getBrandClient(this.brandId).subscribe(
          (response) => {
              this.margin = (response.data.margin > 1) ? ((100 - response.data.margin) / 100) : 1;
              if (typeof this.campaignGroup !== 'undefined' && this.campaignGroup !== null) {
                this.campaignGroup.budget.amount = this.campaignGroup.budget.amount / this.margin;
              }
            },
          (error) => { console.log(error, 'getBrandClient');
        });
        this.breadcrumbsService.breadcrumbs = [
          {label: 'My Brands', url: '/mybrands', params: []},
          {label: 'Campaigns', url: '/campaigns/' + params['brandId'] + '/' + params['groupId'], params: []},
          {label: 'New Campaign', url: '/campaigns/' + params['brandId'] + '/' + params['groupId'] + '/new-campaign/' + this.campaignType, params: []},
        ];
      }
      this.linkAdsCampaignModal.brandId = this.brandId;
      this.campaignList += this.brandId + '/' + this.groupId;
    });
  }

  setCampaignDataToForm(campaignResponse: any, newName = '') {
    const response = campaignResponse;
    const campaignId = response.campaign.campaignId;
    if (response.status === 200) {
      this.margin = (response.margin > 1) ? ((100 - response.margin) / 100) : 1;
      if (this.campaignGroup !== null && typeof this.campaignGroup.budget !== 'undefined' && typeof this.campaignGroup.budget.amount !== 'undefined') {
        this.campaignGroup.budget.amount = this.campaignGroup.budget.amount / this.margin;
      }
      this.newCampaignForm.controls.name.setValue((newName !== '') ? newName : response.campaign.name);
      this.newCampaignForm.controls.status.setValue(response.campaign.status);
      this.newCampaignForm.controls.defaultBid.setValue((response.campaign.defaultBid / this.margin).toFixed(2));
      this.newCampaignForm.controls.bidShading.setValue(response.campaign.bidShading);
      if (typeof response.campaign.maxBid !== 'undefined') {
        this.useBidMultipliers = true;
        this.newCampaignForm.controls.maxBid.setValue(response.campaign.maxBid.toFixed(2));
      }
      this.newCampaignForm.controls.enableCrossDevice.setValue(response.campaign.enableCrossDevice);
      if (typeof response.campaign.frequencyCapping !== 'undefined') {
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.frequencyCapping['controls'].impressions.setValue(response.campaign.frequencyCapping.impressions);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.frequencyCapping['controls'].periodInHours.setValue(response.campaign.frequencyCapping.periodInHours);
        this.newCampaignForm.controls.frequencyCapping['controls'].type.setValue(response.campaign.frequencyCapping.type);
        this.frequencyCap = 'on';
      }
      if (typeof response.campaign.flightDates !== 'undefined') {
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.flightDates['controls'].from.setValue(response.campaign.flightDates.from);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.flightDates['controls'].to.setValue(response.campaign.flightDates.to);
        this.flightDateRangeType = 'range';
        this.budgetDateRange = {
          begin: moment(response.campaign.flightDates.from, 'YYYYMMDD'),
          end: moment(response.campaign.flightDates.to, 'YYYYMMDD')
        };
      }
      this.newCampaignForm.controls.budget['controls'].amount.setValue((response.campaign.budget.amount / this.margin).toFixed(2));
      this.newCampaignForm.controls.budget['controls'].evenDeliveryEnabled.setValue(response.campaign.budget.evenDeliveryEnabled);
      if (typeof response.campaign.budget.evenDeliveryEnabled !== 'undefined'
        && response.campaign.budget.evenDeliveryEnabled !== null
        && response.campaign.budget.evenDeliveryEnabled) {
        this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue('1');
      } else {
        this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue('0');
        // this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue(String(response.campaign.budget.pacingPriority));
      }
      // this.newCampaignForm.controls.budget['controls'].pacingPriority.setValue(String(response.campaign.budget.pacingPriority));
      this.newCampaignForm.controls.budget['controls'].type.setValue(response.campaign.budget.type);
      if (typeof response.campaign.budget.schedule !== 'undefined') {
        if (
          typeof response.campaign.budget.schedule.daypartingRules !== 'undefined'
          && response.campaign.budget.schedule.daypartingRules.length > 0
        ) {
          this.dayPartingType = 'range';
          const dayRulesGrid = [];
          for (const dayRule of response.campaign.budget.schedule.daypartingRules) {
            // (this.newCampaignForm.controls.budget['controls'].schedule['controls'].daypartingRules as FormArray)
            //   .push(this.buildForm.buildForm(dayRule));
            dayRulesGrid.push({
              from: moment(dayRule.fromTime, 'HH:mm').format('hh:mma'),
              to: moment(dayRule.toTime, 'HH:mm').format('hh:mma'),
              monday: dayRule.daysOfWeek.indexOf('monday') !== -1,
              tuesday: dayRule.daysOfWeek.indexOf('tuesday') !== -1,
              wednesday: dayRule.daysOfWeek.indexOf('wednesday') !== -1,
              thursday: dayRule.daysOfWeek.indexOf('thursday') !== -1,
              friday: dayRule.daysOfWeek.indexOf('friday') !== -1,
              saturday: dayRule.daysOfWeek.indexOf('saturday') !== -1,
              sunday: dayRule.daysOfWeek.indexOf('sunday') !== -1
            });
          }
          setTimeout(() => {const result = this.hoursRulesGrid.updateRowData({add: dayRulesGrid}); this.hoursData = dayRulesGrid; }, 1000);
        }
        if (typeof response.campaign.budget.schedule.isRelativeDayParting !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.budget['controls'].schedule['controls'].isRelativeDayParting.setValue(response.campaign.budget.schedule.isRelativeDayParting);
        }
      }
      let convertionChecked = false;
      if (typeof response.campaign.conversionConfig !== 'undefined') {
        this.trackConverstions = 'on';
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.conversionConfig['controls'].conversionWindowInDays.setValue(response.campaign.conversionConfig.conversionWindowInDays);
        this.newCampaignForm.controls.conversionConfig['controls'].ordered.setValue(response.campaign.conversionConfig.ordered);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.conversionConfig['controls'].uniquenessPolicy.setValue(response.campaign.conversionConfig.uniquenessPolicy.toUpperCase());
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.conversionConfig['controls'].uniquenessPeriodInMillis.setValue(response.campaign.conversionConfig.uniquenessPeriodInMillis / 60000);
        // tslint:disable-next-line:max-line-length
        if (typeof response.campaign.conversionConfig.offers !== 'undefined' && response.campaign.conversionConfig.offers.length > 0) {
          this.getConversions(response.campaign.conversionConfig.offers);
          convertionChecked = true;
        }
      }
      if (typeof response.campaign.viewthruConfig !== 'undefined') {
        this.trackConverstions = 'on';
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.viewthruConfig['controls'].conversionWindowInDays.setValue(response.campaign.viewthruConfig.conversionWindowInDays);
        this.newCampaignForm.controls.viewthruConfig['controls'].payoutRate.setValue(response.campaign.viewthruConfig.payoutRate * 100);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.viewthruConfig['controls'].deduplicationEnabled.setValue(response.campaign.viewthruConfig.deduplicationEnabled);
        if (typeof response.campaign.viewthruConfig.offers !== 'undefined' && response.campaign.viewthruConfig.offers.length > 0 && !convertionChecked) {
          this.getConversions(response.campaign.viewthruConfig.offers);
        }
      }
      this.newCampaignForm.controls.enablePixalateViewability.setValue(response.campaign.enablePixalateViewability);
      this.newCampaignForm.controls.isRelativeDayParting.setValue(response.campaign.isRelativeDayParting);
      this.newCampaignForm.controls.notes.setValue(response.campaign.notes);
      if (typeof response.campaign.videoConfig !== 'undefined') {
        this.videoConfig = true;
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].blockUnknownPlayerDimensions.setValue(response.campaign.videoConfig.blockUnknownPlayerDimensions);
        if (typeof response.campaign.videoConfig.minPlayerWidth !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].minPlayerWidth.setValue(response.campaign.videoConfig.minPlayerWidth);
        }
        if (typeof response.campaign.videoConfig.minPlayerHeight !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].minPlayerHeight.setValue(response.campaign.videoConfig.minPlayerHeight);
        }
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.REQUIRED.setValue(response.campaign.videoConfig.videoSkippability.indexOf('REQUIRED') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.BLOCKED.setValue(response.campaign.videoConfig.videoSkippability.indexOf('BLOCKED') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.UNKNOWN.setValue(response.campaign.videoConfig.videoSkippability.indexOf('UNKNOWN') !== -1);

        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM.setValue(response.campaign.videoConfig.placementTypes.indexOf('IN_STREAM') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.OUT_STREAM.setValue(response.campaign.videoConfig.placementTypes.indexOf('OUT_STREAM') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.UNKNOWN.setValue(response.campaign.videoConfig.placementTypes.indexOf('UNKNOWN') !== -1);
        // tslint:disable-next-line:max-line-length
        // this.newCampaignForm.controls.videoConfig['controls'].deliveryTypes.controls.BOTH.setValue(response.campaign.videoConfig.deliveryTypes.indexOf('BOTH') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_ON.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_ON') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_OFF.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('AUTOMATIC_SOUND_OFF') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_CLICK.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_CLICK') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_HOVER.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('ON_HOVER') !== -1);
        // tslint:disable-next-line:max-line-length
        this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.UNKNOWN.setValue(response.campaign.videoConfig.videoPlaybackModes.indexOf('UNKNOWN') !== -1);

        if (typeof response.campaign.videoConfig.startDelays !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('PRE_ROLL') !== -1);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('MID_ROLL') !== -1);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.setValue(response.campaign.videoConfig.startDelays.indexOf('POST_ROLL') !== -1);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.setValue(response.campaign.videoConfig.startDelays.indexOf('UNKNOWN') !== -1);
        } else {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.setValue(false);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.setValue(false);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.setValue(false);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.setValue(false);
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.disable();
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.disable();
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.disable();
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.disable();
        }
      }
      if (typeof response.campaign.audienceCapture !== 'undefined') {
        this.audienceCapture = true;
        this.newCampaignForm.controls.audienceCapture['controls'].clickAudience.enable();
        this.newCampaignForm.controls.audienceCapture['controls'].conversionAudience.enable();
        if (typeof response.campaign.audienceCapture.clickAudience !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.audienceCapture['controls'].clickAudience.setValue(response.campaign.audienceCapture.clickAudience);
          this.audienceCaptureClick = true;
        }
        if (typeof response.campaign.audienceCapture.conversionAudience !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.audienceCapture['controls'].conversionAudience.setValue(response.campaign.audienceCapture.conversionAudience);
          this.audienceCaptureConv = true;
        }
        if (typeof response.campaign.audienceCapture.landingPageClickAudience !== 'undefined') {
          // tslint:disable-next-line:max-line-length
          this.newCampaignForm.controls.audienceCapture['controls'].landingPageClickAudience.setValue(response.campaign.audienceCapture.landingPageClickAudience);
        }
      }
      this.campaignService.getAlgorithmicOptimization(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            if (responseExtra['enabled']) {
              this.optimizationType = 'algorithmic';
              this.advanceOptSettings = true;
              this.newCampaignForm.controls.algorithmicOptimization['controls'].optimizationMetric.setValue(responseExtra['optimizationMetric']);
              switch (responseExtra['optimizationMetric']) {
                case 'CTR':
                  responseExtra['goalValue'] = responseExtra['goalValue'] * 100;
                  break;
                case 'VCR':
                  responseExtra['goalValue'] = responseExtra['goalValue'] * 100;
                  break;
              }
              this.newCampaignForm.controls.algorithmicOptimization['controls'].goalValue.setValue(responseExtra['goalValue']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].maxBid.setValue(responseExtra['maxBid']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].minBid.setValue(responseExtra['minBid']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].learnBudget.setValue(responseExtra['learnBudget']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].stepOfBidAdjustment.setValue(responseExtra['stepOfBidAdjustment']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].thresholdOfImpressions.setValue(responseExtra['thresholdOfImpressions']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].optimizeDomainLevel.setValue(responseExtra['optimizeDomainLevel']);
              this.newCampaignForm.controls.algorithmicOptimization['controls'].turnOffPlacementsOptAtMinBid.setValue(responseExtra['turnOffPlacementsOptAtMinBid']);
            }
          }
        },
        (error) => { console.log(error, 'getAlgorithmicOptimization'); }
      );
      this.campaignService.getMachineLearningOptimization(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] !== null) {
            const last = (responseExtra as Array<any>).length - 1;
            if (last >= 0 && responseExtra[last].active) {
              this.optimizationType = 'machineLearning';
              this.newCampaignForm.controls.machineLearningOptimization['controls'].optimizationMetric.setValue(responseExtra[last]['optimizationMetric']);
              this.newCampaignForm.controls.machineLearningOptimization['controls'].goalValue.setValue(responseExtra[last]['goalValue'].toFixed(2));
              this.newCampaignForm.controls.machineLearningOptimization['controls'].maxBid.setValue(responseExtra[last]['maxBid'].toFixed(2));
            }
          }
        },
        (error) => { console.log(error, 'getMachineLearningOptimization'); }
      );
      this.campaignService.getPagePositions(campaignId).subscribe(
        (responseExtra: any) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            this.pagePosition = true;
            this.newCampaignForm.controls.pagePositions['controls'].above_the_fold.setValue(responseExtra.indexOf('above_the_fold') !== -1);
            this.newCampaignForm.controls.pagePositions['controls'].below_the_fold.setValue(responseExtra.indexOf('below_the_fold') !== -1);
            this.newCampaignForm.controls.pagePositions['controls'].unknown.setValue(responseExtra.indexOf('unknown') !== -1);
          }
        },
        (error) => { console.log(error, 'getPagePositions'); }
      );
      this.campaignService.getTrafficType(campaignId).subscribe(
        (responseExtra: any) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            this.platformInvType = true;
            this.newCampaignForm.controls.trafficTypes['controls'].mobile_app.setValue(responseExtra.indexOf('mobile_app') !== -1 || responseExtra.indexOf('mobile_app'.toUpperCase()) !== -1);
            this.newCampaignForm.controls.trafficTypes['controls'].mobile_optimized_web.setValue(responseExtra.indexOf('mobile_optimized_web') !== -1 || responseExtra.indexOf('mobile_optimized_web'.toUpperCase()) !== -1);
            this.newCampaignForm.controls.trafficTypes['controls'].mobile_web.setValue(responseExtra.indexOf('mobile_web') !== -1 || responseExtra.indexOf('mobile_web'.toUpperCase()) !== -1);
            this.newCampaignForm.controls.trafficTypes['controls'].web.setValue(responseExtra.indexOf('web') !== -1 || responseExtra.indexOf('WEB') !== -1);
            // this.newCampaignForm.controls.trafficTypes['controls'].other.setValue(responseExtra.indexOf('other') !== -1 || responseExtra.indexOf('OTHER') !== -1);
          }
        },
        (error) => { console.log(error, 'getTrafficType'); }
      );
      this.campaignService.getGeoTargeting(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            const toAdd = [];
            for (const target of responseExtra['results']) {
              if (typeof target.dmaCode !== 'undefined') {
                const dmaData = this.dmaCodes.find((elem) => elem.dmaCode === target.dmaCode);
                toAdd.push({ location: 'Country: United States, DMA Code:  ' + dmaData.dmaCode + ' - ' + dmaData.dmaName, type: 'zip', codes: JSON.stringify({countryCode: 'US', blocked: target.blocked, dmaCode: dmaData.dmaCode}), status: (target.blocked) ? 'Excludes' : 'Targeted' });
              } else if (typeof target.postalCode !== 'undefined') {
                toAdd.push({ location: 'Country: ' + target.countryName + ',  Zip/Postal Code: ' + target.postalCode, type: 'zip', codes: JSON.stringify({countryCode: target.countryCode, blocked: target.blocked, postalCode: target.postalCode}), status: (target.blocked) ? 'Excludes' : 'Targeted' })
              } else {
                const locationString = (typeof target.city !== 'undefined')
                  ? (target.city + ' (City), ' + target.primaryRegionName) : ((typeof target.primaryRegionCode !== 'undefined')
                    ? (target.primaryRegionName + ' (Region), ' + target.countryName) : (target.countryName + ' (Country)'));
                toAdd.push({type: 'locations', codes: JSON.stringify(target), location: locationString, status: (target.blocked) ? 'Excludes' : 'Targeted'});
              }
            }
            const res = this.locationsGrid.updateRowData({ add: toAdd });
          }
        },
        (error) => { console.log(error, 'getGeoTargeting'); }
      );
      this.campaignService.getContextualTargeting(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['compositeContextualRule'] !== 'undefined' && responseExtra['compositeContextualRule'] !== null) {
            this.reverseContextualFullfillment(responseExtra['compositeContextualRule']);
          }
        },
        (error) => { console.log(error, 'getContextualTargeting'); }
      );
      this.campaignService.getHyperlocalTargeting(campaignId).subscribe(
        (responseExtra: any) => {
          // tslint:disable-next-line:max-line-length
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] !== null) {
            // this.hyperlocalRadiusUnits = responseExtra[0]['unit'] === 'meters'  ? 'm' : 'ft';
            for (const loc of responseExtra) {
              const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
              if (this.hyperlocalRadiusUnits === 'km' && loc.unit === 'meters') {
                loc.distance = parseFloat(loc.distance) / 1000;
              } else if (this.hyperlocalRadiusUnits === 'ml' && loc.unit === 'meters') {
                loc.distance = parseFloat(loc.distance) / 1609.34;
              } else if (this.hyperlocalRadiusUnits === 'km' && loc.unit === 'feet') {
                loc.distance = parseFloat(loc.distance) / 3280.84;
              } else if (this.hyperlocalRadiusUnits === 'ft') {
                loc.distance = parseFloat(loc.distance);
              }
              const location = {
                status: 'Yes',
                zone: zone,
                address: (typeof loc.label !== 'undefined') ? loc.label : '',
                lat: loc.latitude,
                lng: loc.longitude,
                radius: loc.distance,
                target: (!loc.blocked) ? 'Targeted' : 'Exclude',
              };
              // const res = this.hyperlocalGrid.updateRowData({add: [location]});
              this.hyperlocalSelectedData.push(location);
              this.mapClick(loc.latitude, loc.longitude, zone);
            }
            if (this.hyperlocalSelectedData.length > 0) {
              this.hyperlocal = this.showHyperlocal = true;
              // this.hyperlocalRadiusUnits = responseExtra[0]['unit'] === 'feet' ? 'ft' : 'm';
            }
          }
        },
        (error) => { console.log(error, 'getHyperlocalTargeting'); }
      );
      this.campaignService.getDevicesTargeting(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            if (typeof responseExtra['deviceTypes'] !== 'undefined' && (responseExtra['deviceTypes'] as Array<string>).length > 0) {
              this.deviceType = true;
              for (const type of responseExtra['deviceTypes']) {
                this.deviceType = true;
                (this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes as FormArray).push(this.fb.control(type));
                if (type === 'DESKTOP') {
                  this.deviceTypeDesktop = true;
                } else if (type === 'PHONE') {
                  this.deviceTypePhone = true;
                } else if (type === 'TABLET') {
                  this.deviceTypeTablet = true;
                } else if (type === 'CONNECTED_TV') {
                  this.deviceTypeTV = true;
                }
              }
            }
            if (typeof responseExtra['hardwares'] !== 'undefined' && (responseExtra['hardwares'] as Array<any>).length > 0) {
              this.deviceMake = true;
              this.deviceModel = true;
              const allModels = [];
              for (const type of responseExtra['hardwares']) {
                const models = [];
                if (typeof type.models !== 'undefined') {
                  for (const model of type.models) {
                    models.push({make: type.make, model: model});
                    allModels.push({make: type.make, model: model});
                  }
                }
                (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).push(this.fb.group({
                  make: [type.make],
                  models: this.fb.array(models.map(i => i.model))
                }));
                this.selectedDeviceMakes.push({make: type.make, models: models});
                this.selectedDeviceModels = allModels;
                this.addModelsByMake(type.make);
              }
              // @ts-ignore
              // this.deviceMakesChanged(this.selectedDeviceMakes);
              // if (models.length > 0) {
              //   // @ts-ignore
              //   this.deviceModelsChanged(models);
              // }
            }
            if (typeof responseExtra['softwares'] !== 'undefined' && (responseExtra['softwares'] as Array<any>).length > 0) {
              (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).removeAt(0);
              this.deviceOSVersion = true;
              for (const soft of responseExtra['softwares']) {
                let osVersionTargets: FormArray = null;
                if (typeof soft.osVersionTargets !== 'undefined' && soft.osVersionTargets.length > 0) {
                  osVersionTargets = this.fb.array([
                    this.fb.group({
                      from: this.fb.group({
                        major: [(typeof soft.osVersionTargets[0] !== 'undefined'
                          && typeof soft.osVersionTargets[0].from !== 'undefined'
                          && typeof soft.osVersionTargets[0].from.major !== 'undefined')
                          ? soft.osVersionTargets[0].from.major :
                          ''],
                        minor: [(typeof soft.osVersionTargets[0] !== 'undefined'
                          && typeof soft.osVersionTargets[0].from !== 'undefined'
                          && typeof soft.osVersionTargets[0].from.minor !== 'undefined')
                          ? soft.osVersionTargets[0].from.minor :
                          '']
                      }),
                      to: this.fb.group({
                        major: [(typeof soft.osVersionTargets[0] !== 'undefined'
                          && typeof soft.osVersionTargets[0].to !== 'undefined'
                          && typeof soft.osVersionTargets[0].to.major !== 'undefined')
                          ? soft.osVersionTargets[0].to.major :
                          ''],
                        minor: [(typeof soft.osVersionTargets[0] !== 'undefined'
                          && typeof soft.osVersionTargets[0].to !== 'undefined'
                          && typeof soft.osVersionTargets[0].to.minor !== 'undefined')
                          ? soft.osVersionTargets[0].to.minor :
                          '']
                      }),
                    })
                  ]);
                }
                (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).push(this.fb.group({
                  name: [soft.name],
                  osVersionTargets: osVersionTargets
                }));
              }
            }
            if (typeof responseExtra['browsers'] !== 'undefined' && (responseExtra['browsers'] as Array<any>).length > 0) {
              this.deviceBrowser = true;
              this.deviceBrowserSelected = responseExtra['browsers'];
              for (const brow of responseExtra['browsers']) {
                (this.newDevicesForm.controls.deviceTargeting.controls.browsers as FormArray).push(this.fb.control(brow));
              }
            }
            if (typeof responseExtra['languages'] !== 'undefined' && (responseExtra['languages'] as Array<any>).length > 0) {
              this.deviceBrowserLang = true;
              for (const lang of responseExtra['languages']) {
                (this.newDevicesForm.controls.deviceTargeting.controls.languages as FormArray).push(this.fb.control(lang));
                this.deviceBrowserLangSelected.push(this.browserLanguages.find((item) => item.code === lang).code);
              }
            }
            // tslint:disable-next-line:max-line-length
            if (typeof responseExtra['connection'] !== 'undefined' && typeof responseExtra['connection']['carrierCodes'] !== 'undefined' && (responseExtra['connection']['carrierCodes'] as Array<any>).length > 0) {
              this.mobileCarriers = true;
              this.mobileCarriersType = 'custom';
              this.setCarriersData(responseExtra['connection']['carrierCodes']);
            } else {
              this.setCarriersData();
            }
            if (typeof responseExtra['connection'] !== 'undefined' && typeof responseExtra['connection']['connectionTypes'] !== 'undefined' && (responseExtra['connection']['connectionTypes'] as Array<any>).length > 0) {
              this.mobileConnectionType = true;
              this.wifiEnabled = responseExtra['connection']['connectionTypes'].indexOf('WIFI') >= 0;
              this.mobileDataEnabled = responseExtra['connection']['connectionTypes'].indexOf('CELLULAR_DATA') >= 0;
              this.unknownConnectionEnabled = responseExtra['connection']['connectionTypes'].indexOf('UNKNOWN') >= 0;
            }
          } else {
            this.setCarriersData();
          }
        },
        (error) => { console.log(error, 'getDevicesTargeting'); this.setCarriersData(); }
      );
      this.campaignService.getAudienceTargeting(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['compositeAudienceTargetingRule'] !== 'undefined' && responseExtra['compositeAudienceTargetingRule'] !== null) {
            this.selectedAudienceGroup = 0;
            this.reverseAudiencesFullfillment(responseExtra['compositeAudienceTargetingRule']);
            this.loadingVars.audiences = true;
            setTimeout(() => {
              this.newAudiencesForm = this.fb.group({
                compositeAudienceTargetingRule: this.buildForm.buildForm(responseExtra['compositeAudienceTargetingRule'])
              });
              this.loadingVars.audiences = false;
            }, 8000)
          }
        },
        (error) => { console.log(error, 'getAudienceTargeting'); }
      );
      this.campaignService.getBidMultipliers(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined') {
            if (typeof responseExtra['maxAdjustedBidInCpm'] !== 'undefined' && responseExtra['maxAdjustedBidInCpm'] !== '') {
              this.useBidMultipliers = true;
              this.newCampaignForm.controls.maxBid.enable();
              this.newCampaignForm.controls.maxBid.setValue(parseFloat(responseExtra['maxAdjustedBidInCpm']));
              this.newCampaignForm.controls.maxBid.setValidators(Validators.required);
              this.newCampaignForm.controls.maxBid.setValidators(stringNumberMinValidator(0.10));
              this.newCampaignForm.controls.maxBid.updateValueAndValidity();
              if (typeof responseExtra['bidMultiplierRules'] !== 'undefined' && responseExtra['bidMultiplierRules'].length > 0) {
                this.impressionsMultipliersData = [];
                for (const imp of responseExtra['bidMultiplierRules']) {
                  if (imp.target === 'FREQUENCY_CAPPING') {
                    const impression = imp.fromImpression + 1;
                    this.impressionsMultipliersData.push({
                      impression: (impression === 1) ? '1st' : (impression === 2 ? ('2nd') :
                        (impression === 3 ? '3rd' : impression + 'th')),
                      multiplier: imp.multiplier
                    })
                  } else if (imp.target === 'TRAFFIC_TYPE') {
                    switch (imp.trafficType) {
                      case 'MOBILE_OPTIMIZED_WEB':
                        this.mobileOptimizedWebBid = String(imp.multiplier);
                        break;
                      case 'MOBILE_WEB':
                        this.mobileWebBid = String(imp.multiplier);
                        break;
                      case 'MOBILE_APP':
                        this.mobileAppBid = String(imp.multiplier);
                        break;
                      case 'WEB':
                        this.webBid = String(imp.multiplier);
                        break;
                    }
                  }
                }
              }
            }
          }
        },
        (error) => { console.log(error, 'getAudienceTargeting'); }
      );

      this.campaignService.getOnlyValidTracks(campaignId).subscribe(
        (responseExtra) => {
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra[0] !== 'undefined' && responseExtra[0] === 'IFA') {
            this.onlyTrackableDevices = true;
          }
        },
        (error) => { console.log(error, 'getAudienceTargeting'); }
      );

      const domainsSelected = this.campaignService.getInventoryUnitsData(campaignId);
      const exchangesSelected = this.campaignService.getExchangesData(campaignId);
      const domainListSelected = this.campaignService.getDomainListData(campaignId);
      forkJoin([domainsSelected, exchangesSelected, domainListSelected]).subscribe(
        (results) => {
          if (typeof results[0] !== 'undefined' && typeof results[0]['inventoryUnitTargets'] !== 'undefined') {
            this.inventoryUnitsSelected = typeof response.campaign.enabledRON !== 'undefined' && response.campaign.enabledRON === false ? results[0]['inventoryUnitTargets'] : [];
            this.selectInventoryModal.setInvType(this.inventoryUnitsSelected.length > 0 ? 'custom' : 'all');
            this.selectInventoryModal.domainsSelected = [];
            // @ts-ignore
            this.selectInventoryModal.domainListSelected = (typeof results[2] !== 'undefined' && results[2] !== null) ? results[2] : [];
            this.selectInventoryModal.exchangesSelected = (typeof results[1] !== 'undefined' && results[1] !== null && !results[1]['enableROP'] && typeof results[1]['targets'] !== 'undefined' && results[1]['targets'] !== null)
              ? results[1]['targets'].map(i => ({name: i.exchangeName, exchangeId: i.exchangeId})) : [];
            this.selectInventoryModal.setDomainListType(this.selectInventoryModal.domainListSelected.length > 0 ? 'custom' : 'none');
            this.selectInventoryModal.setExchangeType(this.selectInventoryModal.exchangesSelected.length > 0 ? 'custom' : 'all');
          }
          this.addInventorySelected({
            domainsSelected: (typeof results[0] !== 'undefined' && typeof results[0]['inventoryUnitTargets'] !== 'undefined' && false) ? results[0]['inventoryUnitTargets'] : [],
            exchangesSelected: (typeof results[1] !== 'undefined' && results[1] !== null && !results[1]['enableROP'] && typeof results[1]['targets'] !== 'undefined' && results[1]['targets'] !== null)
              ? results[1]['targets'].map(i => ({name: i.exchangeName, exchangeId: i.exchangeId})) : [],
            domainListSelected: (typeof results[2] !== 'undefined' && results[2] !== null) ? results[2] : [],
          });
          this.loadingVars.fork = true;
        }
      )
      this.campaignService.getCreativesData(campaignId).subscribe(
        (responseExtra) => {
          this.ngLoadingLinkedAds = true;
          // tslint:disable-next-line:max-line-length
          if (responseExtra !== null && typeof responseExtra !== 'undefined' && typeof responseExtra['results'] !== 'undefined') {
            this.campaignService.getSchedulingData(campaignId).subscribe(
              (responseExtra2) => {
                // tslint:disable-next-line:max-line-length
                if (typeof responseExtra2['creativeSchedules'] !== 'undefined') {
                  for (const indexCreative in responseExtra['results']) {
                    if (responseExtra['results'][indexCreative]['linked']) {
                      responseExtra['results'][indexCreative]['flightDates'] = [];
                      // tslint:disable-next-line:max-line-length
                      const scheduleDates = responseExtra2['creativeSchedules'].find((item) => item.creativeId === responseExtra['results'][indexCreative]['creativeId']);
                      if (typeof scheduleDates !== 'undefined' && scheduleDates !== null && typeof scheduleDates['schedules'] !== 'undefined' && scheduleDates['schedules'].length > 0) {
                        for (const date of scheduleDates['schedules']) {
                          responseExtra['results'][indexCreative]['flightDates'].push(
                            typeof date.flightDates.to !== 'undefined' && date.flightDates.to !== null && date.flightDates.to !== ''
                              ? {begin: moment(date.flightDates.from, 'YYYYMMDD HH:mm:ss'), end: moment(date.flightDates.to, 'YYYYMMDD HH:mm:ss')}
                              : {begin: moment(date.flightDates.from, 'YYYYMMDD HH:mm:ss')}
                          );
                        }
                      }
                    }
                  }
                  this.adsToLink(responseExtra['results'].filter(elem => elem.linked));
                } else {
                  this.adsToLink(responseExtra['results'].filter(elem => elem.linked));
                }
                this.loadingVars.creatives = true;
                setTimeout(() => {
                  this.linkedAdsGrid.resetRowHeights();
                  this.ngLoadingLinkedAds = false;
                }, 2000);
              },
              (error) => { console.log(error, 'getSchedulingData'); this.adsToLink(responseExtra['results']); this.ngLoadingLinkedAds = true; this.loadingVars.creatives = true; }
            );
          } else {
            this.loadingVars.creatives = true;
          }
        },
        (error) => { console.log(error, 'getCreativesData'); }
      );
    }
  }

  addModelsByMake(value) {
    this.thirdSourceService.getMakes(value).subscribe(
      response => {
        if (response.length > 0 && response[0].length > 0) {
          for (const data of response[0]) {
            if (this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes.value.indexOf(data.deviceType) !== -1) {
              this.modelsLocal.push(...data.models.map(i => ({make: data.make, model: i.name})));
            }
          }
        }
      }
    );
  }

  setMakes(value) {
    this.makes = this.thirdSourceService.getMakes(value).pipe(
      tap(() => this.makesLoading = false),
      map(item => {
        const data = [];
        item[0].forEach(i => {
          if (
            (!this.deviceType
              || this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes.value.indexOf(i.deviceType) !== -1)
          ) {
            const pos = data.findIndex(m => m.make === i.make);
            if (pos === -1) {
              data.push({make: i.make, models: i.models.map(k => k.name)});
            } else {
              data[pos].models.push(...i.models.map(k => k.name));
            }
          }
        });
        return new Set(data);
      })
    );
  }

  hideInitialStatus() {
    if (this.currentUser.role !== 'ADMIN') {
      const userCients = this.currentUser.clients.map(i => {
        if (i.status === 0 || i.status === '0') {
            this.hideStatus = true;
            return;
        }
      });
    }
  }

  getFormValidationErrors(form: FormGroup) {

    const result = [];
    Object.keys(form.controls).forEach(key => {

      const controlErrors: ValidationErrors = form.get(key).errors;
      if (form.get(key) instanceof FormGroup) {
        result.push(...this.getFormValidationErrors(form.get(key) as FormGroup));
      }
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            'control': key,
            'error': keyError,
            'value': controlErrors[keyError]
          });
        });
      }
    });
    // console.log('getFormValidationErrors: ', result);
    return result;
  }

  setCarriersData(selected = []) {
    for (const carrier of carriersData) {
      if (selected.indexOf(carrier.mccMnc[0].mcc + '-' + carrier.mccMnc[0].mnc) !== -1) {
        this.targetCarriersGridData.push({
          id: carrier.mccMnc[0].mcc + '-' + carrier.mccMnc[0].mnc,
          carrier: carrier.operator + ', Country: ' + carrier.countryCode
        });
      } else {
        this.carriersGridData.push({
          id: carrier.mccMnc[0].mcc + '-' + carrier.mccMnc[0].mnc,
          carrier: carrier.operator + ', Country: ' + carrier.countryCode
        });
      }
    }
  }

  openSelectInventoryModal() {
    this.selectInventoryModal.domainListGridSetSelection();
    this.selectInventoryModal.exchangesGridSetSelection();
    this.selectInventoryModal.domainsGridSetSelection();
    $('.select-inventory-modal').modal('show');
  }

  private openLinkAdsModal() {
    if (typeof this.linkedAdsGrid !== 'undefined' && this.linkedAdsGrid !== null) {
      const ads = [];
      this.linkedAdsGrid.forEachNode(node => { console.log('node: ', node); typeof node.data !== 'undefined' ? ads.push(node.data) : '' });
      console.log('this.rowDataLinkedAds: ', ads);
      this.linkAdsCampaignModal.setLinkedAds(ads);
    } else {
      console.log('this.rowDataLinkedAds: ', []);
      this.linkAdsCampaignModal.setLinkedAds([]);
    }
    this.linkAdsCampaignModal.getAds();
    $('.link-ads-campaign').modal('show');
  }

  get formControls() {
    return this.newCampaignForm.controls;
  }

  get algorithmicOptControls() {
    // @ts-ignore
    return this.newCampaignForm.controls.algorithmicOptimization.controls;
  }

  get machineLearningOptControls() {
    // @ts-ignore
    return this.newCampaignForm.controls.machineLearningOptimization.controls;
  }

  changeUseBid(event: MatCheckboxChange) {
    if (event.checked) {
      this.newCampaignForm.controls.maxBid.enable();
      this.newCampaignForm.controls.maxBid.setValidators(Validators.required);
      this.newCampaignForm.controls.maxBid.setValidators(stringNumberMinValidator(0.10));
      this.newCampaignForm.controls.maxBid.updateValueAndValidity();
    } else {
      this.newCampaignForm.controls.maxBid.clearValidators();
      this.newCampaignForm.controls.maxBid.disable();
    }
  }

  impressionsChanged(event: Event) {
    this.impressionsMultipliersData = [];
    // @ts-ignore
    for (let i = 0; i < event.target.valueAsNumber; i++) {
      const gridRow = this.gridImpressionsMultiplier.getRowNode(i.toString())
      if (typeof gridRow !== 'undefined') {
        this.impressionsMultipliersData.push(gridRow.data);
      } else {
        this.impressionsMultipliersData.push({ impression: (i + 1) + 'th', multiplier: 1 });
      }
    }
  }

  public impressionsMultiplierGridInit(params) {
    this.gridImpressionsMultiplier = params.api;
  }
  public hoursRulesGridInit(params) {
    this.hoursRulesGrid = params.api;
  }
  public locationsGridInit(params) {
    this.locationsGrid = params.api;
  }

  public convertionsGridInit(params) {
    this.convertionsGrid = params.api;
  }

  public carriersGridInit(params) {
    this.carriersGrid = params.api;
  }

  public hyperlocalGridInit(params) {
    this.hyperlocalGrid = params.api;
  }

  public targetCarriersGridInit(params) {
    this.carriersTargetGrid = params.api;
  }

  public toTrackConvertionsGridInit(params) {
    this.toTrackConvertionsGrid = params.api;
    this.toTrackConvertionsColumnApi = params.columnApi
  }

  addHourDaysRule() {
    this.hoursData.push({ from: '12:00am', to: '11:59pm', monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true });
    const res = this.hoursRulesGrid.updateRowData({add: [{ from: '12:00am', to: '11:59pm', monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true }]});
    this.valueChanged = true;
  }

  deleteHourRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.hoursRulesGrid.updateRowData({ remove: [event.data] });
      this.hoursData.splice(event.rowIndex, 1);
      this.valueChanged = true;
    }
  }

  deleteLocationRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.locationsGrid.updateRowData({ remove: [event.data] });
    }
  }

  deleteTrackConvertionRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res2 = this.convertionsGrid.updateRowData({ add: [event.data] });
      this.trackConverstionGridData.splice(event.rowIndex, 1);
      const res = this.toTrackConvertionsGrid.updateRowData({ remove: [event.data] });
      this.valueChanged = true;
    }
  }

  deleteTargetCarrierRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res2 = this.carriersGrid.updateRowData({ add: [event.data] });
      const res = this.carriersTargetGrid.updateRowData({ remove: [event.data] });
      this.targetCarriersGridData.splice(this.targetCarriersGridData.indexOf((item) => item.id === event.data.id));
      this.valueChanged = true;
    }
  }

  trackConvertionRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.toTrackConvertionsGrid.updateRowData({ add: [event.data] });
      const res2 = this.convertionsGrid.updateRowData({ remove: [event.data] });
      this.valueChanged = true;
    }
  }

  targetCarrierRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.carriersTargetGrid.updateRowData({ add: [event.data] });
      const res2 = this.carriersGrid.updateRowData({ remove: [event.data] });
      this.targetCarriersGridData.push(event.data);
      this.valueChanged = true;
    }
  }

  hyperlocalActions(event: any) {
    if (event.colDef.field === 'actions') {
      if (event.event.target.className === 'ti-trash') {
        const res2 = this.hyperlocalGrid.updateRowData({ remove: [event.data] });
        this.hyperlocalSelectedData.splice(event.rowIndex, 1);
        this.markers.splice((event.rowIndex * 2), 2);
        this.valueChanged = true;
        this.initMap();
      } else {
        const group = L.featureGroup([this.markers[event.rowIndex]])
          .addTo(this.mapHyper);
        // @ts-ignore
        this.mapHyper.fitBounds(group.getBounds());
      }
    }
  }

  algorithmicOptChanged(event: MatSelectChange) {
    switch (event.value) {
      case 'CTR':
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValue('0.05');
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValidators([stringNumberMinValidator(0.01), stringNumberMaxValidator(100)]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.updateValueAndValidity();
        break;
      case 'CPC':
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValue('5.00');
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.setValidators([this.stringNumberMaxCustomValidator('defaultBid')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.setValidators([this.stringNumberMaxCustomValidator('budget.amount')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.updateValueAndValidity();
        break;
      case 'CPA':
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValue('25.00');
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.setValidators([this.stringNumberMaxCustomValidator('defaultBid')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.setValidators([this.stringNumberMaxCustomValidator('budget.amount')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.updateValueAndValidity();
        break;
      case 'CPCV':
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValue('0.100');
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.setValidators([this.stringNumberMaxCustomValidator('defaultBid')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.setValidators([this.stringNumberMaxCustomValidator('budget.amount')]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.updateValueAndValidity();
        break;
      case 'VCR':
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValue('50.00');
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.goalValue.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.minBid.updateValueAndValidity();
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.setValidators([]);
        // @ts-ignore
        this.newCampaignForm.controls.algorithmicOptimization.controls.learnBudget.updateValueAndValidity();
        break;
    }
  }

  machineLearningOptChanged(event: MatSelectChange) {
    switch (event.value) {
      case 'CPC':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('5.00');
        break;
      case 'CPA':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('25.00');
        break;
      case 'CPCV':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('0.100');
        break;
      case 'VCR':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('50.00');
        break;
      case 'VR':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('70');
        break;
      case 'VCPM':
        // @ts-ignore
        this.newCampaignForm.controls.machineLearningOptimization.controls.goalValue.setValue('8.00');
        break;
    }
  }

  selectLocations(locationsModal) {
    // if (this.itemsLocations.length <= 1) {
      this.ngLoading = true;
      this.geoService.getGeographicLocations().subscribe(
        (response) => {
          for (const loc in response[0]) {
            this.itemsLocations.push(new TreeviewItem({
              text: response[0][loc].countryName, value: JSON.stringify(response[0][loc]), checked: false, collapsed: true, children: [
                {text: 'loading', value: JSON.stringify(response[0][loc])}
              ]
            }))
            this.itemsLocations[loc]['level'] = 0;
            this.itemsLocations[loc]['countryCode'] = response[0][loc].countryCode;
            this.itemsLocations[loc]['identifier'] = response[0][loc].geoNameId;
            this.itemsLocations[loc].setCheckedRecursive(false);
            this.itemsLocations[loc].setCollapsedRecursive(true);
          }
          this.itemsLocations.sort(function(a, b) {
            return a.text.localeCompare(b.text);
          });
          this.ngLoading = false;
          this.modalService.open(locationsModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
            // this.addLocationsToGrid(this.valuesLocations, 'locations');
            this.addLocationsToGrid(this.itemsLocations);
            this.selectLocationInputControl.setValue('');
          }, (reason) => {
            // this.addLocationsToGrid(this.valuesLocations, 'locations');
            this.addLocationsToGrid(this.itemsLocations);
            this.selectLocationInputControl.setValue('');
          });
        },
        (error) => {
          this.ngLoading = false;
        }
      )
    // } else {
    //   this.modalService.open(locationsModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
    //     // this.addLocationsToGrid(this.valuesLocations, 'locations');
    //     this.addLocationsToGrid(this.itemsLocations);
    //   }, (reason) => {
    //     // this.addLocationsToGrid(this.valuesLocations, 'locations');
    //     this.addLocationsToGrid(this.itemsLocations);
    //   });
    // }
  }

  getLocationRegions(countryCode: string, treeView: TreeviewItem) {
    this.geoService.getCountryRegions(countryCode).subscribe(
      (response) => {
        treeView.children.shift();
        const checked = treeView.checked
        for (const region in response[0]) {
          treeView.children.push(new TreeviewItem ({
            text: response[0][region].primaryRegionName, value: JSON.stringify(response[0][region]), checked: false, collapsed: true, children: [
              { text: 'loading', value: JSON.stringify(response[0][region]) }
            ]
          }, false))
          treeView.children[region]['level'] = 1;
          treeView.children[region]['identifier'] = response[0][region].geoNameId;
          treeView.children[region]['parent'] = treeView;
          treeView.children[region]['countryCode'] = response[0][region].countryCode;
          treeView.children[region]['regionCode'] = response[0][region].primaryRegionCode;
        }
        treeView.setCheckedRecursive(false);
        treeView.checked = checked;
        // if (!treeView.checked) {
        //   treeView.setCheckedRecursive(false);
        // } else {
        //   treeView.setCheckedRecursive(true);
        // }
        // treeView.correctChecked();
      },
      (error) => { console.log('selectLocations', error); this.ngLoading = false; }
    )
  }

  getRegionCities(countryCode: string, regionCode: string, treeView: TreeviewItem) {
    this.geoService.getRegionCities(countryCode, regionCode).subscribe(
      (response) => {
        treeView.children.shift();
        const checked = treeView.checked
        for (const region in response[0]) {
          treeView.children.push(new TreeviewItem ({
            text: response[0][region].cityName, value: JSON.stringify(response[0][region]), checked: false
          }, false))
          treeView.children[region]['level'] = 2;
          treeView.children[region]['identifier'] = response[0][region].geoNameId;
          treeView.children[region]['parent'] = treeView;
        }
        treeView.setCheckedRecursive(false);
        treeView.checked = checked;
        // if (!treeView.checked) {
        //   treeView.setCheckedRecursive(false);
        // } else {
        //   treeView.setCheckedRecursive(true);
        // }
        // treeView.correctChecked();
      },
      (error) => { console.log('selectLocations', error); this.ngLoading = false; }
    )
  }

  addDmaCodes(type) {
    // if (type !== 'OTHER') {
    //   this.locationsGrid.setRowData([]);
    //   this.addCodes('OTHER');
    //   this.addLocationsToGrid(this.valuesLocations, 'OTHER');
    // }
    if (this.selectedDmaCodes.length > 0) {
      const currentData = this.getGridAllRows(this.locationsGrid).map(i => i.location);
      const locationsAdd = [];
      const locationsUpdate = [];
      for (const code of this.selectedDmaCodes) {
        this.dmaCodesSelector.unselect(code);
        this.dmaCodesSelector.clearItem(code);
        const stringLoc = 'Country: United States, DMA Code:  ' + code.dmaCode + ' - ' + code.dmaName;
        const index = currentData.indexOf(stringLoc);
        if (index === -1) {
          locationsAdd.push({
            location: stringLoc,
            type: 'zip',
            codes: JSON.stringify({countryCode: 'US', blocked: (type === 'EXCLUDES'), dmaCode: code.dmaCode}),
            status: (type === 'EXCLUDES') ? 'Excludes' : 'Targeted',
            id: code.dmaCode + '-' + code.dmaName
          });
        } else {
          locationsUpdate.push({
            location: stringLoc,
            type: 'zip',
            codes: JSON.stringify({countryCode: 'US', blocked: (type === 'EXCLUDES'), dmaCode: code.dmaCode}),
            status: (type === 'EXCLUDES') ? 'Excludes' : 'Targeted',
            id: code.dmaCode + '-' + code.dmaName
          });
        }
      }
      if (locationsAdd.length > 0) {
        const res = this.locationsGrid.updateRowData({add: locationsAdd});
      }
      if (locationsUpdate.length > 0) {
        const res2 = this.locationsGrid.updateRowData({update: locationsUpdate});
      }
      this.valueChanged = true;
    }
  }

  addCodes(codeType: string) {
    // if (codeType !== 'OTHER') {
    //   this.locationsGrid.setRowData([]);
    //   this.addLocationsToGrid(this.valuesLocations, 'OTHER');
    // }
    if (this.zipCodesInput !== '') {
      const currentData = this.getGridAllRows(this.locationsGrid).map(i => i.location);
      const zipCodes = this.zipCodesInput.split(',');
      const locationsAdd = [];
      const locationsUpdate = [];
      const wrongCodes = [];
      for (const zip of zipCodes) {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(zip.trim())) {
          const stringLoc = 'Country: ' + this.zipCountrySelect + ',  Zip/Postal Code: ' + zip.trim();
          if (currentData.indexOf(stringLoc) === -1) {
            locationsAdd.push({
              location: stringLoc,
              type: 'zip',
              codes: JSON.stringify({
                countryCode: this.zipCountrySelect,
                blocked: (codeType === 'EXCLUDES') ? true : false,
                postalCode: zip.trim()
              }),
              status: (codeType === 'EXCLUDES') ? 'Excludes' : 'Targeted',
              id: this.zipCountrySelect + '-' + zip.trim()
            });
          } else {
            locationsUpdate.push({
              location: stringLoc,
              type: 'zip',
              codes: JSON.stringify({
                countryCode: this.zipCountrySelect,
                blocked: (codeType === 'EXCLUDES') ? true : false,
                postalCode: zip.trim()
              }),
              status: (codeType === 'EXCLUDES') ? 'Excludes' : 'Targeted',
              id: this.zipCountrySelect + '-' + zip.trim()
            });
          }
        } else {
          wrongCodes.push(zip.trim());
        }
      }
      if (locationsAdd.length > 0) {
        const res = this.locationsGrid.updateRowData({add: locationsAdd});
      }
      if (locationsUpdate.length > 0) {
        const res2 = this.locationsGrid.updateRowData({update: locationsUpdate});
      }
      if (wrongCodes.length > 0) {
        this.alert.alert('error', 'The following zip codes are not valid: ' + wrongCodes.join(', '), 10000)
      }
      this.zipCodesInput = '';
      this.valueChanged = true;
    }
  }

  addSet() {
    $('.add-locations-set').modal('show');
  }

  removeAllLocations() {

  }

  saveSet() {
    this.locationsGrid.forEachNode((node, index) => {
      $('.save-locations-set').modal('show');
      return;
    });
  }

  showContextualSet(type = 'contextual') {
    if (type === 'brand') {
      $('.save-brand-set').modal('show');
    } else {
      $('.save-contextuals-set').modal('show');
    }
  }

  addConversion() {
    this.newConversionModal.brandId = this.brandId;
    this.newConversionModal.resetModal();
    $('.new-conversion-modal').modal('show');
  }

  deleteAllTrackedConvertions() {
    this.toTrackConvertionsGrid.forEachNode((node, index) => {
      const res = this.convertionsGrid.updateRowData({ add: [node.data] });
      const res2 = this.toTrackConvertionsGrid.updateRowData({ remove: [node.data] });
    });
  }

  getConversions(selectedOffers = []) {

    this.convService.getConversionsNoStats(this.brandId).subscribe(
      (response) => {
        const toMove = [];
        let stay = [];
        if (selectedOffers.length > 0) {
          for (const selected of selectedOffers) {
            for (const conv of response.response.conversions) {
              if (conv.offerId === selected) {
                toMove.push(conv);
              } else {
                stay.push(conv);
              }
            }
          }
        } else {
          stay = response.response.conversions;
        }
        if (typeof this.toTrackConvertionsGrid !== 'undefined' && this.toTrackConvertionsGrid !== null) {
          const res = this.toTrackConvertionsGrid.updateRowData({ add: toMove });
        }

        if (selectedOffers.length > 0) {
          this.trackConverstionGridData.push(...toMove)
        }
        this.converstionGridData = new Observable((observer: Observer<Array<Conversion>>) => {
          observer.next(stay);
          observer.complete();
        });
      },
      (error) => { console.log(error, 'getConversions'); }
    )
  }

  clickthroughConversionsChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.conversionWindowInDays.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.conversionWindowInDays.disable();
    }
  }
  viewthroughConversionsChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.viewthruConfig.controls.conversionWindowInDays.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.viewthruConfig.controls.conversionWindowInDays.disable();
    }
  }
  uniquenessPolicyChanged(event: MatRadioChange) {
    if (event.value === 'PER_USER_CUSTOM_PERIOD') {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.uniquenessPeriodInMillis.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.uniquenessPeriodInMillis.disable();
    }
  }

  inStreamChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.PRE_ROLL.enable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.PRE_ROLL.setValue(true);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.MID_ROLL.enable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.MID_ROLL.setValue(true);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.POST_ROLL.enable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.POST_ROLL.setValue(true);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.UNKNOWN.enable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.UNKNOWN.setValue(true);
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.PRE_ROLL.disable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.PRE_ROLL.setValue(false);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.MID_ROLL.disable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.MID_ROLL.setValue(false);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.POST_ROLL.disable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.POST_ROLL.setValue(false);
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.UNKNOWN.disable();
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.startDelays.controls.UNKNOWN.setValue(false);
    }
  }

  playerSizeChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.minPlayerWidth.setValue('310');
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.minPlayerHeight.setValue('174');
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.minPlayerWidth.setValue('');
      // @ts-ignore
      this.newCampaignForm.controls.videoConfig.controls.minPlayerHeight.setValue('');
    }
  }

  removeAllCarriers() {
    this.carriersTargetGrid.forEachNode((node, index) => {
      const res = this.carriersGrid.updateRowData({ add: [node.data] });
      const res2 = this.carriersTargetGrid.updateRowData({ remove: [node.data] });
    });
    this.valueChanged = true;
  }
  changeContextualSource(event) {
    console.log(event);
  }
  changeBranProtectionSource(event) {
    console.log(event);
  }

  contextualFilterChanged(event) {
    console.log(event);
  }

  brandProtectionFilterChanged(event) {
    console.log(event);
  }

  getContextualData(item = null) {
    if (this.itemsContextual.length === 0) {
      this.getFilteredContextual = this.thirdSourceService.getContextualSegments().subscribe(
        (response) => {
          let position = 0;
          for (const context in response[0]) {
            if (response[0][context].type === 'contextual') {
              this.itemsContextual.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              this.itemsContextual[position]['level'] = 0;
              this.itemsContextual[position]['targetable'] = response[0][context].targetable;
              this.itemsContextual[position].setCollapsedRecursive(true);
              position++;
            }
          }
        },
        (error) => {
          console.log(error, 'getContextualData');
        }
      )
    } else if (item !== null) {
      this.getFilteredContextual = this.thirdSourceService.getContextualSegmentChildren(item.value).subscribe(
        (response) => {
          let position = 1;
          for (const context in response[0]) {
            if (response[0][context].type === 'contextual') {
              item.children.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              position++;
            }
          }
          item.children.shift();
        },
        (error) => {
          console.log(error, 'getContextualData');
        }
      )
    }
  }

  contextualThirdPartyTreeDataFilter(text: string): void {
    this.filteringBrandProtection = true;
    this.itemsContextual = [];
    this.getFilteredContextual = this.thirdSourceService.getContextualSegments(text).subscribe(
      response => {
        this.filteringBrandProtection = false;
        const results = response[0].results.filter(i => i.type === 'contextual');
        let items: TreeviewItem[] = [];
        let total = 1000;
        while (results.length > 0) {
          const removed = [];
          for (let i = 0; i < results.length; i++) {
            if (typeof results[i].parentId === 'undefined') {
              items.push(new TreeviewItem({
                text: results[i].name, value: results[i].contextualEntryId, collapsed: false, children: []
              }, false));
              items[items.length - 1]['level'] = 0;
              items[items.length - 1]['targetable'] = results[i].targetable;
              items[items.length - 1].setCheckedRecursive(true);
              removed.unshift(i);
            } else {
              const newItems = this.thirdSourceService.structureFilterContextual(items, results[i]);
              if (newItems[1]) {
                items = newItems[0];
                removed.unshift(i);
              }
            }
          }
          for (const index of removed) {
            results.splice(index, 1);
          }
          total--;
        }
        this.itemsContextual = items;
      }, error => console.log(error, 'contextualThirdPartyTreeDataFilter')
    );
  }

  getBrandProtectionData(item = null) {
    if (this.itemsBrandProtection.length === 0) {
      this.getFilteredBrandProtection = this.thirdSourceService.getContextualSegments().subscribe(
        (response) => {
          let position = 0;
          for (const context in response[0]) {
            if (response[0][context].type === 'brand_safety') {
              this.itemsBrandProtection.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              this.itemsBrandProtection[position]['level'] = 0;
              this.itemsBrandProtection[position]['targetable'] = response[0][context].targetable;
              this.itemsBrandProtection[position].setCollapsedRecursive(true);
              position++;
            }
          }
        },
        (error) => {
          console.log(error, 'getBrandProtectionData');
        }
      )
    } else if (item !== null) {
      this.getFilteredBrandProtection = this.thirdSourceService.getContextualSegmentChildren(item.value).subscribe(
        (response) => {
          let position = 1;
          for (const context in response[0]) {
            if (response[0][context].type === 'brand_safety') {
              item.children.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              position++;
            }
          }
          item.children.shift();
        },
        (error) => {
          console.log(error, 'getBrandProtectionData');
        }
      )
    }
  }

  brandProtectionThirdPartyTreeDataFilter(text: string): void {
    this.filteringBrandProtection = true;
    this.itemsBrandProtection = [];
    this.getFilteredBrandProtection = this.thirdSourceService.getContextualSegments(text).subscribe(
      response => {
        this.filteringBrandProtection = false;
        const results = response[0].results.filter(i => i.type === 'brand_safety');
        let items: TreeviewItem[] = [];
        let total = 1000;
        while (results.length > 0) {
          const removed = [];
          for (let i = 0; i < results.length; i++) {
            if (typeof results[i].parentId === 'undefined') {
              items.push(new TreeviewItem({
                text: results[i].name, value: results[i].contextualEntryId, collapsed: false, children: []
              }, false));
              items[items.length - 1]['level'] = 0;
              items[items.length - 1]['targetable'] = results[i].targetable;
              items[items.length - 1].setCheckedRecursive(true);
              removed.unshift(i);
            } else {
              const newItems = this.thirdSourceService.structureFilterContextual(items, results[i]);
              if (newItems[1]) {
                items = newItems[0];
                removed.unshift(i);
              }
            }
          }
          for (const index of removed) {
            results.splice(index, 1);
          }
          total--;
        }
        this.itemsBrandProtection = items;
      }, error => console.log(error, 'contextualThirdPartyTreeDataFilter')
    );
  }

  getAudiences(type = 'all') {
    if (typeof this.audiences !== 'undefined' && this.audiences !== null) {
      this.audienceService.getAudiencesStats(this.brandId).subscribe(
        (response) => {
          // tslint:disable-next-line:max-line-length
          if (typeof response.response.audiences !== 'undefined' && response.response.audiences !== null && response.response.audiences.length > 0) {
              for (const item of response.response.audiences) {
                if (typeof item.userIdTypes !== 'undefined' && item.userIdTypes.length > 0 && typeof item.userIdTypes[0] !== 'undefined'
                  && item.userIdTypes[0] !== null && item.userIdTypes[0] !== '' && item.userIdTypes[0] === 'RAMP_ID') {
                  this.audiencesCrm.push(item);
                } else {
                  this.audiences.push(item);
                }
              }
          }
          this.audienceDone = true;
          this.audienceCrmDone = true;
        },
        (error) => {
          console.log(error, 'getAudiences');
          this.audienceDone = true;
        }
      )
    }
  }

  getAudienceSets() {
    console.log('getAudienceSets Executed...')
    this.audienceSetsDone = false;
    this.audienceService.getAudienceSets().subscribe(
      (response) => {
        // tslint:disable-next-line:max-line-length
        this.audienceSets = (typeof response.response.client_campaign_audience_sets !== 'undefined' && response.response.client_campaign_audience_sets !== null && response.response.client_campaign_audience_sets.length > 0) ? response.response.client_campaign_audience_sets : [];
        this.audienceSetsDone = true;
      },
      (error) => {
        console.log(error, 'getAudienceSets');
        this.audienceSetsDone = true;
      }
    )
  }

  newAudienceSets() {
    $('.save-audience-set').modal('show');
  }

  captureChanged(event: MatCheckboxChange, name) {
      if (event.checked) {
        this.newCampaignForm.controls.audienceCapture['controls'][name].enable();
      } else {
        this.newCampaignForm.controls.audienceCapture['controls'][name].disable();
        this.newCampaignForm.controls.audienceCapture['controls'][name].setValue('');
      }
  }

  createAudience() {
    this.newAudienceModal.brandId = this.brandId;
    $('.new-audience-modal').modal('show');
  }

  deviceTypeChanged(event: MatCheckboxChange, type: string) {
    if (event.checked) {
      (this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes as FormArray).push(this.fb.control(type));
    } else {
      (this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes as FormArray).removeAt((this.newDevicesForm.controls.deviceTargeting.controls.deviceTypes as FormArray).value.indexOf(type));
    }
  }

  deviceMakesChanged(event: []) {
    // (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).clear();
    // this.modelsLocal = new Observable((ob: Observer<Array<{make: string, model: string}>>) => {
    //   ob.next([]);
    //   ob.complete();
    // });
    // for (const make of event) {
    //   const modelsSelected = this.selectedDeviceModels.map((item) => (item.make === make) ? this.fb.control(item.model) : false).filter((item) => item);
    //   (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).push(this.fb.group({
    //     make: [make],
    //     models: this.fb.array(modelsSelected)
    //   }))
    //   this.getModels(make);
    // }
    // // @ts-ignore
    // this.selectedDeviceModels = this.selectedDeviceModels.filter((item) => event.indexOf(item.make) !== -1);
    // console.log(this.selectedDeviceModels)
    // console.log((this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).value)
  }

  deviceModelsChanged(event: []) {
    (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).clear();
    for (const make of this.selectedDeviceMakes) {
      // @ts-ignore
      const modelsSelected = event.map((item) => (item.make === make) ? this.fb.control(item.model) : false).filter((item) => item);
      (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).push(this.fb.group({
        make: [make],
        models: this.fb.array(modelsSelected)
      }))
    }
  }

  getModels(make: string) {
    // this.thirdSourceService.getModels(make).subscribe(
    //   (response) => {
    //     let currentData = [];
    //     this.modelsLocal.subscribe((data) => { currentData = data })
    //     const modelsFlag = [...(response[0].map(item => ({make: make, model: item}))), ...currentData];
    //     this.modelsLocal = new Observable((ob: Observer<Array<{make: string, model: string}>>) => {
    //       ob.next(modelsFlag);
    //       ob.complete();
    //     })
    //   },
    //   (error) => { console.log(error, 'getModels'); }
    // )
  }

  addSoftware() {
    (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).push(
      this.fb.group({
        name: [''],
        osVersionTargets: this.fb.array([
          this.fb.group({
            from: this.fb.group({
              major: ['']
            }),
            to: this.fb.group({
              major: [''],
            })
          })
        ])
      })
    )
  }

  removeSoftware(i: number) {
    (this.newDevicesForm.controls.deviceTargeting.controls.softwares as FormArray).removeAt(i);
  }
  locationsTreeData(event: TreeviewItem) {
    if (typeof event.children !== 'undefined' && event.children.length > 0 && event.children[0].text === 'loading') {
      if (event['level'] === 0) {
        this.getLocationRegions(event['countryCode'], event);
      } else if (event['level'] === 1) {
        this.getRegionCities(event['countryCode'], event['regionCode'], event);
      }
    }
  }

  locationsSelected(event: any[]) {
    this.valuesLocations = event;
  }

  locationsSelectionChanged(event: any) {
    if (event.checked) {
      // this.valuesLocations.push(event.value);
      event.setCheckedRecursive(false);
      event.checked = true;
      if (typeof event['parent'] !== 'undefined' && event['parent'] !== null) {
        // const index = this.valuesLocations.findIndex(i => {
        //   console.log(JSON.parse(i).geoNameId, event['parent'].identifier);
        //   return JSON.parse(i).geoNameId === event['parent'].identifier;
        // });
        // console.log(index);
        // if (index !== -1) {
          event['parent'].checked = false;
          // this.valuesLocations.splice(index, 1);
          if (typeof event['parent']['parent'] !== 'undefined' && event['parent']['parent'] !== null) {
            // const index2 = this.valuesLocations.findIndex(i => {
            //   console.log(JSON.parse(i).geoNameId, event['parent']['parent'].identifier);
            //   return JSON.parse(i).geoNameId === event['parent']['parent'].identifier;
            // });
            // console.log(index2);
            event['parent']['parent'].checked = false;
            // if (index2 !== -1) {
            //   event['parent']['parent'].checked = false;
            //   this.valuesLocations.splice(index2, 1);
            // }
          }
        // }
      }
    } else {
      this.valuesLocations.splice(this.valuesLocations.findIndex(i => JSON.parse(i).geoNameId === event.value.geoNameId), 1);
    }
  }

  getGridAllRows(grid: any) {
    const rowData = [];
    grid.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  addLocationsToGrid(childs: Array<TreeviewItem>) {
    const currentData = this.getGridAllRows(this.locationsGrid).map(i => i.location);
    const newData = this.getTreeCheckedLocations(childs);
    const toAdd = [];
    for (const newOne of newData) {
      if (currentData.indexOf(newOne.location) === -1) {
        toAdd.push(newOne);
      }
    }
    const res = this.locationsGrid.updateRowData({add: toAdd});
  //   // if (type !== 'OTHER') {
  //   //   this.locationsGrid.setRowData([]);
  //   //   this.addCodes('OTHER');
  //   // }
  //   if (this.valuesLocations.length > 0) {
  //     const locations = [];
  //     for (const loc of this.valuesLocations) {
  //       const locs = JSON.parse(loc);
  //       locs['blocked'] = false;
  //       const locationString = (typeof locs.cityName !== 'undefined')
  //         ? (locs.cityName + ' (City), ' + locs.primaryRegionName) : ((typeof locs.primaryRegionCode !== 'undefined')
  //           ? (locs.primaryRegionName + ' (Region), ' + locs.countryName) : (locs.countryName + ' (Country)'));
  //       console.log(locationString, locs)
  //       locations.push({type: 'locations', codes: JSON.stringify(locs), location: locationString, status: 'Targeted'});
  //     }
  //     const res = this.locationsGrid.updateRowData({add: locations});
    for (const item of childs) {
      item.setCheckedRecursive(false);
      item.setCollapsedRecursive(true);
    }
    if (this.loadingVars.fork && this.loadingVars.creatives) {
      this.valueChanged = true;
    }
  //   }
  }

  getTreeCheckedLocations(childs: Array<TreeviewItem>) {
    const locations = [];
    for (const child of childs) {
      if (child.checked) {
        const locs = JSON.parse(child.value);
        locs['blocked'] = false;
        if (typeof locs.cityName !== 'undefined') {
          locs['city'] = locs.cityName;
        }
        const locationString = (typeof locs.cityName !== 'undefined')
          ? (locs.cityName + ' (City), ' + locs.primaryRegionName) : ((typeof locs.primaryRegionCode !== 'undefined')
            ? (locs.primaryRegionName + ' (Region), ' + locs.countryName) : (locs.countryName + ' (Country)'));
        locations.push({type: 'locations', codes: JSON.stringify(locs), location: locationString, status: 'Targeted'});
      } else if (typeof child.children !== 'undefined' && child.children !== null && child.children.length > 0) {
        locations.push(...this.getTreeCheckedLocations(child.children));
      }
    }
    return locations;
  }

  setSelectedDmaCodes(event: any) {
    this.selectedDmaCodes = event;
  }

  addTargetSegment(item, type: string) {
    item['type'] = type;
    item['blocked'] = false;
    this.contextualRules.push(item);
    this.valueChanged = true;
  }

  addBrandProtectionTargetSegment(item, type: string) {
    item['type'] = type;
    item['blocked'] = false;
    this.brandProtectionRules.push(item);
    this.valueChanged = true;
  }

  removeRule(pos, type) {
    if (type === 'contextual') {
      this.contextualRules.splice(pos, 1);
    } else {
      this.brandProtectionRules.splice(pos, 1);
    }
    this.valueChanged = true;
  }

  pondHandleAddFile(event: any) {
    const f = event.file.file;
    if (f) {
      const r = new FileReader();
      r.onload = (e) => {
        // @ts-ignore
        const contents = e.target.result;
        this.addTargetedHyperlocal(contents, 'add');
      }
      r.readAsText(f);
    } else {
      console.log('Error reading the content.')
    }
  }

  addTargetedHyperlocal(data: any, type = 'add') {
    const targetedLocations = [];
    if (typeof data.option !== 'undefined') {
      const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
      this.geoHereService.getAddressCoordinates(data.option.value).subscribe(
        (response) => {
          const location = {
            status: 'Yes',
            zone: zone,
            address: data.option.value,
            lat: response.Response.View[0].Result[0].Location.DisplayPosition.Latitude,
            lng: response.Response.View[0].Result[0].Location.DisplayPosition.Longitude,
            radius: '1.00 ' + this.hyperlocalRadiusUnits,
            target: (type === 'add') ? 'Targeted' : 'Exclude',
          };
          const res = this.hyperlocalGrid.updateRowData({add: [location]});
          this.hyperlocalSelectedData.push(location);
          // tslint:disable-next-line:max-line-length
          this.mapClick(response.Response.View[0].Result[0].Location.DisplayPosition.Latitude, response.Response.View[0].Result[0].Location.DisplayPosition.Longitude, zone);
          this.initMap();
          this.valueChanged = true;
        },
        (error) => { console.log(error, 'getAddressCoordinates'); }
      )
    }
    if (typeof data === 'string') {
      const lines = data.split('\n');
      for (const line of lines) {
        const spaces = line.split(' ');
        // @ts-ignore
        if ((spaces.length === 2 && !isNaN(spaces[0]) && !isNaN(spaces[1])) || (spaces.length === 3 && !isNaN(spaces[0]) && !isNaN(spaces[1]) && !isNaN(spaces[2]))) {
          const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
          const location = {
            status: 'Yes',
            zone: zone,
            address: '',
            lat: spaces[0],
            lng: spaces[1],
            radius: ((spaces.length === 3) ? spaces[2] + ' ' : '1.00 ') + this.hyperlocalRadiusUnits,
            target: (type === 'add') ? 'Targeted' : 'Exclude',
          };
          const res = this.hyperlocalGrid.updateRowData({add: [location]});
          this.hyperlocalSelectedData.push(location);
          // tslint:disable-next-line:max-line-length
          this.mapClick(spaces[0], spaces[1], zone);
          targetedLocations.push(spaces[0] + ' ' + spaces[1]);
        } else {
          this.geoHereService.getAddressCoordinates(line).subscribe(
            (response) => {
              if (typeof response.Response.View[0] !== 'undefined') {
                const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
                const location = {
                  status: 'Yes',
                  zone: zone,
                  address: response.Response.View[0].Result[0].Location.Address.Label,
                  lat: response.Response.View[0].Result[0].Location.DisplayPosition.Latitude,
                  lng: response.Response.View[0].Result[0].Location.DisplayPosition.Longitude,
                  radius: '1.00 ' + this.hyperlocalRadiusUnits,
                  target: (type === 'add') ? 'Targeted' : 'Exclude',
                };
                const res = this.hyperlocalGrid.updateRowData({add: [location]});
                this.hyperlocalSelectedData.push(location);
                // tslint:disable-next-line:max-line-length
                this.mapClick(response.Response.View[0].Result[0].Location.DisplayPosition.Latitude, response.Response.View[0].Result[0].Location.DisplayPosition.Longitude, zone);
                targetedLocations.push(response.Response.View[0].Result[0].Location.Address.Label);
              }
            },
            (error) => { console.log(error, 'getAddressCoordinates'); }
          )
        }
      }
      this.valueChanged = true;
      if (targetedLocations.length > 0) {
        this.alert.alert('success', 'Success added locations: ' + '\n' + targetedLocations.join(' \n '), 10000);
      }
    }
  }

  mapClick(lat, lng, zone) {
    this.markers.push(L.marker([lat, lng], {icon: this.mapIcon}).bindTooltip(zone));
    this.markers.push(L.circle([lat, lng], {radius: 1000}));

    if (typeof this.mapHyper !== 'undefined' && this.mapHyper !== null) {
      const group = L.featureGroup(this.markers)
        .addTo(this.mapHyper);
      // @ts-ignore
      this.mapHyper.fitBounds(group.getBounds());
    }
  }

  getAddressAutocompleteSuggest(searchText) {
    if (searchText !== '') {
      this.geoHereService.getAddressAutocompleteSuggest(searchText).subscribe(
        (response) => {
          this.autocompleteAddresses = response.suggestions;
          setTimeout(() => {
            this.inputAuto.openPanel();
          }, 1)
        },
        (error) => {
          console.log(error, 'getAddressAutocompleteSuggest');
        }
      )
    }
  }

  initMap(): void {
    setTimeout(() => {
      if (typeof this.mapHyper !== 'undefined' && this.mapHyper !== null) {
        // @ts-ignore
        this.mapHyper.remove();
        this.mapHyper = null;
      }
      // if (typeof this.mapHyper === 'undefined' || this.mapHyper === null) {
        this.mapHyper = new L.Map('map', {
          layers: [L.tileLayer(
            'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            {maxZoom: 16 })],
          center: new L.LatLng(39.074208, -95.723697),
          zoomControl: true,
          zoom: 4 // (this.markers.length > 0) ? 12 : 2
        });
        // @ts-ignore
        this.mapHyper.on('click', (e) => {
          const zone = (this.markers.length + 1 + 9).toString(36).toUpperCase();
          this.mapClick(e.latlng.lat, e.latlng.lng, zone);
          const locations = [{
            status: 'Yes',
            zone: zone,
            address: '',
            lat: e.latlng.lat,
            lng: e.latlng.lng,
            radius: 1.00,
            target: 'Targeted',
          }];
          const res = this.hyperlocalGrid.updateRowData({add: locations});
          this.hyperlocalSelectedData.push(locations[0])
        });
        if ((this.markers.length > 0)) {
          const group = L.featureGroup(this.markers)
            .addTo(this.mapHyper);
          // @ts-ignore
          this.mapHyper.fitBounds(group.getBounds());
        }
     // }
    }, 10);
  }

  audiencesTabChanged(event: MatTabChangeEvent) {
    // if (event.index === 1) {
    //   this.getAudiences('crm');
    // }
    if (event.index === 2) {
      this.audiencesThirdPartyTreeData();
    }
    if (event.index === 3) {
      this.getAudienceSets();
    }
  }

  stepperChanged(event: StepperSelectionEvent) {
    this.showTabErrors = true;
    if (event.selectedStep.label === 'Audiences') {
      // this.getAudiences();
    } else if (event.selectedStep.label === 'Summary') {
      this.amountLocationsByType = this.getAmountLocationsByType();
      this.conversionsText = this.getConversionsText();
      this.contextualRulesBlocked = this.contextualRules.filter((item) => item.blocked).length;
      this.contextualRulesNotBlocked = this.contextualRules.filter((item) => !item.blocked).length;
      this.hyperlocalNotBlocked = this.hyperlocalSelectedData.filter((item) => item.target === 'Targeted').length;
      this.hyperlocalBlocked = this.hyperlocalSelectedData.filter((item) => item.target === 'Exclude').length;
      this.brandProtectionRulesNotBlocked = this.brandProtectionRules.filter((item) => !item.blocked).length;
      this.brandProtectionRulesBlocked = this.brandProtectionRules.filter((item) => item.blocked).length;
    }
  }

  selectAudienceGroup(pos) {
    this.selectedAudienceGroup = pos;
  }

  addAudienceRules(selectedGroup = -1) {
    const newRules: FormArray = this.fb.array([]);
    if (typeof this.myAudiencesList !== 'undefined' && this.myAudiencesList.selectedOptions.selected.length > 0) {
      for (const myAud of this.myAudiencesList.selectedOptions.selected) {
        if (selectedGroup > -1) {
          const currentGroups = this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules.value;
          if (currentGroups.findIndex(i => i.audienceId === myAud.value.audienceId) !== -1) {
            this.alert.alert('error', 'You can not have an Audience twice in the same group.', 10000);
          } else {
            this.newAudiencesFormextraData[myAud.value.audienceId] = {
              checked: true,
              text: myAud.value.name,
              value: myAud.value.audienceId,
              level: 1,
              targetable: true,
              providerId: 0,
              providerName: 'My Audiences',
            };
            (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules as FormArray).push(
              this.fb.group({
                type: ['atomic'],
                audienceId: [myAud.value.audienceId],
                audienceType: ['FIRST_PARTY'],
                fromMillisSinceLastAccess: [0],
                toMillisSinceLastAccess: [0],
                blocked: [false]
              })
            );
          }
        } else {
          this.newAudiencesFormextraData[myAud.value.audienceId] = {
            checked: true,
            text: myAud.value.name,
            value: myAud.value.audienceId,
            level: 1,
            targetable: true,
            providerId: 0,
            providerName: 'My Audiences',
          };
          newRules.push(this.fb.group({
            type: ['atomic'],
            audienceId: [myAud.value.audienceId],
            audienceType: ['FIRST_PARTY'],
            fromMillisSinceLastAccess: [0],
            toMillisSinceLastAccess: [0],
            blocked: [false]
          }))
        }
      }
      this.myAudiencesList.deselectAll();
    }
    if (typeof this.myAudiencesCrmList !== 'undefined' && this.myAudiencesCrmList.selectedOptions.selected.length > 0) {
      for (const myAud of this.myAudiencesCrmList.selectedOptions.selected) {
        if (selectedGroup > -1) {
          const currentGroups = this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules.value;
          if (currentGroups.findIndex(i => i.audienceId === myAud.value.audienceId) !== -1) {
            this.alert.alert('error', 'You can not have an Audience twice in the same group.', 10000);
          } else {
            this.newAudiencesFormextraData[myAud.value.audienceId] = {
              checked: true,
              text: myAud.value.name,
              value: myAud.value.audienceId,
              level: 1,
              targetable: true,
              providerId: 0,
              providerName: 'Crm Audiences',
            };
            (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules as FormArray).push(
              this.fb.group({
                type: ['atomic'],
                audienceId: [myAud.value.audienceId],
                audienceType: ['FIRST_PARTY'],
                fromMillisSinceLastAccess: [0],
                toMillisSinceLastAccess: [0],
                blocked: [false]
              })
            );
          }
        } else {
          this.newAudiencesFormextraData[myAud.value.audienceId] = {
            checked: true,
            text: myAud.value.name,
            value: myAud.value.audienceId,
            level: 1,
            targetable: true,
            providerId: 0,
            providerName: 'Crm Audiences',
          };
          newRules.push(this.fb.group({
            type: ['atomic'],
            audienceId: [myAud.value.audienceId],
            audienceType: ['FIRST_PARTY'],
            fromMillisSinceLastAccess: [0],
            toMillisSinceLastAccess: [0],
            blocked: [false]
          }))
        }
      }
      this.myAudiencesCrmList.deselectAll();
    }
    if (this.audiencesThirdPartyData.length > 0) {
      for (const thirdAud of this.treeItemCheckedOut) {
        if (selectedGroup > -1) {
          const currentGroups = this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules.value;
          if (currentGroups.findIndex(i => i.audienceId === thirdAud.value) !== -1) {
            this.alert.alert('error', 'You can not have an Audience twice in the same group.', 10000);
          } else {
            this.newAudiencesFormextraData[thirdAud.value] = thirdAud;
            // tslint:disable-next-line:max-line-length
            (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules as FormArray).push(
              this.fb.group({
                type: ['atomic'],
                audienceId: [thirdAud.value],
                audienceType: ['THIRD_PARTY'],
                fromMillisSinceLastAccess: [0],
                toMillisSinceLastAccess: [0],
                blocked: [false]
              })
            );
          }
        } else {
          this.newAudiencesFormextraData[thirdAud.value] = thirdAud;
          newRules.push(this.fb.group({
            type: ['atomic'],
            audienceId: [thirdAud.value],
            audienceType: ['THIRD_PARTY'],
            fromMillisSinceLastAccess: [0],
            toMillisSinceLastAccess: [0],
            blocked: [false]
          }))
        }
        thirdAud.setCheckedRecursive(false);
      }
      this.treeItemCheckedOut = [];
      // for (const item of this.audiencesThirdPartyData) {
      //   const selectedItems = item.getSelection();
      //   console.log(selectedItems)
      //   if (selectedItems.checkedItems && selectedItems.checkedItems.length > 0) {
      //     for (const thirdAud of selectedItems.checkedItems) {
      //       console.log(thirdAud)
      //       this.newAudiencesFormextraData[thirdAud.value] = thirdAud;
      //       if (selectedGroup > -1) {
      //         // tslint:disable-next-line:max-line-length
      //         console.log('selectedGroup:', selectedGroup);
      //         console.log('array:', this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls);
      //         (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules.controls[selectedGroup].controls.rules as FormArray).push(
      //           this.fb.group({
      //             type: ['atomic'],
      //             audienceId: [thirdAud.value],
      //             audienceType: ['THIRD_PARTY'],
      //             fromMillisSinceLastAccess: [0],
      //             toMillisSinceLastAccess: [0],
      //             blocked: [false]
      //           })
      //         );
      //       } else {
      //         newRules.push(this.fb.group({
      //           type: ['atomic'],
      //           audienceId: [thirdAud.value],
      //           audienceType: ['THIRD_PARTY'],
      //           fromMillisSinceLastAccess: [0],
      //           toMillisSinceLastAccess: [0],
      //           blocked: [false]
      //         }))
      //       }
      //     }
      //   }
      //   item.setCheckedRecursive(false);
      // }
    }
    if (newRules.length === 1) {
      // (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).push(newRules.controls[0]);
      (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).push(
        this.fb.group({
          type: ['composite'],
          logicalOperator: ['or'],
          rules: newRules
        }));
    } else if (newRules.length > 1) {
      (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).push(
        this.fb.group({
          type: ['composite'],
          logicalOperator: ['or'],
          rules: newRules
      }));
    }
  }

  audiencesThirdPartyTreeDataFilter(text: string): void {
    this.filtering = true;
    this.audiencesThirdPartyData = [];
    this.getAudiencesAll = this.thirdSourceService.getAudienceSegments(text).subscribe(
      response => {
          this.filtering = false;
          const results = response[0].results;
          let items: TreeviewItem[] = [];
          let total = 1000;
          while (results.length > 0) {
            const removed = [];
            for (let i = 0; i < results.length; i++) {
                if (typeof results[i].parentId === 'undefined') {
                  items.push(new TreeviewItem({
                    text: results[i].name, value: results[i].demographicId, collapsed: false, children: []
                  }, false));
                  items[items.length - 1]['level'] = 0;
                  items[items.length - 1]['targetable'] = results[i].targetable;
                  items[items.length - 1]['price'] = (results[i].price / this.margin).toFixed(2);
                  items[items.length - 1].setCheckedRecursive(false);
                  removed.unshift(i);
                } else {
                  const newItems = this.thirdSourceService.structureFilterAudiences(items, results[i]);
                  if (newItems[1]) {
                    items = newItems[0];
                    removed.unshift(i);
                  }
                }
            }
            for (const index of removed) {
              results.splice(index, 1);
            }
            total--;
          }
          this.audiencesThirdPartyData = items;
      }, error => console.log(error, 'audiencesThirdPartyTreeDataFilter')
    );
  }

  audiencesThirdPartyTreeData(item = null) {
    if ((this.audiencesThirdPartyData.length === 0 && item === null) || (item !== null && item.children.length <= 1)) {
      this.getAudiencesAll = this.thirdSourceService.getAudienceSegments(item).subscribe(
        (response) => {
          if (item !== null) { item.children.shift(); }
          let position = 0;
          for (const context in response[0]) {
            if (item === null) {
              this.audiencesThirdPartyData.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].demographicId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].demographicId}
                ] : null)
              }, true));
              this.audiencesThirdPartyData[position]['level'] = 0;
              this.audiencesThirdPartyData[position]['targetable'] = response[0][context].targetable;
              this.audiencesThirdPartyData[position]['price'] = (response[0][context].price / this.margin).toFixed(2);
              this.audiencesThirdPartyData[position].setCollapsedRecursive(true);
              this.audiencesThirdPartyData[position].setCheckedRecursive(false);
            } else {
              item.children.push(new TreeviewItem({
                text: response[0][context].name, value: response[0][context].demographicId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].demographicId}
                ] : null)
              }, true));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              item.children[position]['price'] = (response[0][context].price / this.margin).toFixed(2);
              item.children[position]['reach'] = response[0][context].reach;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              item.children[position].setCheckedRecursive(false);
            }
            position++;
          }
        },
        (error) => {
          console.log(error, 'getAudienceSegments');
        }
      )
    }
  }

  audiencesThirdPartySelectedChanged(event: any[]) {
  }

  removeAudienceRule(position) {
    (this.newAudiencesForm.controls.compositeAudienceTargetingRule.controls.rules as FormArray).removeAt(position);
  }

  updateStatusAdModal(data) {
    switch (data.type) {
      case 'banner':
        // this.newImageAdModal.loadAd(data as Ad);
        // this.newImageAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'audio':
        // this.newAudioAdModal.loadAd(data as Ad);
        // this.newAudioAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'video':
        // this.newVideoAdModal.loadAd(data as Ad);
        // this.newVideoAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'vast_audio':
        // this.newVastAudioAdModal.loadAd(data as Ad);
        // this.newVastAudioAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'vast_video':
        // this.newVastVideoAdModal.loadAd(data as Ad);
        // this.newVastVideoAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'native':
        // this.newNativeAdModal.loadAd(data as Ad);
        // this.newNativeAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'html5':
        // this.newHtml5AdModal.loadAd(data as Ad);
        // this.newHtml5AdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'tag':
        // this.newTagAdModal.loadAd(data as Ad);
        // this.newTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'expandable':
        // this.newExpandableTagAdModal.loadAd(data as Ad);
        // this.newExpandableTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
      case 'ad_builder':
        // this.newMraidTagAdModal.loadAd(data as Ad);
        // this.newMraidTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
          }, error => console.log(error)
        );
        break;
    }
  }

  addInventorySelected(event: {}, fromEdit = false) {
    const formatedData = {};
    this.selectedDomainExchanges.exchanges = [];
    (this.newInventoryForm.controls.inventoryUnitTargets as FormArray).clear();
    (this.newInventoryForm.controls.exchanges['controls'].targets as FormArray).clear();
    this.selectedDomainExchanges.domains = [];
    (this.newInventoryForm.controls.domainList as FormArray).clear();
    this.rowDataDomainLists = new Observable((ob: Observer<Array<any>>) => {
      // @ts-ignore
      ob.next([]);
      ob.complete();
    });
    // @ts-ignore
    if (typeof event.exchangesSelected !== 'undefined' && event.exchangesSelected.length > 0) {
      // @ts-ignore
      for (const exch of event.exchangesSelected) {
        // @ts-ignore
        (this.newInventoryForm.controls.exchanges.controls.targets as FormArray).push(this.fb.group({
          status: ['online'],
          exchangeId: [exch.exchangeId]
        }));
        this.selectedDomainExchanges.exchanges.push({name: exch.name, exchangeId: exch.exchangeId});
      }
    }
    const exchangeIDs = this.selectedDomainExchanges.exchanges.map(i => i.exchangeId);
    const exchangeNames = this.selectedDomainExchanges.exchanges.map(i => i.name);
    // @ts-ignore
    if (typeof event.domainsSelected !== 'undefined' && event.domainsSelected.length > 0) {
      // @ts-ignore
      for (const domain of event.domainsSelected) {
        this.selectedDomainExchanges.domains.push(domain);
        (this.newInventoryForm.controls.inventoryUnitTargets as FormArray).push(this.fb.group({
          identifier: [domain.identifier],
          ruleStatus: ['online'],
          exchanges: this.fb.array(exchangeNames),
          exchangesId: this.fb.array(exchangeIDs),
        }));
        formatedData[domain.identifier] = {
          name: domain.name,
          domainId: domain.identifier,
          exchanges: exchangeNames,
          exchangesId: exchangeIDs
        };
      }
    }
    if (this.inventoryUnitsSelected !== null && this.inventoryUnitsSelected.length > 0) {
      // @ts-ignore
      for (const domain of this.inventoryUnitsSelected) {
        (this.newInventoryForm.controls.inventoryUnitTargets as FormArray).push(this.fb.group({
          identifier: [domain.identifier],
          ruleStatus: ['online'],
          exchanges: this.fb.array(exchangeNames),
          exchangesId: this.fb.array(exchangeIDs),
        }));
        formatedData[domain.identifier] = {
          name: domain.name,
          domainId: domain.identifier,
          exchanges: exchangeNames,
          exchangesId: exchangeIDs
        };
      }
    }
    // @ts-ignore
    if (Object.values(formatedData).length === 0) {
      formatedData['none'] = {
        name: 'All Domains',
        domainId: 'none',
        exchanges: exchangeNames,
        exchangesId: exchangeIDs
      };
    }
    this.rowDataInventory = new Observable((ob: Observer<Array<any>>) => {
      // @ts-ignore
      ob.next(Object.values(formatedData));
      ob.complete();
    });
    // @ts-ignore
    if (typeof event.domainListSelected !== 'undefined' && event.domainListSelected.length > 0) {
      // @ts-ignore
      for (const domain of event.domainListSelected) {
        (this.newInventoryForm.controls.domainList as FormArray).push(this.fb.group({
          domainListId: [domain.domainListId]
        }));
      }
      this.rowDataDomainLists = new Observable((ob: Observer<Array<any>>) => {
        // @ts-ignore
        ob.next(event.domainListSelected);
        ob.complete();
      });
    }
  }

  onInventoryGridReady(event) {
    this.inventoryGrid = event.api;
  }

  onDomainListsGridReady(event) {
    this.domainListsGrid = event.api;
  }

  onDealsGridReady(event) {
    this.dealsGrid = event.api;
  }

  inventoryCellClicked(node) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-trash') {
      const res = this.inventoryGrid.updateRowData({remove: [node.data]});
      this.selectInventoryModal.domainsSelected.splice(
        (this.selectInventoryModal.domainsSelected.findIndex(i => i.identifier === node.data.domainId)), 1);
      if (node.data.domainId === 'none') {

      } else {
        for (const each of node.data.exchanges) {
          (this.newInventoryForm.controls.inventoryUnitTargets as FormArray)
            .removeAt((this.newInventoryForm.controls.inventoryUnitTargets as FormArray).value.findIndex((item) => item.identifier === node.data.domainId));
        }
      }
      const amount = this.inventoryGrid.getSelectedRows();
      if (amount.length === 0) {
        this.selectedDomainExchanges.domains = [];
        this.selectedDomainExchanges.exchanges = [];
      }
    }
  }

  domainListsCellClicked(node) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-trash') {
      const res = this.domainListsGrid.updateRowData({remove: [node.data]});
      const index = this.selectInventoryModal.domainListSelected.findIndex(i => i.domainListId === node.data.domainListId);
      // this.selectInventoryModal.domainListGrid.updateRowData({remove: [this.selectInventoryModal.domainListSelected[index]]});
      this.selectInventoryModal.domainListSelected.splice((index), 1);
      (this.newInventoryForm.controls.domainList as FormArray).removeAt((this.newInventoryForm.controls.domainList as FormArray).value.findIndex((item) => item.domainListId === node.data.domainListId));
    }
  }

  dealsCellClicked(node) {
    if (node.event.target.className === 'ti-trash') {
      const res = this.dealsGrid.updateRowData({remove: [node.data]});
      (this.newCampaignForm.get('deals') as FormArray).removeAt(
        (this.newCampaignForm.get('deals') as FormArray).getRawValue().findIndex(i => i.assetId === node.data.assetId)
      );
      this.marketplaceModal.removeGridSelected(node.data.assetId);
    }
  }

  onGridReadyLinkedAds(params) {
    this.linkedAdsGrid = params.api;
  }

  adsToLink(event: Ad[]) {
    this.rowDataLinkedAds = new Observable((ob: Observer<Array<Ad>>) => {
      ob.next(event);
      ob.complete();
    });
    if (this.loadingVars.fork && this.loadingVars.creatives) {
      this.linkAdsValueChanged = true;
    }
  }

  linkedAdsCellClicked(node: any) {
    if (node.column.colId === 'flightDates' && node.event.target.className === 'ti-pencil') {
      this.currentRowAd = node.data;
      console.log(node.data.flightDates)
      if (typeof node.data.flightDates !== 'undefined' && node.data.flightDates !== null && node.data.flightDates.length > 0) {
        this.currentAdRanges = node.data.flightDates.map(i => ({begin: new Date(i.begin), end: new Date(i.end)}));
      } else {
        this.currentAdRanges = [{begin: null, end: null}]
      }
      $('.add-ad-flight-dates').modal('show');
    }
  }

  removeAd(node: any) {
    this.linkAdsValueChanged = true;
  }

  rangeChanged(type, event, i = null) {
    // @ts-ignore
    if (i !== null) {
      this.currentAdRanges[i][type] = new Date(event.value);
      if (
        typeof this.currentAdRanges[i].begin !== 'undefined'
        && this.currentAdRanges[i].begin !== null
        && this.currentAdRanges[i].begin !== ''
        && typeof this.currentAdRanges[i].end !== 'undefined'
        && this.currentAdRanges[i].end !== null
        && this.currentAdRanges[i].end !== ''
        && this.currentAdRanges[i].begin.getTime() > this.currentAdRanges[i].end.getTime()
      ) {
        this.alert.alert('error', 'End Date needs to be greater than Start Date.');
        this.currentAdRanges[i][type] = null;
      }
      // @ts-ignore
      // this.currentAdRanges[i] = {begin: event.value.begin, end: event.value.end};
    } else {
      // @ts-ignore
      this.currentAdRanges.push({begin: event.value.begin, end: event.value.end});
    }
  }

  addAdFlightDates() {
    const mappedRanges = [];
    for (const range of this.currentAdRanges) {
      if (typeof range.begin !== 'undefined' && range.begin !== null && range.begin !== '') {
        mappedRanges.push(
          (typeof range.end !== 'undefined' && range.end !== null && range.end !== '')
            ? {begin: moment(range.begin.toLocaleDateString()), end: moment(range.end.toLocaleDateString())}
            : {begin: moment(range.begin.toLocaleDateString())}
        );
      } else {
        this.alert.alert('error', 'Start Date is required.');
        return false;
      }
    }
    if (this.bulkEditFlightDates) {
      const nodes = this.linkedAdsGrid.getSelectedNodes();
      for (const node of nodes) {
        node.data['flightDates'] = mappedRanges;
        const res = this.linkedAdsGrid.updateRowData({update: [node.data]});
      }
    } else {
      this.currentRowAd['flightDates'] = mappedRanges;
      const res = this.linkedAdsGrid.updateRowData({update: [this.currentRowAd]});
    }
    this.currentAdRanges = [];
    this.linkedAdsGrid.refreshCells({columns: ['flightDates']});
    this.linkedAdsGrid.redrawRows();
    $('.add-ad-flight-dates').modal('hide');
    this.bulkEditFlightDates = false;
    this.linkAdsValueChanged = true;
  }

  saveCampaignsData() {
    this.ngLoading = true;
    if (typeof this.hoursRulesGrid !== 'undefined' && this.dayPartingType === 'range') {
      this.hoursRulesGrid.forEachNode((node, index) => {
        const weekDays = this.fb.array([]);
        if (node.data.monday) {
          weekDays.push(this.fb.control('monday'));
        }
        if (node.data.tuesday) {
          weekDays.push(this.fb.control('tuesday'));
        }
        if (node.data.wednesday) {
          weekDays.push(this.fb.control('wednesday'));
        }
        if (node.data.thursday) {
          weekDays.push(this.fb.control('thursday'));
        }
        if (node.data.friday) {
          weekDays.push(this.fb.control('friday'));
        }
        if (node.data.saturday) {
          weekDays.push(this.fb.control('saturday'));
        }
        if (node.data.sunday) {
          weekDays.push(this.fb.control('sunday'));
        }
        // @ts-ignore
        (this.newCampaignForm.controls.budget.controls.schedule.controls.daypartingRules as FormArray).push(this.fb.group({
          fromTime: [moment(node.data.from, 'HH:mma').format('HH:mm')],
          toTime: [moment(node.data.to, 'HH:mma').format('HH:mm')],
          daysOfWeek: weekDays
        }));
      });
    } else {
      // @ts-ignore
      (this.newCampaignForm.controls.budget.controls.schedule.controls.daypartingRules as FormArray).clear();
    }
    if (typeof this.budgetDateRange !== 'undefined' && this.budgetDateRange !== null && this.budgetDateRange.begin !== null) {
      // @ts-ignore
      this.newCampaignForm.controls.flightDates.controls.from.setValue(this.budgetDateRange.begin.format('YYYYMMDD'));
      // @ts-ignore
      this.newCampaignForm.controls.flightDates.controls.to.setValue(this.budgetDateRange.end.format('YYYYMMDD'));
      // @ts-ignore
      this.newCampaignForm.controls.budget.controls.schedule.controls.flightDates.controls.from.setValue(this.budgetDateRange.begin.format('YYYYMMDD'));
      // @ts-ignore
      this.newCampaignForm.controls.budget.controls.schedule.controls.flightDates.controls.to.setValue(this.budgetDateRange.end.format('YYYYMMDD'));

      // @ts-ignore
      this.newCampaignForm.controls.isRelativeDayParting.setValue(this.newCampaignForm.controls.budget.controls.schedule.controls.isRelativeDayParting.value);
    } else {
      // (this.newCampaignForm.get('budget') as FormGroup).removeControl('schedule');
    }
    if (typeof this.toTrackConvertionsGrid !== 'undefined') {
      this.toTrackConvertionsGrid.forEachNode((node, index) => {
        // @ts-ignore
        (this.newCampaignForm.controls.viewthruConfig.controls.offers as FormArray).push(this.fb.control(node.data.offerId));
        // @ts-ignore
        (this.newCampaignForm.controls.conversionConfig.controls.offers as FormArray).push(this.fb.control(node.data.offerId));
      });
    }
    if (this.trackConverstions !== 'on') {
      this.newCampaignForm.removeControl('viewthruConfig');
      this.newCampaignForm.removeControl('conversionConfig');
    } else {
      if (this.newCampaignForm.get('conversionConfig').value.offers.length === 0) {
        this.alert.alert('error', 'You need to select at least one conversion to track.');
        this.ngLoading = false;
        return;
      }
    }
    const videoConfig = this.fb.group({
      videoSkippability: this.fb.array([]),
      placementTypes: this.fb.array([]),
      videoPlaybackModes: this.fb.array([]),
      inStreamOptions: this.fb.group({
        startDelays: this.fb.array([])
      }),
      startDelays: this.fb.array([]),
      inBannerOptions: this.fb.group({
        audioPlaybackModes: this.fb.array([])
      }),
      blockUnknownPlayerDimensions: [this.newCampaignForm.controls.videoConfig['controls'].blockUnknownPlayerDimensions.value],
      minPlayerWidth: [this.newCampaignForm.controls.videoConfig['controls'].minPlayerWidth.value],
      minPlayerHeight: [this.newCampaignForm.controls.videoConfig['controls'].minPlayerHeight.value]
    });
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.REQUIRED !== 'undefined'
      && this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.REQUIRED.value) {
      (videoConfig.controls.videoSkippability as FormArray).push(this.fb.control('REQUIRED'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.BLOCKED !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.BLOCKED.value) {
      (videoConfig.controls.videoSkippability as FormArray).push(this.fb.control('BLOCKED'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.UNKNOWN !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.UNKNOWN.value) {
      (videoConfig.controls.videoSkippability as FormArray).push(this.fb.control('UNKNOWN'));
    }

    if (typeof this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM.value) {
      (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('IN_STREAM'));
      if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL !== 'undefined' &&
        this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.value) {
        (videoConfig.controls.startDelays as FormArray).push(this.fb.control('PRE_ROLL'));
      }
      if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL !== 'undefined' &&
        this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.value) {
        (videoConfig.controls.startDelays as FormArray).push(this.fb.control('MID_ROLL'));
      }
      if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL !== 'undefined' &&
        this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.value) {
        (videoConfig.controls.startDelays as FormArray).push(this.fb.control('POST_ROLL'));
      }
      if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN !== 'undefined' &&
        this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.value) {
        (videoConfig.controls.startDelays as FormArray).push(this.fb.control('UNKNOWN'));
      }
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.OUT_STREAM !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.OUT_STREAM.value) {
      (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('OUT_STREAM'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.UNKNOWN !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.UNKNOWN.value) {
      (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('UNKNOWN'));
    }
    // if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoSkippability.controls.BOTH !== 'undefined' &&
    //   this.newCampaignForm.controls.videoConfig['controls'].deliveryTypes.controls.BOTH.value) {
    //   (videoConfig.controls.deliveryTypes as FormArray).push(this.fb.control('BOTH'));
    // }
    // if ((videoConfig.controls.placementTypes as FormArray).length === 0) {
    //   (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('IN_STREAM'));
    //   (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('OUT_STREAM'));
    // }

    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_ON !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_ON.value) {
      (videoConfig.controls.videoPlaybackModes as FormArray).push(this.fb.control('AUTOMATIC_SOUND_ON'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_OFF !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.AUTOMATIC_SOUND_OFF.value) {
      (videoConfig.controls.videoPlaybackModes as FormArray).push(this.fb.control('AUTOMATIC_SOUND_OFF'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_CLICK !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_CLICK.value) {
      (videoConfig.controls.videoPlaybackModes as FormArray).push(this.fb.control('ON_CLICK'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_HOVER !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.ON_HOVER.value) {
      (videoConfig.controls.videoPlaybackModes as FormArray).push(this.fb.control('ON_HOVER'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.UNKNOWN !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].videoPlaybackModes.controls.UNKNOWN.value) {
      (videoConfig.controls.videoPlaybackModes as FormArray).push(this.fb.control('UNKNOWN'));
    }

    if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.PRE_ROLL.value) {
      (videoConfig.controls.inStreamOptions['controls'].startDelays as FormArray).push(this.fb.control('PRE_ROLL'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.MID_ROLL.value) {
      (videoConfig.controls.inStreamOptions['controls'].startDelays as FormArray).push(this.fb.control('MID_ROLL'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.POST_ROLL.value) {
      (videoConfig.controls.inStreamOptions['controls'].startDelays as FormArray).push(this.fb.control('POST_ROLL'));
    }
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].startDelays.controls.UNKNOWN.value) {
      (videoConfig.controls.inStreamOptions['controls'].startDelays as FormArray).push(this.fb.control('UNKNOWN'));
    }
    this.newCampaignForm.removeControl('videoConfig');
    this.newCampaignForm.addControl('videoConfig', videoConfig);

    if (this.optimizationType === 'algorithmic') {
      this.newCampaignForm.removeControl('machineLearningOptimization');
    } else if (this.optimizationType === 'machineLearning') {
      this.newCampaignForm.removeControl('algorithmicOptimization');
      if (this.newCampaignForm.controls.machineLearningOptimization.get('optimizationMetric').value !== 'CPA') {
        this.newCampaignForm.removeControl('machineLearningOptimization.learningScope');
      }
    } else {
      this.newCampaignForm.removeControl('machineLearningOptimization');
      this.newCampaignForm.removeControl('algorithmicOptimization');
    }

    const pagePositions = this.fb.array([]);
    if (typeof this.newCampaignForm.controls.pagePositions['controls'].above_the_fold !== 'undefined' &&
      this.newCampaignForm.controls.pagePositions['controls'].above_the_fold.value) {
      (pagePositions).push(this.fb.control('above_the_fold'));
    }
    if (typeof this.newCampaignForm.controls.pagePositions['controls'].below_the_fold !== 'undefined' &&
      this.newCampaignForm.controls.pagePositions['controls'].below_the_fold.value) {
      (pagePositions).push(this.fb.control('below_the_fold'));
    }
    if (typeof this.newCampaignForm.controls.pagePositions['controls'].unknown !== 'undefined' &&
      this.newCampaignForm.controls.pagePositions['controls'].unknown.value) {
      (pagePositions).push(this.fb.control('unknown'));
    }
    this.newCampaignForm.removeControl('pagePositions');
    this.newCampaignForm.addControl('pagePositions', pagePositions);

    const trafficMultipliers = [];
    const trafficTypes = this.fb.array([]);
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].mobile_app !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].mobile_app.value) {
      (trafficTypes).push(this.fb.control('mobile_app'));
      if (this.useBidMultipliers) {
        trafficMultipliers.push({
          target: 'TRAFFIC_TYPE',
          multiplier: this.mobileAppBid,
          trafficType: 'MOBILE_APP'
        });
      }
    }
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].mobile_optimized_web !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].mobile_optimized_web.value) {
      (trafficTypes).push(this.fb.control('mobile_optimized_web'));
      if (this.useBidMultipliers) {
        trafficMultipliers.push({
          target: 'TRAFFIC_TYPE',
          multiplier: this.mobileOptimizedWebBid,
          trafficType: 'MOBILE_OPTIMIZED_WEB'
        });
      }
    }
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].mobile_web !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].mobile_web.value) {
      (trafficTypes).push(this.fb.control('mobile_web'));
      if (this.useBidMultipliers) {
        trafficMultipliers.push({
          target: 'TRAFFIC_TYPE',
          multiplier: this.mobileWebBid,
          trafficType: 'MOBILE_WEB'
        });
      }
    }
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].web !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].web.value) {
      (trafficTypes).push(this.fb.control('web'));
      if (this.useBidMultipliers) {
        trafficMultipliers.push({
          target: 'TRAFFIC_TYPE',
          multiplier: this.webBid,
          trafficType: 'WEB'
        });
      }
    }
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].other !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].other.value) {
      (trafficTypes).push(this.fb.control('other'));
    }
    if (typeof this.newCampaignForm.controls.trafficTypes['controls'].email !== 'undefined' &&
      this.newCampaignForm.controls.trafficTypes['controls'].email.value) {
      (trafficTypes).push(this.fb.control('email'));
    }
    // this.newCampaignForm.removeControl('trafficTypes');
    // this.newCampaignForm.addControl('trafficTypes', trafficTypes);

    let impressionsMultiplier = null;
    if (this.useBidMultipliers) {
      let count = 0;
      const multipliers = [];
      if (this.frequencyCap === 'on') {
        this.gridImpressionsMultiplier.forEachNode(node => {
          multipliers.push({
            target: 'FREQUENCY_CAPPING',
            multiplier: node.data.multiplier,
            fromImpression: count,
            toImpression: count
          })
          count++;
        });
      }
      impressionsMultiplier = {
        bidMultiplierRules: [...multipliers, ...trafficMultipliers],
        minAdjustedBidInCpm: 0,
        maxAdjustedBidInCpm: this.newCampaignForm.controls.maxBid.value
      };
    }

    if (typeof this.locationsGrid !== 'undefined') {
      this.locationsGrid.forEachNode((node, index) => {
        (this.newCampaignForm.controls.geoTargeting as FormArray).push(this.fb.group(
          JSON.parse(node.data.codes)
        ))
      });
    }

    if (this.contextualRules.length > 0) {
      this.contextualTargetingFilling(this.contextualRules);
      // for (const rule of this.contextualRules) {
      //   this.fullfillContextualTargeting(rule, rule.type);
      // }
    }

    if (this.brandProtectionRules.length > 0) {
      this.contextualTargetingFilling(this.brandProtectionRules);
      // for (const rule of this.brandProtectionRules) {
      //   this.fullfillContextualTargeting(rule, rule.type);
      // }
    } else if (this.newCampaignForm.get('contextualTargeting.providerIds').value.length === 0) {
      (this.newCampaignForm.get('contextualTargeting.providerIds') as FormArray).clear();
      (this.newCampaignForm.get('contextualTargeting.compositeContextualRule.rules') as FormArray).clear();
      // contextualTargeting: this.fb.group({
      //   providerIds: this.fb.array([]),
      //   compositeContextualRule: this.fb.group({
      //     type: ['composite'],
      //     logicalOperator: ['and'],
      //     rules: this.fb.array([])
      //   })
      // }),
    }

    if (typeof this.hyperlocalGrid !== 'undefined') {
      this.hyperlocalGrid.forEachNode((node, index) => {
        let distance = node.data.radius;
        let typeMeasure = 'meters';
        if (this.hyperlocalRadiusUnits === 'km') {
          distance = parseFloat(distance) * 1000;
        } else if (this.hyperlocalRadiusUnits === 'ml') {
          distance = parseFloat(distance) * 1609.34;
        } else if (this.hyperlocalRadiusUnits === 'ft') {
          distance = parseFloat(distance);
          typeMeasure = 'feet'
        }
        (this.newCampaignForm.controls.hyperlocalTargeting as FormArray).push(this.fb.group({
          latitude: [node.data.lat],
          longitude: [node.data.lng],
          distance: [distance],
          unit: [typeMeasure],
          blocked: [(node.data.target === 'Targeted') ? false : true],
          active: [(node.data.status === 'Yes') ? true : false],
          label: node.data.address
        }));
      });
    }

    if (typeof  this.carriersTargetGrid !== 'undefined') {
      this.carriersTargetGrid.forEachNode((node, index) => {
        (this.newDevicesForm.controls.deviceTargeting.controls.connection.controls.carrierCodes as FormArray).push(this.fb.control(node.data.id));
      });
    }
    if (this.wifiEnabled) {
      (this.newDevicesForm.controls.deviceTargeting.controls.connection.controls.connectionTypes as FormArray).push(this.fb.control('WIFI'));
    }
    if (this.mobileDataEnabled) {
      (this.newDevicesForm.controls.deviceTargeting.controls.connection.controls.connectionTypes as FormArray).push(this.fb.control('CELLULAR_DATA'));
    }
    if (this.unknownConnectionEnabled) {
      (this.newDevicesForm.controls.deviceTargeting.controls.connection.controls.connectionTypes as FormArray).push(this.fb.control('UNKNOWN'));
    }

    if (typeof  this.linkedAdsGrid !== 'undefined') {
      this.linkedAdsGrid.forEachNode((node, index) => {
        (this.newLinkAdsForm.controls.ads as FormArray).push(this.fb.group({
          creativeId: [node.data.creativeId],
          schedules: this.fb.array([])
        }));
        if (typeof node.data.flightDates !== 'undefined' && node.data.flightDates !== null && node.data.flightDates !== '') {
          for (const date of node.data.flightDates) {
            // @ts-ignore
            (this.newLinkAdsForm.controls.ads.controls[index].controls.schedules as FormArray).push(this.fb.group({
              flightDates: this.fb.group(
                typeof date.end !== 'undefined' && date.end !== null && date.end !== ''
                ? {from: date.begin.format('YYYYMMDD HH:mm:ss'), to: date.end.format('YYYYMMDD 23:59:59')}
                : {from: date.begin.format('YYYYMMDD HH:mm:ss')}
              )
            }));
          }
        }
      });
    }

    const toSendDeviceForm = this.newDevicesForm.value;

    if (!this.deviceOSVersion) {
      toSendDeviceForm.deviceTargeting.softwares = [];
    }
    if (!this.deviceMake) {
      toSendDeviceForm.deviceTargeting.hardwares = [];
    }
    if (!this.deviceBrowser) {
      toSendDeviceForm.deviceTargeting.browsers = [];
    }
    if (!this.deviceBrowserLang) {
      toSendDeviceForm.deviceTargeting.languages = [];
    }
    if (!this.deviceType) {
      toSendDeviceForm.deviceTargeting.deviceTypes = [];
    }

    if (toSendDeviceForm.deviceTargeting.softwares.length > 0) {
      for (const data of toSendDeviceForm.deviceTargeting.softwares) {
        if (data.osVersionTargets.length > 0) {
          data.osVersionTargets[0].from.major = parseFloat(data.osVersionTargets[0].from.major);
          data.osVersionTargets[0].to.major = parseFloat(data.osVersionTargets[0].to.major);
        }
      }
    }

    const toSendCampaignForm = {...this.newCampaignForm.value};
    toSendCampaignForm.budget.evenDeliveryEnabled = toSendCampaignForm.budget.pacingPriority === '1';
    if (this.frequencyCap !== 'on') {
      toSendCampaignForm.frequencyCapping = null;
    }
    toSendCampaignForm.trafficTypes = trafficTypes.value;
    if (typeof this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM !== 'undefined' &&
      this.newCampaignForm.controls.videoConfig['controls'].placementTypes.controls.IN_STREAM.value) {
      (videoConfig.controls.placementTypes as FormArray).push(this.fb.control('IN_STREAM'));
    }
    if (typeof this.budgetDateRange === 'undefined' || this.budgetDateRange === null || this.budgetDateRange.begin === null) {
      toSendCampaignForm.budget = {...this.newCampaignForm.value.budget}
      delete toSendCampaignForm.budget.schedule;
    }
    if (this.newCampaignForm.get('budget.pacingPriority').value === 0 || this.newCampaignForm.get('budget.pacingPriority').value === '0') {
      delete toSendCampaignForm.budget.pacingPriority;
    }
    if (typeof toSendCampaignForm.budget.amount !== 'undefined') {
      toSendCampaignForm.budget.amount = parseFloat(toSendCampaignForm.budget.amount.replace(',', '')) * this.margin;
    }
    toSendCampaignForm.defaultBid = parseFloat(toSendCampaignForm.defaultBid.replace(',', '')) * this.margin;
    if (this.optimizationType === 'algorithmic') {
      switch (toSendCampaignForm.algorithmicOptimization.optimizationMetric) {
        case 'CTR':
          toSendCampaignForm.algorithmicOptimization.goalValue = toSendCampaignForm.algorithmicOptimization.goalValue / 100;
          break;
        case 'VCR':
          toSendCampaignForm.algorithmicOptimization.goalValue = toSendCampaignForm.algorithmicOptimization.goalValue / 100;
          break;
      }
    }
    if (this.trackConverstions === 'on') {
      toSendCampaignForm.viewthruConfig.payoutRate = this.newCampaignForm.get('viewthruConfig.payoutRate').value / 100;
    }
    toSendCampaignForm['crm_active'] = false;
    const selectedAudiences = this.newAudiencesForm.value;
    if (typeof selectedAudiences.compositeAudienceTargetingRule !== 'undefined' && selectedAudiences.compositeAudienceTargetingRule !== null
      && typeof selectedAudiences.compositeAudienceTargetingRule.rules !== 'undefined' && selectedAudiences.compositeAudienceTargetingRule.rules !== null
      && selectedAudiences.compositeAudienceTargetingRule.rules.length > 0) {
      for (const rule of selectedAudiences.compositeAudienceTargetingRule.rules) {
        if (typeof rule.rules !== 'undefined' && rule.rules !== null && rule.rules.length > 0) {
          for (const subRule of rule.rules) {
            if (this.audiencesCrm.findIndex((item) => item.audienceId === subRule.audienceId ) > -1) {
              toSendCampaignForm['crm_active'] = true;
              break;
            }
          }
          if (toSendCampaignForm['crm_active']) {
            break;
          }
        }
      }
    }
    if (this.campaignId !== null) {
      this.campaignService.updateCampaign({
        campaignForm: toSendCampaignForm,
        deviceForm: toSendDeviceForm,
        audienceForm: this.newAudiencesForm.value,
        inventoryForm: this.newInventoryForm.value,
        linkedAdsForm: this.newLinkAdsForm.value,
        impressionsMultiplier: impressionsMultiplier,
        onlyTrackableDevices: this.onlyTrackableDevices
      } as unknown as Campaign, this.campaignId).subscribe(
        (response) => {
          if (response.status.toLowerCase() === 'success') {
            this.valueChanged = this.devicesValueChanged = this.audiencesValueChanged = this.inventoryValueChanged = this.linkAdsValueChanged = false;
            localStorage.setItem('hyperlocalRadiusUnits_' + response['response'].campaign.campaignId, this.hyperlocalRadiusUnits);
            this.router.navigate(['/campaigns/' + this.brandId + '/' + this.groupId]);
            this.alert.alert('success', 'Campaign updated successfully.');
          }
          this.ngLoading = false;
        },
        (error) => {
          console.log(error, 'createCampaign');
          this.ngLoading = false;
        }
      )
    } else {
      this.campaignService.createCampaignTest({
        campaignForm: toSendCampaignForm,
        deviceForm: toSendDeviceForm,
        audienceForm: this.newAudiencesForm.value,
        inventoryForm: this.newInventoryForm.value,
        linkedAdsForm: this.newLinkAdsForm.value,
        impressionsMultiplier: impressionsMultiplier,
        onlyTrackableDevices: this.onlyTrackableDevices
      } as unknown as Campaign).subscribe(
        (response) => {
          this.valueChanged = this.devicesValueChanged = this.audiencesValueChanged = this.inventoryValueChanged = this.linkAdsValueChanged = false;
          if (response.status.toLowerCase() === 'success') {
            localStorage.setItem('hyperlocalRadiusUnits_' + response['response'].campaign.campaignId, this.hyperlocalRadiusUnits);
          }
          this.router.navigate(['/campaigns/' + this.brandId + '/' + this.groupId]);
          this.alert.alert('success', 'Campaign created successfully.');
          this.ngLoading = false;
        },
        (error) => {
          console.log(error, 'createCampaign');
          this.ngLoading = false;
        }
      )
    }
  }

  contextualTargetingFilling(rules: Array<any>) {
    // @ts-ignore
    const formControl = this.newCampaignForm.controls.contextualTargeting.controls.compositeContextualRule;
    const subRules = [];
    for (const rule of rules) {
      // @ts-ignore
      if (typeof rule.providerId !== 'undefined' && (this.newCampaignForm.controls.contextualTargeting.controls.providerIds as FormArray).value.findIndex((context) => context === rule.providerId) === -1) {
        // @ts-ignore
        (this.newCampaignForm.controls.contextualTargeting.controls.providerIds as FormArray).push(this.fb.control(rule.providerId));
      }
      if (subRules.length === 0) {
        subRules.push({
          type: 'atomic',
          // logicalOperator: ['and'],
          // rules: this.fb.array([])
          blocked: rule.blocked,
          minWeight: 100,
          contextualEntryId: rule.value
        });
      } else {
        if (rule.type === 'and') {
          const lastPos = subRules.length;
          const last = subRules[lastPos - 1];
          if (last.type === 'composite') {
            // @ts-ignore
            last.rules.push(
              {
                type: 'atomic',
                blocked: rule.blocked,
                minWeight: 100,
                contextualEntryId: rule.value
              }
            );
          } else {
            const posSubRules = [
              last,
              {
                type: 'atomic',
                blocked: rule.blocked,
                minWeight: 100,
                contextualEntryId: rule.value
              }
            ];
            subRules[lastPos - 1] = {
              type: 'composite',
              logicalOperator: 'and',
              rules: posSubRules
            };
          }
        } else {
          subRules.push({
            type: 'atomic',
            // logicalOperator: ['and'],
            // rules: this.fb.array([])
            blocked: rule.blocked,
            minWeight: 100,
            contextualEntryId: rule.value
          });
        }
      }
    }
    (formControl.controls.rules as FormArray).push(this.fb.group({
      type: ['composite'],
      logicalOperator: ['or'],
      rules: this.fb.array(subRules)
    }));
  }

  fullfillContextualTargeting(item, type: string, formControl: FormGroup = null) {
    if (formControl === null) {
      // @ts-ignore
      formControl = this.newCampaignForm.controls.contextualTargeting.controls.compositeContextualRule;
    }
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    if (typeof item.providerId !== 'undefined' && (this.newCampaignForm.controls.contextualTargeting.controls.providerIds as FormArray).value.findIndex((context) => context === item.providerId) === -1) {
      // @ts-ignore
      (this.newCampaignForm.controls.contextualTargeting.controls.providerIds as FormArray).push(this.fb.control(item.providerId));
    }
    // @ts-ignore
    const position = (formControl.controls.rules as FormArray).value.length;
    if (position > 1) {
      const currentValues = (formControl.controls.rules as FormArray).at(position - 1).value;
      if (currentValues.type === 'atomic') {
        (formControl.controls.rules as FormArray).removeAt(position - 1);
        (formControl.controls.rules as FormArray).push(this.fb.group({
          type: ['composite'],
          logicalOperator: [type],
          rules: this.fb.array([
            this.fb.group({
              type: ['atomic'],
              blocked: [currentValues.blocked],
              minWeight: [100],
              contextualEntryId: [currentValues.contextualEntryId]
            })
          ])
        }));
      }
      this.fullfillContextualTargeting(item, type, ((formControl.controls.rules as FormArray).at(position - 1) as FormGroup))
    } else {
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      (formControl.controls.logicalOperator as FormControl).setValue(type);
      // @ts-ignore
      (formControl.controls.rules as FormArray).push(this.fb.group({
        type: ['atomic'],
        blocked: [false],
        minWeight: [100],
        contextualEntryId: [item.value]
      }));
    }
  }

  enablePrimaryConversion(event: MatCheckboxChange) {
    this.toTrackConvertionsColumnApi.setColumnVisible('primary', event.checked);
    if (!event.checked) {
      this.primaryConvertionCheckbox.checked = false;
    }
  }

  optimizeForPrimaryConversion(event: MatCheckboxChange) {
    if (this.newCampaignForm.get('conversionConfig.ordered').value) {
      if (event.checked) {
        this.newCampaignForm.get('machineLearningOptimization.optimizationByPrimaryConversions').setValue('PRIMARY_CONVERSIONS');
      } else {
        this.newCampaignForm.get('machineLearningOptimization.optimizationByPrimaryConversions').setValue('ALL_CONVERSIONS');
      }
    } else {
      if (typeof this.primaryConvertionCheckbox !== 'undefined') {
        this.primaryConvertionCheckbox.checked = false;
      }
      this.alert.alert('error', 'To use this option, please select at least one primary conversion from the Conversions section.', 30000);
    }
  }

  browserSelectionChanged(event: MatSelectChange) {
    (this.newDevicesForm.controls.deviceTargeting.controls.browsers as FormArray).clear();
    for (const browser of event.value) {
      (this.newDevicesForm.controls.deviceTargeting.controls.browsers as FormArray).push(this.fb.control(browser));
    }
  }

  browserLanguagesSelected(event: {}) {
    (this.newDevicesForm.controls.deviceTargeting.controls.languages as FormArray).clear();
    // @ts-ignore
    for (const lang of event) {
      (this.newDevicesForm.controls.deviceTargeting.controls.languages as FormArray).push(this.fb.control(lang.code));
    }
  }

  linkedAdsBulkEdit() {
    this.bulkEditFlightDates = true;
    $('.add-ad-flight-dates').modal('show');
  }

  async reverseContextualFullfillment(rules: any) {
    const realRules = rules.rules;
    const firstSetRules = (typeof realRules[0] !== 'undefined') ? realRules[0].rules : [];
    const secondSetRules = (typeof realRules[1] !== 'undefined') ? realRules[1].rules : [];
    for (const rule of firstSetRules) {
      this.ngLoading = true;
      if (rule.type === 'composite') {
        for (let i = 0; i < rule.rules.length; i++) {
          const promiseData = await this.thirdSourceService.getContextualSegmentData(rule.rules[i].contextualEntryId).toPromise();
          const data = new TreeviewItem({
            text: (typeof promiseData[0].name !== 'undefined' && promiseData[0].name !== null) ? promiseData[0].name.toString() : '',
            value: promiseData[0].contextualEntryId, collapsed: true, children: null
          });
          data['targetable'] = promiseData[0].targetable;
          data['blocked'] = rule.rules[i].blocked;
          data['type'] = (i > 0) ? 'and' :  'or';
          if (promiseData[0].targetable) {
            data['parentId'] = promiseData[0].parentId;
            data['parentName'] = promiseData[0].parentName;
            data['providerId'] = promiseData[0].providerId;
            data['providerName'] = promiseData[0].providerName;
          }
          if (promiseData[0].type === 'contextual') {
            this.contextualRules.push(data);
          } else {
            this.brandProtectionRules.push(data);
          }
        }
      } else {
        const promiseData = await this.thirdSourceService.getContextualSegmentData(rule.contextualEntryId).toPromise();
        const data = new TreeviewItem({
          text: (typeof promiseData[0].name !== 'undefined' && promiseData[0].name !== null) ? promiseData[0].name.toString() : '',
          value: promiseData[0].contextualEntryId, collapsed: true, children: null
        });
        data['targetable'] = promiseData[0].targetable;
        data['blocked'] = rule.blocked;
        data['type'] = 'or';
        if (promiseData[0].targetable) {
          data['parentId'] = promiseData[0].parentId;
          data['parentName'] = promiseData[0].parentName;
          data['providerId'] = promiseData[0].providerId;
          data['providerName'] = promiseData[0].providerName;
        }
        if (promiseData[0].type === 'contextual') {
          this.contextualRules.push(data);
        } else {
          this.brandProtectionRules.push(data);
        }
      }
    }
    for (const rule of secondSetRules) {
      if (rule.type === 'composite') {
        for (let i = 0; i < rule.rules.length; i++) {
          const promiseData = await this.thirdSourceService.getContextualSegmentData(rule.rules[i].contextualEntryId).toPromise();
          const data = new TreeviewItem({
            text: (typeof promiseData[0].name !== 'undefined' && promiseData[0].name !== null) ? promiseData[0].name.toString() : '',
            value: promiseData[0].contextualEntryId, collapsed: true, children: null
          });
          data['targetable'] = promiseData[0].targetable;
          data['blocked'] = rule.rules[i].blocked;
          data['type'] = (i > 0) ? 'and' :  'or';
          if (promiseData[0].targetable) {
            data['parentId'] = promiseData[0].parentId;
            data['parentName'] = promiseData[0].parentName;
            data['providerId'] = promiseData[0].providerId;
            data['providerName'] = promiseData[0].providerName;
          }
          if (promiseData[0].type === 'contextual') {
            this.contextualRules.push(data);
          } else {
            this.brandProtectionRules.push(data);
          }
        }
      } else {
        const promiseData = await this.thirdSourceService.getContextualSegmentData(rule.contextualEntryId).toPromise();
        const data = new TreeviewItem({
          text: (typeof promiseData[0].name !== 'undefined' && promiseData[0].name !== null) ? promiseData[0].name.toString() : '',
          value: promiseData[0].contextualEntryId, collapsed: true, children: null
        });
        data['targetable'] = promiseData[0].targetable;
        data['blocked'] = rule.blocked;
        data['type'] = 'or';
        if (promiseData[0].targetable) {
          data['parentId'] = promiseData[0].parentId;
          data['parentName'] = promiseData[0].parentName;
          data['providerId'] = promiseData[0].providerId;
          data['providerName'] = promiseData[0].providerName;
        }
        if (promiseData[0].type === 'contextual') {
          this.contextualRules.push(data);
        } else {
          this.brandProtectionRules.push(data);
        }
      }
    }

    if (this.contextualRules.length > 0) {
      this.contextual = true;
      this.ngLoading = true;
      this.getContextualData();
    }

    if (this.brandProtectionRules.length > 0) {
      this.brandProtection = true;
      this.ngLoading = true;
      this.getBrandProtectionData();
    }

    this.ngLoading = false;
  }

  reverseContextualFullfillmentOld(rule, logicalOperator = null) {
    this.contextualRules = [];
    const ruleFormat = {type: 'or', providerName: '', parentName: '', providerId: '', parentId: '', text: '', blocked: true, value: ''};
    if (rule.type === 'composite') {
      for (const subRule of rule.rules) {
        // this.reverseContextualFullfillment(subRule, rule.logicalOperator);
      }
    } else {
      this.thirdSourceService.getContextualSegmentData(rule.contextualEntryId).subscribe(
        (response) => {
          const data = new TreeviewItem({
            text: (typeof response[0].name !== 'undefined' && response[0].name !== null) ? response[0].name.toString() : '',
            value: response[0].contextualEntryId, collapsed: true, children: null
          });
          data['targetable'] = response[0].targetable;
          data['blocked'] = rule.blocked;
          data['type'] = (logicalOperator !== null) ? logicalOperator : 'or';
          if (response[0].targetable) {
            data['parentId'] = response[0].parentId;
            data['parentName'] = response[0].parentName;
            data['providerId'] = response[0].providerId;
            data['providerName'] = response[0].providerName;
          }
          this.contextualRules.push(data);
        },
        (error) => {
          console.log(error, 'getContextualData');
        }
      )
    }
  }

  reverseAudiencesFullfillment(rule) {
    this.contextualRules = [];
    if (rule.type === 'composite') {
      for (const subRule of rule.rules) {
        this.reverseAudiencesFullfillment(subRule);
      }
    } else {
      if (rule.audienceType === 'FIRST_PARTY') {
        let providerName = 'My Audiences';
        let aud = this.audiences.find((item) => item.audienceId === rule.audienceId );
        if (typeof aud === 'undefined' || aud === null) {
          aud = this.audiencesCrm.find((item) => item.audienceId === rule.audienceId );
          providerName = 'Crm Audiences';
        }
        this.newAudiencesFormextraData[aud.audienceId] = {
          checked: true,
          text: aud.name,
          value: aud.audienceId,
          level: 1,
          targetable: true,
          providerId: 0,
          providerName: providerName,
        };
      } else {
        this.thirdSourceService.getAudienceSegmentData(rule.audienceId).subscribe(
          (response) => {
            if (response[1] === 200 || response[1] === 201) {
              response[0]['text'] = response[0]['name'];
              this.newAudiencesFormextraData[response[0].demographicId] = response[0];
            }
          },
          (error) => {
            console.log(error, 'getAudienceSegmentData');
          }
        )
      }
    }
  }

  hyperlocalRadiusUnitsChanged(event: any) {
    let toMult = 1000;
    if (event === 'ml' && this.hyperlocalRadiusUnits === 'km') {
      toMult = 0.62;
    } else if (event === 'ft' && this.hyperlocalRadiusUnits === 'km') {
      toMult = 3280.84;
    } else if (event === 'km' && this.hyperlocalRadiusUnits === 'm') {
      toMult = 0.001;
    } else if (event === 'ml' && this.hyperlocalRadiusUnits === 'm') {
      toMult = 0.00062;
    } else if (event === 'ft' && this.hyperlocalRadiusUnits === 'm') {
      toMult = 3.28084;
    } else if (event === 'km' && this.hyperlocalRadiusUnits === 'ml') {
      toMult = (1 / 0.62);
    } else if (event === 'm' && this.hyperlocalRadiusUnits === 'ml') {
      toMult = (1 / 0.62) * 1000;
    } else if (event === 'ft' && this.hyperlocalRadiusUnits === 'ml') {
      toMult = (1 / 0.62) * 1000 * 3.28084;
    } else if (event === 'km' && this.hyperlocalRadiusUnits === 'ft') {
      toMult = (1 / 3280.84);
    } else if (event === 'm' && this.hyperlocalRadiusUnits === 'ft') {
      toMult = (1 / 3280.84) * 1000;
    } else if (event === 'ml' && this.hyperlocalRadiusUnits === 'ft') {
      toMult = 1 / ((1 / 0.62) * 1000 * 3.28084);
    }
    this.hyperlocalRadiusUnits = event;
    this.hyperlocalGrid.forEachNode((node, index) => {
      node.data.radius = node.data.radius * toMult;
    });
    this.hyperlocalGrid.refreshCells();
  }

  hyperlocalCellChanged(event: any) {
    // this.markers[1].options.radius = parseFloat(event.newValue) * 1000;
    this.markers[event.rowIndex * 2 + 1] = L.circle([event.data.lat, event.data.lng], {radius: parseFloat(event.newValue) * 1000});
    this.valueChanged = true;
    this.initMap();
  }

  dateConvert(date, format, toFormat) {
    return moment(date, format).format(toFormat);
  }

  getAmountLocationsByType() {
    const data = {dmaCode: 0, city: 0, regionName: 0, countryName: 0, postalCode: 0};
    this.locationsGrid.forEachNode((node, index) => {
      const properties = JSON.parse(node.data.codes);
      if (typeof properties.dmaCode !== 'undefined') {
        data.dmaCode++;
      } else if (typeof properties.city !== 'undefined') {
        data.city++;
      } else if (typeof properties.regionName !== 'undefined') {
        data.regionName++;
      } else if (typeof properties.countryName !== 'undefined') {
        data.countryName++;
      } else if (typeof properties.postalCode !== 'undefined') {
        data.postalCode++;
      }
    });
    return data;
  }

  private getConversionsText() {
    const data = [];
    if (typeof this.toTrackConvertionsGrid !== 'undefined') {
      this.toTrackConvertionsGrid.forEachNode((node, index) => {
        data.push(node.data.name)
      });
    }
    return data;
  }

  goToStep(stepper: MatHorizontalStepper, to) {
    const totalSteps = stepper.steps.length - 1;
    for (let i = totalSteps; i > to; i--) {
      stepper.previous();
    }
  }

  selectedMake(event: MatAutocompleteSelectedEvent, makeInput: HTMLInputElement) {
    this.selectedDeviceMakes.push(event.option.value);
    this.makeInputControl.setValue(null);
    makeInput.value = '';
    this.modelsLocal.push(...event.option.value.models.map(i => ({make: event.option.value.make, model: i})));
    (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray).push(this.fb.group({
      make: [event.option.value.make],
      models: this.fb.array([])
    }))
  }

  removeMake(make: any) {
    this.selectedDeviceMakes.splice(this.selectedDeviceMakes.findIndex(i => i.make === make.make), 1);
    (this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray)
      .removeAt(this.newDevicesForm.get('deviceTargeting.hardwares').value.findIndex(i => i.make === make.make))
    this.selectedDeviceModels = this.selectedDeviceModels.filter(i => i.make !== make.make);
  }

  removeModel(model: any) {
    const makePos = this.newDevicesForm.get('deviceTargeting.hardwares').value.findIndex(i => i.make === model.make);
    const modelPos = this.newDevicesForm.get('deviceTargeting.hardwares').value[makePos].models.findIndex(i => i === model.model);
    ((this.newDevicesForm.controls.deviceTargeting.controls.hardwares as FormArray)
      .at(makePos).get('models') as FormArray)
      .removeAt(modelPos);
    this.selectedDeviceModels.splice(modelPos, 1);
  }

  selectedModel(event: MatAutocompleteSelectedEvent, modelInput: HTMLInputElement) {
    this.selectedDeviceModels.push(event.option.value);
    this.modelInputControl.setValue(null);
    modelInput.value = '';
    const makePos = this.newDevicesForm.get('deviceTargeting.hardwares').value.findIndex(i => i.make === event.option.value.make);
    ((this.newDevicesForm.get('deviceTargeting.hardwares') as FormArray)
      .at(makePos).get('models') as FormArray)
      .push(this.fb.control(event.option.value.model));
  }

  pacingChanged(event: MatRadioChange) {
    if (event.value > 0) {
      this.flightDateRangeType = 'range';
      if (this.newCampaignForm.get('budget.schedule.isRelativeDayParting').value) {
        this.newCampaignForm.get('budget.schedule.isRelativeDayParting').setValue(false);
        this.alert.alert('error', 'Day Parting will not use local times because you need to set Campaign Pacing off.', 10000);
      }
      if (typeof this.budgetDateRange === 'undefined' || this.budgetDateRange === null) {
        this.budgetDateRange = {
          begin: moment(),
          end: moment().add(1, 'months')
        };
      }
    } else if (this.newCampaignForm.value.budget.type === 'daily') {
      // this.flightDateRangeType = 'all';
      // this.budgetDateRange = {
      //   begin: null,
      //   end: null
      // };
    }
  }

  addToOutThirdParty(item: any, event: any) {
    if (item.targetable) {
      if (event) {
        this.treeItemCheckedOut.push(item);
      } else {
        this.treeItemCheckedOut.splice(this.treeItemCheckedOut.findIndex(i => i.value === item.value), 1);
      }
    }
  }

  deleteLocationSet(id) {
    this.ngLoading = true;
    this.campaignService.deleteLocationsSet(id).subscribe(
      response => { this.getLocationSets(); this.ngLoading = false; },
      error => console.log(error)
    );
  }

  deleteAudienceSet(id) {
    this.ngLoading = true;
    this.campaignService.deleteAudienceSet(id).subscribe(
      response => { this.getAudienceSets(); this.ngLoading = false; },
      error => console.log(error)
    );
  }

  deleteContextualSet(id) {
    this.ngLoading = true;
    this.campaignService.deleteContextualSet(id).subscribe(
      response => { this.getContextualSets(); this.ngLoading = false; },
      error => console.log(error)
    );
  }

  getLocationSets() {
    this.campaignService.getLocationsSet().subscribe(
      response => {
        // @ts-ignore
        this.locationsSets = response;
      }
    )
  }

  getContextualSets() {
    this.campaignService.getContextualsSet().subscribe(
      response => {
        // @ts-ignore
        this.contextualSets = response.filter(i => i.type === 'contextual');
        // @ts-ignore
        this.brandProtectionSets = response.filter(i => i.type === 'brand');
      }
    )
  }

  addLocationSet(pos: number) {
    this.locationsGrid.setRowData([]);
    const res = this.locationsGrid.updateRowData({ add: JSON.parse(this.locationsSets[pos].locations) });
    this.valueChanged = true;
    $('.add-locations-set').modal('hide');
  }

  addAudienceSet(pos: number) {
    Swal.fire({
      title: 'Overwrite current selection?',
      text: 'Adding a set will overwrite the selected items',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#51cbce',
      cancelButtonColor: '#ef8157',
      confirmButtonText: 'Add Set'
    }).then((confirm) => {
      if (typeof confirm.value !== 'undefined' && confirm.value) {
        this.newAudiencesForm = this.fb.group({
          compositeAudienceTargetingRule: this.buildForm.buildForm(JSON.parse(this.audienceSets[pos].set)['compositeAudienceTargetingRule'])
        });
        this.reverseAudiencesFullfillment(JSON.parse(this.audienceSets[pos].set)['compositeAudienceTargetingRule']);
      }
    });
  }

  addContextualSet(pos: number) {
    this.contextualRules = JSON.parse(this.contextualSets[pos].segments);
    this.valueChanged = true;
  }

  addBrandContextualSet(pos: number) {
    this.brandProtectionRules = JSON.parse(this.brandProtectionSets[pos].segments);
    this.valueChanged = true;
  }

  saveLocationSet(value: string) {
    this.ngLoading = true;
    const locations = [];
    this.locationsGrid.forEachNode((node, index) => {
      locations.push(node.data);
    });
    if (locations.length > 0) {
      this.campaignService.setLocationsSet(value, locations).subscribe(
        response => {
          $('.save-locations-set').modal('hide');
          this.getLocationSets();
          this.ngLoading = false;
        }
      )
    }
  }

  saveContextualSet(input: HTMLInputElement, type = 'contextual') {
    this.ngLoading = true;
    const rules = type === 'brand' ? this.brandProtectionRules : this.contextualRules;
    if (rules.length > 0) {
      this.campaignService.setContextualsSet(input.value, rules, type).subscribe(
        response => {
          input.value = '';
          $('.save-contextuals-set').modal('hide');
          $('.save-brand-set').modal('hide');
          this.getContextualSets();
          this.ngLoading = false;
        }
      )
    }
  }

  saveAudienceSet(input: HTMLInputElement) {
    this.ngLoading = true;
    this.campaignService.setAudienceSet(input.value, this.newAudiencesForm.value).subscribe(
      response => {
        input.value = '';
        $('.save-audience-set').modal('hide');
        this.getAudienceSets();
        this.ngLoading = false;
      }
    );
  }

  budgetTypeChanged(event: MatRadioChange) {
    if (event.value === 'all_time') {
      this.flightDateRangeType = 'range';
      if (typeof this.budgetDateRange === 'undefined' || this.budgetDateRange === null) {
        this.budgetDateRange = {
          begin: moment(),
          end: moment().add(1, 'months')
        };
      }
    } else {
      // this.flightDateRangeType = 'all';
      // this.budgetDateRange = {
      //   begin: null,
      //   end: null
      // };
    }
  }

  optimizationChanged(event: MatRadioChange) {
    if (event.value === 'none' || event.value === 'machineLearning') {
      this.newCampaignForm.get('algorithmicOptimization').patchValue({
        optimizationMetric: 'CPC',
        goalValue: '5.00',
        maxBid: (Number(this.newCampaignForm.get('defaultBid').value) + 1) + '.00',
        minBid: (Number(this.newCampaignForm.get('defaultBid').value) - 1) + '.00',
        learnBudget: (Number(this.newCampaignForm.get('budget.amount').value) - 1) + '.00',
        stepOfBidAdjustment: this.newCampaignForm.get('defaultBid').value,
        thresholdOfImpressions: '1000',
        optimizeDomainLevel: true,
        turnOffPlacementsOptAtMinBid: true
      });
      this.newCampaignForm.get('algorithmicOptimization').updateValueAndValidity();
    }
    if (event.value === 'none' || event.value === 'algorithmic') {
      this.newCampaignForm.get('machineLearningOptimization').patchValue({
        optimizationMetric: 'CPC',
        goalValue: '5.00',
        maxBid: '10.00',
        optimizationByPrimaryConversions: null,
        learningScope: 'PIXEL'
      });
      this.newCampaignForm.get('machineLearningOptimization').updateValueAndValidity();
    }
  }

  checkPacing(event: MatCheckboxChange) {
    if (event.checked) {
      Swal.fire({
        title: 'Please Note!',
        text: 'Choosing specific daypart hours will disable campaign spend pacing. ' +
          'Please choose All Day if you would like your budget to be spend evenly across the campaign or creative flight dates.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#51cbce',
        confirmButtonText: 'Ok'
      });
    }
    if (event.checked
      && (this.newCampaignForm.get('budget.pacingPriority').value === 1
        || this.newCampaignForm.get('budget.pacingPriority').value === 2
        || this.newCampaignForm.get('budget.pacingPriority').value === '1'
        || this.newCampaignForm.get('budget.pacingPriority').value === '2')) {
      this.newCampaignForm.get('budget.schedule.isRelativeDayParting').setValue(false);
      this.alert.alert('error', 'Local times can not be used, you need to set Campaign Pacing off.', 10000);
    }
  }

  refreshConvertionsGrid($event: string) {
    $('.new-conversion-modal').modal('hide');
    this.getConversions();
  }

  stringNumberMinCustomValidator(fieldPath: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && typeof this.newCampaignForm !== 'undefined' && this.newCampaignForm !== null && typeof this.newCampaignForm.get(fieldPath) !== 'undefined' && this.newCampaignForm.get(fieldPath) !== null) {
        if (this.newCampaignForm.get(fieldPath).value !== null && Number(control.value) < this.newCampaignForm.get(fieldPath).value) {
          return { 'stringNumberMinCustom': true };
        }
      }
      return null;
    };
  }

  stringNumberMaxCustomValidator(fieldPath: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && typeof this.newCampaignForm !== 'undefined' && this.newCampaignForm !== null && typeof this.newCampaignForm.get(fieldPath) !== 'undefined' && this.newCampaignForm.get(fieldPath) !== null) {
        if (this.newCampaignForm.get(fieldPath).value !== null && Number(control.value) > this.newCampaignForm.get(fieldPath).value) {
          return { 'stringNumberMaxCustom': true };
        }
      }
      return null;
    };
  }

  addDeals(): void {
    this.marketplaceModal.isModal = true;
    $('.add-deals').modal('show');
  }

  getSelectedDeals(): void {
    const selectedDeals = this.marketplaceModal.getSelectedRows();
    this.rowDataDeals = new Observable((ob: Observer<Array<any>>) => {
      ob.next(selectedDeals.map(i => i.data));
      ob.complete();
    });
    this.newCampaignForm.get('deals').reset([]);
    for (const deal of selectedDeals) {
      (this.newCampaignForm.get('deals') as FormArray).push(this.fb.group({assetId: [deal.data.assetId]}));
    }
    $('.add-deals').modal('hide');
  }

  geoFilter($event: string) {
    console.log($event);
    if ($event.length >= 3) {
      this.ngLoading = true;
      this.itemsLocations = [];
      this.selectLocationSubs = this.geoService.getGeographicLocationsByFilter($event).subscribe(
        response => {
          let indexCountry = null;
          let indexRegion = null;
          for (const loc in response[0]) {
            indexCountry = this.itemsLocations.findIndex(i => response[0][loc].countryName === i['text']);
            if (indexCountry > -1) {
              if (typeof response[0][loc].primaryRegionName !== 'undefined') {
                indexRegion = this.itemsLocations[indexCountry].children.findIndex(i => response[0][loc].primaryRegionName === i['text']);
                if (indexRegion === -1) {
                  this.itemsLocations[indexCountry].children.push(new TreeviewItem({
                    text: response[0][loc].primaryRegionName,
                    value: JSON.stringify(response[0][loc]),
                    checked: false,
                    collapsed: true,
                    children: [{text: 'loading', value: JSON.stringify(response[0][loc])}]
                  }));
                  if (this.itemsLocations[indexCountry].children[0].text === 'loading') {
                    this.itemsLocations[indexCountry].children.shift();
                  }
                  indexRegion = this.itemsLocations[indexCountry].children.length - 1;
                  this.itemsLocations[indexCountry].children[indexRegion]['level'] = 1;
                  this.itemsLocations[indexCountry].children[indexRegion]['countryCode'] = response[0][loc].countryCode;
                  this.itemsLocations[indexCountry].children[indexRegion]['regionCode'] = response[0][loc].primaryRegionCode;
                  this.itemsLocations[indexCountry].children[indexRegion].setCheckedRecursive(false);
                  // this.itemsLocations[indexCountry].children[indexRegion].setCollapsedRecursive(false);
                }
                if (typeof response[0][loc].cityName !== 'undefined') {
                  this.itemsLocations[indexCountry].children[indexRegion].collapsed = false;
                  this.itemsLocations[indexCountry].children[indexRegion].children.push(new TreeviewItem({
                    text: response[0][loc].cityName,
                    value: JSON.stringify(response[0][loc]),
                    checked: false,
                    collapsed: true,
                    children: [{text: 'loading', value: JSON.stringify(response[0][loc])}]
                  }));
                  if (this.itemsLocations[indexCountry].children[indexRegion].children[0].text === 'loading') {
                    this.itemsLocations[indexCountry].children[indexRegion].children.shift();
                  }
                  const indexCity = this.itemsLocations[indexCountry].children[indexRegion].children.length - 1;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['level'] = 2;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['countryCode'] = response[0][loc].countryCode;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['cityName'] = response[0][loc].cityName;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['regionCode'] = response[0][loc].primaryRegionName;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['identifier'] = response[0][loc].geoNameId;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity].setCheckedRecursive(false);
                  // this.itemsLocations[indexCountry].children[indexRegion].children[indexCity].setCollapsedRecursive(false);
                } else {
                  this.itemsLocations[indexCountry].children[indexRegion]['identifier'] = response[0][loc].geoNameId;
                }
              }  else {
                this.itemsLocations[indexCountry]['identifier'] = response[0][loc].geoNameId;
              }
            } else {
              this.itemsLocations.push(new TreeviewItem({
                text: response[0][loc].countryName,
                value: JSON.stringify(response[0][loc]),
                checked: false,
                collapsed: true,
                children: [{text: 'loading', value: JSON.stringify(response[0][loc])}]
              }));
              indexCountry = this.itemsLocations.length - 1;
              this.itemsLocations[indexCountry]['level'] = 0;
              this.itemsLocations[indexCountry]['countryCode'] = response[0][loc].countryCode;
              this.itemsLocations[indexCountry].setCheckedRecursive(false);
              // this.itemsLocations[indexCountry].setCollapsedRecursive(false);
              if (typeof response[0][loc].primaryRegionName !== 'undefined') {
                this.itemsLocations[indexCountry].collapsed = false;
                this.itemsLocations[indexCountry].children.push(new TreeviewItem({
                  text: response[0][loc].primaryRegionName,
                  value: JSON.stringify(response[0][loc]),
                  checked: false,
                  collapsed: true,
                  children: [{text: 'loading', value: JSON.stringify(response[0][loc])}]
                }));
                if (this.itemsLocations[indexCountry].children[0].text === 'loading') {
                  this.itemsLocations[indexCountry].children.shift();
                }
                indexRegion = this.itemsLocations[indexCountry].children.length - 1;
                this.itemsLocations[indexCountry].children[indexRegion]['level'] = 1;
                this.itemsLocations[indexCountry].children[indexRegion]['countryCode'] = response[0][loc].countryCode;
                this.itemsLocations[indexCountry].children[indexRegion]['regionCode'] = response[0][loc].primaryRegionCode;
                this.itemsLocations[indexCountry].children[indexRegion].setCheckedRecursive(false);
                // this.itemsLocations[indexCountry].children[indexRegion].setCollapsedRecursive(false);

                if (typeof response[0][loc].cityName !== 'undefined') {
                  this.itemsLocations[indexCountry].children[indexRegion].collapsed = false;
                  this.itemsLocations[indexCountry].children[indexRegion].children.push(new TreeviewItem({
                    text: response[0][loc].cityName,
                    value: JSON.stringify(response[0][loc]),
                    checked: false,
                    collapsed: true,
                    children: []
                  }));
                  if (this.itemsLocations[indexCountry].children[indexRegion].children[0].text === 'loading') {
                    this.itemsLocations[indexCountry].children[indexRegion].children.shift();
                  }
                  const indexCity = this.itemsLocations[indexCountry].children[indexRegion].children.length - 1;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['level'] = 2;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['countryCode'] = response[0][loc].countryCode;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['cityName'] = response[0][loc].cityName;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['primaryRegionName'] = response[0][loc].primaryRegionName;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['regionCode'] = response[0][loc].primaryRegionCode;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity]['identifier'] = response[0][loc].geoNameId;
                  this.itemsLocations[indexCountry].children[indexRegion].children[indexCity].setCheckedRecursive(false);
                  // this.itemsLocations[indexCountry].children[indexRegion].children[indexCity].setCollapsedRecursive(false);
                } else {
                  this.itemsLocations[indexCountry].children[indexRegion]['identifier'] = response[0][loc].geoNameId;
                }
              } else {
                this.itemsLocations[indexCountry]['identifier'] = response[0][loc].geoNameId;
              }
            }
          }
          this.itemsLocations.sort(function(a, b) {
            return a.text.localeCompare(b.text);
          });
          console.log(this.itemsLocations)
          this.ngLoading = false;
        }, error => console.log(error)
      )
    }
  }
}

export function stringNumberMinValidator(min: number = null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined) {
      if (min !== null && Number(control.value) < min) {
        return { 'stringNumberMin': true };
      }
    }
    return null;
  };
}

export function stringNumberMaxValidator(max: number = null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined) {
      if (max !== null && Number(control.value) > max) {
        return { 'stringNumberMax': true };
      }
    }
    return null;
  };
}

@Injectable({providedIn: 'root'})
export class CanDeactivateGuard implements CanDeactivate<NewCampaignComponent> {
  canDeactivate(component: NewCampaignComponent): boolean {
    if (component.campaignId !== null && (
        component.valueChanged
        || component.devicesValueChanged
        || component.audiencesValueChanged
        || component.inventoryValueChanged
        || component.linkAdsValueChanged
      )) {
      if (confirm('Your changes haven’t been saved!  Do you want to continue?')) {
        component.valueChanged = component.devicesValueChanged = component.audiencesValueChanged = component.inventoryValueChanged = component.linkAdsValueChanged = false;
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
