import {Component, OnInit, ViewChild} from '@angular/core';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {CsvExportParams, Module} from 'ag-grid-community';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {ReportsService} from '../../_services/reports/reports.service';
import {Observable} from 'rxjs';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {AdReviewComponent} from '../../shared/ad-review/ad-review.component';
import {StatusReportCellRenderComponent} from '../../shared/cell-renderers/status-report-cell-render/status-report-cell-render.component';
import {NewDomainListModalComponent} from '../../modals/new-domain-list-modal/new-domain-list-modal.component';
import {NewScheduleReportModalComponent} from '../../modals/new-schedule-report-modal/new-schedule-report-modal.component';
import {report_types, report_type_dimension, report_type_dimension_codes, report_types_by_entity} from '../../_models/aux-data';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';

@Component({
  selector: 'app-schedule-reports',
  templateUrl: './schedule-reports.component.html',
  styleUrls: ['./schedule-reports.component.scss']
})
export class ScheduleReportsComponent implements OnInit {
  @ViewChild(NewScheduleReportModalComponent, null) newScheduleReportModal: NewScheduleReportModalComponent;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusReportCellRenderComponent,
    // totalCellRendererComponent: TotalCellRendererComponent,
    // numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    // budgetCellRendererComponent: BudgetCellRendererComponent,
    // adReviewComponent: AdReviewComponent
  };
  reportTypeDimension = report_type_dimension;
  reportTypeDimensionCodes = report_type_dimension_codes;
  reportTypesByEntity = report_types_by_entity;
  compParent = {componentParent: this};

  columnDefsOnce = [
    {headerName: 'Report', field: 'description', width: 500 },
    {headerName: 'Created On', field: 'completionTime', width: 150, cellRenderer: this.dateTimeCellRender },
    {headerName: 'Expires On', field: 'expiryTime', width: 150, cellRenderer: this.dateTimeCellRender },
    {headerName: 'Status', field: 'status', width: 100, cellRenderer: this.downloadLinkRender }
  ];
  rowDataOnce = [];
  gridApi;
  gridApiOne;
  ngLoading = false;

  columnDefsSchedule = [
    {headerName: 'Name', field: 'name', width: 500 },
    {
      headerName: 'Actions',
      field: 'id',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'ScheduleReport', copy: true }
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 100, filter: true,
      cellRenderer: 'statusCellRendererComponent',
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Starts', field: 'scheduleStartDate', width: 150, cellRenderer: this.dateCellRender },
    {headerName: 'Ends', field: 'scheduleEndDate', width: 150, cellRenderer: this.dateCellRender},
    {headerName: 'Repeats', field: 'aggregation', width: 100 },
    {headerName: 'Remaining Reports', field: 'remainingRuns', width: 100 },
  ];
  rowDataSchedule: any;
  title = 'schedule';
  selectedOption2: string[];
  selectedOption3: string[];

  defaultColDef;
  autoGroupColumnDef;
  rowSelection;
  groupSelectedRows = [];

  constructor(private reportsService: ReportsService, private alertService: AlertService, private gridRender: GridRenderService, private breadcrumbsService: BreadcrumbsService) { }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'Schedule Reports', url: '/schedule-reports', params: []},
    ];
    this.selectedOption2 = ['active', 'paused', 'failed'];
    this.selectedOption3 = ['DONE', 'IN_PROCESS', 'QUEUED', 'CANCELLED', 'FAILED'];
    this.loadReports();
    this.rowDataOnce = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
  }

  deleteScheduleRpt(params: any) {
    console.log('params:', params);
    this.reportsService.deleteScheduleReport(params.remove[0].id).subscribe(
      (response) => {
        console.log(response);
        this.rowDataOnce = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.loadReports();
        this.alertService.success('Schedule Report deleted.');
      });
  }

  dateCellRender(params: any) {
    return params.value.substr(4, 2) + '/' + params.value.substr(6, 2) + '/' + params.value.substr(0, 4);
  }

  dateTimeCellRender(date_time: any) {
    let params = date_time.value.split(' ')[0];
    return params.substr(4, 2) + '/' + params.substr(6, 2) + '/' + params.substr(0, 4) + ' ' + date_time.value.split(' ')[1];
  }

  downloadLinkRender(params: any) {
    console.log(params);
    if (params.value === 'DONE') {
      return '<a target="_blank" href="' + params.data.url + '">' + params.value + '</a>';
    } else {
      return params.value;
    }
  }

  updateStatusScheduleRpt(data: any) {
    this.ngLoading = true;
    console.log('data:', data);
    this.reportsService.changeScheduleReportStatus({id: data.id, status: data.status}).subscribe(
      (response) => {
        console.log(response);
        this.alertService.success('Report status was changed.');
        this.ngLoading = false;
      });
  }

  searchChange() {
    this.rowDataOnce = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.loadReports();
  }

  loadReports() {
    this.ngLoading = true;
    console.log($('#schedule_rpt_search').val());
    const params = {
      search: (typeof $('#schedule_rpt_search').val() !== 'undefined') ? $('#schedule_rpt_search').val().toString() : '',
      statuses: this.selectedOption2
    };
    this.reportsService.getScheduleReports(params).subscribe(
      (response) => {
        console.log(response);
        this.rowDataSchedule =  Observable.create((obs) => {
          obs.next(response.response.schedule_reports);
          obs.complete();
        });
        this.ngLoading = false;
      });
  }

  openScheduleModal() {
    this.newScheduleReportModal.previousSelected = [];
    this.openNewScheduleRptModal();
  }

  openNewScheduleRptModal() {
    $('#search_grid').val('');
    $('#search_grid_pixels').val('');
    this.newScheduleReportModal.newScheduleRptForm.reset();
    this.newScheduleReportModal.searchGroup = '';
    this.newScheduleReportModal.rowData = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.newScheduleReportModal.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.newScheduleReportModal.groupSelectedRows = [];
    this.newScheduleReportModal.pixelSelected = [];
    this.newScheduleReportModal.showConvPixels = false;
    $('.new-schedule-report-modal').modal('show');
  }

  refreshGrid(event: any) {

  }

  onScheduleGridReady(event: any) {
    this.gridApi = event.api;
  }

  onScheduleGridReadyOne(event: any) {
    this.gridApiOne = event.api;
  }

  loadSingleReports() {
    const node = this.gridApi.getSelectedNodes()[0];
    if (typeof node !== 'undefined') {
      this.ngLoading = true;
      const id = node.data.id.toString();
      const params = {
        id: id,
        search: $('#single_rpt_search').val().toString(),
        statuses: this.selectedOption3
      };
      this.reportsService.getReportsFromSchedule(params).subscribe(
        (response) => {
          console.log(response);
          this.rowDataOnce = Observable.create((obs) => {
            obs.next(response.response.reports);
            obs.complete();
          });
          this.ngLoading = false;
        });
    }
  }

  onCellClicked(node: any) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      console.log('Edit', node.data);
      this.openNewScheduleRptModal();
      this.newScheduleReportModal.formControls.name.setValue(node.data.name);
      this.newScheduleReportModal.formControls.emails.setValue(JSON.parse(node.data.emails).join());
      this.newScheduleReportModal.formControls.interval.setValue(node.data.frequency);
      this.newScheduleReportModal.formControls.group_by.setValue(node.data.groupByEntityType.toLowerCase());
      let str = '';
      if (node.data.lookbackWindow === 1) {
        str = 'PREVIOUS_' + node.data.lookbackType;
      } else {
        str = 'LAST_' + node.data.lookbackWindow + '_' + node.data.lookbackType + 'S';
      }
      this.newScheduleReportModal.formControls.duration.setValue(str);
      this.newScheduleReportModal.formControls.start_time.setValue(node.data.timeToRunReport);
      this.newScheduleReportModal.formControls.start_date.setValue(new Date(node.data.scheduleStartDate.substr(0, 4) + '-' + node.data.scheduleStartDate.substr(4, 2) + '-' + node.data.scheduleStartDate.substr(6, 2)));
      this.newScheduleReportModal.formControls.end_date.setValue(new Date(node.data.scheduleEndDate.substr(0, 4) + '-' + node.data.scheduleEndDate.substr(4, 2) + '-' + node.data.scheduleEndDate.substr(6, 2)));
      this.newScheduleReportModal.formControls.repeats.setValue(node.data.aggregation);
      this.newScheduleReportModal.formControls.conversion_pixels.setValue(node.data.conversionPixelIds !== null ? node.data.conversionPixelIds : false);
      let rpt_type = -1; let index = -1; let aux = 0;
      for (let arr of report_type_dimension_codes) {
        if (rpt_type === -1) {
          const ind = arr.indexOf(node.data.reportType);
          if (ind !== -1) {
            console.log(aux); console.log(index);
            rpt_type = ind;
            index = aux;
          }
        }
        aux++;
      }
      this.newScheduleReportModal.formControls.report_type.setValue(report_types[index]);
      this.newScheduleReportModal.formControls.dimensions.setValue(report_type_dimension_codes[index][rpt_type]);
      this.newScheduleReportModal.searchGroup = node.data.entityType.toLowerCase();
      $('#entityGroup').val(node.data.entityType.toLowerCase());
      this.newScheduleReportModal.previousSelected = JSON.parse(node.data.entityIds);
      console.log(JSON.parse(node.data.entityIds));
      this.newScheduleReportModal.loadBrands();
    } else {
      console.log(node.column.colId);
      console.log(node.event.target.className);
    }
  }

  groupRowSelected(event: any) {
    const node = event.api.getSelectedNodes()[0];
    this.loadSingleReports();
  }

  exportSchedules() {
    this.gridApi.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }
}
