import { Component, OnInit } from '@angular/core';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {FormBuilder} from '@angular/forms';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-conversion-get-tags',
  templateUrl: './conversion-get-tags.component.html',
  styleUrls: ['./conversion-get-tags.component.scss']
})
export class ConversionGetTagsComponent implements OnInit {

  loading = false;
  tagType = 'script';
  linkUrl = '';
  linkText = '';
  offerId: number;
  textCode: string;
  postbackCode: string;
  brandName = '';
  constructor(private convService: ConversionService, private fb: FormBuilder) { }

  ngOnInit() {
    this.tagType = 'script';
  }

  changeTagType(event) {
    this.tagType = event;
    this.getTagCode();
  }

  getTagCode() {
    this.loading = true;
    this.linkText = '';
    this.linkUrl = '';
    this.convService.getTagCode(this.tagType, this.offerId).subscribe(
      (response) => {
        this.loading = false;
        if (this.tagType === 'postback') {
            this.postbackCode = response.response.split('conv/')[1].split('?pb=')[0];
        }
        this.textCode = response.response;
      },
      (error) => { console.log(error, 'getTagCode'); this.loading = false; }
    )
  }

  addRedirectLink(event: any) {
    if (this.tagType === 'link') {
      this.textCode = '<a href=' + '"' + this.linkUrl + '" onclick' + this.textCode.split('onclick')[1];
      this.textCode = this.textCode.split('">')[0] + '">' + this.linkText + '</a>';
    } else {
      this.textCode = this.textCode.split('?r=')[0] + '?r=' + this.linkUrl + '">' + this.linkText + '</a>';
    }

  }

}
