import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserRestService} from '../../admin/users/user-rest.service';
import {AlertService} from '../../_services/alert.service';
import {ActivatedRoute} from '@angular/router';
import {CommonAuthService} from '../../auth/common-auth.service';
import {ClientService} from '../../_services/client/client.service';
import {SubscriptionService} from '../../_services/subscription/subscription.service';
declare var CollectJS;
declare var validCVV;
declare var validCard;
declare var validExp;

@Component({
  selector: 'app-add-balance-modal',
  templateUrl: './add-balance-modal.component.html',
  styleUrls: ['./add-balance-modal.component.scss']
})
export class AddBalanceModalComponent implements OnInit {

  ngLoading = false;
  paymentForm: FormGroup;
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private userService: UserRestService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private auth: CommonAuthService,
    private clientService: ClientService,
    private subsService: SubscriptionService
  ) { }

  ngOnInit() {
    this.paymentForm = this.fb.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      address1: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      zip: ['', Validators.required],
      amount: [''],
      variant: [''],
      payment_token: [''],
      alias: ['', Validators.required],
    });
  }

  onSubmit() {
    event.preventDefault();
    if (document.getElementById('payment_token_2').getAttribute('value') !== '') {
      this.formControls.payment_token.setValue(document.getElementById('payment_token_2').getAttribute('value'));
      console.log(this.formControls);
      this.subsService.addPaymentMethod(this.paymentForm.value)
        .subscribe(response => {
            console.log('response:', response);
            localStorage.setItem('user', response.user);
            localStorage.setItem('user2', response.user);
            this.paymentForm.reset();
            $('.new-add-balance-modal').modal('hide');
            this.ngOnInit();
            if (response.status === 200) {
              this.alertService.alert('success', 'Payment Method accepted successfully.');
            }
            this.refreshGrid.emit();
            // location.reload();
            this.ngLoading = false;
          },
          error => {
            console.log('error: ', error);
            if (error.status === 200) {
              localStorage.setItem('user', error.user);
              localStorage.setItem('user2', error.user);
              this.paymentForm.reset();
              $('.new-add-balance-modal').modal('hide');
              this.ngOnInit();
              if (error.status === 200) {
                this.alertService.alert('success', 'Payment Method accepted successfully.');
              }
              this.refreshGrid.emit();
              // location.reload();
            } else {
              this.alertService.alert('error', error.error);
            }
            this.ngLoading = false;
          });
    } else {
      this.ngLoading = true;
      CollectJS.startPaymentRequest();
    }
  }

  get formControls() {
    return this.paymentForm.controls;
  }

  closeModal() {
    $('.new-add-balance-modal').modal('hide');
  }

}
