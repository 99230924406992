import { Component, OnInit } from '@angular/core';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import * as moment from 'moment';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-audiences-bulk-export',
  templateUrl: './audiences-bulk-export.component.html',
  styleUrls: ['./audiences-bulk-export.component.scss']
})
export class AudiencesBulkExportComponent implements OnInit {
  tagType = 'script';
  audIds = [];
  audNames = [];
  loading = false;
  mapUrlChecked = false;
  mapUrlRedirectUrl = '';
  linkUrl = '';
  linkText = '';
  exportForm: FormGroup;

  constructor(private audService: AudiencesService, private fb: FormBuilder) { }

  ngOnInit() {
    this.tagType = 'script';
  }

  getTagsCode() {
    // this.loading = true;
    this.audService.getAllTagsCode(this.audIds, this.tagType, this.audNames, this.mapUrlRedirectUrl, this.linkUrl, this.linkText).subscribe(
      (response) => {
        if (response) {
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response], {type: 'text/plain'}));
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = moment().format('YYYY-MM-DD-hh:mm') + '-AudienceTags.txt';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.loading = false;
        }
      },
      (error) => { console.log(error, 'getTagsCode'); this.loading = false; }
    )
  }

}
