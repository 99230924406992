import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    childMenu: Array<object>;
    roles: Array<string>;
}

export const ROUTES: RouteInfo[] = [
  {
    path: '/mybrands', title: 'My Brands', icon:'nc-laptop', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/myagencies', title: 'Clients', icon:'nc-spaceship', class: '',
    childMenu: null,
    roles: ['ADMIN']
  },
  {
    path: '/myusers', title: 'Users', icon:'nc-spaceship', class: '',
    childMenu: null,
    roles: ['MANAGER']
  },
  {
    path: '/performance', title: 'Performance', icon:'nc-chart-bar-32', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/inventory', title: 'Inventory', icon:'nc-box-2', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/marketplace', title: 'Private Marketplace', icon:'nc-globe-2', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/domains', title: 'Domains', icon:'nc-diamond', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/one-time-reports', title: 'One Time Reports', icon:'nc-time-alarm', class: '',
    childMenu: null,
    roles: []
  },
  {
    path: '/support', title: 'Support', icon:'nc-settings', class: '',
    childMenu: null,
    roles: ['ADMIN']
  },
  // {
  //   path: '/scheduled-reports', title: 'Schedule Reports', icon:'nc-time-alarm', class: '',
  //   childMenu: null
  // },
  // {
  //   path: '/one-time-reports', title: 'One Time Reports', icon:'nc-minimal-down', class: '',
  //   childMenu: null
  // },
  // { path: '', title: 'Reports', icon:'nc-cloud-download-93', class: '',
  //   childMenu: [
  //     {path: '/scheduled-reports', title: 'Scheduled', icon: 'nc-time-alarm', class: ''},
  //     {path: '/one-time-reports', title: 'One Time', icon: 'nc-minimal-down', class: ''},
  //   ]
  // }
];

// [
//     { path: '/users',         title: 'User List',         icon:'nc-bank',       class: '' },
//     { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
//     { path: '/icons',         title: 'Icons',             icon:'nc-diamond',    class: '' },
//     { path: '/maps',          title: 'Maps',              icon:'nc-pin-3',      class: '' },
//     { path: '/notifications', title: 'Notifications',     icon:'nc-bell-55',    class: '' },
//     { path: '/user',          title: 'User Profile',      icon:'nc-single-02',  class: '' },
//     { path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
//     { path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' }
// ];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public onlyIcons = true;
    public user = localStorage.user ? JSON.parse(localStorage.user) : null;
    ngOnInit() {
        const currentSize = localStorage.getItem('navSize');
        this.onlyIcons = (currentSize !== null && currentSize === 'large') ? false : true;
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

  changeSideBarSize() {
    this.onlyIcons = !this.onlyIcons;
    localStorage.removeItem('navSize');
    localStorage.setItem('navSize', (this.onlyIcons) ? 'small' : 'large');
  }
}
