import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  breadcrumbs: Array<{label: string, url: string, params: []}> = [];
  constructor() { }
}
