import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-universal-subrule',
  templateUrl: './universal-subrule.component.html',
  styleUrls: ['./universal-subrule.component.scss']
})
export class UniversalSubruleComponent implements OnInit {

  @Input() filter: FormGroup;
  type = '';
  operator = '';
  operatorBlocked = false;
  constructor() { }

  ngOnInit() {
    console.log(this.filter)
    if (typeof this.filter.value.type !== 'undefined' && this.filter.value.type !== null) {
      this.type = this.filter.value.type;
    }
    if (typeof this.filter.value.operator !== 'undefined' && this.filter.value.operator !== null) {
      this.operator = (this.filter.value.blocked) ? 'NOT-' + this.filter.value.operator : this.filter.value.operator;
    }
  }

  operatorChanged(event: string) {
    console.log(event);
    const data = event.split('NOT-');
    this.filter.controls.operator.setValue((data.length > 1) ? data[1] : data[0]);
    this.filter.controls.blocked.setValue((data.length > 1) ? true : false);
    console.log(data, this.filter);
    this.filter.updateValueAndValidity();
  }
}
