import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GridRenderService {

  constructor() { }

  cellRenderValue(cell, type = '') {
    const colDef = cell.column.getColDef();
    const column = cell.column.colId;

    const margin = (typeof cell.node.data.extraData !== 'undefined' && cell.node.data.extraData.margin > 1) ?
      ((100 - cell.node.data.extraData.margin) / 100) : 1;

    if (colDef.cellRenderer === 'numberFormatCellRendererComponent') {
      if (colDef.cellRendererParams.totalType === 'percent') {
        return (((typeof cell.value !== 'undefined' && !isNaN(cell.value)) ? parseFloat(cell.value) : 0) * 100).toFixed(2) + '%';
      } else if (colDef.cellRendererParams.totalType === 'money') {
        if (type === 'campaigns' || type === 'domains') {
          if (column !== 'defaultBid') {
            return '$' + ((typeof cell.value !== 'undefined' && !isNaN(cell.value)) ? parseFloat(cell.value) / 1 : 0).toFixed(2);
          } else {
            return '$' + ((typeof cell.value !== 'undefined' && !isNaN(cell.value)) ? parseFloat(cell.value) : 0).toFixed(2);
          }
        }
        return '$' + ((typeof cell.value !== 'undefined' && !isNaN(cell.value)) ? parseFloat(cell.value) : 0).toFixed(2);
      } else {
        return String((typeof cell.value !== 'undefined' && !isNaN(cell.value)) ? parseFloat(cell.value) : 0);
      }
    } else if (colDef.cellRenderer === 'budgetCellRendererComponent' && typeof cell.node.rowPinned === 'undefined') {
      if (colDef.cellRendererParams.type === 'totalBudget') {
        if (type === 'campaigns') {
          if (cell.value.type === 'all_time') {
           return '$' + (cell.value.amount / 1).toFixed(2);
          } else {
            return '-';
          }
        }
        return '$' + cell.value.amount.toFixed(2);
      } else if (colDef.cellRendererParams.type === 'startDate') {
        return (typeof cell.value.schedule !== 'undefined' && typeof cell.value.schedule.flightDates !== 'undefined')
          ? moment(cell.value.schedule.flightDates.from).format('MM/DD/YYYY')
          : '-';
      } else if (colDef.cellRendererParams.type === 'endDate') {
        return (typeof cell.value.schedule !== 'undefined' && typeof cell.value.schedule.flightDates !== 'undefined')
          ? moment(cell.value.schedule.flightDates.to).format('MM/DD/YYYY') : '-';
      } else if (colDef.cellRendererParams.type === 'dailyBudget') {
        if (type === 'campaigns') {
          if (cell.value.type === 'daily') {
            return '$' + (cell.value.amount / 1).toFixed(2);
          } else {
            return '-';
          }
        }
        return '$' + (cell.value.amount).toFixed(2);
      }
    } else {
      return cell.value;
    }
  }
}
