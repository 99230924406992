import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {Audience} from '../../_models/audience';
import {Conversion} from '../../_models/conversions';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-audience-target-rule',
  templateUrl: './audience-target-rule.component.html',
  styleUrls: ['./audience-target-rule.component.scss']
})
export class AudienceTargetRuleComponent implements OnInit {
  @Input() rule: FormGroup;
  @Input() brandId: number;
  audienceSelect = 'AUDIENCE_ADDITION';
  selectedAudiences: Array<number> = [];
  selectedConversions: Array<number> = [];
  audiences: Observable<Array<Audience>>;
  conversions: Observable<Array<Conversion>>;
  logicalGroupsOperator = 'or';
  constructor(private fb: FormBuilder, private audService: AudiencesService, private convService: ConversionService) { }

  ngOnInit() {
    // console.log(this.rule);
    this.audService.getAudiencesStats(this.brandId, {filter: '', visible: '', dateFrom: '', dateTo: '', userIdTypes: ''}, 100).subscribe(
      (response) => {
        this.audiences = new Observable((observer: Observer<Array<Audience>>) => {
          observer.next(response.response.audiences);
          observer.complete();
        });
      },
      (error) => {
        console.log(error, 'getAudiencesStats');
        this.audiences = new Observable((observer: Observer<Array<Audience>>) => {
          observer.next([]);
          observer.complete();
        });
      }
    );
    this.convService.getConversionsStats(this.brandId, {filter: '', status: '', dateFrom: '', dateTo: ''}, 100).subscribe(
      (response) => {
        this.conversions = new Observable((observer: Observer<Array<Conversion>>) => {
          observer.next(response.response.conversions);
          observer.complete();
        });
      },
      (error) => {
        console.log(error, 'getConversionsStats');
        this.conversions = new Observable((observer: Observer<Array<Conversion>>) => {
          observer.next([]);
          observer.complete();
        });
      }
    );
    if (typeof this.rule.value.actions !== 'undefined' && this.rule.value.actions !== null) {
      for (const action of this.rule.value.actions) {
        console.log('ENTERING TO ACTIONS: ', action);
        if (action.type === 'CONVERSION') {
          this.selectedConversions.push(action.id);
        } else if (action.id !== '') {
          this.selectedAudiences.push(action.id);
          this.audienceSelect = action.type;
        }
      }
    }
    if ((typeof this.rule.value.target !== 'undefined' && this.rule.value.target !== null) &&
      (typeof this.rule.value.target.logicalOperator !== 'undefined' && this.rule.value.target.logicalOperator !== null)) {
      this.logicalGroupsOperator = this.rule.value.target.logicalOperator;
    }
  }

  addRuleGroup() {
    const target = this.fb.group({
      type: ['COMPOSITE'],
      blocked: [false],
      logicalOperator: ['or'],
      targets: this.fb.array([
        this.fb.group({
          type: ['', Validators.required],
          blocked: [false],
          operator: ['', Validators.required],
          value: ['', Validators.required],
          parameterName: ['']
        })
      ])
    });
    // @ts-ignore
    (this.rule.controls.target.controls.targets as FormArray).push(target);
    this.rule.updateValueAndValidity();
  }

  actionSelected(event, actType = 'conversion') {
    const old = this.rule.controls.actions['controls'].filter(action => {
      return ((actType === 'conversion') ?
        (action.value.type !== 'CONVERSION') :
        (action.value.type !== 'AUDIENCE_ADDITION' && action.value.type !== 'AUDIENCE_REMOVAL'));
    });
    (this.rule.controls.actions as FormArray).clear();
    for (const action of event) {
      if (typeof action['offerId'] !== 'undefined') {
        // @ts-ignore
        (this.rule.controls.actions as FormArray).push(this.fb.group({ id: action['offerId'], type: 'CONVERSION' }));
      } else {
        // @ts-ignore
        (this.rule.controls.actions as FormArray).controls.push(this.fb.group({ id: action['audienceId'], type: this.audienceSelect }));
      }
    }
    if (old.length > 0) {
      // @ts-ignore
      (this.rule.controls.actions as FormArray).push(...old);
    }
    this.rule.updateValueAndValidity();
  }

  deleteGroup(index: number) {
    // @ts-ignore
    this.rule.controls.target.controls.targets.controls.splice(index, 1);
    this.rule.updateValueAndValidity();
  }
}
