import { Component, OnInit } from '@angular/core';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {FormBuilder} from '@angular/forms';
import {UniversalPixelsService} from '../../_services/universal-pixels/universal-pixels.service';

@Component({
  selector: 'app-universal-pixel-tag',
  templateUrl: './universal-pixel-tag.component.html',
  styleUrls: ['./universal-pixel-tag.component.scss']
})
export class UniversalPixelTagComponent implements OnInit {
  loading = false;
  tagType = 'script';
  universalPixelId: number;
  textCode: string;
  userMatching = true;
  deploy = false;
  brandId: number;
  constructor(private univService: UniversalPixelsService) { }

  ngOnInit() {
    this.tagType = 'script';
  }

  changeTagType(event) {
    this.tagType = event;
    this.getTagCode();
  }

  getTagCode(event = null) {
    this.loading = true;
    console.log(event);
    if (event !== null) {
      if (event.source.id === 'userMatching') {
        this.userMatching = event.checked;
      } else {
        this.deploy = event.checked;
      }
    }
    this.univService.getTagCode(this.tagType, this.universalPixelId, this.brandId, this.userMatching, this.deploy).subscribe(
      (response) => {
        this.loading = false;
        this.textCode = response.response;
      },
      (error) => { console.log(error, 'getTagCode'); this.loading = false; }
    )
  }
}
