import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';

@Component({
  selector: 'app-campaign-conversions-modal',
  templateUrl: './campaign-conversions-modal.component.html',
  styleUrls: ['./campaign-conversions-modal.component.scss']
})
export class CampaignConversionsModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }
  selectOptions = '1';
  selectOptions2 = '1';
  selectOptions3: string;
  selectOptions4: string;

  columnDefsConv = [
    {headerName: 'Name', field: 'name', width: 300 },
    {headerName: 'ID', field: 'id', width: 80 },
    {headerName: 'In Campaigns', field: 'campaigns', width: 120 },
    {headerName: '', field: 'action', width: 80 },
  ];
  rowDataConv = [];

  constructor() { }

  ngOnInit() {
    this.selectOptions = '1';
    this.selectOptions2 = '1';
  }

}
