import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {ReportsService} from '../../_services/reports/reports.service';
import {ScheduleReportsComponent} from '../../pages/schedule-reports/schedule-reports.component';
import {BrandService} from '../../_services/brand/brand.service';
import {GroupService} from '../../_services/group/group.service';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {Observable} from 'rxjs';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {report_types, report_type_dimension, report_type_dimension_codes, report_types_by_entity} from '../../_models/aux-data';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-new-schedule-report-modal',
  templateUrl: './new-schedule-report-modal.component.html',
  styleUrls: ['./new-schedule-report-modal.component.scss']
})
export class NewScheduleReportModalComponent implements OnInit {
  ngLoading = false;
  gridApi;
  gridApiConv;
  gridColumnApi;
  _parent: ScheduleReportsComponent;
  @Input() parent;
  @Output() refreshGrid = new EventEmitter<string>();
  editOrUpdate = 'New';
  newScheduleRptForm: FormGroup;
  selectedOptions = [];
  searchGroup = '';
  previousSelected: any;
  useConvPixels = false;
  archived = false;
  entityGroup = '';
  reportTypes = report_types;
  reportTypeDimension = report_type_dimension;
  reportTypeDimensionCodes = report_type_dimension_codes;
  reportTypesByEntity = report_types_by_entity;
  firstTabActive = true;
  archivedConv = false;
  showConvPixels = false;

  rowSelection;
  public modules: Module[] = AllModules;
  groupSelectedRows = [];
  defaultColDef;
  autoGroupColumnDef;

  columnDefs = [
    {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
    {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
    // {headerName: 'Campaign', field: 'campaign', width: 400 },
    {headerName: 'ID', field: 'campaignId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
  ];
  pixelSelected = [];

  columnDefsPixels = [
    {
      headerName: '',
      field: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      sortable: false,
      resizable: false,
    },
    {headerName: 'Name', field: 'name', width: 400 },
    {headerName: 'ID', field: 'id', width: 120},
  ];
  rowData: any;
  rowDataPixels = [];
  auxRowData = [];

  constructor(private formB: FormBuilder,
              private rptService: ReportsService,
              private alertService: AlertService,
              private auth: CommonAuthService,
              private brandService: BrandService,
              private groupService: GroupService,
              private campaignService: CampaignService,
              private reportsService: ReportsService,
              private conversionService: ConversionService
  ) {
    this.rowSelection = 'multiple';
    this.defaultColDef = {
      filter: true,
      sortable: true,
      resizable: true,
    };
    this.autoGroupColumnDef = {
      headerName: 'Campaign',
      field: 'campaign',
      minWidth: 400,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      }
    };
    this.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
  }

  ngOnInit() {
    this._parent = this.parent;
    this.newScheduleRptForm = this.formB.group({
      name: ['', Validators.required],
      report_type: ['', Validators.required],
      dimensions: ['', Validators.required],
      group_by: ['', Validators.required],
      interval: ['', Validators.required],
      duration: ['', Validators.required],
      repeats: ['', Validators.required],
      start_date: ['', Validators.required],
      start_time: ['', Validators.required],
      end_date: ['', Validators.required],
      entities: ['', []],
      pixels: ['', []],
      conversion_pixels: [''],
      emails: ['']
    });
    this.newScheduleRptForm.controls.conversion_pixels.setValue(false);
    this.loadBrands();
  }

  loadConversions() {
    this.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    const allBrandIds = this.gridApi.getSelectedNodes().map(node => node.data.brandId);
    let brandIds = [];
    for (let b of allBrandIds) {
      if (brandIds.indexOf(b) === -1)
        brandIds.push(b);
    }
    if (brandIds.length > 0) {
      this.ngLoading  = true;
      const search_grid_pixels = $('#search_grid_pixels').val();
      this.pixelSelected = [];
      this.conversionService.getConversionsFromMultpBrands({
        brandIds: brandIds,
        filter: (typeof search_grid_pixels !== 'undefined') ? search_grid_pixels.toString() : '',
        dateTo: '',
        dateFrom: '',
        status: ''
      }).subscribe(
        (response) => {
          console.log(response);
          this.rowDataPixels = Observable.create((obs) => {
            let result = [];
            if (!this.archivedConv) {
              for (let conv of response.response.conversions) {
                if (!conv.deleted) {
                  result.push(conv);
                }
              }
              obs.next(result);
            } else {
              obs.next(response.response.conversions);
            }
            obs.complete();
          });
          this.ngLoading  = false;
        },
        (error) => {
          console.log(error);
          this.ngLoading  = false;
        }
      );
    }
  }

  selectAllConversions() {
    if ($('#sel_conv_all').html() === 'Select All'){
      this.gridApiConv.selectAll();
      $('#sel_conv_all').html('Unselect All');
    } else {
      this.gridApiConv.deselectAll();
      $('#sel_conv_all').html('Select All');
    }
  }

  onGridReadyConv(event: any) {
    this.gridApiConv = event.api;
  }

  convRowSelected(event: any) {
    console.log('event:', event);
    this.pixelSelected =  this.gridApiConv.getSelectedNodes().map(node => node.data.offerId);
  }

  changeArchivedPixels(event: any) {
    this.archivedConv = !this.archivedConv;
    if (this.archivedConv) {
      this.loadConversions();
    }
  }

  changeReportType() {

  }

  onRowDataUpdated(event: any) {
    if (typeof this.previousSelected !== 'undefined' && this.previousSelected.length > 0) {
      let allNodes = [];
      this.gridApi.forEachNode(function(node) {
        if (typeof node.data !== 'undefined') {
          allNodes.push(node);
        }
      });
      let groupIds = []; let brandIds = [];
      for (let row of this.auxRowData) {
        if (this.previousSelected.indexOf(row.campaignId) !== -1) {
          if (groupIds.indexOf(row.gorupId) !== -1) {
            groupIds.push(row.groupId);
          }
          if (brandIds.indexOf(row.brandId) !== -1) {
            brandIds.push(row.brandId);
          }
        }
      }
      for (let node of allNodes) {
        if (this.searchGroup === 'campaign' && this.previousSelected.indexOf(node.data.campaignId) !== -1) {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        } else if (this.searchGroup === 'group' && groupIds.indexOf(node.data.groupId) !== -1) {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        } else if (this.searchGroup === 'brand' && brandIds.indexOf(node.data.brandId) !== -1)  {
          node.selected = true;
          this.groupSelectedRows.push(node.data);
          while (typeof node.parent !== 'undefined' && node.level > -1) {
            node.parent.expanded = true;
            node = node.parent;
          }
        }
      }
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  groupRowSelected(event: any) {
    console.log(event);
    const group = $('#entityGroup').val();
    if (group === 'brand') {
      this.groupSelectedRows = [];
      const selNodes = event.api.getSelectedNodes();
      for (let node of selNodes) {
        for (let obj of this.auxRowData) {
          if (node.data.brandId === obj.brandId)
            this.groupSelectedRows.push(obj);
        }
      }
    } else if (group === 'group') {
      this.groupSelectedRows = [];
      const selNodes = event.api.getSelectedNodes();
      for (let node of selNodes) {
        for (let obj of this.auxRowData) {
          if (node.data.groupId === obj.groupId)
            this.groupSelectedRows.push(obj);
        }
      }
    } else {
      this.groupSelectedRows = event.api.getSelectedNodes().map((value, index, array) => value.data);
    }
    console.log(this.groupSelectedRows);
    this.loadConversions();
  }

  onRowClicked(event: any) { console.log('row', event); }
  onCellClicked(event: any) { console.log('cell', event); }

  loadBrands() {
    this.ngLoading  = true;
    const search = $('#search_grid').val();
    const params = {
      archived: this.archived,
      search: typeof search !== 'undefined' ? search.toString() : '',
    };
    let group = $('#entityGroup').val();
    if ((typeof group === 'undefined' || group === '') && this.searchGroup !== null && this.searchGroup !== '') {
      group = this.searchGroup;
    }
    if (typeof group !== 'undefined' && group !== '' && group !== 'campaign') {
      if (group === 'brand') {
        console.log('BRAND');
        this.columnDefs = [
          {
            headerName: '',
            field: 'checkboxSelect',
            width: 40,
            checkboxSelection: true,
            sortable: false,
            resizable: false,
          },
          {headerName: 'Brand', field: 'brand', width: 400, checkboxSelection: false, sortable: true, resizable: true },
          {headerName: 'ID', field: 'brandId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
        ];
        this.autoGroupColumnDef = {
          headerName: 'Brand',
          field: 'brand',
          minWidth: 400,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true,
          }
        };
      } else {
        this.columnDefs = [
          {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
          // {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true },
          {headerName: 'ID', field: 'groupId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
        ];
        this.autoGroupColumnDef = {
          headerName: 'Group',
          field: 'group',
          minWidth: 400,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true,
          }
        };
      }
    } else {
      console.log('Campaign');
      this.columnDefs = [
        {headerName: 'Brand', field: 'brand', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
        {headerName: 'Group', field: 'group', width: 400, rowGroup: true, hide: true, checkboxSelection: false, sortable: true, resizable: true },
        // {headerName: 'Campaign', field: 'campaign', width: 400 },
        {headerName: 'ID', field: 'campaignId', width: 120, checkboxSelection: false, sortable: true, resizable: true },
      ];
    }
    this.reportsService.getBrandsGroupsCampaigns(params).subscribe(
      (response) => {
        console.log(response);
        this.rowData =  Observable.create((obs) => {
          if (group === 'brand') {
            let aux = []; let aux2 = [];
            for (let obj of response.response.brands) {
              if (aux2.indexOf(obj.brandId) === -1) {
                aux.push(obj);
                aux2.push(obj.brandId);
              }
            }
            obs.next(aux);
          } else if (group === 'group') {
            let aux = []; let aux2 = [];
            for (let obj of response.response.brands) {
              if (aux2.indexOf(obj.groupId) === -1) {
                aux.push(obj);
                aux2.push(obj.groupId);
              }
            }
            obs.next(aux);
          } else {
            console.log('Campaign2');
            obs.next(response.response.brands);
          }
          this.auxRowData = response.response.brands;
          obs.complete();
        });
        this.ngLoading  = false;
      });
  }

  isRowSelectable(params: any) {
    let group = $('#entityGroup').val();
    console.log('group:', group);
    return typeof group !== 'undefined' && group !== '';
  }

  changePixels(event: any) {
    this.showConvPixels = !this.showConvPixels;
    this.loadConversions();
  }

  changeTabEvent(event: any) {
    if (event.index === 1 && this.groupSelectedRows.length === 0) {
      this.loadBrands();
    }
  }

  changeGroupBy() {
    if (this.newScheduleRptForm.controls.group_by.value !== '') {
      if ((this.newScheduleRptForm.controls.group_by.value === 'brand' && (this.searchGroup === 'group' || this.searchGroup === 'campaign')) || (this.newScheduleRptForm.controls.group_by.value === 'group' && this.searchGroup === 'campaign')) {
        this.rowData = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.groupSelectedRows = [];
      }
    }
    this.searchGroup = this.newScheduleRptForm.controls.group_by.value;
    this.loadBrands();
  }

  selectAll() {
    if ($('#sel_grid_all').html() === 'Select All') {
      this.gridApi.selectAll();
      $('#sel_grid_all').html('Unselect All');
    } else {
      this.gridApi.deselectAll();
      $('#sel_grid_all').html('Select All');
    }
    if (this.archivedConv) {
      this.loadConversions();
    }
  }

  changeGroup(event: any) {
    console.log('event:', event);
    $('#entityGroup').val(event.value);
    this.loadBrands();
  }

  changeArchived(event: any) {
    this.archived = !this.archived;
    this.loadBrands();
  }

  onSubmit() {
    if (!this.newScheduleRptForm.valid) {
      console.log(this.newScheduleRptForm.controls);
      this.alertService.alert('error', 'Please complete all required inputs.');
    } else {
      this.ngLoading  = true;
      this.newScheduleRptForm.controls.entities.setValue(JSON.stringify(this.groupSelectedRows));
      this.newScheduleRptForm.controls.pixels.setValue(JSON.stringify(this.pixelSelected));
      console.log(this.newScheduleRptForm.controls);

      const report = {
        advertiserId: '',
        name: this.formControls.name.value,
        conversionPixelIds: JSON.parse(this.newScheduleRptForm.controls.pixels.value),
        reportType: this.formControls.dimensions.value,
        aggregation: this.formControls.interval.value,
        entityType: this.searchGroup.toString().toUpperCase(),
        entityIds: JSON.parse(this.newScheduleRptForm.controls.entities.value),
        scheduleStartDate: this.formControls.start_date.value,
        timeToRunReport: this.formControls.start_time.value,
        scheduleEndDate: this.formControls.end_date.value,
        frequency: this.formControls.repeats.value,
        emails: this.formControls.emails.value.split(','),
        lookbackWindow: '',
        lookbackType: '',
        status: 'ACTIVE'
      };

      if (this.formControls.duration.value.indexOf('PREVIOUS') !== -1) {
        const lookbackType = this.formControls.duration.value.split('_')[1];
        report.lookbackWindow = '1';
        report.lookbackType = (typeof lookbackType !== 'undefined') ? lookbackType.toUpperCase() : '';
        // report.lookbackType = report.lookbackType.substr(0, report.lookbackType.length - 1);
      } else {
        report.lookbackWindow = this.formControls.duration.value.split('_')[1];
        report.lookbackType = this.formControls.duration.value.split('_')[2].toUpperCase();
        // report.lookbackType = report.lookbackType.substr(0, report.lookbackType.length - 1);
      }

      console.log(report);
      this.reportsService.saveSheduleReport(report)
        .subscribe(response => {
          console.log(response);
          $('.new-schedule-report-modal').modal('hide');
          this._parent.loadReports();
          this.alertService.alert('success', 'Schedule Report created successfully.');
          this.ngLoading  = false;
        });
    }
  }

  get formControls() {
    return this.newScheduleRptForm.controls;
  }

}
