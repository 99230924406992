import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';
import {Ad} from '../../../_models/ad';
import {environment} from '../../../../environments/environment';
import {BrandsComponent} from '../../../pages/brands/brands.component';

@Component({
  selector: 'app-new-audio-modal',
  templateUrl: './new-audio-modal.component.html',
  styleUrls: ['./new-audio-modal.component.scss']
})
export class NewAudioModalComponent extends NewAdComponent implements OnInit {

  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }
  pondOptionsAudio: any;

  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService)
  }

  ngOnInit() {
    super.ngOnInit();
    this.pondOptionsAudio = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'audio/*',
      imagePreviewMarkupShow: false,
      maxFileSize: '100MB',
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadAudio(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              load(`${environment.publicUrl}` + response);
              this.newForm.controls.assetUrl.setValue(response);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getFile(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.newForm = this.fb.group({
      adType: ['audio'],
      label: ['', Validators.required],
      status: ['online'],
      width: [''],
      height: [''],
      type: ['audio'],
      assetUrl: ['', [Validators.required]],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      // tslint:disable-next-line:max-line-length
      landingPageDomain: ['', [Validators.pattern(this.domainRegex), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')]),
      companionCreativeId: [''],
      assetUrlCompanion: ['', [Validators.required]],
      clickUrlCompanion: ['', [Validators.required]],
      winNotificationUrlCompanion: this.fb.array([this.fb.control('')]),
      scriptTrackingUrlCompanion: this.fb.array([this.fb.control('')]),
      impressionTrackingCompanion: [''],
      customerVastEvents: ['']
    });
    // this._parent.newForm = this.newForm;
  }

  // onSubmit() {
  //   if (this.brandId === null) {
  //     this.alertService.alert('error', 'You need to select a Brand.');
  //     return;
  //   }
  //   this.newForm.controls.reviewAttributes.setValue(this.selectedOptions);
  //   this.newForm.controls.brandId.setValue(this.brandId);
  //   if (typeof this.newForm.controls.impressionTracking !== 'undefined' && this.newForm.controls.impressionTracking !== null) {
  //     this.newForm.controls.impressionTracking.setValue(this.impressionTrackingUrls());
  //   }
  // tslint:disable-next-line:max-line-length
  //   if (typeof this.newForm.controls.impressionTrackingCompanion !== 'undefined' && this.newForm.controls.impressionTrackingCompanion !== null) {
  //     this.newForm.controls.impressionTrackingCompanion.setValue(this.impressionTrackingUrlsCompanion());
  //   }
  //   if (typeof this.newForm.controls.customerVastEvents !== 'undefined' && this.newForm.controls.customerVastEvents !== null) {
  //     this.newForm.controls.customerVastEvents.setValue(this.getGridTrackingUrls());
  //   }
  //   if (this.createOrEdit === 'Create') {
  //     this.adService.createAd(this.newForm.value as Ad)
  //       .subscribe(response => {
  //         this.ngLoading = false;
  //         this.newForm.reset();
  //         this.ngOnInit();
  //         this.myPondImage.removeFiles();
  //         this.myPondAudio.removeFiles();
  //         if ( String(response.status) === 'SUCCESS' ) {
  //           this.alertService.alert('success', 'Image Ad created successfully.');
  //         }
  //       });
  //   } else {
  //     this.adService.updateAd(this.newForm.value as Ad, this.newForm.get('creativeId').value)
  //       .subscribe(response => {
  //         this.ngLoading = false;
  //         this.myPondImage.removeFiles();
  //         this.myPondAudio.removeFiles();
  //         if ( String(response.status) === 'SUCCESS' ) {
  //           this.alertService.alert('success', 'Image Ad updated successfully.');
  //         }
  //       });
  //   }
  // }
}
