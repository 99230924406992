import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lookup-dma-codes-modal',
  templateUrl: './lookup-dma-codes-modal.component.html',
  styleUrls: ['./lookup-dma-codes-modal.component.scss']
})
export class LookupDmaCodesModalComponent implements OnInit {
  columnDefsCodes = [
    {headerName: 'Code', field: 'code', width: 120 },
    {headerName: 'Location', field: 'location', width: 250 },
  ];
  rowDataCodes = [];

  constructor() { }

  ngOnInit() {
  }

}
