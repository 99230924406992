import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {CampaignsResponse} from '../../_models/responses';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeographicService {

  private geoUrl = 'geo';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getGeographicLocationsByFilter(filter: string) {
    return this.http.get(
      `${environment.apiUrl}` + this.geoUrl + '/filter?filter=' + filter
    ).pipe(
      tap(_ => console.log('fetched geo by filter')),
      catchError(Errors.handleError<any>('getGeographicLocationsByFilter'))
    );
  }

  getGeographicLocations() {
    return this.http.get(
      `${environment.apiUrl}` + this.geoUrl + '/countries'
    ).pipe(
      tap(_ => console.log('fetched countries')),
      catchError(Errors.handleError<any>('getGeographicLocations'))
    );
  }

  getDmaCodes() {
    return this.http.get(
      `${environment.apiUrl}` + this.geoUrl + '/dmacodes'
    ).pipe(
      tap(_ => console.log('fetched countries')),
      catchError(Errors.handleError<any>('getGeographicLocations'))
    );
  }

  getCountryRegions(countryCode) {
    return this.http.get(
      `${environment.apiUrl}` + this.geoUrl + '/regions/' + countryCode
    ).pipe(
      tap(_ => console.log('fetched regions')),
      catchError(Errors.handleError<any>('getCountryRegions'))
    );
  }

  getRegionCities(countryCode, regionCode) {
    return this.http.get(
      `${environment.apiUrl}` + this.geoUrl + '/regions/' + countryCode + '/' + regionCode
    ).pipe(
      tap(_ => console.log('fetched cities')),
      catchError(Errors.handleError<any>('getRegionCities'))
    );
  }
}
