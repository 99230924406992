import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
import {InventoryComponent} from '../../pages/inventory/inventory.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {Domains} from '../../_models/domains';
import {DomainListType} from '../../_models/enums';
import {CampaignGroup} from '../../_models/campaignGroup';
import {DomainListService} from '../../_services/domain-list/domain-list.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-new-domain-list-modal',
  templateUrl: './new-domain-list-modal.component.html',
  styleUrls: ['./new-domain-list-modal.component.scss']
})
export class NewDomainListModalComponent implements OnInit {
  _parent: InventoryComponent;
  @Input() parent;
  @Output() refreshGrid = new EventEmitter<string>();
  inputmethod: boolean;
  newDomainForm: FormGroup;
  disabledFile = false;
  domappCount = 2000;
  editOrUpdate = 'New';
  selInpMeth = true;
  ngLoading = false;

  public pondOptionsImage: any;
  public pondFilesImage = [];

  constructor(
    private formB: FormBuilder,
    private inventoryService: InventoryService,
    private alertService: AlertService,
    private auth: CommonAuthService,
    private domainListService: DomainListService
  ) {
  }

  ngOnInit() {
    this.selInpMeth = true;
    this._parent = this.parent;
    this.inputmethod = true;
    this.newDomainForm = this.formB.group({
      name: ['', Validators.required],
      listType: ['', Validators.required],
      inputMethod: ['', Validators.required],
      domains: [''],
      file: [''],
      id: [''],
    });
    this.newDomainForm.controls.listType.setValue('whitelist');
    this.newDomainForm.controls.inputMethod.setValue('input');
    this.pondFilesImage = [];

    this.pondOptionsImage = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'text/plain, application/octet-stream',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.ngLoading = true;
          this.domainListService.uploadFile(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              // this.img.src = `${environment.publicUrl}` + response;
              if (typeof this.newDomainForm.controls.file !== 'undefined' && this.newDomainForm.controls.file !== null) {
                this.newDomainForm.controls.file.setValue(response);
              }
              console.log('IMG ASSET: ', this.newDomainForm.controls.file.value, this.newDomainForm.value);
              // document.getElementById('adPanel').appendChild(this.img);
              this.ngLoading = false;
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.ngLoading = true;
          this.domainListService.getFile(source)
            .subscribe(response => {
              load(response);
              this.ngLoading = false;
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('file upload error');
          this.ngLoading = false;
          load();
        }
      }
    };
  }

  public pondHandleInit() {
    console.log('FilePond has initialised');
  }

  public pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  public pondRemovedFile(event) {
    const element = document.getElementById('adPanel');
    try {
      //element.removeChild(element.firstChild);
    } catch (error) {
      console.error(error, 'pondRemovedFile');
    }
    //this.adService.deleteFile(event.file).subscribe(response => {}, error => {console.log(error, 'pondRemovedFile')});
  }

  changeInputMethod(input: string) {
    this.inputmethod = !this.inputmethod;
    this.selInpMeth = !this.selInpMeth;
    this.newDomainForm.controls.inputMethod.setValue(input);
  }

  loadDomainList(domain: Domains) {
    console.log(domain);
    this.editOrUpdate = 'Edit';
    this.newDomainForm.controls.name.setValue(domain.name);
    this.newDomainForm.controls.listType.setValue(domain.type);
    this.newDomainForm.controls.domains.setValue(domain.domains.join('\n'));
    this.domappCount = 2000 - domain.domains.length;
    this.disabledFile = true;
    this.inputmethod = true;
    this.pondFilesImage = [];
    this.newDomainForm.controls.id.setValue(domain.domainListId);
    this.selInpMeth = true;
  }

  changeListType(type) {
    this.newDomainForm.controls.listType.setValue(type);
  }

  get formControls() {
    return this.newDomainForm.controls;
  }

  updateCount(event: any) {
    console.log(this.newDomainForm.controls.domains.value.split('\n'));
    this.domappCount = (2001 - parseInt(this.newDomainForm.controls.domains.value.split('\n').length));
  }

  onSubmit() {
    if (!this.newDomainForm.valid) {
      console.log(this.newDomainForm.controls);
      this.alertService.alert('error', 'Please complete all required inputs.');
    } else {
      this.ngLoading = true;
      const list = {
        id: null,
        name: this.newDomainForm.controls.name.value,
        domainListId: 0,
        client_id: 0,
        type: this.newDomainForm.controls.listType.value,
        domains: this.newDomainForm.controls.inputMethod.value === 'input' ? this.newDomainForm.controls.domains.value.split('\n') : [],
        links: [],
        file: this.newDomainForm.controls.inputMethod.value === 'input' ?  null : this.newDomainForm.controls.file.value,
      };
      console.log(list);
      if (this.editOrUpdate === 'New') {
        this.domainListService.createDomainList(list as unknown as Domains)
          .subscribe(response => {
            console.log(response);
            this.newDomainForm.reset();
            this.ngOnInit();
            this.refreshGrid.emit('domain-list');
            $('.new-domain-list-modal').modal('hide');
            if (String(response.status) === 'SUCCESS') {
              this.alertService.alert('success', 'Domain List created successfully.');
            }
            this.ngLoading = false;
          });
      } else {
        this.domainListService.updateDomainList({domainList: list as unknown as Domains, domainId: this.newDomainForm.controls.id.value})
          .subscribe(response => {
            console.log(response);
            this.newDomainForm.reset();
            this.ngOnInit();
            this.refreshGrid.emit('domain-update');
            $('.new-domain-list-modal').modal('hide');
            if (String(response.status) === 'SUCCESS') {
              this.alertService.alert('success', 'Domain List updated successfully.');
            }
            this.ngLoading = false;
          });
      }
    }
  }

}
