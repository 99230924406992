import {KpiType, PacingSetting, ReviewStatus, Status} from './enums';

export class Campaign {
  id: bigint;
  campaignId: bigint;
  campaignGroupId: bigint;
  campaignGroupName: string;
  name: string;
  status: Status;
  defaultBid: number;
  reviewStatus: ReviewStatus;
  portalApprovalStatus: ReviewStatus;
  clickUrl: string;
  budget: any;
  frequencyCapping: any;
  flightDates: any;
  audienceCapture: any;
  viewthruConfig: any;
  videoConfig: any;
  winNotificationUrl: string;
  enabledROP: boolean;
  enabledRON: boolean;
  enableCrossDevice: boolean;
  isRelativeDayParting: boolean;
  excludeAnonymousDomains: boolean;
  enablePixalateViewability: boolean;
  notes: string;
  campaignType: string;
  accessLevel: number;
  kpiValue: string;
  kpiType: KpiType;
  pacingSetting: PacingSetting;
  links: any;
  conversionConfig: any;
  created: string;
  bidShading: string;

  totalSpend: number;
  advertiserSpend: number;
  impressionsWon: number;
  totalEffectiveCPM: number;
  videoCompletionRate: number;
  winRate: number;
  clicks: number;
  costPerClick: number;
  clickThroughRate: number;
  totalConversions: number;
  conversions: number;
  eligibleImpressions: number;
  measuredImpressions: number;
  viewableImpressions: number;
  marginOnAdvertiserSpend: number;
  dataSpend: number;
  effectiveCPMOnAdvertiserSpend: number;
  effectiveCPCOnAdvertiserSpend: number;
  mediaEffectiveCPM: number;
  dataEffectiveCPM: number;
  clickThroughConversions: number;
  clickRPM: number;
  viewthruConversions: number;
  viewCVR: number;
  revenue: number;
  ctcRevenue: number;
  vtcRevenue: number;
  effectiveCPAOnAdvertiserSpend: number;
  totalCVRM: number;
  profitPerClick: number;
  revenuePerMille: number;
  returnOnAdSpend: number;
  videoStarted: number;
  videoFirstQuartileReached: number;
  videoMidpointReached: number;
  videoThirdQuartileReached: number;
  videoCompleted: number;
  effectiveCPCVOnAdvertiserSpend: number;
  totalEffectiveCPCV: number;
  companionImpressions: number;
  companionClicks: number;
  companionConversions: number;
  companionOfferRevenue: number;
  advertiserSpendRate: number;
  maxBid: number;

  constructor(
    id: bigint,
    campaignId: bigint,
    campaignGroupId: bigint,
    name: string,
    status: Status,
    defaultBid: number,
    reviewStatus: ReviewStatus,
    enabledROP: boolean,
    enabledRON: boolean,
    enableCrossDevice: boolean,
    isRelativeDayParting: boolean,
    excludeAnonymousDomains: boolean,
    enablePixalateViewability: boolean,
    portalApprovalStatus: ReviewStatus = null,
    campaignGroupName: string = '',
    clickUrl: string = '',
    budget: any = '',
    frequencyCapping: any = '',
    flightDates: any = '',
    audienceCapture: any = '',
    viewthruConfig: any = '',
    videoConfig: any = '',
    winNotificationUrl: string = '',
    notes: string = '',
    campaignType: string = '',
    accessLevel: number = null,
    kpiValue: string = '',
    kpiType: KpiType = null,
    pacingSetting: PacingSetting = null,
    links: any = '',
    conversionConfig: any = '',
    created: string = '',
    bidShading: string = '',
  ) {
    this.id = id;
    this.campaignId = campaignId;
    this.campaignGroupId = campaignGroupId;
    this.campaignGroupName = campaignGroupName;
    this.name = name;
    this.status = status;
    this.defaultBid = defaultBid;
    this.reviewStatus = reviewStatus;
    this.portalApprovalStatus = portalApprovalStatus;
    this.clickUrl = clickUrl;
    this.budget = budget;
    this.frequencyCapping = frequencyCapping;
    this.flightDates = flightDates;
    this.audienceCapture = audienceCapture;
    this.viewthruConfig = viewthruConfig;
    this.videoConfig = videoConfig;
    this.winNotificationUrl = winNotificationUrl;
    this.enabledROP = enabledROP;
    this.enabledRON = enabledRON;
    this.enableCrossDevice = enableCrossDevice;
    this.isRelativeDayParting = isRelativeDayParting;
    this.excludeAnonymousDomains = excludeAnonymousDomains;
    this.enablePixalateViewability = enablePixalateViewability;
    this.notes = notes;
    this.campaignType = campaignType;
    this.accessLevel = accessLevel;
    this.kpiValue = kpiValue;
    this.kpiType = kpiType;
    this.pacingSetting = pacingSetting;
    this.links = links;
    this.conversionConfig = conversionConfig;
    this.created = created;
    this.bidShading = bidShading;
  }
}
