import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ReportResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subsUrl = 'subscriptions';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
  }

  startSubscription(params = {
    fname: '',
    lname: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    payment_token: '',
    amount: '',
    variant: '',
  }): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}` + this.subsUrl + '/start', params
    ).pipe(
      tap(_ => console.log('start subscription')),
      catchError(Errors.handleError<any>('startSubscription'))
    );
  }

  makePayment(params = {
    fname: '',
    lname: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    payment_token: '',
    amount: '',
    variant: '',
  }): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}` + this.subsUrl + '/payment', params
    ).pipe(
      tap(_ => console.log('make payment')),
      catchError(Errors.handleError<any>('makePayment'))
    );
  }

  addPaymentMethod(params = {
    alias: '',
    fname: '',
    lname: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    payment_token: '',
    amount: '',
    variant: '',
  }): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}` + this.subsUrl + '/addmethod', params
    ).pipe(
      tap(_ => console.log('add payment method')),
      catchError(Errors.handleError<any>('addPaymentMethod'))
    );
  }

  cancelSubscription() {
    return this.http.get<any>(
      `${environment.apiUrl}` + this.subsUrl + '/cancel'
    ).pipe(
      tap(_ => console.log('cancel subscription')),
      catchError(Errors.handleError<any>('cancelSubscription'))
    );
  }

  removeMethod(id) {
    return this.http.get<any>(
      `${environment.apiUrl}` + this.subsUrl + '/removemethod/' + id
    ).pipe(
      tap(_ => console.log('remove method')),
      catchError(Errors.handleError<any>('removeMethod'))
    );
  }

  makeDefaultMethod(id) {
    return this.http.get<any>(
      `${environment.apiUrl}` + this.subsUrl + '/defaultmethod/' + id
    ).pipe(
      tap(_ => console.log('default method')),
      catchError(Errors.handleError<any>('makeDefaultMethod'))
    );
  }

  getPaymentMethods() {
    return this.http.get<any>(
      `${environment.apiUrl}` + this.subsUrl + '/methods'
    ).pipe(
      tap(_ => console.log('get methods')),
      catchError(Errors.handleError<any>('getPaymentMethods'))
    );
  }
}
