import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UsersResponse} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {User} from '../../_models/user';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private supportUrl = 'support';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  // getUsers(max: number = 20, offset: number = 0, searchVal = ''): Observable<UsersResponse> {
  //   return this.http.get<UsersResponse>(
  //     `${environment.apiUrl}` + this.usersUrl + '?max=' + max + '&offset=' + offset + '&query=' + searchVal
  //   ).pipe(
  //     tap(_ => console.log('fetched users')),
  //     catchError(Errors.handleError<UsersResponse>('getUsers'))
  //   );
  // }

  sendTicket(ticket: any) {
    return this.http.post(`${environment.apiUrl}` + this.supportUrl, ticket, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addUser', ticket))
      );
  }
}
