// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  s3FolderName: 'sweetiq_dev',

  // apiUrl: 'http://adforce.test/api/',
  // backendUrl: 'http://adforce.test',
  // publicUrl: 'http://localhost:4200/',
  // appUrl: 'http://localhost:4200/'

  apiUrl: 'https://devapi.adforce.galeforcedigital.com/api/',
  backendUrl: 'https://devapi.adforce.galeforcedigital.com',
  publicUrl: 'https://dev.adforce.galeforcedigital.com',
  appUrl: 'https://dev.adforce.galeforcedigital.com'
};
