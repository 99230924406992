import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  BrandResponse,
  BrandResponseFull,
  CategoryResponse,
  ReportResponse,
  ScheduleReportResponse,
  SingleReportResponse
} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {ScheduleReport} from '../../_models/schedule-report';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private reportUrl = 'reports';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  saveOneTimeReport(params = {
    description: '',
    advertiserId: '',
    entityIds: '',
    entityType: '',
    conversionPixels: '',
    fromDate: '',
    toDate: '',
    reportType: '',
    aggregation: '',
  }): Observable<ReportResponse> {
    return this.http.post<ReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/one-time/save', params
    ).pipe(
      tap(_ => console.log('save report')),
      catchError(Errors.handleError<ReportResponse>('saveOneTimeReport'))
    );
  }

  saveSheduleReport(params = {
    advertiserId: '',
    name: '',
    conversionPixelIds: [],
    reportType: '',
    aggregation: '',
    entityType: '',
    entityIds: [],
    scheduleStartDate: '',
    timeToRunReport: '',
    scheduleEndDate: '',
    frequency: '',
    emails: [],
    lookbackWindow: '',
    lookbackType: '',
    status: 'ACTIVE'
  }): Observable<ScheduleReportResponse> {
    return this.http.post<ScheduleReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/schedule/save', params
    ).pipe(
      tap(_ => console.log('save schedule report')),
      catchError(Errors.handleError<ScheduleReportResponse>('saveSheduleReport'))
    );
  }

  getOneTimeReports(params = {status: 'all', filter: ''}): Observable<ReportResponse> {
    return this.http.get<ReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/one-time?max=1000&filter=' + params.filter + '&status=' + params.status
    ).pipe(
      tap(_ => console.log('fetched reports')),
      catchError(Errors.handleError<ReportResponse>('getReports'))
    );
  }

  getReports(): Observable<ReportResponse> {
    return this.http.get<ReportResponse>(
      `${environment.apiUrl}` + this.reportUrl
    ).pipe(
      tap(_ => console.log('fetched reports')),
      catchError(Errors.handleError<ReportResponse>('getReports'))
    );
  }

  getScheduleReports(params = {search: '', statuses: []}): Observable<ScheduleReportResponse> {
    return this.http.post<ScheduleReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/schedule', params
    ).pipe(
      tap(_ => console.log('fetched schedule reports')),
      catchError(Errors.handleError<ScheduleReportResponse>('getScheduleReports'))
    );
  }

  getReportsFromSchedule(params = {id: '', search: '', statuses: []}): Observable<SingleReportResponse> {
    return this.http.post<SingleReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/schedule/reports', params
    ).pipe(
      tap(_ => console.log('fetched reports from schedule')),
      catchError(Errors.handleError<SingleReportResponse>('getReportsFromSchedule'))
    );
  }

  getBrandsGroupsCampaigns(params = {archived: false, search: ''}): Observable<BrandResponseFull> {
    return this.http.post<BrandResponseFull>(
      `${environment.apiUrl}` + this.reportUrl + '/brands', params
    ).pipe(
      tap(_ => console.log('fetched schedule reports')),
      catchError(Errors.handleError<BrandResponseFull>('getBrandsGroupsCampaigns'))
    );
  }

  changeScheduleReportStatus(params = {id: '', status: ''}): Observable<ScheduleReportResponse> {
    return this.http.post<ScheduleReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/schedule/change-status', params
    ).pipe(
      tap(_ => console.log('update schedule report status')),
      catchError(Errors.handleError<ScheduleReportResponse>('changeScheduleReportStatus'))
    );
  }

  deleteScheduleReport(id): Observable<ScheduleReportResponse> {
    return this.http.get<ScheduleReportResponse>(
      `${environment.apiUrl}` + this.reportUrl + '/schedule/delete/' + id
    ).pipe(
      tap(_ => console.log('delete schedule report')),
      catchError(Errors.handleError<ScheduleReportResponse>('deleteScheduleReport'))
    );
  }

  deleteReport(ids: Array<any>): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiUrl}` + this.reportUrl + '/delete/' + ids.join(',')
    ).pipe(
      tap(_ => console.log('delete report')),
      catchError(Errors.handleError<any>('deleteReport'))
    );
  }

}
