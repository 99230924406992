import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
// import {NewCampaignComponent} from '../../pages/new-campaign/new-campaign.component';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {Observable, Observer} from 'rxjs';
import {Module} from '@ag-grid-community/core';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {DomainListService} from '../../_services/domain-list/domain-list.service';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'app-select-inventory-modal',
  templateUrl: './select-inventory-modal.component.html',
  styleUrls: ['./select-inventory-modal.component.scss']
})
export class SelectInventoryModalComponent implements OnInit, OnDestroy {
  // _parent: NewCampaignComponent ;
  exchangeGrid: any;
  exchangesSelected: any[] = [];
  domainsGrid: any;
  domainsSelected: any[] = [];
  domainListSelected: any[] = [];
  domainListGrid: any;
  updateInvType = 'none';
  // @Input() set parent(value: NewCampaignComponent ) {
  //   this._parent = value;
  // }
  public modules: Module[] = AllModules;
  @Output() dataToAdd = new EventEmitter<{}>();

  columnDefsExchange = [
    {headerName: '', field: 'exchangeId', width: 40, filter: false, hide: true },
    {headerName: 'Name', field: 'name', width: 500, checkboxSelection: true },
  ];
  rowDataExchange: any;

  columnDefsDomains = [
    {headerName: '', field: 'identifier', hide: true},
    {headerName: '', field: 'type', hide: true},
    {headerName: 'Name', field: 'name', width: 500, rowGroup: true, hide: true},
    {headerName: 'Y\'day\'s Auctions', field: 'dailyImpressions', width: 120, aggFunc: 'sum'},
    {headerName: 'CPM', field: 'observedCPM', width: 120,
      cellRenderer: (params) => { return ((params.node.allLeafChildren && params.node.allLeafChildren.length > 0) ? parseFloat(params.node.allLeafChildren[0].data.parentObservedCPM).toFixed(2) : parseFloat(params.value).toFixed(2)) }
    },
  ];
  rowDataDomains: any;

  columnDefsDomainList = [
    {headerName: 'ID', field: 'domainListId', width: 100, checkboxSelection: true },
    {
      headerName: 'Actions',
      field: 'id',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'DomainList' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 500 },
    {headerName: 'Type', field: 'type', width: 150, cellRenderer: (params) => params.value.toUpperCase()},
    {headerName: 'URL Count', field: 'domains', width: 150, cellRenderer: (params) => params.value.length},
  ];
  rowDataDomainList: any;
  amountExanges = 'all';
  loading = false;
  amountDomains = 'all';
  filterText = '';
  defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true
  };
  autoGroupColumnDef = {
    headerName: 'Inventory Unit',
    field: 'exchangeName',
    minWidth: 400,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      checkbox: true,
    }
  };
  amountDomainLists = 'none';
  domainAppsAvgCPM = 0.00;
  exangesChanged = false;
  domainsRequested = false;
  domainsListsRequested = false;
  exchangesRequested = false;
  fromEdit = false;

  constructor(private invService: InventoryService, private domainListService: DomainListService) { }

  ngOnInit() {
    this.rowDataExchange = new Observable((observer: Observer<Array<any>>) => {
      observer.next([]);
      observer.complete();
    });
    this.rowDataDomains = new Observable((observer: Observer<Array<any>>) => {
      observer.next([]);
      observer.complete();
    });
    if (this.updateInvType === 'custom') {
      this.amountDomains = 'custom';
      this.getDomains();
    }
  }

  setInvType(updateInvType = 'none') {
    this.updateInvType = updateInvType;
    if (this.updateInvType === 'custom') {
      this.amountDomains = 'custom';
      this.getDomains();
    }
  }

  setDomainListType(type = 'none') {
    this.amountDomainLists = type;
    if (type === 'custom') {
      this.getDomainList();
    }
  }

  setExchangeType(type = 'all') {
    this.amountExanges = type;
    if (type === 'custom') {
      this.getExangeData();
    }
  }

  openNewDomainListModal() {
    $('.new-domain-list-modal').modal('show');
  }

  getExangeData() {
    if (!this.exchangesRequested) {
      this.loading = true;
      this.invService.getExchanges().subscribe(
        (response) => {
          this.rowDataExchange = new Observable((observer: Observer<Array<any>>) => {
            observer.next(response.response.exchanges);
            observer.complete();
          });
          this.exchangesGridSetSelection();
          this.exchangesRequested = true;
          this.loading = false;
        },
        (error) => {
          console.log(error, 'getExangeData');
          this.loading = false;
        }
      )
    }
  }

  onExchangeGridReady(event: any) {
    this.exchangeGrid = event.api;
    this.exchangesGridSetSelection();
  }

  onDomainListGridReady(event: any) {
    this.domainListGrid = event.api;
    this.domainListGridSetSelection();
  }

  domainListGridSetSelection() {
    if (this.domainListSelected.length > 0 && typeof this.domainListGrid !== 'undefined') {
      this.domainListGrid.forEachNode(node => {
        if (this.domainListSelected.findIndex(i => i.domainListId === node.data.domainListId) !== -1) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }

  exchangesGridSetSelection() {
    if (this.exchangesSelected.length > 0 && typeof this.exchangeGrid !== 'undefined') {
      this.exchangeGrid.forEachNode(node => {
        if (this.exchangesSelected.findIndex(i => i.exchangeId === node.data.exchangeId) !== -1) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    }
  }

  exchangeSelection(event: any) {
    this.exchangesSelected = event.api.getSelectedRows();
    this.exangesChanged = true;
  }

  domainListSelection(event: any) {
    this.domainListSelected = event.api.getSelectedRows();
  }

  onDomainsGridReady(event: any) {
    this.domainsGrid = event.api;
    this.domainsGridSetSelection();
  }

  domainsGridSetSelection() {
    if (this.domainsSelected.length > 0 && typeof this.domainsGrid !== 'undefined') {
      this.domainsGrid.forEachNode(node => {
        if (typeof node.data !== 'undefined') {
          this.domainsSelected.findIndex(i => i.identifier === node.data.identifier) !== -1
            ? node.setSelected(true) : node.setSelected(false)
        }
      });
    }
  }

  domainsSelection(event: any) {
    let cpmAvg = 0.00;
    this.domainsSelected = event.api.getSelectedRows();
    this.domainsSelected.forEach((ex) => {
      cpmAvg += (ex.observedCPM !== '' ? ex.observedCPM : 0);
    });
    this.domainAppsAvgCPM = (this.domainsSelected.length > 0) ? (cpmAvg / this.domainsSelected.length) : 0;
  }

  getDomains(from = 'init') {
    if (!this.domainsRequested || from === 'filter') {
      this.loading = true;
      this.exangesChanged = false;
      const exchanges = this.exchangesSelected.map((ex) => ex.exchangeId).join();
      this.invService.getInventory({
        filter: this.filterText,
        inventoryType: '',
        mediaType: '',
        deviceTypes: '',
        exchangeIds: exchanges,
        categories: '',
        geoCodes: '',
        adSize: ''
      }).subscribe(
        (response) => {
          this.rowDataDomains = new Observable((observer: Observer<Array<any>>) => {
            observer.next(response.response.inventory_units);
            observer.complete();
          });
          this.domainsRequested = true;
          this.domainsGridSetSelection();
          this.loading = false;
        },
        (error) => {
          console.log(error, 'getExangeData');
          this.loading = false;
        }
      )
    }
  }

  clearDomainsSelected() {
    // if (typeof this.domainsGrid !== 'undefined') {
    //   this.domainsGrid.deselectAll();
    //   this.domainsSelected = [];
    // }
  }

  clearExchangeSelected() {
    // if (typeof this.exchangeGrid !== 'undefined') {
    //   this.exchangeGrid.deselectAll();
    //   this.exchangesSelected = [];
    // }
  }

  clearDomainListSelected() {
    // if (typeof this.domainListGrid !== 'undefined') {
    //   this.domainListGrid.deselectAll();
    //   this.domainListSelected = [];
    // }
  }

  getDomainList() {
    if (!this.domainsListsRequested) {
      this.domainListService.getDomainList().subscribe(
        (response) => {
          this.rowDataDomainList = Observable.create((obs) => {
            obs.next(response.response.domain_lists);
            obs.complete();
          });
          this.domainListGridSetSelection();
          this.domainsListsRequested = true;
        });
    }
  }

  sendSelectedItems() {
    if (this.amountExanges === 'all' && typeof this.exchangeGrid !== 'undefined') {
      this.exchangeGrid.deselectAll();
      this.exchangesSelected = [];
    }
    if (this.amountDomains === 'all' && typeof this.domainsGrid !== 'undefined') {
      this.domainsGrid.deselectAll();
      this.domainsSelected = [];
    }
    if (this.amountDomainLists === 'none' && typeof this.domainListGrid !== 'undefined') {
      this.domainListGrid.deselectAll();
      this.domainListSelected = [];
    }
    this.dataToAdd.emit({exchangesSelected: this.exchangesSelected, domainListSelected: this.domainListSelected, domainsSelected: this.domainsSelected});
    $('.select-inventory-modal').modal('hide');
  }

  ngOnDestroy(): void {

  }

  tabChanged(event: MatTabChangeEvent) {
    // if (event.index === 1 && this.amountDomains === 'custom' && this.exangesChanged ) {
    //   this.getDomains();
    // }
  }
}
