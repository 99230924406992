import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {ThirdPartyDataService} from '../../_services/third-party-data/third-party-data.service';

@Component({
  selector: 'app-brand-protection-bulk',
  templateUrl: './brand-protection-bulk.component.html',
  styleUrls: ['./brand-protection-bulk.component.scss']
})
export class BrandProtectionBulkComponent implements OnInit {

  brandProtectionSource = 'thirdParty';
  configContextual = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  itemsBrandProtection: TreeviewItem[] = [];
  brandProtectionRules: any[] = [];
  @Input() brandId: number;
  @Input() parentType = 'default';
  @Output() setDataRules = new EventEmitter<any>();

  constructor(private thirdSourceService: ThirdPartyDataService) { }

  ngOnInit() {
  }

  changeBranProtectionSource(event) {
    console.log(event);
  }

  brandProtectionFilterChanged(event) {
    console.log(event);
  }

  removeRule(pos, type) {
      this.brandProtectionRules.splice(pos, 1);
  }

  getBrandProtectionData(item = null) {
    if (this.itemsBrandProtection.length === 0) {
      this.thirdSourceService.getContextualSegments().subscribe(
        (response) => {
          let position = 0;
          for (const context in response[0]) {
            if (response[0][context].type === 'brand_safety') {
              this.itemsBrandProtection.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              this.itemsBrandProtection[position]['level'] = 0;
              this.itemsBrandProtection[position]['targetable'] = response[0][context].targetable;
              this.itemsBrandProtection[position].setCollapsedRecursive(true);
              position++;
            }
          }
          console.log(response, this.itemsBrandProtection)
        },
        (error) => {
          console.log(error, 'getBrandProtectionData');
        }
      )
    } else if (item !== null) {
      this.thirdSourceService.getContextualSegmentChildren(item.value).subscribe(
        (response) => {
          let position = 1;
          for (const context in response[0]) {
            if (response[0][context].type === 'brand_safety') {
              item.children.push(new TreeviewItem({
                // tslint:disable-next-line:max-line-length
                text: response[0][context].name, value: response[0][context].contextualEntryId, collapsed: true, children: ((response[0][context].numberOfChildren > 0) ? [
                  {text: 'loading', value: response[0][context].contextualEntryId}
                ] : null)
              }));
              item.children[position]['level'] = item.level + 1;
              item.children[position]['targetable'] = response[0][context].targetable;
              if (response[0][context].targetable) {
                item.children[position]['parentId'] = response[0][context].parentId;
                item.children[position]['parentName'] = response[0][context].parentName;
                item.children[position]['providerId'] = response[0][context].providerId;
                item.children[position]['providerName'] = response[0][context].providerName;
              }
              item.children[position].setCollapsedRecursive(true);
              position++;
            }
          }
          item.children.shift();
          console.log(response, this.itemsBrandProtection)
        },
        (error) => {
          console.log(error, 'getBrandProtectionData');
        }
      )
    }
  }

  addBrandProtectionTargetSegment(item, type: string) {
    item['type'] = type;
    item['blocked'] = false;
    this.brandProtectionRules.push(item);
    console.log(this.brandProtectionRules)
  }

  saveRules() {

  }

  cancelModal() {
    $('.brand-protection-modal').modal('hide');
  }
}
