import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-cell',
  // template: `<input type="checkbox" [checked]="params.value" (change)="onChange($event)">`
  template: `<mat-checkbox [checked]="params.value" (change)="onChange($event)" ></mat-checkbox>`
})
export class CheckBoxCellComponent {

  // @ts-ignore
  @ViewChild('.checkbox') checkbox: ElementRef;

  public params: ICellRendererParams;

  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public onChange(event) {
    this.params.data[this.params.colDef.field] = event.checked;
  }
}
