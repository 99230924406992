import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {
  AudienceResponse,
  AudiencesStatsResponse,
  BrandCategoriesResponse,
  BrandResponse,
  BrandStatsResponse
} from '../../_models/responses';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {Brand} from '../../_models/brand';
import {Audience} from '../../_models/audience';

@Injectable({
  providedIn: 'root'
})
export class AudiencesService {

  private audUrl = 'audiences';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  audiencesRefreshed: Subject<any> = new Subject<any>();

  constructor(private http: HttpClient) { }

  getAudiencesStats(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', visible: '', userIdTypes: ''}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<AudiencesStatsResponse> {
    return this.http.get<AudiencesStatsResponse>(
      `${environment.apiUrl}` + this.audUrl
      + '?brandId=' + brandId + '&max=' + max + '&pageSize=' + max + '&offset=' + offset + '&filter=' + params.filter
      + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + ((params.visible !== '') ? ('&visible=' + (params.visible)) : '')
      // + ((typeof params.userIdTypes !== 'undefined' && params.userIdTypes !== null && params.userIdTypes !== '') ? ('&userIdTypes=' + (params.userIdTypes)) : ('&userIdTypes=IFA,COOKIE_USER_ID,SYNTHETIC_CTV_ID,IP_ADDRESS,UNKNOWN'))
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<AudiencesStatsResponse>('getAudiencesStats'))
    );
  }

  getAudienceSets(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}` + 'campaigns/audiences/set/'
    ).pipe(
      tap(_ => console.log('fetched audience sets')),
      catchError(Errors.handleError<any>('getAudienceSets'))
    );
  }

  refreshAudiences(): Observable<any> {
    return this.audiencesRefreshed.asObservable();
  }

  getAudiencesWithStats(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', visible: '', userIdTypes: ''}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<AudiencesStatsResponse> {
    return this.http.get<AudiencesStatsResponse>(
      `${environment.apiUrl}` + this.audUrl + '/stats'
      + '?brandId=' + brandId + '&max=' + max + '&pageSize=' + max + '&offset=' + offset + '&filter=' + params.filter
      + '&dateTo=' + params.dateTo + '&dateFrom=' + params.dateFrom + ((params.visible !== '') ? ('&visible=' + (params.visible)) : '')
      + ((typeof params.userIdTypes !== 'undefined' && params.userIdTypes !== null && params.userIdTypes !== '') ? ('&userIdTypes=' + (params.userIdTypes)) : ('&userIdTypes=IFA,COOKIE_USER_ID,SYNTHETIC_CTV_ID,IP_ADDRESS,UNKNOWN'))
    ).pipe(
      tap(_ => console.log('fetched audiences')),
      catchError(Errors.handleError<AudiencesStatsResponse>('getAudiencesStats'))
    );
  }

  getAudienceById(id: any) {
    return this.http.get<BrandResponse>(`${environment.apiUrl}` + this.audUrl + '/' + id).pipe(
      tap(_ => console.log('fetched audience')),
      catchError(Errors.handleError<BrandResponse>('getAudienceById'))
    );
  }

  createAudience(aud: Audience): Observable<AudienceResponse> {
    return this.http.post<AudienceResponse>(`${environment.apiUrl}` + this.audUrl , aud, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('createAudience', aud))
      ) as Observable<AudienceResponse>;
  }

  updateAudience(aud: Audience, id: bigint): Observable<AudienceResponse>  {
    return this.http.put<AudienceResponse>(`${environment.apiUrl}` + this.audUrl + '/' + id, aud, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateAudience', aud))
      ) as Observable<AudienceResponse>;
  }

  deleteAudience(id: string) {
    return this.http.delete<BrandResponse>(`${environment.apiUrl}` + this.audUrl + '/' + id).pipe(
      tap(_ => console.log('deleted audience')),
      catchError(Errors.handleError<BrandResponse>('deleteAudience'))
    );
  }

  uploadDeviceIds(file: string, audienceId: number) {
    return this.http.post(`${environment.apiUrl}` + this.audUrl + '/uploadDeviceIds/' + audienceId , {fileUrl: file}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('uploadDeviceIds', file))
      );
  }

  uploadCRMList(file: string, audienceId: number) {
    return this.http.post(`${environment.apiUrl}` + this.audUrl + '/uploadCRMList/' + audienceId , {fileUrl: file}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('uploadDeviceIds', file))
      );
  }

  getTagCode(type: string, audienceId: number) {
    return this.http.get<{response: string, status: string}>(`${environment.apiUrl}` + this.audUrl + '/getTag/' + audienceId + '?type=' + type)
      .pipe(
        tap(_ => console.log('fetched audience')),
        catchError(Errors.handleError<{response: string, status: string}>('getTagCode'))
    );
  }

  getAllTagsCode(audIds: number[], type: string, audNames: string[], mapUrlRedirectUrl = '', linkUrl = '', linkText = '') {
    const httpOptionsCustom = {
      responseType: 'text',
      Accept: 'text/plain'
    };
    return this.http.get(`${environment.apiUrl}` + this.audUrl + '/getAllTags'
      + '?audiencesId[]=' + audIds.join('&audiencesId[]=') + '&type=' + type
      + '&audiencesName[]=' + audNames.join('&audiencesName[]=')
      + '&mapUrlRedirectUrl=' + mapUrlRedirectUrl + '&linkUrl=' + linkUrl
      + '&linkText=' + linkText ,
      // @ts-ignore
      httpOptionsCustom)
      .pipe(
        tap(_ => console.log('fetched getAllTagsCode')),
        catchError(Errors.handleError('getAllTagsCode'))
      );
  }
}
