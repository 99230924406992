import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {UniversalPixel} from '../../_models/universal_pixel';
import {UniversalPixelsService} from '../../_services/universal-pixels/universal-pixels.service';
import {Audience} from '../../_models/audience';
import {AudiencesService} from '../../_services/audiences/audiences.service';

@Component({
  selector: 'app-new-universal-pixel',
  templateUrl: './new-universal-pixel.component.html',
  styleUrls: ['./new-universal-pixel.component.scss']
})
export class NewUniversalPixelComponent implements OnInit {
  brandId: number;

  createOrEdit = 'Create';
  ngLoading = false;
  newUnivForm: FormGroup;
  universalPixelId: bigint;
  @Output() gridRefresh = new EventEmitter();
  newAudience = false;
  audName: string;

  constructor(
    private formB: FormBuilder,
    private univService: UniversalPixelsService,
    private alertService: AlertService,
    private audService: AudiencesService
  ) { }

  ngOnInit() {
    this.newUnivForm = this.formB.group({
      name: ['', Validators.required],
      deleted: [false],
      rules: this.formB.array([])
    });
  }

  loadUniversalPixel(univ: UniversalPixel) {
    this.resetModal();
    this.createOrEdit = 'Edit';
    this.universalPixelId = univ.universalPixelId;
    this.newUnivForm.addControl('brandId', new FormControl(this.brandId));
    this.newUnivForm.controls.name.setValue(univ.name);
    this.newUnivForm.controls.deleted.setValue(univ.deleted);

    this.univService.getPixelRules(univ.universalPixelId, this.brandId).subscribe(
      response => {
        if (typeof response.response.rules !== 'undefined' && response.response.rules.length > 0) {
          const rules = this.formB.array([]);
          for (const rule of response.response.rules) {
            const newRule = this.formB.group({});
            if (typeof rule.target !== 'undefined' && rule.target !== null) {
              const newRuleTarget = this.formB.group({});
              newRuleTarget.addControl('type', new FormControl(rule.target.type, Validators.required));
              newRuleTarget.addControl('blocked', new FormControl(rule.target.blocked, Validators.required));
              newRuleTarget.addControl('logicalOperator', new FormControl(rule.target.logicalOperator, Validators.required));
              if (typeof rule.target.targets !== 'undefined' && rule.target.targets !== null) {
                newRuleTarget.addControl('targets', new FormArray([]));
                for (const target of rule.target.targets) {
                  const newTarget = new FormGroup({});
                  newTarget.addControl('type', new FormControl(target.type, Validators.required));
                  newTarget.addControl('blocked', new FormControl(target.blocked, Validators.required));
                  newTarget.addControl('logicalOperator', new FormControl(target.logicalOperator, Validators.required));
                  if (typeof target.targets !== 'undefined' && target.targets !== null) {
                    newTarget.addControl('targets', new FormArray([]));
                    for (const subRule of target.targets) {
                      const subTarget = new FormGroup({});
                      subTarget.addControl('type', new FormControl(subRule.type, Validators.required));
                      subTarget.addControl('blocked', new FormControl(subRule.blocked, Validators.required));
                      subTarget.addControl('operator', new FormControl(subRule.operator, Validators.required));
                      subTarget.addControl('value', new FormControl(subRule.value, Validators.required));
                      subTarget.addControl('parameterName', new FormControl(subRule.parameterName));
                      (newTarget.controls.targets as FormArray).push(subTarget);
                    }
                  }

                  (newRuleTarget.controls.targets as FormArray).push(newTarget);
                }
              }
              newRule.addControl('target', newRuleTarget);
            }
            if (typeof rule.actions !== 'undefined' && rule.actions !== null) {
              const newRuleActions = this.formB.array([]);
              for (const action of rule.actions) {
                const newRuleAction = new FormGroup({});
                newRuleAction.addControl('id', new FormControl(action.id, Validators.required));
                newRuleAction.addControl('type', new FormControl(action.type, Validators.required));
                (newRuleActions as FormArray).push(newRuleAction);
              }
              newRule.addControl('actions', newRuleActions);
            }
            rules.push(newRule);
          }
          this.newUnivForm.removeControl('rules')
          this.newUnivForm.addControl('rules', rules);
        }
      }, error => console.log(error)
    )
    // if (typeof univ.rules !== 'undefined' && univ.rules !== null) {
    //   let rules = this.formB.array([]);
    //   for (const rule of univ.rules) {
    //     let newRule = this.formB.group({});
    //     if (typeof rule.target !== 'undefined' && rule.target !== null) {
    //       let newRuleTarget = this.formB.group({});
    //       newRuleTarget.addControl('type', new FormControl(rule.target.type, Validators.required));
    //       newRuleTarget.addControl('blocked', new FormControl(rule.target.blocked, Validators.required));
    //       newRuleTarget.addControl('logicalOperator', new FormControl(rule.target.logicalOperator, Validators.required));
    //       if (typeof rule.target.targets !== 'undefined' && rule.target.targets !== null) {
    //         newRuleTarget.addControl('targets', new FormArray([]));
    //         for (const target of rule.target.targets) {
    //           let newTarget = new FormGroup({});
    //           newTarget.addControl('type', new FormControl(target.type, Validators.required));
    //           newTarget.addControl('blocked', new FormControl(target.blocked, Validators.required));
    //           newTarget.addControl('logicalOperator', new FormControl(target.logicalOperator, Validators.required));
    //           if (typeof target.targets !== 'undefined' && target.targets !== null) {
    //             newTarget.addControl('targets', new FormArray([]));
    //             for (const subRule of target.targets) {
    //               let subTarget = new FormGroup({});
    //               subTarget.addControl('type', new FormControl(subRule.type, Validators.required));
    //               subTarget.addControl('blocked', new FormControl(subRule.blocked, Validators.required));
    //               subTarget.addControl('operator', new FormControl(subRule.operator, Validators.required));
    //               subTarget.addControl('value', new FormControl(subRule.value, Validators.required));
    //               subTarget.addControl('parameterName', new FormControl(subRule.parameterName));
    //               (newTarget.controls.targets as FormArray).push(subTarget);
    //             }
    //           }
    //
    //           (newRuleTarget.controls.targets as FormArray).push(newTarget);
    //         }
    //       }
    //       newRule.addControl('target', newRuleTarget);
    //     }
    //     if (typeof rule.actions !== 'undefined' && rule.actions !== null) {
    //       let newRuleActions = this.formB.array([]);
    //       for (const action of rule.actions) {
    //         let newRuleAction = new FormGroup({});
    //         newRuleAction.addControl('id', new FormControl(action.id, Validators.required));
    //         newRuleAction.addControl('type', new FormControl(action.type, Validators.required));
    //         (newRuleActions as FormArray).push(newRuleAction);
    //       }
    //       newRule.addControl('actions', newRuleActions);
    //     }
    //     rules.push(newRule);
    //   }
    //   this.newUnivForm.removeControl('rules')
    //   this.newUnivForm.addControl('rules', rules);
    // }
  }

  onSubmit() {
    this.ngLoading = true;
    console.log(this.newUnivForm.value);
    this.newUnivForm.addControl('brandId', new FormControl());
    this.newUnivForm.controls.brandId.setValue(this.brandId);
    if (this.createOrEdit === 'Create') {
      this.univService.createUniversal(this.newUnivForm.value as UniversalPixel).subscribe(
        (response) => {
          if (response.status === 'SUCCESS') {
            this.alertService.alert('success', 'Universal Pixel created successfully.')
          }
          this.gridRefresh.emit();
          this.ngLoading = false;
          this.resetModal();
        },
        (error) => { console.log(error, 'createUniversal'); this.ngLoading = false; }
      );
    } else {
      this.univService.updateUniversal(this.newUnivForm.value as UniversalPixel, this.universalPixelId).subscribe(
        (response) => {
          if (response.status === 'SUCCESS') {
            this.alertService.alert('success', 'Universal Pixel updated successfully.')
          }
          this.gridRefresh.emit();
          this.ngLoading = false;
        },
        (error) => { console.log(error, 'updateUniversal'); this.ngLoading = false; }
      )
    }

  }

  get formControls() {
    return this.newUnivForm.controls;
  }

  public resetModal() {
    this.createOrEdit = 'Create';
    this.newUnivForm.reset();
    this.ngOnInit();
    $('.new-universal-pixel-modal').modal('hide');
  }

  addRule() {
    const rule: FormGroup = this.formB.group({
      target: this.formB.group({
        type: ['COMPOSITE'],
        blocked: [false],
        logicalOperator: ['or'],
        targets: this.formB.array([]),
      }),
      actions: this.formB.array([
        this.formB.group({
          id: ['', Validators.required],
          type: ['AUDIENCE_ADDITION', Validators.required]
        })
      ])
    });
    // @ts-ignore
    (this.formControls.rules as FormArray).push(rule);
    this.newUnivForm.updateValueAndValidity();
  }

  removeRule(i: number) {
    // @ts-ignore
    (this.formControls.rules as FormArray).removeAt(i);
    this.newUnivForm.updateValueAndValidity();
  }

  createAudience() {
    this.ngLoading = true;
    this.audService.createAudience({brandId: this.brandId, name: this.audName, visible: true, notes: ''} as unknown as Audience).subscribe(
      (response) => {
        this.audName = '';
        this.newAudience = false;
        this.ngLoading = false;
        this.alertService.alert('success', 'Audience created successfully.');
        this.audService.audiencesRefreshed.next();
      },
      (error) => { console.log(error, 'createAudience'); this.ngLoading = false; }
    )
  }
}
