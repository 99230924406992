import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatRadioChange} from '@angular/material/radio';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {Observable, Observer} from 'rxjs';
import {Conversion} from '../../_models/conversions';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-conversions-bulk',
  templateUrl: './conversions-bulk.component.html',
  styleUrls: ['./conversions-bulk.component.scss']
})
export class ConversionsBulkComponent implements OnInit {

  public converstionGridData: any;
  public convertionsColumns = [
    {
      headerName: 'Name', field: 'name', width: 300
    },
    {
      headerName: 'ID', field: 'offerId', width: 100
    },
    {
      headerName: 'Default Value', field: 'defaultRevenue', width: 100, cellRenderer: (params) => '$' + parseFloat(params.value).toFixed(2)
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-shift-right" title="Track"></i>'},
  ];
  convertionsGrid: any;
  public trackConverstionGridData = [];
  public trackConvertionsColumns = [
    {
      headerName: 'Primary', field: 'primary', width: 80, hide: true, cellRenderer: 'checkBoxCellComponent'
    },
    {
      headerName: 'Name', field: 'name', width: 300
    },
    {
      headerName: 'ID', field: 'offerId', width: 100
    },
    {
      headerName: 'Default Value', field: 'defaultRevenue', width: 100, cellRenderer: (params) => '$' + parseFloat(params.value).toFixed(2)
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-trash"></i>'},
  ];
  clickthroughConversions = true;
  toTrackConvertionsColumnApi: any;
  toTrackConvertionsGrid: any;
  newCampaignForm: any;
  @Input() parentType = 'modal';
  @Input() brandId: number;
  @Output() setDataRules = new EventEmitter<any>();
  ngLoading = false;
  viewthroughConversions = true;

  constructor(private fb: FormBuilder, private convService: ConversionService) { }

  ngOnInit() {
    this.newCampaignForm = this.fb.group({
      conversionConfig: this.fb.group({
        conversionWindowInDays: ['30', Validators.required],
        offers: this.fb.array([]),
        ordered: [false],
        uniquenessPolicy: ['PER_USER_CUSTOM_PERIOD'],
        uniquenessPeriodInMillis: ['2', Validators.required]
      }),
      viewthruConfig: this.fb.group({
        conversionWindowInDays: ['30', Validators.required],
        offers: this.fb.array([]),
        payoutRate: [100],
        deduplicationEnabled: [true]
      })
    })
  }

  public convertionsGridInit(params) {
    this.convertionsGrid = params.api;
  }

  trackConvertionRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.toTrackConvertionsGrid.updateRowData({ add: [event.data] });
      const res2 = this.convertionsGrid.updateRowData({ remove: [event.data] });
    }
  }

  deleteAllTrackedConvertions() {
    this.toTrackConvertionsGrid.forEachNode((node, index) => {
      const res = this.convertionsGrid.updateRowData({ add: [node.data] });
      const res2 = this.toTrackConvertionsGrid.updateRowData({ remove: [node.data] });
    });
  }

  enablePrimaryConversion(event: MatCheckboxChange) {
    this.toTrackConvertionsColumnApi.setColumnVisible('primary', event.checked);
  }

  public toTrackConvertionsGridInit(params) {
    this.toTrackConvertionsGrid = params.api;
    this.toTrackConvertionsColumnApi = params.columnApi
  }

  deleteTrackConvertionRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res2 = this.convertionsGrid.updateRowData({ add: [event.data] });
      const res = this.toTrackConvertionsGrid.updateRowData({ remove: [event.data] });
    }
  }

  clickthroughConversionsChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.conversionWindowInDays.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.conversionWindowInDays.disable();
    }
  }

  viewthroughConversionsChanged(event: MatCheckboxChange) {
    if (event.checked) {
      // @ts-ignore
      this.newCampaignForm.controls.viewthruConfig.controls.conversionWindowInDays.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.viewthruConfig.controls.conversionWindowInDays.disable();
    }
  }

  uniquenessPolicyChanged(event: MatRadioChange) {
    if (event.value === 'PER_USER_CUSTOM_PERIOD') {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.uniquenessPeriodInMillis.enable();
    } else {
      // @ts-ignore
      this.newCampaignForm.controls.conversionConfig.controls.uniquenessPeriodInMillis.disable();
    }
  }

  saveRules() {
    if (typeof this.toTrackConvertionsGrid !== 'undefined') {
      this.toTrackConvertionsGrid.forEachNode((node, index) => {
        // @ts-ignore
        (this.newCampaignForm.controls.viewthruConfig.controls.offers as FormArray).push(this.fb.control(node.data.offerId));
        // @ts-ignore
        (this.newCampaignForm.controls.conversionConfig.controls.offers as FormArray).push(this.fb.control(node.data.offerId));
      });
    }
    this.setDataRules.emit(this.newCampaignForm.value);
  }

  cancelModal() {
    $('.conversions-bulk-modal').modal('hide');
  }

  getConversions(selectedOffers = []) {
    console.log(selectedOffers);
    this.convService.getConversionsStats(this.brandId).subscribe(
      (response) => {
        const toMove = [];
        let stay = [];
        if (selectedOffers.length > 0) {
          for (const selected of selectedOffers) {
            for (const conv of response.response.conversions) {
              if (conv.offerId === selected) {
                toMove.push(conv);
              } else {
                stay.push(conv);
              }
            }
          }
        } else {
          stay = response.response.conversions;
        }
        const res = this.toTrackConvertionsGrid.updateRowData({ add: toMove });
        this.converstionGridData = new Observable((observer: Observer<Array<Conversion>>) => {
          observer.next(stay);
          observer.complete();
        });
      },
      (error) => { console.log(error, 'getConversions'); }
    )
  }
}
