import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PerformanceComponent} from '../../../pages/performance/performance.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
// @ts-ignore
import moment from 'moment';

@Component({
  selector: 'app-campaign-settings-modal',
  templateUrl: './campaign-settings-modal.component.html',
  styleUrls: ['./campaign-settings-modal.component.scss']
})
export class CampaignSettingsModalComponent implements OnInit {
  _parent: PerformanceComponent ;
  newCampaignForm: FormGroup;
  @Input() set parent(value: PerformanceComponent ) {
    this._parent = value;
  }
  @Output() setData = new EventEmitter<any>();
  selectedOptions: string;
  startDate: any;
  endDate: any;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.newCampaignForm = this.fb.group({
      status: [''],
      defaultBid: [''],
      flightDates: this.fb.group({
        from: [''],
        to: ['']
      }),
      budget: this.fb.group({
        amount: [''],
        type: ['all_time'],
        schedule: this.fb.group({
          flightDates: this.fb.group({
            from: [''],
            to: ['']
          }),
        })
      }),
    });
  }

  sendGeneralData() {
    // moment('Wed May 13 2020 00:00:00 GMT-0400 (Eastern Daylight Time)').format('MM/DD/YYYY')
    console.log(this.endDate);
    let start = '';
    let end = '';
    if (typeof this.startDate !== 'undefined') {
      start = moment(this.startDate).format('YYYYMMDD');
    }
    if (typeof this.endDate !== 'undefined') {
      end = moment(this.endDate).format('YYYYMMDD');
    }
    (this.newCampaignForm.controls.flightDates['controls'].from as FormControl).setValue(start);
    (this.newCampaignForm.controls.flightDates['controls'].to as FormControl).setValue(end);
    (this.newCampaignForm.controls.budget['controls'].schedule['controls'].flightDates['controls'].from as FormControl).setValue(start);
    (this.newCampaignForm.controls.budget['controls'].schedule['controls'].flightDates['controls'].to as FormControl).setValue(end);
    this.setData.emit(this.newCampaignForm.value);
  }
}
