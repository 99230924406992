import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {of, Observable, BehaviorSubject} from 'rxjs';
import {User} from '../_models/user';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonAuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  loggedIn = false;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.isAuthonticated();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get clients() {
    return this.currentUserSubject.value.clients;
  }

  registerUser(form: any){
    console.log(form.value);
    return this.http.post('http://adforce.test/api/register', form.value);
  }

   isAuthonticated() {
    const promise = new Promise(
    (resolve, reject) => {
        setTimeout(() => {
          const t = localStorage.getItem('token');
          if (t) {
            this.loggedIn = true;
            resolve(this.loggedIn);
          } else {
            this.loggedIn = false;
            reject();
          }
        }, 800);
      });
    return promise;
  }

  logIn(form: any): Observable<any> {
    // return this.http.post('http://adforce.test/api/login', form.value);
    return this.http.post<any>(`${environment.apiUrl}login`, form.value)
      .pipe(map(response => {
        console.log(response);
        if (response && response.token && response.user) {
          localStorage.removeItem('token');
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.user));
          localStorage.setItem('user2', JSON.stringify(response.user2));
          this.currentUserSubject.next(response.user as User);
        }
        return response;
      }));
  }

  setCustomUser(user: User) {
    this.currentUserSubject.next(user);
  }

  logout(token: any = ''): Observable<any> {
    if ( this.loggedIn ) {
      this.loggedIn = false;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user2');
      this.currentUserSubject.next(null);
      return this.http.post(`${environment.apiUrl}logout`, {'token': token});
    }
    return this.http.post(`${environment.apiUrl}logout`, {'token': token});
    // return result;
  }

  resetPassword(email: string, redirectUrl = '') {
    return this.http.post<any>(`${environment.apiUrl}password/forgot`, { email: email, redirectUrl: redirectUrl})
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }

  // tslint:disable-next-line:variable-name
  newPassword(password: string, confirmPassword: string, token: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.put<any>(`${environment.apiUrl}password/reset`, { password: password, password_confirmation: confirmPassword, token: token })
      .pipe(map(response => {
        // login successful if there's a jwt token in the response
        console.log(response);
        return response;
      }));
  }
}
