import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonAuthService} from '../../auth/common-auth.service';
import {User} from '../../_models/user';
import {SupportService} from '../../_services/support/support.service';
import {AlertService} from '../../_services/alert.service';

@Component({
  selector: 'app-request-support',
  templateUrl: './request-support.component.html',
  styleUrls: ['./request-support.component.scss']
})
export class RequestSupportComponent implements OnInit {
  ngLoading = false;
  supportForm: FormGroup;
  currentUser: User = null;

  constructor(
    private fb: FormBuilder,
    private auth: CommonAuthService,
    private supportService: SupportService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.supportForm = this.fb.group({
      firstStep: this.fb.group({
        type: ['general'],
        email: ['', Validators.email]
      }),
      secondStep: this.fb.group({
        subject: ['', Validators.required],
        description: ['', Validators.required],
        adId: [''],
        campaignId: ['']
      })
    });
    this.auth.currentUser.subscribe(x => {
      (this.supportForm.controls.firstStep['controls'].email as FormControl).setValue(x.email)
    });
  }

  onSubmit() {
    this.ngLoading = true;
    this.supportService.sendTicket({...this.supportForm.controls.firstStep.value, ...this.supportForm.controls.secondStep.value}).subscribe(
      (response) => {
        this.ngLoading = false;
        if (response['status'] === 'SUCCESS') {
          this.alert.alert('success', 'Your ticket was successfully submitted');
          $('.support-request-modal').modal('hide');
        }
      },
      (error) => { console.log(error, 'sendTicket'); this.ngLoading = false; }
    )
  }
}
