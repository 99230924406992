import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrandsComponent} from '../../pages/brands/brands.component';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdService} from '../../_services/ad/ad.service';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {Ad} from '../../_models/ad';

@Component({
  selector: 'app-multiple-edit2-ad-modal',
  templateUrl: './multiple-edit2-ad-modal.component.html',
  styleUrls: ['./multiple-edit2-ad-modal.component.scss']
})
export class MultipleEdit2AdModalComponent implements OnInit {

  // private _parent: BrandsComponent ;
  // @Input() set parent(value: BrandsComponent ) {
  //   this._parent = value;
  // }

  ngLoading = false;
  selectedAds = [];
  selectedOption: string;
  updateForm: FormGroup;
  urlReg = '(https?://)([\\\\da-z.-]+)\\\\.([a-z.]{2,6})[/\\\\w .-]*/?';
  impTrackingField = [1];
  scriptTrackingField = [1];
  @Output() refreshGrid = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    private adService: AdService,
    private brandService: BrandService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.updateForm = this.fb.group({
      status: [''],
      clickUrl: [''],
      impressionTracking: this.fb.array([this.fb.control('')]),
      scriptTrackingUrl: this.fb.array([this.fb.control('')])
    });
  }

  get formControls() {
    return this.updateForm.controls;
  }

  get winNotificationUrlsControls() {
    return this.updateForm.controls.impressionTracking['controls'] as FormArray;
  }

  get scriptTrackingUrlsControls() {
    return this.updateForm.controls.scriptTrackingUrl['controls'] as FormArray;
  }

  addImpTrackingField(values: any = {}) {
    (this.updateForm.get('impressionTracking') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.impTrackingField.push(this.impTrackingField.length);
  }

  deleteImpTrackingField(pos: number) {
    console.log(pos, this.impTrackingField);
    (this.updateForm.get('impressionTracking') as FormArray).removeAt(pos);
    this.impTrackingField.splice(pos, 1);
  }

  addScriptTrackingField(values: any = {}) {
    (this.updateForm.get('scriptTrackingUrl') as FormArray).push(
      // this.fb.control('', [Validators.pattern(this.urlReg)])
      this.fb.control('')
    );
    this.scriptTrackingField.push(this.scriptTrackingField.length);
  }

  deleteScriptTrackingField(pos: number) {
    console.log(pos, this.scriptTrackingField);
    (this.updateForm.get('scriptTrackingUrl') as FormArray).removeAt(pos);
    this.scriptTrackingField.splice(pos, 1);
  }

  public impressionTrackingUrls() {
    const values = [];
    if (typeof this.updateForm.get('impressionTracking') !== 'undefined' && this.updateForm.get('impressionTracking') !== null) {
      const controlsPixels = (this.updateForm.get('impressionTracking') as FormArray).value;
      for (const control of controlsPixels) {
        if (control !== '') {
          values.push({'url': control, 'type': 'PIXEL'})
        }
      }
    }
    if (typeof this.updateForm.get('scriptTrackingUrl') !== 'undefined' && this.updateForm.get('scriptTrackingUrl') !== null) {
      const controlsScripts = (this.updateForm.get('scriptTrackingUrl') as FormArray).value;
      for (const control of controlsScripts) {
        if (control !== '') {
          values.push({'url': control, 'type': 'SCRIPT'});
          (this.updateForm.controls.impressionTracking as FormArray).push(this.fb.control(''));
        }
      }
    }
    console.log('impressionTracking', this.updateForm.get('impressionTracking'), values);
    return values;
  }

  onSubmit() {
    console.log('this.selectedAds: ', this.selectedAds);
    const values = this.impressionTrackingUrls();
    if (values.length > 0) {
      this.updateForm.controls.impressionTracking.setValue(values);
    }
    this.adService.updateMultipleAds(this.selectedAds, this.updateForm.value)
      .subscribe(response => {
        this.ngLoading = false;
        this.impTrackingField = [1];
        this.scriptTrackingField = [1];
        this.updateForm.reset();
        this.ngOnInit();
        this.refreshGrid.emit('ad');
        $('.new-multiple-edit2-ad-modal').modal('hide');
        this.alertService.alert('success', 'Ads updated successfully.');
      });
  }

}
