import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {AlertService} from '../_services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(public alert: AlertService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // note add this header for CORS pass
    let token = localStorage.getItem('token');
    let headers = null;
    let user = JSON.parse(localStorage.getItem('user'));
    if (typeof user !== 'undefined' && user !== null && typeof user['role'] !== 'undefined' && user['role'] !== null
      && user['role'] === 'ADMIN' && typeof user['email'] !== 'undefined' && user['email'] !== null && user['email'] === 'accounting@thinkstrategic.com' && req.method !== 'GET') {
      this.alert.alert('warning', 'You don\'t have enough permissions for this action.');
      return throwError(null);
    }
    if (token && req.url.indexOf('hereapi.com') === -1) {
      headers = new HttpHeaders({
        // 'Content-Type' : 'application/json',
        'Authorization' : `Bearer ${token}`,
      });
    } else {
      headers = new HttpHeaders({
        // 'Content-Type' : 'application/json'
      });
    }
    const requestChange = req.clone({headers});
    return next.handle(requestChange);
  }
}
