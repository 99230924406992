import {Component, OnInit, ViewChild} from '@angular/core';
import {RangesDatepickerComponent} from '../../shared/ranges-datepicker/ranges-datepicker.component';
import {NewBrandModalComponent} from '../../modals/new-brand-modal/new-brand-modal.component';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {Brand} from '../../_models/brand';
import {GroupService} from '../../_services/group/group.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Campaign} from '../../_models/campaign';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import {AlertService} from '../../_services/alert.service';
import {AudiencesRulesComponent} from '../../shared/audiences-rules/audiences-rules.component';
import {BrandProtectionBulkComponent} from '../../shared/brand-protection-bulk/brand-protection-bulk.component';
import {CampaignNameBulkComponent} from '../../shared/campaign-name-bulk/campaign-name-bulk.component';
import {ContextualBulkComponent} from '../../shared/contextual-bulk/contextual-bulk.component';
import {ConversionsBulkComponent} from '../../shared/conversions-bulk/conversions-bulk.component';
import {DayPartingBulkComponent} from '../../shared/day-parting-bulk/day-parting-bulk.component';
import {DomainListBulkComponent} from '../../shared/domain-list-bulk/domain-list-bulk.component';
import {LocationsBulkComponent} from '../../shared/locations-bulk/locations-bulk.component';
import {DomainsStatsCrudComponent} from '../../shared/domains-stats-crud/domains-stats-crud.component';
import {CsvExportParams} from 'ag-grid-community';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import * as moment from 'moment';
import { CommonAuthService } from 'app/auth/common-auth.service';
import {MatDialog} from '@angular/material/dialog';
import {CopyCampaignComponent} from '../../modals/campaign-bulk-edit/copy-campaign/copy-campaign.component';
import {CampaignAdsStatsComponent} from '../../shared/campaign-ads-stats/campaign-ads-stats.component';
import {CampaignConversionsStatsComponent} from '../../shared/campaign-conversions-stats/campaign-conversions-stats.component';
import {CampaignExchangesStatsComponent} from '../../shared/campaign-exchanges-stats/campaign-exchanges-stats.component';
import {CampaignDailyStatsComponent} from '../../shared/campaign-daily-stats/campaign-daily-stats.component';
import {CampaignHourlyStatsComponent} from '../../shared/campaign-hourly-stats/campaign-hourly-stats.component';
import {GridColumnsCustomizationComponent} from '../../shared/grid-columns-customization/grid-columns-customization.component';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  compParent = {componentParent: this};
  brandName = '';
  groupName = '';
  archivedShow = true;

  selectedOption = '';
  rangesFooter = RangesDatepickerComponent;
  title = 'app';
  hideStatus = false;
  columnDefsCamp = [];
  rowDataCamp: Observable<Array<Campaign>>;
  defaultCampsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' },
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA - valueB)
  };
  pinnedCampsRowData: any;
  @ViewChild(NewBrandModalComponent, null) newBrandModal: NewBrandModalComponent;
  @ViewChild(AudiencesRulesComponent, null) audRulesModal: AudiencesRulesComponent;
  @ViewChild(ContextualBulkComponent, null) contextualBulkRulesModal: ContextualBulkComponent;
  @ViewChild(ConversionsBulkComponent, null) conversionsBulkModal: ConversionsBulkComponent;
  @ViewChild(DayPartingBulkComponent, null) dayPartingBulkModal: DayPartingBulkComponent;
  @ViewChild(DomainListBulkComponent, null) domainListBulkModal: DomainListBulkComponent;
  @ViewChild(LocationsBulkComponent, null) locationsBulkModal: LocationsBulkComponent;
  @ViewChild(BrandProtectionBulkComponent, null) brandProtectionBulkModal: BrandProtectionBulkComponent;
  @ViewChild(CampaignNameBulkComponent, null) campaignNameBulkModal: CampaignNameBulkComponent;
  @ViewChild(DomainsStatsCrudComponent, null) domaninsCampaignStats: DomainsStatsCrudComponent;
  @ViewChild(CampaignAdsStatsComponent, null) adsCampaignStats: CampaignAdsStatsComponent;
  @ViewChild(CampaignConversionsStatsComponent, null) convCampaignStats: CampaignConversionsStatsComponent;
  @ViewChild(CampaignExchangesStatsComponent, null) exCampaignStats: CampaignExchangesStatsComponent;
  @ViewChild(CampaignDailyStatsComponent, null) dailyCampaignStats: CampaignDailyStatsComponent;
  @ViewChild(CampaignHourlyStatsComponent, null) hourlyCampaignStats: CampaignHourlyStatsComponent;
  campDateRange: any;
  campFilterString = '';
  campaignIdSelected = false;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent
  };
  groupId: number;
  brandId: number;
  campIdSelected: number;
  campSelectedRows = [];
  campaignGrid: any;
  ngLoading = false;
  locationsBulkType = 'replace';
  campaignSelected: any = null;
  currentUser;
  rangeDatesFlag = false;
  filtersActive = false;
  currentCampaignId: string = null;
  tabSelected = 0;
  tabs = ['domains', 'ads', 'conversions', 'exchanges', 'daily', 'hourly'];
  currentDateScope = 'one';

  private gridColumns: Array<{text: string, id: string, child: Array<{text: string, id: string}>}> =
      [
        {
          text: 'Auctions',
          id: 'auctions',
          child: [
            {text: 'Imp. Won', id: 'impressionsWon'},
            {text: 'Win Rate', id: 'winRate'},
            {text: 'Total eCPM', id: 'totalEffectiveCPM'},
            {text: 'Adv. Spend eCPM', id: 'effectiveCPMOnAdvertiserSpend'},
            {text: 'Media eCPM', id: 'mediaEffectiveCPM'},
            {text: 'Data eCPM', id: 'dataEffectiveCPM'}
          ]
        },
        {
          text: 'Audio/Video',
          id: 'audio_video',
          child: [
            {text: 'Audio/Video Starts', id: 'videoStarted'},
            {text: '25% Complete', id: 'videoFirstQuartileReached'},
            {text: '50% Complete', id: 'videoMidpointReached'},
            {text: '75% Complete', id: 'videoThirdQuartileReached'},
            {text: '100% Complete', id: 'videoCompleted'},
            {text: 'Completion Rate', id: 'videoCompletionRate'},
            {text: 'Adv. Spend eCPCV', id: 'effectiveCPCVOnAdvertiserSpend'},
            {text: 'Total eCPCV', id: 'totalEffectiveCPCV'},
          ]
        },
        {
          text: 'Budget',
          id: 'budget',
          child: [
            {text: 'Total Spend', id: 'totalSpend'},
            {text: 'CPM Bid', id: 'impliedBid'},
            {text: 'Daily Budget', id: 'dailyTotalSpendTarget'},
            {text: 'All Time Budget', id: 'budget'},
            {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
            {text: 'Adv. Spend', id: 'advertiserSpend'},
            {text: 'Media Spend', id: 'auctionsSpend'},
            {text: 'Data Spend', id: 'dataSpend'},
            {text: 'Actual Margin %', id: 'marginOnAdvertiserSpend'},
            {text: 'Max Billable', id: 'advertiserSpendRate'}
          ]
        },
        {
          text: 'Clicks',
          id: 'clicks',
          child: [
            {text: 'Clicks', id: 'clicks'},
            {text: 'Adv. Spend eCPC', id: 'effectiveCPCOnAdvertiserSpend'},
            {text: 'Total eCPC', id: 'grossTotalEffectiveCPC'},
            {text: 'CTR', id: 'clickthruRate'},
            // {text: 'CPR', id: 'costPerClick'},
            {text: 'EPC', id: 'profitPerClick'},
          ]
        },
        {
          text: 'Companion Ads',
          id: 'companion_ads',
          child: [
            {text: 'Companion Imps. Won', id: 'companionImpressions'},
            {text: 'Companion Clicks', id: 'companionClicks'},
            {text: 'Companion CTC', id: 'companionConversions'},
            {text: 'Companion CTC Revenue', id: 'companionOfferRevenue'}
          ]
        },
        {
          text: 'Conversions',
          id: 'conversions',
          child: [
            {text: 'Total Conversions', id: 'totalConversions'},
            {text: 'CTC', id: 'clickThroughConversions'},
            {text: 'VTC', id: 'viewthruConversions'},
            {text: 'Adv. Spend eCPA', id: 'effectiveCPAOnAdvertiserSpend'},
            {text: 'Total eCPA', id: 'grossTotalEffectiveCPA'},
            {text: 'CTC eCPA', id: 'ctcEffectiveCPA'},
            {text: 'Click CVR', id: 'clickCVR'},
            {text: 'View CVR', id: 'viewCVR'},
            {text: 'Total CVRM', id: 'totalCVRM'},
          ]
        },
        {
          text: 'Extras',
          id: 'extras',
          child: [
            {text: 'Group ID', id: 'campaignGroupId'}
          ]
        },
        {
          text: 'Primary Conversions',
          id: 'primary_conversions',
          child: [
            {text: 'Primary Conv.', id: 'conversions'},
            {text: 'Primary CTC', id: 'primaryCTC'},
            {text: 'Primary VTC', id: 'primaryVTC'},
            {text: 'Primary Adv. Spend eCPA', id: 'primaryEffectiveCPASpend'},
            {text: 'Primary Conv. eCPA', id: 'primaryEffectiveCPAConversion'},
            {text: 'Primary CTC eCPA', id: 'primaryEffectiveCPACTC'},
          ]
        },
        {
          text: 'Revenue',
          id: 'revenues',
          child: [
            {text: 'Total Revenue', id: 'revenue'},
            {text: 'CTC Revenue', id: 'ctcRevenue'},
            {text: 'VTC Revenue', id: 'vtcRevenue'},
            {text: 'Total RPM', id: 'revenuePerMille'},
            {text: 'Click RPM', id: 'clickRPM'},
            {text: 'ROAS', id: 'returnOnAdSpend'},
          ]
        },
        {
          text: 'Viewability',
          id: 'viewability',
          child: [
            {text: 'Total Eligible Imps.', id: 'eligibleImpressions'},
            {text: 'Total Measured Imps.', id: 'measuredImpressions'},
            {text: 'Total Viewable Imps.', id: 'viewableImpressions'},
            {text: 'Measured Rate', id: 'measuredRate'},
            {text: 'Viewable Rate', id: 'viewableRate'},
            {text: 'Eligible Spend', id: 'eligibleSpend'},
            {text: 'Eligible vCPM', id: 'eligibleVCPM'},
          ]
        }
    ];

  columnSetup = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks', 'clickthruRate', 'effectiveCPCOnAdvertiserSpend','grossTotalEffectiveCPC','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','grossTotalEffectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];
  columnSetupDefault = ['impressionsWon','winRate','totalEffectiveCPM','effectiveCPMOnAdvertiserSpend','mediaEffectiveCPM','dataEffectiveCPM','videoStarted','videoFirstQuartileReached','videoMidpointReached','videoThirdQuartileReached','videoCompleted','videoCompletionRate','effectiveCPCVOnAdvertiserSpend','totalEffectiveCPCV','totalSpend','impliedBid','dailyTotalSpendTarget','budget','gboDailyTotalSpendTarget','advertiserSpend','auctionsSpend','dataSpend','marginOnAdvertiserSpend','advertiserSpendRate','clicks', 'clickthruRate','effectiveCPCOnAdvertiserSpend','grossTotalEffectiveCPC','profitPerClick','companionImpressions','companionClicks','companionConversions','companionOfferRevenue','totalConversions','clickThroughConversions','viewthruConversions','effectiveCPAOnAdvertiserSpend','grossTotalEffectiveCPA','ctcEffectiveCPA','clickCVR','viewCVR','totalCVRM','campaignGroupId','conversions','primaryCTC','primaryVTC','primaryEffectiveCPASpend','primaryEffectiveCPAConversion','primaryEffectiveCPACTC','revenue','ctcRevenue','vtcRevenue','revenuePerMille','clickRPM','returnOnAdSpend','eligibleImpressions','measuredImpressions','viewableImpressions','measuredRate','viewableRate','eligibleSpend','eligibleVCPM'];

  constructor(
    private groupService: GroupService,
    private route: ActivatedRoute,
    private campService: CampaignService,
    private router: Router,
    private alert: AlertService,
    private gridRender: GridRenderService,
    private breadcrumbsService: BreadcrumbsService,
    private auth: CommonAuthService,
    public dialog: MatDialog
  ) {
    this.initializeRangeDates();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.hideInitialStatus();
    const campaignsCustomColumns = localStorage.getItem('campaignsCustomColumns');
    if (campaignsCustomColumns !== null) {
      this.columnSetup = JSON.parse(campaignsCustomColumns);
    }
    this.route.params.subscribe(params => {
      this.breadcrumbsService.breadcrumbs = [
        {label: 'My Brands', url: '/mybrands', params: []},
        {label: 'Campaigns', url: '/campaigns/' + params['brandId'] + '/' + params['groupId'], params: []},
      ];
      this.groupId = params['groupId'];
      this.brandId = params['brandId'];
      this.refreshGrid();
      this.groupService.getBrandAndCampaignGroupNames(params['groupId']).subscribe(
        (response) => {
          this.groupName = response.groupName;
          this.brandName = response.brandName;
        },
        (error) => { console.log(error) }
      );
    });
  }

  hideInitialStatus() {
    if (this.currentUser.role !== 'ADMIN') {
      const userCients = this.currentUser.clients.map(i => {
        if (i.status === 0 || i.status === '0') {
            this.hideStatus = true;
            return;
        }
      });
    }
  }

  getCampaigns(groupId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    this.ngLoading = true;
    this.campService.getCampaigns(groupId, params).subscribe(
      (response) => {
        this.rowDataCamp = Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        const pinnedData = {
          totalSpend: 0,
          advertiserSpendRate: 0,
          advertiserSpend: 0,
          impressionsWon: 0,
          totalEffectiveCPM: 0,
          videoCompletionRate: 0,
          winRate: 0,
          clicks: 0,
          costPerClick: 0,
          clickThroughRate: 0,
          totalConversions: 0,
          conversions: 0,
          eligibleImpressions: 0,
          measuredImpressions: 0,
          viewableImpressions: 0,
          marginOnAdvertiserSpend: 0,
          dataSpend: 0,
          effectiveCPMOnAdvertiserSpend: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          mediaEffectiveCPM: 0,
          dataEffectiveCPM: 0,
          clickThroughConversions: 0,
          clickRPM: 0,
          viewthruConversions: 0,
          viewCVR: 0,
          revenue: 0,
          ctcRevenue: 0,
          vtcRevenue: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          totalCVRM: 0,
          profitPerClick: 0,
          revenuePerMille: 0,
          returnOnAdSpend: 0,
          videoStarted: 0,
          videoFirstQuartileReached: 0,
          videoMidpointReached: 0,
          videoThirdQuartileReached: 0,
          videoCompleted: 0,
          effectiveCPCVOnAdvertiserSpend: 0,
          totalEffectiveCPCV: 0,
          companionImpressions: 0,
          companionClicks: 0,
          companionConversions: 0,
          companionOfferRevenue: 0,
          defaultBid: null,
          grossTotalEffectiveCPA: 0,
          grossTotalEffectiveCPC: 0,
          clickthruRate: 0
        };
        this.pinnedCampsRowData = Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        if (typeof response.response.campaigns !== 'undefined' && response.response.campaigns !== null) {
          for (const i in response.response.campaigns) {
            // const margin = (typeof response.response.campaigns[i]['extraData'] !== 'undefined' && response.response.campaigns[i]['extraData'].margin > 1) ?
            //   ((100 - response.response.campaigns[i]['extraData'].margin) / 100) : 1;
            const margin = 1;
            pinnedData.totalSpend += isNaN(response.response.campaigns[i].totalSpend) ? 0 : response.response.campaigns[i].totalSpend / margin;
            response.response.campaigns[i].totalSpend = isNaN(response.response.campaigns[i].totalSpend) ? 0 : response.response.campaigns[i].totalSpend / margin;
            response.response.campaigns[i].defaultBid = isNaN(response.response.campaigns[i].defaultBid) ? 0 : response.response.campaigns[i].defaultBid / margin;
            if (typeof response.response.campaigns[i].budget !== 'undefined' && typeof response.response.campaigns[i].budget.amount !== 'undefined') {
              // response.response.campaigns[i].budget.amount = isNaN(response.response.campaigns[i].budget.amount) ? 0 : response.response.campaigns[i].budget.amount / margin;
              response.response.campaigns[i].budget.amount = isNaN(response.response.campaigns[i].budget.amount) ? 0 : response.response.campaigns[i].budget.amount /
                ((typeof response.response.campaigns[i]['extraData'] !== 'undefined' && response.response.campaigns[i]['extraData'].margin > 1) ?
              ((100 - response.response.campaigns[i]['extraData'].margin) / 100) : 1);
            }
            // pinnedData.defaultBid += isNaN(response.response.campaigns[i].defaultBid) ? 0 : response.response.campaigns[i].defaultBid;
            pinnedData.advertiserSpendRate += isNaN(response.response.campaigns[i].advertiserSpendRate) ? 0 : response.response.campaigns[i].advertiserSpendRate;
            pinnedData.advertiserSpend += isNaN(response.response.campaigns[i].advertiserSpend) ? 0 : response.response.campaigns[i].advertiserSpend / margin
            response.response.campaigns[i].advertiserSpend = isNaN(response.response.campaigns[i].advertiserSpend) ? 0 : response.response.campaigns[i].advertiserSpend / margin
            pinnedData.impressionsWon += isNaN(response.response.campaigns[i].impressionsWon) ? 0 : response.response.campaigns[i].impressionsWon;

            response.response.campaigns[i].totalEffectiveCPM = isNaN(response.response.campaigns[i].totalEffectiveCPM) ? 0 : response.response.campaigns[i].totalEffectiveCPM / margin
            // pinnedData.totalEffectiveCPM += isNaN(response.response.campaigns[i].totalEffectiveCPM) ? 0 : response.response.campaigns[i].totalEffectiveCPM;

            pinnedData.videoCompletionRate += isNaN(response.response.campaigns[i].videoCompletionRate) ? 0 : response.response.campaigns[i].videoCompletionRate;
            pinnedData.winRate += isNaN(response.response.campaigns[i].winRate) ? 0 : response.response.campaigns[i].winRate;
            pinnedData.clicks += isNaN(response.response.campaigns[i].clicks) ? 0 : response.response.campaigns[i].clicks;

            response.response.campaigns[i].costPerClick = isNaN(response.response.campaigns[i].costPerClick) ? 0 : response.response.campaigns[i].costPerClick / margin
            pinnedData.costPerClick += isNaN(response.response.campaigns[i].costPerClick) ? 0 : response.response.campaigns[i].costPerClick;

            pinnedData.clickThroughRate += isNaN(response.response.campaigns[i].clickThroughRate) ? 0 : response.response.campaigns[i].clickThroughRate;
            pinnedData.totalConversions += isNaN(response.response.campaigns[i].totalConversions) ? 0 : response.response.campaigns[i].totalConversions;
            pinnedData.conversions += isNaN(response.response.campaigns[i].conversions) ? 0 : response.response.campaigns[i].conversions;
            pinnedData.eligibleImpressions += isNaN(response.response.campaigns[i].eligibleImpressions) ? 0 : response.response.campaigns[i].eligibleImpressions;
            pinnedData.measuredImpressions += isNaN(response.response.campaigns[i].measuredImpressions) ? 0 : response.response.campaigns[i].measuredImpressions;
            pinnedData.viewableImpressions += isNaN(response.response.campaigns[i].viewableImpressions) ? 0 : response.response.campaigns[i].viewableImpressions;
            pinnedData.marginOnAdvertiserSpend += isNaN(response.response.campaigns[i].marginOnAdvertiserSpend) ? 0 : response.response.campaigns[i].marginOnAdvertiserSpend;
            pinnedData.dataSpend += isNaN(response.response.campaigns[i].dataSpend) ? 0 : response.response.campaigns[i].dataSpend;

            response.response.campaigns[i].effectiveCPMOnAdvertiserSpend = isNaN(response.response.campaigns[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPMOnAdvertiserSpend / margin
            pinnedData.effectiveCPMOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPMOnAdvertiserSpend;

            response.response.campaigns[i].effectiveCPCOnAdvertiserSpend = isNaN(response.response.campaigns[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCOnAdvertiserSpend / margin
            pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCOnAdvertiserSpend;

            response.response.campaigns[i].mediaEffectiveCPM = isNaN(response.response.campaigns[i].mediaEffectiveCPM) ? 0 : response.response.campaigns[i].mediaEffectiveCPM / margin
            pinnedData.mediaEffectiveCPM += isNaN(response.response.campaigns[i].mediaEffectiveCPM) ? 0 : response.response.campaigns[i].mediaEffectiveCPM;

            response.response.campaigns[i].dataEffectiveCPM = isNaN(response.response.campaigns[i].dataEffectiveCPM) ? 0 : response.response.campaigns[i].dataEffectiveCPM / margin
            pinnedData.dataEffectiveCPM += isNaN(response.response.campaigns[i].dataEffectiveCPM) ? 0 : response.response.campaigns[i].dataEffectiveCPM;


            pinnedData.clickThroughConversions += isNaN(response.response.campaigns[i].clickThroughConversions) ? 0 : response.response.campaigns[i].clickThroughConversions;
            pinnedData.clickRPM += isNaN(response.response.campaigns[i].clickRPM) ? 0 : response.response.campaigns[i].clickRPM;
            pinnedData.viewthruConversions += isNaN(response.response.campaigns[i].viewthruConversions) ? 0 : response.response.campaigns[i].viewthruConversions;
            pinnedData.viewCVR += isNaN(response.response.campaigns[i].viewCVR) ? 0 : response.response.campaigns[i].viewCVR;

            response.response.campaigns[i].revenue = isNaN(response.response.campaigns[i].revenue) ? 0 : response.response.campaigns[i].revenue / margin
            pinnedData.revenue += isNaN(response.response.campaigns[i].revenue) ? 0 : response.response.campaigns[i].revenue;

            response.response.campaigns[i].ctcRevenue = isNaN(response.response.campaigns[i].ctcRevenue) ? 0 : response.response.campaigns[i].ctcRevenue / margin
            pinnedData.ctcRevenue += isNaN(response.response.campaigns[i].ctcRevenue) ? 0 : response.response.campaigns[i].ctcRevenue;

            response.response.campaigns[i].vtcRevenue = isNaN(response.response.campaigns[i].vtcRevenue) ? 0 : response.response.campaigns[i].vtcRevenue / margin
            pinnedData.vtcRevenue += isNaN(response.response.campaigns[i].vtcRevenue) ? 0 : response.response.campaigns[i].vtcRevenue;

            response.response.campaigns[i].effectiveCPAOnAdvertiserSpend = isNaN(response.response.campaigns[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPAOnAdvertiserSpend / margin
            pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPAOnAdvertiserSpend;

            pinnedData.viewCVR += isNaN(response.response.campaigns[i].viewCVR) ? 0 : response.response.campaigns[i].viewCVR;
            pinnedData.totalCVRM += isNaN(response.response.campaigns[i].totalCVRM) ? 0 : response.response.campaigns[i].totalCVRM;

            response.response.campaigns[i].profitPerClick = isNaN(response.response.campaigns[i].profitPerClick) ? 0 : response.response.campaigns[i].profitPerClick / margin
            pinnedData.profitPerClick += isNaN(response.response.campaigns[i].profitPerClick) ? 0 : response.response.campaigns[i].profitPerClick;

            response.response.campaigns[i].revenuePerMille = isNaN(response.response.campaigns[i].revenuePerMille) ? 0 : response.response.campaigns[i].revenuePerMille / margin
            pinnedData.revenuePerMille += isNaN(response.response.campaigns[i].revenuePerMille) ? 0 : response.response.campaigns[i].revenuePerMille;

            pinnedData.clickRPM += isNaN(response.response.campaigns[i].clickRPM) ? 0 : response.response.campaigns[i].clickRPM;

            response.response.campaigns[i].returnOnAdSpend = isNaN(response.response.campaigns[i].returnOnAdSpend) ? 0 : response.response.campaigns[i].returnOnAdSpend / margin
            pinnedData.returnOnAdSpend += isNaN(response.response.campaigns[i].returnOnAdSpend) ? 0 : response.response.campaigns[i].returnOnAdSpend;

            pinnedData.videoStarted += isNaN(response.response.campaigns[i].videoStarted) ? 0 : response.response.campaigns[i].videoStarted;
            pinnedData.videoFirstQuartileReached += isNaN(response.response.campaigns[i].videoFirstQuartileReached) ? 0 : response.response.campaigns[i].videoFirstQuartileReached;
            pinnedData.videoMidpointReached += isNaN(response.response.campaigns[i].videoMidpointReached) ? 0 : response.response.campaigns[i].videoMidpointReached;
            pinnedData.videoThirdQuartileReached += isNaN(response.response.campaigns[i].videoThirdQuartileReached) ? 0 : response.response.campaigns[i].videoThirdQuartileReached;
            pinnedData.videoCompleted += isNaN(response.response.campaigns[i].videoCompleted) ? 0 : response.response.campaigns[i].videoCompleted;
            // tslint:disable-next-line:max-line-length
            response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend = isNaN(response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend / margin
            pinnedData.effectiveCPCVOnAdvertiserSpend += isNaN(response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaigns[i].effectiveCPCVOnAdvertiserSpend;

              response.response.campaigns[i].totalEffectiveCPCV = isNaN(response.response.campaigns[i].totalEffectiveCPCV) ? 0 : response.response.campaigns[i].totalEffectiveCPCV / margin
            pinnedData.totalEffectiveCPCV += isNaN(response.response.campaigns[i].totalEffectiveCPCV) ? 0 : response.response.campaigns[i].totalEffectiveCPCV;

            pinnedData.companionImpressions += isNaN(response.response.campaigns[i].companionImpressions) ? 0 : response.response.campaigns[i].companionImpressions;
            pinnedData.companionClicks += isNaN(response.response.campaigns[i].companionClicks) ? 0 : response.response.campaigns[i].companionClicks;
            pinnedData.companionConversions += isNaN(response.response.campaigns[i].companionConversions) ? 0 : response.response.campaigns[i].companionConversions;
            pinnedData.companionOfferRevenue += isNaN(response.response.campaigns[i].companionOfferRevenue) ? 0 : response.response.campaigns[i].companionOfferRevenue;

            response.response.campaigns[i]['grossTotalEffectiveCPA'] = isNaN(response.response.campaigns[i]['grossTotalEffectiveCPA']) ? 0 : response.response.campaigns[i]['grossTotalEffectiveCPA'] / margin;
            // pinnedData.grossTotalEffectiveCPA += isNaN(response.response.campaigns[i]['grossTotalEffectiveCPA']) ? 0 : response.response.campaigns[i]['grossTotalEffectiveCPA'];

            response.response.campaigns[i]['grossTotalEffectiveCPC'] = isNaN(response.response.campaigns[i]['grossTotalEffectiveCPC']) ? 0 : response.response.campaigns[i]['grossTotalEffectiveCPC'] / margin;
            pinnedData.grossTotalEffectiveCPC += isNaN(response.response.campaigns[i]['grossTotalEffectiveCPC']) ? 0 : response.response.campaigns[i]['grossTotalEffectiveCPC'];
          }
          if (pinnedData.impressionsWon > 0) {
            pinnedData.totalEffectiveCPM = (pinnedData.totalSpend / pinnedData.impressionsWon) * 1000;
            pinnedData.clickthruRate = (pinnedData.clicks / pinnedData.impressionsWon);
          }
          if (pinnedData.totalConversions > 0) {
            pinnedData.grossTotalEffectiveCPA = pinnedData.totalSpend / pinnedData.totalConversions;
          }
        }
        this.pinnedCampsRowData = Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.rowDataCamp = Observable.create((obs) => {
          obs.next(response.response.campaigns);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => {
        console.log(error);
        this.ngLoading = false;
      }
    )
  }

  editNewCampaignModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.router.navigate(['/campaigns/' + this.brandId + '/' + this.groupId + '/edit-campaign/' + node.data.campaignId + '/advance']);
    }
    if (
      typeof node.column !== 'undefined'
      && typeof node.event !== 'undefined'
      && node.column.colId === 'actions'
      && node.event.target.className === 'ti-layers'
    ) {
      this.currentCampaignId = node.data.campaignId;
      const dialogRef = this.dialog.open(CopyCampaignComponent, {
        width: '610px',
        data: {brandId: this.brandId, groupId: this.groupId, campId: this.currentCampaignId, campName: node.data.name}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (typeof result !== 'undefined' && result !== null) {
          this.refreshGrid();
        }
      });
    }
  }

  campaignRowSelected(event: any) {
    if (event.node.selected) {
      this.campaignSelected = event.data;
      this.refreshGrid(this.tabs[this.tabSelected], this.campaignSelected.campaignId);
    }
  }

  refreshGrid(type = 'campaign', campaignId: any = null, status = 'online,offline') {
    switch (type) {
      case 'campaign':
        this.getCampaigns(this.groupId, {
          filter: this.campFilterString,
          dateTo: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.end.format('YYYYMMDD') : '',
          dateFrom: (this.campDateRange !== null && typeof this.campDateRange !== 'undefined') ? this.campDateRange.begin.format('YYYYMMDD') : '',
          status: status
        });
        break;
      case 'domains':
        this.domaninsCampaignStats.getCampaignDomainsStats(campaignId);
        break;
      case 'ads':
        this.adsCampaignStats.getCampaignAdsStats(campaignId);
        break;
      case 'conversions':
        this.convCampaignStats.getCampaignConversionsStats(campaignId);
        break;
      case 'exchanges':
        this.exCampaignStats.getCampaignExStats(campaignId);
        break;
      case 'daily':
        this.dailyCampaignStats.getCampaignDayStats(campaignId);
        break;
      case 'hourly':
        this.hourlyCampaignStats.getCampaignDayStats(campaignId);
        break;
    }
  }

  openMultEditBrandProtect() {
    this.brandProtectionBulkModal.getBrandProtectionData();
    $('.brand-protection-modal').modal('show');
  }

  openMultEditCampNames() {
    this.campaignNameBulkModal.rowDataNames = Observable.create((obs) => {
      obs.next(this.campaignGrid.getSelectedRows());
      obs.complete();
    });
    $('.campaigns-name-bulk-modal').modal('show');
  }

  openMultEditCampContextual() {
    this.contextualBulkRulesModal.getContextualData();
    $('.contextual-bulk-modal').modal('show');
  }

  openBulkConversionModal() {
    this.conversionsBulkModal.getConversions();
    $('.conversions-bulk-modal').modal('show');
  }

  openBulkDaypartingModal() {
    $('.day-parting-bulk-modal').modal('show');
  }

  openBulkDomainListModal() {
    this.domainListBulkModal.getDomainList();
    $('.domain-list-bulk-modal').modal('show');
  }

  openBulkGeneralSettingsModal() {
    $('.bulk-campaign-general-modal').modal('show');
  }

  openBulkLocationsModal() {
    $('.locations-bulk-modal').modal('show');
  }

  tabChanged(event: number) {
    if (this.campaignSelected !== null) {
      this.tabSelected = event;
      this.refreshGrid(this.tabs[event], this.campaignSelected.campaignId);
    }
  }

  openCampaignModal() {

  }

  openMultCampEditModal() {

  }

  stringSearch(event: KeyboardEvent, group: string) {

  }

  rangeChanged(event: SatDatepickerInputEvent<any>, input: HTMLInputElement = null, fromChild = false) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      this.campDateRange.begin = this.campDateRange.begin.subtract(1, 'days');
      this.campDateRange.end = this.campDateRange.end.subtract(1, 'days');
      input.value = this.campDateRange.begin.format('MM/DD/YYYY') + ' - ' + this.campDateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      this.currentDateScope = 'one';
      if (event['value']['extraData'] === 'fromButtonAll') {
        this.currentDateScope = 'all';
        this.campDateRange = event['value'];
        this.domaninsCampaignStats.domainsDateRange = event['value'];
        this.adsCampaignStats.adsCampaingDateRange = event['value'];
        this.convCampaignStats.convCampaingDateRange = event['value'];
        this.exCampaignStats.exCampaingDateRange = event['value'];
        this.dailyCampaignStats.dayCampaingDateRange = event['value'];
        this.hourlyCampaignStats.dayCampaingDateRange = event['value'];
        savedGridDates = {
          brandDateRange: event['value'],
          groupDateRange: event['value'],
          audiencesDateRange: event['value'],
          conversionsDateRange: event['value'],
          universalDateRange: event['value'],
          campDateRange: event['value'],
          domainsDateRange: event['value'],
          adsCampaingDateRange: event['value'],
          convCampaingDateRange: event['value'],
          exCampaingDateRange: event['value'],
          dayCampaingDateRange: event['value']
        };
      } else if (savedGridDates !== null && typeof savedGridDates === 'object') {
        savedGridDates = {
          ...savedGridDates,
          campDateRange: this.campDateRange
        };
      } else {
        savedGridDates = {
          campDateRange: this.campDateRange
        };
      }
      // savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
      //   ? {
      //     ...savedGridDates,
      //     campDateRange: this.campDateRange
      //   }
      //   : {
      //     campDateRange: this.campDateRange
      //   };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (fromChild) {
        this.refreshGrid();
      }
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
  }

  closeAudiencesRulesModal() {
    $('.audiences-rules-modal').modal('hide');
  }

  updateAudiencesRules(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({audienceForm: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.audRulesModal.ngLoading = false;
        $('.audiences-rules-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.audRulesModal.ngLoading = false; }
    );
  }

  onCampaignGridReady(event: any) {
    this.campaignGrid = event.api;
    this.campaignGrid.setColumnDefs(this.getCampaignsColumnsDef());
    this.updateColumns();
  }

  getCampaignsColumnsDef() {
    return [
      {
        headerName: '',
        colId: 'checkboxSelect',
        width: 40,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        resizable: false,
        filter: false,
        cellRenderer: '',
        cellRendererParams: null,
        pinnedRowCellRenderer: '',
        pinnedRowCellRendererParams: null
      },
      {
        headerName: 'Actions',
        field: 'campaignId',
        colId: 'actions',
        sortable: true,
        filter: false,
        width: 90,
        cellRenderer: 'actionsCellRendererComponent',
        cellRendererParams: { renderType: 'Campaign' },
        pinnedRowCellRenderer: '',
        pinnedRowCellRendererParams: null
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 90, filter: true,
        cellRenderer: 'statusCellRendererComponent',
        pinnedRowCellRenderer: '',
        sortable: false,
        pinnedRowCellRendererParams: null,
        hide: this.hideStatus
      },
      {headerName: 'Name', field: 'name', width: 250, pinnedRowCellRenderer: '', cellRenderer: '', sort: 'asc',
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA === valueB) { return 0; }
          return (valueA > valueB) ? 1 : -1;
        } },
      {headerName: 'Type', field: 'campaignType', width: 100, pinnedRowCellRenderer: '', cellRenderer: '' },
      {headerName: 'CPM Bid', field: 'defaultBid', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' }, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA - valueB) },
      {headerName: 'Daily Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'dailyBudget'}, pinnedRowCellRenderer: null,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            // tslint:disable-next-line:max-line-length
            ((valueA.type === 'daily') ? valueA.amount : 0) - ((valueB.type === 'daily') ? valueB.amount : 0)
          )
      } },
      {headerName: 'All Time Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'totalBudget'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            // tslint:disable-next-line:max-line-length
            ((valueA.type === 'all_time') ? valueA.amount : 0) - ((valueB.type === 'all_time') ? valueB.amount : 0)
          )
        } },
      {headerName: 'Start Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'startDate'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            typeof valueA.schedule !== 'undefined' ?
              Number(moment(valueA.schedule.flightDates.from).format('x')) : 0 ) - (
            typeof valueB.schedule !== 'undefined' ?
              Number(moment(valueB.schedule.flightDates.from).format('x')) : 0 )
        } },
      {headerName: 'End Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'endDate'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            typeof valueA.schedule !== 'undefined' ?
              Number(moment(valueA.schedule.flightDates.to).format('x')) : 0 ) - (
            typeof valueB.schedule !== 'undefined' ?
              Number(moment(valueB.schedule.flightDates.to).format('x')) : 0 )
        } },
      {headerName: 'Total Spend', field: 'totalSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'KPI Metric', field: '', width: 100 },
      {headerName: 'KPI Value', field: '', width: 100 },
      {headerName: 'Adv. Spend', field: 'advertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Imps.', field: 'impressionsWon', width: 100 },
      {headerName: 'Clicks', field: 'clicks', width: 100 },
      {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Total Conversions', field: 'totalConversions', width: 100 },
      {headerName: 'Primary Conv.', field: 'conversions', width: 100, cellRenderer: (params) => params.node.data.clickThroughConversions +  params.node.data.viewThroughConversions },
      {headerName: 'Total Eligible Imps.', field: 'eligibleImpressions', width: 100 },
      {headerName: 'Total eCPM', field: 'totalEffectiveCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
      {headerName: 'Total Viewable Imps.', field: 'viewableImpressions', width: 100 },
      {headerName: 'Measured Rate', field: '', width: 100 },
      {headerName: 'Viewable Rate', field: '', width: 100 },
      {headerName: 'Media Spend', field: 'auctionsSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Data Spend', field: 'dataSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Win Rate', field: 'winRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Adv. Spend CPM', field: 'effectiveCPMOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Adv. Spend eCPC', field: 'effectiveCPCOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      // {headerName: 'Total CPC', field: 'costPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Media CPM', field: 'mediaEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Data CPM', field: 'dataEffectiveCPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTR', field: 'clickthruRate', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'VTC', field: 'viewthruConversions', width: 100 },
      {headerName: 'CTC', field: 'clickThroughConversions', width: 100 },
      {headerName: 'Primary CTC', field: '', width: 100 },
      {headerName: 'Primary VTC', field: '', width: 100 },
      {headerName: 'Total Revenue', field: 'revenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTC Revenue', field: 'ctcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'VTC Revenue', field: 'vtcRevenue', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Adv. Spend CPA', field: 'effectiveCPAOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Prim. Adv. Spend CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      // {headerName: 'CPA', field: 'costPerAcquisition', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total eCPA', field: 'grossTotalEffectiveCPA', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total eCPC', field: 'grossTotalEffectiveCPC', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Prim. COnv. CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Primary CTC CPA', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Click CVR', field: 'clickCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'View CVR', field: 'viewCVR', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Total CVRM', field: 'totalCVRM', width: 100, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'EPC', field: 'profitPerClick', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total RPM', field: 'revenuePerMille', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Click RPM', field: 'clickRPM', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'ROAS', field: 'returnOnAdSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Audio/Video Starts', field: 'videoStarted', width: 100 },
      {headerName: '25% Complete', field: 'videoFirstQuartileReached', width: 100 },
      {headerName: '50% Complete', field: 'videoMidpointReached', width: 100 },
      {headerName: '75% Complete', field: 'videoThirdQuartileReached', width: 100 },
      {headerName: '100% Complete', field: 'videoCompleted', width: 100 },
      {headerName: 'Adv. Spend CPCV', field: 'effectiveCPCVOnAdvertiserSpend', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total CPCV', field: 'totalEffectiveCPCV', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Eligible Spend', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Eligible CPM', field: '', width: 100, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Companion Imps.', field: 'companionImpressions', width: 100 },
      {headerName: 'Companion Clicks', field: 'companionClicks', width: 100 },
      {headerName: 'Companion CTC', field: 'companionConversions', width: 100 },
      {headerName: 'Companion CTCR', field: 'companionOfferRevenue', width: 100 }
    ];
  }

  openMultEditAudiences() {
    this.audRulesModal.getAudiences();
    $('.audiences-rules-modal').modal('show');
  }

  saveBrandProtectionRules(event: any) {
    console.log(event);
  }

  saveContextualRules(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({contextualTargeting: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.contextualBulkRulesModal.ngLoading = false;
        $('.contextual-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.contextualBulkRulesModal.ngLoading = false; }
    );
  }

  saveConversionsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({conversionConfig: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.conversionsBulkModal.ngLoading = false;
        $('.conversions-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.conversionsBulkModal.ngLoading = false; }
    );
  }

  saveDayPartingBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({dayParting: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.dayPartingBulkModal.ngLoading = false;
        $('.day-parting-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.dayPartingBulkModal.ngLoading = false; }
    );
  }

  saveDomainListBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({domainList: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.domainListBulkModal.ngLoading = false;
        $('.domain-list-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.domainListBulkModal.ngLoading = false; }
    );
  }

  saveGeneralSettingsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({generalSettings: event, campaignIds: campIds}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.getCampaigns(this.groupId);
        $('.bulk-campaign-general-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); }
    );
  }

  saveLocationsBulk(event: any) {
    const campIds = this.campaignGrid.getSelectedRows().map((item) => item.campaignId);
    this.campService.bulkCampaigns({locations: event, campaignIds: campIds, type: this.locationsBulkType}).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaigns updated successfully.');
        this.locationsBulkModal.ngLoading = false;
        $('.locations-bulk-modal').modal('hide');
      }, (error) => { console.log(error, 'bulkCampaigns'); this.locationsBulkModal.ngLoading = false; }
    );
  }

  exportCampaigns() {
    this.campaignGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params, 'campaigns');
      }
    } as CsvExportParams);
  }

  selectFirstCampaign() {
    if (typeof this.campaignGrid !== 'undefined') {
      this.campaignGrid.forEachNode(function(node) {
        node.setSelected(node.rowIndex === 0);
      });
    }
  }

  updateCampaignStatus(data: any) {
    this.ngLoading = true;
    this.campService.updatePartiallyCampaign({status: data.status} as unknown as Campaign, data.campaignId).subscribe(
      (response) => {
        this.alert.alert('success', 'Campaign updated successfully.');
        this.ngLoading = false;
      },
      (error) => {
        this.refreshGrid();
        console.log(error, 'createCampaign');
        this.ngLoading = false;
      }
    )
  }

  closedDatesRange() {
    if (this.rangeDatesFlag) {
      this.refreshGrid();
    } else {
      this.initializeRangeDates();
    }
    this.rangeDatesFlag = false;
  }

  initializeRangeDates() {
    const savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
    if (savedGridDates !== null && typeof savedGridDates === 'object') {
      if (typeof savedGridDates.campDateRange !== 'undefined') {
        this.campDateRange = {begin: moment(savedGridDates.campDateRange.begin), end: moment(savedGridDates.campDateRange.end)};
      }
    }
  }

  filterChanged(event: any) {
    this.filtersActive = Object.keys(event.api.getFilterModel()).length > 0;
  }

  updateColumns(): void {
    let visibleColumns = [];
    if (this.columnSetup.length > 0) {
      visibleColumns = this.columnSetup;
    }
    console.log('visibleColumns: ', visibleColumns);
    this.campaignGrid.columnController.columnApi.setColumnsVisible(this.columnSetupDefault, false);
    this.campaignGrid.columnController.columnApi.setColumnsVisible(visibleColumns, true);
    this.campaignGrid.columnController.columnApi.autoSizeAllColumns();
  }

  customizeColumns() {
    const dialogRef = this.dialog.open(GridColumnsCustomizationComponent, {
      maxWidth: '1050px',
      width: '600px',
      disableClose: true,
      data: {currentColumns: this.columnSetup, gridColumns: this.gridColumns},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (typeof result !== 'undefined' && result !== null) {
        // this._reportsService.saveReportColumnsCustomization({name: result.name, columns: result.columns, ad_account_id: this.filterForm.get('adAccountId').value}).subscribe(
        //   response => {
        //     console.log(response);
        //   }, error => console.log(error)
        // );
        localStorage.setItem('campaignsCustomColumns', JSON.stringify(result.columns));
        this.columnSetup = result.columns;
        this.updateColumns();
      }
    });
  }
}
