import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import * as moment from 'moment';
import {ConversionService} from '../../_services/conversion/conversion.service';

@Component({
  selector: 'app-conversion-bulk-export',
  templateUrl: './conversion-bulk-export.component.html',
  styleUrls: ['./conversion-bulk-export.component.scss']
})
export class ConversionBulkExportComponent implements OnInit {
  tagType = 'script';
  convIds = [];
  convNames = [];
  loading = false;
  linkUrl = '';
  linkText = '';
  exportForm: FormGroup;

  constructor(private convService: ConversionService, private fb: FormBuilder) { }

  ngOnInit() {
    this.tagType = 'script';
  }

  getTagsCode() {
    // this.loading = true;
    this.convService.getAllTagsCode(this.convIds, this.tagType, this.convNames, this.linkUrl, this.linkText).subscribe(
      (response) => {
        console.log(response);
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response], {type: 'text/plain'}));
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = moment().format('YYYY-MM-DD-hh:mm') + '-ConversionTags.txt';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          this.loading = false;
        }
      },
      (error) => { console.log(error, 'getTagsCode'); this.loading = false; }
    )
  }

}
