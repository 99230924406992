import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRestService } from '../user-rest.service';
import {Role} from '../../../_models/enums';
import {User} from '../../../_models/user';
import {Observable} from 'rxjs';
import {GridOptions} from 'ag-grid-community';
import {ActionsCellRendererComponent} from '../../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {NewUserComponent} from '../../../modals/new-user/new-user.component';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-user-index',
  templateUrl: './user-index.component.html',
  styleUrls: ['./user-index.component.scss']
})
export class UserIndexComponent implements OnInit {
  columnDefsUsers = [
    {headerName: '', field: 'id', sortable: true, filter: true, width: 20},
    {headerName: 'First name', field: 'firstname', sortable: true, filter: true, editable: true},
    {headerName: 'Last Name', field: 'lastname', sortable: true, filter: true, editable: true},
    {headerName: 'Email', field: 'email', sortable: true, filter: true, editable: true},
    {headerName: 'Role', field: 'role', sortable: true, filter: true, editable: true},
    {headerName: 'Created', field: 'created_at', sortable: true, filter: true},
    {headerName: 'Actions', field: 'id', colId: 'actions', sortable: true, filter: true, width: 90, cellRenderer: 'actionsCellRendererComponent', cellRendererParams: { renderType: 'User' } }
  ];
  rowDataUsers: any;
  userGridOptions: GridOptions = {
    onGridSizeChanged: (event) => { this.userGridOptions.api.sizeColumnsToFit() }
  };
  userToEdit: User;
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
  };
  @ViewChild(NewUserComponent, null) newUserModal: NewUserComponent;

  constructor(private route: ActivatedRoute, private userRest: UserRestService) { }

  ngOnInit() {
     this.userRest.getUsers().subscribe(
     (response) => {
         this.rowDataUsers =  Observable.create((obs) => {
           obs.next(response.response.users);
           obs.complete();
         });
         console.log(this.rowDataUsers);
       },
     (error) => { console.log(error) }
    );
  }

  openNewUserModal() {
    this.newUserModal.newUserForm.reset();
    this.newUserModal.createOrEdit = 'Create';
    this.newUserModal.newUserForm.controls.password.setValidators([Validators.required]);
    this.newUserModal.newUserForm.controls.confirmPassword.setValidators([Validators.required]);
    this.newUserModal.newUserForm.controls.password.updateValueAndValidity();
    this.newUserModal.newUserForm.controls.confirmPassword.updateValueAndValidity();
    $('.new-user-modal').modal('show');
  }

  editNewUserModal(node) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.newUserModal.loadUser(node.data as User);
      $('.new-user-modal').modal('show');
    }
  }
}
