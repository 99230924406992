import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CampaignService} from '../../_services/campaign/campaign.service';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../common-auth.service';
import {UserService} from '../../_services/user/user.service';
import {User} from '../../_models/user';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  ngLoading = false;
  token: string = null;
  resetForm: FormGroup;
  resetFormNew: FormGroup;
  submitted =  false;
  currentUser: User = null;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alert: AlertService,
    private authService: CommonAuthService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (typeof params['token'] !== 'undefined') {
        this.token = params['token'];
        this.resetForm = this.fb.group({
          password: ['', [Validators.required, Validators.minLength(8)]],
          confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
        });
        this.resetForm.controls.confirmPassword.setValidators(this.confirmPasswordValidator(this.resetForm));
      } else {
        this.resetForm = this.fb.group({
          email: ['', [Validators.required, Validators.email]]
        });
      }
    });
    // this.resetForm = this.fb.group({
    //   email: ['', [Validators.required, Validators.email]]
    // });
    // this.resetFormNew = this.fb.group({
    //   password: ['', [Validators.required]],
    //   confirmPassword: ['', [Validators.required]]
    // });
    this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
      console.log(this.currentUser, this.token)
    });
  }

  get f() { return this.resetForm.controls; }
  // get fNew() { return this.resetFormNew.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    console.log('login comp in');
    this.ngLoading = true;
    this.authService.resetPassword(this.f.email.value, `${environment.appUrl}home/new-password`)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.alert.alert('success', data.response);
          this.ngLoading = false;
        },
        error => {
          console.log('login comp data out');
          this.alert.error(error);
          this.ngLoading = false;
        }
      );
  }

  onSubmitNew() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    console.log('login comp in');
    this.ngLoading = true;
    if (this.currentUser !== null) {
      this.userService.updateUser({
        email: this.currentUser.email,
        password: this.f.password.value,
        confirmPassword: this.f.confirmPassword.value,
        firstname: this.currentUser.firstname,
        lastname: this.currentUser.lastname,
        role: this.currentUser.role
      } as unknown as User, this.currentUser.id).subscribe(
        data => {
          console.log(data);
          this.alert.alert('success', 'Password updated successfully.', 1000);
          this.ngLoading = false;
          this.authService.logout().subscribe((response) => { this.router.navigate(['/home/login']); });
        },
        error => {
          console.log('login comp data out');
          this.alert.error(error);
          this.ngLoading = false;
        }
      );
    } else {
      this.authService.newPassword(this.f.password.value, this.f.confirmPassword.value, this.token)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            this.alert.alert('success', data.response, 1000);
            this.ngLoading = false;
            this.router.navigate(['/home/login']);
          },
          error => {
            console.log('login comp data out');
            this.alert.error(error);
            this.ngLoading = false;
          }
        );
    }
  }

  confirmPasswordValidator(userForm: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && control.value !== userForm.get('password').value) {
        return { 'confirmPassword': true };
      }
      return null;
    };
  }

}
