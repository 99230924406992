import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LinkAdCampaignComponent} from '../cell-renderers/link-ad-campaign/link-ad-campaign.component';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {AdReviewComponent} from '../ad-review/ad-review.component';
import {StatusCellRendererComponent} from '../cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {CheckBoxCellComponent} from '../check-box-cell/check-box-cell.component';
import {FormArray, FormBuilder} from '@angular/forms';
// @ts-ignore
import moment from 'moment';

@Component({
  selector: 'app-day-parting-bulk',
  templateUrl: './day-parting-bulk.component.html',
  styleUrls: ['./day-parting-bulk.component.scss']
})
export class DayPartingBulkComponent implements OnInit {

  @Input() parentType = 'modal';
  @Output() setDataRules =  new EventEmitter<any>();
  public hoursData = [];
  public times = [
    '12:00am',
    '12:30am',
    '1:00am',
    '1:30am',
    '2:00am',
    '2:30am',
    '3:00am',
    '3:30am',
    '4:00am',
    '4:30am',
    '5:00am',
    '5:30am',
    '6:00am',
    '6:30am',
    '7:00am',
    '7:30am',
    '8:00am',
    '8:30am',
    '9:00am',
    '9:30am',
    '10:00am',
    '10:30am',
    '11:00am',
    '11:30am',
    '12:00pm',
    '12:30pm',
    '1:00pm',
    '1:30pm',
    '2:00pm',
    '2:30pm',
    '3:00pm',
    '3:30pm',
    '4:00pm',
    '4:30pm',
    '5:00pm',
    '5:30pm',
    '6:00pm',
    '6:30pm',
    '7:00pm',
    '7:30pm',
    '8:00pm',
    '8:30pm',
    '9:00pm',
    '9:30pm',
    '10:00pm',
    '10:30pm',
    '11:00pm',
    '11:30pm',
    '11:59pm',
  ];
  public hoursColumns = [
    {
      headerName: 'From', field: 'from', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.times
      },
      width: 75
    },
    {
      headerName: 'To', field: 'to', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: this.times
      },
      width: 75
    },
    { headerName: 'Mon', field: 'monday', cellRenderer: 'checkBoxCellComponent', width: 50 },
    { headerName: 'Tue', field: 'tuesday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Wed', field: 'wednesday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Thu', field: 'thursday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Fri', field: 'friday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Sat', field: 'saturday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    { headerName: 'Sun', field: 'sunday', width: 50, cellRenderer: 'checkBoxCellComponent', },
    // tslint:disable-next-line:max-line-length
    { headerName: '', field: 'actions', width: 50, cellRenderer: (params) => '<i class="ti-trash" style="font-size: 14px; cursor: pointer"></i>'},
  ];
  frameworks = {
    linkAdCampaignComponent: LinkAdCampaignComponent,
    actionsCellRendererComponent: ActionsCellRendererComponent,
    adReviewComponent: AdReviewComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    checkBoxCellComponent: CheckBoxCellComponent
  };
  hoursRulesGrid: any;
  newCampaignForm: any;
  ngLoading = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.newCampaignForm = this.fb.group({
      budget: this.fb.group({
        schedule: this.fb.group({
          daypartingRules: this.fb.array([]),
          isRelativeDayParting: [false]
        })
      })
    })
  }

  addHourDaysRule() {
    // tslint:disable-next-line:max-line-length
    this.hoursData.push({ from: '12:00am', to: '11:59pm', monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true });
    // tslint:disable-next-line:max-line-length
    const res = this.hoursRulesGrid.updateRowData({add: [{ from: '12:00am', to: '11:59pm', monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true }]});
  }

  public hoursRulesGridInit(params) {
    this.hoursRulesGrid = params.api;
  }

  deleteHourRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.hoursRulesGrid.updateRowData({ remove: [event.data] });
    }
  }

  saveRules() {
    if (typeof this.hoursRulesGrid !== 'undefined') {
      this.hoursRulesGrid.forEachNode((node, index) => {
        const weekDays = this.fb.array([]);
        if (node.data.monday) {
          weekDays.push(this.fb.control('monday'));
        }
        if (node.data.tuesday) {
          weekDays.push(this.fb.control('tuesday'));
        }
        if (node.data.wednesday) {
          weekDays.push(this.fb.control('wednesday'));
        }
        if (node.data.thursday) {
          weekDays.push(this.fb.control('thursday'));
        }
        if (node.data.friday) {
          weekDays.push(this.fb.control('friday'));
        }
        if (node.data.saturday) {
          weekDays.push(this.fb.control('saturday'));
        }
        if (node.data.sunday) {
          weekDays.push(this.fb.control('sunday'));
        }
        // @ts-ignore
        (this.newCampaignForm.controls.budget.controls.schedule.controls.daypartingRules as FormArray).push(this.fb.group({
          fromTime: [moment(node.data.from, 'HH:mma').format('HH:mm')],
          toTime: [moment(node.data.to, 'HH:mma').format('HH:mm')],
          daysOfWeek: weekDays
        }));
      });
    }
    console.log(this.newCampaignForm.value);
    this.setDataRules.emit(this.newCampaignForm.value);
  }

  cancelModal() {
    $('.day-parting-bulk-modal').modal('hide');
  }
}
