import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import {BrandsComponent} from './pages/brands/brands.component';
import {PartnersComponent} from './pages/partners/partners.component';
import {PerformanceComponent} from './pages/performance/performance.component';
import {InventoryComponent} from './pages/inventory/inventory.component';
import {MarketplaceComponent} from './pages/marketplace/marketplace.component';
import {DomainsComponent} from './pages/domains/domains.component';
import {ScheduleReportsComponent} from './pages/schedule-reports/schedule-reports.component';
import {OneTimeReportsComponent} from './pages/one-time-reports/one-time-reports.component';
import {CanDeactivateGuard, NewCampaignComponent} from './pages/new-campaign/new-campaign.component';
import {CampaignsComponent} from './pages/campaigns/campaigns.component';
import {LoginComponent} from './auth/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {UsersComponent} from './pages/users/users.component';
import {SupportComponent} from './pages/support/support/support.component';
import {AuthGuardService} from './auth-guard.service';
import {TermsComponent} from './pages/terms/terms.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {PrivacyPolicyPrintComponent} from './pages/privacy-policy-print/privacy-policy-print.component';
import {TermsPrintComponent} from './pages/terms-print/terms-print.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'mybrands',
    pathMatch: 'full',
  },
  {
    path: 'mybrands',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: BrandsComponent
      }]
  },
  {
    path: 'campaigns/:brandId/:groupId',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: CampaignsComponent
      }]
  },
  {
    path: 'myagencies',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: PartnersComponent
      }]
  },
  {
    path: 'myusers',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: UsersComponent
      }]
  },
  {
    path: 'performance',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: PerformanceComponent
      }]
  },
  {
    path: 'campaigns/:brandId/:groupId/new-campaign/:campaignType',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: NewCampaignComponent
      }]
  },
  {
    path: 'campaigns/:brandId/:groupId/edit-campaign/:campaignId/:campaignType',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: NewCampaignComponent,
        canDeactivate: [CanDeactivateGuard]
      }]
  },
  {
    path: 'inventory',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: InventoryComponent
      }]
  },
  {
    path: 'marketplace',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: MarketplaceComponent
      }]
  },
  {
    path: 'domains',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: DomainsComponent
      }]
  },
  {
    path: 'scheduled-reports',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: ScheduleReportsComponent
      }]
  },
  {
    path: 'one-time-reports',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: OneTimeReportsComponent
      }]
  },
  {
    path: 'support',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    data: {roles: ['ADMIN']},
    children: [
      {
        path: '',
        component: SupportComponent,
      }]
  },
  // {
  //   path: '',
  //   component: AdminLayoutComponent,
  //   children: [
  //       {
  //     path: '',
  //     loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  // }]},
  // {
  //   path: '',
  //   component: AdminLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: BrandsComponent
  //     }]
  // },
  {
    path: 'home',
    component: WelcomeLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/welcome-layout/welcome-layout.module#WelcomeLayoutModule'
  }]},
  {
    path: 'terms',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: TermsComponent,
      }]
  },
  {
    path: 'privacy-policy',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        component: PrivacyPolicyComponent,
      }]
  },
  {
    path: 'privacy-policy-print',
    component: PrivacyPolicyPrintComponent
  },
  {
    path: 'terms-print',
    component: TermsPrintComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  // {
  //   path: 'dashboard',
  //   component: AdminLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: DashboardComponent
  //     }]
  // },
]
