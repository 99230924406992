import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormBuilder, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {environment} from '../../../../environments/environment';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';

@Component({
  selector: 'app-new-video-modal',
  templateUrl: './new-video-modal.component.html',
  styleUrls: ['./new-video-modal.component.scss']
})
export class NewVideoModalComponent extends NewAdComponent implements OnInit {
  pondOptionsVideo: any;

  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService)
  }

  ngOnInit() {
    super.ngOnInit();
    this.pondOptionsVideo = {
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'video/*',
      imagePreviewMarkupShow: false,
      maxFileSize: '100MB',
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadAudio(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              load(`${environment.publicUrl}` + response);
              this.newForm.controls.assetUrl.setValue(response);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getFile(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.newForm = this.fb.group({
      adType: ['video'],
      label: ['', Validators.required],
      status: ['online'],
      width: [''],
      height: [''],
      type: ['video'],
      assetUrl: ['', [Validators.required]],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      // tslint:disable-next-line:max-line-length
      landingPageDomain: ['', [Validators.pattern(this.domainRegex), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')]),
      companionCreativeId: [''],
      assetUrlCompanion: [''],
      clickUrlCompanion: [''],
      winNotificationUrlCompanion: this.fb.array([this.fb.control('')]),
      scriptTrackingUrlCompanion: this.fb.array([this.fb.control('')]),
      impressionTrackingCompanion: [''],
      customerVastEvents: ['']
    });
    // this._parent.newForm = this.newForm;
  }
}
