import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-new-html5-modal',
  templateUrl: './new-html5-modal.component.html',
  styleUrls: ['./new-html5-modal.component.scss']
})
export class NewHtml5ModalComponent extends NewAdComponent implements OnInit {

  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }

  opened = false;
  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.newForm = this.fb.group({
      adType: ['html5'],
      label: ['', Validators.required],
      status: ['online'],
      width: ['', Validators.required],
      height: ['', Validators.required],
      type: ['html5'],
      assetUrl: ['', [Validators.required]],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      landingPageDomain: ['', [Validators.pattern('^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$'), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')]),
      html5Clickability: this.fb.group({
        type: ['ENTIRE_AD', Validators.required],
        queryParamName: [''],
        jsVariableName: ['']
      })
    });
    this.pondOptionsImage = {
      // class: 'my-filepond',
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadAudio(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              this.img.src = `${environment.publicUrl}` + response;
              if (typeof this.newForm.controls.assetUrlCompanion !== 'undefined' && this.newForm.controls.assetUrlCompanion !== null) {
                this.newForm.controls.assetUrlCompanion.setValue(response);
              } else {
                this.newForm.controls.assetUrl.setValue(response);
              }
              // console.log('IMG ASSET: ', this.newForm.controls.assetUrlCompanion.value, this.newForm.value);
              document.getElementById('adPanel').appendChild(this.img);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getImage(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    // this.brandId = this._parent.brandId;
    // this._parent.newForm = this.newForm;
  }

  changedDimension(event: any) {
    if (typeof event !== 'undefined') {
      const dim = event.split('x');
      this.newForm.controls.width.setValue(dim[0]);
      this.newForm.controls.height.setValue(dim[1]);
    } else {
      this.newForm.controls.width.setValue('');
      this.newForm.controls.height.setValue('');
    }
  }
}
