import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {DomainListService} from '../../_services/domain-list/domain-list.service';

@Component({
  selector: 'app-domain-list-bulk',
  templateUrl: './domain-list-bulk.component.html',
  styleUrls: ['./domain-list-bulk.component.scss']
})
export class DomainListBulkComponent implements OnInit {

  @Input() parentType = 'modal';
  @Output() setDataRules =  new EventEmitter<any>();
  columnDefsDomainList = [
    {headerName: 'ID', field: 'domainListId', width: 100, checkboxSelection: true },
    {
      headerName: 'Actions',
      field: 'id',
      colId: 'actions',
      sortable: true,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'DomainList' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 500 },
    {headerName: 'Type', field: 'type', width: 150, cellRenderer: (params) => params.value.toUpperCase()},
    {headerName: 'URL Count', field: 'domains', width: 150, cellRenderer: (params) => params.value.length},
  ];
  rowDataDomainList: any;
  domainListSelected: any[];
  domainListGrid: any;
  ngLoading = false;
  newInventoryForm: FormGroup;

  constructor(private fb: FormBuilder, private domainListService: DomainListService) { }

  ngOnInit() {
    this.newInventoryForm = this.fb.group({
      domainList: this.fb.group({
        domainListId: ['']
      })
    });
  }

  domainListSelection(event: any) {
    this.domainListSelected = event.api.getSelectedRows();
  }

  onDomainListGridReady(event: any) {
    this.domainListGrid = event.api;
  }

  getDomainList() {
    this.domainListService.getDomainList().subscribe(
      (response) => {
        this.rowDataDomainList =  Observable.create((obs) => {
          obs.next(response.response.domain_lists);
          obs.complete();
        });
      });
  }

  saveRules() {
    (this.newInventoryForm.controls.domainList['controls'].domainListId as FormControl).setValue(this.domainListSelected[0].domainListId);
    this.setDataRules.emit(this.newInventoryForm.value);
  }

  cancelModal() {
    $('.domain-list-bulk-modal').modal('hide');
  }
}
