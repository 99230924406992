import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import {Role} from '../../_models/enums';
import {UserResponse, UsersResponse} from '../../_models/responses';
import {User} from '../../_models/user';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';

@Injectable({
  providedIn: 'root'
})
export class UserRestService {
  users: Array<{id: number, firstname: string, lastname: string, email: string, role: Role}> = [];
  private usersUrl = 'users';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getUsers(): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(`${environment.apiUrl}` + this.usersUrl ).pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UsersResponse>('getUserById'))
    );
  }

  getById(id: any) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getUserById'))
    );
  }

  getByToken(token: string) {
    return this.http.get<UserResponse>(`${environment.apiUrl}` + 'user/clients').pipe(
      tap(_ => console.log('fetched user')),
      catchError(Errors.handleError<UserResponse>('getByToken'))
    );
  }

  createUser(user: User): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.apiUrl}` + this.usersUrl, user, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('addUser', user))
      ) as Observable<UserResponse>;
  }

  updateUser(user: User, id: bigint) {
    return this.http.put<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id, user, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updateUser', user))
      ) as Observable<UserResponse>;
  }

  deleteUser(id: string) {
    return this.http.delete<UserResponse>(`${environment.apiUrl}` + this.usersUrl + '/' + id).pipe(
      tap(_ => console.log('deleted business')),
      catchError(Errors.handleError<UserResponse>('deleteUser'))
    );
  }

  updatePassword(data: any, id: bigint) {
    return this.http.put(`${environment.apiUrl}` + this.usersUrl + '/' + id + '/change-password', data, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updatePassword', data))
      ) as Observable<any>;
  }

  setTermsAcceptance() {
    return this.http.put(`${environment.apiUrl}` + 'user/accept-terms', {accepted: true}, this.httpOptions)
      .pipe(
        catchError(Errors.handleError('updatePassword', {accepted: true}))
      ) as Observable<any>;
  }
}
