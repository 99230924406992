import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeoHereLibService {

  apiAutoCompleteUrl = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
  apiCoordinatesUrl = 'https://geocoder.ls.hereapi.com/6.2/geocode.json';
  apiKey = 'M5eW0jd9VF-8TY4OJjDgU7nu34L4PftOuXSVTiA4_Y0';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  getAddressAutocompleteSuggest(address: string) {
    return this.http.get(
      this.apiAutoCompleteUrl + '?apiKey=' + this.apiKey + '&query=' + address
    ).pipe(
      tap(_ => console.log('fetched Geo Autocomplete Suggestions')),
      catchError(Errors.handleError<any>('getAddressAutocompleteSuggest'))
    );
  }

  getAddressCoordinates(address: string) {
    return this.http.get(
      this.apiCoordinatesUrl + '?apiKey=' + this.apiKey + '&searchtext=' + address
    ).pipe(
      tap(_ => console.log('fetched Geo Coordinates')),
      catchError(Errors.handleError<any>('getAddressCoordinates'))
    );
  }
}
