import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Errors} from '../../_helpers/errors';
import {
  CategoryResponse,
  CityResponse,
  DomainListResponse,
  ExchangeResponse,
  InventoryResponse,
  MarketPlaceResponse
} from '../../_models/responses';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private inventoryUrl = 'domains';
  private marketPlaceUrl = 'marketplace';
  private entitiesUrl = 'entities';
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private http: HttpClient) { }

  getInventory(params = {filter: '', inventoryType: '', mediaType: '', deviceTypes: '', exchangeIds: '', categories: '', geoCodes: '', adSize: ''}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<InventoryResponse> {
    return this.http.get<InventoryResponse>(
      `${environment.apiUrl}` + this.inventoryUrl + '/all?max=' + max + '&offset=' + offset +
       '&filter=' + params.filter + '&inventoryType=' + params.inventoryType + '&mediaType=' + params.mediaType +
        '&deviceTypes=' + params.deviceTypes + '&exchangeIds=' + params.exchangeIds +
          '&categories=' + params.categories + '&geoCodes=' + params.geoCodes + '&adSize=' + params.adSize + '&page=1&pageSize=100'
    ).pipe(
      tap(_ => console.log('fetched inventory')),
      catchError(Errors.handleError<InventoryResponse>('getInventory'))
    );
  }

  getInventoryExanges(inventoryId, params = {filter: '', inventoryType: '', mediaType: '', deviceTypes: '', exchangeIds: '', categories: '', geoCodes: '', adSize: ''}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<InventoryResponse> {
    return this.http.get<InventoryResponse>(
      `${environment.apiUrl}` + this.inventoryUrl + '/all/' + inventoryId + '?max=' + max + '&offset=' + offset +
      '&filter=' + params.filter + '&inventoryType=' + params.inventoryType + '&mediaType=' + params.mediaType +
      '&deviceTypes=' + params.deviceTypes + '&exchangeIds=' + params.exchangeIds +
      '&categories=' + params.categories + '&geoCodes=' + params.geoCodes + '&adSize=' + params.adSize + '&page=1&pageSize=100'
    ).pipe(
      tap(_ => console.log('fetched inventory')),
      catchError(Errors.handleError<InventoryResponse>('getInventory'))
    );
  }

  getMarketPlace(params = {filter: '', inventoryTypes: '', adFormats: '', deviceTypes: '', exchangeIds: '', categories: '', countries: '', adSizes: '', minPrice: 0, maxPrice: 10000000}, max: number = 1000, offset: number = 0, searchVal = ''): Observable<MarketPlaceResponse> {
    return this.http.get<MarketPlaceResponse>(
      `${environment.apiUrl}` + this.marketPlaceUrl + '/deals?type=platform&max=' + max + '&offset=' + offset +
      '&filter=' + params.filter + '&inventoryType=' + params.inventoryTypes + '&adFormats=' + params.adFormats +
      '&deviceTypes=' + params.deviceTypes + '&exchangeIds=' + params.exchangeIds +
      '&categoryIds=' + params.categories + '&countries=' + params.countries + '&adSizes=' + params.adSizes + '&minPrice=' +
      params.minPrice + '&maxPrice=' + params.maxPrice + '&page=1&pageSize=100'
    ).pipe(
      tap(_ => console.log('fetched marketPlace')),
      catchError(Errors.handleError<MarketPlaceResponse>('getMarketPlace'))
    );
  }

  getCategories(): Observable<CategoryResponse> {
    return this.http.get<CategoryResponse>(
      `${environment.apiUrl}` + this.entitiesUrl + '/categories'
    ).pipe(
      tap(_ => console.log('fetched categories')),
      catchError(Errors.handleError<CategoryResponse>('getCategories'))
    );
  }

  getExchanges(): Observable<ExchangeResponse> {
    return this.http.get<ExchangeResponse>(
      `${environment.apiUrl}` + this.entitiesUrl + '/exchanges'
    ).pipe(
      tap(_ => console.log('fetched exchanges')),
      catchError(Errors.handleError<ExchangeResponse>('getExchanges'))
    );
  }

  getCities(params = {filter: ''}): Observable<CityResponse> {
    return this.http.get<CityResponse>(
      `${environment.apiUrl}` + this.entitiesUrl + '/cities?filter=' + params.filter
    ).pipe(
      tap(_ => console.log('fetched cities')),
      catchError(Errors.handleError<CityResponse>('getCities'))
    );
  }

  getCountries(params = {filter: ''}): Observable<CityResponse> {
    return this.http.get<CityResponse>(
      `${environment.apiUrl}` + this.entitiesUrl + '/countries?filter=' + params.filter
    ).pipe(
      tap(_ => console.log('fetched countries')),
      catchError(Errors.handleError<CityResponse>('getCountries'))
    );
  }

}
