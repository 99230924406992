import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-audience-target-group-rule',
  templateUrl: './audience-target-group-rule.component.html',
  styleUrls: ['./audience-target-group-rule.component.scss']
})
export class AudienceTargetGroupRuleComponent implements OnInit {


  @Input() rule: FormGroup;
  @Input() extraData: any;
  @Input() parentPosition: any;
  ruleRange = 'all';
  @Output() removeRule = new EventEmitter<number>();
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // console.log(this.target);
  }

  removeARule(position) {
    (this.rule.controls.rules as FormArray).removeAt(position);
    if ((this.rule.controls.rules as FormArray).length === 0) {
      this.removeRule.emit(this.parentPosition)
    }
    // if (this.rule.controls.type.value === 'atomic') {
    //   this.removeRule.emit(this.parentPosition)
    // } else {
    //   (this.rule.controls.rules as FormArray).removeAt(position);
    //   if ((this.rule.controls.rules as FormArray).length === 1) {
    //     const oldRule = (this.rule.controls.rules as FormArray).at(0);
    //     (this.rule as FormGroup).removeControl('rules');
    //     (this.rule as FormGroup).removeControl('logicalOperator');
    //     this.rule.controls.type.setValue('atomic');
    //     (this.rule as FormGroup).addControl('fromMillisSinceLastAccess', new FormControl(oldRule['controls'].fromMillisSinceLastAccess.value));
    //     (this.rule as FormGroup).addControl('toMillisSinceLastAccess', new FormControl(oldRule['controls'].toMillisSinceLastAccess.value));
    //     (this.rule as FormGroup).addControl('blocked', new FormControl(oldRule['controls'].blocked.value));
    //     (this.rule as FormGroup).addControl('audienceId', new FormControl(oldRule['controls'].audienceId.value));
    //     (this.rule as FormGroup).addControl('audienceType', new FormControl(oldRule['controls'].audienceType.value));
    //   }
    // }
  }

}
