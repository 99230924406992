import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-rule-nested-draw',
  templateUrl: './rule-nested-draw.component.html',
  styleUrls: ['./rule-nested-draw.component.scss']
})
export class RuleNestedDrawComponent implements OnInit {

  @Input() ruleData: FormGroup;
  rules: FormArray = new FormArray([]);
  constructor() { }

  ngOnInit() {
    console.log('app-rule-nested-draw', this.ruleData);
  }

  removeRule(data: FormGroup, pos: number) {
    if (data.controls.rules['controls'][pos].controls.type.value === 'atomic') {
      if (pos === 1 || data.controls.rules['controls'].length === 1 || (pos === 0 && data.controls.rules['controls'][1].controls.type.value === 'atomic')) {
        (data.controls.rules as FormArray).removeAt(pos);
      } else {

      }
    } else {

    }
    console.log('RAW VaLUE: ', data.getRawValue(), data.parent, data.parent.parent, pos);
  }
}
