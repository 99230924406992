import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview';
import {LinkAdCampaignComponent} from '../cell-renderers/link-ad-campaign/link-ad-campaign.component';
import {ActionsCellRendererComponent} from '../actions-cell-renderer/actions-cell-renderer.component';
import {AdReviewComponent} from '../ad-review/ad-review.component';
import {StatusCellRendererComponent} from '../cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {CheckBoxCellComponent} from '../check-box-cell/check-box-cell.component';
import {GeographicService} from '../../_services/geographic/geographic.service';
import {AlertService} from '../../_services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectComponent} from '@ng-select/ng-select';
import {dmaCountryCodes} from '../../_models/aux-data';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'app-locations-bulk',
  templateUrl: './locations-bulk.component.html',
  styleUrls: ['./locations-bulk.component.scss']
})
export class LocationsBulkComponent implements OnInit {

  @Input() parentType = 'modal';
  @Output() setDataRules = new EventEmitter<any>();
  zipCountrySelect = 'US';
  zipCodesInput = '';
  dmaCodes = dmaCountryCodes;
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  public locationsGridData = [];
  public locationsColumns = [
    {
      headerName: '', field: 'type', hide: true
    },
    {
      headerName: '', field: 'codes', hide: true
    },
    {
      headerName: 'Location', field: 'location', width: 500
    },
    {
      headerName: 'Status', field: 'status', width: 100
    },
    { headerName: '', field: 'actions', width: 40, cellRenderer: (params) => '<i class="ti-trash"></i>'},
  ];
  frameworks = {
    linkAdCampaignComponent: LinkAdCampaignComponent,
    actionsCellRendererComponent: ActionsCellRendererComponent,
    adReviewComponent: AdReviewComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    checkBoxCellComponent: CheckBoxCellComponent
  };
  locationsGrid: any;
  itemsLocations: TreeviewItem[] = [];
  valuesLocations: any[] = [];
  ngLoading = false;
  selectedDmaCodes = [];
  // @ts-ignore
  @ViewChild('dmaCodesSelector') dmaCodesSelector: NgSelectComponent;

  constructor(private geoService: GeographicService, private alert: AlertService, private modalService: NgbModal) { }

  ngOnInit() {
  }

  selectLocations(locationsModal) {
    if (this.itemsLocations.length <= 1) {
      this.ngLoading = true;
      this.geoService.getGeographicLocations().subscribe(
        (response) => {
          for (const loc in response[0]) {
            this.itemsLocations.push(new TreeviewItem({
              text: response[0][loc].countryName, value: JSON.stringify(response[0][loc]), checked: false, collapsed: true, children: [
                {text: 'loading', value: JSON.stringify(response[0][loc])}
              ]
            }))
            this.itemsLocations[loc]['level'] = 0;
            this.itemsLocations[loc]['countryCode'] = response[0][loc].countryCode;
            this.itemsLocations[loc].setCheckedRecursive(false);
          }
          console.log(response, this.itemsLocations)
          this.ngLoading = false;
          this.modalService.open(locationsModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
            this.addLocationsToGrid(this.valuesLocations, 'locations');
          }, (reason) => {
            this.addLocationsToGrid(this.valuesLocations, 'locations');
          });
        },
        (error) => {
          console.log('selectLocations', error);
          this.ngLoading = false;
        }
      )
    } else {
      this.modalService.open(locationsModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
        this.addLocationsToGrid(this.valuesLocations, 'locations');
      }, (reason) => {
        this.addLocationsToGrid(this.valuesLocations, 'locations');
      });
    }
  }

  setSelectedDmaCodes(event: any) {
    this.selectedDmaCodes = event;
  }

  addDmaCodes(type) {
    // if (type !== 'OTHER') {
    //   this.locationsGrid.setRowData([]);
    //   this.addCodes('OTHER');
    //   this.addLocationsToGrid(this.valuesLocations, 'OTHER');
    // }
    if (this.selectedDmaCodes.length > 0) {
      const locations = [];
      for (const code of this.selectedDmaCodes) {
        this.dmaCodesSelector.unselect(code);
        this.dmaCodesSelector.clearItem(code);
        // tslint:disable-next-line:max-line-length
        locations.push({ location: 'Country: United States, DMA Code:  ' + code.dmaCode + ' - ' + code.dmaName, type: 'zip', codes: JSON.stringify({countryCode: 'US', blocked: (type === 'EXCLUDES') ? true : false, dmaCode: code.dmaCode}), status: (type === 'EXCLUDES') ? 'Excludes' : 'Targeted' });
      }
      const res = this.locationsGrid.updateRowData({add: locations});
    }
  }

  addCodes(codeType: string) {
    // if (codeType !== 'OTHER') {
    //   this.locationsGrid.setRowData([]);
    //   this.addLocationsToGrid(this.valuesLocations, 'OTHER');
    // }
    if (this.zipCodesInput !== '') {
      const zipCodes = this.zipCodesInput.split(',');
      const locations = [];
      const wrongCodes = [];
      for (const zip of zipCodes) {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(zip.trim())) {
          locations.push({ location: 'Country: ' + this.zipCountrySelect + ',  Zip/Postal Code: ' + zip.trim(), type: 'zip', codes: JSON.stringify({countryCode: this.zipCountrySelect, blocked: (codeType === 'EXCLUDES') ? true : false, postalCode: zip.trim()}), status: (codeType === 'EXCLUDES') ? 'Excludes' : 'Targeted' });
        } else {
          wrongCodes.push(zip.trim());
        }
      }
      const res = this.locationsGrid.updateRowData({add: locations});
      if (wrongCodes.length > 0) {
        this.alert.alert('error', 'The following zip codes are not valid: ' + wrongCodes.join(', '), 10000)
      }
      this.zipCodesInput = '';
    }
  }

  addSet() {

  }

  saveSet() {

  }

  public locationsGridInit(params) {
    this.locationsGrid = params.api;
  }

  deleteLocationRow(event: any) {
    if (event.colDef.field === 'actions') {
      const res = this.locationsGrid.updateRowData({ remove: [event.data] });
    }
  }

  locationsSelected(event: any[]) {
    this.valuesLocations = event;
  }

  locationsTreeData(event: TreeviewItem) {
    if (typeof event.children !== 'undefined' && event.children.length > 0) {
      if (event['level'] === 0) {
        this.getLocationRegions(event['countryCode'], event);
        console.log(event);
      } else if (event['level'] === 1) {
        this.getRegionCities(event['countryCode'], event['regionCode'], event);
        console.log(event);
      }
    }
  }

  getRegionCities(countryCode: string, regionCode: string, treeView: TreeviewItem) {
    this.geoService.getRegionCities(countryCode, regionCode).subscribe(
      (response) => {
        for (const region in response[0]) {
          treeView.children.push(new TreeviewItem ({
            text: response[0][region].city, value: JSON.stringify(response[0][region]), checked: false
          }, true))
          treeView.children[region]['level'] = 2;
        }
        treeView.children.shift();
        if (!treeView.checked) {
          treeView.setCheckedRecursive(false);
        } else {
          treeView.setCheckedRecursive(true);
        }
        // treeView.correctChecked();
        console.log(response, this.itemsLocations)
      },
      (error) => { console.log('selectLocations', error); this.ngLoading = false; }
    )
  }

  getLocationRegions(countryCode: string, treeView: TreeviewItem) {
    this.geoService.getCountryRegions(countryCode).subscribe(
      (response) => {
        treeView.children.shift();
        for (const region in response[0]) {
          treeView.children.push(new TreeviewItem ({
            text: response[0][region].regionName, value: JSON.stringify(response[0][region]), checked: false, collapsed: true, children: [
              { text: 'loading', value: JSON.stringify(response[0][region]) }
            ]
          }, true))
          treeView.children[region]['level'] = 1;
          treeView.children[region]['countryCode'] = response[0][region].countryCode;
          treeView.children[region]['regionCode'] = response[0][region].regionCode;
        }
        if (!treeView.checked) {
          treeView.setCheckedRecursive(false);
        } else {
          treeView.setCheckedRecursive(true);
        }
        // treeView.correctChecked();
        console.log(response, this.itemsLocations)
      },
      (error) => { console.log('selectLocations', error); this.ngLoading = false; }
    )
  }

  addLocationsToGrid(data, type) {
    if (this.valuesLocations.length > 0) {
      const locations = [];
      for (const loc of this.valuesLocations) {
        const locs = JSON.parse(loc);
        locs['blocked'] = false;
        const locationString = (typeof locs.city !== 'undefined')
          ? (locs.city + ' (City), ' + locs.regionName) : ((typeof locs.regionCode !== 'undefined')
            ? (locs.regionName + ' (Region), ' + locs.countryName) : (locs.countryName + ' (Country)'));
        console.log(locationString, locs)
        locations.push({type: 'locations', codes: JSON.stringify(locs), location: locationString, status: 'Targeted'});
      }
      const res = this.locationsGrid.updateRowData({add: locations});
      for (const item of this.itemsLocations) {
        item.setCheckedRecursive(false);
        item.setCollapsedRecursive(true);
      }
    }
  }

  saveRules() {
    const data = [];
    if (typeof this.locationsGrid !== 'undefined') {
      this.locationsGrid.forEachNode((node, index) => {
        data.push(JSON.parse(node.data.codes));
      });
    }
    this.setDataRules.emit(data);
  }

  cancelModal() {
    $('.locations-bulk-modal').modal('hide');
  }
}
