import {Component, Input, OnInit} from '@angular/core';
import {PerformanceComponent} from '../../pages/performance/performance.component';

@Component({
  selector: 'app-browser-add-locations-modal',
  templateUrl: './browser-add-locations-modal.component.html',
  styleUrls: ['./browser-add-locations-modal.component.scss']
})
export class BrowserAddLocationsModalComponent implements OnInit {
  columnDefsCodes = [
    {headerName: 'Country', field: 'country', width: 175 },
    {headerName: 'State', field: 'state', width: 175 },
    {headerName: 'Location', field: 'location', width: 175 },
    {headerName: '', field: 'target', width: 80 },
    {headerName: '', field: 'exclude', width: 80 },
  ];
  rowDataCodes = [];

  constructor() { }

  ngOnInit() {
  }

}
