import {Component, OnInit, ViewChild} from '@angular/core';
import {ClientService} from '../../_services/client/client.service';
import {Observable, Observer} from 'rxjs';
import { Client } from 'app/_models/client';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {UserService} from '../../_services/user/user.service';
import {User} from '../../_models/user';
import {CampaignNameBulkComponent} from '../../shared/campaign-name-bulk/campaign-name-bulk.component';
import {NewClientComponent} from '../../modals/new-client/new-client.component';
import {NewUserComponent} from '../../modals/new-user/new-user.component';
import {FormArray, FormControl} from '@angular/forms';
import {AlertService} from '../../_services/alert.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import * as moment from 'moment';
import {RangesDatepickerComponent} from '../../shared/ranges-datepicker/ranges-datepicker.component';
import {CommonAuthService} from '../../auth/common-auth.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  columnDefsClients = [
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Client', delete: true },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', sortable: true, filter: true},
    {headerName: 'Email', field: 'email', sortable: true, filter: true},
    {headerName: 'Phone', field: 'phone', sortable: true, filter: true},
    {headerName: 'Address', field: 'address', sortable: true, filter: true},
    {headerName: 'Max Users', field: 'users', sortable: true, filter: true},
    {headerName: 'Trial End Date', field: 'trial_end_date', sortable: true, filter: true},
    {headerName: 'Plan End Date', field: 'plan_end_date', sortable: true, filter: true}
  ];
  ngLoading = false;
  rowDataClients: any;
  rowDataUsers: any;
  columnDefsUsers = [
    {
      headerName: 'Actions',
      field: 'actions',
      sortable: false,
      filter: false,
      width: 120,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'User', delete: true },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'First name', field: 'firstname', sortable: true, filter: true},
    {headerName: 'Last Name', field: 'lastname', sortable: true, filter: true},
    {headerName: 'Email', field: 'email', sortable: true, filter: true},
    {headerName: 'Role', field: 'role', sortable: true, filter: true}
  ];
  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
  };
  currentClient: any = null;
  @ViewChild(NewClientComponent, null) newClientModal: NewClientComponent;
  @ViewChild(NewUserComponent, null) newUserModal: NewUserComponent;
  compParent = {componentParent: this};
  clientsGrid: any;
  usersGrid: any;
  rangeDatesFlag = false;
  dateRange: any = {begin: null, end: null};
  rangesFooter = RangesDatepickerComponent;
  searchString = '';
  dateType = 'plan';
  filtersActive = false;

  constructor(private clientService: ClientService, private userService: UserService, private alert: AlertService, private breadcrumbsService: BreadcrumbsService, private authService: CommonAuthService) { }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
      {label: 'My Partners', url: '/myagencies', params: []}
    ];
    this.getClients();
    this.rowDataUsers = new Observable((ob: Observer<Array<User>>) => {
      ob.next([]);
      ob.complete();
    });
  }

  getClients() {
    this.ngLoading = true;
    this.clientService.getClients(1000, 0,
      this.searchString,
      this.dateRange.begin !== null ? this.dateRange.begin.format('YYYYMMDD') : '',
      this.dateRange.end !== null ? this.dateRange.end.format('YYYYMMDD') : '',
      this.dateType).subscribe(
      (response) => {
        this.rowDataClients = new Observable((ob: Observer<Array<Client>>) => {
          ob.next(response.response.clients);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  getUsers() {
    this.ngLoading = true;
    this.userService.getUsersByClient(this.currentClient.id).subscribe(
      (response) => {
        this.rowDataUsers = new Observable((ob: Observer<Array<User>>) => {
          ob.next(response.response.users);
          ob.complete();
        })
        this.ngLoading = false;
      }
    )
  }

  addUserModal() {
    this.newUserModal.clientIds = [String(this.currentClient.id)];
    this.newUserModal.getClients();
    $('.new-user-modal').modal('show');
  }

  rowClientSelected(event: any) {
    if (event.node.selected) {
      this.currentClient = event.data;
      this.getUsers();
    }
  }

  editClientModal(node: any) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newClientModal.loadClient(node.data);
      $('.new-client-modal').modal('show');
    }
  }

  rowUserSelected(event: any) {

  }

  editUserModal(node: any) {
    if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.newUserModal.getClients();
      this.newUserModal.loadUser(node.data);
      $('.new-user-modal').modal('show');
    }
  }

  addClientModal() {
    $('.new-client-modal').modal('show');
  }

  remove(id, type = 'Client') {
    if (type === 'User') {
      this.ngLoading = true;
      this.userService.deleteUser(id).subscribe(
        (response) => { this.alert.alert('success', 'User deleted successfully.'); this.ngLoading = false; },
        (error) => { console.log(error, 'deleteUser'); this.ngLoading = false; }
      )
    } else {
      this.ngLoading = true;
      this.clientService.deleteClient(id).subscribe(
        (response) => { this.alert.alert('success', 'Client deleted successfully.'); this.ngLoading = false; },
        (error) => { console.log(error, 'deleteUser'); this.ngLoading = false; }
      )
    }
  }

  exportClients() {
    this.clientsGrid.exportDataAsCsv();
  }

  clientsGridReady(event: any) {
    this.clientsGrid = event.api;
  }

  usersGridReady(event: any) {
    this.usersGrid = event.api;
  }

  exportUsers() {
    this.usersGrid.exportDataAsCsv();
  }

  rangeChanged(event: SatDatepickerInputEvent<unknown>, dateRange: any, input: HTMLInputElement = null) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      dateRange.begin = dateRange.begin.subtract(1, 'days');
      dateRange.end = dateRange.end.subtract(1, 'days');
      input.value = dateRange.begin.format('MM/DD/YYYY') + ' - ' + dateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
        ? {
          ...savedGridDates,
          partnersDateRange: this.dateRange
        }
        : {
          partnersDateRange: this.dateRange
        };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
  }

  closedDatesRange(type: string = 'brand') {
    if (this.rangeDatesFlag) {
      this.getClients();
    } else {
      this.dateRange = {begin: null, end: null};
    }
    this.rangeDatesFlag = false;
  }

  filterChanged(event: any) {
    this.filtersActive = Object.keys(event.api.getFilterModel()).length > 0;
  }

  ghostUser(id) {
    this.ngLoading = true;
    this.userService.getUserWithTokenById(id).subscribe(
      (response) => {
        if (response && response['response'].token && response['response'].user) {
          localStorage.removeItem('token');
          localStorage.setItem('token', response['response'].token);
          response['response'].user['from'] = 'anAdmin';
          response['response'].user['ghostAdmin'] = this.authService.currentUserValue.id;
          localStorage.setItem('user', JSON.stringify(response['response'].user));
          localStorage.setItem('user2', JSON.stringify(response['response'].user));
          this.authService.setCustomUser(response.user as User);
          location.href = '/mybrands';
        }
        this.ngLoading = false;
      }
    )
  }
}
