import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormArray, FormArrayName, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {BrandCategory} from '../../../_models/responses';
import {BrandService} from '../../../_services/brand/brand.service';
import {CampaignGroup} from '../../../_models/campaignGroup';
import * as moment from 'moment';
import {AdvertiserSpendType} from '../../../_models/enums';
import {Ad} from '../../../_models/ad';
import {Brand} from '../../../_models/brand';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';

@Component({
  selector: 'app-new-image-modal',
  templateUrl: './new-image-modal.component.html',
  styleUrls: ['./new-image-modal.component.scss']
})
export class NewImageModalComponent extends NewAdComponent implements OnInit {
  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }

  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.newForm = this.fb.group({
      adType: ['image'],
      label: ['', Validators.required],
      status: ['online'],
      width: [''],
      height: [''],
      type: ['banner'],
      assetUrl: ['', [Validators.required]],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      landingPageDomain: ['', [Validators.pattern('^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$'), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')])
    });
    // this.brandId = this._parent.brandId;
    // this._parent.newForm = this.newForm;
  }

  // compareCategories = (item, selected) => {
  //   if (selected.attributeId && item.attributeId) {
  //     return item.attributeId === selected.attributeId;
  //   }
  //   return false;
  // };
  //
  // get formControls() {
  //   return this.newImgForm.controls;
  // }
  //
  // get winNotificationUrlsControls() {
  //   return this.newImgForm.controls.winNotificationUrl['controls'] as FormArray;
  // }
  //
  // get scriptTrackingUrlsControls() {
  //   return this.newImgForm.controls.scriptTrackingUrl['controls'] as FormArray;
  // }
  //
  // getBrandCategories() {
  //   this.brandService.getBrandCategories().subscribe(
  //     (response) => {
  //       this.allCategories = response.response.brandCategories;
  //       const filteredCategories = this.allCategories.filter((item, index, array) => {
  //         if (typeof item.parentId) {
  //           const indexParent = array.findIndex(elem => elem.attributeId === item.parentId);
  //           if (indexParent !== -1) {
  //             item['parentName'] = array[indexParent].name;
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         } else {
  //           return false;
  //         }
  //       });
  //       this.categoriesGroup = (text: string, children: any[]) => {
  //         const group = this.allCategories.find(elem => elem.attributeId === children[0].parentId);
  //         return group;
  //       }
  //       this.categories = Observable.create((obs) => {
  //         obs.next(filteredCategories);
  //         obs.complete();
  //       });
  //     },
  //     (error) => { console.log(error); }
  //   )
  // }
  //
  // addImpTrackingField(values: any = {}) {
  //   (this.newImgForm.get('winNotificationUrl') as FormArray).push(
  //     // this.fb.control('', [Validators.pattern(this.urlReg)])
  //     this.fb.control('')
  //   );
  //   this.impTrackingField.push(this.impTrackingField.length);
  // }
  //
  // deleteImpTrackingField(pos: number) {
  //   console.log(pos, this.impTrackingField);
  //   (this.newImgForm.get('winNotificationUrl') as FormArray).removeAt(pos);
  //   this.impTrackingField.splice(pos, 1);
  // }
  //
  // addScriptTrackingField(values: any = {}) {
  //   (this.newImgForm.get('scriptTrackingUrl') as FormArray).push(
  //     // this.fb.control('', [Validators.pattern(this.urlReg)])
  //     this.fb.control('')
  //   );
  //   this.scriptTrackingField.push(this.scriptTrackingField.length);
  // }
  //
  // deleteScriptTrackingField(pos: number) {
  //   console.log(pos, this.scriptTrackingField);
  //   (this.newImgForm.get('scriptTrackingUrl') as FormArray).removeAt(pos);
  //   this.scriptTrackingField.splice(pos, 1);
  // }
  //
  // pondHandleInit() {
  //   console.log('FilePond has initialised', this.myPond);
  // }
  //
  // pondHandleAddFile(event: any) {
  //   console.log('A file was added', event);
  // }
  //
  // pondRemovedFile(event) {
  //   const element = document.getElementById('adPanel');
  //   try {
  //     element.removeChild(element.firstChild);
  //   } catch (error) {
  //     console.error(error, 'pondRemovedFile');
  //   }
  //   this.adService.deleteFile(event.file).subscribe(response => {});
  // }
  //
  // loadGroup(imgAd: Ad) {
  //   this.createOrEdit = 'Edit';
  //   this.newImgForm.addControl('creativeId', this.fb.control(imgAd.creativeId, [Validators.required]));
  //   this.newImgForm.controls.label.setValue(imgAd.label);
  //   this.newImgForm.controls.type.setValue(imgAd.type);
  //   this.newImgForm.controls.assetUrl.setValue(imgAd.assetUrl);
  //   // this.pondFiles = [imgAd.assetUrl];
  //   this.img.src = imgAd.assetUrl;
  //   document.getElementById('adPanel').appendChild(this.img);
  //   this.newImgForm.controls.clickUrl.setValue(imgAd.clickUrl);
  //   if (imgAd.winNotificationUrl !== null) {
  //     if (typeof imgAd.winNotificationUrl === 'string') {
  //       (<FormArray>this.newImgForm.controls.winNotificationUrl).controls['0'].setValue(imgAd.winNotificationUrl);
  //     } else {
  //     }
  //   }
  //   this.newImgForm.controls.sslEnabled.setValue(imgAd.sslEnabled);
  //   this.newImgForm.controls.landingPageDomain.setValue(imgAd.landingPageDomain);
  //   this.newImgForm.controls.landingPageDomain.disable();
  //   this.newImgForm.controls.reviewAttributes.setValue((imgAd.reviewAttributes !== null) ? imgAd.reviewAttributes : '');
  //   // this.newImgForm.controls.scriptTrackingUrl.setValue(imgAd.scriptTrackingUrl);
  //   if (imgAd.reviewAttributes !== null) {
  //     this.selectedOptions = [...this.selectedOptions, ...imgAd.reviewAttributes];
  //   }
  // }
  //
  // onSubmit() {
  //   if (this.brandId === null) {
  //     this.alertService.alert('error', 'You need to select a Brand.');
  //     return;
  //   }
  //   this.newImgForm.controls.reviewAttributes.setValue(this.selectedOptions);
  //   this.newImgForm.controls.brandId.setValue(this.brandId);
  //   const controlsPixels = (this.newImgForm.get('winNotificationUrl') as FormArray).value;
  //   const controlsScripts = (this.newImgForm.get('scriptTrackingUrl') as FormArray).value;
  //   const values = [];
  //   for (const control of controlsPixels) {
  //     values.push({'url': control, 'type': 'PIXEL'})
  //   }
  //   for (const control of controlsScripts) {
  //     values.push({'url': control, 'type': 'SCRIPT'})
  //   }
  //   this.newImgForm.controls.impressionTracking.setValue(values);
  //   if (this.createOrEdit === 'Create') {
  //     this.adService.createAd(this.newImgForm.value as Ad)
  //       .subscribe(response => {
  //         this.ngLoading = false;
  //         this.newImgForm.reset();
  //         this.ngOnInit();
  //         this.myPond.removeFiles();
  //         if ( String(response.status) === 'SUCCESS' ) {
  //           this.alertService.alert('success', 'Image Ad created successfully.');
  //         }
  //       });
  //   } else {
  //     this.adService.updateAd(this.newImgForm.value as Ad, this.newImgForm.get('creativeId').value)
  //       .subscribe(response => {
  //         this.ngLoading = false;
  //         this.myPond.removeFiles();
  //         if ( String(response.status) === 'SUCCESS' ) {
  //           this.alertService.alert('success', 'Image Ad updated successfully.');
  //         }
  //       });
  //   }
  // }
  //
  // resetNewImageAdModal() {
  //   this.pondFiles = [];
  //   this.newImgForm.reset();
  //   this.createOrEdit = 'Create';
  //   this.selectedOptions = [];
  //   this.ngOnInit();
  // }
}
