import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {BrandService} from '../../_services/brand/brand.service';
import {AlertService} from '../../_services/alert.service';
import {CommonAuthService} from '../../auth/common-auth.service';
import {Brand} from '../../_models/brand';

@Component({
  selector: 'app-market-place-details',
  templateUrl: './market-place-details.component.html',
  styleUrls: ['./market-place-details.component.scss']
})
export class MarketPlaceDetailsComponent implements OnInit {

  ngLoading = false;
  @Output() refreshGrid = new EventEmitter<string>();
  public details: any;
  constructor(
    private alertService: AlertService,
    private auth: CommonAuthService
  ) { }

  ngOnInit() {
  }

  closeDetailsModal(): void {

  }

  loadDetails (details: any) {
    console.log(details);
    this.details = details;
  }

}
