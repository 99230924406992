import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import { NavbarComponent } from './shared/common/navbar/navbar.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { PerformanceComponent } from './pages/performance/performance.component';
import { InventoryComponent } from './pages/inventory/inventory.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { DomainsComponent } from './pages/domains/domains.component';
import { AgGridModule } from 'ag-grid-angular';
import {
  MatButtonModule, MatButtonToggleModule, MatCheckboxModule, MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule, MatNativeDateModule, MatRadioModule,
  MatSelectModule,
  MatSliderModule, MatSlideToggleModule,
  MatTabsModule, MatTooltipModule
} from '@angular/material';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {HighchartsChartModule} from 'highcharts-angular';
import { ScheduleReportsComponent } from './pages/schedule-reports/schedule-reports.component';
import { OneTimeReportsComponent } from './pages/one-time-reports/one-time-reports.component';
import { NewBrandModalComponent } from './modals/new-brand-modal/new-brand-modal.component';
import { NewGroupModalComponent } from './modals/new-group-modal/new-group-modal.component';
import { NewAudienceModalComponent } from './modals/new-audience-modal/new-audience-modal.component';
import { NewConversionModalComponent } from './modals/new-conversion-modal/new-conversion-modal.component';
import { NewImageModalComponent } from './modals/Ads/new-image-modal/new-image-modal.component';
import { NewAudioModalComponent } from './modals/Ads/new-audio-modal/new-audio-modal.component';
import { NewVastAudioModalComponent } from './modals/Ads/new-vast-audio-modal/new-vast-audio-modal.component';
import { NewVideoModalComponent } from './modals/Ads/new-video-modal/new-video-modal.component';
import { NewVastVideoModalComponent } from './modals/Ads/new-vast-video-modal/new-vast-video-modal.component';
import { NewNativeModalComponent } from './modals/Ads/new-native-modal/new-native-modal.component';
import { NewHtml5ModalComponent } from './modals/Ads/new-html5-modal/new-html5-modal.component';
import { NewTagModalComponent } from './modals/Ads/new-tag-modal/new-tag-modal.component';
import { NewExpandableTagModalComponent } from './modals/Ads/new-expandable-tag-modal/new-expandable-tag-modal.component';
import { NewMraidTagModalComponent } from './modals/Ads/new-mraid-tag-modal/new-mraid-tag-modal.component';
import { NewMultipleImageModalComponent } from './modals/Ads/new-multiple-image-modal/new-multiple-image-modal.component';
import { NewMultipleNativeModalComponent } from './modals/Ads/new-multiple-native-modal/new-multiple-native-modal.component';
import { NewMultipleTagModalComponent } from './modals/Ads/new-multiple-tag-modal/new-multiple-tag-modal.component';
// import {MatFileUploadModule} from 'angular-material-fileupload';
import { MultipleEditGroupModalComponent } from './modals/multiple-edit-group-modal/multiple-edit-group-modal.component';
import { MultipleEditAdModalComponent } from './modals/multiple-edit-ad-modal/multiple-edit-ad-modal.component';
import { MultipleEdit2AdModalComponent } from './modals/multiple-edit2-ad-modal/multiple-edit2-ad-modal.component';
import { NewCampaignComponent } from './pages/new-campaign/new-campaign.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { SelectInventoryModalComponent } from './modals/select-inventory-modal/select-inventory-modal.component';
import { NewDomainListModalComponent } from './modals/new-domain-list-modal/new-domain-list-modal.component';
import { LinkAdsModalComponent } from './modals/link-ads-modal/link-ads-modal.component';
import { CampaignAudienceModalComponent } from './modals/campaign-bulk-edit/campaign-audience-modal/campaign-audience-modal.component';
import { CampaignBrandProtectionModalComponent } from './modals/campaign-bulk-edit/campaign-brand-protection-modal/campaign-brand-protection-modal.component';
import { CampaignNamesModalComponent } from './modals/campaign-bulk-edit/campaign-names-modal/campaign-names-modal.component';
import { CampaignContextualModalComponent } from './modals/campaign-bulk-edit/campaign-contextual-modal/campaign-contextual-modal.component';
import { CampaignConversionsModalComponent } from './modals/campaign-bulk-edit/campaign-conversions-modal/campaign-conversions-modal.component';
import { CampaignDaypartingModalComponent } from './modals/campaign-bulk-edit/campaign-dayparting-modal/campaign-dayparting-modal.component';
import { CampaignDomainlistModalComponent } from './modals/campaign-bulk-edit/campaign-domainlist-modal/campaign-domainlist-modal.component';
import { CampaignSettingsModalComponent } from './modals/campaign-bulk-edit/campaign-settings-modal/campaign-settings-modal.component';
import { CampaignLocationsModalComponent } from './modals/campaign-bulk-edit/campaign-locations-modal/campaign-locations-modal.component';
import { BrowserAddLocationsModalComponent } from './modals/browser-add-locations-modal/browser-add-locations-modal.component';
import { LookupDmaCodesModalComponent } from './modals/lookup-dma-codes-modal/lookup-dma-codes-modal.component';
import { ActionsCellRendererComponent } from './shared/actions-cell-renderer/actions-cell-renderer.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NgxLoadingModule} from 'ngx-loading';
// import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {UserModule} from './admin/users/user.module';
import { StatusCellRendererComponent } from './shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import { TotalCellRendererComponent } from './shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import { NumberFormatCellRendererComponent } from './shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule} from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RangesDatepickerComponent } from './shared/ranges-datepicker/ranges-datepicker.component';
import { BudgetCellRendererComponent } from './shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import { AdReviewComponent } from './shared/ad-review/ad-review.component';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FilePondModule, registerPlugin} from 'ngx-filepond';

// import and register filepond file type validation plugin
import * as FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
registerPlugin(FilePondPluginFileValidateType);
// Import the plugin code
import * as FilePond from 'filepond';
import * as FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// Register the plugin
FilePond.registerPlugin(FilePondPluginFileValidateSize);
import * as FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
registerPlugin(FilePondPluginImageValidateSize);
import * as FilepondPluginImageSizeMetadata from 'filepond-plugin-image-size-metadata';
registerPlugin(FilepondPluginImageSizeMetadata);
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
registerPlugin(FilePondPluginImagePreview);
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { LinkAdCampaignComponent } from './shared/cell-renderers/link-ad-campaign/link-ad-campaign.component';
import { NewAdComponent } from './shared/new-ad/new-ad.component';
import { GridCellSelectComponent } from './shared/grid-cell-select/grid-cell-select.component';
import { TextEditCellComponent } from './shared/text-edit-cell/text-edit-cell.component';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import { AudienceUploadDeviceidsComponent } from './modals/audience-upload-deviceids/audience-upload-deviceids.component';
import { AudienceGetTagsComponent } from './modals/audience-get-tags/audience-get-tags.component';
import { AudiencesBulkExportComponent } from './modals/audiences-bulk-export/audiences-bulk-export.component';
import { ConverionAudienceCaptureComponent } from './modals/converion-audience-capture/converion-audience-capture.component';
import { ConversionGetTagsComponent } from './modals/conversion-get-tags/conversion-get-tags.component';
import { ConversionBulkExportComponent } from './modals/conversion-bulk-export/conversion-bulk-export.component';
import { NewUniversalPixelComponent } from './modals/new-universal-pixel/new-universal-pixel.component';
import { UniversalPixelTagComponent } from './modals/universal-pixel-tag/universal-pixel-tag.component';
import { UniversalRuleComponent } from './shared/universal-rule/universal-rule.component';
import { UniversalGroupRuleComponent } from './shared/universal-group-rule/universal-group-rule.component';
import { UniversalSubruleComponent } from './shared/universal-subrule/universal-subrule.component';
import {LoginComponent} from './auth/login/login.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTreeModule} from '@angular/material/tree';
import { AddDomainappCurrentDomainModalComponent } from './modals/add-domainapp-current-domain-modal/add-domainapp-current-domain-modal.component';
import {TreeviewModule} from 'ngx-treeview';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { RuleNestedDrawComponent } from './shared/rule-nested-draw/rule-nested-draw.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatListModule} from '@angular/material/list';
import { AudienceTargetRuleComponent } from './shared/audience-target-rule/audience-target-rule.component';
import { AudienceTargetGroupRuleComponent } from './shared/audience-target-group-rule/audience-target-group-rule.component';
import { AudienceTargetSubRuleComponent } from './shared/audience-target-sub-rule/audience-target-sub-rule.component';
import {MatCardModule} from '@angular/material/card';
import { LinkAdsCampaignComponent } from './modals/link-ads-campaign/link-ads-campaign.component';
import { CheckBoxCellComponent } from './shared/check-box-cell/check-box-cell.component';
import { AudiencesRulesComponent } from './shared/audiences-rules/audiences-rules.component';
import { BrandProtectionBulkComponent } from './shared/brand-protection-bulk/brand-protection-bulk.component';
import { CampaignNameBulkComponent } from './shared/campaign-name-bulk/campaign-name-bulk.component';
import { ContextualBulkComponent } from './shared/contextual-bulk/contextual-bulk.component';
import { ConversionsBulkComponent } from './shared/conversions-bulk/conversions-bulk.component';
import { DayPartingBulkComponent } from './shared/day-parting-bulk/day-parting-bulk.component';
import { DomainListBulkComponent } from './shared/domain-list-bulk/domain-list-bulk.component';
import { LocationsBulkComponent } from './shared/locations-bulk/locations-bulk.component';
import { StatusReportCellRenderComponent } from './shared/cell-renderers/status-report-cell-render/status-report-cell-render.component';
import { NewScheduleReportModalComponent } from './modals/new-schedule-report-modal/new-schedule-report-modal.component';
// import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NewOneTimeReportModalComponent } from './modals/new-one-time-report-modal/new-one-time-report-modal.component';
import { NewClientComponent } from './modals/new-client/new-client.component';
import { DomainsStatsCrudComponent } from './shared/domains-stats-crud/domains-stats-crud.component';
import { RequestSupportComponent } from './modals/request-support/request-support.component';
import { ContactInformationComponent } from './modals/contact-information/contact-information.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {CommonAuthModule} from './auth/common-auth.module';
import { MarketPlaceDetailsComponent } from './modals/market-place-details/market-place-details.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatChipsModule} from '@angular/material/chips';
import {UsersComponent} from './pages/users/users.component';
import {NewUserBasicComponent} from './modals/new-user-basic/new-user-basic.component';
import {NewSubscriptionModalComponent} from './modals/new-subscription-modal/new-subscription-modal.component';
import { AddBalanceModalComponent } from './modals/add-balance-modal/add-balance-modal.component';
import {MatCurrencyFormatModule} from 'mat-currency-format';
import { CopyCampaignComponent } from './modals/campaign-bulk-edit/copy-campaign/copy-campaign.component';
import {MatDialogModule} from '@angular/material/dialog';
import {CampaignAdsStatsComponent} from './shared/campaign-ads-stats/campaign-ads-stats.component';
import { CampaignConversionsStatsComponent } from './shared/campaign-conversions-stats/campaign-conversions-stats.component';
import { CampaignExchangesStatsComponent } from './shared/campaign-exchanges-stats/campaign-exchanges-stats.component';
import { CampaignDailyStatsComponent } from './shared/campaign-daily-stats/campaign-daily-stats.component';
import { CampaignHourlyStatsComponent } from './shared/campaign-hourly-stats/campaign-hourly-stats.component';
import { GridColumnsCustomizationComponent } from './shared/grid-columns-customization/grid-columns-customization.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {EditorModule} from '@tinymce/tinymce-angular';
import { SupportComponent } from './pages/support/support/support.component';
import {NewCrmAudienceModalComponent} from './modals/new-crm-audience-modal/new-crm-audience-modal.component';
import {CrmAudienceUploadListComponent} from './modals/crm-audience-upload-list/crm-audience-upload-list.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyPrintComponent } from './pages/privacy-policy-print/privacy-policy-print.component';
import { TermsPrintComponent } from './pages/terms-print/terms-print.component';
registerPlugin(FilePondPluginImagePreview);

@NgModule({
  declarations: [
        AppComponent,
        AdminLayoutComponent,
        WelcomeLayoutComponent,
        NavbarComponent,
        BreadcrumbComponent,
        BrandsComponent,
        PartnersComponent,
        UsersComponent,
        NewUserBasicComponent,
        PerformanceComponent,
        InventoryComponent,
        MarketplaceComponent,
        DomainsComponent,
        ScheduleReportsComponent,
        OneTimeReportsComponent,
        NewBrandModalComponent,
        NewGroupModalComponent,
        NewAudienceModalComponent,
        NewCrmAudienceModalComponent,
        CrmAudienceUploadListComponent,
        NewConversionModalComponent,
        NewImageModalComponent,
        NewAudioModalComponent,
        NewVastAudioModalComponent,
        NewVideoModalComponent,
        NewVastVideoModalComponent,
        NewNativeModalComponent,
        NewHtml5ModalComponent,
        NewTagModalComponent,
        NewExpandableTagModalComponent,
        NewMraidTagModalComponent,
        NewMultipleImageModalComponent,
        NewMultipleNativeModalComponent,
        NewMultipleTagModalComponent,
        MultipleEditGroupModalComponent,
        MultipleEditAdModalComponent,
        MultipleEdit2AdModalComponent,
        NewCampaignComponent,
        SelectInventoryModalComponent,
        NewDomainListModalComponent,
        LinkAdsModalComponent,
        CampaignAudienceModalComponent,
        CampaignBrandProtectionModalComponent,
        CampaignNamesModalComponent,
        CampaignContextualModalComponent,
        CampaignConversionsModalComponent,
        CampaignDaypartingModalComponent,
        CampaignDomainlistModalComponent,
        CampaignSettingsModalComponent,
        CampaignLocationsModalComponent,
        BrowserAddLocationsModalComponent,
        LookupDmaCodesModalComponent,
        ActionsCellRendererComponent,
        StatusCellRendererComponent,
        StatusReportCellRenderComponent,
        TotalCellRendererComponent,
        NumberFormatCellRendererComponent,
        RangesDatepickerComponent,
        BudgetCellRendererComponent,
        AdReviewComponent,
        CampaignsComponent,
        LinkAdCampaignComponent,
        NewAdComponent,
        GridCellSelectComponent,
        TextEditCellComponent,
        AudienceUploadDeviceidsComponent,
        AudienceGetTagsComponent,
        AudiencesBulkExportComponent,
        ConverionAudienceCaptureComponent,
        ConversionGetTagsComponent,
        ConversionBulkExportComponent,
        NewUniversalPixelComponent,
        UniversalPixelTagComponent,
        UniversalRuleComponent,
        UniversalGroupRuleComponent,
        UniversalSubruleComponent,
        AddDomainappCurrentDomainModalComponent,
        RuleNestedDrawComponent,
        AudienceTargetRuleComponent,
        AudienceTargetGroupRuleComponent,
        AudienceTargetSubRuleComponent,
        LinkAdsCampaignComponent,
        CheckBoxCellComponent,
        AudiencesRulesComponent,
        BrandProtectionBulkComponent,
        CampaignNameBulkComponent,
        ContextualBulkComponent,
        ConversionsBulkComponent,
        DayPartingBulkComponent,
        DomainListBulkComponent,
        LocationsBulkComponent,
        StatusReportCellRenderComponent,
        NewScheduleReportModalComponent,
        NewOneTimeReportModalComponent,
        NewClientComponent,
        DomainsStatsCrudComponent,
        RequestSupportComponent,
        ContactInformationComponent,
        MarketPlaceDetailsComponent,
        NewSubscriptionModalComponent,
        AddBalanceModalComponent,
        CopyCampaignComponent,
        CampaignAdsStatsComponent,
        CampaignConversionsStatsComponent,
        CampaignExchangesStatsComponent,
        CampaignDailyStatsComponent,
        CampaignHourlyStatsComponent,
        GridColumnsCustomizationComponent,
        SupportComponent,
        TermsComponent,
        PrivacyPolicyComponent,
        PrivacyPolicyPrintComponent,
        TermsPrintComponent
    ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes,
      {
        useHash: false
      }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    AgGridModule.withComponents([
      ActionsCellRendererComponent,
      TotalCellRendererComponent,
      StatusCellRendererComponent,
      StatusReportCellRenderComponent,
      NumberFormatCellRendererComponent,
      BudgetCellRendererComponent,
      AdReviewComponent,
      LinkAdCampaignComponent,
      GridCellSelectComponent,
      TextEditCellComponent
    ]),
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatMenuModule,
    NgxDaterangepickerMd.forRoot(),
    FormsModule,
    HighchartsChartModule,
    MatSliderModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    // MatFileUploadModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      libraries: ['places', 'geometry']
    }),
    SweetAlert2Module.forRoot(),
    NgxLoadingModule.forRoot({}),
    // NgxMatSelectSearchModule,
    UserModule,
    MatSlideToggleModule,
    MatTooltipModule,
    NgSelectModule,
    SatDatepickerModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    FilePondModule,
    MatStepperModule,
    NgbModule,
    MatTreeModule,
    TreeviewModule.forRoot(),
    MatProgressBarModule,
    MatAutocompleteModule,
    MatListModule,
    MatCardModule,
    // NgxMaterialTimepickerModule,
    MatSidenavModule,
    CommonAuthModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCurrencyFormatModule,
    MatDialogModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatExpansionModule,
    EditorModule
  ],
  entryComponents: [RangesDatepickerComponent, CheckBoxCellComponent, CopyCampaignComponent, GridColumnsCustomizationComponent],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  exports: [
    RequestSupportComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
