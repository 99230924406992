import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import * as bootstrap from "bootstrap";
import {BrandsComponent} from '../../pages/brands/brands.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BrandService} from '../../_services/brand/brand.service';
import {Observable} from 'rxjs';
import {BrandCategory, BrandResponse} from '../../_models/responses';
import {User} from '../../_models/user';
import {AlertService} from '../../_services/alert.service';
import {Brand} from '../../_models/brand';
import {CommonAuthService} from '../../auth/common-auth.service';

@Component({
  selector: 'app-new-brand-modal',
  templateUrl: './new-brand-modal.component.html',
  styleUrls: ['./new-brand-modal.component.scss']
})
export class NewBrandModalComponent implements OnInit {
  _parent: BrandsComponent ;
  // @Input() set parent(value: BrandsComponent ) {
  //   this._parent = value;
  // }
  newBrandForm: FormGroup;
  @Output() refreshGrid = new EventEmitter<string>();

  selectedOptions = [];
  categories: Observable<BrandCategory[]>;
  allCategories: BrandCategory[];

  user = localStorage.user ? JSON.parse(localStorage.user) : null;

  categoriesGroup: any;
  createOrEdit = 'Create';
  ngLoading = false;

  constructor(
    private formB: FormBuilder,
    private brandService: BrandService,
    private alertService: AlertService,
    private auth: CommonAuthService
  ) { }

  ngOnInit() {
    this.selectedOptions = [];
    this.newBrandForm = this.formB.group({
      name: ['', Validators.required],
      landingPageDomain: ['', Validators.pattern('^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$')],
      notes: [''],
      reviewAttributes: [''],
      client_id: [(this.user !== null && this.user['clients'].length > 0) ? this.user['clients'][0].id : '', Validators.required],
      archived: [false]
    });
    console.log(this.user);
    this.getBrandCategories();
  }

  get formControls() {
    return this.newBrandForm.controls;
  }

  compareCategories = (item, selected) => {
    if (selected.attributeId && item.attributeId) {
      return item.attributeId === selected.attributeId;
    }
    return false;
  };

  getBrandCategories() {
    this.brandService.getBrandCategories().subscribe(
      (response) => {
        this.allCategories = response.response.brandCategories;
        const filteredCategories = this.allCategories.filter((item, index, array) => {
          if (typeof item.parentId) {
            const indexParent = array.findIndex(elem => elem.attributeId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.categoriesGroup = (text: string, children: any[]) => {
          const group = this.allCategories.find(elem => elem.attributeId === children[0].parentId);
          return group;
        }
        this.categories = Observable.create((obs) => {
          obs.next(filteredCategories);
          obs.complete();
        });
      },
      (error) => { console.log(error); }
    )
  }

  onSubmit() {
    if (this.createOrEdit === 'Create') {
      this.ngLoading = true;
      this.brandService.createBrand(this.newBrandForm.value as Brand)
        .subscribe(response => {
          this.ngLoading = false;
          this.newBrandForm.reset();
          this.ngOnInit();
          this.refreshGrid.emit('brand');
          // $('.new-brand-modal').modal('hide');
          document.getElementById('closeNewBrandButton').click();
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Brand created successfully.');
          }
          this.ngLoading = false;
        },
          (error) => { console.log(error, 'createBrand'); this.ngLoading = false; });
    } else {
      this.ngLoading = true;
      this.brandService.updateBrand(this.newBrandForm.value as Brand, this.newBrandForm.get('id').value)
        .subscribe(response => {
          this.ngLoading = false;
          this.refreshGrid.emit('brand');
          if ( String(response.status) === 'SUCCESS' ) {
            this.alertService.alert('success', 'Brand updated successfully.');
          }
          this.ngLoading = false;
        },
          (error) => { console.log(error, 'updateBrand'); this.ngLoading = false; });
    }
  }

  loadBrand (brand: Brand) {
    console.log(brand);
    this.createOrEdit = 'Edit';
    this.newBrandForm.addControl('id', new FormControl());
    this.newBrandForm.controls.id.setValue(brand.brandId);
    this.newBrandForm.controls.name.setValue(brand.name);
    this.newBrandForm.controls.archived.setValue(brand.archived);
    this.newBrandForm.controls.notes.setValue(brand.notes);
    this.newBrandForm.controls.client_id.setValue(brand.clientId);
    this.newBrandForm.controls.landingPageDomain.setValue(brand.landingPageDomain);
    this.newBrandForm.controls.reviewAttributes.setValue(brand.reviewAttributes);
    if (brand.reviewAttributes !== null) {
      this.selectedOptions = [...this.selectedOptions, ...brand.reviewAttributes];
    }
  }

  closeNewBrandModal() {
    this.newBrandForm.reset();
    this.createOrEdit = 'Create';
    this.selectedOptions = [];
    // $('.new-brand-modal').modal('hide');
  }
}
