import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {AdService} from '../../../_services/ad/ad.service';
import {BrandService} from '../../../_services/brand/brand.service';
import {AlertService} from '../../../_services/alert.service';
import {NewAdComponent} from '../../../shared/new-ad/new-ad.component';
import {environment} from '../../../../environments/environment';
import {Ad} from '../../../_models/ad';

@Component({
  selector: 'app-new-native-modal',
  templateUrl: './new-native-modal.component.html',
  styleUrls: ['./new-native-modal.component.scss']
})
export class NewNativeModalComponent extends NewAdComponent implements OnInit {

  // private _parent: NewAdComponent ;
  // @Input() set parent(value: NewAdComponent ) {
  //   this._parent = value;
  // }
  pondOptionsLogo: any;
  public logo = new Image();
  constructor(
    public fb: FormBuilder,
    public adService: AdService,
    public brandService: BrandService,
    public alertService: AlertService
  ) {
    super(fb, adService, brandService, alertService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.newForm = this.fb.group({
      adType: ['native'],
      label: ['', Validators.required],
      status: ['online'],
      width: [''],
      height: [''],
      type: ['native'],
      clickUrl: ['', [Validators.required, Validators.pattern('^(http|https):\\/\\/[^ "]+$')]],
      winNotificationUrl: [''],
      scriptTrackingUrl: this.fb.array([this.fb.control('')]),
      sslEnabled: [true],
      landingPageDomain: ['', [Validators.pattern('^(?!:\\/\\/)([a-zA-Z0-9-_]+\\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\\.[a-zA-Z]{2,11}?$'), Validators.required]],
      reviewAttributes: [''],
      brandId: [''],
      impressionTracking: this.fb.array([this.fb.control('')]),
      nativeFields: this.fb.array([
        this.fb.group({
          type: ['text'],
          name: ['DESCRIPTION'],
          value: ['']
        }),
        this.fb.group({
          type: ['text', Validators.required],
          name: ['TITLE', Validators.required],
          value: ['', Validators.required]
        }),
        this.fb.group({
          type: ['image', Validators.required],
          name: ['MAIN', Validators.required],
          imageUrl: ['', Validators.required]
        }),
        this.fb.group({
          type: ['text', Validators.required],
          name: ['SPONSORED_BY', Validators.required],
          value: ['', Validators.required]
        }),
        this.fb.group({
          type: ['image'],
          name: ['ICON'],
          imageUrl: ['']
        }),
      ])
    });
    this.pondOptionsImage = {
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/*',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              this.img.src = `${environment.publicUrl}` + response;
              if (typeof this.nativeFieldsControls[2] !== 'undefined' && this.nativeFieldsControls[2].value !== null) {
                this.nativeFieldsControls[2].setValue({type: 'image', name: 'MAIN' , imageUrl: response});
              }
              // console.log('IMG ASSET: ', this.newForm.controls.assetUrlCompanion.value, this.newForm.value);
              document.getElementById('main_image').appendChild(this.img);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getImage(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
    this.pondOptionsLogo = {
      multiple: false,
      labelIdle: 'Drop files or click here for browsing...',
      acceptedFileTypes: 'image/*',
      allowImagePreview: false,
      imagePreviewTransparencyIndicator: 'grid',
      imagePreviewMarkupShow: false,
      maxFileSize: '200KB',
      allowImageSizeMetadata: true,
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
          this.adService.uploadImage(fieldName, file, metadata, load, error, progress, abort)
            .subscribe(response => {
              console.log('process response: ', response);
              load(`${environment.publicUrl}` + response);
              this.logo.src = `${environment.publicUrl}` + response;
              if (typeof this.nativeFieldsControls[4] !== 'undefined' && this.nativeFieldsControls[4].value !== null) {
                this.nativeFieldsControls[4].setValue({type: 'image', name: 'ICON' , imageUrl: response});
              }
              // console.log('IMG ASSET: ', this.newForm.controls.assetUrlCompanion.value, this.newForm.value);
              document.getElementById('logoNative').appendChild(this.logo);
              return response;
            });
        },
        load: (source, load, error, progress, abort, headers) => {
          this.adService.getImage(source)
            .subscribe(response => {
              load(response);
            });
        },
        revert: (uniqueFileId, load, error) => {
          error('oh my goodness');
          load();
        }
      }
    };
  }

  public get nativeFieldsControls() {
    return this.newForm.controls.nativeFields['controls'] as FormArray;
  }
}
