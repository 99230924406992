import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-universal-group-rule',
  templateUrl: './universal-group-rule.component.html',
  styleUrls: ['./universal-group-rule.component.scss']
})
export class UniversalGroupRuleComponent implements OnInit {

  @Input() target: FormGroup;
  @Input() index: number;
  @Input() targetsLength: number;
  @Output() groupDeleted = new EventEmitter();
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    // console.log(this.target);
  }

  addSubRule() {
    const target = this.fb.group({
      type: ['', Validators.required],
      blocked: [false],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      parameterName: ['']
    });
    (this.target.controls.targets as FormArray).push(target);
    this.target.updateValueAndValidity();
  }

  deleteTarget(i: number) {
    this.target.controls.targets['controls'].splice(i, 1);
    if (this.target.controls.targets['controls'].length === 0) {
      this.groupDeleted.emit(this.index);
    }
    this.target.updateValueAndValidity();
  }
}
