import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import * as  moment from 'moment';
import {InventoryService} from '../../_services/inventory/inventory.service';
import {BrandService} from '../../_services/brand/brand.service';
import {Observable} from 'rxjs';
import {BrandCategory} from '../../_models/responses';
import {City} from '../../_models/city';
import {AllModules, Module} from '@ag-grid-enterprise/all-modules';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {NewBrandModalComponent} from '../../modals/new-brand-modal/new-brand-modal.component';
import {MarketPlaceDetailsComponent} from '../../modals/market-place-details/market-place-details.component';
import {Brand} from '../../_models/brand';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent implements OnInit {

  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
  };
  columnDefsInventory = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Name', field: 'name', width: 300, sortable: true },
    {headerName: 'Publisher', field: 'publisherName', width: 150, sortable: true },
    {headerName: 'Exchange', field: 'exchangeName', width: 150, sortable: true},
    {headerName: 'Price', field: 'price', width: 100, sortable: true, cellRenderer: (params) => '$' + parseFloat(params.node.data.price)},
    {headerName: 'Start Date', field: 'start', width: 100, sortable: true},
    {headerName: 'End Date', field: 'end', width: 100, sortable: true},
    {headerName: 'Y\'days Auctions', field: 'previousDayAuctions', width: 150, sortable: true},
    {
      headerName: 'Details',
      field: 'dealId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 150,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Deal' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
  ];
  rowDataInventory: any;
  rowSelection;
  autoGroupColumnDef;
  public modules: Module[] = AllModules;
  isRowSelectable;
  groupSelectedRows = [];
  inventoryGrid: any;
  title = 'marketplace';
  exchanges = [];
  public allCategories: BrandCategory[];
  public categoriesGroup: any;
  public categories: Observable<BrandCategory[]>;
  public createOrEdit = 'Create';
  public regions: Observable<City[]>;
  public allCountries: City[];
  public countriesGroup: any;
  public createOrEdit2 = 'Create';
  public allowedSizes = ['1x1', '3x3', '3x300', '6x6', '6x7', '6x8', '12x12', '120x20', '120x90', '120x600', '160x600', '168x28',
    '180x150', '200x200', '216x36', '220x90', '234x60', '240x400', '250x250', '250x360', '300x50', '300x60', '300x100', '300x170',
    '300x250', '300x340', '300x600', '300x1050', '320x50', '320x100', '320x180', '320x200', '320x240', '320x250', '320x320',
    '320x480', '333x333', '336x280', '386x300', '400x300', '468x60', '480x75', '480x320', '480x360', '480x480', '480x800', '600x400',
    '600x600', '631x385', '640x300', '640x480', '640x640', '687x228', '700x450', '720x300', '720x480', '728x90', '750x200', '768x768',
    '768x1024', '800x1100', '900x550', '960x30', '960x470', '970x66', '970x90', '970x250', '970x550', '980x120', '980x150', '990x200',
    '1024x768', '1030x590', '1200x627', '1200x1200', '1272x328', '1280x720', '1600x500', '1800x1000', '1920x480', '1920x1080',
    '1920x1200', '1920x1280'];

  selectedOption = [];
  selectedOption2 = [];
  selectedOption3 = [];
  selectedOption4 = [];
  selectedOptions5 = [];
  selectedOptions6 = [];
  selectedOptions7 = [];
  minPrice = '';
  maxPrice = '';
  dealName = '';

  categoriesChanged = false;

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

  @ViewChild(MarketPlaceDetailsComponent, null) newDetailsModal: MarketPlaceDetailsComponent;
  @Input() isModal = false;
  toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  country = new FormControl();
  public countries: any[];

  autoTicks = false;
  disabled = false;
  invert = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;
  vertical = false;
  ngLoading = false;
  _tickInterval = 1;

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  constructor(private inventoryService: InventoryService, private brandService: BrandService) { }

  ngOnInit() {
    this.alwaysShowCalendars = true;
    this.getExchanges();
    this.getBrandCategories();
    this.getCountries();
    this.getMarketPlace();
  }

  openDetailsModal(node) {
    if (node.column.colId === 'actions') {
      this.newDetailsModal.loadDetails(node.data);
      $('.new-brand-modal').modal('show');
    }
  }

  getMarketPlace() {
    this.ngLoading = true;
    console.log(this.selectedOption);
    console.log(this.selectedOption2);
    console.log(this.selectedOption3);
    console.log(this.selectedOption4);
    console.log(this.selectedOptions5);
    console.log(this.selectedOptions6);
    console.log(this.selectedOptions7);
    const categories = [];

    if (this.selectedOptions5.length > 0) {
      for (const cat of this.selectedOptions5) {
        if (typeof cat.parentId !== 'undefined') {
          categories.push(cat.attributeId);
        } else {
          categories.push(this.allCategories.filter(i => i.parentId === cat.attributeId).map(k => k.attributeId));
        }
      }
    }

    // let geo = [];
    // if (this.selectedOption.length > 0) {
    //   for (let aux of this.selectedOptions2) {
    //     if (typeof aux.regionCode !== 'undefined') {
    //       geo.push(aux.countryCode + '-' + aux.regionCode);
    //     } else {
    //       geo.push(aux.countryCode);
    //     }
    //   }
    // }
    //
    const params = {
      filter: this.dealName,
      inventoryTypes: ((typeof this.selectedOption4 !== 'string') ? this.selectedOption4.join(',') : ''),
      adFormats: ((typeof this.selectedOption !== 'string') ? this.selectedOption.join(',') : ''),
      deviceTypes: ((typeof this.selectedOption2 !== 'string') ? this.selectedOption2.join(',') : ''),
      exchangeIds: ((typeof this.selectedOption3 !== 'string') ? this.selectedOption3.join(',') : ''),
      categories: categories.join(','),
      countries: ((typeof this.selectedOptions6 !== 'string') ? this.selectedOptions6.join(',') : ''),
      adSizes: ((typeof this.selectedOptions7 !== 'string') ? this.selectedOptions7.join(',') : ''),
      minPrice: this.minPrice === '' ? 0 : parseFloat(this.minPrice),
      maxPrice: this.maxPrice === '' ? 10000000 : parseFloat(this.maxPrice),
    };
    this.inventoryService.getMarketPlace(params).subscribe(
      (response) => {
        console.log(response);
        this.rowDataInventory =  Observable.create((obs) => {
          obs.next(response.response.deals['results']);
          obs.complete();
        });
        this.ngLoading = false;
      });
  }

  clearFilters() {
    this.selectedOption = [];
    this.selectedOption2 = [];
    this.selectedOption3 = [];
    this.selectedOption4 = [];
    this.selectedOptions5 = [];
    this.selectedOptions6 = [];
    this.selectedOptions7 = [];
    this.dealName = '';
    this.getMarketPlace();
  }

  getExchanges() {
    this.inventoryService.getExchanges().subscribe(
      (response) => {
        console.log('exchanges:', response);
        this.exchanges = response.response.exchanges;
      });
  }

  public getBrandCategories() {
    this.brandService.getBrandCategories().subscribe(
      (response) => {
        console.log(response);
        this.allCategories = response.response.brandCategories;
        const filteredCategories = this.allCategories.filter((item, index, array) => {
          if (typeof item.parentId) {
            const indexParent = array.findIndex(elem => elem.attributeId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.categoriesGroup = (text: string, children: any[]) => {
          const group = this.allCategories.find(elem => elem.attributeId === children[0].parentId);
          return group;
        }
        this.categories = Observable.create((obs) => {
          obs.next(filteredCategories);
          obs.complete();
        });
      },
      (error) => { console.log(error); }
    )
  }

  public compareCategories = (item, selected) => {
    if (selected.attributeId && item.attributeId) {
      return item.attributeId === selected.attributeId;
    }
    return false;
  };

  public compareCountries = (item, selected) => {
    if (selected.entityId && item.entityId) {
      return item.entityId === selected.entityId;
    }
    return false;
  };

  getCountries() {
    this.inventoryService.getCities().subscribe(
      (response) => {
        console.log('countries:', response);
        let auxReponse = []; let auxIndices = [];
        for (let city of response.response.cities) {
            if (auxIndices.indexOf(city.countryCode) === -1) {
              auxIndices.push(city.countryCode);
              auxReponse.push(city);
            }
        }
        this.countries = auxReponse;
      });
  }

  inventoryGridReady(event: any) {
    this.inventoryGrid = event.api;
  }

  groupRowSelected(event: any) {
    this.groupSelectedRows = event.api.getSelectedNodes().map((value, index, array) => value.key);
    console.log(this.groupSelectedRows);
    if (this.groupSelectedRows.length > 0) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  getSelectedRows(): Array<any> {
    return this.inventoryGrid.getSelectedNodes();
  }

  getCities(params = {filter: ''}) {
    this.inventoryService.getCities(params).subscribe(
      (response) => {
        console.log(response);
        this.allCountries = response.response.cities;
        const filteredCountries = this.allCountries.filter((item, index, array) => {
          if (typeof item.parentId !== 'undefined') {
            const indexParent = array.findIndex(elem => elem.entityId === item.parentId);
            if (indexParent !== -1) {
              item['parentName'] = array[indexParent].name;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        this.countriesGroup = (text: string, children: any[]) => {
          const group = this.allCountries.find(elem => elem.entityId === children[0].parentId);
          return group;
        }
        this.regions = Observable.create((obs) => {
          obs.next(filteredCountries);
          obs.complete();
        });
      });
  }

  refreshGrid(type = 'marketplace', showArchived = false) {
    if (type === 'marketplace') {

    }
  }

  setGridSelected(rows: Array<any>): void {
    this.inventoryGrid.forEachNodeAfterFilter(node => {
      // select the node
      if (rows.indexOf(node.data.assetId) !== -1) {
        node.setSelected(true);
      }
    });
  }

  removeGridSelected(row: any): void {
    this.inventoryGrid.forEachNodeAfterFilter(node => {
      // select the node
      if (node.data.assetId === row) {
        node.setSelected(false);
        return;
      }
    });
  }

}
