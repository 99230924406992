import {Component, OnInit, ViewChild} from '@angular/core';
import * as  moment from 'moment';
import * as bootstrap from 'bootstrap';
import {Brand, BrandStats} from '../../_models/brand';
import {Observable} from 'rxjs';
import {BrandService} from '../../_services/brand/brand.service';
import {ActionsCellRendererComponent} from '../../shared/actions-cell-renderer/actions-cell-renderer.component';
import {StatusCellRendererComponent} from '../../shared/cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {TotalCellRendererComponent} from '../../shared/cell-renderers/total-cell-renderer/total-cell-renderer.component';
import {NumberFormatCellRendererComponent} from '../../shared/cell-renderers/number-format-cell-renderer/number-format-cell-renderer.component';
import {NewBrandModalComponent} from '../../modals/new-brand-modal/new-brand-modal.component';
import {RangesDatepickerComponent} from '../../shared/ranges-datepicker/ranges-datepicker.component';
import {SatDatepickerInputEvent} from 'saturn-datepicker';
import {CampaignGroup} from '../../_models/campaignGroup';
import {NewGroupModalComponent} from '../../modals/new-group-modal/new-group-modal.component';
import {GroupService} from '../../_services/group/group.service';
import {BudgetCellRendererComponent} from '../../shared/cell-renderers/budget-cell-renderer/budget-cell-renderer.component';
import {AlertService} from '../../_services/alert.service';
import {MultipleEditGroupModalComponent} from '../../modals/multiple-edit-group-modal/multiple-edit-group-modal.component';
import {AdService} from '../../_services/ad/ad.service';
import {Ad} from '../../_models/ad';
import {AdReviewComponent} from '../../shared/ad-review/ad-review.component';
import {NewImageModalComponent} from '../../modals/Ads/new-image-modal/new-image-modal.component';
import {NewMultipleImageModalComponent} from '../../modals/Ads/new-multiple-image-modal/new-multiple-image-modal.component';
import {LinkAdsModalComponent} from '../../modals/link-ads-modal/link-ads-modal.component';
import {MultipleEditAdModalComponent} from '../../modals/multiple-edit-ad-modal/multiple-edit-ad-modal.component';
import {MultipleEdit2AdModalComponent} from '../../modals/multiple-edit2-ad-modal/multiple-edit2-ad-modal.component';
import {NewAdComponent} from '../../shared/new-ad/new-ad.component';
import {NewAudioModalComponent} from '../../modals/Ads/new-audio-modal/new-audio-modal.component';
import {NewVastAudioModalComponent} from '../../modals/Ads/new-vast-audio-modal/new-vast-audio-modal.component';
import {NewVideoModalComponent} from '../../modals/Ads/new-video-modal/new-video-modal.component';
import {NewVastVideoModalComponent} from '../../modals/Ads/new-vast-video-modal/new-vast-video-modal.component';
import {NewNativeModalComponent} from '../../modals/Ads/new-native-modal/new-native-modal.component';
import {NewHtml5ModalComponent} from '../../modals/Ads/new-html5-modal/new-html5-modal.component';
import {NewTagModalComponent} from '../../modals/Ads/new-tag-modal/new-tag-modal.component';
import {NewExpandableTagModalComponent} from '../../modals/Ads/new-expandable-tag-modal/new-expandable-tag-modal.component';
import {NewMraidTagModalComponent} from '../../modals/Ads/new-mraid-tag-modal/new-mraid-tag-modal.component';
import {AudiencesService} from '../../_services/audiences/audiences.service';
import {Audience} from '../../_models/audience';
import {NewAudienceModalComponent} from '../../modals/new-audience-modal/new-audience-modal.component';
import {AudienceUploadDeviceidsComponent} from '../../modals/audience-upload-deviceids/audience-upload-deviceids.component';
import {AudienceGetTagsComponent} from '../../modals/audience-get-tags/audience-get-tags.component';
import {AudiencesBulkExportComponent} from '../../modals/audiences-bulk-export/audiences-bulk-export.component';
import {Conversion} from '../../_models/conversions';
import {ConversionService} from '../../_services/conversion/conversion.service';
import {NewConversionModalComponent} from '../../modals/new-conversion-modal/new-conversion-modal.component';
import {ConverionAudienceCaptureComponent} from '../../modals/converion-audience-capture/converion-audience-capture.component';
import {ConversionGetTagsComponent} from '../../modals/conversion-get-tags/conversion-get-tags.component';
import {ConversionBulkExportComponent} from '../../modals/conversion-bulk-export/conversion-bulk-export.component';
import {UniversalPixel} from '../../_models/universal_pixel';
import {UniversalPixelsService} from '../../_services/universal-pixels/universal-pixels.service';
import {NewUniversalPixelComponent} from '../../modals/new-universal-pixel/new-universal-pixel.component';
import {UniversalPixelTagComponent} from '../../modals/universal-pixel-tag/universal-pixel-tag.component';
import {CsvExportParams} from 'ag-grid-community';
import {GridRenderService} from '../../_services/grid-render/grid-render.service';
import {BreadcrumbsService} from '../../_services/breadcrumbs.service';
import {response} from 'express';
import { CommonAuthService } from 'app/auth/common-auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {GridColumnsCustomizationComponent} from '../../shared/grid-columns-customization/grid-columns-customization.component';
import {NewCrmAudienceModalComponent} from '../../modals/new-crm-audience-modal/new-crm-audience-modal.component';
import {CrmAudienceUploadListComponent} from '../../modals/crm-audience-upload-list/crm-audience-upload-list.component';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  ngLoading = false;
  selectedOption = '';
  rangesFooter = RangesDatepickerComponent;
  title = 'app';
  hideStatus = false;
  columnDefsBrands = [
    {
      headerName: 'Actions',
      field: 'brandId',
      colId: 'actions',
      sortable: false,
      filter: true,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Brand' },
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Status',
      field: 'archived',
      width: 90, filter: true,
      cellRenderer: 'statusCellRendererComponent',
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {headerName: 'Brand ID', field: 'brandId', width: 100},
    {headerName: 'Name', field: 'name', sort: 'asc', width: 300, pinnedRowCellRenderer: null, pinnedRowCellRendererParams: null, cellRenderer: null, sortable: true, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Imps. Won', field: 'impressionsWon', width: 100},
    {headerName: 'Win Rate', field: 'winRate', width: 100, filter: true, pinnedRowCellRendererParams: { totalType: 'percent' }, cellRendererParams: { totalType: 'percent' }},
    {headerName: 'Total eCPM', field: 'totalEffectiveCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Total eCPC', field: 'grossTotalEffectiveCPC', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Media Spend', field: 'auctionsSpend', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Media eCPM', field: 'mediaEffectiveCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Data Spend', field: 'dataSpend', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Data eCPM', field: 'dataEffectiveCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'CTC', field: 'clickThroughConversions', width: 80 },
    {headerName: 'VTC', field: 'viewthruConversions', width: 80 },
    {headerName: 'Total Conversions', field: 'totalConversions', width: 80 },
    {headerName: 'Total Revenue', field: 'revenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTC Revenue', field: 'ctcRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'VTC Revenue', field: 'vtcRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total eCPA', field: 'grossTotalEffectiveCPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total Eligible Impressions', field: 'eligibleImpressions', width: 80 },
    {headerName: 'Total Measured Impressions', field: 'measuredImpressions', width: 80 },
    {headerName: 'Total Viewable Impressions', field: 'viewableImpressions', width: 80 },
    {headerName: 'Measured Rate', field: 'measuredRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Viewable Rate', field: 'viewableRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'CTC eCPA', field: 'clickEffectiveCPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },

    {headerName: 'Click CVR', field: 'clickCVR', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'View CVR', field: 'viewCVR', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total CVRM', field: 'totalCVRM', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'EPC', field: 'profitPerClick', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Total RPM', field: 'revenuePerMille', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Click RPM', field: 'clickRPM', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'ROAS', field: 'returnOnAdSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Audio/Video Started', field: 'videoStarted', width: 80 },
    {headerName: '25% Complete', field: 'videoFirstQuartileReached', width: 80 },
    {headerName: '50% Complete', field: 'videoMidpointReached', width: 80 },
    {headerName: '75% Complete', field: 'videoThirdQuartileReached', width: 80 },
    {headerName: '100% Complete', field: 'videoCompleted', width: 80 },
    {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
    {headerName: 'Total eCPCV', field: 'grossTotalEffectiveCPCV', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},

    // {headerName: 'CPM', field: 'totalEffectiveCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'CPC', field: 'costPerClick', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Adv. Spend eCPC', field: 'effectiveCPCOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Spend', field: 'totalSpend', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Clicks', field: 'clicks', width: 100},
    {headerName: 'Companion Impressions Won', field: 'companionImpressions', width: 80 },
    {headerName: 'Companion Clicks', field: 'companionClicks', width: 80 },
    {headerName: 'Companion CTC', field: 'companionConversions', width: 80 },
    {headerName: 'Companion CTC Revenue', field: 'companionOfferRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'CTR', field: 'clickthruRate', width: 100, pinnedRowCellRendererParams: { totalType: 'percent' }, cellRendererParams: { totalType: 'percent' }},
    {headerName: 'CPA', field: 'viewEffectiveCPA', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Eligible Spend', field: 'eligibleSpend', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Eligible vCPM', field: 'eligibleVCPM', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
    {headerName: 'Primary Adv. Spend eCPA', field: 'primaryAdvSpendECPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Adv. Spend eCPA', field: 'effectiveCPAOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Primary CTC', field: 'primaryClickthruConversions', width: 80, },
    {headerName: 'Primary VTC', field: 'primaryViewthruConversions', width: 80 },

    {headerName: 'Primary Conv.', field: 'primaryConversion', width: 80 },
    {headerName: 'Primary Conv. eCPA', field: 'primaryCostPerAcquisition', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    {headerName: 'Primary CTC eCPA', field: 'primaryEffectiveCPACTC', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
  ];
  rowDataBrands: Observable<Array<BrandStats>>;
  defaultBrandsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true,
    cellRenderer: 'numberFormatCellRendererComponent',
    cellRendererParams: { totalType: 'quantity' },
    pinnedRowCellRenderer: 'totalCellRendererComponent',
    pinnedRowCellRendererParams: { totalType: 'quantity' },
    comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA - valueB)
  };
  pinnedBrandsRowData: any;
  @ViewChild(NewBrandModalComponent, null) newBrandModal: NewBrandModalComponent;
  brandDateRange: any = {begin: moment(), end: moment()};
  brandFilterString = '';
  brandIdSelected: number = null;
  brandSelected: Brand = null;

  columnDefsGroups = [];
  rowDataGroups: Observable<Array<CampaignGroup>>;
  pinnedGroupsRowData: any = [];
  @ViewChild(NewGroupModalComponent, null) newGroupModal: NewGroupModalComponent;
  @ViewChild(MultipleEditGroupModalComponent, null) multipleEditGroupModal: MultipleEditGroupModalComponent;
  groupDateRange: any = {begin: moment(), end: moment()};
  audiencesDateRange: any = {begin: moment(), end: moment()};
  audiencesCrmDateRange: any = {begin: moment(), end: moment()};
  groupFilterString = '';
  groupSelectedRows = [];

  columnDefsAds = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Actions',
      field: 'creativeId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 90,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'save' }
    },
    {headerName: 'Ad Name', sort: 'asc', field: 'label', width: 200, cellRenderer: '', cellRendererParams: null, sortable: true, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Status', field: 'status', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'Review', field: 'reviewStatus', width: 120, cellRenderer: 'adReviewComponent' },
    {headerName: 'Ad Vault Path', field: 'vaultPath', width: 80 },
    {headerName: 'Size', field: 'width', width: 80, cellRenderer: (params) => (params.node.data.width === 0 || params.node.data.height === 0 ? '-' : params.node.data.width + 'x' +  params.node.data.height) },
    {headerName: 'SSL Status', field: 'sslEnabled', width: 120, cellRenderer: (params) => (params.node.data.sslEnabled) ? 'Secured' : 'Not Secured' },
    {headerName: 'Preview', field: 'previewUrl', width: 200, cellRenderer: (params) => '<a traget="_blank" href="' + params.node.data.previewUrl + '">Go To Preview</a>' },
    {headerName: 'Click URL', field: 'clickUrl', width: 400, cellRenderer: (params) => '<a traget="_blank" href="' + params.node.data.clickUrl + '">' + params.node.data.clickUrl + '</a>' }
  ];
  rowDataAds: Observable<Array<Ad>>;
  defaultAdsColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true
  };
  selectedAdOption = '';
  adSearchString = '';
  @ViewChild(NewImageModalComponent, null) newImageAdModal: NewImageModalComponent;
  @ViewChild(NewMultipleImageModalComponent, null) newMultipleImageAdModal: NewMultipleImageModalComponent;
  @ViewChild(NewAudioModalComponent, null) newAudioAdModal: NewAudioModalComponent;
  @ViewChild(NewVastAudioModalComponent, null) newVastAudioAdModal: NewVastAudioModalComponent;
  @ViewChild(NewVideoModalComponent, null) newVideoAdModal: NewVideoModalComponent;
  @ViewChild(NewVastVideoModalComponent, null) newVastVideoAdModal: NewVastVideoModalComponent;
  @ViewChild(NewNativeModalComponent, null) newNativeAdModal: NewNativeModalComponent;
  @ViewChild(NewHtml5ModalComponent, null) newHtml5AdModal: NewHtml5ModalComponent;
  @ViewChild(NewTagModalComponent, null) newTagAdModal: NewTagModalComponent;
  @ViewChild(NewExpandableTagModalComponent, null) newExpandableTagAdModal: NewExpandableTagModalComponent;
  @ViewChild(NewMraidTagModalComponent, null) newMraidTagAdModal: NewMraidTagModalComponent;
  adSelectedRows: (bigint | number)[] = [];
  @ViewChild(LinkAdsModalComponent, null) linkCampAdModal: LinkAdsModalComponent;
  @ViewChild(MultipleEditAdModalComponent, null) multiEditAdModal: MultipleEditAdModalComponent;
  @ViewChild(MultipleEdit2AdModalComponent, null) multiEdit2AdModal: MultipleEdit2AdModalComponent;
  @ViewChild(NewAdComponent, null) newAd: NewAdComponent;
  gridApiAds: any;

  columnDefsAudience = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Actions',
      field: 'audienceId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Audience' }
    },
    {headerName: 'Status', field: 'visible', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'Name', sort: 'asc', field: 'name', width: 400, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Number of Hits', field: 'totalVisitors', width: 120 },
    {headerName: 'Visitors Added', field: 'newVisitors', width: 700 },
  ];

  columnDefsCrmAudience = [
    {
      headerName: 'Actions',
      field: 'audienceId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Crm Audience' }
    },
    {headerName: 'Status', field: 'visible', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'Name', sort: 'asc', field: 'name', width: 400, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Matches', field: 'newVisitors', width: 120 },
    {headerName: 'Audience Size', field: 'totalVisitors', width: 120 },
    {headerName: 'Last Updated', field: 'lastUploadedData', width: 120, cellRenderer: (params) => typeof params.value !== 'undefined' && params.value !== null && params.value !== '' ? moment(params.value).format('MM/DD/YYYY') : '-'},
  ];


  rowDataAudience: Observable<Array<Audience>>;
  rowDataCrmAudience: Observable<Array<Audience>>;
  defaultAudienceColDefs = {
    width: 100,
    filter: true,
    sortable: true,
    resizable: true
  };
  selectedAudienceOption = '';
  selectedCrmAudienceOption = '';
  audienceSearchString = '';
  audienceSelectedRows: (bigint | number)[] = [];
  @ViewChild(NewAudienceModalComponent, null) newAudienceModal: NewAudienceModalComponent;
  @ViewChild(NewCrmAudienceModalComponent, null) newCrmAudienceModal: NewCrmAudienceModalComponent;
  @ViewChild(AudienceUploadDeviceidsComponent, null) audienceUploadDeviceids: AudienceUploadDeviceidsComponent;
  @ViewChild(CrmAudienceUploadListComponent, null) crmAudienceUploadList: CrmAudienceUploadListComponent;
  @ViewChild(AudienceGetTagsComponent, null) audienceGetTags: AudienceGetTagsComponent;
  @ViewChild(AudiencesBulkExportComponent, null) audiencesBulkExport: AudiencesBulkExportComponent;
  audienceFilterString = '';
  audienceCrmFilterString = '';

  columnDefsConversion = [
    {
      headerName: '',
      colId: 'checkboxSelect',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      sortable: false,
      resizable: false,
      filter: false,
      cellRenderer: '',
      cellRendererParams: null,
      pinnedRowCellRenderer: '',
      pinnedRowCellRendererParams: null
    },
    {
      headerName: 'Actions',
      field: 'offerId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Conversion' }
    },
    {headerName: 'Status', sort: 'asc', field: 'deleted', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'Name', field: 'name', width: 200, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Default Value', field: 'defaultRevenue', width: 120, cellRenderer: 'numberFormatCellRendererComponent', cellRendererParams: { totalType: 'money' }},
    {headerName: 'Number of Hits', field: 'rawConversionRequests', width: 120 },
    {headerName: 'Conversions', field: 'attributedConversionRequests', width: 120 }
  ];
  rowDataConversion: Observable<Array<Conversion>>;
  conversionsSelectedRows: (bigint | number)[] = [];
  selectedConversionOption = '';
  conversionsDateRange: any = {begin: moment(), end: moment()};
  conversionSearchString = '';
  @ViewChild(NewConversionModalComponent, null) newConversionModal: NewConversionModalComponent;
  @ViewChild(ConverionAudienceCaptureComponent, null) conversionAudienceCapture: ConverionAudienceCaptureComponent;
  @ViewChild(ConversionGetTagsComponent, null) conversionGetTags: ConversionGetTagsComponent;
  @ViewChild(ConversionBulkExportComponent, null) conversionBulkExport: ConversionBulkExportComponent;

  columnDefsPixels = [
    {
      headerName: 'Actions',
      field: 'universalPixelId',
      colId: 'actions',
      sortable: false,
      filter: false,
      width: 110,
      cellRenderer: 'actionsCellRendererComponent',
      cellRendererParams: { renderType: 'Universal' }
    },
    {headerName: 'Status', field: 'deleted', width: 80, cellRenderer: 'statusCellRendererComponent' },
    {headerName: 'Name', sort: 'asc', field: 'name', width: 250, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => (valueA.toLowerCase() > valueB.toLowerCase() ? 1 : -1) },
    {headerName: 'Number of Hits', field: 'rawRequests', width: 200 }
  ];
  rowDataPixels: Observable<Array<UniversalPixel>>;
  universalSelectedRows: (bigint | number)[] = [];
  selectedUniversalOption = 'false';
  universalDateRange: any = {begin: moment(), end: moment()};
  universalSearchString = '';
  @ViewChild(NewUniversalPixelComponent, null) newUniversalPixel: NewUniversalPixelComponent;
  @ViewChild(UniversalPixelTagComponent, null) universalPixelTag: UniversalPixelTagComponent;

  frameworkComponents = {
    actionsCellRendererComponent: ActionsCellRendererComponent,
    statusCellRendererComponent: StatusCellRendererComponent,
    totalCellRendererComponent: TotalCellRendererComponent,
    numberFormatCellRendererComponent: NumberFormatCellRendererComponent,
    budgetCellRendererComponent: BudgetCellRendererComponent,
    adReviewComponent: AdReviewComponent
  };

  private gridColumns: {
    brands: Array<{text: string, id: string, child: Array<{text: string, id: string}>}>,
    groups: Array<{text: string, id: string, child: Array<{text: string, id: string}>}>
  } = {
      brands: [
        {
          text: 'Auctions',
          id: 'auctions',
          child: [
            {text: 'Imp. Won', id: 'impressionsWon'},
            {text: 'Win Rate', id: 'winRate'},
            {text: 'Total eCPM', id: 'totalEffectiveCPM'},
            {text: 'Adv. Spend eCPM', id: 'effectiveCPMOnAdvertiserSpend'},
            {text: 'Media eCPM', id: 'mediaEffectiveCPM'},
            {text: 'Data eCPM', id: 'dataEffectiveCPM'}
          ]
        },
        {
          text: 'Audio/Video',
          id: 'audio_video',
          child: [
            {text: 'Audio/Video Starts', id: 'videoStarted'},
            {text: '25% Complete', id: 'videoFirstQuartileReached'},
            {text: '50% Complete', id: 'videoMidpointReached'},
            {text: '75% Complete', id: 'videoThirdQuartileReached'},
            {text: '100% Complete', id: 'videoCompleted'},
            {text: 'Completion Rate', id: 'videoCompletionRate'},
            {text: 'Adv. Spend eCPCV', id: 'effectiveCPCVOnAdvertiserSpend'},
          ]
        },
        {
          text: 'Budget',
          id: 'budget',
          child: [
            {text: 'Total Spend', id: 'totalSpend'},
            {text: 'CPM Bid', id: 'impliedBid'},
            {text: 'Daily Budget', id: 'dailyTotalSpendTarget'},
            {text: 'All Time Budget', id: 'budget'},
            {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
            {text: 'Adv. Spend', id: 'advertiserSpend'},
            {text: 'Media Spend', id: 'auctionsSpend'},
            {text: 'Data Spend', id: 'dataSpend'},
          ]
        },
        {
          text: 'Clicks',
          id: 'clicks',
          child: [
            {text: 'Clicks', id: 'clicks'},
            {text: 'Adv. Spend eCPC', id: 'effectiveCPCOnAdvertiserSpend'},
            {text: 'Total eCPC', id: 'grossTotalEffectiveCPC'},
            {text: 'Total eCPCV', id: 'grossTotalEffectiveCPCV'},
            {text: 'CTR', id: 'clickthruRate'},
            // {text: 'CPM', id: 'totalEffectiveCPM'},
            {text: 'CPC', id: 'costPerClick'},
            {text: 'EPC', id: 'profitPerClick'},
            {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
            {text: 'Adv. Spend', id: 'advertiserSpend'},
            {text: 'Media Spend', id: 'auctionsSpend'},
            {text: 'CPA', id: 'viewEffectiveCPA'},
          ]
        },
        {
          text: 'Companion Ads',
          id: 'companion_ads',
          child: [
            {text: 'Companion Imps. Won', id: 'companionImpressions'},
            {text: 'Companion Clicks', id: 'companionClicks'},
            {text: 'Companion CTC', id: 'companionConversions'},
            {text: 'Companion CTC Revenue', id: 'companionOfferRevenue'}
          ]
        },
        {
          text: 'Conversions',
          id: 'conversions',
          child: [
            {text: 'Total Conversions', id: 'totalConversions'},
            {text: 'CTC', id: 'clickThroughConversions'},
            {text: 'VTC', id: 'viewthruConversions'},
            {text: 'Adv. Spend eCPA', id: 'effectiveCPAOnAdvertiserSpend'},
            {text: 'Total eCPA', id: 'grossTotalEffectiveCPA'},
            {text: 'CTC eCPA', id: 'clickEffectiveCPA'},
            {text: 'Click CVR', id: 'clickCVR'},
            {text: 'View CVR', id: 'viewCVR'},
            {text: 'Total CVRM', id: 'totalCVRM'},
          ]
        },
        {
          text: 'Extras',
          id: 'extras',
          child: [
            {text: 'Brand ID', id: 'brandId'}
          ]
        },
        {
          text: 'Primary Conversions',
          id: 'primary_conversions',
          child: [
            {text: 'Primary Conv.', id: 'conversions'},
            {text: 'Primary CTC', id: 'primaryClickthruConversions'},
            {text: 'Primary VTC', id: 'primaryViewthruConversions'},
            {text: 'Primary Adv. Spend eCPA', id: 'primaryAdvSpendECPA'},
            {text: 'Primary Conv. eCPA', id: 'primaryEffectiveCPAConversion'},
            {text: 'Primary CTC eCPA', id: 'primaryEffectiveCPACTC'},
          ]
        },
        {
          text: 'Revenue',
          id: 'revenues',
          child: [
            {text: 'Total Revenue', id: 'revenue'},
            {text: 'CTC Revenue', id: 'ctcRevenue'},
            {text: 'VTC Revenue', id: 'vtcRevenue'},
            {text: 'Total RPM', id: 'revenuePerMille'},
            {text: 'Click RPM', id: 'clickRPM'},
            {text: 'ROAS', id: 'returnOnAdSpend'},
          ]
        },
        {
          text: 'Viewability',
          id: 'viewability',
          child: [
            {text: 'Total Eligible Imps.', id: 'eligibleImpressions'},
            {text: 'Total Measured Imps.', id: 'measuredImpressions'},
            {text: 'Total Viewable Imps.', id: 'viewableImpressions'},
            {text: 'Measured Rate', id: 'measuredRate'},
            {text: 'Viewable Rate', id: 'viewableRate'},
            {text: 'Eligible Spend', id: 'eligibleSpend'},
            {text: 'Eligible vCPM', id: 'eligibleVCPM'},
          ]
        },
      ],
      groups: [
        {
          text: 'Auctions',
          id: 'auctions',
          child: [
            {text: 'Imp. Won', id: 'impressionsWon'},
            {text: 'Win Rate', id: 'winRate'},
            {text: 'Total eCPM', id: 'totalEffectiveCPM'},
            {text: 'Adv. Spend eCPM', id: 'effectiveCPMOnAdvertiserSpend'},
            {text: 'Media eCPM', id: 'mediaEffectiveCPM'},
            {text: 'Data eCPM', id: 'dataEffectiveCPM'}
          ]
        },
        {
          text: 'Audio/Video',
          id: 'audio_video',
          child: [
            {text: 'Audio/Video Starts', id: 'videoStarted'},
            {text: '25% Complete', id: 'videoFirstQuartileReached'},
            {text: '50% Complete', id: 'videoMidpointReached'},
            {text: '75% Complete', id: 'videoThirdQuartileReached'},
            {text: '100% Complete', id: 'videoCompleted'},
            {text: 'Completion Rate', id: 'videoCompletionRate'},
            {text: 'Adv. Spend eCPCV', id: 'effectiveCPCVOnAdvertiserSpend'},
            {text: 'Total eCPCV', id: 'totalEffectiveCPCV'},
          ]
        },
        {
          text: 'Budget',
          id: 'budget',
          child: [
            {text: 'Total Spend', id: 'totalSpend'},
            {text: 'CPM Bid', id: 'impliedBid'},
            {text: 'Daily Budget', id: 'dailyTotalSpendTarget'},
            {text: 'All Time Budget', id: 'budget'},
            {text: 'GBO Daily Budget', id: 'gboDailyTotalSpendTarget'},
            {text: 'Adv. Spend', id: 'advertiserSpend'},
            {text: 'Media Spend', id: 'auctionsSpend'},
            {text: 'Data Spend', id: 'dataSpend'},
            {text: 'Actual Margin %', id: 'marginOnAdvertiserSpend'},
            {text: 'Max Billable', id: 'advertiserSpendRate'}
          ]
        },
        {
          text: 'Clicks',
          id: 'clicks',
          child: [
            {text: 'Clicks', id: 'clicks'},
            {text: 'Adv. Spend eCPC', id: 'effectiveCPCOnAdvertiserSpend'},
            {text: 'Total eCPC', id: 'grossTotalEffectiveCPC'},
            {text: 'CTR', id: 'clickthruRate'},
            {text: 'EPC', id: 'profitPerClick'},
          ]
        },
        {
          text: 'Companion Ads',
          id: 'companion_ads',
          child: [
            {text: 'Companion Imps. Won', id: 'companionImpressions'},
            {text: 'Companion Clicks', id: 'companionClicks'},
            {text: 'Companion CTC', id: 'companionConversions'},
            {text: 'Companion CTC Revenue', id: 'companionOfferRevenue'}
          ]
        },
        {
          text: 'Conversions',
          id: 'conversions',
          child: [
            {text: 'Total Conversions', id: 'totalConversions'},
            {text: 'Primary Conversions', id: 'primaryConversion'},
            {text: 'CTC', id: 'clickThroughConversions'},
            {text: 'VTC', id: 'viewthruConversions'},
            {text: 'Adv. Spend eCPA', id: 'effectiveCPAOnAdvertiserSpend'},
            {text: 'Primary Adv. Spend eCPA', id: 'primaryAdvSpendECPA'},
            {text: 'Total eCPA', id: 'grossTotalEffectiveCPA'},
            {text: 'CTC eCPA', id: 'clickEffectiveCPA'},
            {text: 'Click CVR', id: 'clickCVR'},
            {text: 'View CVR', id: 'viewCVR'},
            {text: 'Total CVRM', id: 'totalCVRM'},
          ]
        },
        {
          text: 'Extras',
          id: 'extras',
          child: [
            {text: 'Group ID', id: 'campaignGroupId'},
            {text: 'KPI Metric', id: 'kpiType'},
            {text: 'KPI Value', id: 'kpiValue'}
          ]
        },
        {
          text: 'Primary Conversions',
          id: 'primary_conversions',
          child: [
            {text: 'Primary Conv.', id: 'conversions'},
            {text: 'Primary CTC', id: 'primaryClickthruConversions'},
            {text: 'Primary VTC', id: 'primaryViewthruConversions'},
            {text: 'Primary Adv. Spend eCPA', id: 'primaryEffectiveCPASpend'},
            {text: 'Primary Conv. eCPA', id: 'primaryCostPerAcquisition'},
            {text: 'Primary CTC eCPA', id: 'primaryEffectiveCPACTC'},
          ]
        },
        {
          text: 'Revenue',
          id: 'revenues',
          child: [
            {text: 'Total Revenue', id: 'revenue'},
            {text: 'CTC Revenue', id: 'ctcRevenue'},
            {text: 'VTC Revenue', id: 'vtcRevenue'},
            {text: 'Total RPM', id: 'revenuePerMille'},
            {text: 'Click RPM', id: 'clickRPM'},
            {text: 'ROAS', id: 'returnOnAdSpend'},
          ]
        },
        {
          text: 'Viewability',
          id: 'viewability',
          child: [
            {text: 'Total Eligible Imps.', id: 'eligibleImpressions'},
            {text: 'Total Measured Imps.', id: 'measuredImpressions'},
            {text: 'Total Viewable Imps.', id: 'viewableImpressions'},
            {text: 'Measured Rate', id: 'measuredRate'},
            {text: 'Viewable Rate', id: 'viewableRate'},
            {text: 'Eligible Spend', id: 'eligibleSpend'},
            {text: 'Eligible vCPM', id: 'eligibleVCPM'},
          ]
        },
      ]
  };

  selected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  brandNameSelected = '';
  currentTab = 0;
  compParent = {componentParent: this};
  brandsGrid: any;
  groupsGrid: any;
  audiencesGrid: any;
  audiencesCrmGrid: any;
  conversionsGrid: any;
  pixelsGrid: any;
  archivedShow = true;
  rangeDatesFlag = false;
  filtersActive = {
    brand: false,
    group: false,
    ad: false,
    audience: false,
    crmAudience: false,
    conversion: false,
    universal: false
  };
  columnSetup = {
    brands: ['impressionsWon', 'totalEffectiveCPM', 'totalSpend', 'grossTotalEffectiveCPC', 'clicks', 'clickthruRate', 'totalConversions',
      'grossTotalEffectiveCPA', 'eligibleImpressions', 'measuredImpressions', 'viewableImpressions', 'measuredRate', 'viewableRate',
      'clickCVR', 'totalCVRM', 'videoStarted', 'videoCompleted', 'videoCompletionRate', 'totalEffectiveCPCV', 'eligibleSpend',
      'eligibleVCPM', 'effectiveCPAOnAdvertiserSpend', 'primaryEffectiveCPASpend', 'conversions', 'primaryCostPerAcquisition'],
    groups: ['kpiType', 'kpiValue', 'advertiserSpendRate', 'impressionsWon', 'clicks', 'totalConversions', 'conversions',
      'eligibleImpressions', 'measuredImpressions', 'viewableImpressions', 'measuredRate', 'viewableRate', 'eligibleSpend',
      'eligibleVCPM', 'advertiserSpend', 'marginOnAdvertiserSpend', 'effectiveCPMOnAdvertiserSpend', 'totalEffectiveCPM',
      'effectiveCPCOnAdvertiserSpend', 'grossTotalEffectiveCPC', 'clickthruRate', 'effectiveCPAOnAdvertiserSpend',
      'primaryEffectiveCPASpend', 'grossTotalEffectiveCPA', 'primaryCostPerAcquisition', 'clickCVR', 'totalCVRM', 'videoStarted',
      'videoCompleted', 'videoCompletionRate', 'effectiveCPCVOnAdvertiserSpend', 'totalEffectiveCPCV']
  };
  columnSetupDefault = {
    brands: ['impressionsWon', 'totalEffectiveCPM', 'totalSpend', 'grossTotalEffectiveCPC', 'clicks', 'clickthruRate', 'totalConversions',
      'grossTotalEffectiveCPA', 'eligibleImpressions', 'measuredImpressions', 'viewableImpressions', 'measuredRate', 'viewableRate',
      'clickCVR', 'totalCVRM', 'videoStarted', 'videoCompleted', 'videoCompletionRate', 'totalEffectiveCPCV', 'eligibleSpend',
      'eligibleVCPM', 'effectiveCPAOnAdvertiserSpend', 'primaryEffectiveCPASpend', 'conversions', 'primaryCostPerAcquisition'],
    groups: ['kpiType', 'kpiValue', 'advertiserSpendRate', 'impressionsWon', 'clicks', 'totalConversions', 'conversions',
      'eligibleImpressions', 'measuredImpressions', 'viewableImpressions', 'measuredRate', 'viewableRate', 'eligibleSpend',
      'eligibleVCPM', 'advertiserSpend', 'marginOnAdvertiserSpend', 'effectiveCPMOnAdvertiserSpend', 'totalEffectiveCPM',
      'effectiveCPCOnAdvertiserSpend', 'grossTotalEffectiveCPC', 'clickthruRate', 'effectiveCPAOnAdvertiserSpend',
      'primaryEffectiveCPASpend', 'grossTotalEffectiveCPA', 'primaryCostPerAcquisition', 'clickCVR', 'totalCVRM', 'videoStarted',
      'videoCompleted', 'videoCompletionRate', 'effectiveCPCVOnAdvertiserSpend', 'totalEffectiveCPCV']
  };
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  currentDateScope = 'one';
  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  };
  currentUser;

  constructor(
    private brandService: BrandService,
    private groupService: GroupService,
    private alertService: AlertService,
    private adsService: AdService,
    private audienceService: AudiencesService,
    private conversionsService: ConversionService,
    private univService: UniversalPixelsService,
    private gridRender: GridRenderService,
    private breadcrumbsService: BreadcrumbsService,
    private auth: CommonAuthService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.alwaysShowCalendars = true;
    this.initializeRangeDates();
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    const brandsCustomColumns = localStorage.getItem('brandsCustomColumns');
    const groupsCustomColumns = localStorage.getItem('groupsCustomColumns');
    if (brandsCustomColumns !== null) {
      this.columnSetup['brands'] = JSON.parse(brandsCustomColumns);
    }
    if (groupsCustomColumns !== null) {
      this.columnSetup['groups'] = JSON.parse(groupsCustomColumns);
    }
    this.hideInitialStatus();
    this.breadcrumbsService.breadcrumbs = [
      {label: 'My Brands', url: '/mybrands', params: []},
    ];
    this.selectedOption = 'online,offline';
    this.selectedAdOption = 'online,offline';
    this.refreshGrid();
    this.rowDataGroups = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataAds = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataAudience = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataConversion = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataPixels = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataAudience = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataConversion = Observable.create((obs) => {
      obs.next([]);
      obs.complete();
    });
    this.rowDataBrands = new Observable((obs) => {
      obs.next([]);
      obs.complete();
    });
  }

  initializeRangeDates() {
    const savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
    if (savedGridDates !== null && typeof savedGridDates === 'object') {
      if (typeof savedGridDates.brandDateRange !== 'undefined') {
        this.brandDateRange = {begin: moment(savedGridDates.brandDateRange.begin), end: moment(savedGridDates.brandDateRange.end)};
      }
      if (typeof savedGridDates.groupDateRange !== 'undefined') {
        this.groupDateRange = {begin: moment(savedGridDates.groupDateRange.begin), end: moment(savedGridDates.groupDateRange.end)};
      }
      if (typeof savedGridDates.audiencesDateRange !== 'undefined') {
        this.audiencesDateRange = {begin: moment(savedGridDates.audiencesDateRange.begin), end: moment(savedGridDates.audiencesDateRange.end)};
      }
      if (typeof savedGridDates.audiencesCrmDateRange !== 'undefined') {
        this.audiencesCrmDateRange = {begin: moment(savedGridDates.audiencesCrmDateRange.begin), end: moment(savedGridDates.audiencesCrmDateRange.end)};
      }
      if (typeof savedGridDates.conversionsDateRange !== 'undefined') {
        this.conversionsDateRange = {begin: moment(savedGridDates.conversionsDateRange.begin), end: moment(savedGridDates.conversionsDateRange.end)};
      }
      if (typeof savedGridDates.universalDateRange !== 'undefined') {
        this.universalDateRange = {begin: moment(savedGridDates.universalDateRange.begin), end: moment(savedGridDates.universalDateRange.end)};
      }
    }
  }

  hideInitialStatus() {
    if (this.currentUser.role !== 'ADMIN') {
      const userCients = this.currentUser.clients.map(i => {
        if (i.status === 0 || i.status === '0') {
            this.hideStatus = true;
            return;
        }
      });
    }
  }

  getBrands(params = {filter: '', dateTo: '', dateFrom: '', showArchived: false}) {
    this.ngLoading = true;
    this.brandService.getBrands(params).subscribe(
      (response) => {
        const pinnedData = {
          impressionsWon: 0,
          winRate: 0,
          totalEffectiveCPM: 0,
          totalEffectiveCPCV: 0,
          totalSpend: 0,
          clicks: 0,
          clickThroughRate: 0,
          totalConversions: 0,
          viewEffectiveCPA: 0,
          returnOnAdSpend: 0,
          viewabilitySpend: 0,
          costPerAcquisition: 0,
          companionConversions: 0,
          costPerClick: 0,
          impressionsBid: 0,
          revenue: 0,
          ctcRevenue: 0,
          vtcRevenue: 0,
          grossTotalEffectiveCPA: 0,
          eligibleImpressions: 0,
          measuredImpressions: 0,
          viewableImpressions: 0,
          measuredRate: 0,
          viewableRate: 0,
          clickEffectiveCPA: 0,
          profitPerClick: 0,
          revenuePerMille: 0,
          clickRPM: 0,
          videoStarted: 0,
          videoFirstQuartileReached: 0,
          videoMidpointReached: 0,
          videoThirdQuartileReached: 0,
          videoCompleted: 0,
          eligibleSpend: 0,
          companionImpressions: 0,
          companionClicks: 0,
          companionOfferRevenue: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          primaryClickthruConversions: 0,
          primaryViewthruConversions: 0,
          primaryConversion: 0,
          clickThroughConversions: 0,
          viewthruConversions: 0,
          grossTotalEffectiveCPC: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          clickthruRate: 0
        };
        if (typeof response.response.brands !== 'undefined' && response.response.brands !== null) {
          for (const i in response.response.brands) {
            // const margin = (typeof response.response.brands[i]['extraData'] !== 'undefined' && response.response.brands[i]['extraData'].margin > 1) ?
            //   ((100 - response.response.brands[i]['extraData'].margin) / 100) : 1;

            const margin = 1;

            response.response.brands[i].totalEffectiveCPM = isNaN(response.response.brands[i].totalEffectiveCPM) ? 0 : response.response.brands[i].totalEffectiveCPM / margin;
            response.response.brands[i].totalEffectiveCPCV = isNaN(response.response.brands[i].totalEffectiveCPCV) ? 0 : response.response.brands[i].totalEffectiveCPCV / margin;
            response.response.brands[i].totalSpend = isNaN(response.response.brands[i].totalSpend) ? 0 : response.response.brands[i].totalSpend / margin;
            response.response.brands[i].viewEffectiveCPA = isNaN(response.response.brands[i].viewEffectiveCPA) ? 0 : response.response.brands[i].viewEffectiveCPA / margin;
            response.response.brands[i].returnOnAdSpend = isNaN(response.response.brands[i].returnOnAdSpend) ? 0 : response.response.brands[i].returnOnAdSpend / margin;
            response.response.brands[i].viewabilitySpend = isNaN(response.response.brands[i].viewabilitySpend) ? 0 : response.response.brands[i].viewabilitySpend / margin;
            response.response.brands[i].costPerAcquisition = isNaN(response.response.brands[i].costPerAcquisition) ? 0 : response.response.brands[i].costPerAcquisition / margin;
            response.response.brands[i].auctionsSpend = isNaN(response.response.brands[i].auctionsSpend) ? 0 : response.response.brands[i].auctionsSpend / margin;

            pinnedData.impressionsWon += isNaN(response.response.brands[i].impressionsWon) ? 0 : response.response.brands[i].impressionsWon;
            pinnedData.impressionsBid += isNaN(response.response.brands[i].impressionsBid) ? 0 : response.response.brands[i].impressionsBid;
            pinnedData.winRate += isNaN(response.response.brands[i].winRate) ? 0 : response.response.brands[i].winRate;
            // pinnedData.totalEffectiveCPM += isNaN(response.response.brands[i].totalEffectiveCPM) ? 0 : response.response.brands[i].totalEffectiveCPM;
            pinnedData.totalEffectiveCPCV += isNaN(response.response.brands[i].totalEffectiveCPCV) ? 0 : response.response.brands[i].totalEffectiveCPCV;
            pinnedData.totalSpend += isNaN(response.response.brands[i].totalSpend) ? 0 : response.response.brands[i].totalSpend;
            pinnedData.clicks += isNaN(response.response.brands[i].clicks) ? 0 : response.response.brands[i].clicks;
            pinnedData.clickThroughRate += isNaN(response.response.brands[i].clickThroughRate) ? 0 : response.response.brands[i].clickThroughRate;
            pinnedData.viewEffectiveCPA += isNaN(response.response.brands[i].viewEffectiveCPA) ? 0 : response.response.brands[i].viewEffectiveCPA;
            pinnedData.returnOnAdSpend += isNaN(response.response.brands[i].returnOnAdSpend) ? 0 : response.response.brands[i].returnOnAdSpend;
            pinnedData.viewabilitySpend += isNaN(response.response.brands[i].viewabilitySpend) ? 0 : response.response.brands[i].viewabilitySpend;
            pinnedData.costPerAcquisition += isNaN(response.response.brands[i].costPerAcquisition) ? 0 : response.response.brands[i].costPerAcquisition;
            pinnedData.companionConversions += isNaN(response.response.brands[i].companionConversions) ? 0 : response.response.brands[i].companionConversions;
            pinnedData.totalConversions += isNaN(response.response.brands[i].totalConversions) ? 0 : response.response.brands[i].totalConversions;
            pinnedData.ctcRevenue += isNaN(response.response.brands[i].ctcRevenue) ? 0 : response.response.brands[i].ctcRevenue;
            pinnedData.vtcRevenue += isNaN(response.response.brands[i].vtcRevenue) ? 0 : response.response.brands[i].vtcRevenue;
            response.response.brands[i]['grossTotalEffectiveCPA'] = isNaN(response.response.brands[i]['grossTotalEffectiveCPA']) ? 0 : response.response.brands[i]['grossTotalEffectiveCPA'] / margin;
            // pinnedData.grossTotalEffectiveCPA += isNaN(response.response.brands[i]['grossTotalEffectiveCPA']) ? 0 : response.response.brands[i]['grossTotalEffectiveCPA'];
            pinnedData.eligibleImpressions += isNaN(response.response.brands[i].eligibleImpressions) ? 0 : response.response.brands[i].eligibleImpressions;
            pinnedData.measuredImpressions += isNaN(response.response.brands[i].measuredImpressions) ? 0 : response.response.brands[i].measuredImpressions;
            pinnedData.viewableImpressions += isNaN(response.response.brands[i].viewableImpressions) ? 0 : response.response.brands[i].viewableImpressions;
            pinnedData.measuredRate += isNaN(response.response.brands[i].measuredRate) ? 0 : response.response.brands[i].measuredRate;
            pinnedData.viewableRate += isNaN(response.response.brands[i].viewableRate) ? 0 : response.response.brands[i].viewableRate;
            pinnedData.clickEffectiveCPA += isNaN(response.response.brands[i].clickEffectiveCPA) ? 0 : response.response.brands[i].clickEffectiveCPA;
            pinnedData.profitPerClick += isNaN(response.response.brands[i].profitPerClick) ? 0 : response.response.brands[i].profitPerClick;
            pinnedData.revenuePerMille += isNaN(response.response.brands[i].revenuePerMille) ? 0 : response.response.brands[i].revenuePerMille;
            pinnedData.clickRPM += isNaN(response.response.brands[i].clickRPM) ? 0 : response.response.brands[i].clickRPM;
            pinnedData.videoStarted += isNaN(response.response.brands[i].videoStarted) ? 0 : response.response.brands[i].videoStarted;
            pinnedData.videoFirstQuartileReached += isNaN(response.response.brands[i].videoFirstQuartileReached) ? 0 : response.response.brands[i].videoFirstQuartileReached;
            pinnedData.videoMidpointReached += isNaN(response.response.brands[i].videoMidpointReached) ? 0 : response.response.brands[i].videoMidpointReached;
            pinnedData.videoThirdQuartileReached += isNaN(response.response.brands[i].videoThirdQuartileReached) ? 0 : response.response.brands[i].videoThirdQuartileReached;
            pinnedData.videoCompleted += isNaN(response.response.brands[i].videoCompleted) ? 0 : response.response.brands[i].videoCompleted;
            pinnedData.companionImpressions += isNaN(response.response.brands[i].companionImpressions) ? 0 : response.response.brands[i].companionImpressions;
            pinnedData.companionClicks += isNaN(response.response.brands[i].companionClicks) ? 0 : response.response.brands[i].companionClicks;
            pinnedData.companionOfferRevenue += isNaN(response.response.brands[i].companionOfferRevenue) ? 0 : response.response.brands[i].companionOfferRevenue;
            pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response.response.brands[i]['effectiveCPAOnAdvertiserSpend']) ? 0 : response.response.brands[i]['effectiveCPAOnAdvertiserSpend'];
            pinnedData.primaryClickthruConversions += isNaN(response.response.brands[i]['primaryClickthruConversions']) ? 0 : response.response.brands[i]['primaryClickthruConversions'];
            pinnedData.primaryViewthruConversions += isNaN(response.response.brands[i]['primaryViewthruConversions']) ? 0 : response.response.brands[i]['primaryViewthruConversions'];
            pinnedData.primaryConversion += isNaN(response.response.brands[i]['primaryConversion']) ? 0 : response.response.brands[i]['primaryConversion'];
            pinnedData.clickThroughConversions += isNaN(response.response.brands[i]['clickThroughConversions']) ? 0 : response.response.brands[i]['clickThroughConversions'];
            pinnedData.viewthruConversions += isNaN(response.response.brands[i]['viewthruConversions']) ? 0 : response.response.brands[i]['viewthruConversions'];
            response.response.brands[i]['grossTotalEffectiveCPC'] = isNaN(response.response.brands[i]['grossTotalEffectiveCPC']) ? 0 : response.response.brands[i]['grossTotalEffectiveCPC'] / margin;
            pinnedData.grossTotalEffectiveCPC += isNaN(response.response.brands[i]['grossTotalEffectiveCPC']) ? 0 : response.response.brands[i]['grossTotalEffectiveCPC'];
            pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response.response.brands[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.brands[i].effectiveCPCOnAdvertiserSpend / margin;
            response.response.brands[i].effectiveCPCOnAdvertiserSpend = isNaN(response.response.brands[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.brands[i].effectiveCPCOnAdvertiserSpend / margin;
          }
        }
        if (pinnedData.impressionsWon > 0) {
          pinnedData.totalEffectiveCPM = (pinnedData.totalSpend / pinnedData.impressionsWon) * 1000;
          pinnedData.clickthruRate = (pinnedData.clicks / pinnedData.impressionsWon);
        }
        if (pinnedData.totalConversions > 0) {
          pinnedData.grossTotalEffectiveCPA = pinnedData.totalSpend / pinnedData.totalConversions;
        }
        pinnedData.winRate = ((pinnedData.impressionsWon * 100) / pinnedData.impressionsBid) / 100;
        pinnedData.costPerClick = (pinnedData.clicks > 0 ? pinnedData.totalSpend / pinnedData.clicks : 0);
        pinnedData.clickThroughRate = (pinnedData.clicks / pinnedData.impressionsWon);
        this.rowDataBrands = new Observable((obs) => {
          obs.next(response.response.brands);
          obs.complete();
        });
        this.pinnedBrandsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  editNewBrandModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newBrandModal.loadBrand(node.data as Brand);
      $('.new-brand-modal').modal('show');
    } else if (node.column.colId === 'archived') {
          const newData = {...node.data};
          newData['archived'] = !node.data.archived
          this.newBrandModal.loadBrand(newData);
          this.newBrandModal.onSubmit();
    }
  }

  getGroups(brandId: number, params = {filter: '', dateTo: '', dateFrom: '', status: ''}) {
    this.ngLoading = true;
    this.groupService.getGroups(brandId, params).subscribe(
      (response) => {
        this.rowDataGroups =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        const pinnedData = {
          primaryConversion: 0,
          totalSpend: 0,
          advertiserSpendRate: 0,
          advertiserSpend: 0,
          impressionsWon: 0,
          totalEffectiveCPM: 0,
          videoCompletionRate: 0,
          winRate: 0,
          clicks: 0,
          costPerClick: 0,
          clickThroughRate: 0,
          totalConversions: 0,
          conversions: 0,
          eligibleImpressions: 0,
          measuredImpressions: 0,
          viewableImpressions: 0,
          marginOnAdvertiserSpend: 0,
          dataSpend: 0,
          effectiveCPMOnAdvertiserSpend: 0,
          effectiveCPCOnAdvertiserSpend: 0,
          mediaEffectiveCPM: 0,
          dataEffectiveCPM: 0,
          clickThroughConversions: 0,
          clickRPM: 0,
          viewthruConversions: 0,
          viewCVR: 0,
          revenue: 0,
          ctcRevenue: 0,
          vtcRevenue: 0,
          effectiveCPAOnAdvertiserSpend: 0,
          totalCVRM: 0,
          profitPerClick: 0,
          revenuePerMille: 0,
          returnOnAdSpend: 0,
          videoStarted: 0,
          videoFirstQuartileReached: 0,
          videoMidpointReached: 0,
          videoThirdQuartileReached: 0,
          videoCompleted: 0,
          effectiveCPCVOnAdvertiserSpend: 0,
          totalEffectiveCPCV: 0,
          companionImpressions: 0,
          companionClicks: 0,
          companionConversions: 0,
          companionOfferRevenue: 0,
          grossTotalEffectiveCPA: 0,
          clickthruRate: 0

        };
        this.pinnedGroupsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        if (typeof response.response.campaignGroups !== 'undefined' && response.response.campaignGroups !== null) {
          for (const i in response.response.campaignGroups) {
            // const margin = (typeof response.response.campaignGroups[i]['extraData'] !== 'undefined' && response.response.campaignGroups[i]['extraData'].margin > 1) ?
            //   ((100 - response.response.campaignGroups[i]['extraData'].margin) / 100) : 1;

            const margin = 1;

            pinnedData.totalSpend += isNaN(response.response.campaignGroups[i].totalSpend) ? 0 : response.response.campaignGroups[i].totalSpend / margin;
            response.response.campaignGroups[i].totalSpend = isNaN(response.response.campaignGroups[i].totalSpend) ? 0 : response.response.campaignGroups[i].totalSpend / margin;
            // response.response.campaignGroups[i].budget.amount = isNaN(response.response.campaignGroups[i].budget.amount) ? 0 : response.response.campaignGroups[i].budget.amount / margin;
            response.response.campaignGroups[i].budget.amount = isNaN(response.response.campaignGroups[i].budget.amount) ? 0 : response.response.campaignGroups[i].budget.amount /
              ((typeof response.response.campaignGroups[i]['extraData'] !== 'undefined' && response.response.campaignGroups[i]['extraData'].margin > 1) ?
              ((100 - response.response.campaignGroups[i]['extraData'].margin) / 100) : 1);
            pinnedData.advertiserSpendRate += isNaN(response.response.campaignGroups[i].advertiserSpendRate) ? 0 : response.response.campaignGroups[i].advertiserSpendRate;
            pinnedData.advertiserSpend += isNaN(response.response.campaignGroups[i].advertiserSpend) ? 0 : response.response.campaignGroups[i].advertiserSpend / margin;
            response.response.campaignGroups[i].advertiserSpend = isNaN(response.response.campaignGroups[i].advertiserSpend) ? 0 : response.response.campaignGroups[i].advertiserSpend / margin;
            pinnedData.impressionsWon += isNaN(response.response.campaignGroups[i].impressionsWon) ? 0 : response.response.campaignGroups[i].impressionsWon;
            // pinnedData.totalEffectiveCPM += isNaN(response.response.campaignGroups[i].totalEffectiveCPM) ? 0 : response.response.campaignGroups[i].totalEffectiveCPM / margin;
            response.response.campaignGroups[i].totalEffectiveCPM = isNaN(response.response.campaignGroups[i].totalEffectiveCPM) ? 0 : response.response.campaignGroups[i].totalEffectiveCPM / margin;
            pinnedData.videoCompletionRate += isNaN(response.response.campaignGroups[i].videoCompletionRate) ? 0 : response.response.campaignGroups[i].videoCompletionRate;
            pinnedData.winRate += isNaN(response.response.campaignGroups[i].winRate) ? 0 : response.response.campaignGroups[i].winRate;
            pinnedData.clicks += isNaN(response.response.campaignGroups[i].clicks) ? 0 : response.response.campaignGroups[i].clicks;

            response.response.campaignGroups[i].costPerClick = isNaN(response.response.campaignGroups[i].costPerClick) ? 0 : response.response.campaignGroups[i].costPerClick / margin;
            pinnedData.costPerClick += isNaN(response.response.campaignGroups[i].costPerClick) ? 0 : response.response.campaignGroups[i].costPerClick;

            pinnedData.clickThroughRate += isNaN(response.response.campaignGroups[i].clickThroughRate) ? 0 : response.response.campaignGroups[i].clickThroughRate;
            pinnedData.totalConversions += isNaN(response.response.campaignGroups[i].totalConversions) ? 0 : response.response.campaignGroups[i].totalConversions;
            pinnedData.conversions += isNaN(response.response.campaignGroups[i].conversions) ? 0 : response.response.campaignGroups[i].conversions;
            pinnedData.eligibleImpressions += isNaN(response.response.campaignGroups[i].eligibleImpressions) ? 0 : response.response.campaignGroups[i].eligibleImpressions;
            pinnedData.measuredImpressions += isNaN(response.response.campaignGroups[i].measuredImpressions) ? 0 : response.response.campaignGroups[i].measuredImpressions;
            pinnedData.viewableImpressions += isNaN(response.response.campaignGroups[i].viewableImpressions) ? 0 : response.response.campaignGroups[i].viewableImpressions;

            response.response.campaignGroups[i].marginOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].marginOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].marginOnAdvertiserSpend / margin;
            pinnedData.marginOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].marginOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].marginOnAdvertiserSpend;

            response.response.campaignGroups[i].dataSpend = isNaN(response.response.campaignGroups[i].dataSpend) ? 0 : response.response.campaignGroups[i].dataSpend / margin;
            pinnedData.dataSpend += isNaN(response.response.campaignGroups[i].dataSpend) ? 0 : response.response.campaignGroups[i].dataSpend;
            pinnedData.effectiveCPMOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend / margin;
            response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPMOnAdvertiserSpend / margin;
            pinnedData.effectiveCPCOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend / margin;
            response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCOnAdvertiserSpend / margin;
            pinnedData.mediaEffectiveCPM += isNaN(response.response.campaignGroups[i].mediaEffectiveCPM) ? 0 : response.response.campaignGroups[i].mediaEffectiveCPM / margin;
            response.response.campaignGroups[i].mediaEffectiveCPM = isNaN(response.response.campaignGroups[i].mediaEffectiveCPM) ? 0 : response.response.campaignGroups[i].mediaEffectiveCPM / margin;

            response.response.campaignGroups[i].dataEffectiveCPM = isNaN(response.response.campaignGroups[i].dataEffectiveCPM) ? 0 : response.response.campaignGroups[i].dataEffectiveCPM / margin;
            pinnedData.dataEffectiveCPM += isNaN(response.response.campaignGroups[i].dataEffectiveCPM) ? 0 : response.response.campaignGroups[i].dataEffectiveCPM;

            pinnedData.clickThroughConversions += isNaN(response.response.campaignGroups[i].clickThroughConversions) ? 0 : response.response.campaignGroups[i].clickThroughConversions;
            pinnedData.clickRPM += isNaN(response.response.campaignGroups[i].clickRPM) ? 0 : response.response.campaignGroups[i].clickRPM;
            pinnedData.viewthruConversions += isNaN(response.response.campaignGroups[i].viewthruConversions) ? 0 : response.response.campaignGroups[i].viewthruConversions;
            pinnedData.viewCVR += isNaN(response.response.campaignGroups[i].viewCVR) ? 0 : response.response.campaignGroups[i].viewCVR;

            response.response.campaignGroups[i].revenue = isNaN(response.response.campaignGroups[i].revenue) ? 0 : response.response.campaignGroups[i].revenue / margin;
            pinnedData.revenue += isNaN(response.response.campaignGroups[i].revenue) ? 0 : response.response.campaignGroups[i].revenue;

            response.response.campaignGroups[i].ctcRevenue = isNaN(response.response.campaignGroups[i].ctcRevenue) ? 0 : response.response.campaignGroups[i].ctcRevenue / margin;
            pinnedData.ctcRevenue += isNaN(response.response.campaignGroups[i].ctcRevenue) ? 0 : response.response.campaignGroups[i].ctcRevenue;

            response.response.campaignGroups[i].vtcRevenue = isNaN(response.response.campaignGroups[i].vtcRevenue) ? 0 : response.response.campaignGroups[i].vtcRevenue / margin;
            pinnedData.vtcRevenue += isNaN(response.response.campaignGroups[i].vtcRevenue) ? 0 : response.response.campaignGroups[i].vtcRevenue;
            pinnedData.effectiveCPAOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend / margin;
            response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPAOnAdvertiserSpend / margin;
            pinnedData.viewCVR += isNaN(response.response.campaignGroups[i].viewCVR) ? 0 : response.response.campaignGroups[i].viewCVR;
            pinnedData.totalCVRM += isNaN(response.response.campaignGroups[i].totalCVRM) ? 0 : response.response.campaignGroups[i].totalCVRM;

            response.response.campaignGroups[i].profitPerClick = isNaN(response.response.campaignGroups[i].profitPerClick) ? 0 : response.response.campaignGroups[i].profitPerClick / margin;
            pinnedData.profitPerClick += isNaN(response.response.campaignGroups[i].profitPerClick) ? 0 : response.response.campaignGroups[i].profitPerClick;

            response.response.campaignGroups[i]['primaryConversion'] = (isNaN(response.response.campaignGroups[i]['primaryViewthruConversions']) ? 0 : response.response.campaignGroups[i]['primaryViewthruConversions'])
              + (isNaN(response.response.campaignGroups[i]['primaryClickthruConversions']) ? 0 : response.response.campaignGroups[i]['primaryClickthruConversions']);
            pinnedData.primaryConversion += isNaN(response.response.campaignGroups[i]['primaryConversion']) ? 0 : response.response.campaignGroups[i]['primaryConversion'];

            response.response.campaignGroups[i]['grossTotalEffectiveCPA'] = isNaN(response.response.campaignGroups[i]['grossTotalEffectiveCPA']) ? 0 : response.response.campaignGroups[i]['grossTotalEffectiveCPA'] / margin;
            // pinnedData.grossTotalEffectiveCPA += isNaN(response.response.campaignGroups[i]['grossTotalEffectiveCPA']) ? 0 : response.response.campaignGroups[i]['grossTotalEffectiveCPA'];

            response.response.campaignGroups[i].revenuePerMille = isNaN(response.response.campaignGroups[i].revenuePerMille) ? 0 : response.response.campaignGroups[i].revenuePerMille / margin;
            pinnedData.revenuePerMille += isNaN(response.response.campaignGroups[i].revenuePerMille) ? 0 : response.response.campaignGroups[i].revenuePerMille;
            pinnedData.clickRPM += isNaN(response.response.campaignGroups[i].clickRPM) ? 0 : response.response.campaignGroups[i].clickRPM;

            response.response.campaignGroups[i].returnOnAdSpend = isNaN(response.response.campaignGroups[i].returnOnAdSpend) ? 0 : response.response.campaignGroups[i].returnOnAdSpend / margin;
            pinnedData.returnOnAdSpend += isNaN(response.response.campaignGroups[i].returnOnAdSpend) ? 0 : response.response.campaignGroups[i].returnOnAdSpend;
            pinnedData.videoStarted += isNaN(response.response.campaignGroups[i].videoStarted) ? 0 : response.response.campaignGroups[i].videoStarted;
            pinnedData.videoFirstQuartileReached += isNaN(response.response.campaignGroups[i].videoFirstQuartileReached) ? 0 : response.response.campaignGroups[i].videoFirstQuartileReached;
            pinnedData.videoMidpointReached += isNaN(response.response.campaignGroups[i].videoMidpointReached) ? 0 : response.response.campaignGroups[i].videoMidpointReached;
            pinnedData.videoThirdQuartileReached += isNaN(response.response.campaignGroups[i].videoThirdQuartileReached) ? 0 : response.response.campaignGroups[i].videoThirdQuartileReached;
            pinnedData.videoCompleted += isNaN(response.response.campaignGroups[i].videoCompleted) ? 0 : response.response.campaignGroups[i].videoCompleted;
            // tslint:disable-next-line:max-line-length
            pinnedData.effectiveCPCVOnAdvertiserSpend += isNaN(response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend / margin;
            response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend = isNaN(response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend) ? 0 : response.response.campaignGroups[i].effectiveCPCVOnAdvertiserSpend / margin;
            pinnedData.totalEffectiveCPCV += isNaN(response.response.campaignGroups[i].totalEffectiveCPCV) ? 0 : response.response.campaignGroups[i].totalEffectiveCPCV / margin;
            response.response.campaignGroups[i].totalEffectiveCPCV = isNaN(response.response.campaignGroups[i].totalEffectiveCPCV) ? 0 : response.response.campaignGroups[i].totalEffectiveCPCV / margin;
            pinnedData.companionImpressions += isNaN(response.response.campaignGroups[i].companionImpressions) ? 0 : response.response.campaignGroups[i].companionImpressions;
            pinnedData.companionClicks += isNaN(response.response.campaignGroups[i].companionClicks) ? 0 : response.response.campaignGroups[i].companionClicks;
            pinnedData.companionConversions += isNaN(response.response.campaignGroups[i].companionConversions) ? 0 : response.response.campaignGroups[i].companionConversions;
            pinnedData.companionOfferRevenue += isNaN(response.response.campaignGroups[i].companionOfferRevenue) ? 0 : response.response.campaignGroups[i].companionOfferRevenue;
          }
          if (pinnedData.impressionsWon > 0) {
            pinnedData.totalEffectiveCPM = (pinnedData.totalSpend / pinnedData.impressionsWon) * 1000;
            pinnedData.clickthruRate = (pinnedData.clicks / pinnedData.impressionsWon);
          }
          if (pinnedData.totalConversions > 0) {
            pinnedData.grossTotalEffectiveCPA = pinnedData.totalSpend / pinnedData.totalConversions;
          }
        }
        this.rowDataGroups =  Observable.create((obs) => {
          obs.next(response.response.campaignGroups);
          obs.complete();
        });
        this.pinnedGroupsRowData =  Observable.create((obs) => {
          obs.next(pinnedData);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }
  editNewGroupModal(node) {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-pencil') {
      this.newGroupModal.margin = (this.brandSelected['extraData'].margin > 1) ? ((100 - this.brandSelected['extraData'].margin) / 100) : 1;
      this.newGroupModal.loadGroup(node.data as CampaignGroup);
      $('.new-group-modal').modal({backdrop: 'static', keyboard: false});
      $('.new-group-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-layers') {
      this.newGroupModal.margin = (this.brandSelected['extraData'].margin > 1) ? ((100 - this.brandSelected['extraData'].margin) / 100) : 1;
      this.newGroupModal.loadGroup(node.data as CampaignGroup, true);
      $('.new-group-modal').modal({backdrop: 'static', keyboard: false});
      $('.new-group-modal').modal('show');
    }
  }
  updateStatusGroupModal(data) {
    this.newGroupModal.margin = (this.brandSelected['extraData'].margin > 1) ? ((100 - this.brandSelected['extraData'].margin) / 100) : 1;
    this.newGroupModal.loadGroup(data as CampaignGroup);
    this.newGroupModal.onSubmit();
  }

  openMultGroupEditModal() {
    if (this.groupSelectedRows.length === 0) {
      this.alertService.alert('error', 'Please select at least one Campaign Group.')
    } else {
      this.multipleEditGroupModal.selectedGroups = this.groupSelectedRows;
      $('.new-multiple-edit-group-modal').modal('show');
    }
  }

  openMultTagEditModal() {
    if (this.adSelectedRows.length === 0) {
      this.alertService.alert('error', 'You need to select at least one Ad.');
    } else {
      this.multiEditAdModal.refreshGridData();
      $('.new-multiple-edit-ad-modal').modal('show');
    }
  }

  openMultTagEdit2Modal() {
    if (this.adSelectedRows.length === 0) {
      this.alertService.alert('error', 'You need to select at least one Ad.');
    } else {
      $('.new-multiple-edit2-ad-modal').modal('show');
    }
  }

  openBrandModal() {
    $('.new-brand-modal').modal('show');
  }

  openGroupModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      this.newGroupModal.margin = (this.brandSelected['extraData'].margin > 1) ? ((100 - this.brandSelected['extraData'].margin) / 100) : 1;
      $('.new-group-modal').modal('show');
    }
  }

  openAudienceModal() {
    if (this.brandIdSelected === null || typeof this.brandIdSelected === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      this.newAudienceModal.resetModal();
      $('.new-audience-modal').modal('show');
    }
  }

  openCRMAudienceModal() {
    if (this.brandIdSelected === null || typeof this.brandIdSelected === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      this.newCrmAudienceModal.resetModal();
      this.newCrmAudienceModal.brandId = this.brandIdSelected;
      $('.new-crm-audience-modal').modal('show');
    }
  }

  openConversionModal() {
    if (this.brandIdSelected === null || typeof this.brandIdSelected === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      this.newConversionModal.resetModal();
      $('.new-conversion-modal').modal('show');
    }
  }

  openUniversalModal() {
    if (this.brandIdSelected === null || typeof this.brandIdSelected === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      this.newUniversalPixel.resetModal();
      $('.new-universal-pixel-modal').modal('show');
    }
  }

  openImageAdModal() {
    if (this.brandIdSelected === null || typeof this.brandIdSelected === 'undefined') {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-image-modal').modal('show');
    }
  }

  openAudioAdModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-audio-modal').modal('show');
    }
  }

  openVastAudioAdModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-vast-audio-modal').modal('show');
    }
  }

  openVideoAdModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-video-modal').modal('show');
    }
  }

  openVastVideoAdModal() {
    $('.new-vast-video-modal').modal('show');
  }

  openNativeModal() {
    $('.new-native-modal').modal('show');
  }

  openHTML5Modal() {
    $('.new-html5-modal').modal('show');
  }

  openTagModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-tag-modal').modal('show');
    }
  }

  openExpTagModal() {
    $('.new-expandable-tag-modal').modal('show');
  }

  openMRAIDTagModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-mraid-tag-modal').modal('show');
    }
  }

  openMultImageModal() {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else {
      $('.new-multiple-image-modal').modal('show');
    }
  }

  openMultNativeModal() {
    $('.new-multiple-native-modal').modal('show');
  }

  openMultTagModal() {
    $('.new-multiple-tag-modal').modal('show');
  }

  brandRowSelected(event: any) {
    if (event.node.selected) {
      this.brandIdSelected = event.data.brandId;
      this.brandNameSelected = event.data.name;
      this.brandSelected = event.data;
      this.newGroupModal.brandId = event.data.brandId;
      this.linkCampAdModal.brandId = event.data.brandId;
      this.newImageAdModal.brandId = event.data.brandId;
      this.newMultipleImageAdModal.brandId = event.data.brandId;
      this.newMultipleImageAdModal.brandDomain = event.data.landingPageDomain;
      this.newAudioAdModal.brandId = event.data.brandId;
      this.newVastAudioAdModal.brandId = event.data.brandId;
      this.newVideoAdModal.brandId = event.data.brandId;
      this.newNativeAdModal.brandId = event.data.brandId;
      this.newHtml5AdModal.brandId = event.data.brandId;
      this.newTagAdModal.brandId = event.data.brandId;
      this.newExpandableTagAdModal.brandId = event.data.brandId;
      this.newMraidTagAdModal.brandId = event.data.brandId;
      this.newAudienceModal.brandId = event.data.brandId;
      this.newUniversalPixel.brandId = event.data.brandId;
      this.newConversionModal.brandId = event.data.brandId;
      this.tabChanged(this.currentTab);
    }
  }

  groupRowSelected(event: any) {
    this.groupSelectedRows = event.api.getSelectedRows().map((value, index, array) => value.campaignGroupId);
    console.log(this.groupSelectedRows);
  }

  adRowSelected(event: any) {
    const selectedAds = event.api.getSelectedRows()
    this.adSelectedRows = selectedAds.map((value, index, array) => value.creativeId);
    this.linkCampAdModal.selectedAds = this.adSelectedRows;
    this.multiEditAdModal.selectedAds = selectedAds.map((value, index, array) => ({creativeId: value.creativeId, label: value.label}));
    this.multiEdit2AdModal.selectedAds = selectedAds.map((value, index, array) => value);
    console.log(this.adSelectedRows);
  }

  audiencesRowSelected(event: any) {
    const selectedAud = event.api.getSelectedRows();
    this.audienceSelectedRows = selectedAud.map((value, index, array) => value.audienceId);
    this.audiencesBulkExport.audIds = this.audienceSelectedRows;
    this.audiencesBulkExport.audNames = selectedAud.map((value, index, array) => value.name);
    console.log(this.audienceSelectedRows);
  }

  conversionsRowSelected(event: any) {
    const selectedConversions = event.api.getSelectedRows();
    this.conversionsSelectedRows = selectedConversions.map((value, index, array) => value.offerId);
    this.conversionBulkExport.convIds = this.conversionsSelectedRows;
    this.conversionBulkExport.convNames = selectedConversions.map((value, index, array) => value.name);
    console.log(this.conversionsSelectedRows);
  }

  universalRowSelected(event: any) {
    const selectedUniversals = event.api.getSelectedRows();
    this.universalSelectedRows = selectedUniversals.map((value, index, array) => value.universalPixelId);
    // this.conversionBulkExport.convIds = this.conversionsSelectedRows;
    // this.conversionBulkExport.convNames = selectedUniversals.map((value, index, array) => value.name);
    console.log(this.universalSelectedRows);
  }

  rangeChanged(event: SatDatepickerInputEvent<any>, dateRange: any, input: HTMLInputElement = null) {
    // @ts-ignore
    if (typeof event['value']['extraData'] !== 'undefined') {
      this.rangeDatesFlag = true;
      dateRange.begin = dateRange.begin.subtract(1, 'days');
      dateRange.end = dateRange.end.subtract(1, 'days');
      input.value = dateRange.begin.format('MM/DD/YYYY') + ' - ' + dateRange.end.format('MM/DD/YYYY');
      let savedGridDates = JSON.parse(localStorage.getItem('savedGridDates'));
      this.currentDateScope = 'one';
      if (event['value']['extraData'] === 'fromButtonAll') {
        this.currentDateScope = 'all';
        this.brandDateRange = event['value'];
        this.groupDateRange = event['value'];
        this.audiencesDateRange = event['value'];
        this.audiencesCrmDateRange = event['value'];
        this.conversionsDateRange = event['value'];
        this.universalDateRange = event['value'];
        savedGridDates = {
          brandDateRange: event['value'],
          groupDateRange: event['value'],
          audiencesDateRange: event['value'],
          audiencesCrmDateRange: event['value'],
          conversionsDateRange: event['value'],
          universalDateRange: event['value'],
          campDateRange: event['value'],
          domainsDateRange: event['value'],
          adsCampaingDateRange: event['value'],
          convCampaingDateRange: event['value'],
          exCampaingDateRange: event['value'],
          dayCampaingDateRange: event['value']
        };
      } else if (savedGridDates !== null && typeof savedGridDates === 'object') {
        savedGridDates = {
          ...savedGridDates,
          brandDateRange: this.brandDateRange,
          groupDateRange: this.groupDateRange,
          audiencesDateRange: this.audiencesDateRange,
          audiencesCrmDateRange: this.audiencesCrmDateRange,
          conversionsDateRange: this.conversionsDateRange,
          universalDateRange: this.universalDateRange
        };
      } else {
        savedGridDates = {
          brandDateRange: this.brandDateRange,
          groupDateRange: this.groupDateRange,
          audiencesDateRange: this.audiencesDateRange,
          audiencesCrmDateRange: this.audiencesCrmDateRange,
          conversionsDateRange: this.conversionsDateRange,
          universalDateRange: this.universalDateRange
        };
      }

      // savedGridDates = (savedGridDates !== null && typeof savedGridDates === 'object')
      //   ? {
      //     ...savedGridDates,
      //     brandDateRange: this.brandDateRange,
      //     groupDateRange: this.groupDateRange,
      //     audiencesDateRange: this.audiencesDateRange,
      //     conversionsDateRange: this.conversionsDateRange,
      //     universalDateRange: this.universalDateRange
      //   }
      //   : {
      //     brandDateRange: this.brandDateRange,
      //     groupDateRange: this.groupDateRange,
      //     audiencesDateRange: this.audiencesDateRange,
      //     conversionsDateRange: this.conversionsDateRange,
      //     universalDateRange: this.universalDateRange
      //   };
      localStorage.setItem('savedGridDates', JSON.stringify(savedGridDates));
      if (typeof input !== 'undefined' && input !== null) {
        setTimeout(() => {
          input.focus();
        }, 1);
      }
    }
    // if (type === 'brand') {
    //   // @ts-ignore
    //   this.getBrands({filter: '', dateTo: event.value.end.format('YYYYMMDD'), dateFrom: event.value.begin.format('YYYYMMDD')})
    // }
  }

  stringSearch(event: KeyboardEvent, type: string) {
    // if (type === 'brand') {
    //   // @ts-ignore
    //   this.getBrands({filter: event.target.value, dateTo: '', dateFrom: ''})
    // }
  }

  refreshGrid(type = 'brand', showArchived = false) {
    if (type === 'brand') {
      // @ts-ignore
      this.getBrands({
        filter: this.brandFilterString,
          // tslint:disable-next-line:max-line-length
        dateTo: (this.brandDateRange !== null && typeof this.brandDateRange !== 'undefined') ? this.brandDateRange.end.format('YYYYMMDD') : '',
          // tslint:disable-next-line:max-line-length
        dateFrom: (this.brandDateRange !== null && typeof this.brandDateRange !== 'undefined') ? this.brandDateRange.begin.format('YYYYMMDD') : '',
          showArchived: showArchived
      })
    } else if (type === 'group') {
      this.groupSelectedRows = [];
      // @ts-ignore
      this.getGroups(this.brandIdSelected, {
        filter: this.groupFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.groupDateRange !== null && typeof this.groupDateRange !== 'undefined') ? this.groupDateRange.begin.format('YYYYMMDD') : '',
        status: this.selectedOption
      })
    } else if (type === 'ad') {
      this.adSelectedRows = [];
      this.getAds(this.brandIdSelected, {
        filter: this.adSearchString,
        dateTo: '',
        dateFrom: '',
        status: this.selectedAdOption
      });
    } else if (type === 'audience') {
      // @ts-ignore
      this.getAudiences(this.brandIdSelected, {
        filter: this.audienceFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.audiencesDateRange !== null && typeof this.audiencesDateRange !== 'undefined') ? this.audiencesDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.audiencesDateRange !== null && typeof this.audiencesDateRange !== 'undefined') ? this.audiencesDateRange.begin.format('YYYYMMDD') : '',
        visible: this.selectedAudienceOption
      })
    } else if (type === 'crm-audience') {
      // @ts-ignore
      this.getAudiences(this.brandIdSelected, {
        userIdTypes: 'RAMP_ID',
        filter: this.audienceCrmFilterString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.audiencesCrmDateRange !== null && typeof this.audiencesCrmDateRange !== 'undefined') ? this.audiencesCrmDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.audiencesCrmDateRange !== null && typeof this.audiencesCrmDateRange !== 'undefined') ? this.audiencesCrmDateRange.begin.format('YYYYMMDD') : '',
        visible: this.selectedCrmAudienceOption
      })
    } else if (type === 'conversion') {
      // @ts-ignore
      this.getConversions(this.brandIdSelected, {
        filter: this.conversionSearchString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.conversionsDateRange !== null && typeof this.conversionsDateRange !== 'undefined') ? this.conversionsDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.conversionsDateRange !== null && typeof this.conversionsDateRange !== 'undefined') ? this.conversionsDateRange.begin.format('YYYYMMDD') : '',
        status: this.selectedConversionOption
      })
    } else if (type === 'universal') {
      // @ts-ignore
      this.getUniversalPixels(this.brandIdSelected, {
        filter: this.universalSearchString,
        // tslint:disable-next-line:max-line-length
        dateTo: (this.universalDateRange !== null && typeof this.universalDateRange !== 'undefined') ? this.universalDateRange.end.format('YYYYMMDD') : '',
        // tslint:disable-next-line:max-line-length
        dateFrom: (this.universalDateRange !== null && typeof this.universalDateRange !== 'undefined') ? this.universalDateRange.begin.format('YYYYMMDD') : '',
        status: this.selectedUniversalOption
      })
    }
  }

  tabChanged(event: number) {
    this.currentTab = event;
    if (this.brandIdSelected !== null) {
      const tabs = ['group', 'ad', 'audience', 'crm-audience', 'conversion', 'universal'];
      this.refreshGrid(tabs[event]);
    }
  }

  private getAds(brandId: number, params: {filter: string; dateTo: string; dateFrom: string; status: string}) {
    this.ngLoading = true;
    console.log('GET ADS.');
    this.adsService.getAds(brandId, params).subscribe(
      (response) => {
        this.rowDataAds =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataAds =  Observable.create((obs) => {
          obs.next(response.response.ads);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  private getAudiences(brandId: number, params: any) {
    this.ngLoading = true;
    this.audienceService.getAudiencesWithStats(brandId, params).subscribe(
      (response) => {
        if (typeof params.userIdTypes !== 'undefined' && params.userIdTypes !== null && params.userIdTypes !== '' && params.userIdTypes === 'RAMP_ID') {
          this.rowDataCrmAudience =  Observable.create((obs) => {
            obs.next([]);
            obs.complete();
          });
          this.rowDataCrmAudience =  Observable.create((obs) => {
            obs.next(response.response.audiences);
            obs.complete();
          });
        } else {
          this.rowDataAudience =  Observable.create((obs) => {
            obs.next([]);
            obs.complete();
          });
          this.rowDataAudience =  Observable.create((obs) => {
            obs.next(response.response.audiences);
            obs.complete();
          });
        }
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  private getConversions(brandId: number, params: {filter: string; dateTo: string; dateFrom: string; status: string}) {
    this.ngLoading = true;
    this.conversionsService.getConversionsStats(brandId, params).subscribe(
      (response) => {
        this.rowDataConversion =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataConversion =  Observable.create((obs) => {
          obs.next(response.response.conversions);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  private getUniversalPixels(brandId: number, params: {filter: string; dateTo: string; dateFrom: string; status: string}) {
    this.ngLoading = true;
    this.univService.getUniversalStats(brandId, params).subscribe(
      (response) => {
        this.rowDataPixels =  Observable.create((obs) => {
          obs.next([]);
          obs.complete();
        });
        this.rowDataPixels =  Observable.create((obs) => {
          obs.next(response.response.universal_pixels);
          obs.complete();
        });
        this.ngLoading = false;
      },
      (error) => { console.log(error); this.ngLoading = false; }
    );
  }

  editNewAdModal(node: any) {
    if (this.brandIdSelected === null) {
      this.alertService.alert('error', 'You need to select a Brand.');
    } else if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      console.log(node.data);
      switch (node.data.type) {
        case 'banner':
          this.newImageAdModal.loadAd(node.data as Ad);
          $('.new-image-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-image-modal').modal('show');
          break;
        case 'audio':
          this.newAudioAdModal.loadAd(node.data as Ad);
          $('.new-audio-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-audio-modal').modal('show');
          break;
        case 'video':
          this.newVideoAdModal.loadAd(node.data as Ad);
          $('.new-video-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-video-modal').modal('show');
          break;
        case 'vast_audio':
          this.newVastAudioAdModal.loadAd(node.data as Ad);
          $('.new-vast-audio-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-vast-audio-modal').modal('show');
          break;
        case 'vast_video':
          this.newVastVideoAdModal.loadAd(node.data as Ad);
          $('.new-vast-video-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-vast-video-modal').modal('show');
          break;
        case 'vast':
          this.newVastVideoAdModal.loadAd(node.data as Ad);
          $('.new-vast-video-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-vast-video-modal').modal('show');
          break;
        case 'native':
          this.newNativeAdModal.loadAd(node.data as Ad);
          $('.new-native-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-native-modal').modal('show');
          break;
        case 'html5':
          this.newHtml5AdModal.loadAd(node.data as Ad);
          $('.new-html5-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-html5-modal').modal('show');
          break;
        case 'tag':
          this.newTagAdModal.loadAd(node.data as Ad);
          $('.new-tag-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-tag-modal').modal('show');
          break;
        case 'expandable':
          this.newExpandableTagAdModal.loadAd(node.data as Ad);
          $('.new-expandable-tag-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-expandable-tag-modal').modal('show');
          break;
        case 'ad_builder':
          this.newMraidTagAdModal.loadAd(node.data as Ad);
          $('.new-mraid-tag-modal').modal({backdrop: 'static', keyboard: false});
          $('.new-mraid-tag-modal').modal('show');
          break;
      }
    }  else if (node.column.colId === 'status') {
      // if (node.event.target.firstElementChild !== null
      //   && typeof node.event.target.firstElementChild.value !== 'undefined'
      //   && node.event.target.firstElementChild.value === 'on') {
      //   const newData = {...node.data};
      //   newData.status = true;
      //   this.newAd.loadAd(newData);
      //   this.newAd.onSubmit();
      // } else {
      //   const newData = {...node.data};
      //   newData.status = false;
      //   this.newAd.loadAd(newData);
      //   this.newAd.onSubmit();
      // }
    }
  }

  updateStatusAdModal(data) {
    switch (data.type) {
      case 'banner':
        // this.newImageAdModal.loadAd(data as Ad);
        // this.newImageAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'audio':
        // this.newAudioAdModal.loadAd(data as Ad);
        // this.newAudioAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'video':
        // this.newVideoAdModal.loadAd(data as Ad);
        // this.newVideoAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'vast_audio':
        // this.newVastAudioAdModal.loadAd(data as Ad);
        // this.newVastAudioAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'vast_video':
        // this.newVastVideoAdModal.loadAd(data as Ad);
        // this.newVastVideoAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'native':
        // this.newNativeAdModal.loadAd(data as Ad);
        // this.newNativeAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'html5':
        // this.newHtml5AdModal.loadAd(data as Ad);
        // this.newHtml5AdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'tag':
        // this.newTagAdModal.loadAd(data as Ad);
        // this.newTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'expandable':
        // this.newExpandableTagAdModal.loadAd(data as Ad);
        // this.newExpandableTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
      case 'ad_builder':
        // this.newMraidTagAdModal.loadAd(data as Ad);
        // this.newMraidTagAdModal.onSubmit();
        this.adsService.updateAdPartially({status: data.status}, data.creativeId).subscribe(
          response => {
            console.log(response);
            this.refreshGrid('ad');
          }, error => console.log(error)
        );
        break;
    }
  }

  openLinkToCampaignsModal() {
    if (this.adSelectedRows.length === 0) {
      this.alertService.alert('error', 'You need to select at least one Ad.');
    } else {
      if (this.adSelectedRows.length === 1) {
        this.linkCampAdModal.getAdCampaigns();
      } else {
        this.linkCampAdModal.getCampaigns();
      }
      $('.link-ads-modal').modal('show');
    }
  }

  adsGridInit(params) {
    this.gridApiAds = params.api;
  }

  editAudienceModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newAudienceModal.loadAudience(node.data as Audience);
      $('.new-audience-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-upload') {
      this.audienceUploadDeviceids.brandName = (node.data as Audience).brandName;
      this.audienceUploadDeviceids.audienceId = node.data.audienceId;
      $('.audience-upload-deviceids').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-tag') {
      this.audienceGetTags.audienceId =  node.data.audienceId;
      this.audienceGetTags.brandName = (node.data as Audience).brandName;
      this.audienceGetTags.getTagCode();
      $('.audience-get-tags').modal('show');
    } else if (node.column.colId === 'visible') {
        const newData = {...node.data};
        this.newAudienceModal.loadAudience(newData);
        this.newAudienceModal.onSubmit();
    }
  }

  editCrmAudienceModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newCrmAudienceModal.loadAudience(node.data as Audience);
      $('.new-crm-audience-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-upload') {
      this.crmAudienceUploadList.brandName = (node.data as Audience).brandName;
      this.crmAudienceUploadList.audienceId = node.data.audienceId;
      $('.crm-audience-upload-list').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-tag') {
      this.audienceGetTags.audienceId =  node.data.audienceId;
      this.audienceGetTags.brandName = (node.data as Audience).brandName;
      this.audienceGetTags.getTagCode();
      $('.audience-get-tags').modal('show');
    } else if (node.column.colId === 'visible') {
      const newData = {...node.data};
      this.newCrmAudienceModal.loadAudience(newData);
      this.newCrmAudienceModal.brandId = this.brandIdSelected;
      this.newCrmAudienceModal.onSubmit();
    }
  }

  editConversionModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newConversionModal.loadConversion(node.data as Conversion);
      $('.new-conversion-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-link') {
      this.conversionAudienceCapture.loadConversionAudience(node.data.offerId, this.brandIdSelected);
      $('.conversion-audience-capture-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-tag') {
      this.conversionGetTags.offerId =  node.data.offerId;
      this.conversionGetTags.getTagCode();
      this.conversionGetTags.brandName = (node.data as Conversion).brandName;
      $('.conversion-get-tags').modal('show');
    } else if (node.column.colId === 'deleted') {
      if (node.event.target.firstElementChild !== null
        && typeof node.event.target.firstElementChild.value !== 'undefined'
        && node.event.target.firstElementChild.value === 'on') {
        const newData = {...node.data};
        newData.deleted = false;
        this.newConversionModal.loadConversion(newData);
        this.newConversionModal.onSubmit();
      } else {
        const newData = {...node.data};
        newData.deleted = true;
        this.newConversionModal.loadConversion(newData);
        this.newConversionModal.onSubmit();
      }
    }
  }

  editUniversalModal(node) {
    if ((typeof node.column !== 'undefined' && typeof node.event !== 'undefined' && node.column.colId === 'actions' && node.event.target.className === 'ti-pencil')
      || (typeof node.type !== 'undefined' && node.type === 'rowDoubleClicked')) {
      this.newUniversalPixel.loadUniversalPixel(node.data as UniversalPixel);
      $('.new-universal-pixel-modal').modal('show');
    } else if (node.column.colId === 'actions' && node.event.target.className === 'ti-tag') {
      this.universalPixelTag.universalPixelId =  node.data.universalPixelId;
      this.universalPixelTag.brandId = this.brandIdSelected;
      this.universalPixelTag.getTagCode();
      $('.universal-pixel-tag').modal('show');
    } else if (node.column.colId === 'deleted') {
      if (node.event.target.firstElementChild !== null
        && typeof node.event.target.firstElementChild.value !== 'undefined'
        && node.event.target.firstElementChild.value === 'on') {
        const newData = {...node.data};
        newData.deleted = false;
        this.newUniversalPixel.loadUniversalPixel(newData);
        this.newUniversalPixel.onSubmit();
      } else {
        const newData = {...node.data};
        newData.deleted = true;
        this.newUniversalPixel.loadUniversalPixel(newData);
        this.newUniversalPixel.onSubmit();
      }
    }
  }

  openAudienceBulkExportModal() {
    if (this.audienceSelectedRows.length === 0) {
      this.alertService.alert('error', 'You need to select an Audience.');
    } else {
      $('.audience-bulk-export').modal('show');
    }
  }

  openConversionsBulkExportModal() {
    if (this.conversionsSelectedRows.length === 0) {
      this.alertService.alert('error', 'You need to select a Conversion.');
    } else {
      $('.conversion-bulk-export').modal('show');
    }
  }

  cellValueChanged(node: any) {
    console.log('CELL VALUE CHANGED: ', node);
  }

  brandsGridReady(event: any) {
    this.brandsGrid = event.api;
    this.updateColumns();
  }

  exportBrands() {
    this.brandsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  groupsGridReady(event: any) {
    this.groupsGrid = event.api;
    this.groupsGrid.setColumnDefs(this.getGroupColumns());
    this.updateColumns('groups');
  }

  getGroupColumns() {
    return [
      {
        headerName: '',
        colId: 'checkboxSelect',
        width: 40,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        resizable: false,
        filter: false,
        cellRenderer: '',
        cellRendererParams: null,
        pinnedRowCellRenderer: '',
        pinnedRowCellRendererParams: null
      },
      {
        headerName: 'Actions',
        field: 'campaignGroupId',
        colId: 'actions',
        sortable: false,
        filter: false,
        width: 150,
        cellRenderer: 'actionsCellRendererComponent',
        cellRendererParams: { renderType: 'Campaign Group', brandId: this.brandIdSelected },
        pinnedRowCellRenderer: '',
        pinnedRowCellRendererParams: null
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 90, filter: true,
        cellRenderer: 'statusCellRendererComponent',
        pinnedRowCellRenderer: '',
        pinnedRowCellRendererParams: null,
        hide: this.hideStatus,
        sortable: false,
      },
      {headerName: 'Name', sort: 'asc', field: 'name', width: 300, pinnedRowCellRenderer: null, pinnedRowCellRendererParams: null, cellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA === valueB) { return 0; }
          return (valueA > valueB) ? 1 : -1;
        } },
      {headerName: 'All Time Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'totalBudget'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            // tslint:disable-next-line:max-line-length
            (valueA.amount) - (valueB.amount)
          )
        } },
      {headerName: 'Spend', field: 'totalSpend', width: 80, cellRendererParams: { totalType: 'money' } , pinnedRowCellRendererParams: { totalType: 'money' }},
      {headerName: 'Daily Budget', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'dailyBudget'}, pinnedRowCellRenderer: null,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            // tslint:disable-next-line:max-line-length
            (valueA.amount / (typeof valueA.schedule !== 'undefined' ? moment(valueA.schedule.flightDates.to).diff(moment(valueA.schedule.flightDates.from), 'days') : 1)) - (valueB.amount / (typeof valueB.schedule !== 'undefined' ? moment(valueB.schedule.flightDates.to).diff(moment(valueB.schedule.flightDates.from), 'days') : 1))
          )
        } },
      {headerName: 'Start Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'startDate'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            typeof valueA.schedule !== 'undefined' ?
              Number(moment(valueA.schedule.flightDates.from).format('x')) : 0 ) - (
            typeof valueB.schedule !== 'undefined' ?
              Number(moment(valueB.schedule.flightDates.from).format('x')) : 0 )
        } },
      {headerName: 'End Date', field: 'budget', width: 100, cellRenderer: 'budgetCellRendererComponent', cellRendererParams: {type: 'endDate'}, pinnedRowCellRenderer: null, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          return (
            typeof valueA.schedule !== 'undefined' ?
              Number(moment(valueA.schedule.flightDates.to).format('x')) : 0 ) - (
            typeof valueB.schedule !== 'undefined' ?
              Number(moment(valueB.schedule.flightDates.to).format('x')) : 0 )
        } },
      {headerName: 'KPI Metric', field: 'kpiType', width: 80, pinnedRowCellRenderer: null, pinnedRowCellRendererParams: null, cellRenderer: null },
      {headerName: 'KPI Value', field: 'kpiValue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Max Billable', field: 'advertiserSpendRate', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Adv. Spend', field: 'advertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Imps. Won', field: 'impressionsWon', width: 80 },
      {headerName: 'Completion Rate', field: 'videoCompletionRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Win Rate', field: 'winRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Clicks', field: 'clicks', width: 80 },
      {headerName: 'Total eCPC', field: 'grossTotalEffectiveCPC', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTR', field: 'clickthruRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Total Conversions', field: 'totalConversions', width: 80 },
      {headerName: 'Primary Conv.', field: 'primaryConversion', width: 80 },
      {headerName: 'Total Eligible Impressions', field: 'eligibleImpressions', width: 80 },
      {headerName: 'Total Measured Impressions', field: 'measuredImpressions', width: 80 },
      {headerName: 'Total Viewable Impressions', field: 'viewableImpressions', width: 80 },
      {headerName: 'Measured Rate', field: 'measuredRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Viewable Rate', field: 'viewableRate', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Actual Margin %', field: 'marginOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Media Spend', field: 'auctionsSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Data Spend', field: 'dataSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CPC', field: 'costPerClick', width: 100, pinnedRowCellRendererParams: { totalType: 'money' }, cellRendererParams: { totalType: 'money' }},
      {headerName: 'Adv. Spend eCPM', field: 'effectiveCPMOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Adv. Spend eCPC', field: 'effectiveCPCOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Media eCPM', field: 'mediaEffectiveCPM', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Data eCPM', field: 'dataEffectiveCPM', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total eCPM', field: 'totalEffectiveCPM', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTC', field: 'clickThroughConversions', width: 80 },
      {headerName: 'Primary CTC', field: 'primaryClickthruConversions', width: 80, },
      {headerName: 'CTC eCPA', field: 'clickEffectiveCPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Primary CTC eCPA', field: 'primaryEffectiveCPACTC', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'VTC', field: 'viewthruConversions', width: 80 },
      {headerName: 'Primary VTC', field: 'primaryViewthruConversions', width: 80 },
      {headerName: 'Total Revenue', field: 'revenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'CTC Revenue', field: 'ctcRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'VTC Revenue', field: 'vtcRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Adv. Spend eCPA', field: 'effectiveCPAOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Primary Adv. Spend eCPA', field: 'primaryAdvSpendECPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total eCPA', field: 'grossTotalEffectiveCPA', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Primary Conv. eCPA', field: 'primaryCostPerAcquisition', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Click CVR', field: 'clickCVR', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'View CVR', field: 'viewCVR', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'Total CVRM', field: 'totalCVRM', width: 80, cellRendererParams: { totalType: 'percent' }, pinnedRowCellRendererParams: { totalType: 'percent' } },
      {headerName: 'EPC', field: 'profitPerClick', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total RPM', field: 'revenuePerMille', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Click RPM', field: 'clickRPM', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'ROAS', field: 'returnOnAdSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Audio/Video Started', field: 'videoStarted', width: 80 },
      {headerName: '25% Complete', field: 'videoFirstQuartileReached', width: 80 },
      {headerName: '50% Complete', field: 'videoMidpointReached', width: 80 },
      {headerName: '75% Complete', field: 'videoThirdQuartileReached', width: 80 },
      {headerName: '100% Complete', field: 'videoCompleted', width: 80 },
      {headerName: 'Adv. Spend eCPCV', field: 'effectiveCPCVOnAdvertiserSpend', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Total Spend eCPCV', field: 'totalEffectiveCPCV', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
      {headerName: 'Eligible Spend', field: 'eligibleSpend', width: 80, cellRendererParams: { totalType: 'money' } },
      {headerName: 'Eligible vCPM', field: 'eligibleVCPM', width: 80, cellRendererParams: { totalType: 'money' } },
      {headerName: 'Companion Impressions Won', field: 'companionImpressions', width: 80 },
      {headerName: 'Companion Clicks', field: 'companionClicks', width: 80 },
      {headerName: 'Companion CTC', field: 'companionConversions', width: 80 },
      {headerName: 'Companion CTC Revenue', field: 'companionOfferRevenue', width: 80, cellRendererParams: { totalType: 'money' }, pinnedRowCellRendererParams: { totalType: 'money' } },
    ];
  }

  exportGroups() {
    this.groupsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  exportAds() {
    this.gridApiAds.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  audiencesGridReady(event: any) {
    this.audiencesGrid = event.api;
  }

  audiencesCrmGridReady(event: any) {
    this.audiencesCrmGrid = event.api;
  }

  exportAudiences() {
    this.audiencesGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  exportCrmAudiences() {
    this.audiencesCrmGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  conversionGridReady(event: any) {
    this.conversionsGrid = event.api;
  }

  exportConversions() {
    this.conversionsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  pixelsGridReady(event: any) {
    this.pixelsGrid = event.api;
  }

  exportPixels() {
    this.pixelsGrid.exportDataAsCsv({
      processCellCallback: (params) => {
        return this.gridRender.cellRenderValue(params);
      }
    } as CsvExportParams);
  }

  selectFirstBrand() {
    if (typeof this.brandsGrid !== 'undefined') {
      this.brandsGrid.forEachNode(function(node) {
        node.setSelected(node.rowIndex === 0);
      });
    }
  }

  closedDatesRange(type: string = 'brand') {
    if (this.rangeDatesFlag) {
      this.refreshGrid(this.currentDateScope === 'one' ? type : 'brand');
    } else {
      this.initializeRangeDates();
    }
    this.rangeDatesFlag = false;
  }

  groupCampaigns(event: any) {
    console.log(event);
    this.router.navigate(['campaigns/' + event.data.brandId + '/' + event.data.campaignGroupId])
  }

  filterChanged(event: any, varName: string) {
    this.filtersActive[varName] = Object.keys(event.api.getFilterModel()).length > 0;
  }

  updateColumns(grids = 'brands'): void {
    let visibleColumns = [];
    if (this.columnSetup[grids].length > 0) {
      visibleColumns = this.columnSetup[grids];
    }
    console.log('visibleColumns: ', visibleColumns, this.brandsGrid.columnController.columnApi.getAllGridColumns());
    switch (grids) {
      case 'brands':
        this.brandsGrid.columnController.columnApi.setColumnsVisible(this.brandsGrid.columnController.columnApi.getAllGridColumns().slice(4), false);
        this.brandsGrid.columnController.columnApi.setColumnsVisible(visibleColumns, true);
        this.brandsGrid.columnController.columnApi.autoSizeAllColumns();
        break;
      case 'groups':
        this.groupsGrid.columnController.columnApi.setColumnsVisible(this.groupsGrid.columnController.columnApi.getAllGridColumns().slice(9), false);
        this.groupsGrid.columnController.columnApi.setColumnsVisible(visibleColumns, true);
        this.groupsGrid.columnController.columnApi.autoSizeAllColumns();
        break;
    }
  }

  customizeColumns(grid = 'brands') {
    const currentColumns = this.columnSetup[grid];
    const gridColumns = this.gridColumns[grid];
    const dialogRef = this.dialog.open(GridColumnsCustomizationComponent, {
      maxWidth: '1050px',
      width: '600px',
      disableClose: true,
      data: {currentColumns: currentColumns, gridColumns: gridColumns},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (typeof result !== 'undefined' && result !== null) {
        // this._reportsService.saveReportColumnsCustomization({name: result.name, columns: result.columns, ad_account_id: this.filterForm.get('adAccountId').value}).subscribe(
        //   response => {
        //     console.log(response);
        //   }, error => console.log(error)
        // );
        localStorage.setItem(grid + 'CustomColumns', JSON.stringify(result.columns));
        this.columnSetup[grid] = result.columns;
        this.updateColumns(grid);
      }
    });
  }
}
