import {Component, Input, OnInit} from '@angular/core';
import {BrandsComponent} from '../../../pages/brands/brands.component';

@Component({
  selector: 'app-new-multiple-native-modal',
  templateUrl: './new-multiple-native-modal.component.html',
  styleUrls: ['./new-multiple-native-modal.component.scss']
})
export class NewMultipleNativeModalComponent implements OnInit {

  private _parent: BrandsComponent ;
  @Input() set parent(value: BrandsComponent ) {
    this._parent = value;
  }

  constructor() { }

  ngOnInit() {
  }

}
